import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { FormFieldGroupDTO } from 'gen/dtos/FormFieldGroupDTO';
import React, { useEffect, useState } from 'react';

export interface EditFormGroupDialogProps { 
    group: FormFieldGroupDTO; 
    open: boolean; 
    onClose: () => void; 
    onSave: (group: FormFieldGroupDTO) => void; 
    onDelete: (group: FormFieldGroupDTO) => void; 
}


const EditFormGroupDialog : React.FC<EditFormGroupDialogProps> = ({ group, open, onClose, onSave, onDelete }) => { 

    const [formGroup, setFormGroup] = useState<FormFieldGroupDTO>(group); 

    const [deleting, setDeleting] = useState(false); 

    useEffect(() => { 

        setFormGroup(group); 

    }, [group])

    const save = () => { 
        setDeleting(false); 
        onSave(formGroup); 
        onClose(); 
    }

    const cancel = () => { 
        setDeleting(false); 
        onClose(); 
    }

    const doDelete = () => { 
        setDeleting(false); 
        onDelete(group); 
        onClose(); 
    }

    return ( 
        <Dialog open={open} onClose={onClose}>

            <DialogTitle>Edit Group</DialogTitle>

            <DialogContent>
                
                <TextField value={formGroup.Title} fullWidth onChange={(e) => setFormGroup({ ...formGroup, Title: e.target.value as string })} label="Title" /> 
                
                <br /> 
                
                <TextField 
                    value={formGroup.Description} 
                    fullWidth 
                    rows={3} 
                    rowsMax={3} 
                    multiline 
                    onChange={(e) => setFormGroup({ ...formGroup, Description: e.target.value as string })} 
                    label="Description" 
                /> 

            </DialogContent>
            
            <DialogActions>
                
                {!deleting && 
                    <Button onClick={() => setDeleting(true)}>Delete Group</Button>
                }

                {deleting && 
                    <Button variant="contained" style={{ color: '#fff', backgroundColor: '#f00' }} onClick={() => doDelete()}>Confirm Delete Group</Button>
                }

                <Button onClick={save} variant="contained">
                    Done
                </Button>
                
                <Button onClick={cancel}>Cancel</Button>

            </DialogActions>

        </Dialog>
    )

}

export default EditFormGroupDialog; 