

// APIDocs_View -- View APIDocs
export const APIDocs_ViewPermission = "APIDocs_View";
// Account_GetMyAccount -- gets the account of the active user
export const Account_GetMyAccountPermission = "Account_GetMyAccount";
// Account_GetMyAccountUsers -- gets a collection of users across all accounts
export const Account_GetMyAccountUsersPermission = "Account_GetMyAccountUsers";
// Account_UpdateMyAccount -- updates an account
export const Account_UpdateMyAccountPermission = "Account_UpdateMyAccount";
// Account_View -- View Account
export const Account_ViewPermission = "Account_View";
// Accounts_CreateAccount -- creates an account
export const Accounts_CreateAccountPermission = "Accounts_CreateAccount";
// Accounts_DeleteAccount -- deletes an account
export const Accounts_DeleteAccountPermission = "Accounts_DeleteAccount";
// Accounts_GetAccountFromID -- gets an account by it's ID
export const Accounts_GetAccountFromIDPermission = "Accounts_GetAccountFromID";
// Accounts_GetAccountSettings -- gets an accounts settings
export const Accounts_GetAccountSettingsPermission = "Accounts_GetAccountSettings";
// Accounts_GetAccountUsers -- gets a collection of users across all accounts
export const Accounts_GetAccountUsersPermission = "Accounts_GetAccountUsers";
// Accounts_GetAllAccounts -- gets a collection of users by their group
export const Accounts_GetAllAccountsPermission = "Accounts_GetAllAccounts";
// Accounts_UpdateAccount -- updates an account
export const Accounts_UpdateAccountPermission = "Accounts_UpdateAccount";
// Accounts_UpdateAccountSettings -- updates an accounts settings
export const Accounts_UpdateAccountSettingsPermission = "Accounts_UpdateAccountSettings";
// Accounts_View -- View Accounts
export const Accounts_ViewPermission = "Accounts_View";
// Activity_View -- View Activity
export const Activity_ViewPermission = "Activity_View";
// AdminActivity_GetUserLogs -- returns a slice of logs associated with a user
export const AdminActivity_GetUserLogsPermission = "AdminActivity_GetUserLogs";
// AdminActivity_View -- View AdminActivity
export const AdminActivity_ViewPermission = "AdminActivity_View";
// AdminDB_GetAllDBStats -- gets stats on all databases
export const AdminDB_GetAllDBStatsPermission = "AdminDB_GetAllDBStats";
// AdminDB_View -- View AdminDB
export const AdminDB_ViewPermission = "AdminDB_View";
// AdminSettings_CreateSetting -- creates a setting
export const AdminSettings_CreateSettingPermission = "AdminSettings_CreateSetting";
// AdminSettings_DeleteSetting -- deletes a setting
export const AdminSettings_DeleteSettingPermission = "AdminSettings_DeleteSetting";
// AdminSettings_ExportSettings -- exports a setting
export const AdminSettings_ExportSettingsPermission = "AdminSettings_ExportSettings";
// AdminSettings_GetAllSettings -- gets all settings
export const AdminSettings_GetAllSettingsPermission = "AdminSettings_GetAllSettings";
// AdminSettings_ImportSettings -- imports settings
export const AdminSettings_ImportSettingsPermission = "AdminSettings_ImportSettings";
// AdminSettings_UpdateSetting -- 
export const AdminSettings_UpdateSettingPermission = "AdminSettings_UpdateSetting";
// AdminSettings_View -- View AdminSettings
export const AdminSettings_ViewPermission = "AdminSettings_View";
// AdminUsers_View -- View the user admin
export const AdminUsers_ViewPermission = "AdminUsers_View";
// AdminUtils_RebuildPermissions -- rebuilds the permissions
export const AdminUtils_RebuildPermissionsPermission = "AdminUtils_RebuildPermissions";
// AdminUtils_RefreshUserSessions -- refreshes user sessions
export const AdminUtils_RefreshUserSessionsPermission = "AdminUtils_RefreshUserSessions";
// AdminUtils_View -- View AdminUtils
export const AdminUtils_ViewPermission = "AdminUtils_View";
// AdminView -- View the admin menu section
export const AdminViewPermission = "AdminView";
// Auth_View -- View Auth
export const Auth_ViewPermission = "Auth_View";
// BiddingInTheFuture_View -- View bidding in the future section
export const BiddingInTheFuture_ViewPermission = "BiddingInTheFuture_View";
// Comments_CreateComment -- creates a comment
export const Comments_CreateCommentPermission = "Comments_CreateComment";
// Comments_DeleteComment -- deletes a comment
export const Comments_DeleteCommentPermission = "Comments_DeleteComment";
// Comments_GetComments -- gets a collection of comments
export const Comments_GetCommentsPermission = "Comments_GetComments";
// Comments_UpdateComment -- updates a comment
export const Comments_UpdateCommentPermission = "Comments_UpdateComment";
// Comments_View -- View Comments
export const Comments_ViewPermission = "Comments_View";
// Conversations_AddMemberToConversation -- adds a userProfile to a conversation
export const Conversations_AddMemberToConversationPermission = "Conversations_AddMemberToConversation";
// Conversations_AddMessageToConversation -- adds a message to a conversation
export const Conversations_AddMessageToConversationPermission = "Conversations_AddMessageToConversation";
// Conversations_ApproveOpenPMInvite -- approves an open PM invite
export const Conversations_ApproveOpenPMInvitePermission = "Conversations_ApproveOpenPMInvite";
// Conversations_CreatePMConversation -- creates a new conversation
export const Conversations_CreatePMConversationPermission = "Conversations_CreatePMConversation";
// Conversations_CreatePMInvite -- creates a new conversation invitation
export const Conversations_CreatePMInvitePermission = "Conversations_CreatePMInvite";
// Conversations_DeclineOpenPMInvite -- approves an open PM invite
export const Conversations_DeclineOpenPMInvitePermission = "Conversations_DeclineOpenPMInvite";
// Conversations_GetConversationByID -- gets a conversation by its ID
export const Conversations_GetConversationByIDPermission = "Conversations_GetConversationByID";
// Conversations_GetConversationsByMember -- responds with a slice of conversations that the active user is part of
export const Conversations_GetConversationsByMemberPermission = "Conversations_GetConversationsByMember";
// Conversations_GetOpenPMInvites -- returns a collection of open PM invites for the current user
export const Conversations_GetOpenPMInvitesPermission = "Conversations_GetOpenPMInvites";
// Conversations_RemoveMemberFromConversation -- adds a userProfile to a conversation
export const Conversations_RemoveMemberFromConversationPermission = "Conversations_RemoveMemberFromConversation";
// Conversations_RemoveMessageFromConversation -- removes a message from a conversation
export const Conversations_RemoveMessageFromConversationPermission = "Conversations_RemoveMessageFromConversation";
// Conversations_View -- View Conversations
export const Conversations_ViewPermission = "Conversations_View";
// Customers_CanManageLogs -- View and manage logs in Customer Detail View
export const Customers_CanManageLogsPermission = "Customers_CanManageLogs";
// Customers_CreateCustomer -- creates a customer
export const Customers_CreateCustomerPermission = "Customers_CreateCustomer";
// Customers_CreateCustomerContact -- creates a customer contact
export const Customers_CreateCustomerContactPermission = "Customers_CreateCustomerContact";
// Customers_CreateCustomerEvent -- creates a customer event
export const Customers_CreateCustomerEventPermission = "Customers_CreateCustomerEvent";
// Customers_DeleteCustomer -- deletes a company
export const Customers_DeleteCustomerPermission = "Customers_DeleteCustomer";
// Customers_DeleteCustomerContact -- deletes a customer contact
export const Customers_DeleteCustomerContactPermission = "Customers_DeleteCustomerContact";
// Customers_DeleteCustomerEvent -- deletes a customer event
export const Customers_DeleteCustomerEventPermission = "Customers_DeleteCustomerEvent";
// Customers_GetCustomerEvents -- returns a slice of customer events
export const Customers_GetCustomerEventsPermission = "Customers_GetCustomerEvents";
// Customers_GetCustomerFromID -- gets a customer by its ID
export const Customers_GetCustomerFromIDPermission = "Customers_GetCustomerFromID";
// Customers_GetCustomerFromIDs -- gets a customer by its ID
export const Customers_GetCustomerFromIDsPermission = "Customers_GetCustomerFromIDs";
// Customers_GetCustomers -- returns a collection of customers
export const Customers_GetCustomersPermission = "Customers_GetCustomers";
// Customers_SearchCustomersByTitle -- returns a list of customers based on a title search
export const Customers_SearchCustomersByTitlePermission = "Customers_SearchCustomersByTitle";
// Customers_UpdateCustomer -- updates a customer
export const Customers_UpdateCustomerPermission = "Customers_UpdateCustomer";
// Customers_UpdateCustomerContact -- creates a customer contact
export const Customers_UpdateCustomerContactPermission = "Customers_UpdateCustomerContact";
// Customers_UpdateCustomerEvent -- creates a customer event
export const Customers_UpdateCustomerEventPermission = "Customers_UpdateCustomerEvent";
// Customers_View -- View Customers
export const Customers_ViewPermission = "Customers_View";
// Files_GetFileFromID -- gets a file by its ID
export const Files_GetFileFromIDPermission = "Files_GetFileFromID";
// Files_GetFileGroupURLsFromID -- returns a set of URLs by their file group ID
export const Files_GetFileGroupURLsFromIDPermission = "Files_GetFileGroupURLsFromID";
// Files_GetFileQueueFromID -- returns a fileQueue by its ID
export const Files_GetFileQueueFromIDPermission = "Files_GetFileQueueFromID";
// Files_GetFilesFromObject -- returns a set of files by their object
export const Files_GetFilesFromObjectPermission = "Files_GetFilesFromObject";
// Files_View -- View Files
export const Files_ViewPermission = "Files_View";
// Forms_CreateForm -- creates a form
export const Forms_CreateFormPermission = "Forms_CreateForm";
// Forms_DeleteForm -- deletes a form
export const Forms_DeleteFormPermission = "Forms_DeleteForm";
// Forms_ExportForm -- exports a json file containing a form
export const Forms_ExportFormPermission = "Forms_ExportForm";
// Forms_GenerateRandomSubmissions -- generates n random form submissions
export const Forms_GenerateRandomSubmissionsPermission = "Forms_GenerateRandomSubmissions";
// Forms_GetFormData -- gets data submitted to a form
export const Forms_GetFormDataPermission = "Forms_GetFormData";
// Forms_GetFormFromID -- returns a form by its id
export const Forms_GetFormFromIDPermission = "Forms_GetFormFromID";
// Forms_GetFormSubmissionFromID -- gets a specific form submission
export const Forms_GetFormSubmissionFromIDPermission = "Forms_GetFormSubmissionFromID";
// Forms_GetForms -- returns a slice of forms
export const Forms_GetFormsPermission = "Forms_GetForms";
// Forms_ImportForm -- imports a form from a json file
export const Forms_ImportFormPermission = "Forms_ImportForm";
// Forms_SubmitForm -- submits a form
export const Forms_SubmitFormPermission = "Forms_SubmitForm";
// Forms_UpdateForm -- updates a form
export const Forms_UpdateFormPermission = "Forms_UpdateForm";
// Forms_View -- View Forms
export const Forms_ViewPermission = "Forms_View";
// IPs_BlockIP -- blocks an IP address
export const IPs_BlockIPPermission = "IPs_BlockIP";
// IPs_GetIPs -- returns a collection of IP addresses
export const IPs_GetIPsPermission = "IPs_GetIPs";
// IPs_UnBlockIP -- blocks an IP address
export const IPs_UnBlockIPPermission = "IPs_UnBlockIP";
// IPs_View -- View IPs
export const IPs_ViewPermission = "IPs_View";
// Identity_View -- View Identity
export const Identity_ViewPermission = "Identity_View";
// JobSearch_View -- View job search
export const JobSearch_ViewPermission = "JobSearch_View";
// Job_CanManageCloseout -- View and manage the Closeout section in the Job Detail View
export const Job_CanManageCloseoutPermission = "Job_CanManageCloseout";
// Job_CanManageLogs -- View and manage logs in Job Detail View
export const Job_CanManageLogsPermission = "Job_CanManageLogs";
// Job_CanManageTIPTab -- View and Manage TIP in Job Detail View
export const Job_CanManageTIPTabPermission = "Job_CanManageTIPTab";
// Job_CanManageTasks -- View and Manage Tasks in Job Detail View
export const Job_CanManageTasksPermission = "Job_CanManageTasks";
// Job_EditHouseAccount -- edits house account sales
export const Job_EditHouseAccount = "Job_EditHouseAccount"
// Jobs_CreateJob -- creates a job
export const Jobs_CreateJobPermission = "Jobs_CreateJob";
// Jobs_CreateJobSearch -- creates a job search
export const Jobs_CreateJobSearchPermission = "Jobs_CreateJobSearch";
// Jobs_DeleteJob -- deletes a job
export const Jobs_DeleteJobPermission = "Jobs_DeleteJob";
// Jobs_DeleteJobSearch -- deletes a quote search
export const Jobs_DeleteJobSearchPermission = "Jobs_DeleteJobSearch";
// Jobs_DownloadJobExcel -- downloads an excel document
export const Jobs_DownloadJobExcelPermission = "Jobs_DownloadJobExcel";
// Jobs_DownloadJobsExcel -- downloads an excel document
export const Jobs_DownloadJobsExcelPermission = "Jobs_DownloadJobsExcel";
// Jobs_DownloadJobsPDF -- downloads a pdf document
export const Jobs_DownloadJobsPDFPermission = "Jobs_DownloadJobsPDF";
// Jobs_GetDefaultJobSearch -- returns the default quote search for the current user
export const Jobs_GetDefaultJobSearchPermission = "Jobs_GetDefaultJobSearch";
// Jobs_GetJobDatesForCalendar -- gets job dates for calendar
export const Jobs_GetJobDatesForCalendarPermission = "Jobs_GetJobDatesForCalendar";
// Jobs_GetJobFromID -- gets a job from its ID
export const Jobs_GetJobFromIDPermission = "Jobs_GetJobFromID";
// Jobs_GetJobSavedSearches -- gets a collection of job saved searches by user
export const Jobs_GetJobSavedSearchesPermission = "Jobs_GetJobSavedSearches";
// Jobs_GetJobs -- gets a collection of jobs
export const Jobs_GetJobsPermission = "Jobs_GetJobs";
// Jobs_GetJobsFromSavedSearch -- gets a collection of jobs from a saved search
export const Jobs_GetJobsFromSavedSearchPermission = "Jobs_GetJobsFromSavedSearch";
// Jobs_SearchJobs -- searches for jobs by their name
export const Jobs_SearchJobsPermission = "Jobs_SearchJobs";
// Jobs_UpdateJob -- updates a job
export const Jobs_UpdateJobPermission = "Jobs_UpdateJob";
// Jobs_UpdateJobSearch -- updates a quote search
export const Jobs_UpdateJobSearchPermission = "Jobs_UpdateJobSearch";
// Jobs_View -- View Jobs
export const Jobs_ViewPermission = "Jobs_View";
// Join_View -- View Join
export const Join_ViewPermission = "Join_View";
// Lists_CreateList -- creates a list
export const Lists_CreateListPermission = "Lists_CreateList";
// Lists_CreateListItem -- creates a list item
export const Lists_CreateListItemPermission = "Lists_CreateListItem";
// Lists_DeleteList -- deletes a company
export const Lists_DeleteListPermission = "Lists_DeleteList";
// Lists_DeleteListItem -- deletes a list item
export const Lists_DeleteListItemPermission = "Lists_DeleteListItem";
// Lists_ExportList -- exports a json file containing a list and all its elements
export const Lists_ExportListPermission = "Lists_ExportList";
// Lists_GetListFromID -- gets a list by its ID
export const Lists_GetListFromIDPermission = "Lists_GetListFromID";
// Lists_GetListFromListType -- gets a list by its type
export const Lists_GetListFromListTypePermission = "Lists_GetListFromListType";
// Lists_GetLists -- returns a collection of lists
export const Lists_GetListsPermission = "Lists_GetLists";
// Lists_ImportList -- imports policies and roles from a json file
export const Lists_ImportListPermission = "Lists_ImportList";
// Lists_ResetCacheForList -- gets a list by its ID
export const Lists_ResetCacheForListPermission = "Lists_ResetCacheForList";
// Lists_SetItemOrdinal -- creates a list item
export const Lists_SetItemOrdinalPermission = "Lists_SetItemOrdinal";
// Lists_UpdateList -- updates a list
export const Lists_UpdateListPermission = "Lists_UpdateList";
// Lists_UpdateListItem -- creates a list item
export const Lists_UpdateListItemPermission = "Lists_UpdateListItem";
// Lists_View -- View Lists
export const Lists_ViewPermission = "Lists_View";
// MainMenu_ViewPermission -- view the main menu
export const MainMenu_ViewPermission = "MainMenu_View"; 
// Notifications_GetNotificationsByUser -- 
export const Notifications_GetNotificationsByUserPermission = "Notifications_GetNotificationsByUser";
// Notifications_View -- View Notifications
export const Notifications_ViewPermission = "Notifications_View";
// ObjectTodos_CompleteOthersTasks -- Complete a task assigned to someone else
export const ObjectTodos_CompleteOthersTasksPermission = "ObjectTodos_CompleteOthersTasks";
// ObjectTodos_CreateTodo -- creates a todo
export const ObjectTodos_CreateTodoPermission = "ObjectTodos_CreateTodo";
// ObjectTodos_DeleteTodo -- deletes a todo
export const ObjectTodos_DeleteTodoPermission = "ObjectTodos_DeleteTodo";
// ObjectTodos_GetAllTodos -- gets all todos
export const ObjectTodos_GetAllTodosPermission = "ObjectTodos_GetAllTodos";
// ObjectTodos_GetMyTodos -- gets todos assigned to the current person
export const ObjectTodos_GetMyTodosPermission = "ObjectTodos_GetMyTodos";
// ObjectTodos_MarkTodoAsComplete -- marks a todo as complete
export const ObjectTodos_MarkTodoAsCompletePermission = "ObjectTodos_MarkTodoAsComplete";
// ObjectTodos_MarkTodoAsInComplete -- marks a todo as complete
export const ObjectTodos_MarkTodoAsInCompletePermission = "ObjectTodos_MarkTodoAsInComplete";
// ObjectTodos_UpdateTodo -- updates a todo
export const ObjectTodos_UpdateTodoPermission = "ObjectTodos_UpdateTodo";
// ObjectTodos_View -- View ObjectTodos
export const ObjectTodos_ViewPermission = "ObjectTodos_View";
// ObjectTodos_ViewMyTodos -- View my own todos
export const ObjectTodos_ViewMyTodosPermission = "ObjectTodos_ViewMyTodos";
// PasswordReset_View -- View PasswordReset
export const PasswordReset_ViewPermission = "PasswordReset_View";
// Payments_View -- View Payments
export const Payments_ViewPermission = "Payments_View";
// Permissions_GetAllPermissions -- returns a slice of permissions
export const Permissions_GetAllPermissionsPermission = "Permissions_GetAllPermissions";
// Permissions_View -- View Permissions
export const Permissions_ViewPermission = "Permissions_View";
// Ping_View -- View Ping
export const Ping_ViewPermission = "Ping_View";
// Policies_AddPermissionToPolicy -- adds a permission to a policy
export const Policies_AddPermissionToPolicyPermission = "Policies_AddPermissionToPolicy";
// Policies_CreatePolicy -- creates a policy
export const Policies_CreatePolicyPermission = "Policies_CreatePolicy";
// Policies_DeletePolicy -- deletes a policy
export const Policies_DeletePolicyPermission = "Policies_DeletePolicy";
// Policies_ExportPolicies -- exports a json file containing all policies, their permissions and roles
export const Policies_ExportPoliciesPermission = "Policies_ExportPolicies";
// Policies_GetAllPolicies -- returns a slice of policies
export const Policies_GetAllPoliciesPermission = "Policies_GetAllPolicies";
// Policies_GetPolicyFromID -- returns a policy from its ID
export const Policies_GetPolicyFromIDPermission = "Policies_GetPolicyFromID";
// Policies_ImportPolicies -- imports policies and roles from a json file
export const Policies_ImportPoliciesPermission = "Policies_ImportPolicies";
// Policies_RemovePermissionFromPolicy -- removes a permission from a policy
export const Policies_RemovePermissionFromPolicyPermission = "Policies_RemovePermissionFromPolicy";
// Policies_UpdatePolicy -- updates a policy
export const Policies_UpdatePolicyPermission = "Policies_UpdatePolicy";
// Policies_View -- View Policies
export const Policies_ViewPermission = "Policies_View";
// Profiles_GetProfileFromID -- gets a profile from its id
export const Profiles_GetProfileFromIDPermission = "Profiles_GetProfileFromID";
// Profiles_UpdateProfile -- gets a profile from its id
export const Profiles_UpdateProfilePermission = "Profiles_UpdateProfile";
// Profiles_View -- View Profiles
export const Profiles_ViewPermission = "Profiles_View";
// QuoteSearch_View -- View quote search
export const QuoteSearch_ViewPermission = "QuoteSearch_View";
// Quotes_CanManageLogs -- View and manage logs in Quote Detail View
export const Quotes_CanManageLogsPermission = "Quotes_CanManageLogs";
// Quotes_CreateQuote -- creates a quote
export const Quotes_CreateQuotePermission = "Quotes_CreateQuote";
// Quotes_CreateQuoteItem -- adds an item to a quote
export const Quotes_CreateQuoteItemPermission = "Quotes_CreateQuoteItem";
// Quotes_CreateQuoteRevision -- creates a new quote revision
export const Quotes_CreateQuoteRevisionPermission = "Quotes_CreateQuoteRevision";
// Quotes_CreateQuoteSearch -- creates a quote search
export const Quotes_CreateQuoteSearchPermission = "Quotes_CreateQuoteSearch";
// Quotes_DeleteQuote -- deletes a quote
export const Quotes_DeleteQuotePermission = "Quotes_DeleteQuote";
// Quotes_DeleteQuoteItem -- deletes an item on a quote
export const Quotes_DeleteQuoteItemPermission = "Quotes_DeleteQuoteItem";
// Quotes_DeleteQuoteSearch -- deletes a quote search
export const Quotes_DeleteQuoteSearchPermission = "Quotes_DeleteQuoteSearch";
// Quotes_DownloadQuotesExcel -- downloads an excel document
export const Quotes_DownloadQuotesExcelPermission = "Quotes_DownloadQuotesExcel";
// Quotes_DownloadQuotesPDF -- downloads a pdf document
export const Quotes_DownloadQuotesPDFPermission = "Quotes_DownloadQuotesPDF";
// Quotes_GetBiddingInTheFuture -- gets bidding in the future
export const Quotes_GetBiddingInTheFuturePermission = "Quotes_GetBiddingInTheFuture";
// Quotes_GetDefaultQuoteSearch -- returns the default quote search for the current user
export const Quotes_GetDefaultQuoteSearchPermission = "Quotes_GetDefaultQuoteSearch";
// Quotes_GetQuoteFromID -- gets a quote from its ID
export const Quotes_GetQuoteFromIDPermission = "Quotes_GetQuoteFromID";
// Quotes_GetQuoteRevisions -- returns a list of quote revisions
export const Quotes_GetQuoteRevisionsPermission = "Quotes_GetQuoteRevisions";
// Quotes_GetQuoteSearches -- gets a collection of quoteSearches
export const Quotes_GetQuoteSearchesPermission = "Quotes_GetQuoteSearches";
// Quotes_GetQuotes -- gets a collection of quotes
export const Quotes_GetQuotesPermission = "Quotes_GetQuotes";
// Quotes_GetQuotesFromSavedSearch -- gets a collection of quotes saved
export const Quotes_GetQuotesFromSavedSearchPermission = "Quotes_GetQuotesFromSavedSearch";
// Quotes_QuotesBiddingInTheFutureDownloadExcel -- downloads an excel document
export const Quotes_QuotesBiddingInTheFutureDownloadExcelPermission = "Quotes_QuotesBiddingInTheFutureDownloadExcel";
// Quotes_QuotesBiddingInTheFutureDownloadPDF -- downloads a pdf document
export const Quotes_QuotesBiddingInTheFutureDownloadPDFPermission = "Quotes_QuotesBiddingInTheFutureDownloadPDF";
// Quotes_SearchQuotes -- returns a list of quotes by searching
export const Quotes_SearchQuotesPermission = "Quotes_SearchQuotes";
// Quotes_UpdateQuote -- updates a quote
export const Quotes_UpdateQuotePermission = "Quotes_UpdateQuote";
// Quotes_UpdateQuoteItem -- updates an item on a quote
export const Quotes_UpdateQuoteItemPermission = "Quotes_UpdateQuoteItem";
// Quotes_UpdateQuoteSearch -- updates a quote search
export const Quotes_UpdateQuoteSearchPermission = "Quotes_UpdateQuoteSearch";
// Quotes_View -- View Quotes
export const Quotes_ViewPermission = "Quotes_View";
// ReCaptcha_View -- View ReCaptcha
export const ReCaptcha_ViewPermission = "ReCaptcha_View";
// Reports_DownloadSalesActivityReportExcel -- downloads a SalesActivityReport excel document
export const Reports_DownloadSalesActivityReportExcelPermission = "Reports_DownloadSalesActivityReportExcel";
// Reports_DownloadSalesActivityReportPdf -- downloads a SalesActivityReport pdf document
export const Reports_DownloadSalesActivityReportPDFPermission = "Reports_DownloadSalesActivityReportPDF";
// Reports_DownloadSummaryReportExcel -- downloads the summary report in excel 
export const Reports_DownloadSummaryReportExcelPermission = "Reports_DownloadSummaryReportExcel";
// Reports_DownloadSummaryReportPdf -- downloads the summary report in pdf
export const Reports_DownloadSummaryReportPDFPermission = "Reports_DownloadSummaryReportPDF";
// Reports_GetSalesActivityReport -- gets a report
export const Reports_GetSalesActivityReportPermission = "Reports_GetSalesActivityReport";
// Reports_GetSummaryReport -- gets a report
export const Reports_GetSummaryReportPermission = "Reports_GetSummaryReport";
// Reports_View -- View Reports
export const Reports_ViewPermission = "Reports_View";
// Roles_AddPolicyToRole -- adds a permission to a role
export const Roles_AddPolicyToRolePermission = "Roles_AddPolicyToRole";
// Roles_CreateRole -- creates a role
export const Roles_CreateRolePermission = "Roles_CreateRole";
// Roles_DeleteRole -- deletes a role
export const Roles_DeleteRolePermission = "Roles_DeleteRole";
// Roles_GetRoleFromID -- returns a role
export const Roles_GetRoleFromIDPermission = "Roles_GetRoleFromID";
// Roles_GetRoles -- returns a slice of roles
export const Roles_GetRolesPermission = "Roles_GetRoles";
// Roles_GetRolesByPermission -- returns a slice of roles attached to a policy that contains a permission
export const Roles_GetRolesByPermissionPermission = "Roles_GetRolesByPermission";
// Roles_GetRolesByPolicy -- returns a slice of roles attached to a policy
export const Roles_GetRolesByPolicyPermission = "Roles_GetRolesByPolicy";
// Roles_RemovePolicyFromRole -- removes a permission from a role
export const Roles_RemovePolicyFromRolePermission = "Roles_RemovePolicyFromRole";
// Roles_UpdateRole -- updates a role
export const Roles_UpdateRolePermission = "Roles_UpdateRole";
// Roles_View -- View Roles
export const Roles_ViewPermission = "Roles_View";
// Self_UpdateUser -- updates a user
export const Self_UpdateUserPermission = "Self_UpdateUser";
// Self_View -- View Self
export const Self_ViewPermission = "Self_View";
// Settings_View -- View Settings
export const Settings_ViewPermission = "Settings_View";
// Status_View -- View Status
export const Status_ViewPermission = "Status_View";
// Summary_ViewJobStatsForAllSales -- View job stats for all sales
export const Summary_ViewJobStatsForAllSalesPermission = "Summary_ViewJobStatsForAllSales";
// Tasks_CreateTaskBatch -- creates a task batch
export const Tasks_CreateTaskBatchPermission = "Tasks_CreateTaskBatch";
// Tasks_CreateTaskBatchSchedule -- creates a task batch
export const Tasks_CreateTaskBatchSchedulePermission = "Tasks_CreateTaskBatchSchedule";
// Tasks_CreateTaskBatchTask -- creates a task in a batch
export const Tasks_CreateTaskBatchTaskPermission = "Tasks_CreateTaskBatchTask";
// Tasks_DeleteTaskBatch -- deletes a task batch
export const Tasks_DeleteTaskBatchPermission = "Tasks_DeleteTaskBatch";
// Tasks_DeleteTaskBatchSchedule -- deletes a task batch schedule
export const Tasks_DeleteTaskBatchSchedulePermission = "Tasks_DeleteTaskBatchSchedule";
// Tasks_DeleteTaskBatchTask -- deletes a task batch schedule
export const Tasks_DeleteTaskBatchTaskPermission = "Tasks_DeleteTaskBatchTask";
// Tasks_GetTaskBatchFromID -- gets a task batch from ID
export const Tasks_GetTaskBatchFromIDPermission = "Tasks_GetTaskBatchFromID";
// Tasks_GetTaskBatchLogsFromTaskBatch -- gets a collection of task batches
export const Tasks_GetTaskBatchLogsFromTaskBatchPermission = "Tasks_GetTaskBatchLogsFromTaskBatch";
// Tasks_GetTaskBatchSchedulesByTaskBatch -- gets a collection of task batches schedules
export const Tasks_GetTaskBatchSchedulesByTaskBatchPermission = "Tasks_GetTaskBatchSchedulesByTaskBatch";
// Tasks_GetTaskBatchTasksByTaskBatch -- gets a collection of task batch tasks
export const Tasks_GetTaskBatchTasksByTaskBatchPermission = "Tasks_GetTaskBatchTasksByTaskBatch";
// Tasks_GetTaskBatches -- gets a collection of task batches
export const Tasks_GetTaskBatchesPermission = "Tasks_GetTaskBatches";
// Tasks_GetTaskLogStepsFromTaskLog -- gets a collection of task log steps by task log
export const Tasks_GetTaskLogStepsFromTaskLogPermission = "Tasks_GetTaskLogStepsFromTaskLog";
// Tasks_GetTaskLogsFromTaskBatchLog -- gets a collection of task batches
export const Tasks_GetTaskLogsFromTaskBatchLogPermission = "Tasks_GetTaskLogsFromTaskBatchLog";
// Tasks_UpdateTaskBatch -- updates a task batch
export const Tasks_UpdateTaskBatchPermission = "Tasks_UpdateTaskBatch";
// Tasks_UpdateTaskBatchSchedule -- updates a task batch schedule
export const Tasks_UpdateTaskBatchSchedulePermission = "Tasks_UpdateTaskBatchSchedule";
// Tasks_UpdateTaskBatchTask -- updates a task batch task
export const Tasks_UpdateTaskBatchTaskPermission = "Tasks_UpdateTaskBatchTask";
// Tasks_View -- View Tasks
export const Tasks_ViewPermission = "Tasks_View";
// TwoFactor_View -- View TwoFactor
export const TwoFactor_ViewPermission = "TwoFactor_View";
// UserGroups_AddPolicyToGroup -- adds a policy to a group
export const UserGroups_AddPolicyToGroupPermission = "UserGroups_AddPolicyToGroup";
// UserGroups_CreateUserGroup -- creates a user
export const UserGroups_CreateUserGroupPermission = "UserGroups_CreateUserGroup";
// UserGroups_DeleteUserGroup -- deletes a user group
export const UserGroups_DeleteUserGroupPermission = "UserGroups_DeleteUserGroup";
// UserGroups_GetUserGroup -- returns a user group by its id
export const UserGroups_GetUserGroupPermission = "UserGroups_GetUserGroup";
// UserGroups_GetUserGroups -- returns a collection of user groups
export const UserGroups_GetUserGroupsPermission = "UserGroups_GetUserGroups";
// UserGroups_RemovePolicyFromGroup -- removes a policy from a group
export const UserGroups_RemovePolicyFromGroupPermission = "UserGroups_RemovePolicyFromGroup";
// UserGroups_UpdateUserGroup -- updates a user group
export const UserGroups_UpdateUserGroupPermission = "UserGroups_UpdateUserGroup";
// UserGroups_View -- View UserGroups
export const UserGroups_ViewPermission = "UserGroups_View";
// Users_ActivateUser -- activates a user without email activation
export const Users_ActivateUserPermission = "Users_ActivateUser";
// Users_AddPolicyToUser -- adds a policy to a user
export const Users_AddPolicyToUserPermission = "Users_AddPolicyToUser";
// Users_AddRoleToUser -- adds a role to a user
export const Users_AddRoleToUserPermission = "Users_AddRoleToUser";
// Users_AddUserToGroup -- adds a User to a UserGroup
export const Users_AddUserToGroupPermission = "Users_AddUserToGroup";
// Users_CreateUser -- creates a user
export const Users_CreateUserPermission = "Users_CreateUser";
// Users_DeleteUser -- deletes a user
export const Users_DeleteUserPermission = "Users_DeleteUser";
// Users_DisableUser -- disables a user
export const Users_DisableUserPermission = "Users_DisableUser";
// Users_EnableUser -- enables a user
export const Users_EnableUserPermission = "Users_EnableUser";
// Users_GetAllUsers -- gets a collection of users across all accounts
export const Users_GetAllUsersPermission = "Users_GetAllUsers";
// Users_GetUserDevices -- returns a slice of devices associated with a user
export const Users_GetUserDevicesPermission = "Users_GetUserDevices";
// Users_GetUserEventLogs -- gets a users event logs
export const Users_GetUserEventLogsPermission = "Users_GetUserEventLogs";
// Users_GetUserFromID -- gets a user from their ID
export const Users_GetUserFromIDPermission = "Users_GetUserFromID";
// Users_GetUserResetPasswordLink -- returns a link for a user to reset their password
export const Users_GetUserResetPasswordLinkPermission = "Users_GetUserResetPasswordLink";
// Users_GetUserResetPasswordLinkForUserOnAnotherAccount -- Generate a password link for a user on another account
export const Users_GetUserResetPasswordLinkForUserOnAnotherAccountPermission = "Users_GetUserResetPasswordLinkForUserOnAnotherAccount";
// Users_GetUserSession -- returns the session information for another user to impersonate
export const Users_GetUserSessionPermission = "Users_GetUserSession";
// Users_GetUserSettings -- gets a users settings
export const Users_GetUserSettingsPermission = "Users_GetUserSettings";
// Users_LockUser -- disables a user
export const Users_LockUserPermission = "Users_LockUser";
// Users_ManageUsersFromAnotherAccount -- Manage users from another account
export const Users_ManageUsersFromAnotherAccountPermission = "Users_ManageUsersFromAnotherAccount";
// Users_RemovePolicyFromUser -- removes a policy from a group
export const Users_RemovePolicyFromUserPermission = "Users_RemovePolicyFromUser";
// Users_RemoveRoleFromUser -- removes a role from a user
export const Users_RemoveRoleFromUserPermission = "Users_RemoveRoleFromUser";
// Users_RemoveUserFromGroup -- removes a user from a group
export const Users_RemoveUserFromGroupPermission = "Users_RemoveUserFromGroup";
// Users_SearchUsersByEmail -- searches user accounts by email
export const Users_SearchUsersByEmailPermission = "Users_SearchUsersByEmail";
// Users_SearchUsersByName -- searches users by their name
export const Users_SearchUsersByNamePermission = "Users_SearchUsersByName";
// Users_SetUserPassword -- sets a users password
export const Users_SetUserPasswordPermission = "Users_SetUserPassword";
// Users_ToggleTwoFactor -- toggles two factor auth for a user
export const Users_ToggleTwoFactorPermission = "Users_ToggleTwoFactor";
// Users_UnlockUser -- unlocks a user
export const Users_UnlockUserPermission = "Users_UnlockUser";
// Users_UpdateUser -- updates a user
export const Users_UpdateUserPermission = "Users_UpdateUser";
// Users_UpdateUserPhoneNumber -- updates the phone number for a user
export const Users_UpdateUserPhoneNumberPermission = "Users_UpdateUserPhoneNumber";
// Users_UpdateUserProfile -- updates a user profile
export const Users_UpdateUserProfilePermission = "Users_UpdateUserProfile";
// Users_UpdateUserSettings -- updates a user settings
export const Users_UpdateUserSettingsPermission = "Users_UpdateUserSettings";
// Users_View -- View Users
export const Users_ViewPermission = "Users_View";
// Values_CreateFiscalPeriod -- does a thing
export const Values_CreateFiscalPeriodPermission = "Values_CreateFiscalPeriod";
// Values_CreateFiscalYear -- does a thing
export const Values_CreateFiscalYearPermission = "Values_CreateFiscalYear";
// Values_CreateSysVal -- creates a sysVal
export const Values_CreateSysValPermission = "Values_CreateSysVal";
// Values_CreateSysValsForYear -- creates sysVals for a year
export const Values_CreateSysValsForYearPermission = "Values_CreateSysValsForYear";
// Values_DeleteFiscalPeriod -- does a thing
export const Values_DeleteFiscalPeriodPermission = "Values_DeleteFiscalPeriod";
// Values_DeleteFiscalYear -- does a thing
export const Values_DeleteFiscalYearPermission = "Values_DeleteFiscalYear";
// Values_DeleteSysVal -- deletes a sysVal
export const Values_DeleteSysValPermission = "Values_DeleteSysVal";
// Values_GetFiscalPeriods -- does a thing
export const Values_GetFiscalPeriodsPermission = "Values_GetFiscalPeriods";
// Values_GetFiscalYears -- does a thing
export const Values_GetFiscalYearsPermission = "Values_GetFiscalYears";
// Values_GetSysVals -- gets sys vals
export const Values_GetSysValsPermission = "Values_GetSysVals";
// Values_LockFiscalYear -- does a thing
export const Values_LockFiscalYearPermission = "Values_LockFiscalYear";
// Values_UnLockFiscalYear -- does a thing
export const Values_UnLockFiscalYearPermission = "Values_UnLockFiscalYear";
// Values_UpdateFiscalPeriod -- does a thing
export const Values_UpdateFiscalPeriodPermission = "Values_UpdateFiscalPeriod";
// Values_UpdateFiscalYear -- does a thing
export const Values_UpdateFiscalYearPermission = "Values_UpdateFiscalYear";
// Values_UpdateSysVal -- updates a sysVal
export const Values_UpdateSysValPermission = "Values_UpdateSysVal";
// Values_View -- View Values
export const Values_ViewPermission = "Values_View";
// Vendors_CreateVendor -- creates a vendor
export const Vendors_CreateVendorPermission = "Vendors_CreateVendor";
// Vendors_CreateVendorItem -- creates a vendor item
export const Vendors_CreateVendorItemPermission = "Vendors_CreateVendorItem";
// Vendors_DeleteVendor -- deletes a vendor
export const Vendors_DeleteVendorPermission = "Vendors_DeleteVendor";
// Vendors_DeleteVendorItem -- deletes a vendorItem
export const Vendors_DeleteVendorItemPermission = "Vendors_DeleteVendorItem";
// Vendors_GetVendorFromID -- gets a customer by its ID
export const Vendors_GetVendorFromIDPermission = "Vendors_GetVendorFromID";
// Vendors_GetVendorFromIDs -- gets a customer by its ID
export const Vendors_GetVendorFromIDsPermission = "Vendors_GetVendorFromIDs";
// Vendors_GetVendorItemFromID -- returns a vendor item from its ID
export const Vendors_GetVendorItemFromIDPermission = "Vendors_GetVendorItemFromID";
// Vendors_GetVendorItems -- returns a slice of vendor items
export const Vendors_GetVendorItemsPermission = "Vendors_GetVendorItems";
// Vendors_GetVendorItemsList -- gets a list of vendorItems for all vendors
export const Vendors_GetVendorItemsListPermission = "Vendors_GetVendorItemsList";
// Vendors_GetVendors -- returns a collection of vendors
export const Vendors_GetVendorsPermission = "Vendors_GetVendors";
// Vendors_SearchVendorItemsByModelNumber -- returns a list of vendor items based on a model number search
export const Vendors_SearchVendorItemsByModelNumberPermission = "Vendors_SearchVendorItemsByModelNumber";
// Vendors_SearchVendorsByTitle -- returns a list of vendors based on a title search
export const Vendors_SearchVendorsByTitlePermission = "Vendors_SearchVendorsByTitle";
// Vendors_UpdateVendor -- updates a vendor
export const Vendors_UpdateVendorPermission = "Vendors_UpdateVendor";
// Vendors_UpdateVendorItem -- updates a vendor item
export const Vendors_UpdateVendorItemPermission = "Vendors_UpdateVendorItem";
// Vendors_View -- View Vendors
export const Vendors_ViewPermission = "Vendors_View";
// Verify_View -- View Verify
export const Verify_ViewPermission = "Verify_View";
// Version_View -- View Version
export const Version_ViewPermission = "Version_View";