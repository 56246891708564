import CustomerDetail from "app/pages/customers/CustomerDetail";
import CustomerList from "app/pages/customers/CustomerList";
import JobDetail from "app/pages/jobs/JobDetail";
import JobsCalendar from "app/pages/jobs/JobsCalendar";
import JobsList from "app/pages/jobs/JobsList";
import QuoteDetail from "app/pages/quotes/detail/QuoteDetail";
import QuoteList from "app/pages/quotes/QuoteList";
import BiddingInTheFutureReport from "app/pages/reports/BiddingInTheFutureReport";
import SalesActivityReport from "app/pages/reports/SalesActivityReport";
import SummaryReport from "app/pages/reports/SummaryReport";
import FiscalYears from "app/pages/sysvals/FiscalYears";
// App 
import SysVals from "app/pages/sysvals/SysVals";
import VendorDetail from "app/pages/vendors/VendorDetail";
import VendorList from "app/pages/vendors/VendorList";
import MyTodos from "core/pages/todos/MyTodos";
import { IRoute } from "core/utils/routes";
import { BiddingInTheFuture_ViewPermission, Customers_ViewPermission, Jobs_GetJobDatesForCalendarPermission, Jobs_ViewPermission, ObjectTodos_ViewMyTodosPermission, Quotes_ViewPermission, Reports_GetSalesActivityReportPermission, Reports_GetSummaryReportPermission, Values_ViewPermission, Vendors_ViewPermission } from "gen/constants/permissions";
export const RouteLogin = "/login"; 

const routes : IRoute[] = [
    { path: "/quotes/:id", Component: QuoteDetail, private: true, permission: Quotes_ViewPermission, }, 
    { path: "/quotes", Component: QuoteList, private: true,  permission: Quotes_ViewPermission, },
    { path: "/jobs/calendar", Component: JobsCalendar, private: true,  permission: Jobs_GetJobDatesForCalendarPermission, },
    { path: "/jobs/:id", Component: JobDetail, private: true, permission: Jobs_ViewPermission, }, 
    { path: "/jobs", Component: JobsList, private: true, permission: Jobs_ViewPermission, }, 
    { path: "/customers/:id", Component: CustomerDetail, private: true,  permission: Customers_ViewPermission, },
    { path: "/customers", Component: CustomerList, private: true,  permission: Customers_ViewPermission, },
    { path: "/vendors/:id", Component: VendorDetail, private: true,  permission: Vendors_ViewPermission, },
    { path: "/vendors", Component: VendorList, private: true,  permission: Vendors_ViewPermission, },
    { path: "/values/sysvals", Component: SysVals, private: true,  permission: Values_ViewPermission, },
    { path: "/values/calendar", Component: FiscalYears, private: true,  permission: Values_ViewPermission, },

    { path: "/reports/summary", Component: SummaryReport, private: true, permission: Reports_GetSummaryReportPermission, }, 
    { path: "/reports/bidding-in-the-future", Component: BiddingInTheFutureReport, private: true, permission: BiddingInTheFuture_ViewPermission, }, 
    { path: "/reports/sales-activity", Component: SalesActivityReport, private: true, permission: Reports_GetSalesActivityReportPermission, }, 
    { path: "/todos", Component: MyTodos, private: true, permission: ObjectTodos_ViewMyTodosPermission }, 

    { path: "/", Component: QuoteList, private: true,  permission: Quotes_ViewPermission, },
  ]; 
  
  export default routes; 

  