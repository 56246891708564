import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, TextField } from '@material-ui/core';
import { useUpdatePolicy } from 'gen/routes/Policies';
import handleNetworkError from 'core/utils/handleNetworkError';
import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { editPolicyAtom } from './state';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const UpdatePolicyDialog = () => {
    
    const resetPolicy = useResetRecoilState(editPolicyAtom); 
    const [policy, setPolicy] = useRecoilState(editPolicyAtom);
    const [updatePolicy, { error, status }] = useUpdatePolicy(); 
    
    const save = () => updatePolicy({ policyID: policy.PolicyID, body: policy }) 
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(status === "success") {
            close(); 
        }
    }, [status]); 

    const close = () => {
        resetPolicy(); 
    }

    if(policy.PolicyID === 0) {
        return null; 
    }

    return <Dialog open={policy.PolicyID > 0} onBackdropClick={() => close()}>
        
        <DialogTitle>Edit Policy</DialogTitle>
        
        <DialogContent>
            
            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }

            <StyledFormControl>
                <InputLabel>Short Name</InputLabel>
                <Input type="text" name="Name" value={policy.ShortName} onChange={(e) => setPolicy({ ...policy, ShortName: e.target.value })} /> 
            </StyledFormControl>

            <TextField
                multiline
                fullWidth
                rows={5}
                rowsMax={5}
                value={policy.Description}
                onChange={(e) => setPolicy({ ...policy, Description: e.target.value })}
                label="Description"
            /> 
            
        </DialogContent>
        <DialogActions>

            <Button variant="contained" onClick={save} disabled={status === "loading"}>
                
                Save

                {status === "loading" &&
                    <CircularProgress size={20} /> 
                }

            </Button>

            <Button onClick={close} disabled={status === "loading"}>cancel</Button>
            
        </DialogActions>

    </Dialog>
}

export default UpdatePolicyDialog; 