import { List, ListItem, ListItemText } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { Perm } from 'core/utils/hasPerm';
import { Accounts_ViewPermission, Activity_ViewPermission, AdminDB_ViewPermission, AdminSettings_ViewPermission, Forms_ViewPermission, IPs_ViewPermission, Lists_ViewPermission, Policies_ViewPermission, Roles_ViewPermission, Tasks_ViewPermission, UserGroups_ViewPermission, Users_GetUserEventLogsPermission, Users_ViewPermission } from 'gen/constants/permissions';
import React from 'react';
import { useHistory } from 'react-router';
import AdminHeader from './common/AdminHeader';

const Admin = () => { 

    const history = useHistory(); 
    
    const adminItems : { path: string; title: string; permission: string }[] = [ 
        { path: `/admin/accounts`, title: "Accounts", permission: Accounts_ViewPermission }, 
        { path: `/admin/activity`, title: "Activity", permission: Activity_ViewPermission }, 
        { path: `/admin/databases`, title: "Databases", permission: AdminDB_ViewPermission }, 
        { path: `/admin/forms`, title: "Forms", permission: Forms_ViewPermission }, 
        { path: `/admin/activity/ips`, title: "IPs", permission: IPs_ViewPermission }, 
        { path: `/admin/lists`, title: "Lists", permission: Lists_ViewPermission }, 
        { path: `/admin/policies`, title: "Policies", permission: Policies_ViewPermission }, 
        { path: `/admin/roles`, title: "Roles", permission: Roles_ViewPermission }, 
        { path: `/admin/settings`, title: "Settings", permission: AdminSettings_ViewPermission }, 
        { path: `/admin/tasks`, title: "Tasks", permission: Tasks_ViewPermission }, 
        { path: `/admin/users`, title: "Users", permission: Users_ViewPermission }, 
        { path: `/admin/userEvents`, title: "User Events", permission: Users_GetUserEventLogsPermission }, 
        { path: `/admin/groups`, title: "User Groups", permission: UserGroups_ViewPermission }, 
    ]


    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>
            <NavBarSecondary>
                <AdminHeader title={""} />
            </NavBarSecondary>
            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
                <List>
                    
                    {adminItems.map((item, k) => (
                        <Perm perm={item.permission} key={k}>
                            <ListItem key={k} button onClick={() => history.push(item.path)}>
                                <ListItemText primary={item.title} /> 
                            </ListItem>
                        </Perm>
                    ))}

                </List>
            </div>
        </div>
    ); 
}

export default Admin; 