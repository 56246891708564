import { Button } from '@material-ui/core';
import { PaperContainer } from 'core/components/paperContainer';
import { SnackbarSuccess } from 'core/components/Snackbars';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import { getUserResetPasswordLink } from 'gen/routes/Users';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';



const UserPassword = () => {

    const user = useRecoilValue(selectedUserAtom); 
    const [linkCopied, setLinkCopied] = useState(false); 

    const fetchResetPasswordLink = () => { 
        getUserResetPasswordLink(user.UserID)
            .then(response => { 
                navigator.clipboard.writeText(response.data.Link);
                setLinkCopied(true); 
            }); 
    }

    return (
        <PaperContainer>
            
            <SnackbarSuccess open={linkCopied} onClose={() => setLinkCopied(false)}>Link Copied</SnackbarSuccess>

            <Button onClick={() => fetchResetPasswordLink()}>Copy Reset Password Link</Button>

        </PaperContainer>
    ); 
}


export default UserPassword; 