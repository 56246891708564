/**
 *
 * CreateSettingDTO
 */

export type CreateSettingDTO = {

	// DataType int
	DataType: number;

	// DefaultValue string
	DefaultValue: string;

	// Description string
	Description: string;

	// Name string
	Name: string;

	// ObjectType int
	ObjectType: number;

}

// newCreateSettingDTO is a factory method for new CreateSettingDTO objects
export const newCreateSettingDTO = () : CreateSettingDTO => ({
	DataType: 0,
	DefaultValue: '',
	Description: '',
	Name: '',
	ObjectType: 0,
});

