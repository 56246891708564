/**
 *
 * ProfileAggregate
 */
import { Profile, newProfile } from 'gen/models/Profile';

export type ProfileAggregate = Profile & {

	// AvatarURL string
	AvatarURL: string;

}

// newProfileAggregate is a factory method for new ProfileAggregate objects
export const newProfileAggregate = () : ProfileAggregate => ({
	...newProfile(),
	AvatarURL: '',
});

