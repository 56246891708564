import { Button, CircularProgress, FormControl, FormControlLabel, Input, InputLabel, Paper, Switch, TextField } from '@material-ui/core';
import { PaperContainer } from 'core/components/paperContainer';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Accounts_ViewPermission, Users_ToggleTwoFactorPermission, Users_UpdateUserPhoneNumberPermission } from 'gen/constants/permissions';
import { newUpdateUserDTO, UpdateUserDTO } from 'gen/dtos/UpdateUserDTO';
import { useToggleTwoFactor, useUpdateUser, useUpdateUserPhoneNumber } from 'gen/routes/Users';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

const UserDetailAccountEdit = () => {

    const selectedUser = useRecoilValue(selectedUserAtom); 
    const [ updateUser, { status: updateUserStatus, error: updateUserError } ] = useUpdateUser(); 
    const [user, setUser] = useState<UpdateUserDTO>(newUpdateUserDTO()); 
    const [phone, setPhone] = useState<string>(''); 
    const [toggleTwoFactor] = useToggleTwoFactor(); 
    const [updateUserPhone] = useUpdateUserPhoneNumber(); 

    // DIsable
    // Lock
    // Update Email 
    // Phone Number 

    useEffect(() => { 
        
        setUser({ 
            FirstName: selectedUser.Profile.FirstName, 
            LastName: selectedUser.Profile.LastName, 
            UserPrefix: selectedUser.UserPrefix, 
        });

        setPhone(selectedUser.SMSPhoneNumber); 

    }, [selectedUser])

    const save = () => {
        updateUser({ 
            userID: selectedUser.UserID, 
            body: user, 
        })
    }


    const updatePhone = () => { 
        updateUserPhone({ userID: selectedUser.UserID, body: { Phone: phone }})
            .then(() => { 
                alert('phone updated!'); 
            });
    }
    


    return (
        <PaperContainer>
            {updateUserStatus === "error" && 
                <Alert color="danger">{handleNetworkError(updateUserError)}</Alert>
            }

            {updateUserStatus === "success" &&
                <Alert color="success">Account Updated</Alert>
            }

            <Perm perm={Accounts_ViewPermission}>
                <Link to={`/admin/accounts/${selectedUser.AccountID}`}>Account ID: {selectedUser.AccountID}</Link>
            </Perm>
            <br /> 

            <StyledFormControl>
                <InputLabel>Email</InputLabel>
                <Input type="text" name="Email" value={selectedUser.Email} disabled={true} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Prefix</InputLabel>
                <Input type="text" name="Prefix" value={user.UserPrefix} onChange={(e) => setUser({ ...user, UserPrefix: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>First Name</InputLabel>
                <Input type="text" name="FirstName" value={user.FirstName} onChange={(e) => setUser({ ...user, FirstName: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Last Name</InputLabel>
                <Input type="text" name="LastName" value={user.LastName} onChange={(e) => setUser({ ...user, LastName: e.target.value })} /> 
            </StyledFormControl>

            <Perm perm={Users_UpdateUserPhoneNumberPermission}>
                <Paper elevation={3} style={{ padding: 10, marginTop: 10, marginBottom: 10 }}>
                
                    <TextField
                        label="Phone" 
                        type="number" 
                        inputProps={{ 
                            inputMode: 'numeric', 
                            pattern: '[0-9]*' 
                        }} 
                        name="Phone" 
                        value={phone} 
                        onChange={(e) => setPhone(e.target.value as string)} 
                        fullWidth
                    /> 
                    <Button onClick={() => updatePhone()}>Update Phone</Button>

                    <Perm perm={Users_ToggleTwoFactorPermission}>
                        <FormControlLabel
                            label="Two-Factor Enabled"
                            control={
                                <Switch 
                                    checked={selectedUser.TwoFactorEnabled === 1} 
                                    onChange={(e) => { 
                                        toggleTwoFactor({ userID: selectedUser.UserID })    
                                    }} 
                                />
                            }
                        /> 
                    </Perm>
                </Paper>
            </Perm>

            <br /> 

            <Button onClick={save} disabled={updateUserStatus === "loading"} color="primary" variant="contained">
                Save
                {updateUserStatus === "loading" &&
                    <CircularProgress /> 
                }
            </Button>
        </PaperContainer>
    )

}

const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

export default UserDetailAccountEdit;