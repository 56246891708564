import { CircularProgress, Paper, TextField } from '@material-ui/core';
import { useGetUserSettings, useUpdateUserSettings } from 'gen/routes/Users';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { ObjectSettingDTO } from 'gen/dtos/ObjectSettingDTO';
import React, { useEffect, useState } from 'react'; 
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';

const UserSettings = () => { 

    const user = useRecoilValue(selectedUserAtom); 
    const { data : settingData, error, status } = useGetUserSettings(user.UserID)
    const [updateSettings] = useUpdateUserSettings();
    const [data, setData] = useState<ObjectSettingDTO[]>([]); 

    const doUpdateSettings = () => { 
        const update = data.map(x => ({ SettingStringID: x.SettingStringID, Value: x.Value }))
        updateSettings({ userID: user.UserID, body: { Settings: update }})
            .then(() => console.log('setting updated'))
            .catch(e => alert(handleNetworkError(e)))
    }

    useEffect(() => {
        if(settingData) { 
            setData(settingData.data)
        }
    }, [settingData])

    if(status === "error") { 
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    return (
        <Paper elevation={2} style={{ margin: 20, padding: 20 }}>

            {data.map((dto, key) => (
                <TextField
                    fullWidth
                    value={dto.Value}
                    label={dto.Name}
                    helperText={dto.Description}
                    onChange={(e) => setData(data.map(x => x.SettingID === dto.SettingID ? { ...dto, Value: e.target.value as string }  : x))}
                    onBlur={doUpdateSettings}
                /> 
            ))}

        </Paper>
    )

}

export default UserSettings; 