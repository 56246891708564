import { CustomerContact } from "gen/models/CustomerContact"

export type UpdateCustomerContactDTO = {
    CustomerID: number; 
    CustomerContactID: number; 
    FirstName: string; 
    LastName: string; 
    MI: string; 
    Email: string; 
    Phone: string; 
    PhoneExt: number; 
    Cell: string; 
    Role: string; 
}

export const newUpdateCustomerContactDTO = () : UpdateCustomerContactDTO => ({
    CustomerID: 0, 
    CustomerContactID: 0, 
    FirstName: '', 
    LastName: '', 
    MI: '', 
    Email: '', 
    Phone: '', 
    PhoneExt: 0, 
    Cell: '', 
    Role: '', 
})

export const newUpdateCustomerContactDTOFromModel = (model: CustomerContact) : UpdateCustomerContactDTO => ({
    CustomerID: model.CustomerID, 
    CustomerContactID: model.CustomerContactID, 
    FirstName: model.FirstName, 
    LastName: model.LastName, 
    MI: model.MI, 
    Email: model.Email, 
    Phone: model.Phone, 
    PhoneExt: model.PhoneExt, 
    Cell: model.Cell, 
    Role: model.Role, 
})