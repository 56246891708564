/**
 *
 * CreateVendorDTO
 */

export type CreateVendorDTO = {

	// Address string
	Address: string;

	// Address2 string
	Address2: string;

	// City string
	City: string;

	// Fax string
	Fax: string;

	// Phone string
	Phone: string;

	// State string
	State: string;

	// Title string
	Title: string;

	// Zip string
	Zip: string;

}

// newCreateVendorDTO is a factory method for new CreateVendorDTO objects
export const newCreateVendorDTO = () : CreateVendorDTO => ({
	Address: '',
	Address2: '',
	City: '',
	Fax: '',
	Phone: '',
	State: '',
	Title: '',
	Zip: '',
});

