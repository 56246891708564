import { CircularProgress } from '@material-ui/core';
import { useGetFormFromID } from 'gen/routes/Forms';
import Form from 'core/components/form';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { Alert } from 'reactstrap';

const FormDataSubmissionCreateForm : React.FC<{ formID: number; onSuccess?: () => void }> = ({ formID, onSuccess }) => { 

    const { data, error, status } = useGetFormFromID(formID); 

    if(status === "error") { 
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    return (
        <Form 
            form={data.data} 
            onSuccess={onSuccess}
        /> 
    )

}

export default FormDataSubmissionCreateForm; 