/**
 *
 * CreateTaskBatchDTO
 */

export type CreateTaskBatchDTO = {

	// Name string
	Name: string;

}

// newCreateTaskBatchDTO is a factory method for new CreateTaskBatchDTO objects
export const newCreateTaskBatchDTO = () : CreateTaskBatchDTO => ({
	Name: '',
});

