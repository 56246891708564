import { CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { CheckCircle, Help, HourglassEmpty, NotInterested, RefreshOutlined, Remove, Report } from '@material-ui/icons';
import { useGetTaskBatchLogsFromTaskBatch, useGetTaskLogsFromTaskBatchLog, useGetTaskLogStepsFromTaskLog } from "gen/routes/Tasks";
import { DataBody, DataCell, DataHeaderOrdered, DataRow, DataTable, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { TaskBatchLog } from 'gen/models/TaskBatchLog';
import { TaskLog } from 'gen/models/TaskLog';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import selectedTaskBatchAtom from './atoms/selectedTaskBatchAtom';
import selectedTaskBatchLogAtom from './atoms/selectedTaskBatchLogAtom';
import selectedTaskLogAtom from './atoms/selectedTaskLogAtom';

	// TaskRunStatusNone is a task run status of none
const TaskRunStatusNone = 0
	// TaskRunStatusRunning is a task that is running
const TaskRunStatusRunning = 1
	// TaskRunStatusSuccess is a task that has run successfully
const TaskRunStatusSuccess = 2
	// TaskRunStatusFail is a task that has failed in its run
const TaskRunStatusFail = 3
	// TaskRunStatusEmpty is a task or a batch that either has no tasks, or the task cannot be found
const TaskRunStatusEmpty = 4
// TaskRunStatusInterrupted is a task or a batch that was interupted
const TaskRunStatusInterrupted = 5 

const TaskBatchDetailBatchLogs = () => {

    const [sort, setSort] = useState({ orderBy: 'DateCreated', orderDir: 'DESC', page: 0, limit: 50 }); 
    const batch = useRecoilValue(selectedTaskBatchAtom); 
    const resetTaskLog = useResetRecoilState(selectedTaskLogAtom);
    const { data, status, error, refetch } = useGetTaskBatchLogsFromTaskBatch(batch.TaskBatchID, sort.page, sort.limit, sort.orderBy, sort.orderDir); 
    const [selectedLog, setSelectedLog] = useRecoilState(selectedTaskBatchLogAtom)

    const orderCol = (fieldName: string) => () => {

        if(sort.orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }

        setSort({...sort, orderBy: fieldName, orderDir: 'ASC', page: 0, limit: 50 }); 
    }

    const toggleOrderDir = () => {
        const orderDir = sort.orderDir === "ASC" ? "DESC" : "ASC";
        setSort({...sort, orderDir }); 
    }


   


    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const next = () => {
        const newOffset = sort.limit * (sort.page + 1); 
        if(newOffset >= data.data.Count) {
            return; 
        }        
        setSort({ ...sort, page: sort.page + 1})
    }

    const prev = () => {
        const newOffset = sort.limit * (sort.page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setSort({ ...sort, page: sort.page - 1 }); 
    }
    
    const numCols = 6; 

    const colWidth = (units: number) : number => {
        return units * (99.9 / numCols); 
    }

    const selectLog = (v: TaskBatchLog) => () => {
        setSelectedLog(v); 
        resetTaskLog(); 
    }

    return <div style={{ height: '100%', overflow: 'hidden' }}>
            <div style={{height: '48px', verticalAlign: 'middle', lineHeight: '48px' }}>
                
                <div style={{ display: 'inline-block'}}>
                    <TableNav {...{ count: data.data.Count, prev, next, page: sort.page, limit: sort.limit }} />
                </div>

                <IconButton onClick={() => refetch()}>
                    <RefreshOutlined />
                </IconButton>
                
            </div>
            <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>

                <DataRow>
                    <DataHeaderOrdered width={colWidth(.3)} orderFn={orderCol("RunStatus")} isOrderBy={sort.orderBy === "RunStatus"} dir={sort.orderDir}>_</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(1.2)} orderFn={orderCol("DateCreated")} isOrderBy={sort.orderBy === "DateCreated"} dir={sort.orderDir}>Date</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(.5)} orderFn={orderCol("DaemonCounter")} isOrderBy={sort.orderBy === "DaemonCounter"} dir={sort.orderDir}>Run</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(.7)} orderFn={orderCol("FinishedTasks")} isOrderBy={sort.orderBy === "FinishedTasks"} dir={sort.orderDir}>Tasks</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(.7)} orderFn={orderCol("RunTime")} isOrderBy={sort.orderBy === "RunTime"} dir={sort.orderDir}>RunTime</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(2.6)} orderFn={orderCol("Content")} isOrderBy={sort.orderBy === "Content"} dir={sort.orderDir}>Message</DataHeaderOrdered>
                </DataRow>
                
                <DataBody>

                    {data.data.Data.map((v, k) => 
                        <DataRow key={k} onClick={selectLog(v)} style={{ backgroundColor: v.TaskBatchLogID === selectedLog.TaskBatchLogID ? '#efe' : '#fff'}}>
                            <DataCell width={colWidth(.3)}><StatusIcon status={v.RunStatus} /></DataCell>
                            <DataCell width={colWidth(1.2)}>{moment(v.DateCreated).format('MM/DD/YY h:mma')}</DataCell>
                            <DataCell width={colWidth(.5)}>{v.DaemonCounter}</DataCell>
                            <DataCell width={colWidth(.7)}>
                                <span style={{ color: '#444', fontWeight: 'bold' }}>{v.TotalTasks}</span> / <span style={{ color: '#900', fontWeight: 'bold' }}>{v.FailedTasks}</span> / <span style={{ color: '#090', fontWeight: 'bold' }}>{v.FinishedTasks}</span>
                            </DataCell>
                            <DataCell width={colWidth(.7)}>{v.RunTime / 1000}s</DataCell>
                            <DataCell width={colWidth(2.6)}>{v.Content}</DataCell>
                        </DataRow>
                    )}

                </DataBody>
                
        </DataTable>
    </div>
}




const StatusIcon : React.FC<{ status: number }> = ({ status }) => {

    if(status === TaskRunStatusNone) { 
        return <HourglassEmpty /> 
    }

    if(status === TaskRunStatusEmpty) {
        return <Remove style={{ color: '#999' }} /> 
    }

    if(status === TaskRunStatusRunning) {
        return <CircularProgress size={20} /> 
    }

    if(status === TaskRunStatusFail) {
        return <Report style={{ color: '#900' }} /> 
    }

    if(status === TaskRunStatusSuccess) {
        return <CheckCircle style={{ color: '#090' }} /> 
    }

    if(status === TaskRunStatusInterrupted) {
        return <NotInterested style={{ color: '#ff8c00' }} /> 
    }

    return <Help style={{ color: '#009' }} /> 
}

const TaskBatchDetailBatchLogDetail = () => {

    const log = useRecoilValue(selectedTaskBatchLogAtom);
    if(log.TaskBatchLogID === 0) {
        return null; 
    }

    return <TaskLogInner /> 
}

const TaskLogInner = () => {
    
    const batchLog = useRecoilValue(selectedTaskBatchLogAtom);

    const [taskLog, setTaskLog] = useRecoilState(selectedTaskLogAtom); 
    
    const { data, error, status, refetch } = useGetTaskLogsFromTaskBatchLog(batchLog.TaskBatchID, batchLog.TaskBatchLogID, 0, 100, 'BatchOrdinal', 'ASC')

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const selectTaskLog = (taskLog: TaskLog) => () => {
        setTaskLog(taskLog); 
    }

    return <div>
        <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
        </IconButton>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Ordinal</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>RunTime</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Steps</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.data.Data.map((v, k) => <TableRow key={k} onClick={selectTaskLog(v)} style={{ backgroundColor: v.TaskLogID === taskLog.TaskLogID ? '#efe' : '#fff'}}>
                        <TableCell><StatusIcon status={v.RunStatus} /></TableCell>
                        <TableCell>{v.BatchOrdinal}</TableCell>
                        <TableCell>{v.Name}</TableCell>
                        <TableCell>{v.RunTime / 1000}s</TableCell>
                        <TableCell>{v.Content}</TableCell>
                        <TableCell><span style={{ color: '#444', fontWeight: 'bold' }}>{v.TotalSteps}</span> / <span style={{ color: '#900', fontWeight: 'bold' }}>{v.FailedSteps}</span> / <span style={{ color: '#090', fontWeight: 'bold' }}>{v.FinishedSteps}</span></TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </div>
}

const TaskBatchDetailLogs = () => {
    return <Grid container spacing={6} style={{ height: '100%', margin: 0 }}>
        <Grid item xs={4} style={{ height: '100%', padding: 0}}>
            <TaskBatchDetailBatchLogs /> 
        </Grid>
        <Grid item xs={4} style={{ height: '100%', padding: 0}}>
            <TaskBatchDetailBatchLogDetail /> 
        </Grid>
        <Grid item xs={4} style={{ height: '100%', padding: 0}}>
            <TaskBatchDetailBatchLogDetailSteps /> 
        </Grid>
    </Grid>
}

const TaskBatchDetailBatchLogDetailSteps = () => {

    const taskLog = useRecoilValue(selectedTaskLogAtom);
    if(taskLog.TaskLogID === 0) {
        return null; 
    }

    return <TaskBatchDetailBatchLogDetailStepsInner /> 
    
}

const TaskBatchDetailBatchLogDetailStepsInner = () => {
    
    const [sort, setSort] = useState({ orderBy: 'StepNo', orderDir: 'ASC', page: 0, limit: 50 }); 
    const log = useRecoilValue(selectedTaskLogAtom);
    const { data, error, status, refetch } = useGetTaskLogStepsFromTaskLog(log.TaskBatchID, log.TaskBatchLogID, log.TaskLogID, sort.page, sort.limit, sort.orderBy, sort.orderDir)
  
    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const orderCol = (fieldName: string) => () => {

        if(sort.orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }

        setSort({...sort, orderBy: fieldName, orderDir: 'ASC', page: 0, limit: 50 }); 
    }

    const toggleOrderDir = () => {
        const orderDir = sort.orderDir === "ASC" ? "DESC" : "ASC";
        setSort({...sort, orderDir }); 
    }

    const numCols = 5; 
    const colWidth = (units: number) : number => {
        return units * (99.9 / numCols); 
    }

    const next = () => {
        const newOffset = sort.limit * (sort.page + 1); 
        if(newOffset >= data.data.Count) {
            return; 
        }        
        setSort({ ...sort, page: sort.page + 1})
    }

    const prev = () => {
        const newOffset = sort.limit * (sort.page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setSort({ ...sort, page: sort.page - 1 }); 
    }
    
    
    return <div style={{ height: '100%', overflow: 'hidden'}}>
    
            <div style={{height: '48px', verticalAlign: 'middle', lineHeight: '48px' }}>
                
                <div style={{ display: 'inline-block'}}>
                    <TableNav {...{ count: data.data.Count, prev, next, page: sort.page, limit: sort.limit }} />
                </div>

                <IconButton onClick={() => refetch()}>
                    <RefreshOutlined />
                </IconButton>
                
            </div>
            <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>


                <DataRow>
                    <DataHeaderOrdered width={colWidth(.5)} orderFn={orderCol("RunStatus")} isOrderBy={sort.orderBy === "RunStatus"} dir={sort.orderDir}>-</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(.5)} orderFn={orderCol("StepNo")} isOrderBy={sort.orderBy === "StepNo"} dir={sort.orderDir}>Ordinal</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(1)} orderFn={orderCol("RunTime")} isOrderBy={sort.orderBy === "RunTime"} dir={sort.orderDir}>RunTime</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(2.5)} orderFn={orderCol("StatusMessage")} isOrderBy={sort.orderBy === "StatusMessage"} dir={sort.orderDir}>Message</DataHeaderOrdered>
                    <DataHeaderOrdered width={colWidth(.5)} orderFn={orderCol("Steps")} isOrderBy={sort.orderBy === "Steps"} dir={sort.orderDir}>Steps</DataHeaderOrdered>
                </DataRow>
                <DataBody>
                    
                    {data.data.Data.map((v, k) => <DataRow key={k}>
                            <DataCell width={colWidth(.5)}><StatusIcon status={v.RunStatus} /></DataCell>
                            <DataCell width={colWidth(.5)}>{v.StepNo}</DataCell>
                            <DataCell width={colWidth(1)}>{v.RunTime / 1000}s</DataCell>
                            <DataCell width={colWidth(2)}>{v.StatusMessage}</DataCell>
                            <DataCell width={colWidth(.5)}>
                                <span style={{ color: '#444', fontWeight: 'bold' }}>{v.ItemCount}</span> / <span style={{ color: '#000', fontWeight: 'bold' }}>{v.ItemTotal}</span>
                            </DataCell>

                        </DataRow>
                    )}
                
                </DataBody>

            </DataTable>
    </div>
}

export default TaskBatchDetailLogs; 
