/**
 *
 * CreateEmptyQuoteDTO
 */

export type CreateEmptyQuoteDTO = {

	// DueDate string
	DueDate: string;

}

// newCreateEmptyQuoteDTO is a factory method for new CreateEmptyQuoteDTO objects
export const newCreateEmptyQuoteDTO = () : CreateEmptyQuoteDTO => ({
	DueDate: '',
});

