import { newUpdateFiscalPeriodDTO, UpdateFiscalPeriodDTO } from 'app/definitions/dtos/UpdateFiscalPeriodDTO';
import { newUpdateFiscalYearDTO, sysValReport, UpdateFiscalYearDTO } from 'app/definitions/dtos/UpdateFiscalYearDTO';
import { memoize } from 'core/utils/utils';
import { FiscalPeriod, newFiscalPeriod } from 'gen/models/FiscalPeriod';
import { FiscalYear, newFiscalYear } from 'gen/models/FiscalYear';
import { atom, RecoilState, selector, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

//#region FiscalYear
export const selectedFiscalYearAtom = atom<FiscalYear>({ 
    key: 'selectedFiscalYearAtom', 
    default: newFiscalYear(), 
})

export const editingFiscalYearAtom = atom<UpdateFiscalYearDTO>({ 
    key: 'editingFiscalYearAtom', 
    default: newUpdateFiscalYearDTO(), 
})


export const selectedFiscalYearsAtom = atom<FiscalYear[]>({
    key: "selectedFiscalYearsAtom", 
    default: [], 
}); 

export const selectedFiscalYearsSelector = selector<FiscalYear[]>({
    key: 'selectedFiscalYearsSelector', 
    get: ({ get }) => {
        const itemIDs = get(fiscalYearIDsAtom)
        const items = itemIDs.map(id => get(fiscalYearWithID(id)))
        return items; 
    }
})

export const fiscalYearIDsAtom = atom<number[]>({ 
    key: `fiscalYearIDsAtom`, 
    default: [], 
})

export const sortingSysValAtom = atom<any> ({
    key: "sortingSysValAtom", 
    default: sysValReport(), 
})
export const useInsertFiscalYear = () => {

    const [items, setItems] = useRecoilState(fiscalYearIDsAtom); 

    return useRecoilCallback(
        ({set}) => {
            return (value: FiscalYear) => {
                const maxID = items.length > 0 ? Math.max(...items) : -1
                setItems([...items, maxID + 1]); 
                console.log("Insert SysVal", items, maxID + 1)
                set(fiscalYearWithID(maxID + 1), value)
            }
        }
    )

}

export const useInsertFiscalYears = () => {

    const setFiscalYearIDs = useSetRecoilState(fiscalYearIDsAtom); 

    return useRecoilCallback(
        ({set}) => {

            return (value: FiscalYear[]) => {
                
                value.forEach((item, k) => {

                    set(fiscalYearWithID(k), item)

                })
                
                // Set the ids 
                setFiscalYearIDs(value.map((x, y) => y));

            }
        }
    )

}

export const addFiscalYearID = (id: number) => atom<FiscalYear>({
    key: `fiscalYear${id}`, 
    default: newFiscalYear(), 
});

export const fiscalYearWithID = memoize(addFiscalYearID) as (id: number) => RecoilState<FiscalYear>; 

//#endregion Fiscal Year

//#region Fiscal Periods 

export const selectedFiscalPeriodAtom = atom<FiscalPeriod>({ 
    key: 'selectedFiscalPeriodAtom', 
    default: newFiscalPeriod(), 
})

export const editingFiscalPeriodAtom = atom<UpdateFiscalPeriodDTO>({ 
    key: 'editingFiscalPeriodAtom', 
    default: newUpdateFiscalPeriodDTO(), 
})


export const fiscalPeriodIDsAtom = atom<number[]>({ 
    key: `fiscalPeriodIDsAtom`, 
    default: [], 
})

export const useInsertFiscalPeriod = () => {

    const [items, setItems] = useRecoilState(fiscalPeriodIDsAtom); 

    return useRecoilCallback(
        ({set}) => {
            return (value: FiscalPeriod) => {
                const maxID = items.length > 0 ? Math.max(...items) : -1
                setItems([...items, maxID + 1]); 
                console.log("Insert SysVal", items, maxID + 1)
                set(fiscalPeriodWithID(maxID + 1), value)
            }
        }
    )

}

export const useInsertFiscalPeriods = () => {

    const setFiscalPeriodIDs = useSetRecoilState(fiscalPeriodIDsAtom); 

    return useRecoilCallback(
        ({set}) => {

            return (value: FiscalPeriod[]) => {
                console.log('insertFiscalPeriodIDs', value)
                value.forEach((item, k) => {

                    set(fiscalPeriodWithID(k), item)

                })
                
                // Set the ids 
                setFiscalPeriodIDs(value.map((x, y) => y));

            }
        }
    )

}

export const addFiscalPeriodID = (id: number) => atom<FiscalPeriod>({
    key: `fiscalPeriod${id}`, 
    default: newFiscalPeriod(), 
});

export const fiscalPeriodWithID = memoize(addFiscalPeriodID) as (id: number) => RecoilState<FiscalPeriod>; 


//#endregion 
