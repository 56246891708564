import { useCookies } from "react-cookie";

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const deleteCookie = (cname) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const getCookie = (cname: string) : string => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

export const useHasCookie = (name: string) : boolean => {
    const [cookies] = useCookies(); 
    return cookies['auth'] !== 'undefined'; 
}

export const useGetCookie = (name: string) : string | undefined => {
    const [cookies] = useCookies();
    return cookies[name]; 
}

export const setAuthCookie = (value: string) => {
    setCookie("auth", value, 10000); 
}

export const getAuthCookie = () => {
  return getCookie("auth"); 
}

export const deleteAuthCookie = () => {
  deleteCookie("auth"); 
}

export const setImpersonateCookie = (value: string) => { 
  setCookie("impersonateAuth", value, 10000); 
}

export const deleteImpersonateCookie = () => { 
  deleteCookie("impersonateAuth"); 
}

export const getImpersonateCookie = () => {
  return getCookie("impersonateAuth"); 
}

export const setDeviceCookie = (value: string) => {
    setCookie("device", value, 100000); 
}
  

