import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) { 
    return <MuiAlert elevation={6} variant="filled" {...props} /> 
}

const Notify : React.FC<{ type: "error" | "success", message: string, open: boolean, onClose: () => void }> = ({ type, message, open, onClose }) => { 

    return (
        <Snackbar open={open} onClose={onClose} autoHideDuration={3000}>
            <Alert severity={type}>{message}</Alert>
        </Snackbar> 
    );
}

export default Notify; 