
import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserAggregate } from 'core/definitions/aggregates/UserAggregate';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetUserDevices } from 'gen/routes/Users';
import moment from 'moment';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 340,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    },
    add: {
        position: 'absolute', 
        bottom: 40, 
        right: 20, 
    }
})); 

const UserDevices = () => {

    const classes = useStyles(); 
    const user = useRecoilValue(selectedUserAtom) as UserAggregate; 

    const { data, isLoading, isError, error } = useGetUserDevices(user.UserID)

    if(!data || isLoading) {
        return <CircularProgress /> 
    }

    if(isError) {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    return (
        <div style={{ height: 'calc(100% - 48px)',  overflowY: 'auto'}}>
            
            <Grid item xs={12}>
                <TableContainer>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow hover role="checkbox">
                                <TableCell>
                                    Agent
                                </TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.data.map((device, key) => 
                                <TableRow key={key} hover role="checkbox">
                                    <TableCell>{device.UserAgent}</TableCell>
                                    <TableCell>{moment(device.DateCreated).format("DD MMM YYYY hh:mm a")}</TableCell> 
                                    <TableCell>{moment(device.LastUsed).fromNow()}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </Grid>
        </div>
    ); 
}

export default UserDevices; 