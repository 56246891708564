import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateVendorItem } from 'app/api/vendors';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateVendorItemDTO } from 'gen/dtos/CreateVendorItemDTO';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { selectedVendorAtom, showCreateVendorItemModalAtom } from './state';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

export default function CreateVendorItemModal() {

    const selectedVendor = useRecoilValue(selectedVendorAtom); 
    const [showing, setShowing] = useRecoilState(showCreateVendorItemModalAtom); 
    const [item, setItem] = useState({ VendorID: selectedVendor.VendorID, ...newCreateVendorItemDTO() })
    const [createItem, { status, error }] = useCreateVendorItem(); 
    const toggleDialog = () => setShowing(false)

    const [costVal, setCostVal] = useState("") 
    
    const save = () => {

        createItem({ ...item, Cost: parseFloat(costVal)})
            .then(item => {
                setShowing(false); 
                setItem({ VendorID: selectedVendor.VendorID, ...newCreateVendorItemDTO() }); 
            });
    }



    return <Dialog open={showing} onBackdropClick={toggleDialog}>

        <DialogTitle>Create A Product</DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }
            <small>Manufacturer: {selectedVendor.Title}</small>
            <br /> 
            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Title" value={item.Title} onChange={(e) => setItem({ ...item, Title: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Description</InputLabel>
                <Input type="text" name="Description" value={item.Description} onChange={(e) => setItem({ ...item, Description: e.target.value })} multiline rowsMax={3} rows={3} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Model Number</InputLabel>
                <Input type="text" name="Model Number" value={item.ModelNumber} onChange={(e) => setItem({ ...item, ModelNumber: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Price</InputLabel>
                <Input type="text" name="Price" placeholder="0.00" value={costVal} onChange={(e) => setCostVal(e.target.value)} /> 
            </StyledFormControl>


        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={status === "loading"} variant="contained">
                Create
            </Button>
            <Button onClick={toggleDialog} disabled={status === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}