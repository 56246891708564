/**
 *
 * Admin.AdminUtils
 */
 
import axios from 'axios';
import { useMutation } from 'react-query';

// RefreshUserSessions refreshes user sessions
// PUT /admin/utils/refreshSessions/{userID:[0-9]+}
// @permission AdminUtils_RefreshUserSessions
export const refreshUserSessions = async (args : { userID : number }) => await axios.put<any>(`/admin/utils/refreshSessions/${args.userID}`, {});
export const useRefreshUserSessions = () => useMutation(refreshUserSessions);

// RebuildPermissions rebuilds the permissions
// PUT /admin/utils/rebuildPermissions
// @permission AdminUtils_RebuildPermissions
export const rebuildPermissions = async () => await axios.put<any>(`/admin/utils/rebuildPermissions`, {});
export const useRebuildPermissions = () => useMutation(rebuildPermissions);
