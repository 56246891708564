import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { Status, StatusAlert, statusDefault, statusLoading, statusNetworkError, StatusState } from 'core/common/status';
import { newCreateUserDTO } from 'gen/dtos/CreateUserDTO';
import { createUser } from 'gen/routes/Users';
import React, { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import styled from 'styled-components';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

export const useCreateUser = () => useMutation(createUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllUsers"]);
	},
	throwOnError: true, 
});

interface CreateUserModalProps { 
    accountID: number; 
    open: boolean; 
    onClose: () => void;
}

export const CreateUserModal = ( props : CreateUserModalProps ) => { 
    
    const { accountID, open, onClose } = props; 

    const [createUser] = useCreateUser(); 

    const [user, setUser] = useState({ ...newCreateUserDTO(), AccountID: accountID }); 
    const [status, setStatus] = useState<StatusState>(statusDefault())

    // const { status: rolesStatus, data: roles, error: rolesError, isError: isRolesError, isLoading: isRolesLoading, isSuccess: isRolesSuccess } = useGetRoles(); 

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            Email: event.target.value,
        });
    };

    const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            FirstName: event.target.value,
        });
    };

    const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            LastName: event.target.value,
        });
    };

    const save = () => {

        setStatus(statusLoading('')); 

        createUser({ body: user })
            .then(() => { 
                setUser(newCreateUserDTO()); 
                setStatus(statusDefault()); 
                onClose(); 
            })
            .catch(e => { 
                setStatus(statusNetworkError(e));
            });
    }



    return <Dialog open={open} onClose={onClose} onBackdropClick={() => onClose()}>
        <DialogTitle>Create a new user</DialogTitle>
        
        <DialogContent>

            <StatusAlert status={status} /> 
            
            <StyledFormControl>
                <InputLabel>Email</InputLabel>
                <Input type="text" name="Email" autoComplete="off" value={user.Email} onChange={onEmailChanged} /> 
            </StyledFormControl>
            
            <StyledFormControl>
                <InputLabel>First Name</InputLabel>
                <Input type="text" name="FirstName" autoComplete="off" value={user.FirstName} onChange={onFirstNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Last Name</InputLabel>
                <Input type="text" name="LastName" autoComplete="off" value={user.LastName} onChange={onLastNameChanged} /> 
            </StyledFormControl>

        </DialogContent>
        <DialogActions>

            <Button variant="contained" onClick={() => save()} disabled={status.status === Status.Loading}>
                
                Create User

                {status.status === Status.Loading && 
                    <CircularProgress size={20} /> 
                }

            </Button>

            
            <Button onClick={onClose} disabled={status.status === Status.Loading}>cancel</Button>
            
        </DialogActions>

    </Dialog>
}