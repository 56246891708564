/**
 *
 * UpdatePolicyDTO
 */

export type UpdatePolicyDTO = {

	// Description string
	Description: string;

	// PermissionPolicyID int64
	PermissionPolicyID: number;

	// ShortName string
	ShortName: string;

	// Title string
	Title: string;

}

// newUpdatePolicyDTO is a factory method for new UpdatePolicyDTO objects
export const newUpdatePolicyDTO = () : UpdatePolicyDTO => ({
	Description: '',
	PermissionPolicyID: 0,
	ShortName: '',
	Title: '',
});

