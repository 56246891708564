import { Tab, Tabs } from 'core/components/tabs';
import selectedManageRoleAtom from 'core/state/roles/selectedManageRoleAtom';
import selectedRoleDetailTabAtom from 'core/state/roles/selectedRoleDetailTabAtom';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import RolePolicyList from './RolePolicyList';
import RoleUserList from './RoleUserList';


// const useStyles = makeStyles(theme => ({
//     add: {
//         position: 'absolute', 
//         bottom: 20, 
//         right: 20, 
//     }
// }))


const RoleDetail = () => {

    const selectedRoleID = useRecoilValue(selectedManageRoleAtom);
    const [tab, setTab] = useRecoilState(selectedRoleDetailTabAtom); 

    if(selectedRoleID === 0) {
        return null; 
    }


    return (
        <>
            <Tabs>
                <Tab value="policies" label="Policies" onClick={() => setTab('policies')} tab={tab} /> 
                <Tab value="users" label="Users" onClick={() => setTab('users')} tab={tab} /> 
            </Tabs>
            {tab === "policies" && <RolePolicyList />}
            {tab === "users" && <RoleUserList />}
        </>
    )
}

export default RoleDetail; 