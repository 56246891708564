import { CircularProgress, Paper, TextField } from '@material-ui/core';
import { useGetAccountSettings, useUpdateAccountSettings } from 'gen/routes/Accounts';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { AccountAggregate } from 'gen/aggregates/AccountAggregate';
import { AdminSettings_ViewPermission } from 'gen/constants/permissions';
import { ObjectSettingDTO } from 'gen/dtos/ObjectSettingDTO';
import { UpdateObjectSettingDTO } from 'gen/dtos/UpdateObjectSettingDTO';
import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

const AccountSettings : React.FC<{ account: AccountAggregate }> = ({ account }) => { 

    const { data : settingData, error, status } = useGetAccountSettings(account.AccountID)
    const [updateSettings] = useUpdateAccountSettings(); 
    const [data, setData] = useState<ObjectSettingDTO[]>([]); 

    const doUpdateSettings = () => { 

        const update = data.map(x => ({ SettingStringID: x.SettingStringID, Value: x.Value } as UpdateObjectSettingDTO))

        console.log(update)

        updateSettings({ accountID: account.AccountID, body: { Settings: update }})
            .then(() => console.log('setting updated'))
            .catch(e => alert(handleNetworkError(e)))
    }

    useEffect(() => {
        if(settingData) { 
            setData(settingData.data)
        }
    }, [settingData])

    if(status === "error") { 
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    return (

        <Paper elevation={2} style={{ margin: 20, padding: 20 }}>

            {data.map((dto, key) => (
                <TextField
                    fullWidth
                    value={dto.Value}
                    label={dto.Name}
                    helperText={dto.Description}
                    onChange={(e) => setData(data.map(x => x.SettingID === dto.SettingID ? { ...dto, Value: e.target.value as string }  : x))}
                    onBlur={doUpdateSettings}
                /> 
            ))}

            <Perm perm={AdminSettings_ViewPermission}>
                <Link to="/admin/settings">Manage Settings</Link>
            </Perm>

        </Paper>
    )

}

export default AccountSettings; 