import { CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import selectedManageRoleAtom from 'core/state/roles/selectedManageRoleAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetAllUsers } from 'gen/routes/Users';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';

const RoleUserList = () => {

    const selectedRoleID = useRecoilValue(selectedManageRoleAtom);

    if(selectedRoleID === 0) {
        return null; 
    }

    return (
        <RoleUserListInner /> 
    )
} 

const RoleUserListInner = () => {

    const history = useHistory(); 
    const selectedRoleID = useRecoilValue(selectedManageRoleAtom); 
    const { data, status, error } = useGetAllUsers(0, selectedRoleID, 0, "-1", 0, 100, "FullName", "ASC"); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const gotoUser = (userID: number) => () => {
        history.push(`/admin/users/${userID}`); 
    }
    
    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>
            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto', borderTop: 'solid 1px #000'}}>
                {data.data.Count === 0 && 
                    <Alert color="info">No Users have this policy. <Link to="/admin/users">Manage Users</Link></Alert>
                }
                <List>
                    {data.data.Data.map((user, key) => {
                        return (
                            <ListItem key={key} button>
                                <ListItemIcon onClick={gotoUser(user.UserID)}>
                                    <AccountCircle /> 
                                </ListItemIcon>
                                <ListItemText 
                                    onClick={gotoUser(user.UserID)}
                                    primary={user.FullName}                                    
                                />
                            </ListItem>
                        ); 
                    })}
                </List>
            </div>
    
        </div>
    )
}

export default RoleUserList; 