import { Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const PaperInnerEl = styled(Paper)`
    margin: 20px; 
    padding: 20px; 
`

export const PaperContainer = (props) => {
    return <PaperInnerEl elevation={10} variant="elevation">
        {props.children}
    </PaperInnerEl>
}