/**
 *
 * UpdateObjectTodoDTO
 */

export type UpdateObjectTodoDTO = {

	// AssignedTo int64
	AssignedTo: number;

	// DateDue string
	DateDue: string;

	// Description string
	Description: string;

	// Title string
	Title: string;

}

// newUpdateObjectTodoDTO is a factory method for new UpdateObjectTodoDTO objects
export const newUpdateObjectTodoDTO = () : UpdateObjectTodoDTO => ({
	AssignedTo: 0,
	DateDue: '',
	Description: '',
	Title: '',
});

