import { GetJobDatesForCalendar } from 'app/api/jobs';
import axios from 'axios';
import Calendar, { CalendarEvent } from 'core/components/calendar';
import React from 'react';


const fetchEvents = (date: Date) : Promise<CalendarEvent[]> => new Promise((resolve, reject) => { 
    GetJobDatesForCalendar(date.getFullYear(), date.getMonth() + 1)
        .then(response => { 
            resolve(response.data); 
        });
});



const JobsCalendar = () => { 
    

    // iCalURL={`${axios.defaults.baseURL}/jobs/calendar/ical/23d494f3-2747-11ec-b1e0-0242ac1c0003`}
    
    return (
        <>
            <div style={{ margin: 20, padding: 20, height: 'calc(100% - 40px)', overflowY: 'auto' }}>
                <div style={{ fontSize: '20px' }}>Jobs Calendar</div>
                <Calendar 
                    fetchEvents={fetchEvents}
                    iCalURL={`${axios.defaults.baseURL}/jobs/calendar/ical/cf8842b1-2dc1-11ec-8206-0235925560b2`}                   
                />
            </div>
        </> 
    )
}

export default JobsCalendar; 

