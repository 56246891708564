import { Tab, Tabs } from 'core/components/tabs';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import PolicyDetailDetail from './PolicyDetailDetail';
import PolicyPermissionList from './PolicyPermissionList';
import PolicyRoleList from './PolicyRoleList';
import { selectedManagePolicyAtom } from './state';

const PolicyDetail = () => {

    const selectedPolicyID = useRecoilValue(selectedManagePolicyAtom);
    const [tab, setTab] = useState<'details' | 'permissions' | 'roles'>('details'); 
    if(selectedPolicyID === 0) {
        return null; 
    }



    return (
        <>
            <div style={{ height: '100%'}}>

                <Tabs>
                    <Tab value="details" label="Details" onClick={() => setTab('details')} tab={tab} />
                    <Tab value="permissions" label="Permissions" onClick={() => setTab('permissions')} tab={tab} />
                    <Tab value="roles" label="Roles" onClick={() => setTab('roles')} tab={tab} />
                </Tabs>
                
                {{
                    "details": <PolicyDetailDetail />, 
                    "permissions": <PolicyPermissionList />, 
                    "roles": <PolicyRoleList /> 
                }[tab]}

            </div>
        </>
    )
}
export default PolicyDetail;