import { makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10, 
        margin: 10, 
        position: 'relative', 
    },
    title: {
        fontFamily: 'Roboto', 
        fontSize: '16px', 
        paddingBottom: 10, 
        marginBottom: 10, 
        borderBottom: 'solid 1px #eee', 
        fontWeight: 'bold', 
        color: '#666', 
    }, 
    action: { 
        position: 'absolute', 
        top: 0, 
        right: 0, 
    }
}))

interface IContainerProps { 
    children : React.ReactNode; 
    title?: string; 
    open?: boolean; 
    actions?: React.ReactNode; 
}

export const Container = (props: IContainerProps) => {
   
    const { children, title, open, actions } = props; 

    const classes = useStyles(); 
    const [isOpen, setOpen] = useState(open); 

    return (
        <Paper elevation={3} className={classes.container}>

            {actions && 
                <div className={classes.action}>
                    {actions}
                </div>
            }

            {title && 
                <h2 className={classes.title} onClick={() => setOpen(!isOpen)}>{title}</h2>
            }

            {children}

        </Paper>
    );
}