import { CircularProgress, Fab, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useGetAllPolicies } from 'gen/routes/Policies';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Policies_CreatePolicyPermission } from 'gen/constants/permissions';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import CreatePolicyDialog from './CreatePolicyDialog';
import { policyPermissionPageAtom, selectedManagePolicyAtom, showCreatePolicyModalAtom } from './state';


const useStyles = makeStyles(theme => ({
    add: {
        position: 'absolute', 
        bottom: 20, 
        right: 20, 
    }
}))


const PolicyList = () => {

    const { data, status : roleStatus, error } = useGetAllPolicies(0, 'Title', 'ASC'); 
    const setCreateModalShowing = useSetRecoilState(showCreatePolicyModalAtom);
    const [selectedManagePolicy, setSelectedManagePolicy] = useRecoilState(selectedManagePolicyAtom); 
    const setPolicyPermissionPage = useSetRecoilState(policyPermissionPageAtom); 

    const classes = useStyles(); 
    const history = useHistory();

    const selectPolicy = (policyID: number) => () => {
        history.push(`/admin/policies/${policyID}`)
        setSelectedManagePolicy(policyID); 
        setPolicyPermissionPage(0); 
    }

    
    if(roleStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(roleStatus === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <div style={{ height: '100%', overflowY: 'auto', position: 'relative' }}>

            <Perm perm={Policies_CreatePolicyPermission}>
                <Fab color="primary" aria-label="add" className={classes.add} onClick={() => setCreateModalShowing(true)}>
                    <AddIcon />
                </Fab>
            </Perm>

           
            <List>
                {data.data.map((policy, key) => {
                    return (
                        <ListItem key={key} button onClick={selectPolicy(policy.PolicyID)} style={{ backgroundColor: selectedManagePolicy === policy.PolicyID ? '#9f9' : 'transparent'}}>
                            <ListItemText 
                                primary={policy.ShortName} 
                                secondary={`${policy.PermissionCount} permissions | ${policy.RoleCount} roles`}
                            />
                        </ListItem>
                    ); 
                })}
            </List>
            
            <CreatePolicyDialog /> 

        </div>
    )
}

export default PolicyList; 