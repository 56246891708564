import { QuoteAggregate } from "app/definitions/aggregates/QuoteAggregate";
import { newQuoteCustomerFromAggregate } from "app/definitions/aggregates/QuoteCustomerAggregate";
import { QuoteCustomer } from "gen/models/QuoteCustomer";
import { QuoteItem } from "gen/models/QuoteItem";

export type UpdateQuoteDTO = {
    QuoteID: number; 

    QuoteTypeID: number;
    Description: string;
    DueDateString: string;
    FollowUpDateString: string; 
    QuoteStatusID: number;
    TotalPrice: number; 
    ProjectCost: number; 
    TotalCommission: number; 
    GrossMargin: number; 
    CommissionTypeID: number;
    DSO: number;
    Notes: string;
    MarketID: number; 
    BidTypeID: number; 
    ProbabilityID: number; 
    AreWeBidding: number; 
    Customers: QuoteCustomer[]; 
    Sales: UpdateQuoteSalesDTO[]; 
    Items: QuoteItem[]; 
  }

  export type UpdateQuoteSalesDTO = {
    QuoteSalesID: number; 
    UserID: number; 
    Commission: number; 
  }

  export const newUpdateQuoteDTO = () : UpdateQuoteDTO => ({
      QuoteID: 0, 
      QuoteTypeID: 0, 
      Description: '', 
      DueDateString: '', 
      FollowUpDateString: '', 
      QuoteStatusID: 0, 
      TotalPrice: 0, 
      ProjectCost: 0, 
      TotalCommission: 0, 
      GrossMargin: 0, 
      CommissionTypeID: 0, 
      DSO: 0, 
      Notes: '', 
      MarketID: 0, 
      BidTypeID: 0, 
      ProbabilityID: 0, 
      AreWeBidding: 0, 
      Customers: [], 
      Sales: [], 
      Items: [], 
  }); 

  export const newUpdateQuoteDTOFromAggregate = (model: QuoteAggregate) : UpdateQuoteDTO => ({
    QuoteID: model.QuoteID, 
    QuoteTypeID: model.QuoteTypeID, 
    Description: model.Description, 
    DueDateString: model.DueDateString, 
    FollowUpDateString: model.FollowUpDateString, 
    QuoteStatusID: model.QuoteStatusID, 
    TotalPrice: model.TotalPrice, 
    ProjectCost: model.ProjectCost, 
    TotalCommission: model.TotalCommission, 
    GrossMargin: model.GrossMargin, 
    CommissionTypeID: model.CommissionTypeID, 
    DSO: model.DSO, 
    Notes: model.Notes, 
    MarketID: model.MarketID, 
    BidTypeID: model.BidTypeID, 
    ProbabilityID: model.ProbabilityID, 
    AreWeBidding: model.AreWeBidding, 
    Customers: model.Customers.map(x => newQuoteCustomerFromAggregate(x)),
    Sales: model.Sales.map(x => ({ QuoteSalesID: x.QuoteSalesID, UserID: x.UserID, Commission: x.Commission })), 
    Items: model.Items, 
}); 