/**
 *
 * TaskLog
 */

export type TaskLog = {

	// BatchOrdinal int64
	BatchOrdinal: number;

	// BatchTotal int64
	BatchTotal: number;

	// Content null.String
	Content: string;

	// ContinueOnFailure int
	ContinueOnFailure: number;

	// DaemonCounter int64
	DaemonCounter: number;

	// DateCreated int64
	DateCreated: number;

	// DateFinished int64
	DateFinished: number;

	// DateScheduled int64
	DateScheduled: number;

	// DateStarted int64
	DateStarted: number;

	// FailedSteps int64
	FailedSteps: number;

	// FinishedSteps int64
	FinishedSteps: number;

	// IsDeleted int
	IsDeleted: number;

	// Name string
	Name: string;

	// NumAttempts int64
	NumAttempts: number;

	// RunStatus int64
	RunStatus: number;

	// RunTime int64
	RunTime: number;

	// TaskBatchID int64
	TaskBatchID: number;

	// TaskBatchLogID int64
	TaskBatchLogID: number;

	// TaskBatchScheduleID int64
	TaskBatchScheduleID: number;

	// TaskID int64
	TaskID: number;

	// TaskLogID int64
	TaskLogID: number;

	// TotalSteps int64
	TotalSteps: number;

}

// newTaskLog is a factory method for creating new TaskLog objects
export const newTaskLog = () : TaskLog => ({ 
	BatchOrdinal: 0,
	BatchTotal: 0,
	Content: '',
	ContinueOnFailure: 0,
	DaemonCounter: 0,
	DateCreated: 0,
	DateFinished: 0,
	DateScheduled: 0,
	DateStarted: 0,
	FailedSteps: 0,
	FinishedSteps: 0,
	IsDeleted: 0,
	Name: '',
	NumAttempts: 0,
	RunStatus: 0,
	RunTime: 0,
	TaskBatchID: 0,
	TaskBatchLogID: 0,
	TaskBatchScheduleID: 0,
	TaskID: 0,
	TaskLogID: 0,
	TotalSteps: 0,
});

