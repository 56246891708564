
import { makeStyles } from '@material-ui/core';
import styled from "styled-components";

export const useGridStyles = makeStyles((theme) => ({
    grid: {
        width: '100%', 
        position: 'relative', 
        height: 'calc(100% - 48px)', 
        overflowY: 'hidden'
    }, 
    gridItemLeft: {
        width: '30%', 
        float: 'left', 
        position: 'relative', 
    }, 
    gridItemRight: {
        width: '69%', 
        float: 'right', 
        height: '100%',
        overflowY: 'hidden', 
    }, 

    listSelected: {
        backgroundColor: '#efe', 
        fontWeight: 'bold', 
    }

}));


export const useStyles = makeStyles((theme) => ({
    
    render: {
        visibility: 'hidden', 
        opacity: 0, 
        transition: 'all 0.2s ease',
    },

    show: { 
        visibility: 'visible', 
        opacity: 1,
        height: 48, 
        marginBottom: 10, 
    },

    item: {
        
    }, 

    itemSelected: {
        backgroundColor: '#efe', 
    }, 
    selected: {
        position: 'absolute', 
        border: 'solid 1px #909090', 
        borderRadius: '3px', 
        backgroundColor: '#efe', 
        width: '100%', 
        userSelect: 'none', 
    }, 

    listItemMoving: {
        position: 'absolute !important' as any, 
        zIndex: 10000, 
    },
    listItemHovering: {
        '& > div': {
            backgroundColor: '#efe', 
        }
    },

    itemActive : { 
        backroundColor: "#efe !important", 
    }, 

    itemInactive: { 
        backgroundColor: '#ddd !important', 
    }, 

    itemTitleLabel: {
        height: 48, 
        lineHeight: '48px', 
        verticalAlign: 'middle', 
        cursor: 'pointer', 
        fontSize: 12, 
    },

    placeholder: {
        backgroundColor: '#999', 
        border: 'dashed 1px #909090',
        borderRadius: '3px', 
    }, 

    movement: {

    }, 

    delete: {

    }
})); 

export const ListMenuItem = styled.div`
    padding: 10px;  
    margin: 10px; 
    border-radius: 3px;  
    border: solid 1px #eee;
    background-color: #fff; 
    cursor: pointer;  
    display: flex; 
    flex-direction: row; 
    &:hover: {
        background-color: #eee; 
    }
`

export const ListMenuItemTitle = styled.div`
    flex-grow: 1; 
`; 

export const ListMenuItemCounter = styled.div`
    background-color: #666; 
    border-radius: 11px; 
    font-size: 12px;
    font-weight: bold; 
    padding-left: 5px; 
    padding-right: 5px; 
    color: #fff; 
    text-align: center; 
    line-height: 20px; 
    vertical-align: middle; 
    height: 20px; 
    min-width: 20px; 
`; 


export const ListItemsWrapper = styled.div`
    position: relative; 
    height: calc(100% - 156px); 
    overflow-y: auto; 
`; 

// export const ListItemContainer = styled.div`
//     position: static; 
// `;

export const ListItemPlaceholderEl = styled.div`
    width: (100% - 40px); 
    margin-left: 20px; 
    margin-right: 20px; 
    background-color: #eee; 
    border-radius: 3px; 
    line-height: 48px; 
    text-indent: 20px; 
    vertical-align: middle; 
`;





export const ListTitle = styled.div`
    flex-grow: 1; 
    height: 36px; 
    display: flex;
`; 

export const ListTitleLabel = styled.div`

    font-weight: bold; 
    line-height: 36px; 
    vertical-align: middle; 
    cursor: pointer; 
    &:hover {
        background: #c0c0c0; 
    }
`; 

export const ItemHoverContainer = styled.div`
    padding-bottom: 10px; 
    width: calc(100% - 40px);
    min-height: 48px; 
    margin-left: 20px; 
    margin-right: 20px; 
    position: relative; 
`; 

export const ItemWrapper = styled.div`
    border: solid 1px #ddd; 
    background-color: #fff; 
    width: 100%; 
    display: flex; 
    flex-direction: row;  
    position: relative; 
    height: 48px;  
    border-radius: 3px; 
    &:hover: {
        background-color: #efe;  
    }
`; 

export const ItemTitle = styled.div`
    margin-left: 20px;
    height: 48px; 
    min-width: 200px;
    font-weight: bold; 
    fontSize: 16px;
    flex-grow: 1; 
    cursor: pointer;
`;

export const ItemAlias = styled.div`
    margin-left: 20px;
    height: 48px; 
    min-width: 100px;
    font-weight: bold; 
    fontSize: 16px;
    flex-grow: 1; 
    cursor: pointer;
`;

export const ItemAliasLabel = styled.div`
    height: 48px;
    line-height: 48px; 
    vertical-align: middle; 
    cursor: pointer; 
    font-size: 12;
`; 

export const ItemHandle = styled.div`
    position: absolute; 
    left: 0px; 
    top: 0px; 
    width: 15px; 
    background-color: #eee; 
    height: 48px; 
    cursor: grab; 
`; 

export const CurrentListItemBackdrop = styled.div`
    height: 48px; 
    width: (100% - 40px); 
    margin-bottom: 10px; 
    margin-left: 20px; 
    margin-right: 20px; 
    background-color: #bbb; 
    border-radius: 3px; 
    line-height: 48px; 
    text-indent: 20px; 
    vertical-align: middle; 
`
