import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { AccountAggregate } from 'gen/aggregates/AccountAggregate';
import React from 'react'; 

const AccountDetailDetails : React.FC<{ account: AccountAggregate }> = ({ account }) => { 

    return (
        <div>
            <Paper style={{ margin: 20, padding: 20 }} elevation={3}>
                <Table>
                    <TableBody>
                        
                        <TableRow>
                            <TableCell>Account #</TableCell>
                            <TableCell>{account.AccountID}</TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell>Account Owner</TableCell>
                            <TableCell>{account.OwnerName}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>{account.Title}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Max Users</TableCell>
                            <TableCell>{account.MaxUserCount}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>User Count</TableCell>
                            <TableCell>{account.UserCount}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </Paper>
        </div>
    ); 
    
}

export default AccountDetailDetails; 