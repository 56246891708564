export type SearchQuoteDTO = {
    DateFrom: string; 
    DateTo: string; 
    QuoteTypes: number[]; 
    BidTypes: number[]; 
    Markets: number[]; 
    Statuses: number[]; 
    Probabilities: number[]; 
    Sales: number[]; 
    Vendors: number[]; 
    AreWeBidding: number[]; 
    SearchID: number; 

    Limit: number; 
    Page: number; 
    OrderBy: string; 
    OrderDir: string; 

    ResultCount: number; 
}

export const newSearchQuoteDTO = () : SearchQuoteDTO => ({
    DateFrom: '', 
    DateTo: '', 
    QuoteTypes: [], 
    BidTypes: [], 
    Markets: [], 
    Statuses: [], 
    Probabilities: [], 
    Sales: [], 
    Vendors: [], 
    AreWeBidding: [0], 
    SearchID: 0, 
    
    Limit: 50, 
    Page: 0, 
    OrderBy: 'QuoteNumberString', 
    OrderDir: 'DESC', 

    ResultCount: 0, 

})

const toNumberList = (l: string): number[] => !l ? [] : ((l.indexOf(",") > -1 ? l.split(",") : [l]).map(x => parseInt(x))); 

export const newSearchQuoteDTOFromMap = (map: Map<string, string>) : SearchQuoteDTO => ({
    DateFrom: map.has('dateFrom') ? map.get('dateFrom') as string  : '', 
    DateTo: map.has('dateTo') ? map.get('dateTo') as string : '', 
    QuoteTypes: map.has('quoteTypes') ? toNumberList(map.get('quoteTypes') as string)  : [], 
    BidTypes: map.has('bidTypes') ? toNumberList(map.get('bidTypes') as string)  : [], 
    Markets: map.has('markets') ? toNumberList(map.get('markets') as string)  : [], 
    Statuses: map.has('statuses') ? toNumberList(map.get('statuses') as string)  : [], 
    Probabilities: map.has('probabilities') ? toNumberList(map.get('probabilities') as string)  : [], 
    Sales: map.has('sales') ? toNumberList(map.get('sales') as string)  : [], 
    Vendors: map.has('vendors') ? toNumberList(map.get('vendors') as string) : [], 
    AreWeBidding: map.has('areWeBidding') ? toNumberList(map.get('areWeBidding') as string) : [0], 
    SearchID: map.has('searchID') ? parseInt(map.get('searchID') as string) : 0, 
    Limit: map.has('limit') ? parseInt(map.get('limit') as string) : 50, 
    Page: map.has('page') ? parseInt(map.get('page') as string) : 0, 
    OrderBy: map.has('orderBy') ? map.get('orderBy') as string : 'QuoteNumberString', 
    OrderDir: map.has('orderDir') ? map.get('orderDir') as string : 'DESC', 
    ResultCount: 0, 
})

export const searchQuoteDTOToMap = (dto: SearchQuoteDTO) : Map<string, string> => {

    const m = new Map(); 
    m.set('dateFrom', dto.DateFrom); 
    m.set('dateTo', dto.DateTo); 
    m.set('quoteTypes', dto.QuoteTypes ? dto.QuoteTypes.join(',') : ''); 
    m.set('bidTypes', dto.BidTypes ? dto.BidTypes.join(',') : ''); 
    m.set('markets', dto.Markets ? dto.Markets.join(',') : ''); 
    m.set('statuses', dto.Statuses ? dto.Statuses.join(',') : ''); 
    m.set('probabilities', dto.Probabilities ? dto.Probabilities.join(',') : ''); 
    m.set('sales', dto.Sales ? dto.Sales.join(',') : ''); 
    m.set('vendors', dto.Vendors ? dto.Vendors.join(',') : ''); 
    m.set('areWeBidding', dto.AreWeBidding ? dto.AreWeBidding.toString() : '');
    m.set('searchID', dto.SearchID ? dto.SearchID.toString() : 0); 
    m.set('limit', dto.Limit); 
    m.set('page', dto.Page); 
    m.set('orderBy', dto.OrderBy); 
    m.set('orderDir', dto.OrderDir); 
    m.set('resultCount', dto.ResultCount ? dto.ResultCount.toString() : '0'); 

    return m; 
}