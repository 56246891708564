/**
 *
 * CreateFiscalPeriodDTO
 */

export type CreateFiscalPeriodDTO = {

	// DateFrom string
	DateFrom: string;

	// DateTo string
	DateTo: string;

	// FiscalYearID int64
	FiscalYearID: number;

	// Ordinal int64
	Ordinal: number;

}

// newCreateFiscalPeriodDTO is a factory method for new CreateFiscalPeriodDTO objects
export const newCreateFiscalPeriodDTO = () : CreateFiscalPeriodDTO => ({
	DateFrom: '',
	DateTo: '',
	FiscalYearID: 0,
	Ordinal: 0,
});

