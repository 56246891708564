import { FormControl, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
    
export const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

export const GridLabel = styled(Grid)`
    text-align: right; 
    line-height: 57px; 
    vertical-align: middle; 
`;

export const Label = styled.span`
    color: #666; 
    font-weight: bold; 
`; 

export const GridRow = (props: { title: string, children: any, left?: boolean }) => {

    const left = props.left ? 4 : 5; 
    const right = props.left ? 8 : 7;  

    return (
        <React.Fragment>
            <GridLabel item xs={left}>
                <Label>
                    <small>{props.title}</small>
                </Label>
            </GridLabel>
            <Grid item xs={right}>
                {props.children}
            </Grid>
        </React.Fragment>
    )
}