/**
 *
 * UpdateJobItemDTO
 */

export type UpdateJobItemDTO = {

	// AllocatedProfit float64
	AllocatedProfit: number;

	// AllocatedSale float64
	AllocatedSale: number;

	// Cost float64
	Cost: number;

	// EstimatedShipDate string
	EstimatedShipDate: string;

	// JobItemID int64
	JobItemID: number;

	// OrderDate string
	OrderDate: string;

	// OrderNumber string
	OrderNumber: string;

	// Ordinal int64
	Ordinal: number;

	// PONumberToVendor string
	PONumberToVendor: string;

	// ProductDescription string
	ProductDescription: string;

	// RequestedShipDate string
	RequestedShipDate: string;

	// VendorID int64
	VendorID: number;

}

// newUpdateJobItemDTO is a factory method for new UpdateJobItemDTO objects
export const newUpdateJobItemDTO = () : UpdateJobItemDTO => ({
	AllocatedProfit: 0,
	AllocatedSale: 0,
	Cost: 0,
	EstimatedShipDate: '',
	JobItemID: 0,
	OrderDate: '',
	OrderNumber: '',
	Ordinal: 0,
	PONumberToVendor: '',
	ProductDescription: '',
	RequestedShipDate: '',
	VendorID: 0,
});

