
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel } from '@material-ui/core';
import { useCreateCustomerContact } from 'app/api/customers';
import handleNetworkError from 'core/utils/handleNetworkError';
import { CustomerContact } from 'gen/models/CustomerContact';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { createCustomerContactAtom, showCreateQuoteCustomerContactDialogAtom } from '../state';
import { selectedQuoteAtom, selectedQuoteCustomerAtom } from './state';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`
const CreateQuoteCustomerContactDialog = () => {
    
    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const customer  = useRecoilValue(selectedQuoteCustomerAtom); 
    const [createContact, { status, error }] = useCreateCustomerContact(); 
    const [contact, setContact] = useRecoilState(createCustomerContactAtom); 
    const resetContact = useResetRecoilState(createCustomerContactAtom); 
    const [showing, setShowing] = useRecoilState(showCreateQuoteCustomerContactDialogAtom); 
    
    const save = () => {
        
        createContact({ ...contact, CustomerID: customer.CustomerID })
            .then((response) => {
                
                const newContact = response?.data as CustomerContact; 
                const idx = quote.Customers.findIndex(x => x.CustomerID === customer.CustomerID); 

                setQuote({
                    ...quote, 
                    Customers: Object.assign(
                        [...quote.Customers], 
                        {[idx]: {
                            ...quote.Customers[idx],
                            Customer: {
                                ...quote.Customers[idx].Customer, 
                                ContactCount: quote.Customers[idx].Customer.ContactCount + 1, 
                            },  
                            CustomerContactID: newContact.CustomerContactID, 
                            Contact: newContact, 
                        }}
                    )
                });

                close(); 
            });

    }

    const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact,
            FirstName: event.target.value,
        });
    };

    const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            LastName: event.target.value, 
        })
    }

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Email: event.target.value, 
        })
    }
    
    const onPhoneChanged = (value: string) => {
        setContact({
            ...contact, 
            Phone: value, 
        })
    }

    const onPhoneExtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            PhoneExt: parseInt(event.target.value as string), 
        })
    }
    
    const onCellChanged = (value: string) => {
        setContact({
            ...contact, 
            Cell: value, 
        })
    }

    const onRoleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Role: event.target.value, 
        })
    }

    const close = () => {
        setShowing(false); 
        resetContact(); 
    }

    return <Dialog open={showing} onBackdropClick={close}>
        
        <DialogTitle>Create a contact for <i>{customer.Customer.Title}</i></DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">
                    {handleNetworkError(error)}
                </Alert>
            }

            <StyledFormControl>
                <InputLabel>First Name</InputLabel>
                <Input type="text" name="FirstName" value={contact.FirstName} onChange={onFirstNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Last Name</InputLabel>
                <Input type="text" name="LastName" value={contact.LastName} onChange={onLastNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Role</InputLabel>
                <Input type="text" name="Role" value={contact.Role} onChange={onRoleChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Email</InputLabel>
                <Input type="text" name="Email" value={contact.Email} onChange={onEmailChanged} /> 
            </StyledFormControl>
            
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    Phone: <ReactPhoneInput country="us" value={contact.Phone} onChange={onPhoneChanged} /> 
                </Grid>
                <Grid item xs={4}>
                    Ext. <Input type="number" value={contact.PhoneExt} onChange={onPhoneExtChanged} /> 
                </Grid>
            </Grid>
            Cell: <ReactPhoneInput country="us" onChange={onCellChanged} /> 

        </DialogContent>
        <DialogActions>

            <Button onClick={save} disabled={status === "loading"}>
                Create Contact
                {status === "loading" && 
                    <CircularProgress size={20} /> 
                }
            </Button>

            <Button onClick={close} disabled={status === "loading"}>
                Cancel
            </Button>

        </DialogActions>

    </Dialog>
}

export default CreateQuoteCustomerContactDialog; 