import { ArrowRight } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

type Parent = { 
    url?: string; 
    title: string; 
}



const AdminHeader : React.FC<{ 
    title: String, 
    parents?: Parent[], 
}> = ({ title, parents }) => { 

    return (
        <div style={{ display: 'inline-block', marginRight: 20, lineHeight: '48px', verticalAlign: 'middle' }}>
            <Link to={`/admin/`}>Admin</Link> 
            {title.length > 0 && (
                <>
                    <ArrowRight /> 
                    {(parents !== undefined && parents.length > 0) && 
                        <>
                            {parents.map((p, k) => {
                                if(p.url !== undefined) { 
                                    return <span key={k}><Link to={p.url}>{p.title}</Link><ArrowRight /></span>
                                }
                                return <span key={k}><strong>{p.title}</strong><ArrowRight /></span>
                            })}
                        </>
                    }
                    <strong>{title}</strong>
                </>
            )}

        </div>
    );
}


export default AdminHeader; 