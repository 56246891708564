import { newUserAggregate, UserAggregate } from 'core/definitions/aggregates/UserAggregate';
import { useEffect, useState } from 'react'; 
import userService from './userService';


export const useIsLoggedIn = () : boolean => { 

    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    
    useEffect(() => { 
        userService.subscribe("login", () => { 
            // console.log('subscribeToLogin: setIsLoggedIn'); 
            setIsLoggedIn(true)
        }, "login_isLoggedIn"); 
        userService.subscribe("logout", () => setIsLoggedIn(false), "logout_isLoggedIn"); 
    }, [])

    return isLoggedIn; 
}

export const useSelf = () : UserAggregate | undefined => { 

    const [user, setUser] = useState<UserAggregate | undefined>(userService.user); 

    useEffect(() => { 
        userService.subscribe("login", () => { 
            // console.log('subscribeToLogin: useSelf'); 
            setUser(userService.user); 
        }, "login_setUser");

        userService.subscribe("logout", () => setUser(newUserAggregate()), "logout_setUser"); 
    }, []);

    return user; 

}

export const useWSStatus = () : string => { 

    const [status, setStatus] = useState<string | undefined>(undefined); 

    useEffect(() => { 
        userService.subscribe("wsstatus", (status: string) => { 
            setStatus(status); 
        }, "wsstatus_setStatus"); 
    }, []);

    return status || ""; 
}


