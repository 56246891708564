/**
 *
 * CreateUserGroupDTO
 */

export type CreateUserGroupDTO = {

	// Description string
	Description: string;

	// Title string
	Title: string;

}

// newCreateUserGroupDTO is a factory method for new CreateUserGroupDTO objects
export const newCreateUserGroupDTO = () : CreateUserGroupDTO => ({
	Description: '',
	Title: '',
});

