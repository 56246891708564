import { QuoteAggregate } from "app/definitions/aggregates/QuoteAggregate";
import { QuoteBiddingPageAggregate } from "app/definitions/aggregates/QuoteBiddingPageAggregate";
import { QuoteListAggregate } from "app/definitions/aggregates/QuoteListAggregate";
import { QuoteRevisionSummaryAggregate } from "app/definitions/aggregates/QuoteRevisionSummaryAggregate";
import { QuoteSearchAggregate } from "app/definitions/aggregates/QuoteSearchAggregate";
import { CreateQuoteSearchDTO } from "app/definitions/dtos/CreateQuoteSearchDTO";
import { SearchQuoteDTO } from "app/definitions/dtos/SearchQuoteDTO";
import { UpdateQuoteDTO } from "app/definitions/dtos/UpdateQuoteDTO";
import { UpdateQuoteSearchDTO } from "app/definitions/dtos/UpdateQuoteSearchDTO";
import axios from "axios";
import { Paged } from "core/definitions/utils/Paged";
import { SearchResultsAggregate } from "gen/aggregates/SearchResultsAggregate";
import download from 'downloadjs';
import { CreateEmptyQuoteDTO } from "gen/dtos/CreateEmptyQuoteDTO";
import { CreateQuoteItemDTO } from 'gen/dtos/CreateQuoteItemDTO';
import { UpdateQuoteItemDTO } from "gen/dtos/UpdateQuoteItemDTO";
import { QuoteItem } from "gen/models/QuoteItem";
import { queryCache, useMutation, useQuery } from "react-query";

//#region Quotes 

// Create Quote 
export const createQuote = async (request: CreateEmptyQuoteDTO) => await axios.post<QuoteAggregate>(
  "/quotes",
  request, 
);

export const deleteQuote = async (quoteID: number) => await axios.delete<any>(`/quotes/${quoteID}`); 
export const useDeleteQuote = () => useMutation(deleteQuote, { onSuccess: (data, variables) => queryCache.invalidateQueries(["getQuotes"])})


// Update Quote
export const updateQuote = async (request: UpdateQuoteDTO) => await axios.put<QuoteAggregate>(`/quotes/${request.QuoteID}`, request);
export const useUpdateQuote = () => useMutation(updateQuote, {
      onSuccess: (data, variables) => {
        queryCache.invalidateQueries(["getQuoteFromID", variables.QuoteID]);
      },
    });

// Get Quotes 
export const getQuoteList = async (_: string, query: SearchQuoteDTO) => await axios.post<QuoteListAggregate>(`/quotes/list`, query);
export const useGetQuoteList = (query: SearchQuoteDTO) => useQuery(["getQuotes", query], getQuoteList);

// Get Quote From ID
export const getQuoteFromID = async (_: string, quoteID: number) => await axios.get<QuoteAggregate>(`/quotes/${quoteID}`);
export const useGetQuoteFromID = (quoteID: number) => useQuery(["getQuoteFromID", quoteID], getQuoteFromID, { refetchOnWindowFocus: false });

// Search Quotes 
export const searchQuotes = async (searchString: string) => await axios.get<SearchResultsAggregate>(`/quotes?search=${searchString}`);

//#endregion

//#region Quote Items 

// Create Quote Item 
export const createQuoteItem = async (request: CreateQuoteItemDTO & { QuoteID: number }) => await axios.post<QuoteItem>(
  `/quotes/${request.QuoteID}/items`,
    request
  )

export const useCreateQuoteItem = () => {
  return useMutation(createQuoteItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("");
    },
  });
};
  

// Update Quote Item 
export const updateQuoteItem = async (request: UpdateQuoteItemDTO & { QuoteID: number; QuoteItemID: number }) => await axios.put<QuoteItem>(
    `/quotes/${request.QuoteID}/items/${request.QuoteItemID}`,
    request
  );

export const useUpdateQuoteItem = () => {
  return useMutation(updateQuoteItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getQuoteFromID", variables.QuoteID]);
    },
    onError: (error: any) => {
      console.log('useCreateQuote.Error', error.response); 
    }
  });
};

// Delete Quote Item 
export const deleteQuoteItem = async (request: { QuoteID: number, QuoteItemID: number }) => await axios.delete(
  `/quotes/${request.QuoteID}/items/${request.QuoteItemID}`
);
export const useDeleteQuoteItem = () => {
  return useMutation(deleteQuoteItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getQuoteFromID", variables.QuoteID]);
    },
    onError: (error: any) => {
      console.log('useCreateQuote.Error', error.response); 
    }
  });
};

//#endregion Quote Items 

//#region Quote Revisions 
// Create Quote Revision 
export const createQuoteRevision = async (args: { quoteID: number, quoteNumberID: number }) => await axios.post<QuoteAggregate>(
  `/quotes/${args.quoteID}/revisions`
)
export const useCreateQuoteRevision = () => useMutation(createQuoteRevision, { 
  onSuccess: (data, args) => queryCache.invalidateQueries(["getQuoteRevisions", args.quoteNumberID])
})


// Get Quote Revisions 
export const getQuoteRevisions = async (quoteNumberID: number) => await axios.get<QuoteRevisionSummaryAggregate[]>(
  `/quotes/${quoteNumberID}/revisions`
)
export const useGetQuoteRevisions = (quoteNumberID: number) => {
  const wrapper = async(_: string, quoteNumberID: number) => await getQuoteRevisions(quoteNumberID); 
  return useQuery(["getQuoteRevisions", quoteNumberID], wrapper); 
}


//#endregion Quote Revisions 

//#region Saved Searches 

// Get Quotes from Saved Search 
export const getQuotesWithSavedSearch = async (_: string, savedSearchID: number) => await axios.get<Paged<QuoteAggregate>>(`/quotes/list/saved/${savedSearchID}`)
export const useGetQuotesWithSavedSearch = (savedSearchID: number) => useQuery(["getQuotesWithSavedSearch", savedSearchID], getQuotesWithSavedSearch); 
  

// Get Quote Searches 
export const getQuoteSearches = async (_: string) => await axios.get<QuoteSearchAggregate[]>(`/quotes/searches`);
export const useGetQuoteSearches = () => useQuery("getQuoteSearches", getQuoteSearches); 

// Get default quote search 
export const getDefaultQuoteSearch = async (_: string) => await axios.get<QuoteSearchAggregate>(`/quotes/searches/default`);
export const useGetDefaultQuoteSearch = () => useQuery("getDefaultQuoteSearch", getDefaultQuoteSearch); 


// Create Quote Search
export const createQuoteSearch = async (body: CreateQuoteSearchDTO) => await axios.post<QuoteSearchAggregate>(`/quotes/searches`, body)
export const useCreateQuoteSearch = () => useMutation(createQuoteSearch, { onSuccess: (data, variables) => {
  queryCache.invalidateQueries("getQuoteSearches")
}})

// Update Quote Search 
export const updateQuoteSearch = async (body: UpdateQuoteSearchDTO) => await axios.put<QuoteSearchAggregate>(`/quotes/searches/${body.ObjectSearchID}`, body)
export const useUpdateQuoteSearch = () => useMutation(updateQuoteSearch, { onSuccess: (data, variables) => { 
  queryCache.invalidateQueries("getQuoteSearches"); 
}})

// Delete Quote Search
export const deleteQuoteSearch = async (objectSearchID: number) => await axios.delete<any>(`/quotes/searches/${objectSearchID}`)
export const useDeleteQuoteSearch = () => useMutation(deleteQuoteSearch, { onSuccess: (data, variables) => {
  queryCache.invalidateQueries("getQuoteSearches")
}})

//#endregion Saved Searches 

// GetBiddingInTheFuture
export const getBiddingInTheFuture = async (_: string, page: number, limit: number) => await axios.get<QuoteBiddingPageAggregate>(`/quotes/bidding?page=${page}&limit=${limit}`); 
export const useGetBiddingInTheFuture = (page: number, limit: number) => useQuery(["getBiddingInTheFuture", page, limit], getBiddingInTheFuture)

export const downloadQuotesBiddingExcel = async () => await axios.post(`/quotes/bidding/download/excel`, {}, {
  responseType: 'blob', 
})
  .then((resp) => {
    download(resp.data, "quotes-bidding.xlsx", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
  });


  export const downloadQuotesBiddingPDF = async () => await axios.post(`/quotes/bidding/download/pdf`, {}, {
    responseType: 'blob', 
  })
    .then((resp) => {
      download(resp.data, "quotes-bidding.pdf", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
    });
  


export const downloadQuotesExcel = async (search: SearchQuoteDTO) => await axios.post(`/quotes/download/excel`, search, {
  responseType: 'blob', 
})
  .then((resp) => {
    download(resp.data, "test.xlsx", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
  });


  export const downloadQuotesPDF = async (search: SearchQuoteDTO) => await axios.post(`/quotes/download/pdf`, search, {
    responseType: 'blob', 
  })
    .then((resp) => {
      download(resp.data, "quotes.pdf", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
    });
  

  
    



