/**
 *
 * CreateCustomerEventDTO
 */

export type CreateCustomerEventDTO = {

	// Duration int64
	Duration: number;

	// EventDateEnd string
	EventDateEnd: string;

	// EventDateStart string
	EventDateStart: string;

	// Notes string
	Notes: string;

}

// newCreateCustomerEventDTO is a factory method for new CreateCustomerEventDTO objects
export const newCreateCustomerEventDTO = () : CreateCustomerEventDTO => ({
	Duration: 0,
	EventDateEnd: '',
	EventDateStart: '',
	Notes: '',
});

