    import { QuoteItemAggregate } from 'app/definitions/aggregates/QuoteItemAggregate';
import { ListItem, newListItem } from 'gen/models/ListItem';
import { newQuote, Quote } from 'gen/models/Quote';
import { QuoteCustomerAggregate } from './QuoteCustomerAggregate';
import { QuoteSalesAggregate } from './QuoteSalesAggregate';
export type QuoteAggregate = Quote & {
    QuoteNumber: string; 
    CurrentRevision: number; 
    Customers: QuoteCustomerAggregate[]; 
    Items: Array<QuoteItemAggregate>; 
    Sales: Array<QuoteSalesAggregate>; 
    QuoteType: ListItem; 
    QuoteStatus: ListItem; 
    Market: ListItem; 
    BidType: ListItem; 
    Probability: ListItem; 
    NextQuote: { QuoteID: number, QuoteNumber: string }; 
    PrevQuote: { QuoteID: number, QuoteNumber: string }; 
}

export const newQuoteAggregate = () : QuoteAggregate => ({
    ...newQuote(), 
    QuoteNumber: '', 
    CurrentRevision: 0, 
    Customers: [], 
    Items: [], 
    Sales: [], 
    QuoteType: newListItem(), 
    QuoteStatus: newListItem(), 
    Market: newListItem(), 
    BidType: newListItem(), 
    Probability: newListItem(), 
    NextQuote: { QuoteID: 0, QuoteNumber: '' }, 
    PrevQuote: { QuoteID: 0, QuoteNumber: '' }, 
})