import { JobAggregate } from "app/definitions/aggregates/JobAggregate"
import { JobSalesAggregate } from "app/definitions/aggregates/JobSalesAggregate"
import { newUpdateJobItemDTOFromModel, UpdateJobItemDTO } from "./UpdateJobItemDTO"

export type UpdateJobDTO = {
    JobID: number; 
    Notes: string; 
    AwardDate: string; 
    JEFDate: string; 
    
    BillingAddress: string; 
    BillingAddressCity: string; 
    BillingAddressState: string; 
    BillingAddressZip: string; 

    ShippingAddress: string; 
    ShippingAddressCity: string; 
    ShippingAddressState: string; 
    ShippingAddressZip: string; 
    
    CommissionTypeID: number; 
    CustomerPO1Number: string; 
    CustomerPO1SentTo: number; 
    CustomerPO2Number: string; 
    CustomerPO2SentTo: number; 
    IsAddFreight: number; 
    IsThirdPartySplit: number; 
    ThirdPartyName: string; 
    Items: UpdateJobItemDTO[]; 
    Sales: JobSalesAggregate[]; 
    ProjectCost: number; 
    GrossMarginPercent: number; 
    GrossProfit: number; 
    ThirdPartySplitPercent: number; 
    ThirdPartyCommission: number; 
    RemainingGrossProfit: number; 
    TotalPrice: number; 
    TaxExemptNumber: string; 
    InsideSalesID: number; 

    RelationshipID: number;               
	CallAhead: string;              
	ShipToName: string;              
	ShipToTag: string;              
	PropertyName: string;              
	PropertyAddress: string;              
	PropertyCity: string;              
	PropertyState: string;              
	PropertyZip: string;              
	ContractorName: string;              
	ContractorAddress: string;              
	ContractorCity: string;              
	ContractorState: string;              
	ContractorZip: string;              
	PublicPrivateMoneyID: number;               
	BondStatusID: number;               
	CompanyProvidingBond: string;              
	Comments: string;   
    TotalPriceActual: number; 
    TotalCostActual: number;            
    
}

export const newUpdateJobDTO = () : UpdateJobDTO => ({
    JobID: 0, 
    Notes: '', 
    AwardDate: '', 
    JEFDate: '', 
    BillingAddress: '', 
    BillingAddressCity: '', 
    BillingAddressState: '', 
    BillingAddressZip: '', 
    
    ShippingAddress: '', 
    ShippingAddressCity: '', 
    ShippingAddressState: '', 
    ShippingAddressZip: '', 

    CommissionTypeID: 0, 
    CustomerPO1Number: '', 
    CustomerPO1SentTo: 0,  
    CustomerPO2Number: '', 
    CustomerPO2SentTo: 0,  
    IsAddFreight: 0,  
    IsThirdPartySplit: 0,  
    ThirdPartyName: '',  
    Items: [], 
    Sales: [], 
    ProjectCost: 0, 
    GrossMarginPercent: 0, 
    GrossProfit: 0, 
    ThirdPartySplitPercent: 0, 
    ThirdPartyCommission: 0, 
    RemainingGrossProfit: 0, 
    TotalPrice: 0, 
    TaxExemptNumber: '', 
    InsideSalesID: 0,

    RelationshipID: 0,                
	CallAhead: '', 
	ShipToName: '', 
	ShipToTag: '', 
	PropertyName: '', 
	PropertyAddress: '', 
	PropertyCity: '', 
	PropertyState: '', 
	PropertyZip: '', 
	ContractorName: '', 
	ContractorAddress: '', 
	ContractorCity: '', 
	ContractorState: '', 
	ContractorZip: '', 
	PublicPrivateMoneyID: 0,                
	BondStatusID: 0,                
	CompanyProvidingBond: '', 
	Comments: '', 

    TotalPriceActual: 0, 
    TotalCostActual: 0, 

})

export const newUpdateJobDTOFromModel = (model: JobAggregate) : UpdateJobDTO => {
  
    const dto : UpdateJobDTO = {
        JobID: model.JobID, 
        Notes: model.Notes, 
        AwardDate: model.AwardDateString, 
        JEFDate: model.JEFDateString, 
        BillingAddress: model.BillingAddress, 
        BillingAddressCity: model.BillingAddressCity, 
        BillingAddressState: model.BillingAddressState, 
        BillingAddressZip: model.BillingAddressZip, 
        
        ShippingAddress: model.ShippingAddress, 
        ShippingAddressCity: model.ShippingAddressCity, 
        ShippingAddressState: model.ShippingAddressState, 
        ShippingAddressZip: model.ShippingAddressZip, 

        CommissionTypeID: model.CommissionTypeID, 
        CustomerPO1Number: model.CustomerPO1Number,  
        CustomerPO1SentTo: model.CustomerPO1SentTo,  
        CustomerPO2Number: model.CustomerPO2Number,  
        CustomerPO2SentTo: model.CustomerPO2SentTo,  
        IsAddFreight: model.IsAddFreight,  
        IsThirdPartySplit: model.IsThirdPartySplit,  
        ThirdPartyName: model.ThirdPartyName,  
        Items: [], 
        Sales: model.Sales, 
        ProjectCost: model.ProjectCost, 
        GrossMarginPercent: model.GrossMarginPercent, 
        GrossProfit: model.GrossProfit, 
        ThirdPartySplitPercent: model.ThirdPartySplitPercent, 
        ThirdPartyCommission: model.ThirdPartyCommission, 
        RemainingGrossProfit: model.RemainingGrossProfit, 
        TotalPrice: model.TotalPrice, 
        TaxExemptNumber: model.TaxExemptNumber, 
        InsideSalesID: model.InsideSalesID, 

        RelationshipID: model.RelationshipID, 
        CallAhead: model.CallAhead, 
        ShipToName: model.ShipToName, 
        ShipToTag: model.ShipToTag, 
        PropertyName: model.PropertyName, 
        PropertyAddress: model.PropertyAddress, 
        PropertyCity: model.PropertyCity, 
        PropertyState: model.PropertyState, 
        PropertyZip: model.PropertyZip, 
        ContractorName: model.ContractorName, 
        ContractorAddress: model.ContractorAddress, 
        ContractorCity: model.ContractorCity, 
        ContractorState: model.ContractorState, 
        ContractorZip: model.ContractorZip, 
        PublicPrivateMoneyID: model.PublicPrivateMoneyID, 
        BondStatusID: model.BondStatusID, 
        CompanyProvidingBond: model.CompanyProvidingBond, 
        Comments: model.Comments, 
        TotalPriceActual: model.TotalPriceActual, 
        TotalCostActual: model.TotalCostActual, 
    }

    model.Items.map(item => dto.Items.push(newUpdateJobItemDTOFromModel(item)))

    return dto
}