/**
 *
 * Form
 */

export type Form = {

	// AccountID int64
	AccountID: number;

	// CreateButtonText string
	CreateButtonText: string;

	// CreateResponseTextSuccess string
	CreateResponseTextSuccess: string;

	// DateCreated int64
	DateCreated: number;

	// Description null.String
	Description: string;

	// FieldCount int64
	FieldCount: number;

	// FormID int64
	FormID: number;

	// FormRevisionID int64
	FormRevisionID: number;

	// Guid string
	Guid: string;

	// IsDeleted int
	IsDeleted: number;

	// LastSubmitted int64
	LastSubmitted: number;

	// ShowCreatedByOnTableView int
	ShowCreatedByOnTableView: number;

	// ShowDateCreatedOnTableView int
	ShowDateCreatedOnTableView: number;

	// Slug string
	Slug: string;

	// StringIDPrefix string
	StringIDPrefix: string;

	// SubmissionCount int64
	SubmissionCount: number;

	// Title string
	Title: string;

	// UpdateButtonText string
	UpdateButtonText: string;

	// UpdateResponseTextSuccess string
	UpdateResponseTextSuccess: string;

}

// newForm is a factory method for creating new Form objects
export const newForm = () : Form => ({ 
	AccountID: 0,
	CreateButtonText: '',
	CreateResponseTextSuccess: '',
	DateCreated: 0,
	Description: '',
	FieldCount: 0,
	FormID: 0,
	FormRevisionID: 0,
	Guid: '',
	IsDeleted: 0,
	LastSubmitted: 0,
	ShowCreatedByOnTableView: 0,
	ShowDateCreatedOnTableView: 0,
	Slug: '',
	StringIDPrefix: '',
	SubmissionCount: 0,
	Title: '',
	UpdateButtonText: '',
	UpdateResponseTextSuccess: '',
});

