/**
 *
 * Admin.AdminSettings
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateSettingDTO } from 'gen/dtos/CreateSettingDTO';
import { Setting } from 'gen/models/Setting';
import { SettingsAggregate } from 'gen/aggregates/SettingsAggregate';
import { UpdateSettingDTO } from 'gen/dtos/UpdateSettingDTO';

// GetAllSettings gets all settings
// GET /admin/settings?objectType={objectType:[0-9]+}&page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission AdminSettings_GetAllSettings
export const getAllSettings = async (objectType : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<SettingsAggregate>(`/admin/settings?objectType=${objectType}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetAllSettings = (objectType : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getAllSettings", objectType, page, limit, orderBy, orderDir], (_ : string, objectType : number, page : number, limit : number, orderBy : string, orderDir : string) => getAllSettings(objectType,page,limit,orderBy,orderDir));

// CreateSetting creates a setting
// POST /admin/settings
// @permission AdminSettings_CreateSetting
export const createSetting = async (args : { body : CreateSettingDTO }) => await axios.post<Setting>(`/admin/settings`, args.body);
export const useCreateSetting = () => useMutation(createSetting, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllSettings"]);
	},
	throwOnError: true, 
});
	

// UpdateSetting 
// PUT /admin/settings/{settingID:[0-9]+}
// @permission AdminSettings_UpdateSetting
export const updateSetting = async (args : { settingID : number, body : UpdateSettingDTO }) => await axios.put<Setting>(`/admin/settings/${args.settingID}`, args.body);
export const useUpdateSetting = () => useMutation(updateSetting, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllSettings"]);
	},
	throwOnError: true, 
});
	

// DeleteSetting deletes a setting
// DELETE /admin/settings/{settingID:[0-9]+}
// @permission AdminSettings_DeleteSetting
export const deleteSetting = async (args : { settingID : number }) => await axios.delete<any>(`/admin/settings/${args.settingID}`);
export const useDeleteSetting = () => useMutation(deleteSetting, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllSettings"]);
	},
	throwOnError: true, 
});
	

// ExportSettings exports a setting
// GET /admin/settings/export
// @permission AdminSettings_ExportSettings
export const exportSettings = async () => await axios.get(`/admin/settings/export`, { responseType: 'blob' });
export const useExportSettings = () => useQuery(["exportSettings"], (_ : string) => exportSettings());

// ImportSettings imports settings
// POST /admin/settings/import
// @permission AdminSettings_ImportSettings
export const importSettings = async (args : { body : FormData }) => await axios.post<any>(`/admin/settings/import`, args.body, { headers: { "content-type": "multipart/form-data" } });
export const useImportSettings = () => useMutation(importSettings, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllSettings"]);
	},
	throwOnError: true, 
});
	
