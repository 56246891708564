/**
 *
 * CreateTaskBatchScheduleDTO
 */

export type CreateTaskBatchScheduleDTO = {

	// DOM int64
	DOM: number;

	// DOW int64
	DOW: number;

	// HOD int64
	HOD: number;

	// MOH int64
	MOH: number;

}

// newCreateTaskBatchScheduleDTO is a factory method for new CreateTaskBatchScheduleDTO objects
export const newCreateTaskBatchScheduleDTO = () : CreateTaskBatchScheduleDTO => ({
	DOM: 0,
	DOW: 0,
	HOD: 0,
	MOH: 0,
});

