import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useCreateForm } from 'gen/routes/Forms';
import { FormAggregate } from 'core/definitions/aggregates/FormAggregate';
import { CreateFormDTO, newCreateFormDTO } from 'gen/dtos/CreateFormDTO';
import React, { useState } from 'react';

enum Status { 
    None, 
    Loading, 
    Success, 
    Error, 
}

const CreateFormDialog : React.FC<{ open: boolean, onClose: () => void, onSave: (form: FormAggregate) => void }> = ({ open, onClose, onSave }) => { 

    const [status, setStatus] = useState<Status>(Status.None); 
    const [form, setForm] = useState<CreateFormDTO>(newCreateFormDTO()); 
    const [createForm] = useCreateForm(); 

    const doCreateForm = () => { 
        setStatus(Status.Loading); 
        setTimeout(() => { 
            createForm({ body: form })
                .then(response => { 
                    if(response) { 
                        onSave(response.data) 
                    }
                    close(); 
                })
        }, 1000); 
    }

    const close = () => { 
        setStatus(Status.None); 
        setForm(newCreateFormDTO()); 
        onClose(); 
    }

    return ( 
        <Dialog open={open} onClose={onClose}>

            <DialogTitle>Create Form</DialogTitle>

            <DialogContent>
                
                <TextField value={form.Title} fullWidth onChange={(e) => setForm({ ...form, Title: e.target.value as string })} label="Title" /> 
                
                <br /> 
                
                <TextField value={form.Description} fullWidth rows={3} rowsMax={3} multiline onChange={(e) => setForm({ ...form, Description: e.target.value as string })} label="Description" /> 

            </DialogContent>
            
            <DialogActions>
                
                <Button onClick={doCreateForm} variant="contained" disabled={status === Status.Loading}>
                    Create
                    {status === Status.Loading && <CircularProgress size={20} />}
                </Button>
                
                <Button onClick={close}>Cancel</Button>

            </DialogActions>

        </Dialog>
    )

}

export default CreateFormDialog; 