import { VendorAggregate } from "app/definitions/aggregates/VendorAggregate";
import { VendorItemsListItemAggregate } from "app/definitions/aggregates/VendorItemsListItemAggregate";
import { UpdateVendorDTO } from "app/definitions/dtos/UpdateVendorDTO";
import axios from "axios";
import { Paged } from "core/definitions/utils/Paged";
import { SearchResultsAggregate } from "gen/aggregates/SearchResultsAggregate";
import { CreateVendorDTO } from "gen/dtos/CreateVendorDTO";
import { CreateVendorItemDTO } from "gen/dtos/CreateVendorItemDTO";
import { UpdateVendorItemDTO } from "gen/dtos/UpdateVendorItemDTO";
import { Vendor } from "gen/models/Vendor";
import { VendorItem } from "gen/models/VendorItem";
import { queryCache, useMutation, useQuery } from "react-query";

// Create Vendor 
export const createVendor = async (request: CreateVendorDTO) => await axios.post<Vendor>(
    "/vendors",
    request, 
  );


// UPdate Vendor 
export const updateVendor = async (request: UpdateVendorDTO & { VendorID: number }) => await axios.put<Vendor>(
  `/vendors/${request.VendorID}`, 
  request, 
)

export const useUpdateVendor = () => {
  return useMutation(updateVendor, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getVendorFromID", variables.VendorID]) 
    }
  }); 
}

// Delete Vendor 
export const deleteVendor = async (vendorID: number) => await axios.delete<any> (
  `/vendors/${vendorID}`
)

// Get Vendors
export const getVendors = async (_: string, page: number, limit: number, orderBy: string, orderDir: string) => await axios.get<Paged<VendorAggregate>>(
  `/vendors?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`
)
export const useGetVendors = (page: number, limit: number, orderBy: string, orderDir: string) => useQuery(["getVendors", page, limit, orderBy, orderDir], getVendors);

// Search Vendors 
export const searchVendors = async (searchString: string) => await axios.get<SearchResultsAggregate>(
  `/vendors?search=${searchString}`
);

// GetVendorFromID
export const getVendorFromID = async (vendorID: number) => await axios.get<Vendor>(
  `/vendors/${vendorID}`
)
export const useGetVendorFromID = (vendorID: number) => {
  const wrapper = async (_: string, vendorID: number) => await getVendorFromID(vendorID); 
  return useQuery(["getVendorFromID", vendorID], wrapper)
}

export const getVendorsFromIDs = async (_: string, vendorIDs: number[]) => await axios.get<Vendor[]>(
  `/vendors?ids=${vendorIDs.join(",")}`
)
export const useGetVendorsFromIDs = (vendorIDs: number[]) => useQuery(["getVendorsFromIDs", vendorIDs], getVendorsFromIDs);

export const getVendorItems = async (vendorID: number, page: number, orderBy: string, orderDir: string) => await axios.get<Paged<VendorItem>>(
  `/vendors/${vendorID}/items?page=${page}&orderBy=${orderBy}&orderDir=${orderDir}`
)

export const createVendorItem = async (request: CreateVendorItemDTO & { VendorID: number }) => await axios.post<VendorItem>(
  `/vendors/${request.VendorID}/items`, 
  request, 
)

export const updateVendorItem = async (request: UpdateVendorItemDTO & { VendorID: number; VendorItemID: number }) => await axios.put<VendorItem>(
  `/vendors/${request.VendorID}/items/${request.VendorItemID}`, 
  request, 
)

export const deleteVendorItem = async (args: {vendorID: number, vendorItemID: number }) => await axios.delete<any>(
  `/vendors/${args.vendorID}/items/${args.vendorItemID}`
)

export const searchVendorItems = async (vendorID: number, searchString: string) => await axios.get<SearchResultsAggregate>(
  `/vendors/${vendorID}/items?search=${searchString}`
);

export const getVendorItemFromID = async (vendorID: number, itemID: number) => await axios.get<VendorItem>(
  `/vendors/${vendorID}/items/${itemID}`
)

export const getVendorItemsList = async (page: number) => await axios.get<Paged<VendorItemsListItemAggregate>>(
  `/vendors/items?page=${page}`
)

/********* custom react-query hooks  ********************************************/




export const useCreateVendor = () => {
  return useMutation(createVendor, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getVendors") 
    }
  }); 
}



export const useDeleteVendor = () => {
  return useMutation(deleteVendor, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getVendors") 
    }
  }); 
}

export const useGetVendorItems = (vendorID: number, page: number, orderBy: string, orderDir: string) => {
  const wrapper = async (_: string, vendorID: number) => await getVendorItems(vendorID, page, orderBy, orderDir); 
  return useQuery(["getVendorItems", vendorID, orderBy, orderDir], wrapper); 
}

export const useCreateVendorItem = () => {
  return useMutation(createVendorItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getVendorItems", variables.VendorID]) 
      queryCache.invalidateQueries(["getVendorFromID", variables.VendorID])
    }
  }); 
}

export const useUpdateVendorItem = () => {
  return useMutation(updateVendorItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getVendorItems", variables.VendorID]) 
    }
  }); 
}

export const useDeleteVendorItem = () => {
  return useMutation(deleteVendorItem, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(["getVendorItems", variables.vendorID]) 
    }
  }); 
}

export const useGetVendorItemFromID = (vendorID: number, itemID: number) => {
  const wrapper = async (_: string, vendorID: number, itemID: number) => await getVendorItemFromID(vendorID, itemID); 
  return useQuery(["getVendorItemFromID", vendorID, itemID], wrapper); 
}

export const useGetVendorItemsList = (page: number) => {
  const wrapper = async (_: string, page: number) => await getVendorItemsList(page); 
  return useQuery(["getVendorItemsList", page], wrapper); 
}