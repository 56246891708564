import { Grid } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import selectedManageRoleAtom from 'core/state/roles/selectedManageRoleAtom';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AdminHeader from '../common/AdminHeader';
import RoleDetail from './RoleDetail';
import RoleList from './RoleList';

const RolesManager = () => {

    const { id } = useParams() as { id: string }; 
    const setSelectedRole = useSetRecoilState(selectedManageRoleAtom); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(id) {
            setSelectedRole(parseInt(id)); 
        }
    }, [id])

    return (
        <div style={{ height: '100%'}}>
            <NavBarSecondary>
                <AdminHeader title={"Roles"} /> 
            </NavBarSecondary>
            <Grid container spacing={4} style={{ height: '100%', overflowY: 'hidden'}}>
                <Grid item xs={4}>
                    <RoleList /> 
                </Grid>
                <Grid item xs={8} style={{ height: '100%', overflowY: 'hidden'}}>
                    <RoleDetail /> 
                </Grid>
            </Grid>
        </div>
    )

}

export default RolesManager; 