import { Button, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, TableNav } from 'core/components/tables';
import { UserAggregate } from 'core/definitions/aggregates/UserAggregate';
import { CreateUserModal } from 'core/pages/admin/accounts/users/CreateUserModal';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import userService from 'core/utils/userService';
import { AccountAggregate } from 'gen/aggregates/AccountAggregate';
import { Users_CreateUserPermission, Users_GetUserSessionPermission } from 'gen/constants/permissions';
import { useGetRoles } from 'gen/routes/Roles';
import { useGetUserGroups } from 'gen/routes/UserGroups';
import { useGetAllUsers } from 'gen/routes/Users';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';

const AccountUsers : React.FC<{ account: AccountAggregate }> = ({ account }) => {

    const limit = 50; 
    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState('Email');
    const [orderDir, setOrderDir] = useState('ASC'); 
    const [groupID, setGroupID] = useState(0); 
    const [roleID, setRoleID] = useState(0); 
    const [showCreate, setShowCreate] = useState(false);
    
    // fetchURLQueryParamsFromString
    const { data: groupData, status: groupStatus, error: groupError } = useGetUserGroups(); 
    const { data: roleData, status: roleStatus, error: roleError } = useGetRoles(0, 'Name', 'ASC'); 
    const { data: userData, status: userStatus, error: userError } = useGetAllUsers(account.AccountID, groupID, roleID, "-1", page, limit, orderBy, orderDir); 

    const next = () => {
        if(userData) {
            const newOffset = limit * (page + 1); 
            if(newOffset >= userData.data.Count) {
                return; 
            }        
            setPage(page + 1); 
        }
    }

    const prev = () => {
        const newOffset = limit * (page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setPage(page - 1); 
    }

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    const history = useHistory(); 

    if(groupStatus === "error") { return <Alert color="danger">{handleNetworkError(groupError)}</Alert> }
    if(groupStatus === "loading" || !groupData) { return <CircularProgress /> }

    if(userStatus === "error") { return <Alert color="danger">{handleNetworkError(userError)}</Alert> }
    if(userStatus === "loading" || !userData) { return <CircularProgress /> }

    if(roleStatus === "error") { return <Alert color="danger">{handleNetworkError(roleError)}</Alert> }
    if(roleStatus === "loading" || !roleData) { return <CircularProgress /> }

    const users = userData.data.Data; 

    const doImpersonate = (userID: number) => () => { 
        userService.impersonate(userID, () => { 
            history.push(`/`); 
        });
    }
    
    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <NavBarSecondary level={2} right={
                <>
                    <TableNav {
                        ...{count: userData.data.Count, prev, next, page, limit: 20 }
                    } />

                    {/* <AdminActions actions={[
                        { title: 'View Disabled', fn: gotoDisabled, perm: Users_GetDisabledUsersPermission }
                    ]} />  */}
                </>

            }>               
                {/* <AdminHeader title={`${userData.data.Data.}`} parents={[{title: "Accounts", url: "/admin/accounts"}]} />  */}
                &nbsp; 

               
                <TextField 
                    select 
                    size="small" 
                    margin="none"
                    label="Group"
                    value={groupID}
                    onChange={(e) => setGroupID(parseInt(e.target.value as string))}
                >
                    <MenuItem value={0} key={-1}>None</MenuItem>
                    {groupData.data.map((group, key) => <MenuItem key={key} value={group.UserGroupID}>{group.Title} ({group.UserCount})</MenuItem>)}
                </TextField>
                <TextField
                    select 
                    size="small"
                    margin="none"
                    label="Role"
                    value={roleID}
                    onChange={(e) => setRoleID(parseInt(e.target.value as string))}>
                        <MenuItem value={0} key={-1}>None</MenuItem>
                        {roleData.data.Data.map((role, key) => <MenuItem key={key} value={role.RoleID}>{role.Name} ({role.UserCount})</MenuItem>)}
                </TextField>

            </NavBarSecondary>

            <Perm perm={Users_CreateUserPermission}>
                <Add action={() => setShowCreate(true)} /> 
            </Perm>

            <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>

                <DTable
                    cols={[
                        { label: "Email", orderFn: orderCol("Email"), val: (v: UserAggregate) => v.Email, size: 1, onClick: (row: UserAggregate) => () => history.push(`/admin/accounts/${row.AccountID}/users/${row.UserID}`) }, 
                        { label: "Prefix", orderFn: orderCol("UserPrefix"), val: (v: UserAggregate) => v.UserPrefix, size: 1 }, 
                        { label: "Name", orderFn: orderCol("FullName"), val: (v: UserAggregate) => <>{v.FullName}</>, size: 1 }, 
                        { label: "Active", val: (v: UserAggregate) => <>{v.DateActivated > 0 ? 'Yes' : 'No'}</>, size: 1 }, 
                        { label: "Groups", val: (v: UserAggregate) => v.UserGroupCount, size: 1 }, 
                        { label: "Roles", val: (v: UserAggregate) => v.RoleCount, size: 1 }, 
                        { label: "", val: (v: UserAggregate) => <>
                            <Perm perm={Users_GetUserSessionPermission}>
                                <Button onClick={doImpersonate(v.UserID)}>Impersonate</Button>
                            </Perm>
                        </>, size: 1 }, 
                    ]}
                    orderBy={orderBy}
                    orderDir={orderDir}
                    data={users}
                /> 
            </div>

            <CreateUserModal accountID={account.AccountID} open={showCreate} onClose={() => setShowCreate(false)} /> 
        </div>
    ); 
}

export default AccountUsers; 





