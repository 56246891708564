
import { CircularProgress, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, DTableWrapper, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { UserEventLogAggregate } from 'gen/aggregates/UserEventLogAggregate';
import { useGetUserEventLogs } from 'gen/routes/Users';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import AdminHeader from '../common/AdminHeader';


const EventLogs = (props: { userID?: number }) => {

    const history = useHistory(); 
    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState("DateCreated"); 
    const [orderDir, setOrderDir] = useState("DESC"); 
    const limit = 50; 
    const userID = props.userID || 0; 
    const [ip, setIP] = useState('*') 
    const [eventName, setEventName] = useState('*'); 
    const [deviceKey, setDeviceKey] = useState('*'); 

    const doSetIP = (ip: string) => { 
        setPage(0); 
        setIP(ip); 
    }

    const doSetDevice = (device: string) => { 
        setPage(0); 
        setDeviceKey(device); 
    }

    const doSetEvent = (event: string) => { 
        setPage(0); 
        setEventName(event); 
    }

    const { data, status, error } = useGetUserEventLogs(
        userID, 
        ip, 
        eventName,  
        deviceKey, 
        page, 
        limit, 
        orderBy, 
        orderDir); 

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <NavBarSecondary right={
                <TableNav limit={limit} page={page} count={data.data.Count} prev={() => setPage(page - 1)} next={() => setPage(page + 1)} />
            }>
                
                <AdminHeader title={"Events"} />
                
                {ip !== '*' && <>IP: {ip} <IconButton size="small" onClick={() => doSetIP('*')}><Cancel /></IconButton></>}
                
                {eventName !== '*' && <>Event: {eventName} <IconButton size="small" onClick={() => doSetEvent('*')}><Cancel /></IconButton></>}
                {deviceKey !== '*' && <>Device: {deviceKey} <IconButton size="small" onClick={() => doSetDevice('*')}><Cancel /></IconButton></>}

            </NavBarSecondary>

            <DTableWrapper>
                <DTable 
                    orderBy={orderBy}
                    orderDir={orderDir}
                    cols={[
                        
                        { size: 1, orderFn: orderCol('UserID'), orderCol: "UserID", label: "User", val: (row: UserEventLogAggregate) => row.Username, onClick: (row: UserEventLogAggregate) => () => history.push(`/admin/users/${row.ActorID}`) }, 
                        
                        { size: 1, orderFn: orderCol('DateCreated'), orderCol: "DateCreated", label: "Date", val: (row: UserEventLogAggregate) => moment(row.DateCreated).format('DD MMM YYYY hh:mm a') }, 
                        
                        { size: 1, orderFn: orderCol('IP'), orderCol: 'IP', label: 'IP', val: (row: UserEventLogAggregate) => row.IP, onClick: (row: UserEventLogAggregate) => () => doSetIP(row.IP) }, 
                        
                        { size: 1, orderFn: orderCol('DeviceKey'), orderCol: 'DeviceKey', label: 'Device', val: (row: UserEventLogAggregate) => row.DeviceKey, onClick: (row: UserEventLogAggregate) => () => doSetDevice(row.DeviceKey) }, 
                        
                        { size: 1, orderFn: orderCol('EventName'), orderCol: 'EventName', label: 'Event', val: (row: UserEventLogAggregate) => row.EventName, onClick: (row: UserEventLogAggregate) => () => doSetEvent(row.EventName) }, 

                        { size: 1, orderFn: orderCol('Description'), orderCol: 'Description', label: 'Description', val: (row: UserEventLogAggregate) => row.Description }, 

                    ]}
                    data={data.data.Data}
                /> 
            </DTableWrapper>
            {/* <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow hover role="checkbox">
                                    <TableCell onClick={orderCol("UserID")}>
                                        User
                                        {orderBy === "UserID" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}
                                    </TableCell>
                                    <TableCell onClick={orderCol("DateCreated")}>
                                        Date
                                        {orderBy === "DateCreated" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}  
                                    </TableCell>
                                    <TableCell onClick={orderCol("IP")}>
                                        IP
                                        {orderBy === "IP" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}  
                                    </TableCell>
                                    <TableCell onClick={orderCol("Method")}>
                                        Method
                                        {orderBy === "Method" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}  
                                    </TableCell>
                                    <TableCell onClick={orderCol("Path")}>
                                        Path
                                        {orderBy === "Path" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}  
                                    </TableCell>
                                    <TableCell onClick={orderCol("ResponseCode")}>
                                        Response
                                        {orderBy === "ResponseCode" && (orderDir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}  
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs.Data.map((log, key) => 
                                    <TableRow key={key} hover role="checkbox">
                                        <TableCell onClick={gotoUser(log.UserID)}>{log.Username}</TableCell>
                                        <TableCell>{moment(log.DateCreated).format("DD MMM YYYY hh:mm a")}</TableCell>
                                        <TableCell onClick={gotoIP(log.UserLogIPID)}>{log.IP}</TableCell>
                                        <TableCell>{log.Method}</TableCell>
                                        <TableCell>{log.Path}</TableCell> 
                                        <TableCell>{log.ResponseCode}</TableCell>
                                        <TableCell>{log.ErrorCode}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </Grid>
        </div> */}
        </div>
    ); 
}

export default EventLogs; 

export const EventLogsRoute = () => EventLogs({ userID: 0 }); 