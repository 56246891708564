export const memoize = (fn) => { 
    
    const cache = {} 

    return function() {
        
        const key = JSON.stringify(arguments); 
        
        if(cache[key]) {
            return cache[key]; 
        }

        const val = fn.apply(null, arguments)
        cache[key] = val; 
        return val; 
    }
}

export const toNumberList = (l: string): number[] => !l ? [] : ((l.indexOf(",") > -1 ? l.split(",") : [l]).map(x => parseInt(x))); 

