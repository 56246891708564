import React from 'react'; 
import styled from 'styled-components'; 
import handleNetworkError from "core/utils/handleNetworkError";
import { Button, CircularProgress } from '@material-ui/core';

export enum Status { 
    None, 
    Loading, 
    Success, 
    Failure, 
}
  
export type StatusState = { 
    status: Status; 
    message: string; 
}

export const statusDefault = () : StatusState => ({
    status: Status.None, 
    message: '', 
});

export const statusError = (message: string) : StatusState => ({ 
    status: Status.Failure, 
    message: message, 
});

export const statusNetworkError = (e : any) : StatusState => ({ 
    status: Status.Failure, 
    message: handleNetworkError(e), 
})

export const statusLoading = (message: string) : StatusState => ({
    status: Status.Loading, 
    message: message, 
});

export const statusSuccess = (message: string) : StatusState => ({ 
    status: Status.Success, 
    message: message, 
});

interface StatusButtonProps { 
    status : Status; 
    children : React.ReactNode; 
}
// export const StatusButton = (props : StatusButtonProps) => { 
    
//     const { status, children, ...rest } = props; 

//     return ( 
//         <Button disabled={status === Status.Loading} {...rest}>
            
//             {children}

//             {status === Status.Loading && 
//                 <CircularProgress size={20} /> 
//             }
//         </Button>
//     ); 
// }

export const StatusAlert : React.FC<{ status: StatusState }> = ({ status }) => { 

    if(status.status === Status.Failure) { 
        return ( 
            <AlertStatusError>
                {status.message}
            </AlertStatusError>
        ); 
    }

    if(status.status === Status.Success && status.message.length > 0) { 
        return (
            <AlertStatusSuccess>
                {status.message}
            </AlertStatusSuccess>
        )
    }

    if(status.status === Status.Loading && status.message.length > 0) { 
        return (
            <AlertStatusLoading>
                {status.message}
            </AlertStatusLoading>
        )
    }

    return null; 
}

export const StatusButton : React.FC<{ status: StatusState, onClick : () => void, children: React.ReactNode }> = ({ status, onClick, children }) => { 
    return (
        <Button 
            variant="contained" 
            onClick={onClick}
            disabled={status.status === Status.Loading}
        >
            {children}
            {status.status === Status.Loading && 
                <CircularProgress size={20} />
            }
        </Button>
    )
}


const AlertStatusError = styled.div`
border-radius: 3px; 
color: #fff; 
padding: 5px; 
margin: 5px 0px; 
border-color: #f00; 
background-color: #f66;
font-size: 12px; 
`

const AlertStatusSuccess = styled.div`
border-radius: 3px; 
color: #fff; 
padding: 5px; 
margin: 5px 0px; 
border-color: #0f0; 
background-color: #6f6;
font-size: 12px; 
`

const AlertStatusLoading = styled.div`
border-radius: 3px; 
color: #444; 
padding: 5px; 
margin: 5px 0px; 
background-color: #eee;
font-size: 12px; 
border-color: #ccc; 
`
