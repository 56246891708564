import { newVendor } from "gen/models/Vendor";
import { newVendorItem } from "gen/models/VendorItem";
import { atom } from "recoil";

export const selectedVendorAtom = atom({
  key: "selectedVendorAtom",
  default: newVendor(),
});


export const selectedVendorItemAtom = atom({
  key: "selectedVendorItemAtom",
  default: newVendorItem(),
});


export const showCreateVendorItemModalAtom = atom({
  key: "showCreateVendorItemModalAtom",
  default: false,
});


export const showCreateVendorModalAtom = atom({
  key: "showCreateVendorModalAtom",
  default: false,
});


export const showEditVendorItemModalAtom = atom({
  key: "showEditVendorItemModalAtom",
  default: false,
});


export const showEditVendorModalAtom = atom({
  key: "showEditVendorModalAtom",
  default: false,
});
