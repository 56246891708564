import GroupsList from "core/pages/admin/accounts/users/GroupsList";
import UserDetail from "core/pages/admin/accounts/users/UserDetail";
import UserList from "core/pages/admin/accounts/users/UserList";
import { ActivityListRoute } from "core/pages/admin/activity/ActivityList";
import IPList from "core/pages/admin/activity/IPList";
import PoliciesManager from "core/pages/admin/policies/PoliciesManager";
import RolesManager from "core/pages/admin/roles/RolesManager";
import Settings from 'core/pages/admin/settings/Settings';
import TaskBatchDetail from "core/pages/admin/tasks/TaskBatchDetail";
import TaskBatchList from "core/pages/admin/tasks/TaskBatchList";
import Login from "core/pages/auth/login";
import ResetFinish from "core/pages/auth/ResetPasswordFinish";
import ResetStart from "core/pages/auth/ResetPasswordStart";
import ListList from 'core/pages/lists/listList';
import About from "core/pages/utils/About";
import { Activation } from 'core/pages/utils/Activation';
import { IRoute } from "core/utils/routes";
import { Accounts_ViewPermission, Activity_ViewPermission, AdminDB_ViewPermission, AdminSettings_ViewPermission, AdminUsers_ViewPermission, AdminViewPermission, Forms_ViewPermission, IPs_ViewPermission, Lists_ViewPermission, ObjectTodos_ViewPermission, Policies_ViewPermission, Roles_ViewPermission, Settings_ViewPermission, Tasks_ViewPermission, UserGroups_ViewPermission, Users_GetUserEventLogsPermission, Users_ViewPermission } from "gen/constants/permissions";
import AccountDetail from "./pages/admin/accounts/AccountDetail";
import AccountList from "./pages/admin/accounts/AccountList";
import { EventLogsRoute } from "./pages/admin/activity/EventLogs";
import Admin from "./pages/admin/Admin";
import DBList from "./pages/admin/database/DBList";
import FormData from "./pages/admin/forms/FormData";
import FormDetail from "./pages/admin/forms/FormDetail";
import FormsList from "./pages/admin/forms/FormsList";
import Verify from "./pages/auth/verify";
import AdminLogs from "./pages/todos/AdminLogs";
import AdminTodos from "./pages/todos/AdminTodos";
export const RouteLogin = "/login"; 

const routes : IRoute[] = [
    { path: "/login", Component: Login, private: false,  permission: "", },
    { path: "/password/reset/start", Component: ResetStart, private: false, permission: "", }, 
    { path: "/password/reset/:token", Component: ResetFinish, private: false, permission: "", }, 
    { path: "/admin/forms/:id/data", Component: FormData, private: true, permission: Forms_ViewPermission, },
    { path: "/admin/forms/:id", Component: FormDetail, private: true, permission: Forms_ViewPermission, },
    { path: "/admin/forms", Component: FormsList, private: true, permission: Forms_ViewPermission, },
    { path: "/admin/activation", Component: Activation, private: true, permission: "", },
    
    { path: "/admin/accounts/:accountID/users/:userID", Component: UserDetail, private: true,  permission: Users_ViewPermission, },
    { path: "/admin/accounts/:id", Component: AccountDetail, private: true,  permission: Accounts_ViewPermission, },
    { path: "/admin/accounts", Component: AccountList, private: true,  permission: Accounts_ViewPermission, },
    { path: "/admin/settings", Component: Settings, private: true,  permission: AdminSettings_ViewPermission, },
    { path: "/admin/users/:userID", Component: UserDetail, private: true,  permission: Accounts_ViewPermission, },
    { path: "/admin/users", Component: UserList, private: true,  permission: AdminUsers_ViewPermission, },
    { path: "/admin/databases", Component: DBList, private: true,  permission: AdminDB_ViewPermission, }, 
    { path: "/admin/activity/ips", Component: IPList, private: true,  permission: IPs_ViewPermission, }, 
    { path: "/admin/activity", Component: ActivityListRoute, private: true,  permission: Activity_ViewPermission, }, 
    { path: "/admin/policies/:id", Component: PoliciesManager, private: true, permission: Policies_ViewPermission }, 
    { path: "/admin/policies", Component: PoliciesManager, private: true,  permission: Policies_ViewPermission }, 
    { path: "/admin/roles/:id", Component: RolesManager, private: true,  permission: Roles_ViewPermission}, 
    { path: "/admin/roles", Component: RolesManager, private: true,  permission: Roles_ViewPermission, },
    { path: "/admin/groups", Component: GroupsList, private: true,  permission: UserGroups_ViewPermission, },
    { path: "/admin/tasks/:id", Component: TaskBatchDetail, private: true, permission: Tasks_ViewPermission }, 
    { path: "/admin/tasks", Component: TaskBatchList, private: true, permission: Tasks_ViewPermission }, 
    { path: "/admin/lists/:id", Component: ListList, private: true,  permission: Lists_ViewPermission, },
    { path: "/admin/lists", Component: ListList, private: true,  permission: Lists_ViewPermission, },
    { path: "/admin/userEvents", Component: EventLogsRoute, private: true,  permission: Users_GetUserEventLogsPermission, },
    { path: "/admin/todos", Component: AdminTodos, private: true, permission: ObjectTodos_ViewPermission}, 
    { path: "/admin/logs", Component: AdminLogs, private: true, permission: ObjectTodos_ViewPermission}, 
    { path: "/admin", Component: Admin, private: true, permission: AdminViewPermission },
    { path: "/settings", Component: Settings, private: true, permission: Settings_ViewPermission },
    { path: "/verify/:id", Component: Verify, private: false, permission: "" }, 
    { path: "/about", Component: About, private: false, permission: "" },
  ]; 
  
  export default routes; 

  