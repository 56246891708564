/**
 *
 * UpdateRoleDTO
 */

export type UpdateRoleDTO = {

	// IsDefault int
	IsDefault: number;

	// Name string
	Name: string;

}

// newUpdateRoleDTO is a factory method for new UpdateRoleDTO objects
export const newUpdateRoleDTO = () : UpdateRoleDTO => ({
	IsDefault: 0,
	Name: '',
});

