/**
 *
 * Base.Tasks
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateTaskBatchDTO } from 'gen/dtos/CreateTaskBatchDTO';
import { CreateTaskBatchScheduleDTO } from 'gen/dtos/CreateTaskBatchScheduleDTO';
import { CreateTaskBatchTaskDTO } from 'gen/dtos/CreateTaskBatchTaskDTO';
import { Task } from 'gen/models/Task';
import { TaskBatch } from 'gen/models/TaskBatch';
import { TaskBatchAggregate } from 'gen/aggregates/TaskBatchAggregate';
import { TaskBatchLogListAggregate } from 'gen/aggregates/TaskBatchLogListAggregate';
import { TaskBatchSchedule } from 'gen/models/TaskBatchSchedule';
import { TaskLogListAggregate } from 'gen/aggregates/TaskLogListAggregate';
import { TaskLogStepListAggregate } from 'gen/aggregates/TaskLogStepListAggregate';
import { UpdateTaskBatchDTO } from 'gen/dtos/UpdateTaskBatchDTO';
import { UpdateTaskBatchScheduleDTO } from 'gen/dtos/UpdateTaskBatchScheduleDTO';
import { UpdateTaskBatchTaskDTO } from 'gen/dtos/UpdateTaskBatchTaskDTO';

// GetTaskBatches gets a collection of task batches
// GET /tasks
// @permission Tasks_GetTaskBatches
export const getTaskBatches = async () => await axios.get<TaskBatchAggregate[]>(`/tasks`);
export const useGetTaskBatches = () => useQuery(["getTaskBatches"], (_ : string) => getTaskBatches());

// GetTaskBatchFromID gets a task batch from ID
// GET /tasks/{taskBatchID:[0-9]+}
// @permission Tasks_GetTaskBatchFromID
export const getTaskBatchFromID = async (taskBatchID : number) => await axios.get<TaskBatchAggregate>(`/tasks/${taskBatchID}`);
export const useGetTaskBatchFromID = (taskBatchID : number) => useQuery(["getTaskBatchFromID", taskBatchID], (_ : string, taskBatchID : number) => getTaskBatchFromID(taskBatchID));

// CreateTaskBatch creates a task batch
// POST /tasks
// @permission Tasks_CreateTaskBatch
export const createTaskBatch = async (args : { body : CreateTaskBatchDTO }) => await axios.post<TaskBatch>(`/tasks`, args.body);
export const useCreateTaskBatch = () => useMutation(createTaskBatch, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
	},
	throwOnError: true, 
});
	

// UpdateTaskBatch updates a task batch
// PUT /tasks/{taskBatchID:[0-9]+}
// @permission Tasks_UpdateTaskBatch
export const updateTaskBatch = async (args : { taskBatchID : number, body : UpdateTaskBatchDTO }) => await axios.put<TaskBatch>(`/tasks/${args.taskBatchID}`, args.body);
export const useUpdateTaskBatch = () => useMutation(updateTaskBatch, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// DeleteTaskBatch deletes a task batch
// DELETE /tasks/{taskBatchID:[0-9]+}
// @permission Tasks_DeleteTaskBatch
export const deleteTaskBatch = async (args : { taskBatchID : number }) => await axios.delete<TaskBatch>(`/tasks/${args.taskBatchID}`);
export const useDeleteTaskBatch = () => useMutation(deleteTaskBatch, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// GetTaskBatchSchedulesByTaskBatch gets a collection of task batches schedules
// GET /tasks/{taskBatchID:[0-9]+}/schedules
// @permission Tasks_GetTaskBatchSchedulesByTaskBatch
export const getTaskBatchSchedulesByTaskBatch = async (taskBatchID : number) => await axios.get<TaskBatchSchedule[]>(`/tasks/${taskBatchID}/schedules`);
export const useGetTaskBatchSchedulesByTaskBatch = (taskBatchID : number) => useQuery(["getTaskBatchSchedulesByTaskBatch", taskBatchID], (_ : string, taskBatchID : number) => getTaskBatchSchedulesByTaskBatch(taskBatchID));

// CreateTaskBatchSchedule creates a task batch
// POST /tasks/{taskBatchID:[0-9]+}/schedules
// @permission Tasks_CreateTaskBatchSchedule
export const createTaskBatchSchedule = async (args : { taskBatchID : number, body : CreateTaskBatchScheduleDTO }) => await axios.post<TaskBatchSchedule>(`/tasks/${args.taskBatchID}/schedules`, args.body);
export const useCreateTaskBatchSchedule = () => useMutation(createTaskBatchSchedule, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// UpdateTaskBatchSchedule updates a task batch schedule
// PUT /tasks/{taskBatchID:[0-9]+}/schedules/{taskBatchScheduleID:[0-9]+}
// @permission Tasks_UpdateTaskBatchSchedule
export const updateTaskBatchSchedule = async (args : { taskBatchID : number, taskBatchScheduleID : number, body : UpdateTaskBatchScheduleDTO }) => await axios.put<TaskBatchSchedule>(`/tasks/${args.taskBatchID}/schedules/${args.taskBatchScheduleID}`, args.body);
export const useUpdateTaskBatchSchedule = () => useMutation(updateTaskBatchSchedule, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// DeleteTaskBatchSchedule deletes a task batch schedule
// DELETE /tasks/{taskBatchID:[0-9]+}/schedules/{taskBatchScheduleID:[0-9]+}
// @permission Tasks_DeleteTaskBatchSchedule
export const deleteTaskBatchSchedule = async (args : { taskBatchID : number, taskBatchScheduleID : number }) => await axios.delete<any>(`/tasks/${args.taskBatchID}/schedules/${args.taskBatchScheduleID}`);
export const useDeleteTaskBatchSchedule = () => useMutation(deleteTaskBatchSchedule, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// GetTaskBatchTasksByTaskBatch gets a collection of task batch tasks
// GET /tasks/{taskBatchID:[0-9]+}/tasks
// @permission Tasks_GetTaskBatchTasksByTaskBatch
export const getTaskBatchTasksByTaskBatch = async (taskBatchID : number) => await axios.get<Task[]>(`/tasks/${taskBatchID}/tasks`);
export const useGetTaskBatchTasksByTaskBatch = (taskBatchID : number) => useQuery(["getTaskBatchTasksByTaskBatch", taskBatchID], (_ : string, taskBatchID : number) => getTaskBatchTasksByTaskBatch(taskBatchID));

// CreateTaskBatchTask creates a task in a batch
// POST /tasks/{taskBatchID:[0-9]+}/tasks
// @permission Tasks_CreateTaskBatchTask
export const createTaskBatchTask = async (args : { taskBatchID : number, body : CreateTaskBatchTaskDTO }) => await axios.post<Task>(`/tasks/${args.taskBatchID}/tasks`, args.body);
export const useCreateTaskBatchTask = () => useMutation(createTaskBatchTask, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// UpdateTaskBatchTask updates a task batch task
// PUT /tasks/{taskBatchID:[0-9]+}/tasks/{taskID:[0-9]+}
// @permission Tasks_UpdateTaskBatchTask
export const updateTaskBatchTask = async (args : { taskBatchID : number, taskID : number, body : UpdateTaskBatchTaskDTO }) => await axios.put<Task>(`/tasks/${args.taskBatchID}/tasks/${args.taskID}`, args.body);
export const useUpdateTaskBatchTask = () => useMutation(updateTaskBatchTask, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// DeleteTaskBatchTask deletes a task batch schedule
// DELETE /tasks/{taskBatchID:[0-9]+}/tasks/{taskID:[0-9]+}
// @permission Tasks_DeleteTaskBatchTask
export const deleteTaskBatchTask = async (args : { taskBatchID : number, taskID : number }) => await axios.delete<any>(`/tasks/${args.taskBatchID}/tasks/${args.taskID}`);
export const useDeleteTaskBatchTask = () => useMutation(deleteTaskBatchTask, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getTaskBatches"]);
		queryCache.invalidateQueries(["getTaskBatchFromID", variables.taskBatchID]);
	},
	throwOnError: true, 
});
	

// GetTaskBatchLogsFromTaskBatch gets a collection of task batches
// GET /tasks/{taskBatchID:[0-9]+}/logs?page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Tasks_GetTaskBatchLogsFromTaskBatch
export const getTaskBatchLogsFromTaskBatch = async (taskBatchID : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<TaskBatchLogListAggregate>(`/tasks/${taskBatchID}/logs?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetTaskBatchLogsFromTaskBatch = (taskBatchID : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getTaskBatchLogsFromTaskBatch", taskBatchID, page, limit, orderBy, orderDir], (_ : string, taskBatchID : number, page : number, limit : number, orderBy : string, orderDir : string) => getTaskBatchLogsFromTaskBatch(taskBatchID,page,limit,orderBy,orderDir));

// GetTaskLogsFromTaskBatchLog gets a collection of task batches
// GET /tasks/{taskBatchID:[0-9]+}/logs/{taskBatchLogID:[0-9]+}/logs?page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Tasks_GetTaskLogsFromTaskBatchLog
export const getTaskLogsFromTaskBatchLog = async (taskBatchID : number, taskBatchLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<TaskLogListAggregate>(`/tasks/${taskBatchID}/logs/${taskBatchLogID}/logs?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetTaskLogsFromTaskBatchLog = (taskBatchID : number, taskBatchLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getTaskLogsFromTaskBatchLog", taskBatchID, taskBatchLogID, page, limit, orderBy, orderDir], (_ : string, taskBatchID : number, taskBatchLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => getTaskLogsFromTaskBatchLog(taskBatchID,taskBatchLogID,page,limit,orderBy,orderDir));

// GetTaskLogStepsFromTaskLog gets a collection of task log steps by task log
// GET /tasks/{taskBatchID:[0-9]+}/logs/{taskBatchLogID:[0-9]+}/logs/{taskLogID:[0-9]+}/steps?page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Tasks_GetTaskLogStepsFromTaskLog
export const getTaskLogStepsFromTaskLog = async (taskBatchID : number, taskBatchLogID : number, taskLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<TaskLogStepListAggregate>(`/tasks/${taskBatchID}/logs/${taskBatchLogID}/logs/${taskLogID}/steps?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetTaskLogStepsFromTaskLog = (taskBatchID : number, taskBatchLogID : number, taskLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getTaskLogStepsFromTaskLog", taskBatchID, taskBatchLogID, taskLogID, page, limit, orderBy, orderDir], (_ : string, taskBatchID : number, taskBatchLogID : number, taskLogID : number, page : number, limit : number, orderBy : string, orderDir : string) => getTaskLogStepsFromTaskLog(taskBatchID,taskBatchLogID,taskLogID,page,limit,orderBy,orderDir));
