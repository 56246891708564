import styled from 'styled-components';

export const MultiSearchItem = styled.div`
    font-weight: bold;
    font-size: 12px; 
    margin-bottom: 5px; 
    margin-top: 5px; 
    padding: 5px; 
    background-color: #fefefe; 
    border: solid 1px #eee; 
    border-radius: 3px; 
    position: relative; 
`; 
export const RemoveMultiSearchItem = styled.div`
    position: absolute; 
    right: 0; 
    top: 0; 
`; 