import { Button, Checkbox, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Fab, FormControl, FormControlLabel, IconButton, Input, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, Tooltip, withStyles } from '@material-ui/core';
import { Cancel, CloudDownload, Delete, ExpandMore, Sort } from '@material-ui/icons';
import { createQuote, downloadQuotesExcel, downloadQuotesPDF, searchQuotes, useCreateQuoteSearch, useDeleteQuoteSearch, useGetDefaultQuoteSearch, useGetQuoteList, useGetQuoteSearches, useUpdateQuoteSearch } from 'app/api/quotes';
import { searchVendors, useGetVendorsFromIDs } from 'app/api/vendors';
import { newQuoteSearchAggregate, newQuoteSearchAggregateFromMap, QuoteSearchAggregate } from 'app/definitions/aggregates/QuoteSearchAggregate';
import { newCreateQuoteSearchDTO } from 'app/definitions/dtos/CreateQuoteSearchDTO';
import { SearchQuoteDTO, searchQuoteDTOToMap } from 'app/definitions/dtos/SearchQuoteDTO';
import { newUpdateQuoteSearchDTO } from 'app/definitions/dtos/UpdateQuoteSearchDTO';
import Autocomplete from 'core/components/Autocomplete';
import { DatePickerButton } from 'core/components/DatePicker';
import MoneyFormat from 'core/components/MoneyFormat';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DataBody, DataCell, DataHeaderOrdered, DataRow, DataTable, ReportWrapper, TableNav } from 'core/components/tables';
import { ObjectSearchAggregate } from 'core/definitions/aggregates/ObjectSearchAggregate';
import { ListTypeBidType, ListTypeMarket, ListTypeProbability, ListTypeQuoteStatus, ListTypeQuoteType } from 'core/definitions/constants/listTypes';
import { MultiSearchItem, RemoveMultiSearchItem } from 'core/pages/utils/MultiSearch';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { fetchURLQueryParamsFromString, mapToURL } from 'core/utils/url';
import { QuoteSearch_ViewPermission, Quotes_CreateQuotePermission, Quotes_DownloadQuotesExcelPermission, Quotes_DownloadQuotesPDFPermission, Quotes_SearchQuotesPermission } from 'gen/constants/permissions';
import { newCreateEmptyQuoteDTO } from 'gen/dtos/CreateEmptyQuoteDTO';
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import { useGetListFromListType } from 'gen/routes/Lists';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { quoteFilterPanelShowingAtom, quoteSearchAtom, searchQuoteTabAtom } from './detail/state';



const filterMenuWidth = 300; 
// const [quoteSearch, setquoteSearch] = useRecoilState(searchQuoteListAtom);
const QuoteList = () => {
    
    const location = useLocation(); 
    var params = fetchURLQueryParamsFromString(location.search)

    const overrideQuoteSearch = params.size > 0; 

    // console.log('location.search', location.search);
    const { data, error, status } = useGetDefaultQuoteSearch(); 
    const setQuoteSearch = useSetRecoilState(quoteSearchAtom);
    const filterShowing = useRecoilValue(quoteFilterPanelShowingAtom); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        if(!overrideQuoteSearch && status === "success" && data) {
            setQuoteSearch(verifyQuoteSearch(data.data))
        } else {
            console.log('params', params); 
            setQuoteSearch(newQuoteSearchAggregateFromMap(params))
        }

    }, [data, status])

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }  

    return <div style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>

        <QuoteListNav /> 
        
        {filterShowing && 
            <FilterMenu />
        }

        <QuoteListInner /> 
        
    </div>
}


const QuoteListNav = () => {

    const [quoteSearch, setQuoteSearch] = useRecoilState(quoteSearchAtom);
    const resetQuoteSearch = useResetRecoilState(quoteSearchAtom); 
    const [filterShowing, setFilterShowing] = useRecoilState(quoteFilterPanelShowingAtom);
    const history = useHistory();  
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); 
    
    const next = () => {
        const newOffset = quoteSearch.Search.Limit * (quoteSearch.Search.Page + 1); 
        if(newOffset >= quoteSearch.Search.ResultCount) {
            return; 
        }        
        const newQuoteSearch = {...quoteSearch, Search: { ...quoteSearch.Search, Page: quoteSearch.Search.Page + 1 } };
        setQuoteSearch(newQuoteSearch);
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(newQuoteSearch.Search))); 
    }

    const prev = () => {
        const newOffset = quoteSearch.Search.Limit * (quoteSearch.Search.Page - 1); 
        if(newOffset < 0) {
            return; 
        }
        const newQuoteSearch = {...quoteSearch, Search: { ...quoteSearch.Search, Page: quoteSearch.Search.Page - 1 } };
        console.log('setting page to', quoteSearch.Search.Page - 1)
        setQuoteSearch(newQuoteSearch);
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(newQuoteSearch.Search))); 

    }

    const toNewQuote = () => {
        createQuote({ ...newCreateEmptyQuoteDTO(), DueDate: moment().format() }).then(newQuote => {
            history.push(`/quotes/${newQuote.data.QuoteID}`); 
        }); 
    }

    const onSearchQuoteChange = (val: SearchResultDTO) => {
        history.push(`/quotes/${val.ID}`)
    }

    const handleMenuClick = (e) => {
        setMenuAnchorEl(e.currentTarget);
    }

    const doDownloadQuotesExcel = () => {
        setMenuAnchorEl(null); 
        downloadQuotesExcel(quoteSearch.Search)
            .then(() => console.log('downloaded!')); 
    }

    const doDownloadQuotesPDF = () => {
        setMenuAnchorEl(null); 
        downloadQuotesPDF(quoteSearch.Search)
            .then(() => console.log('downloaded!')); 
    }

    return (
        <NavBarSecondary right={
            <div style={{ display: 'flex'}}>
                
                <Perm perm={[Quotes_DownloadQuotesExcelPermission, Quotes_DownloadQuotesPDFPermission]} any>
                    
                    <IconButton onClick={handleMenuClick}>
                        <CloudDownload /> 
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={() => setMenuAnchorEl(null)}
                    >
                        <Perm perm={Quotes_DownloadQuotesExcelPermission}>
                            <MenuItem onClick={doDownloadQuotesExcel}>
                                Download Excel
                            </MenuItem>
                        </Perm>

                        <Perm perm={Quotes_DownloadQuotesPDFPermission}>
                            <MenuItem onClick={doDownloadQuotesPDF}>
                                Download PDF
                            </MenuItem>
                        </Perm> 

                    </Menu>
                </Perm>

                <Perm perm={Quotes_SearchQuotesPermission}>
                    <div style={{ display: 'inline-block', marginRight: 10}}>
                        <Autocomplete
                            title={"Search Quotes..."}
                            searchFn={searchQuotes}
                            onSelect={onSearchQuoteChange} 
                        />
                    </div>
                </Perm>

                <div style={{ display: 'inline-block', height: '48px', paddingTop: '10px', boxSizing: 'border-box'}}>
                    <TableNav {...{ count: quoteSearch.Search.ResultCount, prev, next, page: quoteSearch.Search.Page, limit: quoteSearch.Search.Limit }} />
                </div>        
                
                <Perm perm={QuoteSearch_ViewPermission}>
                    <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                        <Tooltip title="Saved Search">
                            <Fab size="small" style={{ height: 20, width: 35 }} onClick={() => setFilterShowing(!filterShowing)}>
                                <Sort style={{ height: 20, width: 20 }} /> 
                            </Fab>
                        </Tooltip>
                    </div>
                </Perm>
            </div>
        }>
            <strong>Quotes</strong>
            <Perm perm={Quotes_CreateQuotePermission}>
                <div style={{ display: 'inline-block', marginLeft: 20 }}>
                    <Button size="small" onClick={toNewQuote} variant="contained" color="primary">Create Quote</Button>
                </div>
            </Perm>
            {quoteSearch.ObjectSearchID > 0 && 
                <div style={{ display: 'inline-block', marginLeft: 20 }}>
                    <i style={{ fontStyle: 'italic', fontSize: 10 }}>{quoteSearch.Title}</i>
                    <IconButton size="small" onClick={() => resetQuoteSearch()}>
                        <Cancel style={{ fontSize: 14 }} />
                    </IconButton>
                </div>
            }
        </NavBarSecondary>
    ); 
}

const Accordion = withStyles({
    root: { 
        margin: '0px !important', 
        '&$expanded': {
            margin: '0px !important', 
        }
    }, 
    content: {
        '&$expanded': {
            margin: 0, 
        }
    }
})(ExpansionPanel); 

const AccordionSummary = withStyles({
    root: {
        margin: 0, 
        '&$expanded': {
            margin: 0, 
        }
    }, 
    content: {
        '&$expanded': { 
            margin: '10px 0', 
        }
    }
})(ExpansionPanelSummary);


const FilterMenu = () => {

    const history = useHistory(); 
    const [panel, setPanel] = useState('dates'); 
    const [tab, setTab] = useRecoilState(searchQuoteTabAtom); 
    const [quoteSearch, setQuoteSearch] = useRecoilState(quoteSearchAtom);
    const [createSearch] = useCreateQuoteSearch(); 
    const [updateSearch] = useUpdateQuoteSearch(); 
    const [deleteSearch] = useDeleteQuoteSearch(); 

    const doSetQuoteSearch = (quoteSearch: QuoteSearchAggregate) => {
        setQuoteSearch(quoteSearch);
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(quoteSearch.Search))); 
    }
    
    const [isDeleting, setIsDeleting] = useState(false); 

    const { data: quoteStatusesData, status: quoteStatusesStatus, error: quoteStatusesError } = useGetListFromListType(ListTypeQuoteStatus);
    const { data: marketData, status: marketStatus, error: marketError } = useGetListFromListType(ListTypeMarket);
    const { data: quoteTypeData, status: quoteTypeStatus, error: quoteTypeError } = useGetListFromListType(ListTypeQuoteType);
    const { data: bidTypeData, status: bidTypeStatus, error: bidTypeError } = useGetListFromListType(ListTypeBidType);
    const { data: probData, status: probStatus, error: probError } = useGetListFromListType(ListTypeProbability);
    const { data: usersData, status: usersStatus, error: usersError } = useGetMyAccountUsers(1, 0, 50);


    if(quoteStatusesStatus === "error") { return <Alert color="danger">{handleNetworkError(quoteStatusesError)}</Alert>}
    if(marketStatus === "error") { return <Alert color="danger">{handleNetworkError(marketError)}</Alert>}
    if(quoteTypeStatus === "error") { return <Alert color="danger">{handleNetworkError(quoteTypeError)}</Alert>}
    if(bidTypeStatus === "error") { return <Alert color="danger">{handleNetworkError(bidTypeError)}</Alert>}
    if(probStatus === "error") { return <Alert color="danger">{handleNetworkError(probError)}</Alert>}
    if(usersStatus === "error") { return <Alert color="danger">{handleNetworkError(usersError)}</Alert>}

    if(
        (quoteStatusesStatus === "loading" || !quoteStatusesData) ||
        (marketStatus === "loading" || !marketData) ||
        (quoteTypeStatus === "loading" || !quoteTypeData) ||
        (bidTypeStatus === "loading" || !bidTypeData) ||
        (probStatus === "loading" || !probData) ||
        (usersStatus === "loading" || !usersData) 
    ) {
        return <CircularProgress /> 
    }

    const togglePanel = (name: string) => () => {
        if(panel === name) {
            setPanel(''); 
        } else {
            setPanel(name); 
        }
    }

    const toggle = (name: string, id: number) => () => {
        
        // Check in case of null values where there should be an array :-(
        const search = quoteSearch.Search[name] ? quoteSearch.Search[name] : []; 

        if(search.indexOf(id) > -1) {
            doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, [name] : search.filter(x => x !== id )} }); 
        } else {
            doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, [name] : [...search, id ]} })
        }
        
    }

    const isChecked = (name: string, id: number) => {
        // console.log('isChecked', name, id, quoteSearch); s
        return quoteSearch && quoteSearch.Search && quoteSearch.Search[name] && quoteSearch.Search[name].indexOf(id) > -1; 
    }

    const doCreateSearch = () => {
        createSearch(newCreateQuoteSearchDTO(quoteSearch.Title, quoteSearch.Search, quoteSearch.IsDefault))
            .then(response => {
                doSetQuoteSearch(response?.data as ObjectSearchAggregate<SearchQuoteDTO>); 
            }); 
    }

    const doUpdateSearch = () => updateSearch(newUpdateQuoteSearchDTO(quoteSearch.ObjectSearchID, quoteSearch.Title, quoteSearch.Search, quoteSearch.IsDefault)); 

    const doDeleteSearch = () => deleteSearch(quoteSearch.ObjectSearchID)
        .then(() => {
            doSetQuoteSearch(newQuoteSearchAggregate()); 
        });

    

    return <FilterMenuEl>
        
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab value={0} label="Search" /> 
            <Tab value={1} label="Saved" />
        </Tabs>

        {/* Search */}
        {tab === 0 && 
            <div>
                

                <Accordion expanded={panel === 'details'} onChange={togglePanel('details')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Save Search</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ display: 'block'}}>
                        {quoteSearch.ObjectSearchID > 0 ? (
                                <>
                                    <FormControl>
                                        <InputLabel>Search Title</InputLabel>
                                        <Input value={quoteSearch.Title} onChange={(e) => setQuoteSearch({ ...quoteSearch, Title: e.target.value as string})} />
                                    </FormControl>

                                    <FormControlLabel 
                                        control={<Checkbox 
                                            checked={quoteSearch.IsDefault === 1} 
                                            onChange={(e) => {
                                                setQuoteSearch({ ...quoteSearch, IsDefault: e.target.checked ? 1 : 0 })
                                            }} 
                                            />
                                        }
                                        label={`Default`}
                                    /> 

                                    <br /> 
                                    {!isDeleting ? (
                                        <>
                                            <Button onClick={doUpdateSearch} variant="contained">Update</Button>
                                            <IconButton onClick={() => setIsDeleting(true)}>
                                                <Delete /> 
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <Button onClick={doDeleteSearch} color="secondary" variant="contained">
                                                Yes, delete
                                            </Button>
                                            <Button onClick={() => setIsDeleting(false)}>
                                                cancel
                                            </Button>
                                        </>
                                    )}


                                </>
                            ) : (
                                <>
                                    <FormControl>
                                        <InputLabel>Search Title</InputLabel>
                                        <Input value={quoteSearch.Title} onChange={(e) => setQuoteSearch({ ...quoteSearch, Title: e.target.value as string})} />
                                    </FormControl>
                                    
                                    <Button onClick={doCreateSearch} variant="contained">Create</Button>
                                    
                                </>
                            )
                        }
                    </ExpansionPanelDetails>
                </Accordion>
                
                <Accordion expanded={panel === 'dates'} onChange={togglePanel('dates')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Dates</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ display: 'block'}}>
                        <div>Date From: <br /> <DatePickerButton value={quoteSearch.Search.DateFrom} onChange={(d: Date) => doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, DateFrom: moment(d).format() } })} /></div>
                        <div>Date To: <br /> <DatePickerButton value={quoteSearch.Search.DateTo} onChange={(d: Date) => doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, DateTo: moment(d).format()  } })} /></div>
                    </ExpansionPanelDetails>
                </Accordion>
                
                <Accordion expanded={panel === "statuses"} onChange={togglePanel('statuses')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Statuses {quoteSearch.Search.Statuses.length > 0 && `(${quoteSearch.Search.Statuses.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            <ListItem key={-1} style={{ padding: 0 }} onClick={toggle('Statuses', 0)}>
                                <ListItemIcon>
                                    <Checkbox checked={isChecked('Statuses', 0)} /> 
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontStyle: 'italic' }}>Not Set</span>} /> 
                            </ListItem>
                            {quoteStatusesData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <Checkbox onChange={toggle('Statuses', item.ListItemID)} checked={isChecked('Statuses', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion expanded={panel === "markets"} onChange={togglePanel('markets')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Markets {quoteSearch.Search.Markets.length > 0 && `(${quoteSearch.Search.Markets.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            <ListItem key={-1} style={{ padding: 0 }} onClick={toggle('Markets', 0)}>
                                <ListItemIcon>
                                    <Checkbox checked={isChecked('Markets', 0)} /> 
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontStyle: 'italic' }}>Not Set</span>} /> 
                            </ListItem>
                            {marketData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('Markets', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('Markets', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion expanded={panel === "quoteTypes"} onChange={togglePanel('quoteTypes')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Quote Types {quoteSearch.Search.QuoteTypes.length > 0 && `(${quoteSearch.Search.QuoteTypes.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            <ListItem key={-1} style={{ padding: 0 }} onClick={toggle('QuoteTypes', 0)}>
                                <ListItemIcon>
                                    <Checkbox checked={isChecked('QuoteTypes', 0)} /> 
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontStyle: 'italic' }}>Not Set</span>} /> 
                            </ListItem>
                            {quoteTypeData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('QuoteTypes', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('QuoteTypes', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion expanded={panel === "bidTypes"} onChange={togglePanel('bidTypes')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Bid Types {quoteSearch.Search.BidTypes.length > 0 && `(${quoteSearch.Search.BidTypes.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            <ListItem key={-1} style={{ padding: 0 }} onClick={toggle('BidTypes', 0)}>
                                <ListItemIcon>
                                    <Checkbox checked={isChecked('BidTypes', 0)} /> 
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontStyle: 'italic' }}>Not Set</span>} /> 
                            </ListItem>
                            {bidTypeData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('BidTypes', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('BidTypes', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>
                {/* <Accordion expanded={panel === "probs"} onChange={togglePanel('probs')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <strong>Probabilities {quoteSearch.Search.Probabilities.length > 0 && `(${quoteSearch.Search.Probabilities.length})`}</strong>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            {probData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <Checkbox onChange={toggle('Probabilities', item.ListItemID)} checked={isChecked('Probabilities', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion> */}

                <Accordion square expanded={panel === "sales"} onChange={togglePanel('sales')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Sales {quoteSearch.Search.Sales.length > 0 && `(${quoteSearch.Search.Sales.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div style={{ height: '200px', overflowY: 'auto', width: '100%' }}>
                            <List>
                                {usersData.data.Data.map((user, key) => 
                                    <ListItem key={key} style={{ padding: 0 }} onClick={toggle('Sales', user.UserID)}>
                                        <ListItemIcon>
                                            <Checkbox   checked={isChecked('Sales', user.UserID)} /> 
                                        </ListItemIcon>
                                        <ListItemText primary={user.Profile.FirstName + ' ' + user.Profile.LastName} /> 
                                    </ListItem> 
                                )}
                            </List>
                        </div>
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion square expanded={panel === "manufacturer"} onChange={togglePanel('manufacturer')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Manufacturers {quoteSearch.Search.Vendors.length > 0 && `(${quoteSearch.Search.Vendors.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <ManufacturersSearch /> 
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion square expanded={panel === "areWeBidding"} onChange={togglePanel('areWeBidding')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Are We Bidding? {quoteSearch.Search.AreWeBidding.length > 0 && `(${quoteSearch.Search.AreWeBidding.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div style={{ height: '200px', overflowY: 'auto', width: '100%' }}>
                            <List>
                                <ListItem key={0} style={{ padding: 0 }} onClick={toggle('AreWeBidding', 0)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('AreWeBidding', 0)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={`Yes`} /> 
                                </ListItem> 
                                <ListItem key={1} style={{ padding: 0 }} onClick={toggle('AreWeBidding', 1)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('AreWeBidding', 1)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={`No`} /> 
                                </ListItem> 
                                <ListItem key={2} style={{ padding: 0 }} onClick={toggle('AreWeBidding', 2)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('AreWeBidding', 2)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={`??`} /> 
                                </ListItem> 
                            </List>
                        </div>

                    </ExpansionPanelDetails>
                </Accordion>
            </div>
        }

        {/* Saved */}
        {tab === 1 && 
            <div>
                <SavedSearches /> 
            </div>
        }

    </FilterMenuEl>
}

const ManufacturersSearch = () => {

    const history = useHistory(); 
    const [quoteSearch, setQuoteSearch] = useRecoilState(quoteSearchAtom); 
    const { data, status, error } = useGetVendorsFromIDs(quoteSearch.Search.Vendors);

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const doSetQuoteSearch = (quoteSearch: QuoteSearchAggregate) => {
        setQuoteSearch(quoteSearch);
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(quoteSearch.Search))); 
    }

    const onSelectVendor = (result: SearchResultDTO) => { 
        console.log('onSelectVendor'); 
        doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, Vendors: [...quoteSearch.Search.Vendors, result.ID ]}})
    }

    const removeVendor = (vendorID: number) => () => {
        doSetQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, Vendors: quoteSearch.Search.Vendors.filter(x => x !== vendorID)}})
    }

    return (
        <>
            
                <div style={{ height: '200px', overflowY: 'auto', width: '100%', paddingLeft: 20, paddingRight: 20 }}>
                    <Autocomplete
                        title={"Search Manufacturers..."} 
                        searchFn={searchVendors}
                        onSelect={onSelectVendor}
                        clearOnSelect={true}
                    />
                    
                    {data.data.map((vendor, key) => 
                        <MultiSearchItem>
                            {vendor.Title}
                            <RemoveMultiSearchItem>
                                <IconButton size="small" onClick={removeVendor(vendor.VendorID)}>
                                    <Cancel /> 
                                </IconButton>
                            </RemoveMultiSearchItem>
                        </MultiSearchItem>
                    )}
                </div>
        </>
    )

}

const verifyQuoteSearch = (data: QuoteSearchAggregate) : QuoteSearchAggregate => {

    return { 
        ...data, 
        Search: { 
            ...data.Search, 
            Vendors: !data.Search.Vendors ? [] : data.Search.Vendors, 
            SearchID: !data.Search.SearchID ? data.ObjectSearchID : data.Search.SearchID, 
            AreWeBidding: !data.Search.AreWeBidding ? [] : data.Search.AreWeBidding, 
        }
    }
}

const SavedSearches = () => {

    const history = useHistory(); 
    const { data, error, status } = useGetQuoteSearches(); 
    const setTab = useSetRecoilState(searchQuoteTabAtom)
    const [quoteSearch, setQuoteSearch] = useRecoilState(quoteSearchAtom); 

    const selectSearch = (search : QuoteSearchAggregate) => () => {
        console.log('Select Search: ', search); 
        const s = verifyQuoteSearch(search); 
        setQuoteSearch(s); 
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(s.Search))); 
        setTab(0); 

    }

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }
    
    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <List>
            {data.data.map((item, key) => 
                <ListItem key={key} onClick={selectSearch(item)} button style={{ backgroundColor: item.ObjectSearchID === quoteSearch.ObjectSearchID ? '#dfd' : 'inherit'}}>
                    <ListItemText primary={item.Title} secondary={item.IsDefault === 1 ? 'Default' : ''} />
                </ListItem>
            )}
        </List>
    ); 

    
}

const FilterMenuEl = styled.div`

    position: absolute; 
    top: 52px; 
    right: 0; 
    height: calc(100% - 52px); 
    width: ${filterMenuWidth}px; 
    border-left: solid 1px #909090; 
    z-index: 10000; 
    background-color: #fff; 

`; 


const QuoteListInner = () => {

    const filterShowing = useRecoilValue(quoteFilterPanelShowingAtom); 
    const history = useHistory(); 
    const [ quoteSearch, setQuoteSearch ] = useRecoilState(quoteSearchAtom);
    const { data, status, error } = useGetQuoteList(quoteSearch.Search); 

    useEffect(() => {
        if(status === "success" && data) {
            setQuoteSearch({ ...quoteSearch, Search: { ...quoteSearch.Search, ResultCount: data.data.Count } }); 
        }
    }, [ status, data ])

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const quotes = data.data; 

    const orderCol = (fieldName: string) => () => {
        if(quoteSearch.Search.OrderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        const newQuoteSearch = { ...quoteSearch, Search: { ...quoteSearch.Search, OrderBy: fieldName, OrderDir: "ASC" } };
        setQuoteSearch(newQuoteSearch); 
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(newQuoteSearch.Search))); 
    }

    const toggleOrderDir = () => {
        const dirName = quoteSearch.Search.OrderDir === "ASC" ? "DESC" : "ASC";
        const newQuoteSearch = { ...quoteSearch, Search: { ...quoteSearch.Search, OrderDir: dirName } };
        setQuoteSearch(newQuoteSearch); 
        history.push(mapToURL(`/quotes`, searchQuoteDTOToMap(newQuoteSearch.Search))); 
    }

    const toQuotePage = (quoteID: number) => () => {
        history.push(`/quotes/${quoteID}`)
    }

    
    const numCols = 12; 

    const colWidth = (units: number) : number => {
        return units * (99.9 / numCols); 
    }


    // const cols = [
    //     { width: 1, name: "QuoteNumberString", label: "#" }, 
    // ]

    const tableWidth = filterShowing ? `calc(100% - ${filterMenuWidth}px` : '100%'; 

    const today = moment(); 

    return (
        <ReportWrapper>
            <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'hidden', width: tableWidth, paddingRight:'20px', paddingLeft:'20px'}}>

                <DataRow>

                    <DataHeaderOrdered percent={colWidth(.75)} orderFn={orderCol("QuoteNumberString")} isOrderBy={quoteSearch.Search.OrderBy === "QuoteNumberString"} dir={quoteSearch.Search.OrderDir}>#</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(2)} orderFn={orderCol("Description")} isOrderBy={quoteSearch.Search.OrderBy === "Description"} dir={quoteSearch.Search.OrderDir}>Name</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.75)} orderFn={orderCol("DueDate")} isOrderBy={quoteSearch.Search.OrderBy === "DueDate"} dir={quoteSearch.Search.OrderDir}>Due</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.75)} orderFn={orderCol("FollowUpDate")} isOrderBy={quoteSearch.Search.OrderBy === "FollowUpDate"} dir={quoteSearch.Search.OrderDir}>Follow Up</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.75)} orderFn={orderCol("Sales1")} isOrderBy={quoteSearch.Search.OrderBy === "Sales1"} dir={quoteSearch.Search.OrderDir}>Sales 1</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.75)} orderFn={orderCol("Sales2")} isOrderBy={quoteSearch.Search.OrderBy === "Sales2"} dir={quoteSearch.Search.OrderDir}>Sales 2</DataHeaderOrdered>

                    {/* Type */}
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("QuoteTypeID")} isOrderBy={quoteSearch.Search.OrderBy === "QuoteTypeID"} dir={quoteSearch.Search.OrderDir}>Type</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("BidTypeID")} isOrderBy={quoteSearch.Search.OrderBy === "BidTypeID"} dir={quoteSearch.Search.OrderDir}>Bid Type</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1.25)} orderFn={orderCol("MarketID")} isOrderBy={quoteSearch.Search.OrderBy === "MarketID"} dir={quoteSearch.Search.OrderDir}>Market</DataHeaderOrdered>

                    {/* Total */}
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("TotalPrice")} isOrderBy={quoteSearch.Search.OrderBy === "TotalPrice"} dir={quoteSearch.Search.OrderDir} align="center">Total</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("QuoteStatusID")} isOrderBy={quoteSearch.Search.OrderBy === "QuoteStatusID"} dir={quoteSearch.Search.OrderDir} align="center">Status</DataHeaderOrdered>
                    {/* <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("ProbabilityID")} isOrderBy={quoteSearch.Search.OrderBy === "ProbabilityID"} dir={quoteSearch.Search.OrderDir} align="center">Probability</DataHeaderOrdered> */}
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("CurrentRevision")} isOrderBy={quoteSearch.Search.OrderBy === "CurrentRevision"} dir={quoteSearch.Search.OrderDir} align="center">Revision</DataHeaderOrdered>
                
                </DataRow>

                <DataBody>
                    
                    {quotes.Data.map((quote, key) => { 
                
                        let rowBg = "transparent"; 
                        if(quote.FollowUpDate > 0) { 
                            const fu = moment(quote.FollowUpDate); 
                            if(quote.Status === "Pending") { 
                                if(fu.isBefore(today, 'day')) { 
                                    // Red
                                    rowBg = "#f99"; 
                                } else if(fu.isBefore(moment(today).add(3, 'day'))) { 
                                    // Yellow 
                                    rowBg = "#ff9"; 
                                }
                            } 
                        }

                        return ( 
                            <DataRow key={key} onClick={toQuotePage(quote.QuoteID)} style={{ backgroundColor: rowBg }}>
                                
                                <DataCell percent={colWidth(.75)}>{quote.QuoteNumber}</DataCell>
                                <DataCell percent={colWidth(2)}>{quote.Name}</DataCell>
                                <DataCell percent={colWidth(.75)}>{moment(quote.DueDate).format('M/D/YY')}</DataCell>
                                <DataCell percent={colWidth(.75)}>{quote.FollowUpDate > 0 ? moment(quote.FollowUpDate).format('M/D/YY') : ''}</DataCell>
                                <DataCell percent={colWidth(.75)}>{quote.Sales1}</DataCell>
                                <DataCell percent={colWidth(.75)}>{quote.Sales2}</DataCell>

                                {/* Type */}
                                <DataCell percent={colWidth(1)}>{quote.QuoteType || <span style={{ fontStyle: 'italic' }}>Not Set</span>}</DataCell>
                                <DataCell percent={colWidth(1)}>{quote.BidType || <span style={{ fontStyle: 'italic' }}>Not Set</span>}</DataCell>
                                <DataCell percent={colWidth(1.25)}>{quote.Market || <span style={{ fontStyle: 'italic' }}>Not Set</span>}</DataCell>

                                {/* Total */}
                                <DataCell percent={colWidth(1)} align="right">$<MoneyFormat val={quote.Total} /></DataCell>
                                <DataCell percent={colWidth(1)} align="center">{quote.Status || <span style={{ fontStyle: 'italic' }}>Not Set</span>}</DataCell>
                                {/* <DataCell percent={colWidth(1)} align="center">{quote.Probability.Name}</DataCell> */}
                                <DataCell percent={colWidth(1)} align="center">{quote.Revisions}</DataCell>
                                
                            </DataRow>
                        ); 
                        
                    })}

                </DataBody>
            </DataTable>
            <DataRow key={-1} style={{ backgroundColor: '#eee', borderTop: 'solid 1px #909090', width: tableWidth }}>
                
                <DataCell percent={colWidth(.5)}></DataCell>
                <DataCell percent={colWidth(2)}></DataCell>
                <DataCell percent={colWidth(.75)}></DataCell>
                <DataCell percent={colWidth(.75)}></DataCell>
                <DataCell percent={colWidth(.75)}></DataCell>
                
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1.25)} align="right"><strong>Total:</strong></DataCell>

                {/* Total */}
                <DataCell percent={colWidth(1)} align="right">$<MoneyFormat val={quotes.Total} /></DataCell>
                <DataCell percent={colWidth(1)} align="right"></DataCell>
                {/* <DataCell percent={colWidth(1)} align="center">{quote.Probability.Name}</DataCell> */}
                <DataCell percent={colWidth(1)} align="center"></DataCell>
                
            </DataRow>

        </ReportWrapper>
    ); 
}

export default QuoteList; 