/**
 *
 * UpdateUserDTO
 */

export type UpdateUserDTO = {

	// FirstName string
	FirstName: string;

	// LastName string
	LastName: string;

	// UserPrefix string
	UserPrefix: string;

}

// newUpdateUserDTO is a factory method for new UpdateUserDTO objects
export const newUpdateUserDTO = () : UpdateUserDTO => ({
	FirstName: '',
	LastName: '',
	UserPrefix: '',
});

