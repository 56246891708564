import { Button, Checkbox, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, Save } from '@material-ui/icons';
import { Status, StatusAlert, statusDefault, statusLoading, statusNetworkError, StatusState, statusSuccess } from 'core/common/status';
import { DatePickerButton } from 'core/components/DatePicker';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { TableNav } from 'core/components/tables';
import { ObjectTypeCustomer, ObjectTypeJob, ObjectTypeQuote } from 'core/definitions/constants/objectTypes';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useSelf } from 'core/utils/userUtils';
import { ObjectTodoAggregate } from 'gen/aggregates/ObjectTodoAggregate';
import { newCreateObjectTodoDTO } from 'gen/dtos/CreateObjectTodoDTO';
import { newUpdateObjectTodoDTO, UpdateObjectTodoDTO } from 'gen/dtos/UpdateObjectTodoDTO';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import { markTodoAsComplete, markTodoAsInComplete, useCreateTodo, useDeleteTodo, useGetAllTodos, useUpdateTodo } from 'gen/routes/ObjectTodos';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';

const useStyles = makeStyles((theme) => ({ 

    todo : { 
        minHeight: 58, 
        border: 'solid 1px #ccc', 
        borderRadius: '5px', 
        padding: 5, 
        marginBottom: 10, 
        position: 'relative', 
    }, 
    todoCompleted: { 
        backgroundColor: '#efe', 
    }, 
    todoOverdue: { 
        backgroundColor: '#fee', 
    }, 
    todoInner: { 
        height: 48, 
    },
    todoBottomRight: {  
        position: 'absolute', 
        bottom: 5, 
        right: 20, 
        fontSize: "13px",
    },
    ln : { 
        color: 'blue', 
        textDecoration: 'underline', 
        cursor: 'pointer', 
        display: 'inline-block'
    }, 

    todoTitle: { 
        float: 'left', 
        marginLeft: 10, 
        width: '70%', 
        lineHeight: '48px', 
        verticalAlign: 'middle', 
        textOverflow: 'ellipsis', 
        height: 48, 
        overflow: 'hidden',
        cursor: 'pointer', 
    }, 

    todoTitleHide : { 
        fontSize: 12, 
        textDecoration: 'underline', 
        color: 'blue', 
    }, 

    todoTitleTitle: { 
        fontWeight: 'bold', 
        fontSize: '14px', 
        marginLeft: 10, 
    }, 

    todoTitleFull: { 
        clear: 'both', 
        width: 'calc(100% - 20px)', 
        borderRadius: '3px', 
        minHeight: '50px', 
        maxHeight: '200px', 
        overflowY: 'auto', 
        border: 'solid 1px #eee', 
        padding: 5, 
        marginTop: 10, 
        marginBottom: 10, 
        whiteSpace: 'pre-wrap', 
        background: '#fff', 
        marginLeft: 10, 
    },

    todoDescriptionTitle: { 
        fontWeight: 'bold', 
        fontSize: '14px', 
        marginLeft: 10, 
    }, 
    todoAssignedTo: { 
        float: 'left', 
        width: '15%', 
        lineHeight: '48px', 
        height: '48px', 
        overflow: 'hidden', 
        verticalAlign: 'middle', 
        fontWeight: 'bold',
        cursor: 'pointer',  
    }, 
    todoDueDate: { 
        float: 'right', 
        marginRight: '10px',  
        lineHeight: '48px', 
        verticalAlign: 'middle',
        cursor: 'pointer', 
    }, 
    todoCheckbox: { 
        float: 'right',
        lineHeight: '48px', 
        verticalAlign: 'middle', 
        position: 'relative', 
    },

    todoCheckboxLoading: { 
        position: 'absolute', 
        top: 7, 
        left: 10, 
        height: 48, 
        width: 48
    }, 
    todoDescription: { 
        clear: 'both', 
        width: 'calc(100% - 20px)', 
        minHeight: '50px', 
        maxHeight: '200px', 
        overflowY: 'auto', 
        border: 'solid 1px #eee', 
        padding: 5, 
        marginTop: 10, 
        marginBottom: 10, 
        whiteSpace: 'pre-wrap', 
        background: '#fff', 
        marginLeft: 10, 
    }, 
    todoCreatedBy: { 
        fontSize: '11px', 
        fontVariant: 'italic', 
        marginLeft: 10, 
    }, 
    todoGotoObject: { 
        fontSize: '11px', 
        textDecoration: 'underline', 
        color: 'blue', 
        cursor: 'pointer', 
        display: 'inline-block', 
        marginLeft: 20, 
    }, 
    todoCompletedBy: { 
        fontSize: '11px', 
        fontVariant:'italic', 
        display: 'inline-block', 
        marginRight: 20
    }, 
    todosWrapper : { 
        padding: 5, 
        marginBottom: 5, 
        marginTop: 5, 
        border: 'solid 1px #eee', 
        borderRadius: '5px'
    }, 
    createTodoForm: { 
        padding: 5, 
        marginBottom: 5, 
        marginTop: 5, 
        border: 'solid 1px #ccc', 
        borderRadius: '5px'
    }
    
})); 

const objectURL = (objectType: number, objectID: number) : string => { 
    if(objectType === ObjectTypeCustomer) { 
        return `/customers/${objectID}`
    }

    if(objectType === ObjectTypeQuote) { 
        return `/quotes/${objectID}`
    }

    if(objectType === ObjectTypeJob) { 
        return `/jobs/${objectID}`
    }

    return ""; 

}

const ObjectTodos : React.FC<{ 
    assignedTo: number; 
    objectType : number;
    objectID: number;
    showCreate?: boolean;
    rowLimit?: number;
    showUserFilter?: boolean; 
    isPlainLog?: boolean; 
    showObjectTypeFilter?: boolean; 
}> = ({ assignedTo, objectType, objectID, showCreate, rowLimit, showUserFilter, isPlainLog, showObjectTypeFilter }) => { 

    const styles = useStyles(); 
    const [page, setPage] = useState(0); 
    const [includeCompleted, setIncludeCompleted] = useState(0); 
    const [userAssignedTo, setUserAssignedTo] = useState(assignedTo); 
    const [ot, setObjectType] = useState(objectType); 
    const limit = rowLimit || 10
    const orderBy = "DateCreated"; 
    const orderDir = "DESC"; 

    useEffect(() => { 
        setUserAssignedTo(assignedTo); 
    }, [assignedTo])

    useEffect(() => { 
        setObjectType(objectType); 
    }, [objectType]); 

    const { data, error, status } = useGetAllTodos(userAssignedTo, ot, objectID, includeCompleted, isPlainLog === true ? 1 : 0, page, limit, orderBy, orderDir); 
    
    if(status === "error") { 
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    return ( 
        <div className={styles.todosWrapper}>
            

            <NavBarSecondary
                right={<>
                        <TableNav 
                            {
                                ...{ count: data.data.Count, prev: () => setPage(page - 1), next: () => setPage(page + 1) , page, limit }
                            } 
                        />
                    </>
                }
            >
                <strong>{isPlainLog === true ? 'Logs' : 'Tasks'}</strong>
                &nbsp; 
                {showUserFilter === true && <AssignedTo val={userAssignedTo} setVal={(id: number) => setUserAssignedTo(id)} includeAll /> }
                {showObjectTypeFilter === true && <TextField 
                    select 
                    onChange={(e) => setObjectType(parseInt(e.target.value))} 
                    value={ot} 
                    variant="outlined" 
                    size="small"
                    fullWidth
                    label="Type"
                >
                    <MenuItem value={0}>All...</MenuItem>
                    <MenuItem value={ObjectTypeJob}>Jobs</MenuItem>
                    <MenuItem value={ObjectTypeCustomer}>Customers</MenuItem>
                    <MenuItem value={ObjectTypeQuote}>Quotes</MenuItem>
                </TextField>
                }
                {!isPlainLog && <>
                    &nbsp; 
                    <FormControlLabel
                        control={<Checkbox onClick={() => setIncludeCompleted(includeCompleted === 1 ? 0 : 1)} checked={includeCompleted === 1} />}
                        label={`Include Completed`}
                    />
                </>}
                &nbsp;
                
            </NavBarSecondary>

            <div style={{ height: 20, width: '100%'}}>&nbsp;</div>

            {data.data.Data.length === 0 && 
                <Alert color="info" style={{ margin: 20 }}>No {isPlainLog === true ? 'Logs' : 'Tasks'} found</Alert>
            }

            {data.data.Data.map((todo, key) => <ObjectTodo 
                key={key} 
                ot={todo} 
                includeCompleted={includeCompleted === 1} 
                includeObjectLink={objectType === 0 || objectID === 0} 
                isPlainLog={isPlainLog === true}
                />
            )}

            {showCreate && 
                <CreateTodoForm objectType={objectType} objectID={objectID} isPlainLog={isPlainLog === true} /> 
            }

        </div>
    );
}


const ObjectTodo : React.FC<{ 
    ot: ObjectTodoAggregate, 
    includeCompleted: boolean, 
    includeObjectLink: boolean, 
    isPlainLog: boolean, 
}> = ({ 
    ot, 
    includeCompleted, 
    includeObjectLink, 
    isPlainLog, 
}) => { 

    const currentUser = useSelf(); 
    const [todo, setTodo] = useState(ot);     
    const [edit, setEdit] = useState(false); 
    const [visible, setVisible] = useState(true); 

    const [isCompleting, setIsCompleting] = useState(false); 

    useEffect(() => { 

        setTodo(ot); 

    }, [ot])

    const toggleTodoComplete = () => { 

        setIsCompleting(true); 

        setTimeout(() => { 

            if(todo.DateCompleted > 0) { 
                markTodoAsInComplete({ objectTodoID: todo.ObjectTodoID })
                    .then(() => { 
                        setIsCompleting(false); 
                        if(currentUser) { 
                            setTodo({ ...todo, DateCompleted : 0, CompletedBy: 0, CompletedByName: '' });
                        }
                    })
            } else {
                markTodoAsComplete({ objectTodoID: todo.ObjectTodoID }).then(() => { 
                    setIsCompleting(false); 
                    if(currentUser) { 
                        setTodo({ ...todo, DateCompleted : moment().valueOf(), CompletedBy: currentUser.UserID, CompletedByName: currentUser.FullName });
                    }

                    if(!includeCompleted) { 
                        setTimeout(() => { 
                            setVisible(false); 
                        }, 2000); 
                    }
                })
            }
        },500); 
    }

    if(!visible) { 
        return null; 
    }

    return ( 
        edit ? 
        <UpdateTodoForm 
            t={todo}
            cancel={() => setEdit(!edit)} 
            onUpdate={() => setEdit(!edit)} 
            isPlainLog={isPlainLog} 
        /> 
        : (
            isPlainLog === true ? (
                <PlainLogLayout 
                    todo={todo}     
                    isCompleting={isCompleting} 
                    setEdit={() => setEdit(!edit)} 
                    includeObjectLink={includeObjectLink}
                    toggleTodoComplete={toggleTodoComplete}
                /> 
            ) : (
                <TodoItemLayout 
                    todo={todo} 
                    isCompleting={isCompleting} 
                    setEdit={() => setEdit(!edit)} 
                    includeObjectLink={includeObjectLink}
                    toggleTodoComplete={toggleTodoComplete}
                /> 
            )
        )
    )
}


const DeleteConfirm = (props: { status: StatusState, onConfirm: () => void, onCancel: () => void } ) => { 

    const { onConfirm, onCancel, status } = props; 

    return ( 
        <Dialog open={true} onClose={onCancel}>
            
            <DialogTitle>Confirm Delete</DialogTitle>
            
            <StatusAlert status={status} /> 

            <DialogContent>
                <DialogContentText>Are you sure you want to delete this entry?</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={onConfirm} disabled={status.status === Status.Loading}>
                    Delete 
                    {status.status === Status.Loading && <CircularProgress size={20} />}
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )

}


const PlainLogLayout : React.FC<{ 
    todo : ObjectTodoAggregate, 
    isCompleting: boolean, 
    setEdit : () => void,
    includeObjectLink: boolean, 
    toggleTodoComplete: () => void, 
}> = ({ 
    todo, 
    isCompleting, 
    setEdit,
    includeObjectLink, 
    toggleTodoComplete, 
}) => { 

    const [deleteObjectTodo] = useDeleteTodo(); 
    const [deleteStart, setDeleteStart] = useState(false); 
    const [deleteStatus, setDeleteStatus] = useState(statusDefault()); 
    const doDelete = () => { 
        setDeleteStatus(statusLoading('')); 
        setTimeout(() => { 
            deleteObjectTodo({ todoID: todo.ObjectTodoID })
                .then(() => { 
                    setDeleteStatus(statusSuccess('')); 
                })
                .catch(e => { 
                    setDeleteStatus(statusNetworkError(e)); 
                }); 
        }, 1000); 
    }


    const currentUser = useSelf(); 
    const history = useHistory(); 
    const styles = useStyles(); 

    return ( 
        <>
            {deleteStart && <DeleteConfirm status={deleteStatus} onCancel={() => setDeleteStart(false)} onConfirm={doDelete} />}
            
            <div className={styles.todo}>
                        
                <div className={styles.todoDescription}>
                    {todo.Description}
                </div>
                
                <div className={styles.todoCreatedBy} style={{ border: 'none' }}>
                    Created By {todo.CreatedByName} on {moment(todo.DateCreated).format("M/D/YY")}
                    {includeObjectLink && <div onClick={() => history.push(objectURL(todo.ObjectType, todo.ObjectID))} className={styles.todoGotoObject}>Go to {todo.ObjectTitle}</div>}
                </div>

                {(currentUser !== undefined && currentUser.UserID === todo.CreatedBy) && (
                    <div className={styles.todoBottomRight}>
                        <div className={styles.ln} onClick={() => setEdit()}>
                            Edit
                        </div>
                        &nbsp; 
                        <div className={styles.ln} onClick={() => setDeleteStart(true)}>
                            Delete
                        </div>

                    </div>
                )}
                    

            </div>
        </>
    )
}

const TodoItemLayout : React.FC<{ 
    todo : ObjectTodoAggregate, 
    isCompleting: boolean, 
    setEdit : () => void,
    includeObjectLink: boolean, 
    toggleTodoComplete: () => void, 
}> = ({ 
    todo, 
    isCompleting, 
    setEdit,
    includeObjectLink, 
    toggleTodoComplete, 
}) => { 

    const [deleteObjectTodo] = useDeleteTodo(); 
    const [deleteStart, setDeleteStart] = useState(false); 
    const [deleteStatus, setDeleteStatus] = useState(statusDefault()); 
    const doDelete = () => { 
        setDeleteStatus(statusLoading('')); 
        setTimeout(() => { 
            deleteObjectTodo({ todoID: todo.ObjectTodoID })
                .then(() => { 
                    setDeleteStatus(statusSuccess('')); 
                })
                .catch(e => { 
                    setDeleteStatus(statusNetworkError(e)); 
                }); 
        }, 1000); 
    }

    const history = useHistory(); 
    const styles = useStyles(); 
    const now = moment().valueOf(); 
    const isOverdue = todo.DateCompleted === 0 && todo.DateDue < now; 
    const classes = todo.DateCompleted > 0 ? ' ' + styles.todoCompleted : ( isOverdue ? ' ' + styles.todoOverdue : '' );
    const [more, setMore] = useState(false); 
    const currentUser = useSelf(); 

    return ( 
        <>
            {deleteStart && <DeleteConfirm status={deleteStatus} onCancel={() => setDeleteStart(false)} onConfirm={doDelete} />}
            <div className={styles.todo +  classes}>

                <div className={styles.todoInner}>
            
                    <div className={styles.todoTitle} onClick={() => setMore(!more)}>
                        <div hidden={more}>
                            {todo.Title}
                        </div>
                        <div hidden={!more}>
                            <div className={styles.todoTitleHide} onClick={() => setMore(!more)}>hide</div>
                        </div>
                    </div>

                    <div className={styles.todoAssignedTo} onClick={() => setMore(!more)}>
                        {todo.AssignedToName || 'Noone'}
                    </div>


                    <div className={styles.todoCheckbox}>
                        
                        <Checkbox 
                            onChange={toggleTodoComplete} 
                            checked={todo.DateCompleted > 0} 
                            disabled={isCompleting} 
                        /> 

                        {isCompleting && 
                            <div className={styles.todoCheckboxLoading}><CircularProgress size={20} /></div>
                        } 
                    </div>
                    

                    <div className={styles.todoDueDate} onClick={() => setMore(!more)}>
                        {moment(todo.DateDue).format('M/D/YY')}
                    </div>

                </div>

                <Collapse in={more}>
                        
                    <div className={styles.todoTitleTitle}>Title</div>
                    
                    <div className={styles.todoTitleFull}>
                        {todo.Title}
                    </div>

                    <div className={styles.todoDescriptionTitle}>
                        Description
                    </div>
                    
                    <div className={styles.todoDescription}>
                        {todo.Description}
                    </div>
                    
                    <div className={styles.todoCreatedBy}>
                        Created By {todo.CreatedByName} on {moment(todo.DateCreated).format("M/D/YY")}
                        {includeObjectLink && <div onClick={() => history.push(`/jobs/${todo.ObjectID}`)} className={styles.todoGotoObject}>Go to {todo.ObjectTitle}</div>}
                    </div>

                    <div className={styles.todoBottomRight}>
                        
                        <div className={styles.todoCompletedBy} hidden={todo.DateCompleted === 0}>
                            Completed by {todo.CompletedByName} on {moment(todo.DateCompleted).format("M/D/YY")}
                        </div>

                        {(currentUser !== undefined && currentUser.UserID === todo.CreatedBy) && (
                            <>
                                <div className={styles.ln} onClick={() => setEdit()}>
                                    Edit
                                </div>  
                                &nbsp; 
                                <div className={styles.ln} onClick={() => setDeleteStart(true)}>
                                    Delete
                                </div>                     
                            </>
                        )}

                    </div>
                    
                </Collapse>

            </div>
        </>
    )
}


const CreateTodoForm : React.FC<{ objectType: number; objectID: number; isPlainLog: boolean }> = ({ objectType, objectID, isPlainLog }) => { 

    const currentUser = useSelf(); 
    const styles = useStyles(); 

    const [todo, setTodo] = useState({ ...newCreateObjectTodoDTO(), ObjectType: objectType, ObjectID: objectID, DateDue: moment().format() }); 
    const [status, setStatus] = useState<StatusState>({ status: Status.None, message: '' });
    const [createTodo] = useCreateTodo(); 
    const doCreateTodo = () => { 
        setStatus({ status: Status.Loading, message: '' }); 
        setTimeout(() => { 


            if(!currentUser) return; 

            createTodo({ body: isPlainLog ? { ...todo, IsPlainLog: 1, AssignedTo: currentUser?.UserID,  } : todo })
                .then(() => { 
                    
                    setStatus({ status: Status.Success, message: ''}); 
                    
                    setTodo({ 
                        ...newCreateObjectTodoDTO(), 
                        ObjectType: objectType, 
                        ObjectID: objectID 
                    }); 
                })
                .catch(e => { 
                    setStatus(statusNetworkError(e))
                })

            },1000); 
    }

    return ( 
        <div className={styles.createTodoForm}>
            <Grid container spacing={1}>
                
                {isPlainLog === false && (<> 
                    <Grid item xs={6}>
                        <TextField 
                            variant="outlined" 
                            size="small" 
                            fullWidth 
                            value={todo.Title} 
                            onChange={e => setTodo({ ...todo, Title: e.target.value })} 
                            error={status.status === Status.Failure} 
                            helperText={status.message} 
                            label="Task Title" 
                        /> 
                    </Grid>
                    <Grid item xs={2}>
                        <AssignedTo setVal={(id : number) => setTodo({ ...todo, AssignedTo: id })} /> 
                    </Grid>
                    <Grid item xs={2}>
                        <DatePickerButton value={todo.DateDue} onChange={(d) => setTodo({ ...todo, DateDue: moment(d).format() })} /> 
                    </Grid>

                    <Grid item xs={2}>
                        <Button variant="contained" onClick={doCreateTodo} disabled={status.status === Status.Loading}>
                            Create
                            {status.status === Status.Loading && <CircularProgress size={20} />}
                        </Button>
                    </Grid>

                </>)}

                
                <Grid item xs={12}>
                    <TextField 
                        variant="outlined"
                        size="small" 
                        fullWidth
                        value={todo.Description}
                        onChange={e => setTodo({ ...todo, Description: e.target.value })}
                        label={`${isPlainLog === true ? 'Log' : 'Task Description'}`}
                        multiline
                        rows={5}
                        rowsMax={10}
                    /> 
                </Grid>
                {isPlainLog === true && 
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={doCreateTodo} disabled={status.status === Status.Loading}>
                            Create
                            {status.status === Status.Loading && <CircularProgress size={20} />}
                        </Button>
                    </Grid>
                }
            </Grid>
        </div>
    )

}

const UpdateTodoForm : React.FC<{ t: ObjectTodoAggregate, onUpdate: () => void, cancel: () => void, isPlainLog: boolean }> = ({ t, onUpdate, cancel, isPlainLog }) => { 
    
    const styles = useStyles(); 
    const [todo, setTodo] = useState<UpdateObjectTodoDTO>({ ...newUpdateObjectTodoDTO(), AssignedTo: t.AssignedTo, DateDue: moment(t.DateDue).format(), Description: t.Description, Title: t.Title }); 
    const [status, setStatus] = useState<StatusState>({ status: Status.None, message: '' });
    const [updateTodo] = useUpdateTodo(); 
    
    const doUpdateTodo = () => { 

        setStatus({ status: Status.Loading, message: '' }); 
        setTimeout(() => { 
        
            updateTodo({todoID: t.ObjectTodoID, body: todo })
                .then(() => { 
                    setStatus({ status: Status.Success, message: ''});  
                    onUpdate();                    
                })
                .catch(e => { 
                    setStatus(statusNetworkError(e))
                })

            },1000); 
    }

    return ( 
        <div className={styles.createTodoForm}>
            <Grid container spacing={1}>
                {isPlainLog === false && (
                    <>
                
                        <Grid item xs={6}>
                            <TextField 
                                variant="outlined" 
                                size="small" 
                                fullWidth 
                                value={todo.Title} 
                                onChange={e => setTodo({ ...todo, Title: e.target.value })} 
                                error={status.status === Status.Failure} 
                                helperText={status.message || "200 characters..."} 
                                label="Task Title" 
                            /> 
                        </Grid>
                        <Grid item xs={2}>
                            <AssignedTo val={todo.AssignedTo} setVal={(id : number) => setTodo({ ...todo, AssignedTo: id })} /> 
                        </Grid>
                        <Grid item xs={2}>
                            <DatePickerButton value={todo.DateDue} onChange={(d) => setTodo({ ...todo, DateDue: moment(d).format() })} /> 
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" size="small" onClick={doUpdateTodo} disabled={status.status === Status.Loading}>
                                <Save />    
                                {status.status === Status.Loading && <CircularProgress size={20} />}
                            </Button>
                            <IconButton size="small" onClick={cancel}>
                                <Cancel /> 
                            </IconButton>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TextField 
                        variant="outlined" 
                        rowsMax={10} 
                        rows={5} 
                        multiline 
                        size="small" 
                        fullWidth 
                        value={todo.Description} 
                        onChange={e => setTodo({ ...todo, Description: e.target.value })} 
                        label="Description" 
                    /> 
                </Grid>
                {isPlainLog === true && 
                    <Grid item xs={12}>
                        <Button variant="contained" size="small" onClick={doUpdateTodo} disabled={status.status === Status.Loading}>
                            <Save />    
                            {status.status === Status.Loading && <CircularProgress size={20} />}
                        </Button>
                        <IconButton size="small" onClick={cancel}>
                            <Cancel /> 
                        </IconButton>
                    </Grid>
                }
            </Grid>
        </div>
    )

}



const AssignedTo : React.FC<{ 
    val?: number; 
    setVal : (id: number) => void, 
    includeAll?: boolean, 
}> = ({ 
    val, 
    setVal, 
    includeAll 
}) => {

    const [user, setUser] = useState(val || 0); 
    const { data, status, error } = useGetMyAccountUsers(1, 0, 100); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }


    return (
        <TextField 
            select 
            fullWidth 
            variant="outlined"
            value={user}
            size="small"
            label="Assigned To..."
            onChange={(e) => { 
                setUser(parseInt(e.target.value))
                setVal(parseInt(e.target.value)); 
            }}
        >
            {includeAll === true && <MenuItem value={0}>All...</MenuItem>}
            {data.data.Data.map((u, k) => <MenuItem key={k} value={u.UserID}>{u.FullName}</MenuItem>)} 
        </TextField>
    );
}



export default ObjectTodos; 