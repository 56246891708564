import { ListAggregate, newListAggregate } from 'core/definitions/aggregates/ListAggregate';
import { memoize } from 'core/utils/utils';
import { newUpdateSysValDTO, UpdateSysValDTO } from 'gen/dtos/UpdateSysValDTO';
import { newSysVal, SysVal } from 'gen/models/SysVal';
import { atom, RecoilState, selector, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';


export const sysValTypesAtom = atom<ListAggregate>({ 
    key: "sysValTypesAtom", 
    default: newListAggregate(), 
})

export const selectedSysValAtom = atom<UpdateSysValDTO>({ 
    key: 'selectedSysValAtom', 
    default: newUpdateSysValDTO(), 
})

export const selectedSysValsAtom = atom<SysVal[]>({
    key: "selectedSysValsAtom", 
    default: [], 
}); 

export const selectedSysValsSelector = selector<SysVal[]>({
    key: 'selectedSysValsSelector', 
    get: ({ get }) => {
        const itemIDs = get(sysValIDsAtom)
        const items = itemIDs.map(id => get(sysValWithID(id)))
        return items; 
    }
})

export const sysValIDsAtom = atom<number[]>({ 
    key: `sysValIDsAtom`, 
    default: [], 
})

export const useInsertSysVal = () => {

    const [items, setItems] = useRecoilState(sysValIDsAtom); 

    return useRecoilCallback(
        ({set}) => {
            return (value: SysVal) => {
                const maxID = items.length > 0 ? Math.max(...items) : -1
                setItems([...items, maxID + 1]); 
                console.log("Insert SysVal", items, maxID + 1)
                set(sysValWithID(maxID + 1), value)
            }
        }
    )

}

export const useInsertSysVals = () => {

    const setSysValIDs = useSetRecoilState(sysValIDsAtom); 

    return useRecoilCallback(
        ({set}) => {

            return (value: SysVal[]) => {
                
                value.forEach((item, k) => set(sysValWithID(k), item)); 
                
                // Set the ids 
                setSysValIDs(value.map((x, y) => y));

            }
        }
    )

}

export const newItem = (id: number) => atom<SysVal>({
    key: `sysValListItem${id}`, 
    default: newSysVal(), 
});

export const sysValWithID = memoize(newItem) as (id: number) => RecoilState<SysVal>; 


export const totalSysValValuesSelector = selector<number>({
    key: "totalSysValListItems", 
    get: ({ get }) => {
        const items = get(selectedSysValsSelector); 
        return items.map(item => item.Val).reduce((x, y) => x + y, 0);
    }
})
