/**
 *
 * Quote
 */

export type Quote = {

	// AreWeBidding int
	AreWeBidding: number;

	// BidTypeID int64
	BidTypeID: number;

	// CommissionTypeID int64
	CommissionTypeID: number;

	// CreatedBy int64
	CreatedBy: number;

	// CustomerCount int64
	CustomerCount: number;

	// DSO int64
	DSO: number;

	// DateCreated int64
	DateCreated: number;

	// Description string
	Description: string;

	// DueDate int64
	DueDate: number;

	// DueDateString string
	DueDateString: string;

	// FileCount int64
	FileCount: number;

	// FollowUpDate int64
	FollowUpDate: number;

	// FollowUpDateString string
	FollowUpDateString: string;

	// GrossMargin float64
	GrossMargin: number;

	// Guid string
	Guid: string;

	// IsDeleted int
	IsDeleted: number;

	// ItemCount int64
	ItemCount: number;

	// JobID int64
	JobID: number;

	// MarketID int64
	MarketID: number;

	// Notes null.String
	Notes: string;

	// ProbabilityID int64
	ProbabilityID: number;

	// ProjectCost float64
	ProjectCost: number;

	// QuoteID int64
	QuoteID: number;

	// QuoteNumberID int64
	QuoteNumberID: number;

	// QuoteStatusID int64
	QuoteStatusID: number;

	// QuoteTypeID int64
	QuoteTypeID: number;

	// Revision int
	Revision: number;

	// Sales1 string
	Sales1: string;

	// Sales2 string
	Sales2: string;

	// SalesCount int64
	SalesCount: number;

	// SelectedCustomerID int64
	SelectedCustomerID: number;

	// TotalCommission float64
	TotalCommission: number;

	// TotalPrice float64
	TotalPrice: number;

	// TotalQty int64
	TotalQty: number;

	// VendorCount int64
	VendorCount: number;

}

// newQuote is a factory method for creating new Quote objects
export const newQuote = () : Quote => ({ 
	AreWeBidding: 0,
	BidTypeID: 0,
	CommissionTypeID: 0,
	CreatedBy: 0,
	CustomerCount: 0,
	DSO: 0,
	DateCreated: 0,
	Description: '',
	DueDate: 0,
	DueDateString: '',
	FileCount: 0,
	FollowUpDate: 0,
	FollowUpDateString: '',
	GrossMargin: 0,
	Guid: '',
	IsDeleted: 0,
	ItemCount: 0,
	JobID: 0,
	MarketID: 0,
	Notes: '',
	ProbabilityID: 0,
	ProjectCost: 0,
	QuoteID: 0,
	QuoteNumberID: 0,
	QuoteStatusID: 0,
	QuoteTypeID: 0,
	Revision: 0,
	Sales1: '',
	Sales2: '',
	SalesCount: 0,
	SelectedCustomerID: 0,
	TotalCommission: 0,
	TotalPrice: 0,
	TotalQty: 0,
	VendorCount: 0,
});

