import { CircularProgress } from '@material-ui/core';
import { searchCustomers, useGetCustomers } from 'app/api/customers';
import { CustomerAggregate } from 'app/definitions/aggregates/CustomerAggregate';
import Autocomplete from 'core/components/Autocomplete';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, DTableWrapper, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Customers_CreateCustomerPermission, Customers_SearchCustomersByTitlePermission } from 'gen/constants/permissions';
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedCustomerIDAtom, showCreateCustomerModalAtom } from '../quotes/state';
import CreateCustomerModal from './CreateCustomerModal';

const CustomerList = () => {

    const history = useHistory(); 
    const limit = 50; 
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState("Title"); 
    const [orderDir, setOrderDir] = useState("ASC"); 

    const { status : customersStatus, data : customersData, error : customersError } = useGetCustomers(page, limit, orderBy, orderDir);

    const [showCreateCustomerModal, setShowCreateCustomerModal] = useRecoilState(showCreateCustomerModalAtom); 

    const toggleCreateCustomerModal = () => {
        setShowCreateCustomerModal(!showCreateCustomerModal); 
    }

    const setSelectedCustomerID = useSetRecoilState(selectedCustomerIDAtom); 

    
    if (customersStatus === "error") {
        return <Alert color="danger">{handleNetworkError(customersError)}</Alert> 
    }
    
    if(customersStatus === "loading"  || !customersData) {
        return <CircularProgress /> 
    }


    const next = () => {
        const newOffset =  limit * (page + 1); 
        if(newOffset >= customersData.data.Count) {
            return; 
        }       
        setPage(page + 1); 
    }

    const prev = () => {
        const newOffset = limit * (page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setPage(page - 1); 
    }

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName);
    }

    const toCustomerPage = (customerID: number) => {
        setSelectedCustomerID(customerID); 
        history.push(`/customers/${customerID}`)
    }


    return <div style={{ height: '100%' }}>

        <NavBarSecondary right={
            <div style={{ display: 'flex' }}>

                <Perm perm={Customers_SearchCustomersByTitlePermission}>
                    <div style={{ display: 'inline-block', marginRight: 10}}>
                        <Autocomplete
                        title={"Search Customers..."}
                        searchFn={searchCustomers}
                        onSelect={(result: SearchResultDTO) => toCustomerPage(result.ID)} 
                        />
                    </div>
                </Perm>
                <div style={{ display: 'inline-block', height: '48px', paddingTop: '10px', boxSizing: 'border-box'}}>
                    <TableNav {...{count: customersData.data.Count, prev, next, page, limit}} />
                </div>
            </div>
        }>
            <strong>Customers</strong>
        </NavBarSecondary>
        
        <DTableWrapper>
            <DTable
                onRowClick={(row) => toCustomerPage(row.CustomerID)}
                cols={[
                    { size: 2, orderFn: orderCol("Title"), orderCol: "Title", label: "Title", val: (row: CustomerAggregate) => <strong>{row.Title}</strong> },
                    { size: .5, orderFn: orderCol("City"), orderCol: "City", label: "City", val: (row: CustomerAggregate) => <div>{row.City}, {row.State}</div> },
                    { size: .5, orderFn: orderCol("ContactCount"), orderCol: "ContactCount", label: "Contacts", val: (row: CustomerAggregate) => row.ContactCount, align: 'center' },
                ]}
                data={customersData.data.Data}
                orderBy={orderBy}
                orderDir={orderDir}
            /> 
        </DTableWrapper>

        <Perm perm={Customers_CreateCustomerPermission}>
            <Add action={toggleCreateCustomerModal} /> 
        </Perm>

        <CreateCustomerModal /> 

        
    </div>
}



export default CustomerList