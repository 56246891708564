/**
 *
 * Base.PasswordReset
 */
 
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { ResetPasswordDTO } from 'gen/dtos/ResetPasswordDTO';
import { ResetPasswordStartDTO } from 'gen/dtos/ResetPasswordStartDTO';

// ResetPasswordStart starts resets a user password (while logged in)
// POST /password/reset/start?active={active:[0-9]+}
export const resetPasswordStart = async (args : { active : number }) => await axios.post<any>(`/password/reset/start?active=${args.active}`, {});
export const useResetPasswordStart = () => useMutation(resetPasswordStart);

// ResetPasswordAnonymousStart starts resets a user password
// POST /password/reset/start
export const resetPasswordAnonymousStart = async (args : { body : ResetPasswordStartDTO }) => await axios.post<any>(`/password/reset/start`, args.body);
export const useResetPasswordAnonymousStart = () => useMutation(resetPasswordAnonymousStart);

// ResetPasswordAnonymousValidate validates the nonce used when showing the page for resetting your password
// GET /password/reset/validate/{token:[a-zA-Z0-9]+}
export const resetPasswordAnonymousValidate = async (token : string) => await axios.get<any>(`/password/reset/validate/${token}`);
export const useResetPasswordAnonymousValidate = (token : string) => useQuery(["resetPasswordAnonymousValidate", token], (_ : string, token : string) => resetPasswordAnonymousValidate(token));

// ResetPasswordAnonymousFinish resets a user password
// POST /password/reset
export const resetPasswordAnonymousFinish = async (args : { body : ResetPasswordDTO }) => await axios.post<any>(`/password/reset`, args.body);
export const useResetPasswordAnonymousFinish = () => useMutation(resetPasswordAnonymousFinish);
