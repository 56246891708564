import { CircularProgress, MenuItem, TextField } from '@material-ui/core';
import { useGetVendors } from 'app/api/vendors';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { Alert } from 'reactstrap';
export const UserSelect : React.FC<{ userID: number; onChange: (userID: number) => void }> = ({ userID, onChange }) => { 
    const { data, status, error } = useGetMyAccountUsers(0, 0, 50); 
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }
    return <TextField size="small" variant="outlined" select value={userID} onChange={(e) => onChange(parseInt(e.target.value))} label="User">
        <MenuItem value={0}>All</MenuItem>
        {data.data.Data.map((user, key) => <MenuItem value={user.UserID} key={key}>{user.FullName}</MenuItem>)}
    </TextField>
}

export const VendorSelect : React.FC<{ vendorID: number; onChange: (vendorID: number) => void }> = ({ vendorID, onChange }) => { 
    const { data, status, error } = useGetVendors(0, 100, "Title", "ASC");  
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }
    return <TextField size="small" variant="outlined" select value={vendorID} onChange={(e) => onChange(parseInt(e.target.value))} label="Manufacturer">
        <MenuItem value={0}>All</MenuItem>
        {data.data.Data.map((vendor, key) => <MenuItem value={vendor.VendorID} key={key}>{vendor.Title}</MenuItem>)}
    </TextField>
}