/**
 *
 * UpdateUserGroupDTO
 */

export type UpdateUserGroupDTO = {

	// Description string
	Description: string;

	// Title string
	Title: string;

}

// newUpdateUserGroupDTO is a factory method for new UpdateUserGroupDTO objects
export const newUpdateUserGroupDTO = () : UpdateUserGroupDTO => ({
	Description: '',
	Title: '',
});

