import { SearchQuoteDTO } from "./SearchQuoteDTO"

export type UpdateQuoteSearchDTO = {
    ObjectSearchID: number; 
    Title: string; 
    Search: SearchQuoteDTO; 
    IsDefault: number; 
}

export const newUpdateQuoteSearchDTO = (objectSearchID: number, title: string, search: SearchQuoteDTO, isDefault: number ) : UpdateQuoteSearchDTO => ({
    ObjectSearchID: objectSearchID, 
    Title: title, 
    Search: search, 
    IsDefault: isDefault, 
})