import axios from "axios";
import { ReportAggregate } from "core/definitions/aggregates/ReportAggregate";
import download from "downloadjs";
import { useQuery } from "react-query";

//#region Summary Report 
export const getSummaryReport = async (_: string, year: number, type: string) => await axios.get<ReportAggregate>(
    `/reports/summary?year=${year}&scope=${type}`
)
export const useGetSummaryReport = (year: number, type: string) => useQuery(['getSummaryReport', year, type], getSummaryReport); 
export const downloadSummaryReportExcel = async (year: number,tab:string) => await axios.get(`/reports/summary/excel?year=${year}&scope=${tab}`, {
    responseType: 'blob', 
})
.then((resp) => {
    download(resp.data, `summaryReport-${tab}-${year}.xlsx`, resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
});
  
export const downloadSummaryReportPDF = async (year: number,tab:string) => await axios.get(`/reports/summary/pdf?year=${year}&scope=${tab}`, {
    responseType: 'blob', 
})
.then((resp) => {
    download(resp.data, `summaryReport-${tab}-${year}.pdf`, resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
});

//#endregion Summary Report 

//#region Sales Report 
export const getSalesActivityReport = async (_: string, year: number, scope: string) => await axios.get<ReportAggregate>(
    `/reports/sales-activity?year=${year}&scope=${scope}`
)

export const useGetSalesActivityReport = (year: number, scope: string,orderBy?:string,orderDir?:string) => useQuery(['getSalesActivityReport', year, scope,orderBy,orderDir], getSalesActivityReport); 
export const downloadSalesActivityReportExcel = async (scope:string,year: number) => await axios.get(`/reports/sales-activity/download/excel?scope=${scope}&year=${year}`, {
    responseType: 'blob', 
})
.then((resp) => {
    download(resp.data, `saleActivity-${scope=== "vendors" ?"manufacturers":scope}-${year}.xlsx`, resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
});

export const downloadSalesActivityReportPDF = async (scope:string,year: number) => await axios.get(`/reports/sales-activity/download/pdf?scope=${scope}&year=${year}`, {
    responseType: 'blob', 
})
.then((resp) => {
    download(resp.data, `saleActivity-${scope==="vendors"?"manufacturers":scope}-${year}.pdf`, resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
});
//#endregion Summary Report 
  