import { Button, Checkbox, CircularProgress, ClickAwayListener, FormControlLabel, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Select, Switch, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { Cancel, CheckCircle, Delete, Help, Remove, Report } from '@material-ui/icons';
import { useCreateTaskBatchSchedule, useDeleteTaskBatchSchedule, useGetTaskBatchSchedulesByTaskBatch, useUpdateTaskBatchSchedule } from 'gen/routes/Tasks';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateTaskBatchScheduleDTO } from 'gen/dtos/CreateTaskBatchScheduleDTO';
import { UpdateTaskBatchScheduleDTO } from 'gen/dtos/UpdateTaskBatchScheduleDTO';
import { TaskBatchSchedule } from 'gen/models/TaskBatchSchedule';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import selectedTaskBatchAtom from './atoms/selectedTaskBatchAtom';

export const newUpdateTaskBatchScheduleDTOFromModel = (schedule: TaskBatchSchedule) : UpdateTaskBatchScheduleDTO => ({
    DOM: schedule.DOM, 
    DOW: schedule.DOW, 
    HOD: schedule.HOD, 
    MOH: schedule.MOH, 
    IsActive: schedule.IsActive, 
})

const TaskBatchDetailSchedules = () => {
    
    const batch = useRecoilValue(selectedTaskBatchAtom); 
    const { data, error, status } = useGetTaskBatchSchedulesByTaskBatch(batch.TaskBatchID)
    const [createBatchSchedule] = useCreateTaskBatchSchedule(); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const createSchedule = () => {
        createBatchSchedule({taskBatchID: batch.TaskBatchID, body: newCreateTaskBatchScheduleDTO()})
    }

    return <div>
        <Button onClick={createSchedule}>Create Schedule</Button>
        <Table>
            <TableHead>
                <TableRow>
                    
                    <TableCell>Status</TableCell>
                    <TableCell>Last Error</TableCell>
                    <TableCell>Last Success</TableCell>
                    <TableCell>Total</TableCell>

                    <TableCell>Active</TableCell>
                    <TableCell>Day of Month</TableCell>
                    <TableCell>Day of Week</TableCell>
                    <TableCell>Hour of Day</TableCell>
                    <TableCell>Minute of Hour</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.data.map((schedule, key) => 
                    <ScheduleRow schedule={schedule} key={key} /> 
                )}
            </TableBody>
        </Table>

    </div>
    
}

const ScheduleRow : React.FC<{ schedule: TaskBatchSchedule }> = ({ schedule }) => {

    const [hourMenuOpen, setHourMenuOpen] = useState(false); 
    const hourAnchorRef = useRef<HTMLButtonElement>(null); 
    const [update] = useUpdateTaskBatchSchedule(); 
    const [deleteBatch] = useDeleteTaskBatchSchedule(); 
    const [deleteStarted, setDeleteStarted] = useState(false); 
    const deleteStart = () => {
        setDeleteStarted(true); 
    }
    const deleteCancel = () => setDeleteStarted(false); 
    const deleteFinish = () => deleteBatch({ taskBatchID: schedule.TaskBatchID, taskBatchScheduleID: schedule.TaskBatchScheduleID }).then(() => setDeleteStarted(false))
    
    // const [s, setS] = useState(newUpdateTaskBatchScheduleDTOFromModel(props.schedule)); 

    const updateDOWFlag = (flag: number) => (e: any) => {
        const updated = e.target.checked ? schedule.DOW + flag : schedule.DOW - flag; 
        update({ taskBatchID: schedule.TaskBatchID, taskBatchScheduleID: schedule.TaskBatchScheduleID, body: { ...newUpdateTaskBatchScheduleDTOFromModel(schedule), DOW: updated }})
    }

    const updateHODFlag = (flag: number) => (e: any) => {
        const val = Math.pow(2, flag); 
        const updated = e.target.checked ? schedule.HOD + val : schedule.HOD - val; 
        update({ taskBatchID: schedule.TaskBatchID, taskBatchScheduleID: schedule.TaskBatchScheduleID, body: { ...newUpdateTaskBatchScheduleDTOFromModel(schedule), HOD: updated }})
    }

    const handleToggleHourMenu = () => setHourMenuOpen(!hourMenuOpen); 
    const handleHourMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if(hourAnchorRef.current && hourAnchorRef.current.contains(event.target as HTMLElement)) {
            return; 
        }
        setHourMenuOpen(false); 
    }


    return (
        <TableRow>
            <TableCell><LastRunStatusIcon schedule={schedule} /> </TableCell>
            <TableCell>{schedule.LastRunDate === 0 ? 'never' : moment().diff(moment(schedule.LastErrorDate), 'seconds')}s</TableCell>
            <TableCell>{schedule.LastRunDate === 0 ? 'never' : moment().diff(moment(schedule.LastSuccessDate), 'seconds')}s</TableCell>
            <TableCell>
                {schedule.NumAttempted} / <span style={{ color: '#900', fontWeight: 'bold' }}>{schedule.NumFailed}</span> / <span style={{ color: '#090', fontWeight: 'bold' }}>{schedule.NumFinished}</span>
            </TableCell>

            <TableCell>
                
                <Switch checked={schedule.IsActive === 1} onChange={(e) => update({ taskBatchID: schedule.TaskBatchID, taskBatchScheduleID: schedule.TaskBatchScheduleID, body: { ...newUpdateTaskBatchScheduleDTOFromModel(schedule), IsActive: e.target.checked ? 1 : 0 }})} />
                
            </TableCell>

            <TableCell>
                {schedule.DOM}
            </TableCell>
            <TableCell>
                <FormControlLabel 
                    control={<Checkbox checked={(1 & schedule.DOW) === 1} onChange={updateDOWFlag(1)} />}
                    label="Sun"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(2 & schedule.DOW) === 2} onChange={updateDOWFlag(2)} />}
                    label="Mon"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(4 & schedule.DOW) === 4} onChange={updateDOWFlag(4)} />}
                    label="Tue"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(8 & schedule.DOW) === 8} onChange={updateDOWFlag(8)} />}
                    label="Wed"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(16 & schedule.DOW) === 16} onChange={updateDOWFlag(16)} />}
                    label="Thu"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(32 & schedule.DOW) === 32} onChange={updateDOWFlag(32)} />}
                    label="Fri"
                />
                <FormControlLabel 
                    control={<Checkbox checked={(64 & schedule.DOW) === 64} onChange={updateDOWFlag(64)} />}
                    label="Sat"
                />
            </TableCell>
            <TableCell>
                <Button ref={hourAnchorRef} onClick={handleToggleHourMenu}>Hours</Button>
                <Popper open={hourMenuOpen} anchorEl={hourAnchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 100000 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ zIndex: 100000, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{ height: 200, overflowY: 'scroll', zIndex: 100000, backgroundColor: '#fff'}}>
                                <ClickAwayListener onClickAway={handleHourMenuClose}>
                                    <MenuList id="menu-list-grow" style={{ backgroundColor: '#fff', zIndex: 100000 }}>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map((v, k) => 
                                            <MenuItem key={k}>
                                                <FormControlLabel 
                                                    key={k}
                                                    control={<Checkbox checked={(Math.pow(2, v) & schedule.HOD) === Math.pow(2, v)} onChange={updateHODFlag(v)} />}
                                                    label={`${v > 12 ? v - 12 : v} ${v > 11 ? 'pm' : 'am'}`}
                                                />    
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* {props.schedule.HOD} */}
                {/* <MenuList open={menuOpen}> */}
                    
                {/* </MenuList> */}
            
            </TableCell>
            <TableCell>
                <Select value={schedule.MOH} onChange={(e) => update({ taskBatchID: schedule.TaskBatchID, taskBatchScheduleID: schedule.TaskBatchScheduleID,  body: { ...newUpdateTaskBatchScheduleDTOFromModel(schedule), MOH: parseInt(e.target.value as string) } })}>
                    {[1,2,3,4,5,6,10,12,15,20,30, 60].map((v, k) => 
                        <MenuItem key={k} value={v}>Every {v} minute{v === 1 ? '' : 's'} ({60 / v} times an hour)</MenuItem>
                    )}
                </Select>
                {/* {props.schedule.MOH} */}
            
            </TableCell>
            <TableCell>
                {!deleteStarted && 
                    <IconButton onClick={deleteStart}>
                        <Delete /> 
                    </IconButton>
                }
                {deleteStarted && 
                    <>
                        <IconButton onClick={deleteFinish}>
                            <Delete style={{ color: 'red' }} /> 
                        </IconButton>

                        <IconButton onClick={deleteCancel}>
                            <Cancel /> 
                        </IconButton>
                    </>
                }
            </TableCell>
        </TableRow>

    )
}

const LastRunStatusIcon = (props: { schedule: TaskBatchSchedule }) => {
    
    if(props.schedule.LastRunDate === 0) {
        return <Remove style={{ color: '#999' }} /> 
    }

    if(props.schedule.LastErrorDate > props.schedule.LastSuccessDate) {
        return <Report style={{ color: '#900' }} /> 
    }

    if(props.schedule.LastErrorDate < props.schedule.LastSuccessDate) {
        return <CheckCircle style={{ color: '#090' }} /> 
    }

    return <Help style={{ color: '#009' }} /> 
}

export default TaskBatchDetailSchedules; 
