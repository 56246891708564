/**
 *
 * List
 */

export type List = {

	// AccountID int64
	AccountID: number;

	// DateCreated int64
	DateCreated: number;

	// DefaultListItemID int64
	DefaultListItemID: number;

	// IsConst int
	IsConst: number;

	// IsDeleted int
	IsDeleted: number;

	// ItemCount int64
	ItemCount: number;

	// ListID int64
	ListID: number;

	// ListType int64
	ListType: number;

	// Slug string
	Slug: string;

	// StringID string
	StringID: string;

	// Title string
	Title: string;

}

// newList is a factory method for creating new List objects
export const newList = () : List => ({ 
	AccountID: 0,
	DateCreated: 0,
	DefaultListItemID: 0,
	IsConst: 0,
	IsDeleted: 0,
	ItemCount: 0,
	ListID: 0,
	ListType: 0,
	Slug: '',
	StringID: '',
	Title: '',
});

