/**
 *
 * CreatePolicyDTO
 */

export type CreatePolicyDTO = {

	// Description string
	Description: string;

	// ShortName string
	ShortName: string;

	// Title string
	Title: string;

}

// newCreatePolicyDTO is a factory method for new CreatePolicyDTO objects
export const newCreatePolicyDTO = () : CreatePolicyDTO => ({
	Description: '',
	ShortName: '',
	Title: '',
});

