/**
 *
 * Base.Auth
 */
 
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { LoginRequestDTO } from 'gen/dtos/LoginRequestDTO';
import { LoginResponseAggregate } from 'gen/aggregates/LoginResponseAggregate';

// CreateAuthorizationForUser logs a user in
// POST /auth
export const createAuthorizationForUser = async (args : { body : LoginRequestDTO }) => await axios.post<LoginResponseAggregate>(`/auth`, args.body);
export const useCreateAuthorizationForUser = () => useMutation(createAuthorizationForUser);

// CreateAuthFromToken logs a user using a token
// GET /auth/auto/{token:[a-zA-Z0-9]+}
export const createAuthFromToken = async (token : string) => await axios.get<LoginResponseAggregate>(`/auth/auto/${token}`);
export const useCreateAuthFromToken = (token : string) => useQuery(["createAuthFromToken", token], (_ : string, token : string) => createAuthFromToken(token));
