import { ISiteConfig } from "core/utils/routes";
import { BiddingInTheFuture_ViewPermission, Customers_ViewPermission, Jobs_GetJobDatesForCalendarPermission, Jobs_ViewPermission, ObjectTodos_ViewMyTodosPermission, ObjectTodos_ViewPermission, Quotes_ViewPermission, Reports_GetSalesActivityReportPermission, Reports_ViewPermission, Values_ViewPermission, Vendors_ViewPermission } from "gen/constants/permissions";

export const siteConfig : ISiteConfig = { 
    Title: 'JOC RFQ', 
    Footer: 'JOC Sales', 
    NavLinks: [
      { Title: 'My Tasks', Path: '/todos', Permission: ObjectTodos_ViewMyTodosPermission, IconClasses: 'fa fa-check fa-lg' }, 
      { Title: 'All Tasks', Path: '/admin/todos', Permission: ObjectTodos_ViewPermission, IconClasses: 'fa fa-check fa-lg' }, 
      // { Title: 'All Logs', Path: '/admin/logs', Permission: , IconClasses: 'fa fa-check fa-history' }, 
      { Title: 'Quotes', Path: '/quotes', Permission: Quotes_ViewPermission, IconClasses: 'fa fa-file fa-lg' }, 
      { Title: 'Jobs', Path: '/jobs', Permission: Jobs_ViewPermission, IconClasses: 'fa fa-building fa-lg' }, 
      { Title: 'Jobs Calendar', Path: '/jobs/calendar', Permission: Jobs_GetJobDatesForCalendarPermission, IconClasses: 'fa fa-building fa-lg' }, 
      {
        Title: 'Reports',
        IconClasses: 'fa fa-line-chart fa-lg',
        SubItems:
         [
      { Title: 'Summary', Path: '/reports/summary', Permission: Reports_ViewPermission, IconClasses: 'fa fa-line-chart fa-lg'}, 
      { Title: 'Bidding In The Future', Path: '/reports/bidding-in-the-future', Permission: BiddingInTheFuture_ViewPermission, IconClasses: 'fa fa-line-chart fa-lg'  }, 
      { Title: 'Sales Activity Report', Path: '/reports/sales-activity', Permission: Reports_GetSalesActivityReportPermission, IconClasses: 'fa fa-line-chart fa-lg'  }, 
        ]},
      { Type: 'divider' }, 
      { Title: 'Customers', Path: '/customers', Permission: Customers_ViewPermission, IconClasses: 'fa fa-briefcase fa-lg' }, 
      { Title: 'Manufacturers', Path: '/vendors', Permission: Vendors_ViewPermission, IconClasses: 'fa fa-building fa-lg' }, 
      { Type: 'divider' }, 
      { Title: 'System Values', Path: '/values/sysvals', Permission: Values_ViewPermission }, 
    ]
  }