import { Button, CircularProgress, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { newQuoteSalesAggregate, newQuoteSalesAggregateFromUserProfile } from 'app/definitions/aggregates/QuoteSalesAggregate';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import { Container } from 'core/components/Container';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { selectedQuoteAtom } from './state';

const QuoteSales = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const { data: usersData, status: usersStatus, error: usersError } = useGetMyAccountUsers(1, 0, 100); 

    if(usersStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(usersError)}
        </Alert>
    }

    if(usersStatus === "loading" || !usersData) {
        return <CircularProgress /> 
    }

    const users = usersData.data.Data; 

    const addNewSales = () => {

        setQuote({
            ...quote, 
            Sales: [
                ...quote.Sales.map(s => ({ ...s, Commission: 100 / (quote.Sales.length + 1) })),
                newQuoteSalesAggregate(100 / (quote.Sales.length + 1)), 
            ]
        })
    }

    const deleteSales = (idx: number) => () => {

        setQuote({
            ...quote, 
            Sales: (quote.Sales.length - 1 > 0) ? quote.Sales.filter((x, key) => key !== idx).map(x => ({ ...x, Commission: (100 / (quote.Sales.length - 1)) })) : []
        })
    }
    
    // const onSelectSales = (index : number) => (result: SearchResultDTO) => {
    //     getUserFromID(result.id)
    //         .then(userData => {
    //             setQuote({
    //                 ...quote, 
    //                 Sales: Object.assign([...quote.Sales], {[index] : newQuoteSalesAggregateFromUserProfile(userData.data.UserProfile, quote.Sales[index].QuoteSalesID, quote.Sales[index].Commission)})
    //             })
    //         })
    // }

    const onSelectSales = (index: number) => (e) => {
        
        const salesID = parseInt(e.target.value as string); 
        const sales = users.find(x => x.UserID === salesID); 

        if(!sales) return; 

        setQuote({
            ...quote, 
            Sales: Object.assign([...quote.Sales], {[index] : newQuoteSalesAggregateFromUserProfile(sales, quote.Sales[index].QuoteSalesID, quote.Sales[index].Commission)})
        })

    }

    return (
        <Container title={`Sales (${quote.Sales.length})`}>
            {quote.Sales.map((sales, key) => 
                <Container key={key}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ flexGrow: 1 }}>
                            <TextField 
                                variant="outlined"
                                size="small"
                                margin="dense"
                                fullWidth
                                label="Sales"
                                select
                                value={sales.UserID}
                                onChange={onSelectSales(key)}
                                helperText="Select Sales Person"
                            >
                               
                                {users.map((user, key) => 
                                    <MenuItem key={key} value={user.UserID}>
                                        {user.FullName}
                                    </MenuItem>
                                )}
                            </TextField>
                            {/* <Autocomplete
                                title={"Search Manufacturer Products..."}
                                searchFn={searchUsersByName}
                                initialValue={sales.UserID > 0 ? sales.FirstName + " " + sales.LastName : ""}
                                onSelect={onSelectSales(key)}
                            />                                  */}
                        </div>
                        <div style={{ flexGrow: 1 }}>&nbsp;</div>
                        <div>
                            {quote.QuoteNumberID > 0 && 
                                <TextField
                                        label="Commission Percent"
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                        }}
                                        value={sales.Commission}  
                                        disabled={true}
                                    />
                            }
                            {/* <PercentInput
                                label="Commission Percent"
                                value={sales.Commission}
                                onChange={(value: number) => {
                                    setQuote({
                                        ...quote, 
                                        Sales: Object.assign([], [...quote.Sales], {[key]: {...sales, Commission: value }})
                                    })
                                }}
                            />  */}
                        </div>
                        <div>
                            <IconButton onClick={deleteSales(key)}>
                                <Delete /> 
                            </IconButton>
                        </div>
                    </div>
                </Container>
            )}
            <Button variant="contained" onClick={addNewSales}>Add Sales</Button>
        </Container>
    ); 
}

export default QuoteSales; 