import { CircularProgress } from '@material-ui/core';
import { useGetFormSubmissionFromID } from 'gen/routes/Forms';
import Form from 'core/components/form';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { Alert } from 'reactstrap';

const FormDataSubmissionEditForm : React.FC<{ formID: number; submissionID: number, onSuccess?: () => void }> = ({ formID, submissionID, onSuccess }) => { 

    const { data, error, status } = useGetFormSubmissionFromID(formID, submissionID); 

    if(status === "error") { 
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    return (
        <Form 
            form={data.data.Form} 
            data={data.data.Data} 
            formSubmissionID={submissionID}
            onSuccess={onSuccess}
        /> 
    )

}

export default FormDataSubmissionEditForm; 