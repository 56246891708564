/**
 *
 * CreateVendorItemDTO
 */

export type CreateVendorItemDTO = {

	// Cost float64
	Cost: number;

	// Description string
	Description: string;

	// ModelNumber string
	ModelNumber: string;

	// Title string
	Title: string;

}

// newCreateVendorItemDTO is a factory method for new CreateVendorItemDTO objects
export const newCreateVendorItemDTO = () : CreateVendorItemDTO => ({
	Cost: 0,
	Description: '',
	ModelNumber: '',
	Title: '',
});

