import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { newSearchResultsAggregate, SearchResultsAggregate } from 'gen/aggregates/SearchResultsAggregate';
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    autocompleteItem: {
        position: 'relative', 
    },
    autocompleteHighlight: {
        backgroundColor: '#ddd', 
        fontWeight: 'bold', 
    }, 
}));

export default function Autocomplete(props: { 
    title: string, 
    searchFn: (searchString: string) => Promise<AxiosResponse<SearchResultsAggregate>>, 
    onSelect: (result: SearchResultDTO) => void,  
    clearOnSelect?: boolean, 
    initialValue?: string, 
    clearOnFocus?: boolean, 
    disabled?: boolean, 
    helperText?: string
}) {

    const classes = useStyles(); 
    const listRef = useRef<HTMLDivElement>(null); 

    const [searchString, setSearchString] = useState(props.initialValue || ''); 
    const [showSearchResults, setShowSearchResults] = useState(false); 
    const [selectedIndex, setSelectedIndex] = useState(-1); 
    const [searchResults, setSearchResults] = useState(newSearchResultsAggregate()); 


    const onAutocompleteKeyDown = (e) => {
        
        // Down arrow 
        if(e.keyCode === 40) {

            e.preventDefault(); 

            if(selectedIndex < searchResults.Results.length - 1) {
                setSelectedIndex(selectedIndex + 1); 
            } else {
                setSelectedIndex(-1); 
            }


            if(listRef && listRef.current) {

                const bottom = listRef.current.scrollHeight - listRef.current.scrollTop === listRef.current.clientHeight; 

                if(!bottom && selectedIndex > 0) {
                    listRef.current.scrollTop += 40; 
                }

                // selectedIndex > 0 && selectedIndex < searchResults.results.length && 

                // console.log(listRef.current.scrollTop, listRef.current.)
            }
        
        // Up arrow 
        } else if (e.keyCode === 38) {
            e.preventDefault(); 
            if(selectedIndex < 1) {
                setSelectedIndex(-1); 
            } else {
                setSelectedIndex(selectedIndex - 1); 
            }

            if(listRef && listRef.current && listRef.current.scrollTop > 0) {
                // const bottom = listRef.current.scrollHeight - listRef.current.scrollTop === listRef.current.clientHeight; 
                listRef.current.scrollTop -= 40; 
                // if(!bottom) {
                // }
            }

            // if(listRef && listRef.current && listRef.current.scrollTop > 0) {
            //     listRef.current.scrollTop -= 40; 
            // }

        // Enter key 
        } else if (e.keyCode === 13) {
            
            e.preventDefault(); 
                
            setSelectedIndex(-1); 
            setShowSearchResults(false); 
            
            if(selectedIndex > -1) {
                if(props.clearOnSelect === true) {
                    setSearchString(''); 
                } else {
                    setSearchString(searchResults.Results[selectedIndex].Value); 
                }
                props.onSelect(searchResults.Results[selectedIndex]); 
            }

        // Escape key 
        } else if (e.keyCode === 27) { 
            e.preventDefault() 
            setSelectedIndex(-1); 
            setShowSearchResults(false); 
            setSearchString(''); 
        }
        
    }

    const onSearchChange = (e) => {
        
        const val = e.target.value as string
        
        setSearchString(val); 

        if(val.length === 0) {
            setShowSearchResults(false); 
            return; 
        }

        if(!showSearchResults) {
            setShowSearchResults(true); 
        }

        props.searchFn(val)
            .then(results => {
                setSearchResults(results.data); 
            });
    }

    const onItemSelect = (result : SearchResultDTO) => () => {
        setShowSearchResults(false); 
        if(props.clearOnSelect === true) {
            setSearchString(''); 
        } else {
            setSearchString(result.Value); 
        }
        props.onSelect(result); 
    }

    return (
        <AutocompleteContainer>
            
            <TextField 
                variant="outlined" 
                size="small" 
                margin="dense" 
                value={searchString} 
                onChange={onSearchChange} 
                onKeyDown={onAutocompleteKeyDown}
                fullWidth
                InputProps={{
                    fullWidth: true, 
                    style: { width: '100%' }
                }}
                label={props.title}
                disabled={props.disabled}
                helperText={props.helperText}
            /> 
            {/* <FormControl style={{ width: '100%' }} variant="filled">
                <InputLabel style={{ fontSize: '.8em'}}>{props.title} hello?</InputLabel>
                <Input 
                    type="text" 
                    value={searchString} 
                    onChange={onSearchChange} 
                    onKeyDown={onAutocompleteKeyDown} 
                /> 
            </FormControl> */}

            {showSearchResults && 
                <AutocompleteListContainer ref={listRef}>
                    {searchResults.Results.map((result, key) => 
                        <AutocompleteListItem key={key} onClick={onItemSelect(result)} tabIndex={key} className={clsx(classes.autocompleteItem, selectedIndex === key && classes.autocompleteHighlight)}>
                            {result.Value}
                        </AutocompleteListItem>
                    )}
                </AutocompleteListContainer>
            }
            
        </AutocompleteContainer>
    )
}

const AutocompleteContainer = styled.div`
    position: relative; 
    display: inline-block; 
    width: 100%; 
`

const AutocompleteListContainer = styled.div`
    position: absolute; 
    top: 100%; 
    left: 0; 
    right: 0; 
    overflow-x: hidden; 
    border: solid 1px #ccc; 
    border-bottom-left-radius: 3px; 
    border-bottom-right-radius: 3px; 
    z-index: 10000; 
    max-height: 200px; 
    overflow-y: auto; 
    
`

const AutocompleteListItem = styled.div`
    padding:10px; 
    cursor: pointer; 
    line-height: 20px; 
    vertical-align: middle; 
    color: #000; 
    background-color: #fff; 

    &:last-child {
        border-bottom-left-radius: 3px; 
        border-bottom-right-radius: 3px; 
        border-bottom: none; 
    }

    &:hover {
        background-color: #eee; 
    }

`