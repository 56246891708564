import { CircularProgress, Fab, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useGetRoles } from 'gen/routes/Roles';
import selectedManageRoleAtom from 'core/state/roles/selectedManageRoleAtom';
import showCreateRoleModalAtom from 'core/state/roles/showCreateRoleModalAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import CreateRoleDialog from './CreateRoleDialog';


const useStyles = makeStyles(theme => ({
    add: {
        position: 'absolute', 
        bottom: 20, 
        right: 20, 
    }
}))


const RoleList = () => {

    const { data, status : roleStatus, error } = useGetRoles(0, 'Name', 'ASC'); 
    const setCreateModalShowing = useSetRecoilState(showCreateRoleModalAtom);
    const [selectedManageRole, setSelectedManageRole] = useRecoilState(selectedManageRoleAtom); 

    const classes = useStyles(); 
    const history = useHistory();

    const selectRole = (roleID: number) => () => {
        setSelectedManageRole(roleID)
        history.push(`/admin/roles/${roleID}`)
    }

    if(roleStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(roleStatus === "loading" || !data) {
        return <CircularProgress /> 
    }

    const roles = data.data.Data; 
    
    return (
        <div style={{ height: '100%', overflowY: 'auto', position: 'relative' }}>
            <Fab color="primary" aria-label="add" className={classes.add} onClick={() => setCreateModalShowing(true)}>
                <AddIcon />
            </Fab>


            {/* <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'center'
                }}
                message="Role Created"
                open={createRoleStatus === ActionTypes.SUCCESS} onClose={() => null} 
            /> */}
        
            <List>
                {roles.map((role, key) => {
                    return (
                        <ListItem key={key} button onClick={selectRole(role.RoleID)} style={{ backgroundColor: selectedManageRole === role.RoleID ? '#9f9' : 'transparent'}}>
                            <ListItemText 
                                primary={role.Name} 
                                secondary={`${role.PolicyCount} policies | ${role.UserCount} users ${role.IsDefault > 0 ? '| default' : ''}`}
                            />
                        </ListItem>
                    ); 
                })}
            </List>
            
            <CreateRoleDialog /> 
        </div>
    )
}

export default RoleList; 