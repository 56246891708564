import { CircularProgress, Grid, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Add, Cancel, Edit, Lock, LockOpen, Save } from '@material-ui/icons';
import { createFiscalYear, updateFiscalPeriod, updateFiscalYear, useCreateFiscalPeriod, useGetFiscalPeriods, useGetFiscalYears } from 'app/api/values';
import { newUpdateFiscalPeriodDTOFromModel } from 'app/definitions/dtos/UpdateFiscalPeriodDTO';
import { newUpdateFiscalYearDTOFromModel } from 'app/definitions/dtos/UpdateFiscalYearDTO';
import { DatePickerInput } from 'core/components/DatePicker';
import NavBarSecondary from 'core/components/NavBarSecondary';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateFiscalPeriodDTO } from 'gen/dtos/CreateFiscalPeriodDTO';
import { newCreateFiscalYearDTO } from 'gen/dtos/CreateFiscalYearDTO';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { editingFiscalPeriodAtom, editingFiscalYearAtom, fiscalPeriodIDsAtom, fiscalPeriodWithID, fiscalYearIDsAtom, fiscalYearWithID, selectedFiscalPeriodAtom, selectedFiscalYearAtom, useInsertFiscalPeriods, useInsertFiscalYears } from './state';


const Calendar = () => {

    return (
        <div style={ { height: '100%', overflowY: 'hidden' }}>
            
            <NavBarSecondary
                right={<div></div>}
            >
                <strong>Fiscal Years</strong>
           
            </NavBarSecondary>
            <Grid container spacing={0} style={{ height: 'calc(100% - 48px)', overflowY: 'hidden'}}>
                <Grid item xs={4}>
                    <FiscalYears /> 
                </Grid>
                <Grid item xs={4}>
                    <FiscalPeriods /> 
                </Grid>
            </Grid>
        </div>

    )
}

const FiscalYears = () => {

    const { data, status, error } = useGetFiscalYears(); 
    const insertFiscalYears = useInsertFiscalYears(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        
        if(status === "success" && data) {
            // const dtos = data.data.map(item => newUpdateFiscalYearDTOFromModel(item)); 
            insertFiscalYears(data.data); 
        }

    }, [status, data]); 


    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        
        <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
            <FiscalYearRows /> 
        </div>

    )
}

const FiscalYearRows = () => {

    const ids = useRecoilValue(fiscalYearIDsAtom); 

    return (
        <TableContainer component={Paper} style={{ height: '100%', overflowY: 'auto' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Days</TableCell>
                        <TableCell>Locked</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ids.map((id, key) => <FiscalYearRow key={key} id={id} />)}
                    <CreateFiscalYear /> 
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const FiscalYearRow = (props: { id: number }) => {

    const [updating, setUpdating] = useState(false); 
    const [fiscalYear, setFiscalYear] = useRecoilState(fiscalYearWithID(props.id))
    const [editing, setEditing] = useRecoilState(editingFiscalYearAtom); 
    const [selected, setSelected] = useRecoilState(selectedFiscalYearAtom); 
    const resetEditing = useResetRecoilState(editingFiscalYearAtom); 

    const edit = () => {
        setEditing(newUpdateFiscalYearDTOFromModel(fiscalYear));
    }

    const update = () => {

        setUpdating(true); 
        // setFiscalYear(updatedFiscalYear); 
        updateFiscalYear(editing).then((response) => {
            setFiscalYear(response.data)
            setUpdating(false); 
            resetEditing(); 
        }).catch((e) => alert(handleNetworkError(e))); 
    }

    const cancel = () => {
        console.log('cancel!'); 
        resetEditing(); 
    };
    const onDateToChange = (date: Date) => setEditing({ ...editing, DateTo: moment(date).format() })
    const onDateFromChange = (date: Date) => setEditing({ ...editing, DateFrom: moment(date).format() })
    

    const isEditing = editing.Year === fiscalYear.Year; 
    const isSelected = selected.Year === fiscalYear.Year; 

    return (
        <TableRow style={{ backgroundColor: isSelected ? '#efe' : '#fff' }}>
            <TableCell onClick={ () => setSelected(fiscalYear)}>
                {fiscalYear.Year}
            </TableCell>
            <TableCell>
                {isEditing ? 
                    <DatePickerInput value={editing.DateFrom} onChange={onDateFromChange} />
                : <span>{moment(fiscalYear.DateFrom).format('MM/DD/YYYY')}</span>}
            </TableCell>
            <TableCell>
                {isEditing ? 
                    <DatePickerInput value={editing.DateTo} onChange={onDateToChange} />
                : <span>{moment(fiscalYear.DateTo).format('MM/DD/YYYY')}</span>}
            </TableCell>
            <TableCell>
                {fiscalYear.TotalFiscalDays}
            </TableCell>
            <TableCell>
                {fiscalYear.IsLocked ? <Lock /> : <LockOpen />}
            </TableCell>
            <TableCell>
                {isEditing ? <>
                    <IconButton onClick={update} disabled={updating}><Save /></IconButton>
                    <IconButton onClick={cancel} disabled={updating}><Cancel /></IconButton>
                </> : <IconButton onClick={edit}><Edit /></IconButton>}
            </TableCell>
        </TableRow>
    )
}

const CreateFiscalYear = () => {
    const [newFiscalYear, setNewFiscalYear] = useState({...newCreateFiscalYearDTO(), Year: 2020, DateFrom: '2020-01-01', DateTo: '2020-12-31' });  
    
    // const [createFiscalYear] = useCreateFiscalYear(); 
    
    const onDateFromChange = (date: Date) => setNewFiscalYear({ ...newFiscalYear, DateFrom: moment(date).format() })
    const onDateToChange = (date: Date) => setNewFiscalYear({ ...newFiscalYear, DateTo: moment(date).format() })
    
    const doCreateFiscalYear = () => {
        createFiscalYear(newFiscalYear)
            .then(() => {
                console.log('worked!'); 
                // setNewFiscalYear(newCreateFiscalYearDTO()); 
            }).catch((e) => alert(handleNetworkError(e)))
    }

    return (
        <TableRow>
            <TableCell>
                <TextField 
                    variant="outlined" 
                    size="small" 
                    value={newFiscalYear.Year} 
                    onChange={(e) => setNewFiscalYear({...newFiscalYear, Year: parseInt(e.target.value)})} 
                />
            </TableCell>
            <TableCell>
                <DatePickerInput value={newFiscalYear.DateFrom} onChange={onDateFromChange} />
            </TableCell>
            <TableCell>
                <DatePickerInput value={newFiscalYear.DateTo} onChange={onDateToChange} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
                <IconButton onClick={doCreateFiscalYear}>
                    <Add />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

const FiscalPeriods = () => {

    const fiscalYear = useRecoilValue(selectedFiscalYearAtom); 

    if(!fiscalYear) {
        return null; 
    }

    return <FiscalPeriodsInner /> 
}

const FiscalPeriodsInner = () => {
    
    const fiscalYear = useRecoilValue(selectedFiscalYearAtom); 
    const { data, status, error } = useGetFiscalPeriods(fiscalYear.FiscalYearID)
    const insertFiscalPeriods = useInsertFiscalPeriods(); 

    useEffect(() => {
        
        if(status === "success" && data) {
            console.log('FiscalPeriods', data); 
            // const dtos = data.data.map(item => newUpdateFiscalYearDTOFromModel(item)); 
            insertFiscalPeriods(data.data); 
        }

    }, [status, data]); 
    
    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    if(fiscalYear.FiscalYearID === 0){
        return null; 
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Days</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>                    
                
                <FiscalPeriodRows /> 

            </Table>
        </TableContainer>
    )
}

const CreateFiscalPeriod = () => {

    const [createFiscalPeriod] = useCreateFiscalPeriod(); 
    const selectedFiscalYear = useRecoilValue(selectedFiscalYearAtom);
    const [newFiscalPeriod, setNewFiscalPeriod] = useState({...newCreateFiscalPeriodDTO(), FiscalYearID: selectedFiscalYear.FiscalYearID, Ordinal: 1, DateFrom: moment('2020-01-01').format(), DateTo: moment('2020-01-31').format() });  
    
    // const [createFiscalYear] = useCreateFiscalYear(); 
    
    const onDateFromChange = (date: Date) => setNewFiscalPeriod({ ...newFiscalPeriod, DateFrom: moment(date).format() })
    const onDateToChange = (date: Date) => setNewFiscalPeriod({ ...newFiscalPeriod, DateTo: moment(date).format() })
    
    const doCreateFiscalPeriod = () => {
        createFiscalPeriod(newFiscalPeriod)
            .then(() => {
                console.log('worked!'); 
                // setNewFiscalYear(newCreateFiscalYearDTO()); 
            }).catch((e) => alert(handleNetworkError(e)))
    }

    return (
        <TableRow>
            <TableCell>
                <TextField 
                    select
                    variant="outlined" 
                    size="small" 
                    value={newFiscalPeriod.Ordinal} 
                    onChange={(e) => setNewFiscalPeriod({...newFiscalPeriod, Ordinal: parseInt(e.target.value)})} 
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                </TextField>
            </TableCell>
            <TableCell>
                <DatePickerInput value={newFiscalPeriod.DateFrom} onChange={onDateFromChange} />
            </TableCell>
            <TableCell>
                <DatePickerInput value={newFiscalPeriod.DateTo} onChange={onDateToChange} />
            </TableCell>
            <TableCell>
                <IconButton onClick={doCreateFiscalPeriod}>
                    <Add />
                </IconButton>
            </TableCell>
            <TableCell>&nbsp;</TableCell>
        </TableRow>

    )
}

const FiscalPeriodRows = () => {

    const ids = useRecoilValue(fiscalPeriodIDsAtom); 

    return (
        <TableBody>
            {ids.map((id, key) => <FiscalPeriodRow key={key} id={id} />)}
            <CreateFiscalPeriod />
        </TableBody>
    )
    
}

const FiscalPeriodRow = (props: { id: number }) => {

    console.log('FiscalPeriodRow', props.id); 
    const [fiscalPeriod, setFiscalPeriod] = useRecoilState(fiscalPeriodWithID(props.id))
    const [updating, setUpdating] = useState(false); 
    const [editing, setEditing] = useRecoilState(editingFiscalPeriodAtom); 
    const [selected, setSelected] = useRecoilState(selectedFiscalPeriodAtom); 
    const resetEditing = useResetRecoilState(editingFiscalPeriodAtom); 

    const edit = () => {
        setEditing(newUpdateFiscalPeriodDTOFromModel(fiscalPeriod));
    }

    const update = () => {

        setUpdating(true); 
        // setFiscalYear(updatedFiscalYear); 
        updateFiscalPeriod(editing).then((response) => {
            setFiscalPeriod(response.data)
            setUpdating(false); 
            resetEditing(); 
        }).catch((e) => alert(handleNetworkError(e))); 
    }

    const cancel = () => resetEditing(); 
    const onDateToChange = (date: Date) => setEditing({ ...editing, DateTo: moment(date).format() })
    const onDateFromChange = (date: Date) => setEditing({ ...editing, DateFrom: moment(date).format() })
    

    const isEditing = editing.FiscalPeriodID === fiscalPeriod.FiscalPeriodID; 
    const isSelected = selected.FiscalPeriodID === fiscalPeriod.FiscalPeriodID; 


    return (
        <TableRow style={{ color: isSelected ? '#efe' : '#fff' }}>
            <TableCell onClick={() => setSelected(fiscalPeriod)}>{fiscalPeriod.Ordinal}</TableCell>
            <TableCell>
                {isEditing ? 
                    <DatePickerInput value={editing.DateFrom} onChange={onDateFromChange} />
                : <span>{moment(fiscalPeriod.DateFrom).format('MM/DD/YYYY')}</span>}
            </TableCell>
            <TableCell>
                {isEditing ? 
                    <DatePickerInput value={editing.DateTo} onChange={onDateToChange} />
                : <span>{moment(fiscalPeriod.DateTo).format('MM/DD/YYYY')}</span>}
            </TableCell>
            <TableCell>
                {fiscalPeriod.TotalFiscalDays}
            </TableCell>
            <TableCell>
                {isEditing ? <>
                    <IconButton onClick={update} disabled={updating}><Save /></IconButton>
                    <IconButton onClick={cancel} disabled={updating}><Cancel /></IconButton>
                </> : <IconButton onClick={edit}><Edit /></IconButton>}
            </TableCell>
        </TableRow>
    );

}
export default Calendar; 