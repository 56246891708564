/**
 *
 * CreateUserDTO
 */

export type CreateUserDTO = {

	// AccountID int64
	AccountID: number;

	// Email string
	Email: string;

	// FirstName string
	FirstName: string;

	// LastName string
	LastName: string;

	// Password string
	Password: string;

	// PasswordConfirm string
	PasswordConfirm: string;

	// ProfileType int64
	ProfileType: number;

	// RedirURL string
	RedirURL: string;

}

// newCreateUserDTO is a factory method for new CreateUserDTO objects
export const newCreateUserDTO = () : CreateUserDTO => ({
	AccountID: 0,
	Email: '',
	FirstName: '',
	LastName: '',
	Password: '',
	PasswordConfirm: '',
	ProfileType: 0,
	RedirURL: '',
});

