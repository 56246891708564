import { UserAggregate } from 'core/definitions/aggregates/UserAggregate';
import React from 'react';
import userService from './userService';
import { useSelf } from './userUtils';

export const _hasPerm = (
    permissionNames: string | string[] | undefined, 
    currentUser: UserAggregate, 
    any?: boolean, 
) => {

    // If an empty permission name (or an empty array) was passed in, assume it's a placeholder and return true 
    if(!permissionNames || permissionNames.length === 0) {
        return true; 
    }

    const pm = !Array.isArray(permissionNames) ? [permissionNames] : permissionNames; 

    if(!currentUser) {
        return false; 
    }

    if(currentUser.UserID === 1) {
        return true; 
    }

    if(!currentUser.PermissionNames) {
        return false; 
    }

    if(!(currentUser.DateActivated > 0 && currentUser.IsDisabled === 0 && currentUser.IsLocked === 0)) {
        return false; 
    }


    let ret = false; 
    for(let i = 0; i < pm.length; i++) {
        const hasPerm = currentUser.PermissionNames.some(x => x === pm[i]); 

        // If any of the permissions are found, return true; 
        if(any) { 
            if(hasPerm) { 
                ret = true; 
                break; 
            } 
        } else {
            // If any of the permissions are not found, return false; 
            if(!hasPerm) {
                ret = false; 
                break; 
            } else {
                ret = true; 
            }
        }
    }

    return ret; 
}

interface PermProps { 
    perm?: string | string[] | undefined;
    children: any; 
    any? : boolean;
    cb?: () => boolean; 
    otherwise?: JSX.Element;
}

export const Perm = React.forwardRef<HTMLSpanElement, PermProps>((props, ref) => { 
   
    useSelf(); 

    const otherwise = props.otherwise || ''; 


    if(!userService.hasPerm(props.perm, props.any)) { 
        return <span ref={ref}>{otherwise}</span>;
    }

    if(props.cb && !props.cb()) { 
        return <span ref={ref}>{otherwise}</span>;
    }

    return <span ref={ref}>{props.children}</span>; 
}); 