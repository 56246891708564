/**
 *
 * UpdateCustomerEventDTO
 */

export type UpdateCustomerEventDTO = {

	// Duration int64
	Duration: number;

	// EventDateStart string
	EventDateStart: string;

	// Notes string
	Notes: string;

}

// newUpdateCustomerEventDTO is a factory method for new UpdateCustomerEventDTO objects
export const newUpdateCustomerEventDTO = () : UpdateCustomerEventDTO => ({
	Duration: 0,
	EventDateStart: '',
	Notes: '',
});

