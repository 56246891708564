import { FormAggregate, newFormAggregate } from 'core/definitions/aggregates/FormAggregate';
import { newUpdateFormDTO, UpdateFormDTO } from 'gen/dtos/UpdateFormDTO';
import { atom } from 'recoil';
export const selectedFormAtom = atom<FormAggregate> ({
    key: "selectedFormAtom", 
    default: newFormAggregate(), 
});


// UpdateForm
export const UpdateFormDTOFromAggregate = (agg: FormAggregate) : UpdateFormDTO => { 

    const f = newUpdateFormDTO(); 
    f.CreateButtonText = agg.CreateButtonText; 
    f.UpdateButtonText = agg.UpdateButtonText; 
    f.Title = agg.Title; 
    f.CreateResponseTextSuccess = agg.CreateResponseTextSuccess; 
    f.UpdateResponseTextSuccess = agg.UpdateResponseTextSuccess
    f.StringIDPrefix = agg.StringIDPrefix
    
    agg.FieldGroups.forEach((group, k) => { 
        f.Groups.push({ 
            FormFieldGroupID: group.FormFieldGroupID, 
            Ordinal: group.Ordinal, 
            Title: group.Title, 
            Description: group.Description, 
            FieldCount: group.FieldCount, 
        })
    })

    agg.Fields.forEach((field, k) => { 
        const group = f.Groups.find(x => x.FormFieldGroupID === field.FormFieldGroupID) || null; 
        const groupOrdinal = group ? group.Ordinal : 0; 
        f.Fields.push({ 
            DefaultValue: field.DefaultValue,
            FieldType: field.FieldType, 
            FormFieldID: field.FormFieldID, 
            HelpText: field.HelpText, 
            Ordinal: field.Ordinal, 
            Title: field.Title, 
            ObjectType: field.ObjectType, 
            ObjectID: field.ObjectID, 
            MinVal: field.MinVal, 
            MaxVal: field.MaxVal, 
            IsUnique: field.IsUnique, 
            IsRequired: field.IsRequired, 
            Options: field.Options, 
            Slug: field.Slug, 
            DataSourceType: field.DataSourceType, 
            DataSourceID: field.DataSourceID, 
            FormFieldGroupID: field.FormFieldGroupID, 
            GroupOrdinal: groupOrdinal, 
            ShowOnCreate: field.ShowOnCreate, 
            EditOnUpdate: field.EditOnUpdate, 
            DataSourceFormFieldID: field.DataSourceFormFieldID, 
            ShowOnTableView: field.ShowOnTableView, 
            TableViewOrdinal: field.TableViewOrdinal, 
        })
    })

    return f; 
}
