import { SearchJobDTO } from "./SearchJobDTO"

export type UpdateJobSearchDTO = {
    ObjectSearchID: number; 
    Title: string; 
    Search: SearchJobDTO; 
    IsDefault: number; 
}

export const newUpdateJobSearchDTO = (objectSearchID: number, title: string, search: SearchJobDTO, isDefault: number ) : UpdateJobSearchDTO => ({
    ObjectSearchID: objectSearchID, 
    Title: title, 
    Search: search, 
    IsDefault: isDefault, 
})