// ListItemDTO is a list item 

import { ListItem } from "gen/models/ListItem";

type Hoverable = {
    _hovering: number; 
}

export type ListItemAggregate = Hoverable & ListItem;

export const newListItemAggregate = (listItem : ListItem) : ListItemAggregate => ({
        ...listItem, 
        _hovering: -1, 
}); 
