
import { Button, CircularProgress } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, DTableWrapper, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { UserLogIP } from 'gen/models/UserLogIP';
import { useBlockIP, useGetIPs, useUnBlockIP } from 'gen/routes/IPs';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import AdminHeader from '../common/AdminHeader';

const IPList = () => {

    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState("IP"); 
    const [orderDir, setOrderDir] = useState("DESC"); 
    const limit = 50; 

    const { data, status, error } = useGetIPs(limit, page, orderBy, orderDir); 
    const [blockIP] = useBlockIP(); 
    const [unBlockIP] = useUnBlockIP(); 


    const next = () => {
        if(data) {
            const newOffset = (page + 1) * 20;  
            if(newOffset >= data.data.Count) {
                return; 
            }        
            setPage(page + 1); 
        }
    }

    const prev = () => {

        const newOffset = (page - 1) * 20; 
        if(newOffset < 0) {
            return; 
        }
        setPage(page - 1); 
    }

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    const doBlockIP = (ipID: number) => () => {
        blockIP({ ipID });
    }

    const doUnBlockIP = (ipID: number) => () => {
        unBlockIP({ ipID });
    }


    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data || !data.data || !data.data.Data) {
        return <CircularProgress /> 
    }

    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <NavBarSecondary right={
                <TableNav {...{ count: data.data.Count, prev, next, page: page, limit: limit }} />
            }>

                <AdminHeader title={"IPs"} />
            </NavBarSecondary>

            <DTableWrapper>
                <DTable 
                    orderBy={orderBy}
                    orderDir={orderDir}
                    cols={[
                        { size: 1, orderFn: orderCol('IP'), orderCol: "IP", label: "IP", val: (row: UserLogIP) => row.IP }, 
                        { size: 1, orderFn: orderCol('DateCreated'), orderCol: "DateCreated", label: "Created", val: (row: UserLogIP) => moment(row.DateCreated).format() }, 
                        { size: 1, orderFn: orderCol('IsBlocked'), orderCol: "IsBlocked", label: "Blocked", val: (row: UserLogIP) =>  row.IsBlocked === 1 ? 
                            <Button variant="contained" onClick={doUnBlockIP(row.UserLogIPID)}>
                                  UnBlock
                            </Button>
                          : <Button onClick={doBlockIP(row.UserLogIPID)}>
                              Block
                          </Button>
                        }, 
                    ]}
                    data={data.data.Data}
                /> 
            </DTableWrapper>
        </div>
    ); 
}


export default IPList; 