/**
 *
 * CreateQuoteDTO
 */
import { CustomerContact, newCustomerContact } from 'gen/models/CustomerContact';
import { CreateQuoteItemDTO } from './CreateQuoteItemDTO';
import { Customer, newCustomer } from 'gen/models/Customer';

export type CreateQuoteDTO = {

	// BidTypeID int64
	BidTypeID: number;

	// Customer *models.Customer
	Customer: Customer;

	// CustomerContact *models.CustomerContact
	CustomerContact: CustomerContact;

	// Description string
	Description: string;

	// DueDate string
	DueDate: string;

	// Items []CreateQuoteItemDTO
	Items: CreateQuoteItemDTO[];

	// QuoteTypeID int64
	QuoteTypeID: number;

	// Sales []int64
	Sales: number[];

}

// newCreateQuoteDTO is a factory method for new CreateQuoteDTO objects
export const newCreateQuoteDTO = () : CreateQuoteDTO => ({
	BidTypeID: 0,
	Customer: newCustomer(),
	CustomerContact: newCustomerContact(),
	Description: '',
	DueDate: '',
	Items: [],
	QuoteTypeID: 0,
	Sales: [],
});

