/**
 *
 * SysVal
 */

export type SysVal = {

	// DateCreated int64
	DateCreated: number;

	// IsDeleted int
	IsDeleted: number;

	// Month int64
	Month: number;

	// ObjectID int64
	ObjectID: number;

	// ObjectType int64
	ObjectType: number;

	// SysValID int64
	SysValID: number;

	// SysValType int64
	SysValType: number;

	// Val float64
	Val: number;

	// Year int64
	Year: number;

}

// newSysVal is a factory method for creating new SysVal objects
export const newSysVal = () : SysVal => ({ 
	DateCreated: 0,
	IsDeleted: 0,
	Month: 0,
	ObjectID: 0,
	ObjectType: 0,
	SysValID: 0,
	SysValType: 0,
	Val: 0,
	Year: 0,
});

