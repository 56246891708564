import { CustomerAggregate } from "app/definitions/aggregates/CustomerAggregate";

export type UpdateCustomerDTO = {
    CustomerID: number; 
    Title: string; 
    Address: string; 
    Address2: string; 
    City: string; 
    State: string; 
    Zip: string; 
    Phone: string; 
    Fax: string; 
}

export const newUpdateCustomerDTO = () : UpdateCustomerDTO => ({
    CustomerID: 0, 
    Title: '', 
    Address: '', 
    Address2: '', 
    City: '', 
    State: '', 
    Zip: '', 
    Phone: '', 
    Fax: '', 
})

export const newUpdateCustomerDTOFromAggregate = (agg: CustomerAggregate) : UpdateCustomerDTO => ({
    CustomerID: agg.CustomerID, 
    Title: agg.Title, 
    Address: agg.Address, 
    Address2: agg.Address2, 
    City: agg.City, 
    State: agg.State, 
    Zip: agg.Zip, 
    Phone: agg.Phone, 
    Fax: agg.Fax, 
})

