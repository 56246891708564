/**
 *
 * Admin.IPs
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';

// GetIPs returns a collection of IP addresses
// GET /admin/ips?limit={limit:[0-9]+}&page={page:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission IPs_GetIPs
export const getIPs = async (limit : number, page : number, orderBy : string, orderDir : string) => await axios.get<any>(`/admin/ips?limit=${limit}&page=${page}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetIPs = (limit : number, page : number, orderBy : string, orderDir : string) => useQuery(["getIPs", limit, page, orderBy, orderDir], (_ : string, limit : number, page : number, orderBy : string, orderDir : string) => getIPs(limit,page,orderBy,orderDir));

// BlockIP blocks an IP address
// PUT /admin/ips/{ipID:[0-9]+}/block
// @permission IPs_BlockIP
export const blockIP = async (args : { ipID : number }) => await axios.put<any>(`/admin/ips/${args.ipID}/block`, {});
export const useBlockIP = () => useMutation(blockIP, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getIPs"]);
	},
	throwOnError: true, 
});
	

// UnBlockIP blocks an IP address
// PUT /admin/ips/{ipID:[0-9]+}/unblock
// @permission IPs_UnBlockIP
export const unBlockIP = async (args : { ipID : number }) => await axios.put<any>(`/admin/ips/${args.ipID}/unblock`, {});
export const useUnBlockIP = () => useMutation(unBlockIP, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getIPs"]);
	},
	throwOnError: true, 
});
	
