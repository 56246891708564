/**
 *
 * Base.Activity
 */
 
import axios from 'axios';
import { useMutation } from 'react-query';
import { CreateClientLogDTO } from 'gen/dtos/CreateClientLogDTO';

// CreateClientLog creates a client log
// POST /activity
export const createClientLog = async (args : { body : CreateClientLogDTO }) => await axios.post<any>(`/activity`, args.body);
export const useCreateClientLog = () => useMutation(createClientLog);
