/**
 *
 * Base.Forms
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateFormDTO } from 'gen/dtos/CreateFormDTO';
import { FormAggregate } from 'gen/aggregates/FormAggregate';
import { FormSubmitDTO } from 'gen/dtos/FormSubmitDTO';
import { FormsAggregate } from 'gen/aggregates/FormsAggregate';
import { UpdateFormDTO } from 'gen/dtos/UpdateFormDTO';

// GetForms returns a slice of forms
// GET /forms?page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Forms_GetForms
export const getForms = async (page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<FormsAggregate>(`/forms?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetForms = (page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getForms", page, limit, orderBy, orderDir], (_ : string, page : number, limit : number, orderBy : string, orderDir : string) => getForms(page,limit,orderBy,orderDir));

// GetFormFromID returns a form by its id
// GET /forms/{formID:[0-9]+}
// @permission Forms_GetFormFromID
export const getFormFromID = async (formID : number) => await axios.get<FormAggregate>(`/forms/${formID}`);
export const useGetFormFromID = (formID : number) => useQuery(["getFormFromID", formID], (_ : string, formID : number) => getFormFromID(formID));

// CreateForm creates a form
// POST /forms
// @permission Forms_CreateForm
export const createForm = async (args : { body : CreateFormDTO }) => await axios.post<FormAggregate>(`/forms`, args.body);
export const useCreateForm = () => useMutation(createForm, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
	},
	throwOnError: true, 
});
	

// UpdateForm updates a form
// PUT /forms/{formID:[0-9]+}
// @permission Forms_UpdateForm
export const updateForm = async (args : { formID : number, body : UpdateFormDTO }) => await axios.put<any>(`/forms/${args.formID}`, args.body);
export const useUpdateForm = () => useMutation(updateForm, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
		queryCache.invalidateQueries(["getFormFromID", variables.formID]);
	},
	throwOnError: true, 
});
	

// DeleteForm deletes a form
// DELETE /forms/{formID:[0-9]+}
// @permission Forms_DeleteForm
export const deleteForm = async (args : { formID : number }) => await axios.delete<any>(`/forms/${args.formID}`);
export const useDeleteForm = () => useMutation(deleteForm, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
		queryCache.invalidateQueries(["getFormFromID", variables.formID]);
	},
	throwOnError: true, 
});
	

// SubmitForm submits a form
// POST /forms/{formID:[0-9]+}/submit
// @permission Forms_SubmitForm
export const submitForm = async (args : { formID : number, body : FormSubmitDTO }) => await axios.post<any>(`/forms/${args.formID}/submit`, args.body);
export const useSubmitForm = () => useMutation(submitForm, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
		queryCache.invalidateQueries(["getFormFromID", variables.formID]);
	},
	throwOnError: true, 
});
	

// GetFormData gets data submitted to a form
// GET /forms/{formID:[0-9]+}/data?page={page:[0-9]+}&limit={limit:[0-9]+}
// @permission Forms_GetFormData
export const getFormData = async (formID : number, page : number, limit : number) => await axios.get<any>(`/forms/${formID}/data?page=${page}&limit=${limit}`);
export const useGetFormData = (formID : number, page : number, limit : number) => useQuery(["getFormData", formID, page, limit], (_ : string, formID : number, page : number, limit : number) => getFormData(formID,page,limit));

// GetFormSubmissionFromID gets a specific form submission
// GET /forms/{formID:[0-9]+}/data/{submissionID:[0-9]+}
// @permission Forms_GetFormSubmissionFromID
export const getFormSubmissionFromID = async (formID : number, submissionID : number) => await axios.get<any>(`/forms/${formID}/data/${submissionID}`);
export const useGetFormSubmissionFromID = (formID : number, submissionID : number) => useQuery(["getFormSubmissionFromID", formID, submissionID], (_ : string, formID : number, submissionID : number) => getFormSubmissionFromID(formID,submissionID));

// GenerateRandomSubmissions generates n random form submissions
// PUT /forms/{formID:[0-9]+}/random?qty={qty:[0-9]+}
// @permission Forms_GenerateRandomSubmissions
export const generateRandomSubmissions = async (args : { formID : number, qty : number }) => await axios.put<any>(`/forms/${args.formID}/random?qty=${args.qty}`, {});
export const useGenerateRandomSubmissions = () => useMutation(generateRandomSubmissions, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
		queryCache.invalidateQueries(["getFormFromID", variables.formID]);
	},
	throwOnError: true, 
});
	

// ExportForm exports a json file containing a form
// GET /forms/{formID:[0-9]+}/export
// @permission Forms_ExportForm
export const exportForm = async (formID : number) => await axios.get(`/forms/${formID}/export`, { responseType: 'blob' });
export const useExportForm = (formID : number) => useQuery(["exportForm", formID], (_ : string, formID : number) => exportForm(formID));

// ImportForm imports a form from a json file
// POST /forms/import
// @permission Forms_ImportForm
export const importForm = async (args : { body : FormData }) => await axios.post<any>(`/forms/import`, args.body, { headers: { "content-type": "multipart/form-data" } });
export const useImportForm = () => useMutation(importForm, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getForms"]);
	},
	throwOnError: true, 
});
	
