/**
 *
 * Admin.Roles
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateRoleDTO } from 'gen/dtos/CreateRoleDTO';
import { Role } from 'gen/models/Role';
import { RoleAggregate } from 'gen/aggregates/RoleAggregate';
import { RoleCollectionAggregate } from 'gen/aggregates/RoleCollectionAggregate';
import { UpdateRoleDTO } from 'gen/dtos/UpdateRoleDTO';

// CreateRole creates a role
// POST /roles
// @permission Roles_CreateRole
export const createRole = async (args : { body : CreateRoleDTO }) => await axios.post<Role>(`/roles`, args.body);
export const useCreateRole = () => useMutation(createRole, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getRolesByPermission"]);
	},
	throwOnError: true, 
});
	

// UpdateRole updates a role
// PUT /roles/{roleID:[0-9]+}
// @permission Roles_UpdateRole
export const updateRole = async (args : { roleID : number, body : UpdateRoleDTO }) => await axios.put<Role>(`/roles/${args.roleID}`, args.body);
export const useUpdateRole = () => useMutation(updateRole, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getRolesByPermission"]);
		queryCache.invalidateQueries(["getRoleFromID", variables.roleID]);
	},
	throwOnError: true, 
});
	

// DeleteRole deletes a role
// DELETE /roles/{roleID:[0-9]+}
// @permission Roles_DeleteRole
export const deleteRole = async (args : { roleID : number }) => await axios.delete<Role>(`/roles/${args.roleID}`);
export const useDeleteRole = () => useMutation(deleteRole, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getRolesByPermission"]);
		queryCache.invalidateQueries(["getRoleFromID", variables.roleID]);
	},
	throwOnError: true, 
});
	

// GetRoles returns a slice of roles
// GET /roles?page={page:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z0-9]+}
// @permission Roles_GetRoles
export const getRoles = async (page : number, orderBy : string, orderDir : string) => await axios.get<RoleCollectionAggregate>(`/roles?page=${page}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetRoles = (page : number, orderBy : string, orderDir : string) => useQuery(["getRoles", page, orderBy, orderDir], (_ : string, page : number, orderBy : string, orderDir : string) => getRoles(page,orderBy,orderDir));

// GetRolesByPolicy returns a slice of roles attached to a policy
// GET /roles?policyID={policyID:[0-9]+}
// @permission Roles_GetRolesByPolicy
export const getRolesByPolicy = async (policyID : number) => await axios.get<Role[]>(`/roles?policyID=${policyID}`);
export const useGetRolesByPolicy = (policyID : number) => useQuery(["getRolesByPolicy", policyID], (_ : string, policyID : number) => getRolesByPolicy(policyID));

// GetRolesByPermission returns a slice of roles attached to a policy that contains a permission
// GET /roles?permission={permissionName:[a-z0-9A-Z_]+}
// @permission Roles_GetRolesByPermission
export const getRolesByPermission = async (permission : string) => await axios.get<Role[]>(`/roles?permission=${permission}`);
export const useGetRolesByPermission = (permission : string) => useQuery(["getRolesByPermission", permission], (_ : string, permission : string) => getRolesByPermission(permission));

// GetRoleFromID returns a role
// GET /roles/{roleID:[0-9]+}
// @permission Roles_GetRoleFromID
export const getRoleFromID = async (roleID : number) => await axios.get<RoleAggregate>(`/roles/${roleID}`);
export const useGetRoleFromID = (roleID : number) => useQuery(["getRoleFromID", roleID], (_ : string, roleID : number) => getRoleFromID(roleID));

// AddPolicyToRole adds a permission to a role
// POST /roles/{roleID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission Roles_AddPolicyToRole
export const addPolicyToRole = async (args : { roleID : number, policyID : number }) => await axios.post<any>(`/roles/${args.roleID}/policies/${args.policyID}`, {});
export const useAddPolicyToRole = () => useMutation(addPolicyToRole, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getRolesByPermission"]);
		queryCache.invalidateQueries(["getRoleFromID", variables.roleID]);
	},
	throwOnError: true, 
});
	

// RemovePolicyFromRole removes a permission from a role
// DELETE /roles/{roleID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission Roles_RemovePolicyFromRole
export const removePolicyFromRole = async (args : { roleID : number, policyID : number }) => await axios.delete<any>(`/roles/${args.roleID}/policies/${args.policyID}`);
export const useRemovePolicyFromRole = () => useMutation(removePolicyFromRole, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getRolesByPermission"]);
		queryCache.invalidateQueries(["getRoleFromID", variables.roleID]);
	},
	throwOnError: true, 
});
	
