import { Customer, newCustomer } from 'gen/models/Customer';
import { CustomerContact, newCustomerContact } from 'gen/models/CustomerContact';
import { newQuoteCustomer, QuoteCustomer } from 'gen/models/QuoteCustomer';

export type QuoteCustomerAggregate = QuoteCustomer & {
    Customer: Customer; 
    Contact: CustomerContact; 
}

export const newQuoteCustomerAggregate = () : QuoteCustomerAggregate => ({
    ...newQuoteCustomer(), 
    Customer: newCustomer(), 
    Contact: newCustomerContact(), 
});

export const newQuoteCustomerAggregateFromCustomer = (customer: Customer, quoteCustomerType: number) : QuoteCustomerAggregate => ({
    ...newQuoteCustomer(), 
    CustomerID: customer.CustomerID, 
    Customer: customer, 
    Contact: newCustomerContact(), 
    QuoteCustomerType: quoteCustomerType, 
});;

export const newQuoteCustomerFromAggregate = (customer: QuoteCustomerAggregate) : QuoteCustomer => ({
    QuoteCustomerID: customer.QuoteCustomerID, 
    CustomerID: customer.CustomerID, 
    CustomerContactID: customer.CustomerContactID, 
    DateCreated: customer.DateCreated, 
    QuoteID: customer.QuoteID, 
    IsDeleted: customer.IsDeleted, 
    QuoteCustomerType: customer.QuoteCustomerType, 
    QuoteNumberID: customer.QuoteNumberID, 
})