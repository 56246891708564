import { newQuoteItem, QuoteItem } from "gen/models/QuoteItem";
import { newVendor, Vendor } from "gen/models/Vendor";
import { newVendorItem, VendorItem } from "gen/models/VendorItem";

export type QuoteItemAggregate = QuoteItem & {
    Vendor: Vendor; 
    VendorItem: VendorItem;   
}

export const newQuoteItemAggregate = () : QuoteItemAggregate => ({
    ...newQuoteItem(), 
    Vendor: newVendor(), 
    VendorItem: newVendorItem(), 
})