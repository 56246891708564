import { Button, CircularProgress, FormControl, FormHelperText, IconButton, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Cancel, Delete } from '@material-ui/icons';
import { useGetVendors } from 'app/api/vendors';
import { newQuoteItemAggregate, QuoteItemAggregate } from 'app/definitions/aggregates/QuoteItemAggregate';
import { Container } from 'core/components/Container';
import { MoneyInput } from 'core/components/MoneyInput';
import handleNetworkError from 'core/utils/handleNetworkError';
import { ListItem } from 'gen/models/ListItem';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { itemMeasurementTypeAtom, selectedQuoteAtom } from './state';

const QuoteItems = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const itemMeasurementTypes = useRecoilValue(itemMeasurementTypeAtom);
    const [removeItemIndex, setRemoveItemIndex] = useState(-1); 
    const { data: vendorData, status: vendorStatus, error: vendorError } = useGetVendors(0, 100, "Title", "ASC"); 

    if(vendorStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(vendorError)}
        </Alert>
    }

    if(vendorStatus === "loading" || !vendorData) {
        return <CircularProgress /> 
    }

    const removeItemStart = (index: number) => () => {
        setRemoveItemIndex(index); 
    }

    const removeItem = (index: number) => () => {
        const updatedItems = quote.Items.filter((v, k) => k !== index)
        setQuote({
            ...quote, 
            Items: updatedItems, 
            TotalPrice: updatedItems.length > 0 ? updatedItems.map(x => x.TotalPrice).reduce((p, v) => p + v) : 0, 
        })
    }

    const addItem = () => {
        setQuote({
            ...quote, 
            Items: [...quote.Items, { ...newQuoteItemAggregate(), MeasurementType: (itemMeasurementTypes.Items.find(x => x.ListItemID === itemMeasurementTypes.DefaultListItemID) as ListItem).ListItemID }]
        })
    }
    
    // Item Vendor 
    const onItemVendorChange = (index: number) => (e) => {
        
        const vendorID = parseInt(e.target.value as string); 
        console.log('Updating items at index ', index, vendorID); 
        
        const updatedItems = Object.assign([...quote.Items], {[index]: { ...quote.Items[index], VendorID: vendorID, VendorItemID: 0, Qty: 0, VendorItemPrice: 0 } })
        
        setQuote({
            ...quote, 
            Items: updatedItems, 
            TotalPrice: updatedItems.length > 0 ? updatedItems.map(x => x.TotalPrice).reduce((p, v) => p + v) : 0, 
        })

    }

    // Item Vendor Item
    const onItemVendorItemChange = (index: number) => (e) => {

        const itemID = parseInt(e.target.value as string)
        const updatedItems = Object.assign([...quote.Items], {[index]: {
            ...quote.Items[index], VendorItemID: itemID, VendorItemPrice: 0, TotalPrice: 0, Qty: 1
        }});
        
        setQuote({
            ...quote, 
            Items: updatedItems,  
            TotalPrice: updatedItems.length > 0 ? updatedItems.map(x => x.TotalPrice).reduce((p, v) => p + v) : 0, 
        });

        // getVendorItemFromID(quote.Items[index].VendorID, val.id)
        //     .then((item) => {
        //     }); 
    }


    // Item Qty  
    // const onItemQtyChange = (index: number) => (e) => {
    //     const qty = parseInt(e.target.value); 
    //     const quoteItems = Object.assign([], [...quote.Items], {[index]: {...quote.Items[index], Qty: qty, TotalPrice: quote.Items[index].VendorItemPrice * qty, TotalCost: quote.Items[index].VendorItemCost * qty }}) as QuoteItemAggregate[]; 
    //     setQuote({
    //         ...quote, 
    //         Items: quoteItems, 
    //         TotalPrice: quoteItems.length  > 0 ? quoteItems.map(x => x.TotalPrice).reduce((p, v) => p + v) : 0, 
    //         ProjectCost: quoteItems.length > 0 ? quoteItems.map(x => x.TotalCost).reduce((p, v) => p + v) : 0, 
    //     })
    // }

    // // Item Price 
    // const onItemPriceChange = (index: number) => (value: number) => {

    //     setQuote({
    //         ...quote, 
    //         Items: Object.assign([], [...quote.Items], {[key]: {...item, MeasurementQty: parseInt(e.target.value as string)}})
    //     })} 
    //     // console.log(`item ${index} price change`, e.target.value); 
    //     const quoteItems = Object.assign([], [...quote.Items],{[index]: {...quote.Items[index], VendorItemPrice: value, TotalPrice: value * quote.Items[index].Qty }}) as QuoteItemAggregate[]; 
    //     setQuote({
    //         ...quote, 
    //         Items: quoteItems, 
    //         TotalPrice: quoteItems.length > 0 ? quoteItems.map(x => x.TotalPrice).reduce((p, v) => p + v) : 0, 
    //     })
    // }
    
    // const onItemCostChange = (index: number) => (value: number) => {

    //     // const quoteItems =  as QuoteItemAggregate[]; 
        
    //     setQuote({
    //         ...quote, 
    //         Items: Object.assign([], [...quote.Items],{[index]: {...quote.Items[index], VendorItemCost: value }}) 
    //         // ProjectCost: quoteItems.length > 0 ? quoteItems.map(x => x.TotalCost).reduce((p, v) => p + v) : 0, 
    //     })

    // }

    const onTotalCostChange = (index: number) => (value: number) => {

        const quoteItems = Object.assign([], [...quote.Items],{[index]: {...quote.Items[index], TotalCost: value  }})  as QuoteItemAggregate[]; 
        
        setQuote({
            ...quote, 
            Items: quoteItems, 
            ProjectCost: quoteItems.length > 0 ? quoteItems.map(x => x.TotalCost).reduce((p, v) => p + v) : 0, 
        })
    }

    return (
        
        <Container title={`Units (${quote.ItemCount})`}>

            {quote.Items.map((item, key) => {
                
                const vendor = item.VendorID > 0 ? vendorData.data.Data.find(x => x.VendorID === item.VendorID) : undefined; 
                const items = vendor ? vendor.Items : []; 

                return (
                    <Container title={`Unit #${key + 1}`} key={key}>
                        
                        {/* VendorID: {item.VendorID}
                        Item Count: {items.length} */}

                        {/* Details */}
                        <div style={{ display: 'flex', flexDirection: 'row'}}>

                            <div style={{ flexGrow: 1, padding: 5 }}>
                                <TextField
                                    label="Unit Name / Tag Name"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={item.Title}
                                    fullWidth={true}
                                    InputProps={{
                                        fullWidth: true, 
                                        style: { width: '100%' }
                                    }}
                                    onChange={(e) => {
                                        setQuote({
                                            ...quote, 
                                            Items: Object.assign([], [...quote.Items], {[key]: {...item, Title: e.target.value }})
                                        })
                                    }}
                                    helperText="Unit name or tag name"
                                />
                            </div>

                            <div style={{ flexGrow: 1, padding: 5}}>
                                <TextField
                                    select 
                                    fullWidth 
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    value={item.VendorID}
                                    onChange={onItemVendorChange(key)}
                                    label="Manufacturer"
                                >
                                        {vendorData.data.Data.map((vendor, idx) => 
                                            <MenuItem value={vendor.VendorID} key={idx}>
                                                {vendor.Title}
                                            </MenuItem>
                                        )}
                                </TextField>
                                {/* <Autocomplete
                                    title={"Manufacturer"}
                                    searchFn={searchVendors}
                                    initialValue={item.Vendor.Title}
                                    onSelect={onItemVendorChange(key)}
                                    helperText="Search manufacturers by name"
                                />  */}

                            </div>

                            <div style={{ flexGrow: 1, padding: 5}}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={item.VendorItemID}
                                    onChange={onItemVendorItemChange(key)} 
                                    disabled={item.VendorID === 0}
                                    label="Model"
                                    select
                                >
                                    {items.map((item, key) => 
                                        <MenuItem key={key} value={item.VendorItemID}>{item.Title}</MenuItem>
                                    )}
                                </TextField>
                                {/* <Autocomplete
                                    title={"Model"}
                                    searchFn={(searchString: string) => searchVendorItems(item.VendorID, searchString)}
                                    initialValue={item.VendorItem.ModelNumber}
                                    onSelect={onItemVendorItemChange(key)}
                                    helperText="Search manufacturer models by model number"
                                />  */}

                            </div>

                            <div style={{ flexGrow: 1, padding: 5, display: 'none'}}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel htmlFor={`measurementType-${key}`}>Measurement Type</InputLabel>
                                    <Select 
                                        value={item.MeasurementType}
                                        id={`measurementType-${key}`}
                                        input={<Input />}
                                        onChange={(e) => {
                                            setQuote({ 
                                                ...quote, 
                                                Items: Object.assign([], [...quote.Items], {[key]: {...item, MeasurementType: parseInt(e.target.value as string)}})
                                            })
                                        }}
                                    >
                                        {itemMeasurementTypes.Items.map((imt, key) => 
                                            <MenuItem value={imt.ListItemID} key={key}>
                                                {imt.Name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>Measurement Type</FormHelperText>
                                </FormControl>
                            </div>

                            {/* <div style={{ flexGrow: 1, padding: 5 }}>
                                <TextField
                                    label={"CFM / Tonnage"}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={item.MeasurementQty} 
                                    onChange={(e) => setQuote({
                                        ...quote, 
                                        Items: Object.assign([], [...quote.Items], {[key]: {...item, MeasurementQty: parseInt(e.target.value as string)}})
                                    })} 
                                />
                            </div> */}

                            <div style={{ padding: 5 }}>
                                {removeItemIndex !== key && 
                                    <IconButton onClick={removeItemStart(key)}>    
                                        <Delete /> 
                                    </IconButton>
                                }
                                {removeItemIndex === key && 

                                    <div>
                                        <IconButton onClick={removeItem(key)} style={{ backgroundColor: "#f00"}}>
                                            <Delete style={{ color: "#fff" }}/> 
                                        </IconButton>
                                        &nbsp;
                                        <IconButton onClick={() => setRemoveItemIndex(-1)}>
                                            <Cancel /> 
                                        </IconButton>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Notes */}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flexGrow: 1, padding: 5 }}>
                                
                                <TextField
                                    label="Item Notes"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={item.Description}
                                    fullWidth={true}
                                    InputProps={{
                                        fullWidth: true, 
                                        style: { width: '100%' }
                                    }}
                                    onChange={(e) => {
                                        setQuote({
                                            ...quote, 
                                            Items: Object.assign([], [...quote.Items], {[key]: {...item, Description: e.target.value }})
                                        })
                                    }}
                                />

                            </div>
                        </div>

                        {/* Price */}
                        <div style={{ display: 'flex', flexDirection: 'row'}}>

                                {/* <div style={{ padding: 5 }}>
                                    <MoneyInput label="Item Sell Price" value={item.VendorItemPrice} onChange={(v) => {

                                        setQuote({ 
                                            ...quote, 
                                            Items: Object.assign([], [...quote.Items], {[key]: {...item, VendorItemPrice: v }})
                                        })
                                    }} /> 
                                </div> */}

                                <div style={{ padding: 5 }}>
                                    <TextField
                                        label={"Qty"}
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        value={item.Qty} 
                                        onChange={(e) => {
                                            setQuote({
                                                ...quote, 
                                                Items: Object.assign([], [...quote.Items], {[key]: {...item, Qty: parseInt(e.target.value) }})
                                            })
                                        }} 
                                    />
                                </div>

                                <div style={{ padding: 5 }}>
                                    <MoneyInput label="Total Sell Price" value={item.TotalPrice} onChange={(value: number) => {
                                        
                                        const items : QuoteItemAggregate[] = Object.assign([], [...quote.Items], {[key]: {...item, TotalPrice: value }}); 

                                        setQuote({
                                            ...quote, 
                                            Items: items, 
                                            TotalPrice: items.map(x => x.TotalPrice).reduce((p, v) => p + v, 0), 
                                        })
                                    }} /> 
                                </div>

                                {/* <div style={{ padding: 5 }}>
                                    <MoneyInput 
                                        label="Item Cost Price" 
                                        value={item.VendorItemCost} 
                                        onChange={onItemCostChange(key)} 
                                    /> 
                                </div> */}
                                
                                <div style={{ padding: 5 }}>
                                    <MoneyInput 
                                        label="Total Cost Price" 
                                        value={item.TotalCost} 
                                        onChange={onTotalCostChange(key)} 
                                    /> 
                                </div>

                        </div>

                    </Container>
                )
            })}
            <Container>
                <div style={{ display: 'flex'}}>
                    <div style={{ flexGrow: 1 }}>
                        <Button variant="contained" onClick={addItem}>Add Product</Button>
                    </div>
                    <div>
                        Total: ${quote.TotalPrice.toFixed(2)}
                    </div>
                </div>
            </Container>
        </Container>
    )
}

export default QuoteItems;      