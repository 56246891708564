import { Button, CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Delete, MoreVert } from '@material-ui/icons';
import { useDeleteJob } from 'app/api/jobs';
import { deleteQuote, useGetQuoteFromID, useUpdateQuote } from 'app/api/quotes';
import { newUpdateQuoteDTOFromAggregate } from 'app/definitions/dtos/UpdateQuoteDTO';
import { PaperBox } from 'app/pages/jobs/common';
import AlertDialog from 'core/components/alertDialog';
// import FileDropTarget from 'core/components/FileDropTarget';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { SnackbarError, SnackbarSuccess } from 'core/components/Snackbars';
import { ListTypeBidType, ListTypeItemMeasurementType, ListTypeMarket, ListTypeProbability, ListTypeQuoteStatus, ListTypeQuoteType } from 'core/definitions/constants/listTypes';
import { ObjectTypeQuote } from 'core/definitions/constants/objectTypes';
import { QuoteCustomerType } from 'core/definitions/constants/quoteCustomerType';
import ObjectTodos from 'core/pages/todos/ObjectTodos';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Jobs_DeleteJobPermission, Quotes_CanManageLogsPermission, Quotes_DeleteQuotePermission } from 'gen/constants/permissions';
// import { uploadFile } from 'gen/routes/Files';
import { useGetListFromListType } from 'gen/routes/Lists';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import CreateJobDialog from './CreateJobDialog';
import NewQuoteDetailsForm from './NewQuoteDetailsForm';
import QuoteCustomers from './QuoteCustomers';
import QuoteDetailsForm from './QuoteDetailsForm';
import QuoteItems from './QuoteItems';
import QuoteNotes from './QuoteNotes';
import QuoteRevisions from './QuoteRevisions';
import QuoteSales from './QuoteSales';
import { bidTypesAtom, itemMeasurementTypeAtom, marketsAtom, probabilitiesAtom, quoteStatusesAtom, quoteTypesAtom, selectedQuoteAtom, showCreateJobDialogAtom } from './state';

const QuoteDetail = () => {

    const { id } = useParams() as { id: string }; 
    const { data: quoteData, status: quoteStatus, error: quoteError } = useGetQuoteFromID(parseInt(id as string)); 
    const { data: quoteStatusesData, status: quoteStatusesStatus, error: quoteStatusesError } = useGetListFromListType(ListTypeQuoteStatus);
    const { data: marketData, status: marketStatus, error: marketError } = useGetListFromListType(ListTypeMarket);
    const { data: quoteTypeData, status: quoteTypeStatus } = useGetListFromListType(ListTypeQuoteType);
    const { data: bidTypeData, status: bidTypeStatus } = useGetListFromListType(ListTypeBidType);
    const { data: probabilitiesData, status: probabilitiesStatus } = useGetListFromListType(ListTypeProbability);
    const { data: itemMeasurementTypeData, status: itemMeasurementTypeStatus  } = useGetListFromListType(ListTypeItemMeasurementType);


    const setSelectedQuote = useSetRecoilState(selectedQuoteAtom); 
    const setQuoteStatuses = useSetRecoilState(quoteStatusesAtom); 
    const setQuoteTypes = useSetRecoilState(quoteTypesAtom); 
    const setMarkets = useSetRecoilState(marketsAtom); 
    const setBidTypes = useSetRecoilState(bidTypesAtom); 
    const setProbabilities = useSetRecoilState(probabilitiesAtom); 
    const setItemMeasurementTypes = useSetRecoilState(itemMeasurementTypeAtom);
    
    // Quote 
    useEffect(() => {
        if(quoteStatus === "success" && quoteData) {
            setSelectedQuote(quoteData.data); 
        }
    }, [quoteStatus, quoteData, setSelectedQuote]); 

    // Status
    useEffect(() => {
        if(quoteStatusesStatus === "success" && quoteStatusesData) {
            setQuoteStatuses(quoteStatusesData.data); 
        }
    }, [quoteStatusesStatus, quoteStatusesData, setQuoteStatuses])

    // Markets
    useEffect(() => {
        if(marketStatus === "success" && marketData) {
            setMarkets(marketData.data); 
        }
    }, [marketStatus, marketData, setMarkets])

    // QuoteTypes
    useEffect(() => {
        if(quoteTypeStatus === "success" && quoteTypeData) {
            setQuoteTypes(quoteTypeData.data); 
        }
    }, [quoteTypeStatus, quoteTypeData, setQuoteTypes])

    // Bid Types
    useEffect(() => {
        if(bidTypeStatus === "success" && bidTypeData) {
            setBidTypes(bidTypeData.data); 
        }
    }, [bidTypeStatus, bidTypeData, setBidTypes])

    // Probabilities
    useEffect(() => {
        if(probabilitiesStatus === "success" && probabilitiesData) {
            setProbabilities(probabilitiesData.data); 
        }
    }, [probabilitiesStatus, probabilitiesData, setProbabilities])

    // ItemMeasurementType
    useEffect(() => {
        if(itemMeasurementTypeStatus === "success" && itemMeasurementTypeData) {
            setItemMeasurementTypes(itemMeasurementTypeData.data); 
        }
    }, [itemMeasurementTypeStatus, itemMeasurementTypeData, setItemMeasurementTypes])


    if(quoteStatusesStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(quoteStatusesError)}
        </Alert>
    }

    if(quoteStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(quoteError)}
        </Alert>
    }

    if(marketStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(marketError)}
        </Alert>
    }

    if(
        quoteStatus === "loading" || !quoteData || 
        quoteStatusesStatus === "loading" || !quoteStatusesData || 
        marketStatus === "loading" || !marketData ||
        bidTypeStatus === "loading" || !bidTypeData ||
        probabilitiesStatus === "loading" || !probabilitiesData ||
        itemMeasurementTypeStatus === "loading" || !itemMeasurementTypeData
    ) {
        return <CircularProgress /> 
    }

    // <animated.div style={animatedProps}>
    return (
            <QuoteDetailInner />
        )
        // </animated.div>
}

const QuoteDetailInner = () => {
    
    const { id } = useParams() as { id: string }; 
    const setSelectedQuote = useSetRecoilState(selectedQuoteAtom); 
    const { data: quoteData, status: quoteStatus, error: quoteError } = useGetQuoteFromID(parseInt(id as string)); 


    const [updateQuote, { status: updateQuoteStatus, error: updateQuoteError }] = useUpdateQuote(); 
    const history = useHistory(); 
    const quote = useRecoilValue(selectedQuoteAtom); 
    const [tab, setTab] = useState(0); 
    const setCreateJobDialogShowing = useSetRecoilState(showCreateJobDialogAtom);
    const [ createJobError, setCreateJobError ] = useState(""); 

    
    // Lists 

    // const [showAwardDate, setShowAwardDate] = useState(false); 
    const [updateError, setUpdateError] = useState(''); 
    const [updateSuccess, setUpdateSuccess] = useState(false); 

    useEffect(() => {
        if(updateQuoteStatus === "success") {
            setUpdateSuccess(true); 
        }
        if(updateQuoteStatus === "error") {
            setUpdateSuccess(false); 
            setUpdateError(handleNetworkError(updateQuoteError)); 
        }
    }, [updateQuoteStatus, updateQuoteError]);

    useEffect(() => {
        if(quoteStatus === "success" && quoteData) {
            setSelectedQuote(quoteData.data)
        }
    }, [quoteStatus, quoteData, setSelectedQuote]); 

    if(quoteStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(quoteError)}
        </Alert>
    }

    if(quoteStatus === "loading" || !quoteData) {
        return <CircularProgress /> 
    }

    
    
    // const onSetIsAwardDateSet = () =>{
    //     if(quote.IsAwardDateSet === 0) {
    //         setQuote({
    //             ...quote, 
    //             IsAwardDateSet: 1, 
    //             AwardDateString: moment().format(), 
    //         });
    //     } else {
    //         setQuote({
    //             ...quote, 
    //             IsAwardDateSet: 0, 
    //             AwardDateString: '', 
    //         });
    //     }
    // }

    // const onAwardDateChange = (date: Date) => {
    //     setQuote({
    //         ...quote, 
    //         AwardDateString: moment(date).format(), 
    //     })
    // }

    // const handleFileSelection = (files: File[]) => {
    //     files.map(file => {
    //         const formData = new FormData(); 
    //         formData.append("myFile", file); 
    //         uploadFile({ 
    //             body: formData, 
    //             objectType: 10, 
    //             objectID: 20, 
    //         }).then(() => {
    //             alert('file uploaded!!!'); 
    //         })
    //     })
    //     console.log('handleFileSelection', files); 
    //     // setSelectedNewsletter({
    //     //   ...selectedNewsletter,
    //     //   files: [...files],
    //     // });
    // };
    
    
    const onUpdateQuote = () => {
        updateQuote(newUpdateQuoteDTOFromAggregate(quote)); 
    }

    const noCustomers = quote.Customers.length === 0 || !quote.Customers.some(x => [QuoteCustomerType.QuoteCustomerTypeMechanicalContractor, QuoteCustomerType.QuoteCustomerTypeGeneralContractor].indexOf(x.QuoteCustomerType) > -1)

    const doCreateJob = () => {

        if(noCustomers) {
            setCreateJobError('A quote must have atleast one customer to create a job.');
            return; 
        }

        // Validation 
        for(let i = 0; i < quote.Customers.length; i++) {
            
            // Must have a contact 
            if(
                [
                    QuoteCustomerType.QuoteCustomerTypeMechanicalContractor, 
                    QuoteCustomerType.QuoteCustomerTypeGeneralContractor
                ].indexOf(quote.Customers[i].QuoteCustomerType) > -1
                &&
                quote.Customers[i].CustomerContactID === 0
            ) {
                setCreateJobError('All Mechanical Contractors and General Contractors / Owners must have a contact specified.')
                return; 
            }
        }

        updateQuote(newUpdateQuoteDTOFromAggregate(quote))
            .then(() => {
                setCreateJobDialogShowing(true); 
            });
    }

    const gotoJob = () => {
        history.push(`/jobs/${quote.JobID}`)
    }

    const gotoQuote = (quoteID: number) => { 
        history.push(`/quotes/${quoteID}`)
    }

    return (
        <div style={{ backgroundColor: '#ddd', height: '100%', overflowY: 'auto', width: '100%' }}>

            <AlertDialog 
                title={"Create Job Error"}
                open={createJobError.length > 0}
                description={createJobError}
                handleClose={() => setCreateJobError("")}
            /> 

            <SnackbarError open={updateError.length > 0} onClose={() => setUpdateError('')}>{updateError}</SnackbarError>
            <SnackbarSuccess open={updateSuccess} onClose={() => setUpdateSuccess(false)}>
                Quote Updated
            </SnackbarSuccess>

            <NavBarSecondary back="/quotes"
                right={
                    <div>

                        {quote.JobID === 0 && 
                            <Button onClick={onUpdateQuote} variant="contained" color="primary" disabled={updateQuoteStatus === "loading"}>
                                {quote.QuoteNumberID === 0 ? 'Create Quote' : 'Save' }
                                {updateQuoteStatus === "loading" && <CircularProgress size={20} /> }
                            </Button>
                        }

                        {quote.QuoteNumberID > 0 && 
                            <div style={{ display: 'inline-block', marginLeft: 20, marginRight: 20 }}>
                                Revision: {quote.Revision} / {quote.CurrentRevision}
                            </div>
                        }
                    
                        &nbsp; &nbsp; 
                        {(quote.QuoteNumberID > 0 && quote.JobID === 0 && quote.AreWeBidding === 0) && 
                            <Button onClick={doCreateJob} variant="contained" color="secondary" disabled={updateQuoteStatus === "loading" || noCustomers }>
                                Create job 
                            </Button>
                        }

                        {quote.JobID > 0 && 
                            <Button onClick={gotoJob} variant="contained">
                                View Job 
                            </Button>
                        }

                        <Perm perm={[Jobs_DeleteJobPermission, Quotes_DeleteQuotePermission]} any>
                            <QuoteOptions /> 
                        </Perm>
                    
                    </div>
                }
            >
                <strong>Quotes / {quote.QuoteNumberID > 0 ? quote.QuoteNumber : 'New'}</strong>
                
                {quote.PrevQuote.QuoteID > 0 && 
                    <Button size="small" onClick={() => gotoQuote(quote.PrevQuote.QuoteID)}>
                        <ChevronLeft /> 
                        {quote.PrevQuote.QuoteNumber}
                    </Button>
                }

                {quote.NextQuote.QuoteID > 0 && 
                    <Button size="small" onClick={() => gotoQuote(quote.NextQuote.QuoteID)}>
                        {quote.NextQuote.QuoteNumber}
                        <ChevronRight /> 
                    </Button>
                }

            </NavBarSecondary>

            {quote.QuoteNumberID > 0 && 
                <QuoteTabs>
                    
                    <QuoteTab value={0} tab={tab} onClick={() => setTab(0)}>
                        Quote
                    </QuoteTab>
                    
                    <QuoteTab value={1} tab={tab} onClick={() => setTab(1)}>
                        Revisions ({quote.CurrentRevision})
                    </QuoteTab>

                    <Perm perm={Quotes_CanManageLogsPermission}>
                        <QuoteTab value={2} tab={tab} onClick={() => setTab(2)}>
                            Logs
                        </QuoteTab>
                    </Perm>

                </QuoteTabs>
            }

            {tab === 0 && 
                <div style={{ width: '100%', overflowX: 'hidden' }}>
                    
                    {quote.QuoteNumberID === 0 ? (<div>
                        <NewQuoteDetailsForm /> 
                        {/* <NewQuoteCustomerForm />  */}
                    </div>) : (
                        <div>
                            {/* Details */}
                            <QuoteDetailsForm /> 
                        </div>
                    )}

                    {/* Sales */}
                    <QuoteSales /> 

                    {/* Mechanical Contractors */}
                    <QuoteCustomers 
                        customerType={QuoteCustomerType.QuoteCustomerTypeMechanicalContractor}
                        title="Mechanical Contractors"
                        contact
                    /> 

                    {/* Engineers */}
                    <QuoteCustomers 
                        customerType={QuoteCustomerType.QuoteCustomerTypeEngineer}
                        title="Engineers"
                    /> 

                    {/* Engineers */}
                    <QuoteCustomers 
                        customerType={QuoteCustomerType.QuoteCustomerTypeGeneralContractor}
                        title="General Contractor / Owner"
                        contact
                    /> 

                    {/* Quote Items */}
                    <QuoteItems /> 
            
                    {/* Notes */}
                    <QuoteNotes /> 

                    {/* Files */}

                    {/* <Container title={`Files(${quote.FileCount})`}>
                        <FileDropTarget
                            handleFileSelection={handleFileSelection}
                        />
                    </Container> */}

                   

                </div>
            }

            {tab === 1 && 
                <QuoteRevisions /> 
            }

            {tab === 2 &&
                <QuoteLogs /> 
            }

            <CreateJobDialog /> 
        </div>
    )
}

const QuoteLogs = () => { 
    const quote = useRecoilValue(selectedQuoteAtom); 
    return (
        <PaperBox>
            <ObjectTodos
                assignedTo={0}
                objectType={ObjectTypeQuote}
                objectID={quote.QuoteID}
                showCreate={true}
                isPlainLog
            />
        </PaperBox>
    )
}

const QuoteTabs = styled.div`
    display: block; 
    width: 100%; 
    height: 48px; 
    border-bottom: solid 1px #616161; 
    margin-top: 10px; 
    margin-bottom: 10px; 
    padding-left: 20px; 
`; 

const QuoteTab = (props: { value: number, children: any, onClick: () => void , tab: number }) => {
    if(props.value === props.tab ) {
        return <QuoteTabDivSelected onClick={props.onClick}>
            {props.children}
        </QuoteTabDivSelected>
    }

    return <QuoteTabDiv onClick={props.onClick}>
        {props.children}
    </QuoteTabDiv>
}


const QuoteTabDiv = styled.div`
    display: inline-block; 
    height: 47px; 
    line-height: 48px; 
    vertical-align: middle; 
    padding-left: 20px; 
    padding-right: 20px; 
    background-color: #ccc; 
    border-top-left-radius: 4px; 
    border-top-right-radius: 4px; 
    margin-left: 5px; 
    margin-right: 5px; 
    cursor: pointer; 
    margin-bottom: 1px; 
    font-weight: 600; 
`

const QuoteTabDivSelected = styled(QuoteTabDiv)`
    background-color: #616161; 
    color: #fff; 
    height: 48px; 
    margin-bottom: 0px; 
`


const QuoteOptions = () => {

    const history = useHistory(); 
    const quote = useRecoilValue(selectedQuoteAtom); 
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | undefined>(undefined); 
    const [isDeletingQuote, setIsDeletingQuote] = useState(false); 
    const [isDeletingJob, setIsDeletingJob] = useState(false); 
    const [deleteJob] = useDeleteJob(); 

    const onMenuClick = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuAnchorEl(e.currentTarget); 
    }

    const doDeleteQuote = () => {
        deleteQuote(quote.QuoteID)
            .then(() => {
                history.push(`/quotes`);
                setIsDeletingQuote(false); 
                setMenuAnchorEl(undefined)
            });
    }

    const doDeleteJob = () => {
        deleteJob({ jobID: quote.JobID, quoteID: quote.QuoteID })
            .then(() => {
                setIsDeletingJob(false); 
                setMenuAnchorEl(undefined)
            });
    }


    return (
        <>
            {isDeletingQuote && 
                <AlertDialog 
                    open={isDeletingQuote}
                    title="Delete this quote?" 
                    description={`Are you sure you want to permanently delete quote ${quote.QuoteNumber}?`}
                    handleClose={() => setIsDeletingQuote(false)}
                    yes={doDeleteQuote}
                    yesText={"Yes, delete"}
                    noText={"No"}
                    no={() => setIsDeletingQuote(false)}
                />
            }

            {isDeletingJob && 
                <AlertDialog 
                    open={isDeletingJob}
                    title="Delete Job?" 
                    description={`Are you sure you want to permanently delete the job ${quote.QuoteNumber} attached to this quote?`}
                    handleClose={() => setIsDeletingJob(false)}
                    yes={doDeleteJob}
                    yesText={"Yes, delete"}
                    noText={"No"}
                    no={() => setIsDeletingJob(false)}
                />
            }

            <IconButton onClick={onMenuClick}>
                <MoreVert /> 
            </IconButton>

            <Menu 
                anchorEl={menuAnchorEl} 
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(undefined)}
            >
                <Perm perm={Quotes_DeleteQuotePermission}>
                    <MenuItem onClick={() => setIsDeletingQuote(true)} disabled={quote.JobID > 0}>
                        <Delete />
                        Delete Quote
                    </MenuItem>
                </Perm>

                <Perm perm={Jobs_DeleteJobPermission} cb={() => quote.JobID > 0}>
                    <MenuItem onClick={() => setIsDeletingJob(true)}>
                        <Delete />
                        Delete Job
                    </MenuItem>
                </Perm>
            </Menu>
        </>
    )

}


export default QuoteDetail; 