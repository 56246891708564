import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Add } from 'core/components/fabs';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { editingCustomerContactAtom, selectedCustomerAtom, showCreateContactModalAtom } from '../quotes/state';
import CreateContactModal from './CreateContactModal';
import EditContactModal from './EditContactModal';

const CustomerContactsList = () => {

    const customer = useRecoilValue(selectedCustomerAtom); 
    const setIsCreateModalShowing = useSetRecoilState(showCreateContactModalAtom); 
    const selectContact = useSetRecoilState(editingCustomerContactAtom); 
    
    return (
        <div style={{ height: "calc(100% - 96px)", overflow: "auto" }}>

            {customer.Contacts.length === 0 &&
                <Alert color="secondary">No Contacts</Alert>
            }

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            First Name
                        </TableCell>
                        <TableCell>
                            Last Name
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.Contacts.map((contact, key) => 
                        <TableRow key={key}>
                            <TableCell>{contact.FirstName}</TableCell>
                            <TableCell>{contact.LastName}</TableCell>
                            <TableCell>{contact.Role}</TableCell>
                            <TableCell>{contact.Email}</TableCell>
                            <TableCell>{contact.Phone}</TableCell>
                            <TableCell>
                                <IconButton size="small" onClick={() => selectContact(contact)}>
                                    <Edit /> 
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    
                </TableBody>
            </Table>


            <EditContactModal /> 

            <CreateContactModal/> 

            <Add action={() => setIsCreateModalShowing(true)} /> 
            
        </div>
    )
}

export default CustomerContactsList; 