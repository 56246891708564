/**
 *
 * UpdateSysValDTO
 */

export type UpdateSysValDTO = {

	// Month int64
	Month: number;

	// SysValType int64
	SysValType: number;

	// Val float64
	Val: number;

	// Year int64
	Year: number;

}

// newUpdateSysValDTO is a factory method for new UpdateSysValDTO objects
export const newUpdateSysValDTO = () : UpdateSysValDTO => ({
	Month: 0,
	SysValType: 0,
	Val: 0,
	Year: 0,
});

