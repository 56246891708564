/**
 *
 * Base.Accounts
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { AccountAggregate } from 'gen/aggregates/AccountAggregate';
import { AccountsAggregate } from 'gen/aggregates/AccountsAggregate';
import { CreateAccountDTO } from 'gen/dtos/CreateAccountDTO';
import { ObjectSettingDTO } from 'gen/dtos/ObjectSettingDTO';
import { UpdateAccountDTO } from 'gen/dtos/UpdateAccountDTO';
import { UpdateObjectSettingsDTO } from 'gen/dtos/UpdateObjectSettingsDTO';
import { UserCollectionAggregate } from 'gen/aggregates/UserCollectionAggregate';

// GetAllAccounts gets a collection of users by their group
// GET /accounts?page={page:[0-9]+}&limit={limit:[0-9]+}
// @permission Accounts_GetAllAccounts
export const getAllAccounts = async (page : number, limit : number) => await axios.get<AccountsAggregate>(`/accounts?page=${page}&limit=${limit}`);
export const useGetAllAccounts = (page : number, limit : number) => useQuery(["getAllAccounts", page, limit], (_ : string, page : number, limit : number) => getAllAccounts(page,limit));

// GetAccountFromID gets an account by it's ID
// GET /accounts/{accountID:[0-9]+}
// @permission Accounts_GetAccountFromID
export const getAccountFromID = async (accountID : number) => await axios.get<AccountAggregate>(`/accounts/${accountID}`);
export const useGetAccountFromID = (accountID : number) => useQuery(["getAccountFromID", accountID], (_ : string, accountID : number) => getAccountFromID(accountID));

// CreateAccount creates an account
// POST /accounts
// @permission Accounts_CreateAccount
export const createAccount = async (args : { body : CreateAccountDTO }) => await axios.post<any>(`/accounts`, args.body);
export const useCreateAccount = () => useMutation(createAccount, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllAccounts"]);
	},
	throwOnError: true, 
});
	

// UpdateAccount updates an account
// PUT /accounts/{accountID:[0-9]+}
// @permission Accounts_UpdateAccount
export const updateAccount = async (args : { accountID : number, body : UpdateAccountDTO }) => await axios.put<any>(`/accounts/${args.accountID}`, args.body);
export const useUpdateAccount = () => useMutation(updateAccount, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllAccounts"]);
		queryCache.invalidateQueries(["getAccountFromID", variables.accountID]);
	},
	throwOnError: true, 
});
	

// DeleteAccount deletes an account
// DELETE /accounts/{accountID:[0-9]+}
// @permission Accounts_DeleteAccount
export const deleteAccount = async (args : { accountID : number }) => await axios.delete<any>(`/accounts/${args.accountID}`);
export const useDeleteAccount = () => useMutation(deleteAccount, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllAccounts"]);
		queryCache.invalidateQueries(["getAccountFromID", variables.accountID]);
	},
	throwOnError: true, 
});
	

// GetAccountUsers gets a collection of users across all accounts
// GET /accounts/{accountID:[0-9]+}/users?page={page:[0-9]+}&limit={limit:[0-9]+}
// @permission Accounts_GetAccountUsers
export const getAccountUsers = async (accountID : number, page : number, limit : number) => await axios.get<UserCollectionAggregate>(`/accounts/${accountID}/users?page=${page}&limit=${limit}`);
export const useGetAccountUsers = (accountID : number, page : number, limit : number) => useQuery(["getAccountUsers", accountID, page, limit], (_ : string, accountID : number, page : number, limit : number) => getAccountUsers(accountID,page,limit));

// GetAccountSettings gets an accounts settings
// GET /accounts/{accountID:[0-9]+}/settings
// @permission Accounts_GetAccountSettings
export const getAccountSettings = async (accountID : number) => await axios.get<ObjectSettingDTO[]>(`/accounts/${accountID}/settings`);
export const useGetAccountSettings = (accountID : number) => useQuery(["getAccountSettings", accountID], (_ : string, accountID : number) => getAccountSettings(accountID));

// UpdateAccountSettings updates an accounts settings
// PUT /accounts/{accountID:[0-9]+}/settings
// @permission Accounts_UpdateAccountSettings
export const updateAccountSettings = async (args : { accountID : number, body : UpdateObjectSettingsDTO }) => await axios.put<any>(`/accounts/${args.accountID}/settings`, args.body);
export const useUpdateAccountSettings = () => useMutation(updateAccountSettings, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllAccounts"]);
		queryCache.invalidateQueries(["getAccountFromID", variables.accountID]);
	},
	throwOnError: true, 
});
	
