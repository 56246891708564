export default (e) : string => {
    if(!e) {
        return ''; 
    }
    if(!e.response) {
        return "A network error has occurred."
    } else if(e.response.status === 403) {
        return "You do not have permission to perform this action."; 
    } else if(e.response.status === 405) {
        return "405 - Method Not Allowed"; 
    } else if(e.response.status === 401) {
        return "You do not have permission to perform this action"
    } else {
        console.log('hello?', e.response); 
        return e.response.data.detail; 
    }
}
