import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, IconButton, Input, Menu, MenuItem } from '@material-ui/core';
import { Add, Cancel, Delete, MoreVert, Save, Visibility } from '@material-ui/icons';
import clsx from 'clsx';
import { exportList, importList, useCreateList, useCreateListItem, useDeleteList, useDeleteListItem, useGetListFromID, useGetLists, useResetCacheForList, useUpdateList, useUpdateListItem } from 'gen/routes/Lists';
import { Container } from 'core/components/Container';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { ListAggregate } from 'core/definitions/aggregates/ListAggregate';
import { ListItemAggregate } from 'core/definitions/aggregates/ListItemAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Lists_ExportListPermission, Lists_ImportListPermission } from 'gen/constants/permissions';
import { UpdateListItemDTO } from 'gen/dtos/UpdateListItemDTO';
import { ListItem } from 'gen/models/ListItem';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import AdminActions from '../admin/common/AdminActions';
import AdminHeader from '../admin/common/AdminHeader';
import { ItemAlias, ItemAliasLabel, ItemHandle, ItemHoverContainer, ItemTitle, ItemWrapper, ListItemPlaceholderEl, ListItemsWrapper, ListMenuItem, ListMenuItemCounter, ListMenuItemTitle, ListTitle, ListTitleLabel, useGridStyles, useStyles } from './components';
import { deleteListItemIDAtom, listItemIDsAtom, listItemParentTopAtom, listItemsCurrentHoveringItemSelector, listItemWithID, movingListItemDataAtom, movingListItemPositionAtom, selectedListAtom, selectedListIDAtom, selectedListItemAtom, sortListByAtom, updateListItemOrdinalAtom, useInsertItems } from './state';
import download from 'downloadjs';


const newUpdateListItemDTOFromModel = (model: ListItem) : UpdateListItemDTO & { ListID: number; ListItemID: number } => ({
    ListID: model.ListID, 
    ListItemID: model.ListItemID, 
    Name: model.Name, 
    Ordinal: model.Ordinal, 
    Alias: model.Alias, 
    ParentListItemID: model.ParentListItemID, 
    IsActive: model.IsActive, 
})

export default () => {

    const classes = useGridStyles(); 
    const { status : listsStatus, data : listsData, error : listsError } = useGetLists(0, "Title", "ASC"); 
    const [currentListID, setCurrentListID] = useRecoilState(selectedListIDAtom); 
    const [createList, { status: createStatus }] = useCreateList(); 
    const [newListTitle, setNewListTitle] = useState(''); 

    const [importShowing, setImportShowing] = useState(false); 
    const [selectedUploadFile, setSelectedUploadFile] = useState<File | undefined>(undefined); 

    

    useEffect(() => {
        if(listsStatus === "success" && listsData && listsData?.data.Count > 0 && currentListID === 0) {
            setCurrentListID(listsData?.data.Data[0].ListID); 
        }
    })

    if(listsStatus === "error") {
        return <Alert color="danger">{handleNetworkError(listsError)}</Alert>
    }

    if(listsStatus === "loading" || !listsData) {
        return <CircularProgress /> 
    }

    const selectList = (listID: number) => () => setCurrentListID(listID); 

    const lists = listsData.data; 

    const createNewList = () => {
        createList({ body: {  Title: newListTitle } }).then(() => setNewListTitle("")); 
    }
    
    const doImportList = () => setImportShowing(true); 
    const onImportFileChange = (e) => { 
        const el = (e.target as HTMLInputElement); 
        if(el && el.files) { 
            setSelectedUploadFile(el.files[0]);
        }
    } 
    const onImportSubmit = () => { 
        const formData = new FormData(); 

        if(!selectedUploadFile) { 
            return 
        } 
        formData.append(
            "myFile", 
            selectedUploadFile, 
            selectedUploadFile.name, 
        )

        importList({ body: formData })
            .then(() => { 
                alert("List imported...") 
                setImportShowing(false); 
            })
    }

    return <div style={{ height: '100%', overflowY: 'hidden' }}>

        <NavBarSecondary
                right={<>
                    <AdminActions actions={[
                        { title: 'Import', fn: doImportList, perm: Lists_ImportListPermission },
                    ]} /> 
                </>}
            >
            <AdminHeader title="Lists" />    
        </NavBarSecondary>

        <Dialog open={importShowing} onClose={() => setImportShowing(false)}>
            <DialogTitle>Import Policies</DialogTitle>
            <DialogContent>
                <input type="file" onChange={onImportFileChange} /> 
                <Button variant="contained" onClick={onImportSubmit}>Upload</Button>
            </DialogContent>
        </Dialog>

        <div className={classes.grid}>
            <div className={classes.gridItemLeft} style={{ position: 'relative' }}>
                {lists.Data.map((list, key) => 
                    <ListMenuItem key={key} className={clsx(currentListID === list.ListID && classes.listSelected)} onClick={selectList(list.ListID)}>
                        <ListMenuItemTitle>
                            {list.Title}
                            {list.ListType > 0 && <span style={{ verticalAlign: 'top', fontSize: '1em' }}>*</span>}
                        </ListMenuItemTitle>
                        <ListMenuItemCounter>
                            {list.ItemCount}
                        </ListMenuItemCounter>
                    </ListMenuItem>
                )}
                <Container>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ flexGrow: 1 }}>
                            <Input 
                                fullWidth
                                value={newListTitle} 
                                onChange={(e) => setNewListTitle(e.target.value)} disabled={createStatus === "loading"}
                            /> 
                        </div>
                        <div style={{ width:70, textAlign: 'right'}}>
                            <Fab 
                                onClick={createNewList} 
                                disabled={createStatus === "loading"}
                                size="small"
                            >
                                {createStatus === "loading" && 
                                    <CircularProgress /> 
                                }
                                {createStatus !== "loading" && 
                                    <Add /> 
                                }
                            </Fab>
                        </div>
                    </div>
                </Container>

            </div>
            <div className={classes.gridItemRight}>
                {currentListID > 0 && 
                    <ItemListWrapper /> 
                }
            </div>
        </div>
    

        
    </div>
}

const ItemListWrapper = () => {

    const currentListID = useRecoilValue(selectedListIDAtom); 
    const { status, data, error } = useGetListFromID(currentListID); 
    const setCurrentList = useSetRecoilState(selectedListAtom); 

    const insertItems = useInsertItems(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(data) {
            setCurrentList(data.data as ListAggregate); 
            insertItems(data.data.Items as ListItemAggregate[]);
        }
    }, [data, setCurrentList]); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return <ItemList /> 
}

const ListItemPlaceholder = (props: { show: boolean }) => {
    const classes = useStyles(); 
    const { show } = props; 
    const render = useState(false); 
    useEffect(() => {
        if(show) { 
            setTimeout(() => {
                render[1](true); 
            }, 1000);
        } else {
            render[1](false); 
        }
    }, [show])

    return <ListItemPlaceholderEl className={clsx(classes.render, show && classes.show)} /> 
}

const LastPlaceholder = () => {
    const currentHoveringItemIdx = useRecoilValue(listItemsCurrentHoveringItemSelector); 
    const itemIDs = useRecoilValue(listItemIDsAtom); 
    return <ListItemPlaceholder show={currentHoveringItemIdx >= itemIDs.length} /> 
}

const Reporter = () => {
    
    // Position of the moving list item 
    const setMovingListItemPosition = useSetRecoilState(movingListItemPositionAtom);
    const [hovering, setHovering] = useState(-1)
    const [movingListItemData, setMovingListItemData] = useRecoilState(movingListItemDataAtom); 
    const currentHoveringItemIdx = useRecoilValue(listItemsCurrentHoveringItemSelector); 
    const [list, setList] = useRecoilState(selectedListAtom); 
    const [itemIDs] = useRecoilState(listItemIDsAtom); 
    // console.log('itemIDs', itemIDs); 
    const movingItemID = itemIDs[movingListItemData.movingItemIndex]; 
    // console.log('MovingItemID', movingItemID); 
    // console.log('MovingItemIndex', movingListItemData.movingItemIndex); 
    const [movingItem, setMovingItem] = useRecoilState(listItemWithID(movingItemID))
    const [newItem, setNewItem] = useRecoilState(listItemWithID(itemIDs[currentHoveringItemIdx]))  
    const [oldItem, setOldItem] = useRecoilState(listItemWithID(itemIDs[hovering]))  
    const [updateItem, setUpdateItem] = useRecoilState(updateListItemOrdinalAtom);
    const [updateListItem] = useUpdateListItem(); 
    const insertItems = useInsertItems(); 

    if(currentHoveringItemIdx !== hovering) {
        console.log('setting item hovering!'); 
        setNewItem({ ...newItem, _hovering: movingListItemData.movingItemIndex}); 
        setOldItem({ ...oldItem, _hovering: -1 }); 
        setHovering(currentHoveringItemIdx); 
    }

    useEffect(() => {
        // return null; 
        if(updateItem) { 

            console.log('updating item #', movingItem.ListItemID, ': ', movingItem.Ordinal, ' ==> ', newItem.Ordinal); 
            // const hoveringItemIdx = currentHoveringItemIdx; 
            // const movingItemIdx = movingListItemData.movingItemIndex; 

            // console.log(`Moving from ${movingItemIdx} to ${hoveringItemIdx}`);
            
            setMovingListItemData({ 
                hoveringItemIndex: -1, 
                movingItemIndex: -1, 
            }); 

            // Globally tell the application what the current position of the item is
            setMovingListItemPosition(-1) 
            setUpdateItem(false); 

            // const movingItemOrdinal = movingItem.Ordinal; 
            const newItemOrdinal = newItem.Ordinal; 

            // console.log('movingItem', movingItem); 
            const updatedItem = { ...movingItem, Ordinal: newItemOrdinal, _hovering: -1 }; 
            // console.log('updatedItem', updatedItem); 
            setMovingItem(updatedItem);
            
            updateListItem({ listID: updatedItem.ListID, itemID: updatedItem.ListItemID, body: newUpdateListItemDTOFromModel(updatedItem) })

            const newList = setItemOrdinals(updatedItem.ListItemID, newItemOrdinal, list); 
            setList(newList); 
            console.log('Inserting items', newList.Items); 
            insertItems(newList.Items as ListItemAggregate[])



            // setNewItem({ ...movingItem, Ordinal: newItemOrdinal, _hovering: -1 }); 

            // const arr = itemIDs.slice(); 
            // arr[hoveringItemIdx] = itemIDs[movingItemIdx]; 
            // arr[movingItemIdx] = itemIDs[hoveringItemIdx]; 
            // setItemIDs(itemIDs); 
            // console.log('itemIDs', itemIDs); 


            // const hoveringItemID = itemIDs[movingListItemData.hoveringItemIndex]; 
            // const movingItem = itemIDs[movingListItemData.movingItemIndex]; 



            // arr[movingListItemData.hoveringItemIndex] = { ...movingItem, Ordinal: hoveringItem.Ordinal };
            // arr[movingListItemData.movingItemIndex] = { ...hoveringItem, Ordinal: movingItem.Ordinal }; 
            // console.log(`listItemStoppedMoving!!! Swapping ${movingListItemData.hoveringItemIndex} for ${movingListItemData.movingItemIndex}`);
            // setList({ ...list, Items: arr });
            // updateListItem(arr[movingListItemData.hoveringItemIndex]); 
        }
    }, [updateItem])

    






    // // Set the CSS of the item 
    // const domNode = itemHoverContainerRef.current;
    // if(domNode) { 
    //     domNode.style.top = `0px`; 
    // }
    // return null; 
    // window.removeEventListener('mousemove', onMouseMove, false); 
    // window.removeEventListener('mouseup', onMouseUp, false); 
    // setItemMoving({ ...itemMoving, isMoving: false })
    
    return null; 
    // return <div>
    //     Moving from {movingListItemData.movingItemIndex} - {currentHoveringItemIdx}
    //     {updateItem && <strong>Updating item {movingListItemData.movingItemIndex}</strong>}
    // </div>
}


const ItemList = () => {

    const [itemAliasShowing, setItemAliasShowing] = useRecoilState(itemAliasShowingAtom);
    const itemIDs = useRecoilValue(listItemIDsAtom);

    // console.log('itemList::render()'); 
    const [list, setList] = useRecoilState(selectedListAtom); 
    const [sortBy, setSortBy] = useRecoilState(sortListByAtom); 
    const [isEditingListTitle, setIsEditingListTitle] = useState(false); 
    const [listTitle, setListTitle] = useState(list.Title); 
    const [createListItem, { status: createItemStatus }] = useCreateListItem(); 
    const [updateList, { status: updateListStatus }] = useUpdateList(); 
    const [deleteList] = useDeleteList(); 
    const [newItemName, setNewItemName] = useState(''); 
    const [deletingList, setDeletingList] = useState(false); 
    const setCurrentListID = useSetRecoilState(selectedListIDAtom); 
    const createListItemInputRef = useRef<HTMLInputElement>(null); 
    const listRef = useRef<HTMLDivElement>(null); 
    const setParentTop = useSetRecoilState(listItemParentTopAtom); 
    const [refreshCache] = useResetCacheForList(); 
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | undefined>(undefined); 

    const onMenuClick = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuAnchorEl(e.currentTarget); 
    }

    const onNewItemInputChange = (e) => { 
        setNewItemName(e.target.value as string)
    } 

    const onNewItemInputKeyDown = (e) => {
        if(e.key === 'Enter') {
            addListItem(); 
        }
    }

    const toggleAlias = () => {
        setItemAliasShowing(!itemAliasShowing); 
        setMenuAnchorEl(undefined); 
    }

    // const movingListItemData = useRecoilValue(movingListItemDataAtom); 
    // const resetMovingListItemData = useResetRecoilState(movingListItemDataAtom); 
    
    // const trueParentTop = listRect?.top as number; 

    // useEffect(() => {
    //     setParentTop(trueParentTop)
    // }, [trueParentTop])

    // console.log('list', rect?.top, rect?.bottom)

    // const itemListRef = useRef(null as any)
    // const setListPosition = useSetRecoilState(listPosition); 
    // const listCoords = useRecoilValue(listPosition); 

    useEffect(() => {
        const listRect = listRef.current?.getBoundingClientRect();
        setParentTop(listRect?.top as number); 
    }, [listRef, setParentTop]); 

    const addListItem = () => {

        createListItem({
            listID: list.ListID,
            body: { Name: newItemName }, 
        }).then(() => {
            
            setNewItemName(''); 
            
            if(null !== createListItemInputRef.current) {
                createListItemInputRef.current.focus(); 
            }
        });

        setNewItemName(''); 
    }

    // useEffect(() => {

        // if(isListItemMoving === false && movingListItemData.hoveringItemIndex > -1 && movingListItemData.movingItemIndex > -1) {

            // if(movingListItemData.hoveringItemIndex !== movingListItemData.movingItemIndex) {
            //     const arr = list.Items.slice(); 
            //     const hoveringItem = list.Items[movingListItemData.hoveringItemIndex]; 
            //     const movingItem = list.Items[movingListItemData.movingItemIndex]; 
            //     arr[movingListItemData.hoveringItemIndex] = { ...movingItem, Ordinal: hoveringItem.Ordinal };
            //     arr[movingListItemData.movingItemIndex] = { ...hoveringItem, Ordinal: movingItem.Ordinal }; 
            //     console.log(`listItemStoppedMoving!!! Swapping ${movingListItemData.hoveringItemIndex} for ${movingListItemData.movingItemIndex}`);
            //     setList({ ...list, Items: arr });
            //     updateListItem(arr[movingListItemData.hoveringItemIndex]); 
            // }


            // setIsListItemMoving(false); 
            // resetMovingListItemData(); 

        // }
    // }, [isListItemMoving, movingListItemData, list, resetMovingListItemData, setIsEditingListTitle, setIsListItemMoving, setList, updateListItem]);

    const deleteListStart = () => {
        setDeletingList(true); 
    }

    const deleteListConfirm = () => {
        deleteList({ listID: list.ListID }).then(() => setCurrentListID(0)); 
    }

    const onEditTitleStart = () => {
        setListTitle(list.Title); 
        setIsEditingListTitle(true); 
    }
    
    const onEditTitleSave = () => {
        updateList({ listID: list.ListID, body: {  ...list, Title: listTitle } })
            .then(() => {
                setList({ ...list, Title: listTitle }); 
                setIsEditingListTitle(false); 
            });
    }

    const onEditTitleCancel = () => {
        setListTitle(list.Title); 
        setIsEditingListTitle(false); 
    }

    // useEffect(() => {
    //     const rect = listRef.current?.getBoundingClientRect();
    //     // console.log('list', rect?.top, rect?.bottom)
    // }, [])

    const resetCache = () => {
        refreshCache({ listID: list.ListID }); 
    }
    
    const doExportList = () => { 
        exportList(list.ListID)
            .then(resp => { 
                download(resp.data, `list-${list.Title.toLocaleLowerCase().replace(/\s/g, "-")}.json`, resp.headers["content-type"]); 
            }); 
    }


    // console.log('itemIDs', itemIDs); 

    // if(itemIDs.length === 0) {
    //     return null; 
    // }

    // console.log('itemIDs', itemIDs); 

    const sortedItemIDs = itemIDs.length === 0 ? [] as number[] : (
        sortBy === "title" ? 
            [...list.Items].sort((a, b) => a.Name.localeCompare(b.Name)).map(a => a.ListItemID)
            : [...list.Items].sort((a, b) => a.Ordinal - b.Ordinal).map(a => a.ListItemID)
    ); 
    console.log('sortedItemIDs', sortedItemIDs); 


    return (

        <>

            <Reporter /> 
            {/* <Messenger />  */}

            <Dialog open={deletingList} onClose={() => setDeletingList(false)} onEscapeKeyDown={() => setDeletingList(false)}>
                <DialogTitle>Delete list {list.Title}?</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this list {list.Title}?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={deleteListConfirm}>Yes, Delete</Button>
                    <Button variant="contained" onClick={() => setDeletingList(false)}>Cancel</Button>
                </DialogActions>

            </Dialog>

            <Container>
                

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListTitle>
                        <div style={{ flexGrow: 1}}>
                            {!isEditingListTitle && 
                                <ListTitleLabel onClick={onEditTitleStart}>{list.Title}</ListTitleLabel>
                            }
                            {isEditingListTitle &&
                                <div>
                                    <Input value={listTitle} onChange={(e) => setListTitle(e.target.value)} disabled={updateListStatus === "loading"} />
                                    <IconButton onClick={onEditTitleSave} disabled={updateListStatus === "loading"}>
                                        <Save /> 
                                    </IconButton> 
                                    <IconButton onClick={onEditTitleCancel} disabled={updateListStatus === "loading"}>
                                        <Cancel /> 
                                    </IconButton>
                                </div>
                            }
                        </div>
                        <div>
                            <IconButton onClick={onMenuClick}>
                                <MoreVert /> 
                            </IconButton>

                            <Menu 
                                anchorEl={menuAnchorEl} 
                                open={Boolean(menuAnchorEl)}
                                onClose={() => setMenuAnchorEl(undefined)}
                            >
                                <MenuItem disabled={true}>
                                    List # {list.ListID}
                                </MenuItem>
                                <MenuItem disabled={true}>
                                    {list.StringID}
                                </MenuItem>
                                <Divider /> 
                                <MenuItem onClick={toggleAlias}>{itemAliasShowing ? 'Hide' : 'Show'} Item Aliases</MenuItem>
                                <MenuItem onClick={() => setSortBy("ordinal")}>Sort By Ordinal</MenuItem>
                                <MenuItem onClick={() => setSortBy("title")}>Sort By Name</MenuItem>
                                {/* title: 'Export', fn: doExportList, perm: Lists_ExportListPermission */}
                                <MenuItem onClick={resetCache}>Clear Cache</MenuItem>
                                <Perm perm={Lists_ExportListPermission}>
                                    <MenuItem onClick={() => doExportList()}>Export List...</MenuItem>
                                </Perm>
                                <Divider /> 
                                <MenuItem onClick={deleteListStart} style={{ color: "#f00" }}>Delete List</MenuItem>
                            </Menu>
                        </div>
                    </ListTitle>
                    
                </div>
            </Container>


            <ListItemsWrapper ref={listRef}>
                {sortedItemIDs.map((id, index) => <ListItemEl key={index} idx={index} id={id} itemCount={list.Items.length} />)}
                <LastPlaceholder /> 
            </ListItemsWrapper>

            <Container>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Input 
                            fullWidth
                            value={newItemName} 
                            onChange={onNewItemInputChange}
                            onKeyDown={onNewItemInputKeyDown}
                            inputRef={createListItemInputRef}
                            /> 
                    </div>

                    <div style={{ width:70, textAlign: 'right'}}>
                        <Fab
                            size="small"
                            onClick={addListItem} 
                            disabled={createItemStatus === "loading"}>
                                {createItemStatus !== "loading" && 
                                    <Add /> 
                                }
                                {createItemStatus === "loading" && 
                                    <CircularProgress size={20} /> 
                                }
                        </Fab>
                    </div>
                </div>
            </Container>


        </>
    )
}

const setItemOrdinals = (listItemID : number, newOrdinal : number, list : ListAggregate) : ListAggregate => {

    // return { 
    //     ...list, 
    //     Items: [
    //         ...list.Items.map(x => ({ 
    //             ...x, Ordinal: x.Ordinal + 1 
    //         }))
    //     ]
    // }

    const movingItemIdx = list.Items.findIndex((v) => v.ListItemID === listItemID);
    const movingItem = list.Items[movingItemIdx]; 

	// No Change
    if(movingItem.Ordinal === newOrdinal) {
        return list; 
    }

	const isMovingDown = movingItem.Ordinal < newOrdinal;

    let items = [...list.Items]; 

	// The item moved down, so we need to update all of the items between the old position (top) and new position (bottom)
	if(isMovingDown) {

        for(let k = 0; k < items.length; k++) {

			// Skip if the the current item is less than the old position or greater than the new position
			if(
                items[k].Ordinal < movingItem.Ordinal 
                || 
                items[k].Ordinal > newOrdinal 
                || 
                items[k].ListItemID === movingItem.ListItemID
            ) {
				continue
			}

			// Moving each item down one to fill the empty spot
            items = Object.assign([...list.Items], {[k]: { ...items[k], Ordinal: items[k].Ordinal - 1 }})
		}

	} else {

		// The item moved up, so we need to update all of the items between the old position (bottom) and new position (top)
        for(let k = 0; k < items.length; k++) {

			// Skip if the current item is greater than the old position or less than the new position
			if(
                items[k].Ordinal > movingItem.Ordinal 
                || 
                items[k].Ordinal < newOrdinal 
                || 
                items[k].ListItemID === movingItem.ListItemID 
            ) {
				continue
			}
            
			// Moving each item up one to fill the empty spot
            items = Object.assign([...list.Items], {[k]: { ...items[k], Ordinal: items[k].Ordinal + 1 }})
		}

	}

    items = Object.assign([...list.Items], {[movingItemIdx]: { ...movingItem, Ordinal: newOrdinal }})

    console.log('##### New Items Built', items); 

    return { ...list, Items: [...items]}; 
}

const itemAliasShowingAtom = atom<boolean>({
    key: 'itemAliasShowingAtom', 
    default: false, 
});

const ListItemEl = (props: { id: number, idx: number, itemCount: number }) => {

    const itemAliasShowing = useRecoilValue(itemAliasShowingAtom); 
    const [item, setItem] = useRecoilState(listItemWithID(props.id)); 
    const setUpdateItem = useSetRecoilState(updateListItemOrdinalAtom);

    // console.log(`ListItemEl::Render(${props.id})`); 

    // Ref to the container 
    const itemHoverContainerRef = useRef<HTMLDivElement>(null); 
    const setMovingListItemPosition = useSetRecoilState(movingListItemPositionAtom); 

    // console.log('ListItem #', props.idx, 'ParentTop: ', props.parentTop); 

    const [localItemAliasShowing, setLocalItemAliasShowing] = useState(false); 
    const classes = useStyles();
    const [itemToDelete, setItemToDelete] = useState(0); 
    const list = useRecoilValue(selectedListAtom); 
    const [updateListItem, { status: updateItemStatus }] = useUpdateListItem(); 
    const [updateList] = useUpdateList(); 
    const parentTop = useRecoilValue(listItemParentTopAtom); 
    // const [message, setMessage]= useRecoilState(messageState); 
    const setDeleteListItemID = useSetRecoilState(deleteListItemIDAtom);
    const [selectedListItem, setSelectedListItem] = useRecoilState(selectedListItemAtom); 
    const resetSelectedListItem = useResetRecoilState(selectedListItemAtom); 
    const setMovingListItemData = useSetRecoilState(movingListItemDataAtom);
    const itemHandleRef = useRef<HTMLDivElement>(null); 
    const inputRef = useRef<HTMLInputElement>(null); 
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | undefined>(undefined); 
    //const currentHoveringIdx = useRecoilValue(listItemsCurrentHoveringItemSelector); 

    const onMenuClick = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuAnchorEl(e.currentTarget); 
    }

    // Indicates that the listItem is moving 
    const [itemMoving, setItemMoving] = useState({ isMoving: false, origTop: 0, mouseY: 0, }); 
    
    // Set the initial positioning    
    useEffect(() => {
        const rect = itemHandleRef.current?.getBoundingClientRect();
        const rectTop = (rect?.top as number)
        // console.log('rectTop', rectTop, 'parentTop', parentTop)
        const origTop = rectTop - parentTop; 

        setItemMoving({ ...itemMoving, origTop })

        // console.log('origTop', origTop); 
    }, [])

    

    // Get the initial position for this item 



    const [deleteListItem, { status: deleteItemStatus }] = useDeleteListItem(); 


    // const moveItem = (item: ListItemDTO, newOrdinal: number) => {
    //     const updatedListItem = Object.assign({}, {...item, Ordinal: newOrdinal }); 
    //     updateListItem(updatedListItem); 
    // }

    const deleteItemStart = (item: ListItem) => () => {
        setMenuAnchorEl(undefined); 
        setItemToDelete(item.ListItemID); 
    }
    const deleteItemConfirm = (item: ListItem) => () => {
        deleteListItem({ listID: item.ListID, itemID: item.ListItemID}); 
        setItemToDelete(0);
    }

    const deleteItemCancel = (item: ListItem) => () => {
        setItemToDelete(0); 
    }

    // Item was successfully deleted 
    useEffect(() => {
        if(deleteItemStatus === "success") {
            setDeleteListItemID(itemToDelete); 
            setItemToDelete(0); 
        }
    }, [deleteItemStatus, itemToDelete, setDeleteListItemID])

    // // Item was successfully updated 
    // useEffect(() => {
    //     // context.lists.Data = (Object.assign([...context.lists.Data], 
    //     // {[deleteListItemIdx]: {...context.lists.Data[deleteListItemIdx], ItemCount: context.lists.Data[deleteListItemIdx].ItemCount - 1}}))
    //     if(updateItemStatus === "success" && selectedListItem.ListItemID > 0) {
    //     //     setList({
    //     //         ...list, 
    //     //         Items: Object.assign(
    //     //             [...list.Items], 
    //     //             {[list.Items.findIndex(x => x.ListItemID === updateItemData?.data.ListItemID)]: selectedListItem}, 
    //     //         )
    //     //     });
    //         resetSelectedListItem(); 
    //         console.log('resetSelectedListItem()'); 
    //     }

        
    // }, [updateItemStatus, selectedListItem, list, resetSelectedListItem, setList, updateItemData ])

    const onItemClick = (item: ListItem) => () => {
        console.log('onItemClick', item.ListItemID)
        setSelectedListItem(item);
        
        if(inputRef.current) {
            console.log('focus ref'); 
            inputRef.current.focus(); 
        } 
    }

    const saveItem = () => {
        updateListItem({ listID: item.ListID, itemID: item.ListItemID, body: selectedListItem })
            .then(() => {
                resetSelectedListItem(); 
            });
    }

    const [activeChanged, setActiveChanged] = useState(0); 

    useEffect(() => { 
        if (activeChanged > 0) { 
            saveItem(); 
        }
        console.log('item.IsActive changed!', activeChanged); 
        setActiveChanged(activeChanged + 1)
    }, [item.IsActive])


    const clearSelectedListItem = () => {
        resetSelectedListItem(); 
    }

    const handleDefaultChecked = (event) => {
        
        // Close the menu 
        setMenuAnchorEl(undefined); 

        if(list.DefaultListItemID !== item.ListItemID) {
            updateList({
                listID: list.ListID, 
                body: { 
                    ...list, 
                    DefaultListItemID: item.ListItemID, 
                }
            });
        }
    }

    let origMouseScreenY = 0; 
    let startingOffset = 0; 
    const itemHeight = 58; 
    
    const onMouseDown = (e : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        
        e.preventDefault(); 
        e.stopPropagation(); 
        
        setMovingListItemData({ 
            hoveringItemIndex: props.idx, 
            movingItemIndex: props.idx, 
        }); 

        setMovingListItemPosition(0); 

        // const rect = itemHandleRef.current?.getBoundingClientRect(); 
        
        setItemMoving({
            origTop: itemMoving.origTop, 
            isMoving: true, 
            mouseY: e.screenY, 
        }); 

        origMouseScreenY = e.screenY
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('mouseup', onMouseUp, false); 

        startingOffset = itemHeight * props.idx; 
        const domNode = itemHoverContainerRef.current;        
        if(domNode) { 
            domNode.style.top = `${startingOffset}px`; 
        }

    }

    const onMouseMove = (e : MouseEvent) => { 
        
        // ScreenY => Vertical coordinate (offset) of the mouse pointer in global screen coordinates
        const mouseMoveOffset = (e.screenY - origMouseScreenY); // + itemMoving.origTop
        // Globally tell the application what the current position of the item is
        setMovingListItemPosition(mouseMoveOffset);

        const domNode = itemHoverContainerRef.current;        
        if(domNode) { 
            domNode.style.top = `${startingOffset + mouseMoveOffset}px`; 
        }
    }

    const onMouseUp = (e : MouseEvent) => {
        setUpdateItem(true); 
        window.removeEventListener('mousemove', onMouseMove, false); 
        window.removeEventListener('mouseup', onMouseUp, false); 
        setItemMoving({
            mouseY: -1, 
            origTop: 0, 
            isMoving: false, 
        })        
        const domNode = itemHoverContainerRef.current;        
        if(domNode) { 
            domNode.style.top = `0px`; 
        }
    }

    const onInputKeyDown = (e) => {

        if(e.key === 'Enter') {
            saveItem();
        }

        if(e.key === 'Escape') {
            clearSelectedListItem(); 
        }

    }

    // const showPlaceholder = movingListItemData.hoveringItemIndex === props.idx && movingListItemData.movingItemIndex !== props.idx; 
    // const showTopPlaceholder = showPlaceholder && props.idx < movingListItemData.movingItemIndex; 
    // const showBottomPlaceholder = showPlaceholder && props.idx > movingListItemData.movingItemIndex; 

    return (
        <>

        <ListItemPlaceholder show={item._hovering >= props.idx} />

        <ItemHoverContainer 
            className={clsx(
                selectedListItem.ListItemID === item.ListItemID && classes.itemSelected, 
                itemMoving.isMoving && classes.listItemMoving,
                item._hovering > -1 && classes.listItemHovering, 
            )} 
            ref={itemHoverContainerRef}
        >
            <ItemWrapper className={item.IsActive === 0 ? classes.itemInactive : classes.itemActive}>
                    
                <ItemHandle 
                    ref={itemHandleRef} 
                    onMouseDown={onMouseDown} 
                >
                    <span style={{ display: 'block', height: '100%', lineHeight: '48px', verticalAlign: 'middle', fontSize: '9px', textAlign: 'center', color: '#bbb' }}>
                        {`${item.Ordinal}`}
                    </span>
                    &nbsp;
                </ItemHandle>
                

                <ItemTitle>
                    {item.ListItemID !== selectedListItem.ListItemID && 
                        <div onClick={onItemClick(item)} className={classes.itemTitleLabel}> 
                            {item.Name}

                            {list.DefaultListItemID === item.ListItemID && <span style={{ color: 'red', verticalAlign: 'top', fontSize: '1em' }}>*</span>}

                            {/* <small> # {item.ListItemID}</small> */}
                        </div>
                    }

                    {item.ListItemID === selectedListItem.ListItemID && 
                        <div>
                            
                            <Input 
                                value={item.Name} 
                                onChange={(e) => setItem({ ...item, Name: e.target.value })} 
                                inputRef={inputRef}
                                disabled={updateItemStatus === "loading"}
                                onKeyDown={onInputKeyDown}
                                autoFocus
                            /> 

                            &nbsp; 

                            <Input 
                                value={item.Alias} 
                                onChange={(e) => setItem({ ...item, Alias: e.target.value })} 
                                disabled={updateItemStatus === "loading"}
                                style={{ width: 500 }}
                                onKeyDown={onInputKeyDown}
                            />

                            {/* <Button onClick={saveItem} disabled={updateItemStatus === "loading"}>
                                
                                <Save />

                                {updateItemStatus === "loading" &&
                                    <CircularProgress size={20} /> 
                                }

                            </Button>

                            <Button onClick={clearSelectedListItem} disabled={updateItemStatus === "loading"}>

                                <Cancel /> 

                            </Button> */}

                        </div>
                    }
                </ItemTitle>

                <ItemAlias>
                    {item.ListItemID !== selectedListItem.ListItemID &&
                        <ItemAliasLabel>
                            {(itemAliasShowing || localItemAliasShowing) && <span style={{ color: '#090', display: 'inline-block', marginLeft: 20 }}>{item.Alias}</span>}
                        </ItemAliasLabel>
                    }
                </ItemAlias>

                <div>
                    <Checkbox 
                        onChange={(e) => {
                            setItem({ ...item, IsActive: e.target.checked ? 1 : 0 })
                        }}
                        checked={item.IsActive === 1}
                    /> 
                    &nbsp; 
                    {!itemAliasShowing && <IconButton size="small" onMouseOver={() => setLocalItemAliasShowing(true)} onMouseOut={() => setLocalItemAliasShowing(false)}>
                                <Visibility />
                            </IconButton>}
                    <IconButton onClick={onMenuClick}>
                        <MoreVert /> 
                    </IconButton>

                    <Menu 
                        anchorEl={menuAnchorEl} 
                        open={Boolean(menuAnchorEl)}
                        onClose={() => setMenuAnchorEl(undefined)}
                    >
                        <MenuItem disabled={true}>
                           Item # {item.ListItemID}
                        </MenuItem>
                        <Divider /> 
                        <MenuItem onClick={handleDefaultChecked} disabled={item.ListItemID === list.DefaultListItemID}>
                            Make Default
                        </MenuItem>

                        <MenuItem onClick={deleteItemStart(item)}>
                            <Delete />
                            Delete
                        </MenuItem>
                    </Menu>
                </div>

                
                <div className={classes.delete}>

                    {itemToDelete === item.ListItemID && 
                        <div>
                            <Button color="secondary" onClick={deleteItemConfirm(item)}>
                                Confirm Delete?
                            </Button>
                            <IconButton onClick={deleteItemCancel(item)}>
                                <Cancel /> 
                            </IconButton>
                        </div>
                    }
                </div>
                
            </ItemWrapper>

        </ItemHoverContainer>
        <ListItemPlaceholder show={(item._hovering > -1 && item._hovering < props.idx)}/>
        </>
    ); 
}



// Forward Ref: https://reactjs.org/docs/forwarding-refs.html
// const ListItem = React.forwardRef((props, ref: HTMLDivElement) => {
    
//     const { children, ...rest} = props; 

//     return (
//         <div {...rest} ref={ref}>
//             {children}
//         </div>
//     )
// }); 