import { ClickAwayListener } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonGroup } from 'reactstrap';
import styled from 'styled-components';

const titleHeight = 64; 
const titleSize = 24; 
const headerHeight = 32; 
const dayHeight = 200; // 122 
const dayWidth = 145;
const dayNumSize = 15;  
const eventTitleSize = 12; 
const maxEventsDisplayedInDay = 5; 


const DayNames = styled.div`
    display: flex; 
    flex-direction: row; 
    width: ${dayWidth * 7}px; 
    height: ${headerHeight}px; 
`; 
const DayName = styled.div`
    width: ${dayWidth}px; 
    height: ${headerHeight}px; 
    text-align: center; 
    font-size: 12px; 
    font-weight: normal; 
    box-sizing: border-box; 
    border-right: solid 1px #ddd; 
    border-bottom: solid 1px #ddd; 
    line-height: ${headerHeight}px; 
    vertical-align: middle; 
`; 

const MonthWrapper = styled.div`
    position: relative; 
    width: ${dayWidth * 7}px; 
`; 

const MonthTitleBar = styled.div`
    height: ${titleHeight}px; 
    display: flex; 
    flex-direction: row; 
    width: 100%; 
    justify-content: space-between; 
`; 

const MonthTitleLeft = styled.div`
    height: ${titleHeight}px; 
    line-height: ${titleHeight}px; 
    vertical-align: middle; 
`; 

const MonthTitleCenter = styled.div`
    font-size: ${titleSize}px; 
    height: ${titleHeight}px; 
    line-height: ${titleHeight}px; 
    vertical-align: middle; 
`; 

const MonthTitleRight = styled.div`
    height: ${titleHeight}px; 
    line-height: ${titleHeight}px; 
    vertical-align: middle; 
`; 
const Month = styled.div`

    position: relative; 
    border-top: solid 1px #ddd; 
    border-left: solid 1px #ddd; 

`; 
const Week = styled.div`

    display: flex; 
    flex-direction: row; 
    width: ${dayWidth * 7}px; 
    height: ${dayHeight}px;

`; 
const Day = styled.div`
    width: ${dayWidth}px; 
    height: ${dayHeight}px; 
    border-bottom: solid 1px #ddd; 
    border-right: solid 1px #ddd; 
    position: relative; 
    box-sizing: border-box; 
`; 

const DayNum = styled.div`

    display: flex;
    flex-direction: row-reverse; 
    padding: 4px; 
    font-size: ${dayNumSize}px; 
`; 

const DayEvent = styled.div`
    padding: 4px; 
    font-size: ${eventTitleSize}px; 
    width: calc(100% - 6px); 
    text-overflow: ellipsis; 
    border-radius: 3px; 
    background-color: rgba(55, 136, 216, 1); 
    color: #fff; 
    margin-bottom: 3px; 
    margin-left: 3px; 
    margin-right: 3px; 
`; 

const DayEventsShowMore = styled.div`
    text-align: center; 
    width: calc(100% - 6px); 
    font-size: ${eventTitleSize - 1}px; 
    padding: 4px; 
    cursor: pointer; 
    margin-left: 3px; 
    border-radius: 3px; 
    &:hover { 
        background-color: #ccc; 
    }
`; 

const DayEventsMoreToolTip = styled.div`
    position: absolute; 
    top: 0; 
    left: 0;
    min-height: 100px; 
    width: 300px; 
    border: solid 1px #909090; 
    border-radius: 3px; 
    padding: 3px; 
    background-color: #fff; 
    z-index: 10000; 
`; 

const DayEventsMoreToolTipTitle = styled.div`
    padding: 3px;
    margin-bottom: 5px; 
    font-weight: bold; 
    font-size: 13px; 
`; 

const DayEventsMoreToolTipClose = styled.div`
    position: absolute; 
    top: 0; 
    right: 0; 
    padding: 5px; 
    cursor: pointer; 
`; 

const useStyles = makeStyles((theme) => ({ 
    today: { 
        backgroundColor: 'rgba(255, 220, 40, .15)', 
    },
    active: { 
        color: '#000', 
    }, 
    inactive: { 
        color: '#999', 
    }, 
    dayEventLink: { 
        '&:hover': { 
            cursor: 'pointer', 
            backgroundColor: 'rgba(55, 136, 216, .8)', 
        }
    }
}));

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']; 
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']; 
const isSameDay = (date1: Date, date2: Date) : boolean => date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate(); 

type DayObj = { unixStart: number; unixEnd: number; day: number; week: number; month: number; year: number; isToday: boolean; isInMonth: boolean, events: CalendarEvent[] };


const buildDays = (d: Date) :  DayObj[][] => { 

    const today = new Date(); 
    const date = new Date(d.getTime()); 
    const month = d.getMonth();
    const startDOW = date.getDay(); 
    const days : DayObj[][] = []; 

    // First Week 
    let week : DayObj[] = []; 
    let dayIndex = 0; 
    date.setDate(date.getDate() - startDOW); 
    while(dayIndex < startDOW) { 

        const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

        week.push({ unixStart: startOfDay.getTime(), unixEnd: endOfDay.getTime(), day: date.getDate(), week: 1, month: date.getMonth(), year: date.getFullYear(), isToday: isSameDay(today, date), isInMonth: false, events: [] }); 
        date.setDate(date.getDate() + 1);
        dayIndex++; 
    }

    let weekNum = 1; 
    while(date.getMonth() === month) { 

        const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

        // console.log('month', date.getMonth(), 'week', weekNum, ' date ', date.getDate(), ' day ', date.getDay())
        week.push({ unixStart: startOfDay.getTime(), unixEnd: endOfDay.getTime(), day: date.getDate(), week: weekNum, month: date.getMonth(), year: date.getFullYear(), isToday: isSameDay(today, date), isInMonth: true, events: [] }); 

        if(date.getDay() === 6) { 
            weekNum++;
            // console.log('new weeki!', weekNum); 
            days.push(week); 
            week = []; 
        }

        // Go to next day 
        date.setDate(date.getDate() + 1)
    }

    // Add the final week 
    days.push(week); 

    // Final day
    if(date.getDay() < 7 && date.getDay() > 0) { 
        // console.log('Final Day', date.getDay()); 
        const remainingDays = 6 - date.getDay(); 
        let k = 0; 
        
        while(k < remainingDays + 1) {

            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

            week.push({ unixStart: startOfDay.getTime(), unixEnd: endOfDay.getTime(),day: date.getDate(), week: weekNum, month: date.getMonth(), year: date.getFullYear(), isToday: isSameDay(today, date), isInMonth: false, events: [] }); 
            date.setDate(date.getDate() + 1)
            k++ 
        }
    }

    return days; 
}

const applyEventsToDays = (days: DayObj[][], events: CalendarEvent[]) : DayObj[][] => { 
    
    days.forEach((week, k) => week.forEach((day, l) => { 
        events.forEach(event => {

            if(day.unixStart <= event.Date && day.unixEnd >= event.Date) { 
                console.log("Found a date: ", event.Date); 
                days[k][l].events.push(event); 
            }

        })
    }))

    return days; 
}


export type CalendarEvent = { 
    Date: number; 
    Title: string; 
    Link: string; 
    Description: string; 
}


const Calendar : React.FC<{ 
    fetchEvents: (date: Date) => Promise<CalendarEvent[]>; 
    iCalURL?: string;
}> = ({
    fetchEvents, 
    iCalURL, 
}) => { 

    const now = new Date(); 
    const [currentDate, setCurrentDate] = useState(new Date(now.getFullYear(), now.getMonth(), 1)); 
    const [days, setDays] = useState([] as DayObj[][]); 
    const today = () => setCurrentDate(new Date()); 
    const next = () => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));    
    const prev = () => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
    const classes = useStyles(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => { 
        const days = buildDays(currentDate); 
        fetchEvents(currentDate)
            .then(events => { 
                setDays(applyEventsToDays(days, events)); 
            });

    }, []); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => { 
        const days = buildDays(currentDate); 
        fetchEvents(currentDate)
            .then(events => { 
                setDays(applyEventsToDays(days, events)); 
            });

    }, [currentDate]); 

    return (
        <MonthWrapper>
            <MonthTitleBar>
                <MonthTitleLeft>
                    <ButtonGroup>
                        <Button color="secondary" onClick={prev}>
                            <ChevronLeft /> 
                        </Button>
                        <Button color="secondary" onClick={next}>
                            <ChevronRight /> 
                        </Button>
                    </ButtonGroup>
                    &nbsp; 
                    <Button color="secondary" onClick={today}>
                        Today
                    </Button>
                </MonthTitleLeft>
                <MonthTitleCenter>
                    {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
                </MonthTitleCenter>
                <MonthTitleRight>
                    {iCalURL && 
                        <a href={iCalURL}>iCal</a>
                    }
                </MonthTitleRight>
            </MonthTitleBar>
            <Month>
                <DayNames>{dayNames.map((dayName, k) => <DayName key={k}>{dayName}</DayName>)}</DayNames>
                {days.map((week, k) => (
                    <Week key={k}>
                        {week.map((day, l) => (
                            <Day key={l} className={day.isToday ? classes.today : ''} >
                                <DayNum className={day.isInMonth ? classes.active : classes.inactive}>{day.day}</DayNum>
                                <DayEvents day={day} /> 
                            </Day>
                        ))} 
                    </Week>
                ))}
            </Month>
        </MonthWrapper>
    ); 


}

const DayEvents : React.FC<{ day: DayObj }> = ({ day }) => { 

    const [moreShowing, setMoreShowing] = useState(false); 
    const styles = useStyles();
    const history = useHistory();  

    const onClick = (event : CalendarEvent) => () => { 
        if(event.Link.length > 0) { 
            history.push(event.Link); 
        }
    }

    return (
        <>
            {day.events.map((event, m) => { 
                if(m >= maxEventsDisplayedInDay) { 
                    return null; 
                }
                return <DayEvent onClick={onClick(event)} className={event.Link.length > 0 ? styles.dayEventLink : ''} key={m}>{event.Title}</DayEvent>
            })}
            {day.events.length > maxEventsDisplayedInDay && 
                <>
                    <DayEventsShowMore onClick={() => setMoreShowing(true)}>
                        + {day.events.length - maxEventsDisplayedInDay} more
                    </DayEventsShowMore>
                    {moreShowing && 
                        <ClickAwayListener onClickAway={() => setMoreShowing(false)}>
                            <DayEventsMoreToolTip>
                                <DayEventsMoreToolTipClose onClick={() => setMoreShowing(false)}>
                                    <Close /> 
                                </DayEventsMoreToolTipClose>
                                <DayEventsMoreToolTipTitle>
                                    {monthNames[day.month]} {day.day}, {day.year}
                                </DayEventsMoreToolTipTitle>
                                {day.events.map((event, m) => <DayEvent onClick={onClick(event)} className={event.Link.length > 0 ? styles.dayEventLink : ''} key={m}>{event.Title}</DayEvent>)}
                            </DayEventsMoreToolTip>
                        </ClickAwayListener>
                    }
                </>
            }
        </>
    ); 
}
export default Calendar; 

