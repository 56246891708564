import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, MenuItem, Tab, Tabs, TextField } from '@material-ui/core';
import { useCreateSysValsForYear } from 'app/api/values';
import { useGetListByTitle } from 'gen/routes/Lists';
import { MoneyInput } from 'core/components/MoneyInput';
import { ObjectTypes, ObjectTypeUser, ObjectTypeVendor } from 'core/definitions/constants/objectTypes';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateSysValsForYearDTO } from 'gen/dtos/CreateSysValsForYearDTO';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { UserSelect, VendorSelect } from './common';

enum DefaultValRange { 
    Month, 
    Year, 
}

const NewSysValsDialog : React.FC<{ 
    open: boolean; 
    onClose: () => void; 
}> = ({ open, onClose }) => { 

    const [model, setModel] = useState({ ...newCreateSysValsForYearDTO(), Year: (new Date()).getFullYear() });
    const { data, status, error } = useGetListByTitle("SysValType");
    const [create] = useCreateSysValsForYear(); 

    const doCreate = () => { 
        create(model)
            .then(() => { 
                onClose(); 
            });
    }

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>New System Values</DialogTitle>
            <DialogContent>
                <TextField fullWidth label="Year" type="number" value={model.Year} onChange={(e) => setModel({ ...model, Year: parseInt(e.target.value as string) })} />

                <h3><strong>Type</strong></h3>
                <List>
                    {data.data.Items.map((item, key) => <ListItem key={key}>
                        <ListItemIcon>
                            <Checkbox onChange={(e) => { 
                                if(e.target.checked && !model.SysValTypes.some(x => x === item.ListItemID)) { 
                                    setModel({ ...model, SysValTypes: [...model.SysValTypes, item.ListItemID ]});
                                }
                                if(!e.target.checked && model.SysValTypes.some(x => x === item.ListItemID)) { 
                                    setModel({ ...model, SysValTypes: model.SysValTypes.filter(x => x !== item.ListItemID) }); 
                                }
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={item.Name} /> 
                    </ListItem>)}
                </List>
                <Tabs value={model.DefaultValRange} onChange={(e, v) => setModel({ ...model, DefaultValRange: v })}>
                    <Tab value={DefaultValRange.Month} label="Month" /> 
                    <Tab value={DefaultValRange.Year} label="Year" /> 
                </Tabs>
                {model.DefaultValRange === DefaultValRange.Month && (
                    <>
                        <h4>Monthly Value</h4>
                        <MoneyInput label="Default Value" value={model.DefaultVal} onChange={(v) => setModel({ ...model, DefaultVal: v })} /> 
                    </>
                )}
                {model.DefaultValRange === DefaultValRange.Year && (
                    <>
                        <h4>Annual Value</h4>
                        <MoneyInput label="Default Value" value={model.DefaultVal} onChange={(v) => setModel({ ...model, DefaultVal: v })} /> 
                    </>
                )}
                
                <h4>Scope</h4>
                <TextField size="small" select label="Scope" variant="outlined" value={model.ObjectType} onChange={(e) => setModel({ ...model, ObjectType: parseInt(e.target.value as string) })}>
                    <MenuItem value={0}>
                        Global
                    </MenuItem>
                    {ObjectTypes.map((objectType, key) => <MenuItem value={objectType.Value} key={key}>{objectType.Name}</MenuItem>)}
                </TextField>
                
                <br /> 

                {model.ObjectType === ObjectTypeUser && <UserSelect userID={model.ObjectID} onChange={(userID: number) => setModel({ ...model, ObjectID: userID }) } />}
                {model.ObjectType === ObjectTypeVendor && <VendorSelect vendorID={model.ObjectID} onChange={(vendorID: number) => setModel({ ...model, ObjectID: vendorID }) } />}

                <br /> 
                <Checkbox onChange={(e) => setModel({ ...model, OverrideExisting: e.target.checked })} /> Override Existing Values
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={doCreate}>Create</Button>
                <Button onClick={() => onClose()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )

}

export default NewSysValsDialog; 