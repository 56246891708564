import { CircularProgress } from '@material-ui/core';
import { useGetVendors } from 'app/api/vendors';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DataBody, DataCell, DataHeaderOrdered, DataRow, DataTable, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import CreateVendorModal from './CreateVendorModal';
import { showCreateVendorModalAtom } from './state';

const VendorList = () => {

    const limit = 100; 
    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState("Title"); 
    const [orderDir, setOrderDir] = useState("ASC"); 
    const [createModalShowing, setCreateModalShowing] = useRecoilState(showCreateVendorModalAtom); 
    const { data: vendorsData, status, error } = useGetVendors(page, limit, orderBy, orderDir);  
    const toggleCreateModalShowing = () => setCreateModalShowing(!createModalShowing); 
    const history = useHistory(); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !vendorsData) {
        return <CircularProgress /> 
    }

    const vendors = vendorsData.data; 

    const next = () => {
        const newOffset = limit * (page + 1); 
        if(newOffset >= vendors.Count) {
            return; 
        }           
        setPage(page + 1);    
    }

    const prev = () => {    
        const newOffset = limit * (page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setPage(page - 1); 
    }

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    const toVendorPage = (companyID: number) => () => {
        history.push(`/vendors/${companyID}`)
    }

    const numCols = 4; 

    const colWidth = (units: number) : number => {
        return units * (99.9 / numCols); 
    }

    return <div style={{ height: '100%', overflow: 'hidden'}}>

        <NavBarSecondary right={
            <TableNav {...{count: vendors.Count, prev, next, page, limit}} />
        }>
            <strong>Manufacturers</strong>
        </NavBarSecondary>
           
        {vendors.Count === 0 &&
            <Alert color="info" style={{ margin: 20 }}>
                No Manufacturers
            </Alert>
        }

        {vendors.Data.length > 0 && 
            <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'hidden'}}>
                <DataRow>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("Title")} isOrderBy={orderBy === "Title"} dir={orderDir}>Title</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("City")} isOrderBy={orderBy === "City"} dir={orderDir}>City</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("State")} isOrderBy={orderBy === "State"} dir={orderDir}>State</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("ItemCount")} isOrderBy={orderBy === "ItemCount"} dir={orderDir}>Models</DataHeaderOrdered>
                </DataRow>
                <DataBody>
                    {vendors.Data.map((vendor, key) => 
                        <DataRow key={key} onClick={toVendorPage(vendor.VendorID)}>
                            <DataCell percent={colWidth(1)}>{vendor.Title}</DataCell>
                            <DataCell percent={colWidth(1)}>{vendor.City}</DataCell>
                            <DataCell percent={colWidth(1)}>{vendor.State}</DataCell>
                            <DataCell align="center" percent={colWidth(1)}>{vendor.ItemCount}</DataCell>
                        </DataRow>
                    )}
                </DataBody>
            </DataTable>
        }

        <Add action={toggleCreateModalShowing} /> 

        <CreateVendorModal /> 

        
    </div>
}

export default VendorList; 