import { Button, TextField } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DatePickerButtonEl = (props: { value?: any, onClick?: any }) => (
    <Button variant="contained" size="small" onClick={props.onClick}>
        {props.value}
    </Button>
); 

interface DatePickerButtonProps { 
    value: string; 
    onChange: (date: Date) => void; 
    showTime?: boolean 
}

export const DatePickerButton = ( props: DatePickerButtonProps ) => {

    const { value, onChange, showTime } = props; 
    
    let momentDate = moment(value); 
    
    if(!momentDate.isValid()) {
        momentDate = moment(); 
    }

    const date = momentDate.toDate(); 
    
    return (
        <ReactDatePicker
            selected={date}
            onChange={onChange}
            showTime
            timeIntervals={15}
            dateFormat={showTime ? "MMMM d, yyyy h:mm aa" : "MM/dd/yyyy"}
            showTimeSelect={showTime}
            customInput={<DatePickerButtonEl />}
        /> 
    )
}

const DatePickerInputEl = (props: { value?: any, onClick?: any }) => (
    <TextField style={{width: 120}} variant="outlined" size="small" inputProps={{ style: { fontSize: '14px' }}} value={props.value} onClick={props.onClick} />
); 

export const DatePickerInput = (props: { value: string, onChange: (date: Date) => void, showTime?: boolean }) => {
    
    const date = props.value ? moment(props.value).toDate() : null; 

    // let momentDate = moment(props.value); 
    
    // if(!momentDate.isValid()) {
    //     momentDate = moment(); 
    // }

    // const date = momentDate.toDate(); 

    return (
        <ReactDatePicker
            selected={date}
            onChange={props.onChange}
            showTime={props.showTime!!}
            timeIntervals={15}
            dateFormat={props.showTime ? "MMMM d, yyyy h:mm aa" : "MM/dd/yyyy"}
            showTimeSelect={props.showTime}
            customInput={<DatePickerInputEl />}
            
        /> 
    )
}

export const DatePickerRaw : React.FC<{ value: Date; onChange: (date: Date) => void }> = ({ value, onChange }) => {
    
    // const date = props.value ? moment(props.value).toDate() : null; 

    // let momentDate = moment(props.value); 
    
    // if(!momentDate.isValid()) {
    //     momentDate = moment(); 
    // }

    // const date = momentDate.toDate(); 

    return (
        <ReactDatePicker
            selected={value}
            onChange={onChange}

            timeIntervals={15}
            dateFormat={"MM/dd/yyyy"}           
        /> 
    )
}