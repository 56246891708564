/**
 *
 * FormFieldDTO
 */

export type FormFieldDTO = {

	// DataSourceFormFieldID int64
	DataSourceFormFieldID: number;

	// DataSourceID int64
	DataSourceID: number;

	// DataSourceType int
	DataSourceType: number;

	// DefaultValue string
	DefaultValue: string;

	// EditOnUpdate int
	EditOnUpdate: number;

	// FieldType int
	FieldType: number;

	// FormFieldGroupID int64
	FormFieldGroupID: number;

	// FormFieldID int64
	FormFieldID: number;

	// GroupOrdinal int64
	GroupOrdinal: number;

	// HelpText string
	HelpText: string;

	// IsRequired int
	IsRequired: number;

	// IsUnique int
	IsUnique: number;

	// MaxVal float64
	MaxVal: number;

	// MinVal float64
	MinVal: number;

	// ObjectID int64
	ObjectID: number;

	// ObjectType int64
	ObjectType: number;

	// Options string
	Options: string;

	// Ordinal int64
	Ordinal: number;

	// ShowOnCreate int
	ShowOnCreate: number;

	// ShowOnTableView int
	ShowOnTableView: number;

	// Slug string
	Slug: string;

	// TableViewOrdinal int64
	TableViewOrdinal: number;

	// Title string
	Title: string;

}

// newFormFieldDTO is a factory method for new FormFieldDTO objects
export const newFormFieldDTO = () : FormFieldDTO => ({
	DataSourceFormFieldID: 0,
	DataSourceID: 0,
	DataSourceType: 0,
	DefaultValue: '',
	EditOnUpdate: 0,
	FieldType: 0,
	FormFieldGroupID: 0,
	FormFieldID: 0,
	GroupOrdinal: 0,
	HelpText: '',
	IsRequired: 0,
	IsUnique: 0,
	MaxVal: 0,
	MinVal: 0,
	ObjectID: 0,
	ObjectType: 0,
	Options: '',
	Ordinal: 0,
	ShowOnCreate: 0,
	ShowOnTableView: 0,
	Slug: '',
	TableViewOrdinal: 0,
	Title: '',
});

