/**
 *
 * Admin.Policies
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreatePolicyDTO } from 'gen/dtos/CreatePolicyDTO';
import { Policy } from 'gen/models/Policy';
import { PolicyAggregate } from 'gen/aggregates/PolicyAggregate';
import { UpdatePolicyDTO } from 'gen/dtos/UpdatePolicyDTO';

// CreatePolicy creates a policy
// POST /admin/policies
// @permission Policies_CreatePolicy
export const createPolicy = async (args : { body : CreatePolicyDTO }) => await axios.post<Policy>(`/admin/policies`, args.body);
export const useCreatePolicy = () => useMutation(createPolicy, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	

// UpdatePolicy updates a policy
// PUT /admin/policies/{policyID:[0-9]+}
// @permission Policies_UpdatePolicy
export const updatePolicy = async (args : { policyID : number, body : UpdatePolicyDTO }) => await axios.put<Policy>(`/admin/policies/${args.policyID}`, args.body);
export const useUpdatePolicy = () => useMutation(updatePolicy, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	

// DeletePolicy deletes a policy
// DELETE /admin/policies/{policyID:[0-9]+}
// @permission Policies_DeletePolicy
export const deletePolicy = async (args : { policyID : number }) => await axios.delete<Policy>(`/admin/policies/${args.policyID}`);
export const useDeletePolicy = () => useMutation(deletePolicy, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	

// GetAllPolicies returns a slice of policies
// GET /admin/policies?page={page:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z0-9]+}
// @permission Policies_GetAllPolicies
export const getAllPolicies = async (page : number, orderBy : string, orderDir : string) => await axios.get<Policy[]>(`/admin/policies?page=${page}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetAllPolicies = (page : number, orderBy : string, orderDir : string) => useQuery(["getAllPolicies", page, orderBy, orderDir], (_ : string, page : number, orderBy : string, orderDir : string) => getAllPolicies(page,orderBy,orderDir));

// GetPolicyFromID returns a policy from its ID
// GET /admin/policies/{policies:[0-9]+}
// @permission Policies_GetPolicyFromID
export const getPolicyFromID = async (policies : number) => await axios.get<PolicyAggregate>(`/admin/policies/${policies}`);
export const useGetPolicyFromID = (policies : number) => useQuery(["getPolicyFromID", policies], (_ : string, policies : number) => getPolicyFromID(policies));

// AddPermissionToPolicy adds a permission to a policy
// POST /admin/policies/{policyID:[0-9]+}/permissions/{permission:[a-zA-Z0-9_]+}
// @permission Policies_AddPermissionToPolicy
export const addPermissionToPolicy = async (args : { policyID : number, permission : string }) => await axios.post<any>(`/admin/policies/${args.policyID}/permissions/${args.permission}`, {});
export const useAddPermissionToPolicy = () => useMutation(addPermissionToPolicy, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	

// RemovePermissionFromPolicy removes a permission from a policy
// DELETE /admin/policies/{policyID:[0-9]+}/permissions/{permission:[a-zA-Z0-9_]+}
// @permission Policies_RemovePermissionFromPolicy
export const removePermissionFromPolicy = async (args : { policyID : number, permission : string }) => await axios.delete<any>(`/admin/policies/${args.policyID}/permissions/${args.permission}`);
export const useRemovePermissionFromPolicy = () => useMutation(removePermissionFromPolicy, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	

// ExportPolicies exports a json file containing all policies, their permissions and roles
// GET /admin/policies/export
// @permission Policies_ExportPolicies
export const exportPolicies = async () => await axios.get(`/admin/policies/export`, { responseType: 'blob' });
export const useExportPolicies = () => useQuery(["exportPolicies"], (_ : string) => exportPolicies());

// ImportPolicies imports policies and roles from a json file
// POST /admin/policies/import
// @permission Policies_ImportPolicies
export const importPolicies = async (args : { body : FormData }) => await axios.post<any>(`/admin/policies/import`, args.body, { headers: { "content-type": "multipart/form-data" } });
export const useImportPolicies = () => useMutation(importPolicies, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllPolicies"]);
	},
	throwOnError: true, 
});
	
