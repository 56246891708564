import { newUpdatePolicyDTO, UpdatePolicyDTO } from "gen/dtos/UpdatePolicyDTO";
import { atom } from "recoil";

export const editPolicyAtom = atom<UpdatePolicyDTO & { PolicyID: number }>({
  key: "editPolicyAtom",
  default: { ...newUpdatePolicyDTO(), PolicyID: 0 },
});


export const policyPermissionPageAtom = atom<number>({
  key: "policyPermissionPageAtom",
  default: 0,
});


export const selectedManagePolicyAtom = atom<number>({
  key: "selectedManagePolicyAtom",
  default: 0,
});



export const showCreatePolicyModalAtom = atom<boolean>({
  key: "showCreatePolicyModalAtom",
  default: false,
});


export const showUpdatePolicyModalAtom = atom<boolean>({
  key: "showUpdatePolicyModalAtom",
  default: false,
});

