/**
 *
 * Profile
 */

export type Profile = {

	// AccountID int64
	AccountID: number;

	// Alias string
	Alias: string;

	// Avatar string
	Avatar: string;

	// BirthDate int64
	BirthDate: number;

	// CalendlySlug string
	CalendlySlug: string;

	// DateCreated int64
	DateCreated: number;

	// DateDeleted int64
	DateDeleted: number;

	// DateVerified int64
	DateVerified: number;

	// Description null.String
	Description: string;

	// FirstName string
	FirstName: string;

	// GenderID int
	GenderID: number;

	// GenderPronoun int64
	GenderPronoun: number;

	// IntroVideoURL null.String
	IntroVideoURL: string;

	// IsAdult int
	IsAdult: number;

	// IsPublic int
	IsPublic: number;

	// LanguageID int64
	LanguageID: number;

	// LastName string
	LastName: string;

	// LastUpdated int64
	LastUpdated: number;

	// MiddleName string
	MiddleName: string;

	// OnlineNow int
	OnlineNow: number;

	// PayoutBalance float64
	PayoutBalance: number;

	// PayoutPercent int64
	PayoutPercent: number;

	// ProfileID int64
	ProfileID: number;

	// ProfileType int64
	ProfileType: number;

	// Slug string
	Slug: string;

	// StripeID string
	StripeID: string;

	// StripeTOSAccepted int64
	StripeTOSAccepted: number;

	// TagLine string
	TagLine: string;

	// TeacherStripeCustomAccount string
	TeacherStripeCustomAccount: string;

	// TimezoneID int64
	TimezoneID: number;

	// UserID int64
	UserID: number;

}

// newProfile is a factory method for creating new Profile objects
export const newProfile = () : Profile => ({ 
	AccountID: 0,
	Alias: '',
	Avatar: '',
	BirthDate: 0,
	CalendlySlug: '',
	DateCreated: 0,
	DateDeleted: 0,
	DateVerified: 0,
	Description: '',
	FirstName: '',
	GenderID: 0,
	GenderPronoun: 0,
	IntroVideoURL: '',
	IsAdult: 0,
	IsPublic: 0,
	LanguageID: 0,
	LastName: '',
	LastUpdated: 0,
	MiddleName: '',
	OnlineNow: 0,
	PayoutBalance: 0,
	PayoutPercent: 0,
	ProfileID: 0,
	ProfileType: 0,
	Slug: '',
	StripeID: '',
	StripeTOSAccepted: 0,
	TagLine: '',
	TeacherStripeCustomAccount: '',
	TimezoneID: 0,
	UserID: 0,
});

