/**
 *
 * Base.ObjectTodos
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateObjectTodoDTO } from 'gen/dtos/CreateObjectTodoDTO';
import { ObjectTodo } from 'gen/models/ObjectTodo';
import { ObjectTodosAggregate } from 'gen/aggregates/ObjectTodosAggregate';
import { UpdateObjectTodoDTO } from 'gen/dtos/UpdateObjectTodoDTO';

// CreateTodo creates a todo
// POST /objectTodos
// @permission ObjectTodos_CreateTodo
export const createTodo = async (args : { body : CreateObjectTodoDTO }) => await axios.post<ObjectTodo>(`/objectTodos`, args.body);
export const useCreateTodo = () => useMutation(createTodo, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllTodos"]);
	},
	throwOnError: true, 
});
	

// UpdateTodo updates a todo
// PUT /objectTodos/{todoID:[0-9]+}
// @permission ObjectTodos_UpdateTodo
export const updateTodo = async (args : { todoID : number, body : UpdateObjectTodoDTO }) => await axios.put<ObjectTodo>(`/objectTodos/${args.todoID}`, args.body);
export const useUpdateTodo = () => useMutation(updateTodo, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllTodos"]);
	},
	throwOnError: true, 
});
	

// DeleteTodo deletes a todo
// DELETE /objectTodos/{todoID:[0-9]+}
// @permission ObjectTodos_DeleteTodo
export const deleteTodo = async (args : { todoID : number }) => await axios.delete<any>(`/objectTodos/${args.todoID}`);
export const useDeleteTodo = () => useMutation(deleteTodo, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllTodos"]);
	},
	throwOnError: true, 
});
	

// GetMyTodos gets todos assigned to the current person
// GET /objectTodos/my?objectType={objectType:[0-9]+}&objectID={objectID:[0-9]+}&includeCompleted={includeCompleted:[0-9]+}&isPlainLog={isPlainLog:[0-9]+}&page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z0-9]+}
// @permission ObjectTodos_GetMyTodos
export const getMyTodos = async (objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<ObjectTodosAggregate>(`/objectTodos/my?objectType=${objectType}&objectID=${objectID}&includeCompleted=${includeCompleted}&isPlainLog=${isPlainLog}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetMyTodos = (objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getMyTodos", objectType, objectID, includeCompleted, isPlainLog, page, limit, orderBy, orderDir], (_ : string, objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => getMyTodos(objectType,objectID,includeCompleted,isPlainLog,page,limit,orderBy,orderDir));

// GetAllTodos gets all todos
// GET /objectTodos?assignedTo={assignedTo:[0-9]+}&objectType={objectType:[0-9]+}&objectID={objectID:[0-9]+}&includeCompleted={includeCompleted:[0-9]+}&isPlainLog={isPlainLog:[0-9]+}&&page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z0-9]+}
// @permission ObjectTodos_GetAllTodos
export const getAllTodos = async (assignedTo : number, objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<ObjectTodosAggregate>(`/objectTodos?assignedTo=${assignedTo}&objectType=${objectType}&objectID=${objectID}&includeCompleted=${includeCompleted}&isPlainLog=${isPlainLog}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetAllTodos = (assignedTo : number, objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getAllTodos", assignedTo, objectType, objectID, includeCompleted, isPlainLog, page, limit, orderBy, orderDir], (_ : string, assignedTo : number, objectType : number, objectID : number, includeCompleted : number, isPlainLog : number, page : number, limit : number, orderBy : string, orderDir : string) => getAllTodos(assignedTo,objectType,objectID,includeCompleted,isPlainLog,page,limit,orderBy,orderDir));

// MarkTodoAsComplete marks a todo as complete
// PUT /objectTodos/{objectTodoID:[0-9]+}/complete
// @permission ObjectTodos_MarkTodoAsComplete
export const markTodoAsComplete = async (args : { objectTodoID : number }) => await axios.put<any>(`/objectTodos/${args.objectTodoID}/complete`, {});
export const useMarkTodoAsComplete = () => useMutation(markTodoAsComplete, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllTodos"]);
	},
	throwOnError: true, 
});
	

// MarkTodoAsInComplete marks a todo as complete
// PUT /objectTodos/{objectTodoID:[0-9]+}/incomplete
// @permission ObjectTodos_MarkTodoAsInComplete
export const markTodoAsInComplete = async (args : { objectTodoID : number }) => await axios.put<any>(`/objectTodos/${args.objectTodoID}/incomplete`, {});
export const useMarkTodoAsInComplete = () => useMutation(markTodoAsInComplete, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getAllTodos"]);
	},
	throwOnError: true, 
});
	
