import { Form, newForm } from "gen/models/Form";
import { FormField } from "gen/models/FormField";
import { FormFieldGroup } from "gen/models/FormFieldGroup";

export type FormAggregate = Form & { 
    Fields : FormField[]
    FieldGroups: FormFieldGroup[]
}

export const newFormAggregate = () : FormAggregate => ({
    ...newForm(), 
    Fields: [], 
    FieldGroups: [], 
})