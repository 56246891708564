import { CircularProgress } from '@material-ui/core';
import { useGetVendorItems } from 'app/api/vendors';
import { Add } from 'core/components/fabs';
import MoneyFormat from 'core/components/MoneyFormat';
import { DTable } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { VendorItem } from 'gen/models/VendorItem';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import CreateVendorItemModal from './CreateVendorItemModal';
import EditVendorItemModal from './EditVendorItemModal';
import { selectedVendorAtom, selectedVendorItemAtom, showCreateVendorItemModalAtom, showEditVendorItemModalAtom } from './state';

const VendorItemList = () => {

    const vendor = useRecoilValue(selectedVendorAtom); 

    
    const { data: itemsData, status, error } = useGetVendorItems(vendor.VendorID, 0, "VendorItemID", "ASC")
    const setCreateDialogShowing = useSetRecoilState(showCreateVendorItemModalAtom)
    const setShowEditVendorItemModal = useSetRecoilState(showEditVendorItemModalAtom)
    const setSelectedVendorItem = useSetRecoilState(selectedVendorItemAtom); 

    // Might move items to their own page, so leave the double atom for right now. 
    const selectItem = (item: VendorItem) => {
        setSelectedVendorItem(item); 
        setShowEditVendorItemModal(true); 
    }


    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !itemsData) {
        return <CircularProgress /> 
    }

    const items = itemsData.data; 

    

    return (
        <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
            <DTable
                onRowClick={(item) => selectItem(item)}
                cols={[
                    { size: 2, label: "Title", val: (row: VendorItem) => <strong>{row.Title}</strong> },
                    { size: .5, label: "Model Number", val: (row: VendorItem) => <div>{row.ModelNumber}</div> },
                    { size: .5, label: "Price", val: (row: VendorItem) => <>$<MoneyFormat val={row.Cost} /></>, align: 'center' },
                ]}
                data={items.Data}
            /> 

            <Add action={() => setCreateDialogShowing(true)} />
            <CreateVendorItemModal /> 

            <EditVendorItemModal /> 

        </div>
    )
}

export default VendorItemList; 