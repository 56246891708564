import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateVendor } from 'app/api/vendors';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateVendorDTO } from 'gen/dtos/CreateVendorDTO';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { showCreateVendorModalAtom } from './state';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreateVendorModal = () => {

    const [showing, setShowing] = useRecoilState(showCreateVendorModalAtom); 
    const [vendor, setVendor] = useState(newCreateVendorDTO())
    const [createVendor, { status, error }] = useCreateVendor(); 

    const toggleDialog = () => setShowing(false)

    const save = () => {
        createVendor(vendor)
            .then(vendor => {
                setShowing(false); 
                setVendor(newCreateVendorDTO()); 
            })
    }


    return <Dialog open={showing} onBackdropClick={toggleDialog}>

        <DialogTitle>Create A Vendor</DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }

            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Title" value={vendor.Title} onChange={(e) => setVendor({ ...vendor, Title: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address</InputLabel>
                <Input type="text" name="Address" value={vendor.Address} onChange={(e) => setVendor({ ...vendor, Address: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address (cont.)</InputLabel>
                <Input type="text" name="Address2" value={vendor.Address2} onChange={(e) => setVendor({ ...vendor, Address2: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>City</InputLabel>
                <Input type="text" name="City" value={vendor.City} onChange={(e) => setVendor({ ...vendor, City: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>State</InputLabel>
                <Input type="text" name="State" value={vendor.State} onChange={(e) => setVendor({ ...vendor, State: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Zip</InputLabel>
                <Input type="text" name="Zip" value={vendor.Zip} onChange={(e) => setVendor({ ...vendor, Zip: e.target.value })} /> 
            </StyledFormControl>

            Phone: <ReactPhoneInput country="us" value={vendor.Phone} onChange={(v) => setVendor({ ...vendor, Phone: v})} /> 
            <br /> 
            Fax: <ReactPhoneInput country="us" value={vendor.Fax} onChange={(v) => setVendor({ ...vendor, Fax: v })} /> 

        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={status === "loading"} variant="contained">
                Create
            </Button>
            <Button onClick={toggleDialog} disabled={status === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default CreateVendorModal; 