/**
 *
 * Customer
 */

export type Customer = {

	// Address string
	Address: string;

	// Address2 string
	Address2: string;

	// City string
	City: string;

	// ContactCount int64
	ContactCount: number;

	// CustomerID int64
	CustomerID: number;

	// DateCreated int64
	DateCreated: number;

	// Fax string
	Fax: string;

	// IsDeleted int
	IsDeleted: number;

	// Phone string
	Phone: string;

	// State string
	State: string;

	// Title string
	Title: string;

	// Zip string
	Zip: string;

}

// newCustomer is a factory method for creating new Customer objects
export const newCustomer = () : Customer => ({ 
	Address: '',
	Address2: '',
	City: '',
	ContactCount: 0,
	CustomerID: 0,
	DateCreated: 0,
	Fax: '',
	IsDeleted: 0,
	Phone: '',
	State: '',
	Title: '',
	Zip: '',
});

