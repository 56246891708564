import { Chip, CircularProgress, Snackbar } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { Tab, Tabs } from 'core/components/tabs';
import { ObjectTypeUserProfile } from 'core/definitions/constants/objectTypes';
import ActivityList from 'core/pages/admin/activity/ActivityList';
import AdminActions from 'core/pages/admin/common/AdminActions';
import AdminHeader from 'core/pages/admin/common/AdminHeader';
import Notify from 'core/pages/admin/common/Notifiy';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import userService from 'core/utils/userService';
import { Activity_ViewPermission, AdminUtils_RefreshUserSessionsPermission, Users_ActivateUserPermission, Users_AddRoleToUserPermission, Users_AddUserToGroupPermission, Users_DisableUserPermission, Users_EnableUserPermission, Users_GetUserDevicesPermission, Users_GetUserEventLogsPermission, Users_GetUserSessionPermission, Users_RemoveRoleFromUserPermission, Users_RemoveUserFromGroupPermission, Users_SetUserPasswordPermission, Users_UpdateUserPermission, Users_UpdateUserSettingsPermission, Values_ViewPermission } from 'gen/constants/permissions';
import { refreshUserSessions } from 'gen/routes/AdminUtils';
import { useActivateUser, useDisableUser, useEnableUser, useGetUserFromID, useLockUser, useUnlockUser } from 'gen/routes/Users';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import EventLogs from '../../activity/EventLogs';
import UserDetailAccountEdit from './UserDetailAccountEdit';
import UserDevices from './UserDevices';
import UserGroups from './UserGroups';
import UserPassword from './UserPassword';
import UserRoles from './UserRoles';
import UserSettings from './UserSettings';


function Alert(props) { 
    return <MuiAlert elevation={6} variant="filled" {...props} /> 
}

const UserDetail = () => {

    const { userID } = useParams() as { userID: string }; 
    const { status, data, error } = useGetUserFromID(parseInt(userID as string));
    const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom); 

    useEffect(() => {

        if(status === "success" && data) {
            console.log('useEffect', status, data.data); 
            setSelectedUser(data.data); 
        }

    }, [status, data, setSelectedUser]);

    
    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }
    
    return status === "success" && selectedUser.UserID > 0  ?  ( <UserDetailInner /> ) : <CircularProgress />; 
}

enum Status { 
    None, 
    Loading, 
    Success, 
    Failure, 
}

const UserDetailInner = () => {

    const history = useHistory(); 
    const user = useRecoilValue(selectedUserAtom);
    const [activateUser, { status: activateUserStatus, error: activateUserError }] = useActivateUser(); 
    const [disableUser, { status: disableUserStatus, error: disableUserError, reset : resetDisableUser }] = useDisableUser(); 
    const [enableUser, { status: enableUserStatus, error: enableUserError, reset : resetEnableUser }] = useEnableUser(); 
    const [lockUser, { status: lockUserStatus, error: lockUserError, reset: resetLockUser }] = useLockUser(); 
    const [unLockUser, { status: unLockUserStatus, error: unLockUserError, reset: resetUnLockUser }] = useUnlockUser();


    const [refreshStatus, setRefreshStatus] = useState<Status>(Status.None); 
    const doRefreshUserSession = () => { 
        setRefreshStatus(Status.Loading); 
        refreshUserSessions({ userID: user.UserID })
            .then(() => { 
                setRefreshStatus(Status.Success); 
            })
            .catch(() => { 
                setRefreshStatus(Status.Failure); 
            });

    } 

    const doImpersonation = () => { 

        console.log('doing impersonation'); 
        userService.impersonate(user.UserID, () => { 
            console.log('Finished impersonation'); 
            window.location.reload(); 
        });
    }
    
    const [tab, setTab] = useState('details'); 

    return <div style={{ height: "100%" }}>

        <NavBarSecondary
            right={<>
                <AdminActions actions={[
                        { title: 'Activate', fn: () => activateUser({ userID: user.UserID }), show: () => user.DateActivated === 0, perm: Users_ActivateUserPermission }, 
                        { title: user.IsDisabled === 0 ? 'Disable' : 'Enable', fn: () => user.IsDisabled === 0 ? disableUser({ userID: user.UserID }) : enableUser({ userID: user.UserID }), show: () => user.DateActivated > 0, perm: user.IsDisabled ? Users_EnableUserPermission : Users_DisableUserPermission }, 
                        { title: user.IsLocked === 0 ? 'Lock' : 'Unlock', fn: () => user.IsLocked === 0 ? lockUser({ userID: user.UserID }) : unLockUser({ userID: user.UserID }), show: () => user.DateActivated > 0}, 
                        { title: "Refresh Session", fn: doRefreshUserSession, perm: AdminUtils_RefreshUserSessionsPermission}, 
                        { title: "Impersonate", fn: doImpersonation, perm: Users_GetUserSessionPermission }, 
                    ]}
                /> 
            </>}
        >
            <AdminHeader title={user.FullName} parents={[
                {title: `Users`, url: `/admin/users`},
            ]} /> 

            {user.IsDisabled === 1 && <Chip label="Disabled" onDelete={() => enableUser({ userID: user.UserID })}  />}
            {user.IsLocked === 1 && <Chip icon={<Lock />} label="Locked" onDelete={() => unLockUser({ userID: user.UserID })} />}
            {user.DateActivated === 0 && <Chip label="Inactive" onDelete={() => activateUser({ userID: user.UserID })} />}
        </NavBarSecondary>

        {/* Activate */}
        <Snackbar open={activateUserStatus === "error"} onClick={() => null}>
            <Alert severity="error">{handleNetworkError(activateUserError)}</Alert>
        </Snackbar> 

        <Snackbar open={activateUserStatus === "success"} onClick={() => null}>
            <Alert severity="success">User Activated</Alert>
        </Snackbar>

        {/* Disable */}
        <Notify open={disableUserStatus === "error"} onClose={() => resetDisableUser()} message={handleNetworkError(disableUserError)} type="error" /> 
        <Notify open={disableUserStatus === "success"} onClose={() => resetDisableUser()} message={"User Disabled"} type="success" /> 

        {/* Enable */}
        <Notify open={enableUserStatus === "error"} onClose={() => resetEnableUser()} message={handleNetworkError(enableUserError)} type="error" /> 
        <Notify open={enableUserStatus === "success"} onClose={() => resetEnableUser()} message={"User Enabled"} type="success" /> 

        {/* Lock */}
        <Notify open={lockUserStatus === "error"} onClose={() => resetLockUser()} message={handleNetworkError(lockUserError)} type="error" /> 
        <Notify open={lockUserStatus === "success"} onClose={() => resetLockUser()} message={"User Locked"} type="success" /> 

        {/* UnLock */}
        <Notify open={unLockUserStatus === "error"} onClose={() => resetUnLockUser()} message={handleNetworkError(unLockUserError)} type="error" /> 
        <Notify open={unLockUserStatus === "success"} onClose={() => resetUnLockUser()} message={"User UnLocked"} type="success" /> 

        {/* Refresh Session */}
        <Notify open={refreshStatus === Status.Failure} onClose={() => setRefreshStatus(Status.None)} message={"Error refreshing user session"} type="error" /> 
        <Notify open={refreshStatus === Status.Success} onClose={() => setRefreshStatus(Status.None)} message={"User session refreshed"} type="success" /> 


        <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden'}}>
            
            <Tabs>

                <Tab perm={Users_UpdateUserPermission} value="details" label="Details" onClick={() => setTab('details')} tab={tab} />
                <Tab perm={Users_SetUserPasswordPermission} value="password" label="Password" onClick={() => setTab('password')} tab={tab} />
                <Tab perm={[Users_AddRoleToUserPermission, Users_RemoveRoleFromUserPermission]} value="roles" label="Roles" onClick={() => setTab('roles')} tab={tab} />
                <Tab perm={[Users_AddUserToGroupPermission, Users_RemoveUserFromGroupPermission]} value="groups" label="Groups" onClick={() => setTab('groups')} tab={tab} />
                <Tab perm={Users_GetUserDevicesPermission} value="devices" label="Devices" onClick={() => setTab('devices')} tab={tab} />
                <Tab perm={Users_UpdateUserSettingsPermission} value="settings" label="Settings" onClick={() => setTab('settings')} tab={tab} />
                <Tab perm={Users_GetUserEventLogsPermission} value="eventlogs" label="Events" onClick={() => setTab('eventlogs')} tab={tab} />
                <Tab perm={Activity_ViewPermission} value="logs" label="Logs" onClick={() => setTab('logs')} tab={tab} />
                <Tab perm={Values_ViewPermission} value="values" label={`Values`} onClick={() => history.push(`/values/sysvals?objectType=${ObjectTypeUserProfile}&objectID=${user.UserID}`)} tab={tab} /> 

            </Tabs>
            {/* eslint-disable no-whitespace-before-property */}
            {{
                "details": <UserDetailAccountEdit />, 
                "devices": <UserDevices />, 
                "groups": <UserGroups />, 
                "logs": <div style={{ height: 'calc(100% - 67px)', overflowY: 'hidden' }}><ActivityList userID={user.UserID} /></div>, 
                "eventlogs": <div style={{ height: 'calc(100% - 67px)', overflowY: 'hidden' }}><EventLogs userID={user.UserID} /></div>, 
                "roles": <UserRoles />, 
                "password": <UserPassword />,
                "settings": <UserSettings />, 
            } [tab] || <UserDetailAccountEdit />}
        </div>


    </div>
}


export default UserDetail; 
