/**
 *
 * Base.Verify
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserVerifyResponseDTO } from 'gen/dtos/UserVerifyResponseDTO';

// VerifyUserNonce 
// GET /verify/{token:[0-9a-zA-Z]+}
export const verifyUserNonce = async (token : string) => await axios.get<UserVerifyResponseDTO>(`/verify/${token}`);
export const useVerifyUserNonce = (token : string) => useQuery(["verifyUserNonce", token], (_ : string, token : string) => verifyUserNonce(token));
