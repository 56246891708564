/**
 *
 * Base.Lists
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateListDTO } from 'gen/dtos/CreateListDTO';
import { CreateListItemDTO } from 'gen/dtos/CreateListItemDTO';
import { List } from 'gen/models/List';
import { ListAggregate } from 'gen/aggregates/ListAggregate';
import { ListItem } from 'gen/models/ListItem';
import { ListsAggregate } from 'gen/aggregates/ListsAggregate';
import { SearchResultsAggregate } from 'gen/aggregates/SearchResultsAggregate';
import { UpdateListDTO } from 'gen/dtos/UpdateListDTO';
import { UpdateListItemDTO } from 'gen/dtos/UpdateListItemDTO';

// CreateList creates a list
// POST /lists
// @permission Lists_CreateList
export const createList = async (args : { body : CreateListDTO }) => await axios.post<List>(`/lists`, args.body);
export const useCreateList = () => useMutation(createList, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
	},
	throwOnError: true, 
});
	

// UpdateList updates a list
// PUT /lists/{listID:[0-9]+}
// @permission Lists_UpdateList
export const updateList = async (args : { listID : number, body : UpdateListDTO }) => await axios.put<List>(`/lists/${args.listID}`, args.body);
export const useUpdateList = () => useMutation(updateList, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// DeleteList deletes a company
// DELETE /lists/{listID:[0-9]+}
// @permission Lists_DeleteList
export const deleteList = async (args : { listID : number }) => await axios.delete<any>(`/lists/${args.listID}`);
export const useDeleteList = () => useMutation(deleteList, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// GetLists returns a collection of lists
// GET /lists?page={page:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z0-9]+}
// @permission Lists_GetLists
export const getLists = async (page : number, orderBy : string, orderDir : string) => await axios.get<ListsAggregate>(`/lists?page=${page}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetLists = (page : number, orderBy : string, orderDir : string) => useQuery(["getLists", page, orderBy, orderDir], (_ : string, page : number, orderBy : string, orderDir : string) => getLists(page,orderBy,orderDir));

// GetListFromID gets a list by its ID
// GET /lists/{listID:[0-9]+}
// @permission Lists_GetListFromID
export const getListFromID = async (listID : number) => await axios.get<ListAggregate>(`/lists/${listID}`);
export const useGetListFromID = (listID : number) => useQuery(["getListFromID", listID], (_ : string, listID : number) => getListFromID(listID));

// ResetCacheForList gets a list by its ID
// PUT /lists/{listID:[0-9]+}/refreshCache
// @permission Lists_ResetCacheForList
export const resetCacheForList = async (args : { listID : number }) => await axios.put<any>(`/lists/${args.listID}/refreshCache`, {});
export const useResetCacheForList = () => useMutation(resetCacheForList, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// GetListFromListType gets a list by its type
// GET /lists?listType={listType:[0-9]+}
// @permission Lists_GetListFromListType
export const getListFromListType = async (listType : number) => await axios.get<ListAggregate>(`/lists?listType=${listType}`);
export const useGetListFromListType = (listType : number) => useQuery(["getListFromListType", listType], (_ : string, listType : number) => getListFromListType(listType));

// SearchListsByTitle returns a list of lists based on a title search
// GET /lists?search={search:[a-zA-Z0-9]+}
export const searchListsByTitle = async (search : string) => await axios.get<SearchResultsAggregate>(`/lists?search=${search}`);
export const useSearchListsByTitle = (search : string) => useQuery(["searchListsByTitle", search], (_ : string, search : string) => searchListsByTitle(search));

// GetListByStringID returns a list by its stringID
// GET /lists?stringID={stringID:[a-zA-Z0-9]+}
export const getListByStringID = async (stringID : string) => await axios.get<ListAggregate[]>(`/lists?stringID=${stringID}`);
export const useGetListByStringID = (stringID : string) => useQuery(["getListByStringID", stringID], (_ : string, stringID : string) => getListByStringID(stringID));

// CreateListItem creates a list item
// POST /lists/{listID:[0-9]+}/items
// @permission Lists_CreateListItem
export const createListItem = async (args : { listID : number, body : CreateListItemDTO }) => await axios.post<ListItem>(`/lists/${args.listID}/items`, args.body);
export const useCreateListItem = () => useMutation(createListItem, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// UpdateListItem creates a list item
// PUT /lists/{listID:[0-9]+}/items/{itemID:[0-9]+}
// @permission Lists_UpdateListItem
export const updateListItem = async (args : { listID : number, itemID : number, body : UpdateListItemDTO }) => await axios.put<ListItem>(`/lists/${args.listID}/items/${args.itemID}`, args.body);
export const useUpdateListItem = () => useMutation(updateListItem, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// SetItemOrdinal creates a list item
// GET /lists/{listID:[0-9]+}/items/{itemID:[0-9]+}/ordinal?ordinal={ordinal:[0-9]+}
// @permission Lists_SetItemOrdinal
export const setItemOrdinal = async (listID : number, itemID : number, ordinal : number) => await axios.get<ListItem>(`/lists/${listID}/items/${itemID}/ordinal?ordinal=${ordinal}`);
export const useSetItemOrdinal = (listID : number, itemID : number, ordinal : number) => useQuery(["setItemOrdinal", listID, itemID, ordinal], (_ : string, listID : number, itemID : number, ordinal : number) => setItemOrdinal(listID,itemID,ordinal));

// DeleteListItem deletes a list item
// DELETE /lists/{listID:[0-9]+}/items/{itemID:[0-9]+}
// @permission Lists_DeleteListItem
export const deleteListItem = async (args : { listID : number, itemID : number }) => await axios.delete<any>(`/lists/${args.listID}/items/${args.itemID}`);
export const useDeleteListItem = () => useMutation(deleteListItem, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
		queryCache.invalidateQueries(["getListFromID", variables.listID]);
	},
	throwOnError: true, 
});
	

// GetListItemsFromParentListItem gets a slice of list items based on their parent list item
// GET /lists?parentListItemIDs={parentListItemIDs:[0-9,]+}
export const getListItemsFromParentListItem = async (parentListItemIDs : string) => await axios.get<ListItem[]>(`/lists?parentListItemIDs=${parentListItemIDs}`);
export const useGetListItemsFromParentListItem = (parentListItemIDs : string) => useQuery(["getListItemsFromParentListItem", parentListItemIDs], (_ : string, parentListItemIDs : string) => getListItemsFromParentListItem(parentListItemIDs));

// GetListByTitle gets a by the title
// GET /lists?title={title:[a-zA-Z0-9-_]+}
export const getListByTitle = async (title : string) => await axios.get<ListAggregate>(`/lists?title=${title}`);
export const useGetListByTitle = (title : string) => useQuery(["getListByTitle", title], (_ : string, title : string) => getListByTitle(title));

// GetListsByTitles gets a set of lists by their title
// GET /lists?titles={title:[a-zA-Z0-9-_,]+}
export const getListsByTitles = async (titles : string) => await axios.get<{ [key: string]: ListAggregate }>(`/lists?titles=${titles}`);
export const useGetListsByTitles = (titles : string) => useQuery(["getListsByTitles", titles], (_ : string, titles : string) => getListsByTitles(titles));

// GetListsByStringIDs gets a set of lists by their stringID
// GET /lists?stringIDs={lists:[a-zA-Z0-9-_,]+}
export const getListsByStringIDs = async (stringIDs : string) => await axios.get<{ [key: string]: ListAggregate }>(`/lists?stringIDs=${stringIDs}`);
export const useGetListsByStringIDs = (stringIDs : string) => useQuery(["getListsByStringIDs", stringIDs], (_ : string, stringIDs : string) => getListsByStringIDs(stringIDs));

// ExportList exports a json file containing a list and all its elements
// GET /lists/{listID:[0-9]+}/export
// @permission Lists_ExportList
export const exportList = async (listID : number) => await axios.get(`/lists/${listID}/export`, { responseType: 'blob' });
export const useExportList = (listID : number) => useQuery(["exportList", listID], (_ : string, listID : number) => exportList(listID));

// ImportList imports policies and roles from a json file
// POST /lists/import
// @permission Lists_ImportList
export const importList = async (args : { body : FormData }) => await axios.post<any>(`/lists/import`, args.body, { headers: { "content-type": "multipart/form-data" } });
export const useImportList = () => useMutation(importList, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getListsByStringIDs"]);
	},
	throwOnError: true, 
});
	
