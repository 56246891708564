import { CircularProgress, Divider, IconButton, Menu, MenuItem, Snackbar, Tab, Table, TableBody, TableCell, TableRow, Tabs } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDeleteVendor, useGetVendorFromID } from 'app/api/vendors';
import ConfirmModal from 'core/components/confirmModal';
import { Edit } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { ObjectTypeVendor } from 'core/definitions/constants/objectTypes';
import handleNetworkError from 'core/utils/handleNetworkError';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { cond, equals, T } from 'ramda'; 
import { Alert } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import EditVendorModal from './EditVendorModal';
import { selectedVendorAtom, showEditVendorModalAtom } from './state';
import VendorItemList from './VendorItemList';


const ITEM_HEIGHT = 48; 

const VendorDetail = () => {

    const history = useHistory(); 
    const { id } = useParams() as { id: string }; 
    const vendorID = parseInt(id as string); 
    const  { status, error, data: vendorData } = useGetVendorFromID(vendorID); 
    const [deleteVendor, { status: deleteVendorStatus, error: deleteVendorError }] = useDeleteVendor(); 
    const [tab, setTab] = useState(0); 
    const [actionsEl, setActionsEl] = useState(null); 
    const handleShowActions = (e) => setActionsEl(e.currentTarget); 
    const handleHideActions = () => setActionsEl(null); 
    const actionsShowing = Boolean(actionsEl); 
    const [deleteModalShowing, setDeleteModalShowing] = useState(false); 
    const setSelectedVendor = useSetRecoilState(selectedVendorAtom); 
    
    const deleteVendorStart = () => {
        setDeleteModalShowing(true); 
        handleHideActions(); 
    }

    const confirmDelete = () => {
        deleteVendor(vendorID); 
        history.push(`/vendors`)
    }

    useEffect(() => {
        if(status === "success" && vendorData) {
            setSelectedVendor(vendorData.data); 
        }
    }, [status, vendorData, setSelectedVendor])


    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !vendorData) {
        return <CircularProgress /> 
    }

    const vendor = vendorData.data; 
    
    return (
        <div style={{ height: '100%', overflow: 'hidden' }}>
            <NavBarSecondary 
                back="/vendors"
                right={<div>
                    <IconButton onClick={handleShowActions}>
                        <MoreVert /> 
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={actionsEl}
                        keepMounted
                        open={actionsShowing}
                        onClose={handleHideActions}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem key={0} onClick={handleHideActions}>Archive</MenuItem>
                        <Divider /> 
                        <MenuItem key={1} onClick={deleteVendorStart}>Delete</MenuItem>
                    </Menu>
                </div>}
            >
                Manufacturers / {vendor.Title}
            </NavBarSecondary>
            
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                <Tab label="Details" /> 
                <Tab label={`Models (${vendor.ItemCount})`} /> 
                <Tab label={`Values`} onClick={() => history.push(`/values/sysvals?objectType=${ObjectTypeVendor}&objectID=${vendor.VendorID}`)} /> 
            </Tabs>

            {tab === 0 && <Detail />}
            {tab === 1 && <VendorItemList  /> }

            {/* Delete vendor error */}
            <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'center'
                }}
                message={`Error: ${handleNetworkError(deleteVendorError)}`}
                open={deleteVendorStatus === "error"} onClose={() => null} 
            />

            <ConfirmModal 
                title="Delete this manufacturer?"
                description="Are you sure you want to delete this manufacturer?"
                open={deleteModalShowing}
                setOpen={setDeleteModalShowing}
                confirm={confirmDelete}
            /> 
        </div>

    )
}

const Detail = () => {
    
    const model = useRecoilValue(selectedVendorAtom); 
    const setShowEdit = useSetRecoilState(showEditVendorModalAtom); 

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{model.Address}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Address 2</TableCell>
                        <TableCell>{model.Address2}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>City</TableCell>
                        <TableCell>{model.City}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>{model.State}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Zip</TableCell>
                        <TableCell>{model.Zip}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{model.Phone}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Fax</TableCell>
                        <TableCell>{model.Fax}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Edit action={() => setShowEdit(true)} /> 

            <EditVendorModal /> 
        </div>
    )
}

export default VendorDetail; 