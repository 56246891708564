import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Input, InputLabel } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useDeleteVendorItem, useUpdateVendorItem } from 'app/api/vendors';
import { newUpdateVendorItemDTO } from 'app/definitions/dtos/UpdateVendorItemDTO';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Vendors_DeleteVendorPermission } from 'gen/constants/permissions';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { selectedVendorAtom, selectedVendorItemAtom, showEditVendorItemModalAtom } from './state';

const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const EditVendorItemModal = () => {

    const [deleteVendorItem] = useDeleteVendorItem(); 
    const [updateItem, { status: updateItemStatus, error: updateItemError }] = useUpdateVendorItem(); 
    const [showing, setShowing] = useRecoilState(showEditVendorItemModalAtom); 
    const [item, setItem] = useRecoilState(selectedVendorItemAtom); 
    const vendor = useRecoilValue(selectedVendorAtom);
    const [costVal, setCostVal] = useState(""); 
    const [isDeleting, setIsDeleting] = useState(false); 

    
    useEffect(() => {
        if(showing === true) {
            setCostVal(item.Cost.toFixed(2)) 
        }
    }, [showing, item])


    const save = () => {
        updateItem({ ...newUpdateVendorItemDTO(item), Cost: parseFloat(costVal), VendorID: item.VendorID, VendorItemID: item.VendorItemID })
            .then(() => {
                setShowing(false); 
            });
    }

    const deleteStart = () => { 
        setIsDeleting(true); 
    }

    const deleteFinish = () => { 
        setIsDeleting(false); 
        deleteVendorItem({ vendorID: item.VendorID, vendorItemID: item.VendorItemID })    
            .then(() => {
                setShowing(false); 
            });
    }


    return <Dialog open={showing} onBackdropClick={() => setShowing(false)} onEscapeKeyDown={() => setShowing(false)}>

        <DialogTitle>Edit Product</DialogTitle>
        
        <DialogContent>

            {updateItemStatus === "error" && 
                <Alert color="danger">{handleNetworkError(updateItemError)}</Alert>
            }

            <small>Manufacturer: {vendor.Title}</small> <br /> 


            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Title" value={item.Title} onChange={(e) => setItem({ ...item, Title: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address</InputLabel>
                <Input type="text" name="Address" value={item.Description} onChange={(e) => setItem({ ...item, Description: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Model Number</InputLabel>
                <Input type="text" name="Address" value={item.ModelNumber} onChange={(e) => setItem({ ...item, ModelNumber: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Price</InputLabel>
                <Input type="text" name="Price" value={costVal} onChange={(e) => setCostVal(e.target.value)} /> 
            </StyledFormControl>

        </DialogContent>
        <DialogActions>

            {isDeleting ? 
                <>
                    <Button variant="contained" color="secondary" onClick={deleteFinish}>
                        Yes, Delete
                    </Button>
                    <Button variant="contained" onClick={() => setIsDeleting(false)}>
                        No, Cancel
                    </Button>
                </>
                : <>
                    <Perm perm={Vendors_DeleteVendorPermission}>
                        <IconButton onClick={deleteStart}>
                            <Delete />
                        </IconButton>
                    </Perm>
                    <Button onClick={save} disabled={updateItemStatus === "loading"} variant="contained">Save</Button>
                    <Button onClick={() => setShowing(false)} disabled={updateItemStatus === "loading"}>cancel</Button>
                </>
            }

        </DialogActions>

    </Dialog>
}

export default EditVendorItemModal; 