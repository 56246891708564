/**
 *
 * UpdateFormDTO
 */
import { FormFieldDTO } from './FormFieldDTO';
import { FormFieldGroupDTO } from './FormFieldGroupDTO';

export type UpdateFormDTO = {

	// CreateButtonText string
	CreateButtonText: string;

	// CreateResponseTextSuccess string
	CreateResponseTextSuccess: string;

	// Description string
	Description: string;

	// Fields []*FormFieldDTO
	Fields: FormFieldDTO[];

	// Groups []*FormFieldGroupDTO
	Groups: FormFieldGroupDTO[];

	// Slug string
	Slug: string;

	// StringIDPrefix string
	StringIDPrefix: string;

	// Title string
	Title: string;

	// UpdateButtonText string
	UpdateButtonText: string;

	// UpdateResponseTextSuccess string
	UpdateResponseTextSuccess: string;

}

// newUpdateFormDTO is a factory method for new UpdateFormDTO objects
export const newUpdateFormDTO = () : UpdateFormDTO => ({
	CreateButtonText: '',
	CreateResponseTextSuccess: '',
	Description: '',
	Fields: [],
	Groups: [],
	Slug: '',
	StringIDPrefix: '',
	Title: '',
	UpdateButtonText: '',
	UpdateResponseTextSuccess: '',
});

