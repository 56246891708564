import { CircularProgress, MenuItem, TextField } from '@material-ui/core';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import React from "react";
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { selectedJobAtom } from './state';


const JobDetailInsideSales = () => {

    const [job, setJob] = useRecoilState(selectedJobAtom)
    const { data, status, error } = useGetMyAccountUsers(1, 0, 100); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }


    return (
        <TextField 
            select 
            fullWidth 
            variant="outlined"
            value={job.InsideSalesID}
            size="small"
            onChange={(e) => setJob({ ...job, InsideSalesID: parseInt(e.target.value as string)})}
        >
            {data.data.Data.map((u, k) => <MenuItem key={k} value={u.UserID}>{u.FullName}</MenuItem>)} 
        </TextField>
    );
}

export default JobDetailInsideSales; 