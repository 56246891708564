/**
 *
 * QuoteCustomer
 */

export type QuoteCustomer = {

	// CustomerContactID int64
	CustomerContactID: number;

	// CustomerID int64
	CustomerID: number;

	// DateCreated int64
	DateCreated: number;

	// IsDeleted int
	IsDeleted: number;

	// QuoteCustomerID int64
	QuoteCustomerID: number;

	// QuoteCustomerType int
	QuoteCustomerType: number;

	// QuoteID int64
	QuoteID: number;

	// QuoteNumberID int64
	QuoteNumberID: number;

}

// newQuoteCustomer is a factory method for creating new QuoteCustomer objects
export const newQuoteCustomer = () : QuoteCustomer => ({ 
	CustomerContactID: 0,
	CustomerID: 0,
	DateCreated: 0,
	IsDeleted: 0,
	QuoteCustomerID: 0,
	QuoteCustomerType: 0,
	QuoteID: 0,
	QuoteNumberID: 0,
});

