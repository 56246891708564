/**
 *
 * FiscalPeriod
 */

export type FiscalPeriod = {

	// DateCreated int64
	DateCreated: number;

	// DateFrom int64
	DateFrom: number;

	// DateTo int64
	DateTo: number;

	// FiscalPeriodID int64
	FiscalPeriodID: number;

	// FiscalPeriodKey int64
	FiscalPeriodKey: number;

	// FiscalYearID int64
	FiscalYearID: number;

	// IsDeleted int
	IsDeleted: number;

	// Ordinal int64
	Ordinal: number;

	// TotalCalendarDays int64
	TotalCalendarDays: number;

	// TotalFiscalDays int64
	TotalFiscalDays: number;

	// Year int64
	Year: number;

}

// newFiscalPeriod is a factory method for creating new FiscalPeriod objects
export const newFiscalPeriod = () : FiscalPeriod => ({ 
	DateCreated: 0,
	DateFrom: 0,
	DateTo: 0,
	FiscalPeriodID: 0,
	FiscalPeriodKey: 0,
	FiscalYearID: 0,
	IsDeleted: 0,
	Ordinal: 0,
	TotalCalendarDays: 0,
	TotalFiscalDays: 0,
	Year: 0,
});

