import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateUserGroup } from 'gen/routes/UserGroups';
import showCreateGroupModalAtom from 'core/state/users/showCreateGroupModalAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateUserGroupDTO } from 'gen/dtos/CreateUserGroupDTO';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreateUserGroupDialog = () => {

    const [isShowing, setIsShowing] = useRecoilState(showCreateGroupModalAtom);
    const [createUserGroup, { error, status }] = useCreateUserGroup(); 
    const [userGroup, setUserGroup] = useState(newCreateUserGroupDTO()); 
    const save = () => { createUserGroup({ body: userGroup }); }

    useEffect(() => {

        // Close the dialog when the user group has been created
        if(status === "success") {
            setIsShowing(false); 
            setUserGroup(newCreateUserGroupDTO()); 
        }

    }, [status, setIsShowing])


    return <Dialog open={isShowing} onBackdropClick={() => setIsShowing(false)}>
        <DialogTitle>Create a new user group</DialogTitle>
        
        <DialogContent>

            
            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }

            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Description" value={userGroup.Title} onChange={(e) => setUserGroup({ ...userGroup, Title: e.target.value as string })} /> 
            </StyledFormControl>
            
            <StyledFormControl>
                <InputLabel>Description</InputLabel>
                <Input type="text" name="Description" multiline rowsMax={5} rows={5} value={userGroup.Description} onChange={(e) => setUserGroup({...userGroup, Description: e.target.value as string })} /> 
            </StyledFormControl>

        </DialogContent>
        <DialogActions>

            <Button variant="contained" onClick={() => save()} disabled={status === "loading"}>
                
                Create Group

                {status === "loading" && 
                    <CircularProgress size={20} /> 
                }

            </Button>

            <Button onClick={() => setIsShowing(false)} disabled={status === "loading"}>cancel</Button>
            
        </DialogActions>

    </Dialog>
}

export default CreateUserGroupDialog; 