import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import NavBarSecondary from "core/components/NavBarSecondary";
import handleNetworkError from "core/utils/handleNetworkError";
import { useGetTaskBatchFromID } from "gen/routes/Tasks";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { useRecoilValue, useSetRecoilState } from "recoil";
import AdminHeader from "../common/AdminHeader";
import selectedTaskBatchAtom from "./atoms/selectedTaskBatchAtom";
import TaskBatchDetailLogs from "./TaskBatchDetailLogs";
import TaskBatchDetailMain from "./TaskBatchDetailMain";
import TaskBatchDetailSchedules from "./TaskBatchDetailSchedules";
import TaskBatchDetailTasks from "./TaskBatchDetailTasks";

const TaskBatchDetail = () => {

    const { id } = useParams() as { id: string }; 
    const { status, data, error } = useGetTaskBatchFromID(parseInt(id));
    const setSelectedTaskBatch = useSetRecoilState(selectedTaskBatchAtom);
    
    useEffect(() => {
        if(status === "success" && data) {
            setSelectedTaskBatch(data.data)
        }
    }, [status, data, setSelectedTaskBatch]); 

    
    if(status === "error") { 
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <TaskBatchDetailInner /> 
    )

}

const TaskBatchDetailInner = () => {

    const batch = useRecoilValue(selectedTaskBatchAtom); 
    const [tab, setTab] = useState('main'); 

    return <div style={{ height: '100%' }}>
        
        <NavBarSecondary>
            <AdminHeader title={batch.Name} parents={[{ title: "Tasks", url: `/admin/tasks`}]} />
        </NavBarSecondary>

        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab value="main" label="Main" />
            <Tab value="schedules" label={`Schedules (${batch.ScheduleCount})`} />
            <Tab value="tasks" label={`Tasks (${batch.TaskCount})`} />
            <Tab value="logs" label="Logs" />
        </Tabs>
        <div style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
            {tab === "main" && <TaskBatchDetailMain />}
            {tab === "schedules" && <TaskBatchDetailSchedules />}
            {tab === "tasks" && <TaskBatchDetailTasks />}
            {tab === "logs" && <TaskBatchDetailLogs />}
        </div>

    </div>
}



export default TaskBatchDetail; 