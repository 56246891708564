import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel } from '@material-ui/core';
import { useCreateCustomerContact } from 'app/api/customers';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { createCustomerContactAtom, selectedCustomerAtom, showCreateContactModalAtom } from '../quotes/state';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`
const CreateContactModal = () => {
    
    const [customer, setCustomer]  = useRecoilState(selectedCustomerAtom); 
    const [createContact, { status, error }] = useCreateCustomerContact(); 
    const [contact, setContact] = useRecoilState(createCustomerContactAtom); 
    const resetContact = useResetRecoilState(createCustomerContactAtom); 
    const [isCreateModalShowing, setIsCreateModalShowing] = useRecoilState(showCreateContactModalAtom); 
    
    const save = () => {
        
        console.log('Creating contact!!', contact, customer); 

        createContact({ ...contact, CustomerID: customer.CustomerID })
            .then((response) => {

                if(!response) {
                    return; 
                }

                setCustomer({
                    ...customer, 
                    Contacts: [
                        ...customer.Contacts, 
                        response.data, 
                    ]
                })
    
                resetContact(); 
                setIsCreateModalShowing(false); 
            });

    }

    const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact,
            FirstName: event.target.value,
        });
    };

    const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            LastName: event.target.value, 
        })
    }

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Email: event.target.value, 
        })
    }
    
    const onPhoneChanged = (value: string) => {
        setContact({
            ...contact, 
            Phone: value, 
        })
    }

    const onPhoneExtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            PhoneExt: parseInt(event.target.value as string), 
        })
    }
    
    const onCellChanged = (value: string) => {
        setContact({
            ...contact, 
            Cell: value, 
        })
    }

    const onRoleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Role: event.target.value, 
        })
    }

    return <Dialog open={isCreateModalShowing} onBackdropClick={() => setIsCreateModalShowing(false)}>
        
        <DialogTitle>Create a Contact</DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">
                    {handleNetworkError(error)}
                </Alert>
            }

            <StyledFormControl>
                <InputLabel>First Name</InputLabel>
                <Input type="text" name="FirstName" value={contact.FirstName} onChange={onFirstNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Last Name</InputLabel>
                <Input type="text" name="LastName" value={contact.LastName} onChange={onLastNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Role</InputLabel>
                <Input type="text" name="Role" value={contact.Role} onChange={onRoleChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Email</InputLabel>
                <Input type="text" name="Email" value={contact.Email} onChange={onEmailChanged} /> 
            </StyledFormControl>
            
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    Phone: <ReactPhoneInput country="us" value={contact.Phone} onChange={onPhoneChanged} /> 
                </Grid>
                <Grid item xs={4}>
                    Ext. <Input type="number" value={contact.PhoneExt} onChange={onPhoneExtChanged} /> 
                </Grid>
            </Grid>
            Cell: <ReactPhoneInput country="us" onChange={onCellChanged} /> 

                {/* <Input type="text" name="Phone" value={phone} onChange={onPhoneChanged  */}


        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={status === "loading"}>
                Create Contact
                {status === "loading" && 
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button onClick={() => setIsCreateModalShowing(false)} disabled={status === "loading"}>
                Cancel
            </Button>
        </DialogActions>

    </Dialog>
}

export default CreateContactModal; 