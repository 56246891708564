import { CircularProgress, FormControlLabel, IconButton, Switch } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, DTableWrapper, TableNav } from 'core/components/tables';
import { UserLogSummaryAggregate } from 'core/definitions/aggregates/UserLogsSummaryAggregate';
import { UserLogActionTypeAPI, UserLogActionTypeClient } from 'core/definitions/constants/userLogActionTypes';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetUserLogs } from 'gen/routes/AdminActivity';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import AdminHeader from '../common/AdminHeader';

type ActivityListProps = { 
    userID: number; 
} & typeof defaultProps; 

const ActivityList = (props : ActivityListProps)=> {

    const history = useHistory(); 
    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState("DateCreated"); 
    const [orderDir, setOrderDir] = useState("DESC"); 
    const limit = 50; 
    const [activityType, setActivityType] = useState(UserLogActionTypeClient); 
    const [ip, setIP] = useState(0) 

    const { data, status, error } = useGetUserLogs(
        props.userID, 
        limit, 
        page, 
        orderBy, 
        orderDir, 
        activityType, 
        ip); 


    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const logs = data.data; 

    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <NavBarSecondary right={

                <TableNav 
                    {...{ 
                        limit, 
                        page, 
                        count: logs.Count, 
                        prev : () => setPage(page - 1), 
                        next : () => setPage(page + 1)
                    }}
                 />
            }>
                <AdminHeader title={"Logs"} />

                <FormControlLabel value="Client" control={<Switch checked={activityType===UserLogActionTypeAPI} onChange={(e) =>  {
                    setActivityType(e.target.checked ? UserLogActionTypeAPI : UserLogActionTypeClient); 
                }} color="primary" />} label="Client" labelPlacement="start" /> 
                {ip && <>{ip} <IconButton size="small" onClick={() => setIP(0)}><Cancel /></IconButton></>}
            </NavBarSecondary>

            <DTableWrapper>
            
                <DTable 
                    orderBy={orderBy}
                    orderDir={orderDir}
                    cols={[
                        { size: 1, orderFn: orderCol('UserID'), orderCol: "UserID", label: "User", val: (row: UserLogSummaryAggregate) => row.Username, onClick: (row: UserLogSummaryAggregate) => () => history.push(`/admin/users/${row.UserID}`) }, 
                        { size: 1, orderFn: orderCol('DateCreated'), orderCol: "DateCreated", label: "Date", val: (row: UserLogSummaryAggregate) => moment(row.DateCreated).format('DD MMM YYYY hh:mm a') }, 
                        { size: 1, orderFn: orderCol('IP'), orderCol: 'IP', label: 'IP', val: (row: UserLogSummaryAggregate) => row.IP, onClick: (row: UserLogSummaryAggregate) => () => setIP(row.UserLogIPID)}, 
                        { size: 1, orderFn: orderCol('Method'), orderCol: 'Method', label: 'Method', val: (row: UserLogSummaryAggregate) => row.Method}, 
                        { size: 1, orderFn: orderCol('Path'), orderCol: 'Path', label: 'Path', val: (row: UserLogSummaryAggregate) => row.Path}, 
                        { size: 1, orderFn: orderCol('ResponseCode'), orderCol: 'ResponseCode', label: 'Response', val: (row: UserLogSummaryAggregate) => row.ResponseCode}, 
                        { size: 1, orderFn: orderCol('ErrorCode'), orderCol: 'ErrorCode', label: 'Error', val: (row: UserLogSummaryAggregate) => row.ErrorCode}, 
                    ]}
                    data={data.data.Data}
                /> 
            
            </DTableWrapper>

        </div>
    ); 
}

const defaultProps = { 
    userID: 0, 
};

ActivityList.defaultProps = defaultProps; 

export default ActivityList; 

export const ActivityListRoute = () => ActivityList({ userID: 0 }); 