import axios from 'axios';
import routes from 'configs/routes';
import coreRoutes from 'core/routes';
import { initApp } from 'core/utils/routes';
import './App.css';
import config from 'config.json';

// Axios Default BaseURL for
axios.defaults.baseURL = config.apiURL; 
  // process.env.NODE_ENV === "production"
  //   ? process.env.REACT_APP_API_BASE_URL_PROD
  //   : process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers['Content-Type'] = "application/json"

if(window.location.hostname === "airtechrfq.com") { 
  window.document.title = "AirTech RFQ"
} else {
  window.document.title = "JOC RFQ Sales"
}

const App = initApp(coreRoutes.concat(routes)); 

export default App;
