/**
 *
 * Admin.UserGroups
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateUserGroupDTO } from 'gen/dtos/CreateUserGroupDTO';
import { UpdateUserGroupDTO } from 'gen/dtos/UpdateUserGroupDTO';
import { UserGroup } from 'gen/models/UserGroup';

// CreateUserGroup creates a user
// POST /groups
// @permission UserGroups_CreateUserGroup
export const createUserGroup = async (args : { body : CreateUserGroupDTO }) => await axios.post<UserGroup>(`/groups`, args.body);
export const useCreateUserGroup = () => useMutation(createUserGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getUserGroups"]);
	},
	throwOnError: true, 
});
	

// UpdateUserGroup updates a user group
// PUT /groups/{groupID:[0-9]+}
// @permission UserGroups_UpdateUserGroup
export const updateUserGroup = async (args : { groupID : number, body : UpdateUserGroupDTO }) => await axios.put<UserGroup>(`/groups/${args.groupID}`, args.body);
export const useUpdateUserGroup = () => useMutation(updateUserGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getUserGroups"]);
		queryCache.invalidateQueries(["getUserGroup", variables.groupID]);
	},
	throwOnError: true, 
});
	

// DeleteUserGroup deletes a user group
// DELETE /groups/{groupID:[0-9]+}
// @permission UserGroups_DeleteUserGroup
export const deleteUserGroup = async (args : { groupID : number }) => await axios.delete<any>(`/groups/${args.groupID}`);
export const useDeleteUserGroup = () => useMutation(deleteUserGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getUserGroups"]);
		queryCache.invalidateQueries(["getUserGroup", variables.groupID]);
	},
	throwOnError: true, 
});
	

// GetUserGroups returns a collection of user groups
// GET /groups
// @permission UserGroups_GetUserGroups
export const getUserGroups = async () => await axios.get<UserGroup[]>(`/groups`);
export const useGetUserGroups = () => useQuery(["getUserGroups"], (_ : string) => getUserGroups());

// GetUserGroup returns a user group by its id
// GET /groups/{groupID:[0-9]+}
// @permission UserGroups_GetUserGroup
export const getUserGroup = async (groupID : number) => await axios.get<any>(`/groups/${groupID}`);
export const useGetUserGroup = (groupID : number) => useQuery(["getUserGroup", groupID], (_ : string, groupID : number) => getUserGroup(groupID));

// AddPolicyToGroup adds a policy to a group
// POST /admin/groups/{groupID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission UserGroups_AddPolicyToGroup
export const addPolicyToGroup = async (args : { groupID : number, policyID : number }) => await axios.post<any>(`/admin/groups/${args.groupID}/policies/${args.policyID}`, {});
export const useAddPolicyToGroup = () => useMutation(addPolicyToGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getUserGroups"]);
		queryCache.invalidateQueries(["getUserGroup", variables.groupID]);
	},
	throwOnError: true, 
});
	

// RemovePolicyFromGroup removes a policy from a group
// DELETE /admin/groups/{groupID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission UserGroups_RemovePolicyFromGroup
export const removePolicyFromGroup = async (args : { groupID : number, policyID : number }) => await axios.delete<any>(`/admin/groups/${args.groupID}/policies/${args.policyID}`);
export const useRemovePolicyFromGroup = () => useMutation(removePolicyFromGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getUserGroups"]);
		queryCache.invalidateQueries(["getUserGroup", variables.groupID]);
	},
	throwOnError: true, 
});
	
