/**
 *
 * CustomerEvent
 */

export type CustomerEvent = {

	// CustomerEventID int64
	CustomerEventID: number;

	// CustomerID int64
	CustomerID: number;

	// DateCreated int64
	DateCreated: number;

	// Duration int64
	Duration: number;

	// EventDateStart int64
	EventDateStart: number;

	// EventType int64
	EventType: number;

	// IsDeleted int
	IsDeleted: number;

	// Notes null.String
	Notes: string;

	// ProjectID int64
	ProjectID: number;

	// TicketID int64
	TicketID: number;

	// TicketTaskID int64
	TicketTaskID: number;

}

// newCustomerEvent is a factory method for creating new CustomerEvent objects
export const newCustomerEvent = () : CustomerEvent => ({ 
	CustomerEventID: 0,
	CustomerID: 0,
	DateCreated: 0,
	Duration: 0,
	EventDateStart: 0,
	EventType: 0,
	IsDeleted: 0,
	Notes: '',
	ProjectID: 0,
	TicketID: 0,
	TicketTaskID: 0,
});

