import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export const MoneyInput = (props: { 
    value: number, 
    onChange: (value: number) => void, 
    helperText?: string, 
    label?: string, 
    disabled?: boolean,
    percent?: boolean, 
    onBlur?: () => void
}) => {

    const initialValue = props.value.toFixed(2); 

    const [value, setValue] = useState({
        value: initialValue, 
        isInitial: 0, 
    }); 

    const [isValid, setIsValid] = useState(true); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(value.isInitial < 2) {
            setValue({ 
                isInitial: value.isInitial++, 
                value: props.value.toFixed(2), 
            });
        }
    }, [props]); 

    const onFocus = (e) => {
        if(value.value === '0.00') {
            setValue({ ...value, value: '' })
        }
    }

    const onBlur = (e) => {
        
        // String value of number 
        const inputValue = e.target.value as string; 
        const inputValueNumber = parseFloat(inputValue); 

        // 1. Check if this is a valid number 
        const isValidNumber = !isNaN(inputValueNumber)

        // Check if its a floating numbeer
        if(isValidNumber) {
            // Trigger the change in the outer scope 
            props.onChange(parseFloat(inputValueNumber.toFixed(2)))
            setIsValid(true)
        } else {
            setIsValid(false); 
        }   

        // No matter what we update the internal state
        setValue({ ...value, value: inputValue }); 

        if(props.onBlur) {
            props.onBlur(); 
        }
    }

    const onChange = (e) => {
        setValue({ ...value, value: e.target.value as string })
    }

    const onKeyPress = (e : React.KeyboardEvent<HTMLInputElement>) => {
        // const re = /^[0-9]\b]+$/; 
        const charCode = e.which ? e.which : e.keyCode; 
        // 110 = decimal point; 190 = period
        if(!((charCode >= 48 && charCode <= 57) || charCode === 190 || charCode === 110 || charCode === 46)) { 
            console.log(charCode); 
            e.preventDefault(); 
        }
    }

    const inputProps = props.percent ? {
      endAdornment: <InputAdornment position="end">%</InputAdornment>  
    } : {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
    };

    return (
        <TextField
            label={props.label || ''}
            type="number"
            variant="outlined"
            size="small"
            margin="dense"
            error={!isValid}
            InputProps={inputProps}
            value={value.value} 
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
            helperText={props.helperText || ''}
            disabled={props.disabled}
            inputProps={{
                style: { 
                    fontSize: 14,
                    width: 100
                }
            }}
        />
    ); 
}