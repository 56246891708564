import { Vendor } from "gen/models/Vendor";

export type UpdateVendorDTO = {
    VendorID: number; 
    Title: string; 
    Address: string; 
    Address2: string; 
    City: string; 
    State: string; 
    Zip: string; 
    Phone: string; 
    Fax: string; 
}

export const newUpdateVendorDTO = (model: Vendor) : UpdateVendorDTO => ({
    VendorID: model.VendorID, 
    Title: model.Title, 
    Address: model.Address, 
    Address2: model.Address2, 
    City: model.City, 
    State: model.State, 
    Zip: model.Zip, 
    Phone: model.Phone, 
    Fax: model.Fax, 
}); 