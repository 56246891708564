import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Perm } from 'core/utils/hasPerm';
import React, { useState } from 'react';

type Action = { 
    title?: string; 
    fn?: () => void; 
    isDivider?: boolean; 
    perm?: string; 
    show?: () => boolean; 
}

const AdminActions : React.FC<{ actions: Action[] }> = ({ actions }) => { 

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined); 

    return (
        actions.length > 0 ? (
            <>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <MoreVert /> 
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(undefined)}
                >
                    {actions.map((action, key) => { 
                        
                        if(action.isDivider) { 
                            return <Divider /> 
                        }

                        if(action.show !== undefined && !action.show()) { 
                            return null; 
                        }

                        return (
                            <Perm perm={action.perm} key={key}>
                                <MenuItem onClick={() => { 
                                    if(action.fn) { 
                                        action.fn(); 
                                    }
                                    setAnchorEl(undefined); 
                                }}>{action.title}</MenuItem>
                            </Perm>
                        );         
                    })}
                </Menu>
            </>
        ) : null 
    ); 

}

export default AdminActions; 