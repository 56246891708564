import { List, newList } from "gen/models/List"
import { ListItem } from "gen/models/ListItem"

export type ListAggregate = List & {
    Items: Array<ListItem>; 
}

export const newListAggregate = () : ListAggregate => ({
    ...newList(), 
    Items: [], 
})