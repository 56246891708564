
import { CircularProgress } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Account } from 'gen/models/Account';
import { useGetAllAccounts } from 'gen/routes/Accounts';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import AdminHeader from '../common/AdminHeader';

const AccountList = () => {

    const limit = 50; 
    const [page, setPage] = useState(0); 
    const { data, status, error } = useGetAllAccounts(page, limit); 
    // const [addShowing, setAddShowing] = useState(false); 
    

    const history = useHistory(); 
    if(status === "error") { return <Alert color="danger">{handleNetworkError(error)}</Alert> }
    if(status === "loading" || !data) { return <CircularProgress /> }
    
    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <NavBarSecondary right={
                <>
                    <TableNav {
                        ...{count: data.data.Count, prev : () => setPage(page - 1), next : () => setPage(page + 1), page, limit: 20 }
                    } />
                </>

            }>
                <AdminHeader title="Accounts" /> 
            </NavBarSecondary>

            <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
                <DTable
                    onRowClick={(row: Account) => history.push(`/admin/accounts/${row.AccountID}`)}
                    cols={[
                        { label: 'ID', size: .5, val: (v: Account) => v.AccountID }, 
                        { label: 'Title', size: 2.5, val: (v: Account) => v.Title }, 
                        { label: 'Created', size: 1, val: (v: Account) => moment(v.DateCreated).format('M/D/Y') }, 
                        { label: 'Users', size: .5, val: (v: Account) => v.UserCount }, 
                        { label: 'Max Users', size: .5, val: (v: Account) => v.MaxUserCount }, 
                    ]}
                    data={data.data.Data}
                />
            </div>

            {/* <Add action={() => setAddShowing(true)} /> */}
        </div>
    ); 
}

export default AccountList; 

// const UsersList = (props: { 
//     users : Paged<UserDTO>, 
//     next: () => void, 
//     prev: () => void,
//     page: number, 
//     orderBy: string, 
//     orderCol: (fieldName: string) => () => void, 
//     orderDir: string,
// }) => {

    

    

//     const { users, next, prev, page, orderBy, orderCol, orderDir } = props; 

//     return ( 
//         <
//     ); 
// }





