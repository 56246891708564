import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useCreateQuoteRevision, useGetQuoteRevisions } from 'app/api/quotes';
import { QuoteAggregate } from 'app/definitions/aggregates/QuoteAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { selectedQuoteAtom } from './state';

const QuoteRevisions = () => {

    const quote = useRecoilValue(selectedQuoteAtom); 
    const { data, error, status } = useGetQuoteRevisions(quote.QuoteNumberID);
    const history = useHistory(); 
    const [createRevision] = useCreateQuoteRevision(); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 

    }

    const gotoRevision = (quoteID) => () => {
        history.push(`/quotes/${quoteID}`)
        window.location.reload(); 
    }

    const doCreateRevision = (quoteID: number) => () => {
        createRevision({ quoteID: quoteID, quoteNumberID: quote.QuoteNumberID })
            .then(data => {
                const quoteAggregate = data?.data as QuoteAggregate; 
                history.push(`/quotes/${quoteAggregate.QuoteID}`)
            });
    }

    return (
        <Paper style={{ margin: 20, padding: 20 }} elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        
                        <TableCell align="center">Revision</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell align="center">Customers</TableCell>
                        <TableCell align="center">Units</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.data.map((revision, key) => 
                        <TableRow key={key} style={{ backgroundColor: quote.QuoteID === revision.QuoteID ? "#efe" : "inherit"}}>
                            <TableCell align="center">{revision.RevisionNumber}</TableCell>
                            <TableCell>{moment(revision.DateCreated).format()}</TableCell>
                            <TableCell>{revision.CreatedByName}</TableCell>
                            <TableCell align="center">{revision.CustomerCount}</TableCell>
                            <TableCell align="center">{revision.ItemCount}</TableCell>
                            <TableCell>
                                {quote.QuoteID === revision.QuoteID && 
                                    <strong>CURRENT</strong>
                                }
                                {quote.QuoteID !== revision.QuoteID && 
                                    <Button onClick={gotoRevision(revision.QuoteID)}>
                                        View
                                    </Button>
                                }
                                
                            </TableCell>
                            <TableCell>
                                {quote.JobID === 0 && <Button variant="contained" onClick={doCreateRevision(revision.QuoteID)}>New Revision</Button> }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    )

}   

export default QuoteRevisions; 