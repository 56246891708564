/**
 *
 * FormFieldGroupDTO
 */

export type FormFieldGroupDTO = {

	// Description string
	Description: string;

	// FieldCount int64
	FieldCount: number;

	// FormFieldGroupID int64
	FormFieldGroupID: number;

	// Ordinal int64
	Ordinal: number;

	// Title string
	Title: string;

}

// newFormFieldGroupDTO is a factory method for new FormFieldGroupDTO objects
export const newFormFieldGroupDTO = () : FormFieldGroupDTO => ({
	Description: '',
	FieldCount: 0,
	FormFieldGroupID: 0,
	Ordinal: 0,
	Title: '',
});

