import { Button, CircularProgress, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import { Add, Cancel, Delete, Edit, Launch } from '@material-ui/icons';
import { useGetCustomerFromID } from 'app/api/customers';
import { QuoteCustomerAggregate } from 'app/definitions/aggregates/QuoteCustomerAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import { CustomerContact } from 'gen/models/CustomerContact';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { selectQuoteCustomerContactCustomerIDAtom, showCreateQuoteCustomerContactDialogAtom } from '../state';
import { selectedQuoteAtom, selectedQuoteCustomerAtom } from './state';



const QuoteCustomerCard : React.FC<{ 
    customer: QuoteCustomerAggregate, 
    contact?: boolean 
}> = ({ 
    customer, 
    contact 
})   => {
    
    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const selectCustomer = useSetRecoilState(selectedQuoteCustomerAtom); 
    const showCreateContact = useSetRecoilState(showCreateQuoteCustomerContactDialogAtom);
    const [showSelectContact, setShowSelectContact] = useRecoilState(selectQuoteCustomerContactCustomerIDAtom); 

    const create = () => {
        selectCustomer(customer); 
        showCreateContact(true); 
    }

    const showSelectContactUI = () => {
        selectCustomer(customer); 
        setShowSelectContact(customer.CustomerID)
    }
    
    const gotoCustomer = () => window.open(`/app/customers/${customer.CustomerID}`)

    return (
        <Card elevation={3} style={{ backgroundColor: (quote.SelectedCustomerID === customer.CustomerID ? '#efe' : 'inherit'), border: (quote.SelectedCustomerID === customer.CustomerID ? 'solid 3px #060' : 'inherit')}}>
            <Customer>
                <CustomerTitle>{customer.Customer.Title}</CustomerTitle>
                {customer.Customer.Address && <div>{customer.Customer.Address}</div>}
                {customer.Customer.Address2 && <div>{customer.Customer.Address2}</div>}
                <div>{customer.Customer.City} {(customer.Customer.City && customer.Customer.State) && <>,</>} {customer.Customer.State} {customer.Customer.Zip}</div>
                <div>{customer.Customer.Phone && <>P: {customer.Customer.Phone}</>}</div>
                {customer.Customer.Fax && <div>F: {customer.Customer.Fax}</div>}
            </Customer>
            
            {/* {!!contact &&  */}
                <Contact>
                    {showSelectContact !== customer.CustomerID ?
                        <div>
                            {customer.CustomerContactID === 0 ? 
                                <div>
                                    <Alert color={!!contact ? "danger" : "info"}>No Contact Specified.</Alert>
                                </div>
                            : ( 
                                <div style={{ position: 'relative' }}>
                                    <IconButton size="small" style={{ position: 'absolute', top: 0, right: 0, opacity: .3 }} onClick={() => {
                                        setQuote({
                                            ...quote, 
                                            Customers: quote.Customers.map((c) => { 
                                               if(customer.CustomerID === c.CustomerID) { 
                                                    return { ...customer, CustomerContactID: 0 }
                                               } 
                                               return c; 
                                            })
                                        })
                                    }}>
                                        <Cancel style={{ height: 15, width: 15 }} /> 
                                    </IconButton>
                                    <div><strong>{customer.Contact.FirstName} {customer.Contact.LastName}</strong></div>
                                    <div><i>{customer.Contact.Role}</i></div>
                                    <div>{customer.Contact.Email}</div>
                                    <div>{customer.Contact.Phone} {customer.Contact.PhoneExt && <span>Ext. {customer.Contact.PhoneExt}</span>}</div>
                                </div>
                            )}
                        </div>
                        :
                        <SelectCustomerContact /> 
                    }
                </Contact>
            {/* } */}

            <CardFooter>

                {(quote.JobID === 0 && customer.Customer.ContactCount > 0) && <Tooltip title="Set Customer Contact"><IconButton onClick={showSelectContactUI}><Edit /></IconButton></Tooltip>}
                
                {quote.JobID === 0 && <Tooltip title="Create a New Contact"><IconButton onClick={create}><Add /></IconButton></Tooltip>}

                <IconButton onClick={gotoCustomer}>
                    <Tooltip title="Customer Information"><Launch /></Tooltip> 
                </IconButton>
                {quote.JobID === 0 && 
                    <Tooltip title="Remove Customer From Quote">
                        <IconButton onClick={() => {
                            setQuote({
                                ...quote, 
                                CustomerCount: quote.CustomerCount - 1, 
                                Customers: quote.Customers.filter(x => x.CustomerID !== customer.CustomerID)
                            })
                        }}>
                            <Delete /> 
                        </IconButton>
                    </Tooltip>
                }

            </CardFooter>
        </Card>
    )
}

const SelectCustomerContact = () => {
    
    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const [customerID, setCustomerID] = useRecoilState(selectQuoteCustomerContactCustomerIDAtom); 

    const { data, error, status } = useGetCustomerFromID(customerID); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const customer = data.data; 

    const selectContact = (contactID: number) => {

        const selectedContact = customer.Contacts.find(x => x.CustomerContactID === contactID) as CustomerContact; 
        const idx = quote.Customers.findIndex(x => x.CustomerID === customer.CustomerID); 

        setQuote({
            ...quote, 
            Customers: Object.assign(
                [...quote.Customers], 
                {[idx]: {
                    ...quote.Customers[idx],
                    Customer: {
                        ...quote.Customers[idx].Customer, 
                        ContactCount: quote.Customers[idx].Customer.ContactCount + 1, 
                    },  
                    CustomerContactID: selectedContact.CustomerContactID, 
                    Contact: selectedContact, 
                }}
            )
        });

        close(); 
    }

    const close = () => {
        setCustomerID(0); 
    }

    return (
        <div>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Select</InputLabel>
                <Select fullWidth onChange={(e) => selectContact(parseInt(e.target.value as string))}>
                    {customer.Contacts.map((contact, key) => 
                        <MenuItem value={contact.CustomerContactID} key={key}>
                            {contact.FirstName} {contact.LastName}
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>Select Contact...</FormHelperText>
            </FormControl>
            <Button onClick={close}>
                cancel
            </Button>
        </div>
    );

}

const Card = styled(Paper)` 
    width: 250px; 
    min-width: 250px; 
    min-height: 300px; 
    padding: 10px; 
    position: relative; 
    margin-right: 20px; 
`; 

const Customer = styled.div`
    padding: 5px; 
    height: 150px; 
`; 

const CustomerTitle = styled.div`
    font-size: 16px; 
    font-weight: bold; 
    color: #555; 
`; 

const Contact = styled.div`
    padding: 5px; 
    height: 150px; 
    border-top: solid 1px rgba(0, 0, 0, .2);
`; 

const CardFooter = styled.div`
    position: absolute; 
    bottom: 0; 
    left: 0; 
    width: 100%; 
    height: 48px; 
    border-top: dashed 1px rgba(0, 0, 0, .5); 
    display: flex; 
    justify-content: flex-end; 
`; 

export default QuoteCustomerCard; 