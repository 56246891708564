/**
 *
 * UserDevice
 */

export type UserDevice = {

	// DateCreated int64
	DateCreated: number;

	// DateRegistered int64
	DateRegistered: number;

	// IsDeleted int
	IsDeleted: number;

	// Key string
	Key: string;

	// LastUsed int64
	LastUsed: number;

	// UserAgent string
	UserAgent: string;

	// UserDeviceID int64
	UserDeviceID: number;

	// UserID int64
	UserID: number;

}

// newUserDevice is a factory method for creating new UserDevice objects
export const newUserDevice = () : UserDevice => ({ 
	DateCreated: 0,
	DateRegistered: 0,
	IsDeleted: 0,
	Key: '',
	LastUsed: 0,
	UserAgent: '',
	UserDeviceID: 0,
	UserID: 0,
});

