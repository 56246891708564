import { CustomerAggregate, newCustomerAggregate } from "app/definitions/aggregates/CustomerAggregate";
import { newQuoteAggregate, QuoteAggregate } from "app/definitions/aggregates/QuoteAggregate";
import { newQuoteCustomerAggregate, QuoteCustomerAggregate } from "app/definitions/aggregates/QuoteCustomerAggregate";
import { newQuoteSearchAggregate, QuoteSearchAggregate } from "app/definitions/aggregates/QuoteSearchAggregate";
import { ListAggregate, newListAggregate } from "core/definitions/aggregates/ListAggregate";
import { CreateQuoteDTO, newCreateQuoteDTO } from 'gen/dtos/CreateQuoteDTO';
import { atom } from 'recoil';


// Bid Types 
export const bidTypesAtom = atom<ListAggregate>({
    key: "bidTypesAtom", 
    default: newListAggregate(), 
}); 

// Create Quote 
export const createQuoteAtom = atom<CreateQuoteDTO> ({
    key: "createQuoteAtom", 
    default: newCreateQuoteDTO(), 
})

// Item Measurement Type 
export const itemMeasurementTypeAtom = atom<ListAggregate>({
    key: "itemMeasurementTypeAtom", 
    default: newListAggregate(), 
}); 

// Markets 
export const marketsAtom = atom<ListAggregate>({
    key: "marketsAtom", 
    default: newListAggregate(), 
}); 

// Probabilities 
export const probabilitiesAtom = atom<ListAggregate>({
    key: "probabilitiesAtom", 
    default: newListAggregate(), 
}); 

// QuoteFilterPanelShowing
export const quoteFilterPanelShowingAtom = atom<boolean> ({
    key: "quoteFilterPanelShowingAtom", 
    
    default: false, 
})

// QuoteSearchAtom 
export const quoteSearchAtom = atom<QuoteSearchAggregate> ({
    key: "quoteSearchAtom", 
    default: newQuoteSearchAggregate(), 
})

// QuoteStatuses
export const quoteStatusesAtom = atom<ListAggregate>({
    key: "quoteStatusesAtom", 
    default: newListAggregate(), 
}); 

export const quoteTypesAtom = atom<ListAggregate>({
    key: "quoteTypesAtom", 
    default: newListAggregate(), 
}); 

export const searchQuoteTabAtom = atom<number> ({
    key: "searchQuoteTabAtom", 
    default: 0
})

export const selectedCreateQuoteCustomerAtom = atom<CustomerAggregate>({
    key: "selectedCreateQuoteCustomerAtom", 
    default: newCustomerAggregate(), 
})

export const selectedQuoteAtom = atom<QuoteAggregate>({
    key: "selectedQuoteAtom", 
    default: newQuoteAggregate(), 
}); 

export const selectedQuoteCustomerAtom = atom<QuoteCustomerAggregate>({
  key: "selectedQuoteCustomerAtom",
  default: newQuoteCustomerAggregate(), 
});

export const showCreateJobDialogAtom = atom({
  key: "showCreateJobDialogAtom",
  default: false,
});

export const showCreateQuoteCustomerOfTypeDialogAtom = atom<number>({
  key: "showCreateQuoteCustomerDialogAtom",
  default: 0,
});

