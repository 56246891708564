import { CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { PaperContainer } from 'core/components/paperContainer';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { UserGroups_ViewPermission } from 'gen/constants/permissions';
import { useGetUserGroups } from 'gen/routes/UserGroups';
import { useAddUserToGroup, useRemoveUserFromGroup } from 'gen/routes/Users';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';

const UserGroups = () => {

    const [user] = useRecoilState(selectedUserAtom); 

    const { data: groups, status: groupsStatus, error: rolesError, } = useGetUserGroups(); 
    const [addUserToGroup, { error: addError, status: addStatus }] = useAddUserToGroup();
    const [removeUserFromGroup, { error: removeError, status: removeStatus }] = useRemoveUserFromGroup();
    
    const doAddUserToGroup = (groupID: number) => () => addUserToGroup({ userID: user.UserID, groupID: groupID }); 
    const doRemoveUserFromGroup = (groupID: number) => () => removeUserFromGroup({ userID: user.UserID, groupID: groupID }); 

    if(groupsStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(rolesError)}
        </Alert>
    }

    if(groupsStatus === "loading" || !groups) {
        return <CircularProgress /> 
    }

    const addedGroupIDs = user.Groups.map(x => x.UserGroupID);
    const filteredGroups = groups.data.filter(x => addedGroupIDs.indexOf(x.UserGroupID) === -1); 

    
    return (
        <PaperContainer>

            {addStatus === "error" && 
                <Alert color="danger">
                    {handleNetworkError(addError)}
                </Alert>
            }

            {removeStatus === "error" && 
                <Alert color="danger">
                    {handleNetworkError(removeError)}
                </Alert>
            }

            {addStatus === "success" && 
                <Alert color="success">
                    User added to group.            
                </Alert>
            }

            {removeStatus === "success" && 
                <Alert color="success">
                    User removed from group.
                </Alert>
            }
            <Grid container spacing={6}>
                <Grid item xs={5}>
                    <List>
                        {filteredGroups.map((group, k) => 
                            <ListItem>
                                <ListItemText 
                                    primary={group.Title}
                                    secondary={`${group.Description}`}
                                /> 
                                <ListItemSecondaryAction>
                                    <IconButton disabled={addStatus === "loading"} onClick={doAddUserToGroup(group.UserGroupID)}>
                                        <Add /> 
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    <Perm perm={UserGroups_ViewPermission}>
                        <Link to="/admin/groups">Manage User Groups</Link>
                    </Perm>
                </Grid>
                <Grid item xs={7}>
                    <List>
                        {user.Groups.map((group, k) => 
                            <ListItem key={k}>
                                <ListItemText primary={group.Title} secondary={group.Description} /> 
                                <ListItemSecondaryAction>
                                    <IconButton onClick={doRemoveUserFromGroup(group.UserGroupID)}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>

        </PaperContainer>
    ); 
}

export default UserGroups; 