/**
 *
 * Admin.Permissions
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';

// GetAllPermissions returns a slice of permissions
// GET /permissions
// @permission Permissions_GetAllPermissions
export const getAllPermissions = async () => await axios.get<string[][]>(`/permissions`);
export const useGetAllPermissions = () => useQuery(["getAllPermissions"], (_ : string) => getAllPermissions());
