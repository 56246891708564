import { CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { selectedUserAtom } from 'core/state/users/selectedUserAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import userService from 'core/utils/userService';
import { Policies_ViewPermission, Roles_ViewPermission } from 'gen/constants/permissions';
import { Policy } from 'gen/models/Policy';
import { Role } from 'gen/models/Role';
import { useGetRoles } from 'gen/routes/Roles';
import { useAddRoleToUser, useRemoveRoleFromUser } from 'gen/routes/Users';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';

const UserRoles = () => {

    const user = useRecoilValue(selectedUserAtom); 
    const history = useHistory(); 

    const { data: roles, status: rolesStatus, error: rolesError, } = useGetRoles(0, 'Name', 'ASC'); 
    const [addRoleToUser, { error: addRoleError, status: addRoleStatus }] = useAddRoleToUser();
    const [removeRoleFromUser, { error: removeRoleError, status: removeRoleStatus }] = useRemoveRoleFromUser();
       
    const doAddRoleToUser = (roleID: number) => () => {
        addRoleToUser({ userID: user.UserID, roleID: roleID }); 
    }

    const gotoRole = (role: Role) => () => {

        if(!userService.hasPerm(Roles_ViewPermission)) { 
            return false; 
        }

        history.push(`/admin/roles/${role.RoleID}`)
    }

    const gotoPolicy = (policy: Policy) => () => {

        if(!userService.hasPerm(Policies_ViewPermission)) { 
            return false; 
        }

        history.push(`/admin/policies/${policy.PolicyID}`)
    }

    const doRemoveRoleFromUser = (roleID: number) => () => {
        removeRoleFromUser({ userID: user.UserID, roleID: roleID }); 
    }

    if(rolesStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(rolesError)}
        </Alert>
    }

    if(rolesStatus === "loading" || !roles) {
        return <CircularProgress /> 
    }
    
    const addedRoleIDs = user.Roles.map(x => x.RoleID); 
    const filteredRoles = roles.data.Data.filter(x => addedRoleIDs.indexOf(x.RoleID) === -1);

    return (
        <div style={{ height: 'calc(100% - 68px)', padding: '0px 20px', overflow: 'hidden' }}>

            {addRoleStatus === "error" && 
                <Alert color="danger">
                    {handleNetworkError(addRoleError)}
                </Alert>
            }

            {removeRoleStatus === "error" && 
                <Alert color="danger">
                    {handleNetworkError(removeRoleError)}
                </Alert>
            }

            {addRoleStatus === "success" && 
                <Alert color="success">
                    Role added to user.
                </Alert>
            }

            {removeRoleStatus === "success" && 
                <Alert color="success">  
                    Role removed from user.
                </Alert>
            }

            <Grid container spacing={1} style={{ height: '100%', overflow: 'hidden'}}>
                <Grid item xs={3}>
                    <h3>Available Roles</h3>
                    <List>
                        {filteredRoles.map((role, k) => 
                            <ListItem button key={k}>
                                <ListItemText 
                                    primary={role.Name} 
                                    secondary={`${role.PolicyCount} policies`}
                                    onClick={gotoRole(role)}
                                /> 
                                <ListItemSecondaryAction>
                                    <IconButton disabled={addRoleStatus === "loading"} onClick={doAddRoleToUser(role.RoleID)}>
                                        <Add /> 
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    
                    <Perm perm={Roles_ViewPermission}>
                        <Link to="/admin/roles">Manage Roles</Link>
                    </Perm>

                </Grid>
                <Grid item xs={3}>
                    <h3>Assigned Roles</h3>
                    <List>
                        {user.Roles.map((role, k) => 
                            <ListItem button key={k}>
                                <ListItemText 
                                    primary={role.Name} 
                                    secondary={`${role.PolicyCount} policies | ${role.PermissionCount} permissions`} 
                                    onClick={gotoRole(role)} 
                                /> 
                                <ListItemSecondaryAction>
                                    <IconButton onClick={doRemoveRoleFromUser(role.RoleID)}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </Grid>
                
                <Grid item xs={3}>
                    <h3>Assigned Policies</h3>
                    <List>
                        {user.Policies.map((policy, key) => 
                            <ListItem key={key} button onClick={gotoPolicy(policy)}>
                                <ListItemText primary={policy.Title} secondary={policy.ShortName} /> 
                            </ListItem>
                        )}
                    </List>
                    <Perm perm={Policies_ViewPermission}>
                        <Link to="/admin/policies">Manage Policies</Link>
                    </Perm>
                </Grid>

                <Grid item xs={3} style={{ overflow: 'hidden', height: '100%' }}>
                    <h3>Assigned Permissions</h3>
                    <List style={{ overflowY: 'auto', height: 'calc(100% - 20px)'}}>
                        {user.PermissionNames.map((permissionName, key) => 
                            <ListItem key={key}>
                                <ListItemText primary={permissionName} /> 
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>


        </div>
    ); 
}

export default UserRoles; 