import { SearchQuoteDTO } from "./SearchQuoteDTO"

export type CreateQuoteSearchDTO = {
    Title: string; 
    Search: SearchQuoteDTO; 
    IsDefault: number; 
}

export const newCreateQuoteSearchDTO = (title: string, search: SearchQuoteDTO, isDefault: number) : CreateQuoteSearchDTO => ({
    Title: title, 
    Search: search, 
    IsDefault: isDefault, 
})