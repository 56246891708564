import { newUser, User } from "gen/models/User"

export type QuoteSalesAggregate = User & {
    QuoteSalesID: number; 
    Commission: number; 
}

export const newQuoteSalesAggregate = (commission: number) : QuoteSalesAggregate => ({
    ...newUser(), 
    QuoteSalesID: 0, 
    Commission: commission, 
})

export const newQuoteSalesAggregateFromUserProfile = (user: User, quoteSalesID: number, commission: number) : QuoteSalesAggregate => ({
    ...user, 
    Commission: commission, 
    QuoteSalesID: quoteSalesID, 
})