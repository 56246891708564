/**
 *
 * CreateTaskBatchTaskDTO
 */

export type CreateTaskBatchTaskDTO = {

	// BatchOrdinal int
	BatchOrdinal: number;

	// ContinueOnFailure int
	ContinueOnFailure: number;

	// IsActive int
	IsActive: number;

	// Name string
	Name: string;

}

// newCreateTaskBatchTaskDTO is a factory method for new CreateTaskBatchTaskDTO objects
export const newCreateTaskBatchTaskDTO = () : CreateTaskBatchTaskDTO => ({
	BatchOrdinal: 0,
	ContinueOnFailure: 0,
	IsActive: 0,
	Name: '',
});

