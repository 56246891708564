import { JobAggregate } from "app/definitions/aggregates/JobAggregate";
import { JobsAggregate } from "app/definitions/aggregates/JobsAggregate";
import { JobSearchAggregate } from "app/definitions/aggregates/JobSearchAggregate";
import { CreateJobSearchDTO } from "app/definitions/dtos/CreateJobSearchDTO";
import { SearchJobDTO } from "app/definitions/dtos/SearchJobDTO";
import { UpdateJobDTO } from "app/definitions/dtos/UpdateJobDTO";
import { UpdateJobSearchDTO } from "app/definitions/dtos/UpdateJobSearchDTO";
import axios from "axios";
import { CalendarEvent } from "core/components/calendar";
import { Paged } from "core/definitions/utils/Paged";
import { SearchResultsAggregate } from "gen/aggregates/SearchResultsAggregate";
import download from "downloadjs";
import { CreateJobDTO } from "gen/dtos/CreateJobDTO";
import { queryCache, useMutation, useQuery } from "react-query";

//#region Jobs

// Create Job 
export const createJob = async (request: CreateJobDTO) => await axios.post<JobAggregate>("/jobs",request);
export const useCreateJob = () => useMutation(createJob, {
  onSuccess: (data, variables) => {
    queryCache.invalidateQueries(["getQuoteFromID", variables.QuoteID])
  }
}); 

export const deleteJob = async (args: { jobID: number, quoteID: number }) => await axios.delete<any>(`/jobs/${args.jobID}`);
export const useDeleteJob = () => useMutation(deleteJob, { 
  onSuccess: (data, variables) => {
    queryCache.invalidateQueries(["getQuoteFromID", variables.quoteID])
  }
})

// Update Job 
export const updateJob = async (request: UpdateJobDTO) => await axios.put<JobAggregate>(
  `/jobs/${request.JobID}`, 
  request
)
export const useUpdateJob = () => useMutation(updateJob, {
  onSuccess: (data, variables) => {
    queryCache.invalidateQueries(["getJobFromID", variables.JobID])
  }
}); 

// Get Jobs 
export const getJobs = async (_: string, query: SearchJobDTO) => await axios.post<JobsAggregate>(`/jobs/list`, query);
export const useGetJobs = (query: SearchJobDTO) => useQuery(["getJobs", query], getJobs); 

// GetJobFromID
export const getJobFromID = async (_: string, jobID: number) => await axios.get<JobAggregate>(`/jobs/${jobID}`);
export const useGetJobFromID = (jobID: number) => useQuery(["getJobFromID", jobID], getJobFromID, { refetchOnWindowFocus: false }); 

// Search Jobs 
export const searchJobs = async (searchString: string) => await axios.get<SearchResultsAggregate>(`/jobs?search=${searchString}`);

//#endregion Jobs

//#region Saved Searches 

// Get Jobs from Saved Search 
export const getJobsWithSavedSearch = async (_: string, savedSearchID: number) => await axios.get<Paged<JobAggregate>>(`/jobs/list/saved/${savedSearchID}`)
export const useGetQuotesWithSavedSearch = (savedSearchID: number) => useQuery(["getJobsWithSavedSearch", savedSearchID], getJobsWithSavedSearch); 
  

// Get Job Searches 
export const getJobSearches = async (_: string) => await axios.get<JobSearchAggregate[]>(`/jobs/searches`);
export const useGetJobSearches = () => useQuery("getJobSearches", getJobSearches); 

// Get default job Search 
export const getDefaultJobSearch = async (_: string) => await axios.get<JobSearchAggregate>(`/jobs/searches/default`);
export const useGetDefaultJobSearch = () => useQuery("getDefaultJobSearch", getDefaultJobSearch); 


// Create Job Search
export const createJobSearch = async (body: CreateJobSearchDTO) => await axios.post<JobSearchAggregate>(`/jobs/searches`, body)
export const useCreateJobSearch = () => useMutation(createJobSearch, { onSuccess: (data, variables) => {
  queryCache.invalidateQueries("getJobSearches")
}})

// Update Job Search 
export const updateJobSearch = async (body: UpdateJobSearchDTO) => await axios.put<JobSearchAggregate>(`/jobs/searches/${body.ObjectSearchID}`, body)
export const useUpdateJobSearch = () => useMutation(updateJobSearch, { onSuccess: (data, variables) => { 
  queryCache.invalidateQueries("getJobSearches"); 
}})

// Delete Job Search
export const deleteJobSearch = async (objectSearchID: number) => await axios.delete<any>(`/jobs/searches/${objectSearchID}`)
export const useDeleteJobSearch = () => useMutation(deleteJobSearch, { onSuccess: (data, variables) => {
  queryCache.invalidateQueries("getJobSearches")
}})

//#endregion Saved Searches 


export const downloadJobsExcel = async (search: SearchJobDTO) => await axios.post(`/jobs/download/excel`, search, {
  responseType: 'blob', 
})
  .then((resp) => {
    download(resp.data, "jobs.xlsx", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
  });


  export const downloadJobsPDF = async (search: SearchJobDTO) => await axios.post(`/jobs/download/pdf`, search, {
    responseType: 'blob', 
  })
    .then((resp) => {
      download(resp.data, "jobs.pdf", resp.headers['content-type']); // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
    });
  

  export const downloadJobExcel = async (jobID: number) => await axios.get(`/jobs/${jobID}/download/excel`, { 
    responseType: 'blob', 
  }).then((resp) => { 
    download(resp.data, `job-${jobID}.xlsx`, resp.headers["content-type"]); 
  })

// GetJobDatesForCalendar 
export const GetJobDatesForCalendar = async (year : number, month: number) => await axios.get<CalendarEvent[]>(`/jobs/calendar?year=${year}&month=${month}`); 