/**
 *
 * TaskBatch
 */

export type TaskBatch = {

	// CurrentStatus int
	CurrentStatus: number;

	// DateCreated int64
	DateCreated: number;

	// IsActive int
	IsActive: number;

	// IsDeleted int
	IsDeleted: number;

	// LastDuration int64
	LastDuration: number;

	// LastErrorDate int64
	LastErrorDate: number;

	// LastRunDate int64
	LastRunDate: number;

	// LastSuccessDate int64
	LastSuccessDate: number;

	// Name string
	Name: string;

	// NumAttempted int64
	NumAttempted: number;

	// NumFailed int64
	NumFailed: number;

	// NumFinished int64
	NumFinished: number;

	// ScheduleCount int
	ScheduleCount: number;

	// SendEmailStatus int
	SendEmailStatus: number;

	// SendEmailStatusUserGroupID int64
	SendEmailStatusUserGroupID: number;

	// TaskBatchID int64
	TaskBatchID: number;

	// TaskCount int
	TaskCount: number;

}

// newTaskBatch is a factory method for creating new TaskBatch objects
export const newTaskBatch = () : TaskBatch => ({ 
	CurrentStatus: 0,
	DateCreated: 0,
	IsActive: 0,
	IsDeleted: 0,
	LastDuration: 0,
	LastErrorDate: 0,
	LastRunDate: 0,
	LastSuccessDate: 0,
	Name: '',
	NumAttempted: 0,
	NumFailed: 0,
	NumFinished: 0,
	ScheduleCount: 0,
	SendEmailStatus: 0,
	SendEmailStatusUserGroupID: 0,
	TaskBatchID: 0,
	TaskCount: 0,
});

