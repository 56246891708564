import { SearchJobDTO } from "./SearchJobDTO"

export type CreateJobSearchDTO = {
    Title: string; 
    Search: SearchJobDTO; 
    IsDefault: number; 
}

export const newCreateJobSearchDTO = (title: string, search: SearchJobDTO, isDefault: number) : CreateJobSearchDTO => ({
    Title: title, 
    Search: search, 
    IsDefault: isDefault, 
})