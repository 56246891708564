import React from 'react';
import { useRecoilValue } from 'recoil';
import selectedTaskBatchAtom from './atoms/selectedTaskBatchAtom';

const TaskBatchDetailMain = () => {
    
    const batch = useRecoilValue(selectedTaskBatchAtom); 
    
    return <div>
        <div>Name: {batch.Name}</div>
    </div>
}

export default TaskBatchDetailMain; 
