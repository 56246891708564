import { ObjectTypeJob } from 'core/definitions/constants/objectTypes';
import { useSelf } from 'core/utils/userUtils';
import React from 'react'; 
import ObjectTodos from './ObjectTodos';

const MyTodos = () => { 

    const currentUser = useSelf(); 

    if(!currentUser) { 
        return <div>??</div>
    }

    return (
        <div style={{ height: '100%', overflowY: 'auto' }}>
            <ObjectTodos assignedTo={currentUser.UserID} objectType={ObjectTypeJob} objectID={0} rowLimit={50} />
        </div>
    ); 

}

export default MyTodos; 