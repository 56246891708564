import { Button, Checkbox, CircularProgress, IconButton, Switch, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { Cancel, Delete } from '@material-ui/icons';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateTaskBatchTaskDTO } from 'gen/dtos/CreateTaskBatchTaskDTO';
import { UpdateTaskBatchTaskDTO } from 'gen/dtos/UpdateTaskBatchTaskDTO';
import { Task } from 'gen/models/Task';
import { useCreateTaskBatchTask, useDeleteTaskBatchTask, useGetTaskBatchTasksByTaskBatch, useUpdateTaskBatchTask } from 'gen/routes/Tasks';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import selectedTaskBatchAtom from './atoms/selectedTaskBatchAtom';

export const newUpdateTaskBatchTaskDTOFromModel = (model: Task) : UpdateTaskBatchTaskDTO => ({    
    Name: model.Name, 
    BatchOrdinal: model.BatchOrdinal, 
    IsActive: model.IsActive, 
    ContinueOnFailure: model.ContinueOnFailure, 

})

const TaskBatchDetailTasks = () => {
    
    const batch = useRecoilValue(selectedTaskBatchAtom); 
    const { data, error, status } = useGetTaskBatchTasksByTaskBatch(batch.TaskBatchID)
    const [createTask] = useCreateTaskBatchTask(); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const createSchedule = () => {
        createTask({
            taskBatchID: batch.TaskBatchID, 
            body: { 
                ...newCreateTaskBatchTaskDTO(), 
                BatchOrdinal: data.data.length, 
            }
        })
    }

    return <div>

        <Button onClick={createSchedule}>Create Task</Button>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Continue On Failure</TableCell>
                    <TableCell>Ordinal</TableCell>
                    <TableCell>Last Success</TableCell>
                    <TableCell>Last Failure</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.data.map((task, key) => 
                    <TaskRow task={task} key={key} /> 
                )}
            </TableBody>
        </Table>

    </div>
    
}

const TaskRow = (props: { task : Task }) => {

    // const [hourMenuOpen, setHourMenuOpen] = useState(false); 
    // const hourAnchorRef = useRef<HTMLButtonElement>(null); 
    const [update] = useUpdateTaskBatchTask(); 
    const [deleteTask] = useDeleteTaskBatchTask(); 
    const [deleteStarted, setDeleteStarted] = useState(false); 
    const deleteStart = () => {
        setDeleteStarted(true); 
    }
    const deleteCancel = () => setDeleteStarted(false); 
    const deleteFinish = () => deleteTask({ taskBatchID: props.task.TaskBatchID, taskID: props.task.TaskID }).then(() => setDeleteStarted(false))
    
    // const [s, setS] = useState(newUpdateTaskBatchScheduleDTOFromModel(props.schedule)); 
  

    return (
        <TableRow>
            <TableCell>{props.task.TaskID}</TableCell>
            <TableCell>
                {props.task.Name}
            </TableCell>
            <TableCell><Switch checked={props.task.IsActive === 1} onChange={(e) => update({ taskBatchID: props.task.TaskBatchID, taskID: props.task.TaskID, body: { ...newUpdateTaskBatchTaskDTOFromModel(props.task), IsActive: e.target.checked ? 1 : 0 }})} /></TableCell>
            <TableCell>
                <Checkbox checked={props.task.ContinueOnFailure === 1} onChange={(e) => update({ taskBatchID: props.task.TaskBatchID, taskID: props.task.TaskID, body: { ...newUpdateTaskBatchTaskDTOFromModel(props.task), ContinueOnFailure: e.target.checked ? 1 : 0 }})} /> 
            </TableCell>
            <TableCell>{props.task.BatchOrdinal}</TableCell>
           
            <TableCell>{props.task.LastSuccessDate === 0 ? 'never' : moment(props.task.LastSuccessDate).fromNow()}</TableCell>
            <TableCell>{props.task.LastErrorDate === 0 ? 'never' : moment(props.task.LastErrorDate).fromNow()}</TableCell>
            <TableCell>
                <span style={{ color: '#444', fontWeight: 'bold' }}>{props.task.NumAttempted}</span> / <span style={{ color: '#900', fontWeight: 'bold' }}>{props.task.NumFailed}</span> / <span style={{ color: '#090', fontWeight: 'bold' }}>{props.task.NumFinished}</span>
            </TableCell>
            <TableCell>
                {!deleteStarted && 
                    <IconButton onClick={deleteStart}>
                        <Delete /> 
                    </IconButton>
                }
                {deleteStarted && 
                    <>
                        <IconButton onClick={deleteFinish}>
                            <Delete style={{ color: 'red' }} /> 
                        </IconButton>

                        <IconButton onClick={deleteCancel}>
                            <Cancel /> 
                        </IconButton>
                    </>
                }
            </TableCell>
        </TableRow>

    )
}

export default TaskBatchDetailTasks; 
