/**
 *
 * QuoteItem
 */

export type QuoteItem = {

	// DateCreated int64
	DateCreated: number;

	// Description string
	Description: string;

	// IsDeleted int
	IsDeleted: number;

	// JobID int64
	JobID: number;

	// MeasurementQty int64
	MeasurementQty: number;

	// MeasurementType int64
	MeasurementType: number;

	// Qty int64
	Qty: number;

	// QuoteID int64
	QuoteID: number;

	// QuoteItemID int64
	QuoteItemID: number;

	// QuoteNumberID int64
	QuoteNumberID: number;

	// Title string
	Title: string;

	// TotalCost float64
	TotalCost: number;

	// TotalPrice float64
	TotalPrice: number;

	// VendorID int64
	VendorID: number;

	// VendorItemCost float64
	VendorItemCost: number;

	// VendorItemID int64
	VendorItemID: number;

	// VendorItemPrice float64
	VendorItemPrice: number;

}

// newQuoteItem is a factory method for creating new QuoteItem objects
export const newQuoteItem = () : QuoteItem => ({ 
	DateCreated: 0,
	Description: '',
	IsDeleted: 0,
	JobID: 0,
	MeasurementQty: 0,
	MeasurementType: 0,
	Qty: 0,
	QuoteID: 0,
	QuoteItemID: 0,
	QuoteNumberID: 0,
	Title: '',
	TotalCost: 0,
	TotalPrice: 0,
	VendorID: 0,
	VendorItemCost: 0,
	VendorItemID: 0,
	VendorItemPrice: 0,
});

