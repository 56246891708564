/**
 *
 * CreateFiscalYearDTO
 */

export type CreateFiscalYearDTO = {

	// DateFrom string
	DateFrom: string;

	// DateTo string
	DateTo: string;

	// Year int64
	Year: number;

}

// newCreateFiscalYearDTO is a factory method for new CreateFiscalYearDTO objects
export const newCreateFiscalYearDTO = () : CreateFiscalYearDTO => ({
	DateFrom: '',
	DateTo: '',
	Year: 0,
});

