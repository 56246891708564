import { Button, Checkbox, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Fab, FormControl, FormControlLabel, IconButton, Input, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, Tooltip, withStyles } from '@material-ui/core';
import { Cancel, CloudDownload, Delete, ExpandMore, Sort } from '@material-ui/icons';
import { searchCustomers, useGetCustomersFromIDs } from 'app/api/customers';
import { downloadJobsExcel, downloadJobsPDF, searchJobs, useCreateJobSearch, useDeleteJobSearch, useGetDefaultJobSearch, useGetJobs, useGetJobSearches, useUpdateJobSearch } from 'app/api/jobs';
import { searchVendors, useGetVendorsFromIDs } from 'app/api/vendors';
import { JobSearchAggregate, newJobSearchAggregate, newJobSearchAggregateFromMap } from 'app/definitions/aggregates/JobSearchAggregate';
import { newCreateJobSearchDTO } from 'app/definitions/dtos/CreateJobSearchDTO';
import { searchJobDTOToMap } from 'app/definitions/dtos/SearchJobDTO';
import { newUpdateJobSearchDTO } from 'app/definitions/dtos/UpdateJobSearchDTO';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import { useGetListFromListType } from 'gen/routes/Lists';
import Autocomplete from 'core/components/Autocomplete';
import { DatePickerButton } from 'core/components/DatePicker';
import MoneyFormat from 'core/components/MoneyFormat';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DataBody, DataCell, DataHeaderOrdered, DataRow, DataTable, ReportWrapper, TableNav } from 'core/components/tables';
import { ListTypeBidType, ListTypeCommissionType, ListTypeMarket } from 'core/definitions/constants/listTypes';
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO'; 
import { MultiSearchItem, RemoveMultiSearchItem } from 'core/pages/utils/MultiSearch';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { fetchURLQueryParamsFromString, mapToURL } from 'core/utils/url';
import { JobSearch_ViewPermission, Jobs_DownloadJobsExcelPermission, Jobs_DownloadJobsPDFPermission, Jobs_SearchJobsPermission } from 'gen/constants/permissions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { jobFilterPanelShowingAtom, jobSearchAtom, searchJobTabAtom } from './state';

const verifyJobSearch = (data : JobSearchAggregate) : JobSearchAggregate => {

    return {
        ...data, 
        Search: {
            ...data.Search, 
            Customers: !data.Search.Customers ? [] : data.Search.Customers, 
            Vendors: !data.Search.Vendors ? [] : data.Search.Vendors, 
            CommissionTypes: !data.Search.CommissionTypes ? [] : data.Search.CommissionTypes, 
            EngineerCompanies: !data.Search.EngineerCompanies ? [] : data.Search.EngineerCompanies, 
        }
    }
}

const filterMenuWidth = 300; 
// const [jobSearch, setJobSearch] = useRecoilState(searchQuoteListAtom);
const JobsList = () => {
    
    const location = useLocation(); 
    var params = fetchURLQueryParamsFromString(location.search); 
    const overrideJobSearch = params.size > 0; 
    
    const { data, error, status } = useGetDefaultJobSearch(); 
    const setJobSearch = useSetRecoilState(jobSearchAtom);
    const filterShowing = useRecoilValue(jobFilterPanelShowingAtom); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        if(!overrideJobSearch && status === "success" && data) {
            setJobSearch(verifyJobSearch(data.data))
        } else {
            setJobSearch(newJobSearchAggregateFromMap(params))
        }

    }, [data, status])

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }  

    return <div style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>

        <JobListNav /> 
        
        {filterShowing && 
            <FilterMenu />
        }

        <JobListInner /> 
        
    </div>
}


const JobListNav = () => {

    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom);
    const resetJobSearch = useResetRecoilState(jobSearchAtom); 
    const [filterShowing, setFilterShowing] = useRecoilState(jobFilterPanelShowingAtom);
    const history = useHistory();  
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); 

    const next = () => {
        const newOffset = jobSearch.Search.Limit * (jobSearch.Search.Page + 1); 
        if(newOffset >= jobSearch.Search.ResultCount) {
            return; 
        }        
        setJobSearch({...jobSearch, Search: { ...jobSearch.Search, Page: jobSearch.Search.Page + 1 } })
    }

    const prev = () => {
        const newOffset = jobSearch.Search.Limit * (jobSearch.Search.Page - 1); 
        if(newOffset < 0) {
            return; 
        }
        setJobSearch({...jobSearch, Search: { ...jobSearch.Search, Page: jobSearch.Search.Page - 1 } })
    }

    const onSearchJobSelect = (val: SearchResultDTO) => {
        history.push(`/jobs/${val.ID}`)
    }

    const handleMenuClick = (e) => {
        setMenuAnchorEl(e.currentTarget);
    }

    const doDownloadJobsExcel = () => {
        downloadJobsExcel(jobSearch.Search)
            .then(() => console.log('downloaded!')); 
    }

    const doDownloadJobsPDF = () => {
        downloadJobsPDF(jobSearch.Search)
            .then(() => console.log('downloaded!')); 
    }

    return (
        <NavBarSecondary right={
            <div style={{ display: 'flex'}}>
                
                {/* @test */}
                <Perm perm={[Jobs_DownloadJobsExcelPermission, Jobs_DownloadJobsPDFPermission]} any>
                    <>
                        <IconButton onClick={handleMenuClick}>
                            <CloudDownload /> 
                        </IconButton>
                        
                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            <Perm perm={Jobs_DownloadJobsExcelPermission}>
                                <MenuItem onClick={doDownloadJobsExcel}>
                                    Download Excel
                                </MenuItem>
                            </Perm>

                            <Perm perm={Jobs_DownloadJobsPDFPermission}>
                                <MenuItem onClick={doDownloadJobsPDF}>
                                    Download PDF
                                </MenuItem>
                            </Perm>

                        </Menu>
                    </>
                </Perm>

                <Perm perm={Jobs_SearchJobsPermission}>
                    <div style={{ display: 'inline-block', marginRight: 10}}>
                        <Autocomplete
                            title={"Search jobs..."}
                            searchFn={searchJobs}
                            onSelect={onSearchJobSelect} 
                        />
                    </div>
                </Perm>

                <div style={{ display: 'inline-block', height: '48px', paddingTop: '10px', boxSizing: 'border-box'}}>
                    <TableNav {...{ count: jobSearch.Search.ResultCount, prev, next, page: jobSearch.Search.Page, limit: jobSearch.Search.Limit }} />
                </div>        

                <Perm perm={JobSearch_ViewPermission}>
                    <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                        <Tooltip title="Saved Search">
                            <Fab size="small" style={{ height: 20, width: 35 }} onClick={() => setFilterShowing(!filterShowing)}>
                                <Sort style={{ height: 20, width: 20 }} /> 
                            </Fab>
                        </Tooltip>
                    </div>
                </Perm>
            </div>
        }>
            <strong>Jobs</strong>
            {jobSearch.ObjectSearchID > 0 && 
                <div style={{ display: 'inline-block', marginLeft: 20 }}>
                    <i style={{ fontStyle: 'italic', fontSize: 10 }}>{jobSearch.Title}</i>
                    <IconButton size="small" onClick={() => resetJobSearch()}>
                        <Cancel style={{ fontSize: 14 }} />
                    </IconButton>
                </div>
            }
        </NavBarSecondary>
    ); 
}

const Accordion = withStyles({
    root: { 
        margin: '0px !important', 
        '&$expanded': {
            margin: '0px !important', 
        }
    }, 
    content: {
        '&$expanded': {
            margin: 0, 
        }
    }
})(ExpansionPanel); 

const AccordionSummary = withStyles({
    root: {
        margin: 0, 
        '&$expanded': {
            margin: 0, 
        }
    }, 
    content: {
        '&$expanded': { 
            margin: '10px 0', 
        }
    }
})(ExpansionPanelSummary);

// const jobsListSelectedCustomerAtom = atom<Customer>({
//     key: "jobsListSelectedCustomerAtom",
//     default: newCustomer(), 
// });


const FilterMenu = () => {

    const history = useHistory(); 
    const [panel, setPanel] = useState('dates'); 
    const [tab, setTab] = useRecoilState(searchJobTabAtom); 
    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom);
    const [createSearch] = useCreateJobSearch(); 
    const [updateSearch, { status: updateSearchStatus }] = useUpdateJobSearch(); 
    const [deleteSearch] = useDeleteJobSearch(); 

    const doSetJobSearch = (jobSearch: JobSearchAggregate) => {
        setJobSearch(jobSearch);
        history.push(mapToURL(`/jobs`, searchJobDTOToMap(jobSearch.Search))); 
    }
    
    const [isDeleting, setIsDeleting] = useState(false); 

    const { data: marketData, status: marketStatus, error: marketError } = useGetListFromListType(ListTypeMarket);
    const { data: bidTypeData, status: bidTypeStatus, error: bidTypeError } = useGetListFromListType(ListTypeBidType);
    const { data: commissionTypeData, status: commissionTypeStatus, error: commissionTypeError } = useGetListFromListType(ListTypeCommissionType);
    const { data: usersData, status: usersStatus, error: usersError } = useGetMyAccountUsers(1, 0, 50);


    if(marketStatus === "error") { return <Alert color="danger">{handleNetworkError(marketError)}</Alert>}
    if(bidTypeStatus === "error") { return <Alert color="danger">{handleNetworkError(bidTypeError)}</Alert>}
    if(usersStatus === "error") { return <Alert color="danger">{handleNetworkError(usersError)}</Alert>}
    if(commissionTypeStatus === "error") { return <Alert color="danger">{handleNetworkError(commissionTypeError)}</Alert>}

    if(
        (marketStatus === "loading" || !marketData) ||
        (bidTypeStatus === "loading" || !bidTypeData) ||
        (usersStatus === "loading" || !usersData) ||
        (commissionTypeStatus === "loading" || !commissionTypeData) 
    ) {
        return <CircularProgress /> 
    }

    const togglePanel = (name: string) => () => {
        if(panel === name) {
            setPanel(''); 
        } else {
            setPanel(name); 
        }
    }

    const toggle = (name: string, id: number) => () => {
        
        // Check in case of null values where there should be an array :-(
        const search = jobSearch.Search[name] ? jobSearch.Search[name] : []; 

        if(search.indexOf(id) > -1) {
            doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, [name] : search.filter(x => x !== id )} }); 
        } else {
            doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, [name] : [...search, id ]} })
        }
        
    }

    const isChecked = (name: string, id: number) => {
        // console.log('isChecked', name, id, jobSearch); 
        return jobSearch && jobSearch.Search && jobSearch.Search[name] && jobSearch.Search[name].indexOf(id) > -1; 
    }

    const doCreateSearch = () => {
        createSearch(newCreateJobSearchDTO(jobSearch.Title, jobSearch.Search, jobSearch.IsDefault))
            .then(response => {
                doSetJobSearch(response?.data as JobSearchAggregate); 
            }); 
    }

    const doUpdateSearch = () => updateSearch(newUpdateJobSearchDTO(jobSearch.ObjectSearchID, jobSearch.Title, jobSearch.Search, jobSearch.IsDefault)); 

    const doDeleteSearch = () => deleteSearch(jobSearch.ObjectSearchID)
        .then(() => {
            doSetJobSearch(newJobSearchAggregate()); 
        });
   

    return <FilterMenuEl>
        
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab value={0} label="Search" /> 
            <Tab value={1} label="Saved" />
        </Tabs>

        {/* Search */}
        {tab === 0 && 
            <div>
                

                <Accordion expanded={panel === 'details'} onChange={togglePanel('details')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Save Search</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ display: 'block'}}>
                        {jobSearch.ObjectSearchID > 0 ? (
                                <>
                                    {/* <Button onClick={() => resetJobSearch()}>Reset</Button> */}
                                    
                                    <FormControl>
                                        <InputLabel>Search Title</InputLabel>
                                        <Input value={jobSearch.Title} onChange={(e) => setJobSearch({ ...jobSearch, Title: e.target.value as string})} />
                                    </FormControl>

                                    <FormControlLabel 
                                        control={<Checkbox 
                                            checked={jobSearch.IsDefault === 1} 
                                            onChange={(e) => {
                                                setJobSearch({ ...jobSearch, IsDefault: e.target.checked ? 1 : 0 })
                                            }} 
                                            />
                                        }
                                        label={`Default`}
                                    /> 

                                    <br /> 
                                    {!isDeleting ? (
                                        <>
                                            
                                            <Button onClick={doUpdateSearch} variant="contained" disabled={updateSearchStatus === "loading"}>
                                                Update
                                                {updateSearchStatus === "loading" && <CircularProgress size={20} />}
                                            </Button>
                                            
                                            <IconButton onClick={() => setIsDeleting(true)}>
                                                <Delete /> 
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <Button onClick={doDeleteSearch} color="secondary" variant="contained">
                                                Yes, delete
                                            </Button>
                                            <Button onClick={() => setIsDeleting(false)}>
                                                cancel
                                            </Button>
                                        </>
                                    )}


                                </>
                            ) : (
                                <>
                                    <FormControl>
                                        <InputLabel>Search Title</InputLabel>
                                        <Input value={jobSearch.Title} onChange={(e) => setJobSearch({ ...jobSearch, Title: e.target.value as string})} />
                                    </FormControl>
                                    
                                    <Button onClick={doCreateSearch} variant="contained">Create</Button>
                                    
                                </>
                            )
                        }

                    </ExpansionPanelDetails>
                </Accordion>
                
                <Accordion expanded={panel === 'dates'} onChange={togglePanel('dates')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Dates Awarded {(jobSearch.Search.DateFrom.length > 0 || jobSearch.Search.DateTo.length > 0) && `(1)`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ display: 'block'}}>
                        <div> 
                            {jobSearch.Search.DateFrom.length > 0 ? 
                                <>
                                    <DatePickerButton value={jobSearch.Search.DateFrom} onChange={(d: Date) => doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, DateFrom: moment(d).format() } })} />
                                    <br /> 
                                    <DatePickerButton value={jobSearch.Search.DateTo} onChange={(d: Date) => doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, DateTo: moment(d).format() } })} />
                                    <br /> 
                                    <Button onClick={() => doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, DateFrom: '', DateTo: '' }})}>
                                        Clear
                                        <Cancel /> 
                                    </Button>
                                </>
                                : 
                                <Button onClick={() => doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, DateFrom: moment().format(), DateTo: moment().format() }})}>Set</Button>
                            }
                        </div>
                        {/* <Grid container spacing={0}>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid> */}
                    </ExpansionPanelDetails>
                </Accordion>
                
                <Accordion expanded={panel === "markets"} onChange={togglePanel('markets')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Markets {jobSearch.Search.Markets.length > 0 && `(${jobSearch.Search.Markets.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            {marketData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('Markets', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('Markets', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={`${item.Name}`} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>
                <Accordion expanded={panel === "bidTypes"} onChange={togglePanel('bidTypes')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Bid Types {jobSearch.Search.BidTypes.length > 0 && `(${jobSearch.Search.BidTypes.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            {bidTypeData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('BidTypes', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('BidTypes', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>

                <Accordion expanded={panel === "commissionTypes"} onChange={togglePanel('commissionTypes')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Sales Types {jobSearch.Search.CommissionTypes.length > 0 && `(${jobSearch.Search.CommissionTypes.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <List>
                            {commissionTypeData.data.Items.map((item, key) => 
                                <ListItem key={key} style={{ padding: 0 }} onClick={toggle('CommissionTypes', item.ListItemID)}>
                                    <ListItemIcon>
                                        <Checkbox checked={isChecked('CommissionTypes', item.ListItemID)} /> 
                                    </ListItemIcon>
                                    <ListItemText primary={item.Name} /> 
                                </ListItem> 
                            )}
                        </List>
                    </ExpansionPanelDetails>
                </Accordion>

                <Accordion square expanded={panel === "sales"} onChange={togglePanel('sales')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Sales {jobSearch.Search.Sales.length > 0 && `(${jobSearch.Search.Sales.length})`}</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div style={{ height: '200px', overflowY: 'auto', width: '100%' }}>
                            <List>
                                {usersData.data.Data.map((user, key) => 
                                    <ListItem key={key} style={{ padding: 0 }} onClick={toggle('Sales', user.UserID)}>
                                        <ListItemIcon>
                                            <Checkbox   checked={isChecked('Sales', user.UserID)} /> 
                                        </ListItemIcon>
                                        <ListItemText primary={user.FullName} /> 
                                    </ListItem> 
                                )}
                            </List>
                        </div>
                    </ExpansionPanelDetails>
                </Accordion>
            
                <Accordion square expanded={panel === "customers"} onChange={togglePanel('customers')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Customers ({jobSearch.Search.Customers.length})</strong>
                    </AccordionSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <CustomersSearch /> 
                    </ExpansionPanelDetails>
                </Accordion>

                <Accordion square expanded={panel === "manufacturers"} onChange={togglePanel('manufacturers')}>
                    
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Manufacturers ({jobSearch.Search.Vendors.length})</strong>
                    </AccordionSummary>

                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div style={{ height: '200px', overflowY: 'auto', width: '100%', paddingLeft: 20, paddingRight: 20 }}>
                            <ManufacturersSearch /> 
                        </div>
                    </ExpansionPanelDetails>
                    
                </Accordion>

                
                <Accordion square expanded={panel === "engineerCompanies"} onChange={togglePanel('engineerCompanies')}>
                    
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <strong>Engineering Companies ({jobSearch.Search.EngineerCompanies.length})</strong>
                    </AccordionSummary>

                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div style={{ height: '200px', overflowY: 'auto', width: '100%', paddingLeft: 20, paddingRight: 20 }}>
                            <EngineerCompanySearch /> 
                        </div>
                    </ExpansionPanelDetails>
                    
                </Accordion>
            
            </div>
        }

        {/* Saved */}
        {tab === 1 && 
            <div>
                <SavedSearches /> 
            </div>
        }

    </FilterMenuEl>
}

const CustomersSearch = () => {

    const history = useHistory(); 
    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom); 
    const { data, status, error } = useGetCustomersFromIDs(jobSearch.Search.Customers);
    
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const doSetJobSearch = (jobSearch: JobSearchAggregate) => {
        setJobSearch(jobSearch); 
        history.push(mapToURL(`/jobs`, searchJobDTOToMap(jobSearch.Search))); 
    }

    const onSelectCustomer = (result: SearchResultDTO) => { 
        console.log('onSelectVendor'); 
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, Customers: [...jobSearch.Search.Customers, result.ID ]}})
    }

    const removeCustomer = (customerID: number) => () => {
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, Customers: jobSearch.Search.Customers.filter(x => x !== customerID)}})
    }

    return (

    
        <div style={{ height: '200px', overflowY: 'auto', width: '100%', paddingLeft: 20, paddingRight: 20 }}>
            <Autocomplete
                title={"Search Customers..."} 
                searchFn={searchCustomers}
                onSelect={onSelectCustomer}
                clearOnSelect={true}
            />
            
            {data.data.map((customer, key) => 
                <MultiSearchItem>
                    {customer.Title}
                    <RemoveMultiSearchItem>
                        <IconButton size="small" onClick={removeCustomer(customer.CustomerID)}>
                            <Cancel /> 
                        </IconButton>
                    </RemoveMultiSearchItem>
                </MultiSearchItem>
            )}
        </div>


    )

}



const EngineerCompanySearch = () => {

    const history = useHistory(); 
    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom); 
    const { data, status, error } = useGetCustomersFromIDs(jobSearch.Search.EngineerCompanies);
    
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const doSetJobSearch = (jobSearch: JobSearchAggregate) => {
        setJobSearch(jobSearch); 
        history.push(mapToURL(`/jobs`, searchJobDTOToMap(jobSearch.Search))); 
    }

    const onSelectEngineerCompany = (result: SearchResultDTO) => { 
        
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, EngineerCompanies: [...jobSearch.Search.EngineerCompanies, result.ID ]}})
    }

    const removeEngineerCompany= (customerID: number) => () => {
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, EngineerCompanies: jobSearch.Search.EngineerCompanies.filter(x => x !== customerID)}})
    }

    return (

    
        <div style={{ height: '200px', overflowY: 'auto', width: '100%', paddingLeft: 20, paddingRight: 20 }}>
            <Autocomplete
                title={"Search Engineer Companies..."} 
                searchFn={searchCustomers}
                onSelect={onSelectEngineerCompany}
                clearOnSelect={true}
            />
          
            {data.data.map((engineerCompanies, key) => 
                <MultiSearchItem>
                    {engineerCompanies.Title}
                    <RemoveMultiSearchItem>
                        <IconButton size="small" onClick={removeEngineerCompany(engineerCompanies.CustomerID)}>
                            <Cancel /> 
                        </IconButton>
                    </RemoveMultiSearchItem>
                </MultiSearchItem>
            )}
        </div>


    )

}

const ManufacturersSearch = () => {

    const history = useHistory(); 
    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom); 
    const { data, status, error } = useGetVendorsFromIDs(jobSearch.Search.Vendors);
    
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const doSetJobSearch = (jobSearch: JobSearchAggregate) => {
        setJobSearch(jobSearch); 
        history.push(mapToURL(`/jobs`, searchJobDTOToMap(jobSearch.Search))); 
    }

    const onSelectVendor = (result: SearchResultDTO) => { 
        console.log('onSelectVendor'); 
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, Vendors: [...jobSearch.Search.Vendors, result.ID ]}})
    }

    const removeVendor = (vendorID: number) => () => {
        doSetJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, Vendors: jobSearch.Search.Vendors.filter(x => x !== vendorID)}})
    }

    return (
        <>
            
                    <Autocomplete
                        title={"Search Manufacturers..."} 
                        searchFn={searchVendors}
                        onSelect={onSelectVendor}
                        clearOnSelect={true}
                    />
                    
                    {data.data.map((vendor, key) => 
                        <MultiSearchItem>
                            {vendor.Title}
                            <RemoveMultiSearchItem>
                                <IconButton size="small" onClick={removeVendor(vendor.VendorID)}>
                                    <Cancel /> 
                                </IconButton>
                            </RemoveMultiSearchItem>
                        </MultiSearchItem>
                    )}
        </>
    )

}

const SavedSearches = () => {

    const { data, error, status } = useGetJobSearches(); 
    const setTab = useSetRecoilState(searchJobTabAtom)
    const [jobSearch, setJobSearch] = useRecoilState(jobSearchAtom); 

    const selectSearch = (search : JobSearchAggregate) => () => {
        console.log('Select Search: ', search); 
        setJobSearch(verifyJobSearch(search)); 
        setTab(0); 

    }

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }
    
    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }


    return (
        <div>
            {data.data.length > 0 ? 
                <List>
                    {data.data.map((item, key) => 
                        <ListItem key={key} onClick={selectSearch(item)} button style={{ backgroundColor: item.ObjectSearchID === jobSearch.ObjectSearchID ? '#dfd' : 'inherit'}}>
                            <ListItemText primary={item.Title} secondary={item.IsDefault === 1 ? 'Default' : ''} />
                        </ListItem>
                    )}
                </List>
                : 
                <Alert color="secondary" style={{ margin: 10 }}>No saved searches</Alert>
            }
        </div>
    ); 

    
}

const FilterMenuEl = styled.div`

    position: absolute; 
    top: 52px; 
    right: 0; 
    height: calc(100% - 52px); 
    width: ${filterMenuWidth}px; 
    border-left: solid 1px #909090; 
    z-index: 10000; 
    background-color: #fff; 

`; 


const JobListInner = () => {

    const filterShowing = useRecoilValue(jobFilterPanelShowingAtom); 
    const history = useHistory(); 
    const [ jobSearch, setJobSearch ] = useRecoilState(jobSearchAtom);
    const { data, status, error } = useGetJobs(jobSearch.Search); 

    useEffect(() => {
        if(status === "success" && data) {
            console.log('jobListInner:useEffect()', jobSearch.Search)
            setJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, ResultCount: data.data.Count } }); 
        }
    }, [ status, data ])

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const jobs = data.data; 

    const orderCol = (fieldName: string) => () => {
        if(jobSearch.Search.OrderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, OrderBy: fieldName, OrderDir: "ASC" } }); 
    }

    const toggleOrderDir = () => {
        const dirName = jobSearch.Search.OrderDir === "ASC" ? "DESC" : "ASC";
        setJobSearch({ ...jobSearch, Search: { ...jobSearch.Search, OrderDir: dirName } })
    }

    const toJobPage = (jobID: number) => () => {
        history.push(`/jobs/${jobID}`)
    }

    
    const numCols = 15; 

    const colWidth = (units: number) : number => {
        return units * (99.9 / numCols); 
    }


    const tableWidth = filterShowing ? `calc(100% - ${filterMenuWidth}px` : '100%'; 
    

    return (
        <ReportWrapper>
            
            <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'auto', width: tableWidth,paddingRight:'20px', paddingLeft:'20px'}}>

                <DataRow>

                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("PONumber")} isOrderBy={jobSearch.Search.OrderBy === "PONumber"} dir={jobSearch.Search.OrderDir}>PO #</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("AwardDate")} isOrderBy={jobSearch.Search.OrderBy === "AwardDate"} dir={jobSearch.Search.OrderDir}>Awarded</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.5)} orderFn={orderCol("JobNumberString")} isOrderBy={jobSearch.Search.OrderBy === "JobNumberString"} dir={jobSearch.Search.OrderDir}>Job #</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1.75)} orderFn={orderCol("Description")} isOrderBy={jobSearch.Search.OrderBy === "Description"} dir={jobSearch.Search.OrderDir}>Job Name</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("Sales1")} isOrderBy={jobSearch.Search.OrderBy === "Sales1"} dir={jobSearch.Search.OrderDir}>Sales 1</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("Sales2")} isOrderBy={jobSearch.Search.OrderBy === "Sales2"} dir={jobSearch.Search.OrderDir}>Sales 2</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1.5)} orderFn={orderCol("EngineerCoName")} isOrderBy={jobSearch.Search.OrderBy === "EngineerCoName"} dir={jobSearch.Search.OrderDir}>Engineering Company</DataHeaderOrdered>
                     <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("Vendor1Name")} isOrderBy={jobSearch.Search.OrderBy === "Vendor1Name"} dir={jobSearch.Search.OrderDir}>Manufacturer</DataHeaderOrdered>
                     <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("QuoteTypeName")} isOrderBy={jobSearch.Search.OrderBy === "QuoteTypeName"} dir={jobSearch.Search.OrderDir}>Quote Type</DataHeaderOrdered>
                     <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("BidTypeName")} isOrderBy={jobSearch.Search.OrderBy === "BidTypeName"} dir={jobSearch.Search.OrderDir}>Bid Type</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("MarketName")} isOrderBy={jobSearch.Search.OrderBy === "MarketName"} dir={jobSearch.Search.OrderDir}>Market</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} align="center" orderFn={orderCol("TotalPrice")} isOrderBy={jobSearch.Search.OrderBy === "TotalPrice"} dir={jobSearch.Search.OrderDir}>Total Sell</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(1)} align="center" orderFn={orderCol("ProjectCost")} isOrderBy={jobSearch.Search.OrderBy === "ProjectCost"} dir={jobSearch.Search.OrderDir}>Cost</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.75)} align="center" orderFn={orderCol("GrossProfit")} isOrderBy={jobSearch.Search.OrderBy === "GrossProfit"} dir={jobSearch.Search.OrderDir}>GM</DataHeaderOrdered>
                    <DataHeaderOrdered percent={colWidth(.5)} align="center" orderFn={orderCol("GrossMarginPercent")} isOrderBy={jobSearch.Search.OrderBy === "GrossMarginPercent"} dir={jobSearch.Search.OrderDir}>GM %</DataHeaderOrdered>
                    {/* <DataHeaderOrdered percent={colWidth(.5)} orderFn={orderCol("DateCreated")} isOrderBy={jobSearch.Search.OrderBy === "DateCreated"} dir={jobSearch.Search.OrderDir}>Created</DataHeaderOrdered> */}
                    {/* <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("Customer")} isOrderBy={jobSearch.Search.OrderBy === "Customer"} dir={jobSearch.Search.OrderDir}>Customer</DataHeaderOrdered> */}
                    {/* <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("MarketID")} isOrderBy={jobSearch.Search.OrderBy === "MarketID"} dir={jobSearch.Search.OrderDir}>Market</DataHeaderOrdered> */}
                    {/* <DataHeaderOrdered percent={colWidth(1)} orderFn={orderCol("BidTypeID")} isOrderBy={jobSearch.Search.OrderBy === "BidTypeID"} dir={jobSearch.Search.OrderDir}>BidType</DataHeaderOrdered> */}
                
                </DataRow>

                <DataBody>
                    
                    {jobs.Data.map((job, key) => { 
                        const vendors = job.Vendor1Name + (job.Vendor2Name ? ", " + job.Vendor2Name : ""); 

                        return (
                            <DataRow key={key} onClick={toJobPage(job.JobID)}>
                                <DataCell percent={colWidth(1)}>{job.PONumber}</DataCell>
                                <DataCell percent={colWidth(1)}>{moment(job.AwardDate).format('M/D/YY')}</DataCell>
                                <DataCell percent={colWidth(.5)}>{job.JobNumberString}</DataCell>
                                <DataCell percent={colWidth(1.75)}>{job.Description}</DataCell>
                                <DataCell percent={colWidth(1)}>{job.Sales1}</DataCell>
                                <DataCell percent={colWidth(1)}>{job.Sales2}</DataCell>
                                <DataCell percent={colWidth(1.5)}>{job.EngineerCoName}</DataCell>
                                <DataCell percent={colWidth(1)}>{vendors}</DataCell>
                                <DataCell percent={colWidth(1)}>{job.QuoteTypeName}</DataCell>
                                <DataCell percent={colWidth(1)}>{job.BidTypeName}</DataCell>
                                <DataCell percent={colWidth(1)}>{job.MarketName}</DataCell>
                                <DataCell percent={colWidth(1)} align="right">$<MoneyFormat val={job.TotalPrice} /></DataCell>
                                <DataCell percent={colWidth(1)} align="right">$<MoneyFormat val={job.ProjectCost} /></DataCell>
                                <DataCell percent={colWidth(.75)} align="right">$<MoneyFormat val={job.GrossProfit} /></DataCell>
                                <DataCell percent={colWidth(.5)} align="right"><MoneyFormat val={(100 - job.GrossMarginPercent)} />%</DataCell>
                            </DataRow>                        
                        ); 
                    })}

                </DataBody>
            </DataTable>
            <DataRow key={-1} style={{ backgroundColor: '#eee', borderTop: 'solid 1px #909090', width: tableWidth }}>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(.5)}></DataCell>
                <DataCell percent={colWidth(1.75)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1.5)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(1)} align="right"><strong>Total:</strong></DataCell>
                <DataCell percent={colWidth(1)} align="right">$<MoneyFormat val={jobs.Total} /></DataCell>
                <DataCell percent={colWidth(1)}></DataCell>
                <DataCell percent={colWidth(.75)}></DataCell>
                <DataCell percent={colWidth(.5)}></DataCell>               
            </DataRow>

        </ReportWrapper>
    ); 
}

export default JobsList; 