import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AuthForm = styled.div`
    border: solid 1px rgba(0, 0, 0, .1); 
    max-width: 320px; 
    margin: 100px auto; 
    min-height: 300px; 
    padding: 10px;  
    background-color: #fff; 
    border-radius: 5px; 
    box-shadow: 3px 3px 3px rgba(240, 240, 240, 1); 
`


const useStyles = makeStyles((theme) => ({
    title: { 
      fontFamily: 'helvetica', 
      fontSize: '1.5em', 
      textDecoration: 'none', 
      marginBottom: 20, 
      borderBottom: 'solid 1px #999', 
      paddingBottom: 10, 
      color: '#999', 
    }, 
    ln: { 
      fontFamily: 'helvetica', 
      fontSize: '.9em',
      textDecoration: 'underline'
    }, 
    row: { 
      fontFamily: 'helvetica', 
      marginTop: 20, 
      marginBottom: 20 
    }, 
    alertInfo: {
      padding: 10, 
      marginBottom: 10, 
      marginTop: 10, 
      backgroundColor: '#eee', 
      borderRadius: '5px', 
      fontFamily: 'helvetica', 
      fontSize: '.9em', 
    }, 
    alertError: { 
      padding: 10, 
      marginBottom: 10, 
      marginTop: 10, 
      backgroundColor: '#f66', 
      border: 'solid 1px #f00', 
      borderRadius: 3, 
      color: '#fff',
      fontSize: '.9em', 
    }, 
    alertSuccess: { 
        padding: 10, 
        marginBottom: 10, 
        marginTop: 10, 
        backgroundColor: '#6f6', 
        border: 'solid 1px #0f0', 
        borderRadius: 3, 
        color: '#000', 
        fontSize: '.9em', 
    }
  
  })); 

export const Ln : React.FC<{ to: string, children: React.ReactNode }> = ({ to, children }) => { 
    
    const classes = useStyles(); 

    return ( 
        <Link className={classes.ln} to={to}>
          {children}
        </Link>
    ); 
} 

export const AuthTitle : React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const classes = useStyles(); 

    return ( 
        <h1 className={classes.title}>
            {children}
        </h1>
    ); 
}

export const AlertError : React.FC<{ children : React.ReactNode }> = ({ children }) => {

    const classes = useStyles(); 

    return (
        <div className={classes.alertError}>
            {children}
          </div>
    )
}

export const AlertInfo : React.FC<{ children: React.ReactNode }> = ({ children }) => { 

    const classes = useStyles(); 

    return (
        <div className={classes.alertInfo}>
            {children}
        </div>
    )
}

export const AlertSuccess : React.FC<{ children: React.ReactNode }> = ({ children }) => { 

    const classes = useStyles(); 

    return (
        <div className={classes.alertSuccess}>
            {children}
        </div>
    )
}



export const Row : React.FC<{ children : React.ReactNode }> = ({ children }) => { 

    const classes = useStyles(); 

    return (
        <div className={classes.row}>
            {children}
        </div>
    )
}