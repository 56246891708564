import axios from 'axios';
export const setHeader = (name: string, value: string) => {
    axios.defaults.headers.common[name] = value; 
}

export const setAuthHeader = (value: string) => {
    setHeader("Authorization", "Bearer " + value); 
}

export const setDeviceHeader = (value: string) => {
    setHeader("X-Device", value);
}

