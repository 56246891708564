/**
 *
 * VendorItem
 */

export type VendorItem = {

	// Cost float64
	Cost: number;

	// DateCreated int64
	DateCreated: number;

	// Description null.String
	Description: string;

	// IsDeleted int
	IsDeleted: number;

	// LastUpdated int64
	LastUpdated: number;

	// ModelNumber string
	ModelNumber: string;

	// Title string
	Title: string;

	// VendorID int64
	VendorID: number;

	// VendorItemID int64
	VendorItemID: number;

}

// newVendorItem is a factory method for creating new VendorItem objects
export const newVendorItem = () : VendorItem => ({ 
	Cost: 0,
	DateCreated: 0,
	Description: '',
	IsDeleted: 0,
	LastUpdated: 0,
	ModelNumber: '',
	Title: '',
	VendorID: 0,
	VendorItemID: 0,
});

