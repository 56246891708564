import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetAccountFromID } from 'gen/routes/Accounts';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import AdminActions from '../common/AdminActions';
import AdminHeader from '../common/AdminHeader';
import AccountDetailDetails from './AccountDetailDetails';
import AccountSettings from './AccountSettings';
import AccountUsers from './AccountUsers';

const AccountDetail = () =>  { 

    const { id } = useParams() as { id: string }; 
    const [tab, setTab] = useState('users'); 

    const { data, error, status } = useGetAccountFromID(parseInt(id)); 

    if(status === "error" ) {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) { 
        return <CircularProgress /> 
    }

    const account = data.data; 

    return (
        <div style={{ height: '100%' }}>
            
            <NavBarSecondary
                right={<>
                    <AdminActions actions={[]} />
                </>}
            >
                <AdminHeader title={`Account # ${account.AccountID}`} parents={[{title: "Accounts", url: "/admin/accounts"}]} /> 
            </NavBarSecondary>

            <AccountDetailDetails account={account} />
            
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>

                    <Tab value="users" label="Users" />
                    <Tab value="settings" label="Settings" />  
            </Tabs>
            {{
                "settings": <AccountSettings account={account} />, 
                "users": <AccountUsers account={account} />, 
            }[tab] || <AccountUsers account={account} />}
        </div>
    );
}

export default AccountDetail; 