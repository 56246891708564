import { Button, CircularProgress, FormControl, Input, InputLabel } from '@material-ui/core';
import config from 'config.json';
import { Status, statusDefault, statusLoading, StatusState, statusSuccess } from 'core/common/status';
import handleNetworkError from 'core/utils/handleNetworkError';
import userService from 'core/utils/userService';
import { createAuthorizationForUser } from 'gen/routes/Auth';
import React, { useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Redirect } from 'react-router-dom';
import { AlertError, AlertInfo, AuthForm, AuthTitle, Ln, Row } from './common';

const LoginInternal = () => {


  // Note: useState<string>() returns `string | undefined` instead of just `string`
  const [username, setUsername] = useState(""); 
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState<StatusState>(statusDefault()); 
  const { executeRecaptcha } = useGoogleReCaptcha(); 

  const loginClick = async () => {

    setStatus(statusLoading('')); 

    console.log('Calling login'); 

    if(!executeRecaptcha) { 
      console.log('executeRecaptcha undefined'); 
      return; 
    }

    const rcToken = config.reCaptchaKey.length > 0 ? await executeRecaptcha('LOGIN') : ''; 

    createAuthorizationForUser({ body: { Email: username, Password: password, RCToken:  rcToken } })
      .then(response => {
        
        console.log('login callback!'); 

        userService.login(response.data); 

        setStatus(
          statusSuccess(
            "Logged in..."
          )
        )

        window.location.reload(); 
      
      })
      .catch(e => {

        setStatus({ 
          status: Status.Failure, 
          message: handleNetworkError(e)
        }); 

      })

  }

  const onKeyUp = (e:any) => {
      if(e.key === "Enter") {
          loginClick(); 
      }
  }

  return userService.isLoggedIn() ? (
      <Redirect from="/login" to="/" /> 
    ) : (
    <AuthForm>

        <AuthTitle>
          User Login
        </AuthTitle>
        
        {status.status === Status.Failure && 
          <AlertError>
            {status.message}
          </AlertError>
        }

        {status.status === Status.Loading && 
          <AlertInfo>
            {status.message}
          </AlertInfo>
        } 

        <FormControl style={{ width: '100%'}}>
          <InputLabel>Email</InputLabel>
          <Input 
            value={username} 
            onChange={(e:any) => setUsername(e.target.value)} 
            disabled={status.status === Status.Loading}
            onKeyUp={onKeyUp}
          /> 
        </FormControl>
  
        <FormControl style={{ width: '100%'}}>
          <InputLabel>Password</InputLabel>
          <Input 
            type="password" 
            onKeyUp={onKeyUp} 
            value={password} 
            onChange={(e:any) => setPassword(e.target.value)} 
            disabled={status.status === Status.Loading}
          /> 
        </FormControl>
        
        <Row>
          <Button 
            onClick={loginClick}
            disabled={status.status === Status.Loading}
            variant="contained"
          >
            Login
            {status.status === Status.Loading && 
              <CircularProgress size={20} /> 
            }
          </Button> 
        </Row>
        
        <Ln to={`/password/reset/start`}>
          Forgot your password?
        </Ln>
  
      </AuthForm>
  )

}


const Login = () => { 
  
  if(config.reCaptchaKey.length === 0) { 
    return <LoginInternal /> 
  }

  return ( 
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <LoginInternal /> 
    </GoogleReCaptchaProvider>
  )
}


  export default Login; 