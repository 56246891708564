import { Paper } from '@material-ui/core';
import { getCustomerFromID, searchCustomers } from 'app/api/customers';
import { newQuoteCustomerAggregateFromCustomer } from 'app/definitions/aggregates/QuoteCustomerAggregate';
import Autocomplete from 'core/components/Autocomplete';
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO';
import React from 'react';
import { Button } from 'reactstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import CreateQuoteCustomerContactDialog from './CreateQuoteCustomerContactDialog';
import CreateQuoteCustomerDialog from './CreateQuoteCustomerDialog';
import QuoteCustomerCard from './QuoteCustomerCard';
import { selectedQuoteAtom, showCreateQuoteCustomerOfTypeDialogAtom } from './state';


const QuoteCustomers : React.FC<{ 
    customerType: number; 
    title: string; 
    contact?: boolean 
}> = ({ 
    customerType, 
    title, 
    contact 
}) => {

    const setShowCreateQuoteCustomerDialog  = useSetRecoilState(showCreateQuoteCustomerOfTypeDialogAtom); 
    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 

    const selectAutocompleteCustomer = (searchResult: SearchResultDTO) => {
        
        // Customer already on the quote for this customer type 
        if(quote.Customers.some(x => x.CustomerID === searchResult.ID && x.QuoteCustomerType === customerType)) {
            return; 
        }

        getCustomerFromID(searchResult.ID)
            .then(response => {
                setQuote({
                    ...quote, 
                    CustomerCount: quote.CustomerCount + 1, 
                    Customers: [
                        ...quote.Customers, 
                        newQuoteCustomerAggregateFromCustomer(response.data, customerType) 
                    ]
                });
            });
    }

    const customers = quote.Customers.filter(x => x.QuoteCustomerType === customerType);

    return (
        <CustomersContainer elevation={3}>
            <Header>
                <HeaderLeft>{title} ({customers.length})</HeaderLeft>
                {quote.JobID === 0 && 
                    <HeaderRight>
                        <HeaderRightAutocomplete>
                            <Autocomplete
                                title={`Search ${title}...`}
                                onSelect={selectAutocompleteCustomer}
                                searchFn={searchCustomers}
                                clearOnSelect
                            /> 
                        </HeaderRightAutocomplete>
                        <HeaderRightCreateNewButton> 
                            <Button color="info" onClick={() => setShowCreateQuoteCustomerDialog(customerType)}>
                                New {title}
                            </Button>
                        </HeaderRightCreateNewButton>
                    </HeaderRight>
                }
            </Header>
            <Body>
                {customers.map((customer, key) => <QuoteCustomerCard {...{ customer, key, contact }} />)}
            </Body>
            <CreateQuoteCustomerDialog /> 
            <CreateQuoteCustomerContactDialog />
        </CustomersContainer>
    )

}

const CustomersContainer = styled(Paper)`
    margin: 10px; 
    width: calc(100% - 20px); 
    overflow-x: hidden; 
    min-height: 428px; 
`; 

const Header  = styled.div`
    height: 58px; 
    border-bottom: solid 1px #909090; 
    margin-bottom: 10px; 
    padding: 5px; 
    display: flex; 
    flex-direction: row; 
`; 

const HeaderLeft = styled.div`
    flex-grow: 2; 
    font-weight: bold; 
    line-height: 58px; 
    vertical-align: middle; 
    text-indent: 20px; 
`; 

const HeaderRight = styled.div`
    position: relative; 
    flex-grow: 1; 
    display: flex; 
`; 
const HeaderRightAutocomplete = styled.div`
    flex-grow: 4; 
    padding-right: 10px; 
`
const HeaderRightCreateNewButton = styled.div`
    flex-grow: 1; 
    padding-top: 8px; 
`

const Body = styled.div`
    justify-content: flex-start; 
    display: flex; 
    padding: 20px; 
    width: 100%; 
    overflow-x: auto; 
`; 

export default QuoteCustomers; 