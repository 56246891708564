/**
 *
 * FiscalYear
 */

export type FiscalYear = {

	// DateCreated int64
	DateCreated: number;

	// DateFrom int64
	DateFrom: number;

	// DateTo int64
	DateTo: number;

	// FiscalYearID int64
	FiscalYearID: number;

	// FiscalYearKey int64
	FiscalYearKey: number;

	// IsDeleted int
	IsDeleted: number;

	// IsLocked int64
	IsLocked: number;

	// TotalFiscalDays int64
	TotalFiscalDays: number;

	// Year int64
	Year: number;

}

// newFiscalYear is a factory method for creating new FiscalYear objects
export const newFiscalYear = () : FiscalYear => ({ 
	DateCreated: 0,
	DateFrom: 0,
	DateTo: 0,
	FiscalYearID: 0,
	FiscalYearKey: 0,
	IsDeleted: 0,
	IsLocked: 0,
	TotalFiscalDays: 0,
	Year: 0,
});

