    import { Customer, newCustomer } from 'gen/models/Customer';
import { CustomerContact, newCustomerContact } from 'gen/models/CustomerContact';
import { Job, newJob } from 'gen/models/Job';
import { JobItem } from 'gen/models/JobItem';
import { newQuote, Quote } from 'gen/models/Quote';
import { JobSalesAggregate } from './JobSalesAggregate';
export type JobAggregate = Job & {
    Customer: Customer; 
    CustomerContact: CustomerContact; 
    Quote: Quote; 
    QuoteNumber: string; 
    MarketName: string; 
    BidTypeName: string; 
    QuoteTypeName: string; 
    Items: JobItem[]; 
    Sales: JobSalesAggregate[]; 
    Vendor1Name: string; 
    Vendor2Name: string; 
    NextJob: { JobID: number; JobNumber: string; };
    PrevJob: { JobID: number; JobNumber: string; };
    EngineerCoName: string;
}

export const newJobAggregate = () : JobAggregate => ({
    ...newJob(), 
    Quote: newQuote(), 
    Customer: newCustomer(), 
    CustomerContact: newCustomerContact(), 
    QuoteNumber: '', 
    MarketName: '', 
    BidTypeName: '', 
    QuoteTypeName: '', 
    Items: [], 
    Sales: [], 
    Vendor1Name: '', 
    Vendor2Name: '', 
    NextJob: { JobID: 0, JobNumber: '' }, 
    PrevJob: { JobID: 0, JobNumber: '' }, 
    EngineerCoName: '',
})