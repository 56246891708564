import { FiscalYear } from "gen/models/FiscalYear";
import moment from "moment";

export type UpdateFiscalYearDTO = {
    Year: number; 
    DateFrom: string; 
    DateTo: string; 
}

export const newUpdateFiscalYearDTOFromModel = (model: FiscalYear) : UpdateFiscalYearDTO => ({
    Year: model.Year, 
    DateFrom: moment(model.DateFrom).format(), 
    DateTo: moment(model.DateTo).format(), 
})

export const newUpdateFiscalYearDTO = () : UpdateFiscalYearDTO => ({
    Year: 0, 
    DateFrom: '', 
    DateTo: '', 
});

export type sysValDto = {
    OrderBy: string; 
    OrderDir: string; 
    ResultCount: number; 
}
export const sysValReport = () : sysValDto => ({
    OrderBy: 'Month', 
    OrderDir: 'ASC', 
    ResultCount: 0, 
});