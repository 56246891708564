/**
 *
 * CreateCustomerDTO
 */

export type CreateCustomerDTO = {

	// Address string
	Address: string;

	// Address2 string
	Address2: string;

	// City string
	City: string;

	// Fax string
	Fax: string;

	// Phone string
	Phone: string;

	// State string
	State: string;

	// Title string
	Title: string;

	// Zip string
	Zip: string;

}

// newCreateCustomerDTO is a factory method for new CreateCustomerDTO objects
export const newCreateCustomerDTO = () : CreateCustomerDTO => ({
	Address: '',
	Address2: '',
	City: '',
	Fax: '',
	Phone: '',
	State: '',
	Title: '',
	Zip: '',
});

