import { JobItem } from "gen/models/JobItem"
import moment from "moment"

export type UpdateJobItemDTO = {
    JobItemID: number; 
    Ordinal: number; 
    OrderNumber: string; 
    OrderDate: string; 
    RequestedShipDate: string; 
    EstimatedShipDate: string; 
    Cost: number; 
    AllocatedProfit: number; 
    AllocatedSale: number; 
    PONumberToVendor: string; 
    VendorID: number; 
    ProductDescription: string; 
}

export const newUpdateJobItemDTO = () : UpdateJobItemDTO => ({
    JobItemID: 0, 
    Ordinal: 0, 
    OrderNumber: '', 
    OrderDate: "", 
    RequestedShipDate: "", 
    EstimatedShipDate: "", 
    Cost: 0, 
    AllocatedProfit: 0, 
    AllocatedSale: 0, 
    PONumberToVendor: '',
    VendorID: 0,  
    ProductDescription: '', 
})

export const newUpdateJobItemDTOFromModel = (model: JobItem) : UpdateJobItemDTO => {

    const t = moment.unix(model.EstimatedShipDate); 

    if(t.isValid()) { 
        console.log('newUpdateJobItemDTOFromModel::isValid()', model.EstimatedShipDate, ' - ', t.format()) 
    } else {
        console.log('newUpdateJobItemDTOFromModel::isValid() - nerp');  
    }

    return {
        JobItemID: model.JobItemID, 
        Ordinal: model.Ordinal, 
        OrderNumber: model.OrderNumber, 
        OrderDate: model.OrderDate > 0 ? moment.unix(model.OrderDate).format() : '', 
        RequestedShipDate: model.RequestedShipDate > 0 ? moment.unix(model.RequestedShipDate).format() : '', 
        EstimatedShipDate: model.EstimatedShipDate > 0 ? moment.unix(model.EstimatedShipDate).format() : '', 
        Cost: model.Cost, 
        AllocatedProfit: model.AllocatedProfit, 
        AllocatedSale: model.AllocatedSale, 
        PONumberToVendor: model.PONumberToVendor, 
        VendorID: model.VendorID, 
        ProductDescription: model.ProductDescription, 
    }
}