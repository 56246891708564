import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { exportPolicies, importPolicies } from 'gen/routes/Policies';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { AdminUtils_RebuildPermissionsPermission, Policies_ExportPoliciesPermission, Policies_ImportPoliciesPermission } from 'gen/constants/permissions';
import { rebuildPermissions } from 'gen/routes/AdminUtils';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AdminActions from '../common/AdminActions';
import AdminHeader from '../common/AdminHeader';
import PoliciesList from './PoliciesList';
import PolicyDetail from './PolicyDetail';
import { selectedManagePolicyAtom } from './state';
import download from 'downloadjs';

const PoliciesManager = () => {

    const [importShowing, setImportShowing] = useState(false);
    const [selectedUploadFile, setSelectedUploadFile] = useState<File | undefined>(undefined); 

    const { id } = useParams() as { id: string }; 
    const setSelectedPolicy = useSetRecoilState(selectedManagePolicyAtom); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(id) {
            setSelectedPolicy(parseInt(id)); 
        }
    }, [id]);

    const doRebuildPermissions = () => {
        rebuildPermissions()
            .then(() => alert('Permissions rebuilt')); 
    }

    const doExportPolicies = () => { 
        exportPolicies()
            .then((resp) => { 
                download(resp.data, `policies-export.json`, resp.headers['content-type']); 
            });
    }

    const doImportPolicies = () => setImportShowing(true); 
    const onImportFileChange : ChangeEventHandler<HTMLInputElement> = (e : React.ChangeEvent<HTMLInputElement>) => { 
        const el = (e.target as HTMLInputElement); 
        if(el && el.files) { 
            setSelectedUploadFile(el.files[0]);
        }
    } 
    const onImportSubmit = () => { 
        const formData = new FormData(); 

        if(!selectedUploadFile) { 
            return 
        } 
        formData.append(
            "myFile", 
            selectedUploadFile, 
            selectedUploadFile.name, 
        )

        importPolicies({ body: formData })
            .then(() => { 
                alert("Policies uploaded...") 
                setImportShowing(false); 
            })
    }


    return (
        <div style={{ height: '100%', overflow: 'hidden'}}>
            <NavBarSecondary
                right={<>
                    <AdminActions actions={[
                        { title: 'Rebuild', fn: doRebuildPermissions, perm: AdminUtils_RebuildPermissionsPermission }, 
                        { title: 'Export', fn: doExportPolicies, perm: Policies_ExportPoliciesPermission },
                        { title: 'Import', fn: doImportPolicies, perm: Policies_ImportPoliciesPermission },
                    ]} /> 
                </>}
            >
                <AdminHeader title={"Policies"} />
            </NavBarSecondary>

            <Dialog open={importShowing} onClose={() => setImportShowing(false)}>
                <DialogTitle>Import Policies</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={onImportFileChange} /> 
                    <Button variant="contained" onClick={onImportSubmit}>Upload</Button>
                </DialogContent>
            </Dialog>

            <Grid container spacing={4} style={{ height: 'calc(100% - 40px)', margin: 0, overflowY: 'hidden'}}>
                <Grid item xs={4}>
                    <PoliciesList /> 
                </Grid>
                <Grid item xs={8} style={{ height: '100%', overflowY: 'hidden'}}>
                    <PolicyDetail /> 
                </Grid>
            </Grid>
        </div>
    )

}

export default PoliciesManager; 