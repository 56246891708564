/**
 *
 * Vendor
 */

export type Vendor = {

	// Address string
	Address: string;

	// Address2 string
	Address2: string;

	// City string
	City: string;

	// Fax string
	Fax: string;

	// IsDeleted int
	IsDeleted: number;

	// ItemCount int64
	ItemCount: number;

	// Phone string
	Phone: string;

	// State string
	State: string;

	// Title string
	Title: string;

	// VendorID int64
	VendorID: number;

	// Zip string
	Zip: string;

}

// newVendor is a factory method for creating new Vendor objects
export const newVendor = () : Vendor => ({ 
	Address: '',
	Address2: '',
	City: '',
	Fax: '',
	IsDeleted: 0,
	ItemCount: 0,
	Phone: '',
	State: '',
	Title: '',
	VendorID: 0,
	Zip: '',
});

