import axios from "axios";
import { Paged } from "core/definitions/utils/Paged";
import { CreateFiscalPeriodDTO } from "gen/dtos/CreateFiscalPeriodDTO";
import { CreateFiscalYearDTO } from "gen/dtos/CreateFiscalYearDTO";
import { CreateSysValDTO } from "gen/dtos/CreateSysValDTO";
import { CreateSysValsForYearDTO } from "gen/dtos/CreateSysValsForYearDTO";
import { UpdateFiscalPeriodDTO } from "gen/dtos/UpdateFiscalPeriodDTO";
import { UpdateFiscalYearDTO } from "gen/dtos/UpdateFiscalYearDTO";
import { UpdateSysValDTO } from "gen/dtos/UpdateSysValDTO";
import { FiscalPeriod } from "gen/models/FiscalPeriod";
import { FiscalYear } from "gen/models/FiscalYear";
import { SysVal } from "gen/models/SysVal";
import { queryCache, useMutation, useQuery } from "react-query";

//#region SysVals 

// Create SysVal
export const createSysVal = async (request: CreateSysValDTO) => await axios.post<SysVal>(
  "/values/sysvals",
  request, 
);
export const useCreateSysVal = () => useMutation(createSysVal, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getSysVals"])})


// Create SysVals for year 
export const createSysValsForYear = async (request: CreateSysValsForYearDTO) => await axios.post<SysVal>(
  "/values/sysvals/year",
  request, 
);
export const useCreateSysValsForYear = () => useMutation(createSysValsForYear, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getSysVals"])})


// Update SysVal
export const updateSysVal = async (request: UpdateSysValDTO & { SysValID: number }) => await axios.put<SysVal>(
  `/values/sysvals/${request.SysValID}`,
  request, 
);
export const useUpdateSysVal = () => useMutation(updateSysVal, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getSysVals"])})


// Delete SysVal
export const deleteSysVal = async (sysValID: number) => await axios.delete<any>(
  `/values/sysvals/${sysValID}`
);
export const useDeleteSysVal = () => useMutation(deleteSysVal, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getSysVals"])})


// Get SysVals 
export const getSysVals = async (_: string, sysValType: number, year: number, month: number, objectType: number, objectID: number, page: number, limit: number, orderBy: string, orderDir: string, refresh: boolean) => await axios.get<Paged<SysVal>>(`/values/sysvals?sysValType=${sysValType}&year=${year}&month=${month}&objectType=${objectType}&objectID=${objectID}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetSysVals = (sysValType: number, year: number, month: number, objectType: number, objectID: number, page: number, limit: number, orderBy: string, orderDir: string, refresh: boolean) => useQuery(["getSysVals", sysValType, year, month, objectType, objectID, page, limit, orderBy, orderDir, refresh], getSysVals);

//#endregion 

//#region Years

// Create Fiscal Year
export const createFiscalYear = async (request: CreateFiscalYearDTO) => await axios.post<FiscalYear>(
  "/values/years",
  request, 
);
export const useCreateFiscalYear = () => useMutation(createFiscalYear, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getFiscalYears"])})


// Update Fiscal Year
export const updateFiscalYear = async (request: UpdateFiscalYearDTO & { Year: number }) => await axios.put<FiscalYear>(
  `/values/years/${request.Year}`,
  request, 
);
export const useUpdateFiscalYear = () => useMutation(updateFiscalYear, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getFiscalYears"])})


// Delete Fiscal Year
export const deleteFiscalYear = async (fiscalYear: number) => await axios.delete<any>(
  `/values/years/${fiscalYear}`
);
export const useDeleteFiscalYear = () => useMutation(deleteFiscalYear, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getFiscalYears"])})


// Get Fiscal Years
export const getFiscalYears = async (_: string) => await axios.get<FiscalYear[]>(`/values/years`);
export const useGetFiscalYears = () => useQuery(["getFiscalYears"], getFiscalYears);

//#endregion Years

//#region Periods

// Create Fiscal Period
export const createFiscalPeriod = async (request: CreateFiscalPeriodDTO) => await axios.post<FiscalPeriod>(
  `/values/years/${request.FiscalYearID}/periods`,
  request, 
);
export const useCreateFiscalPeriod = () => useMutation(createFiscalPeriod, { 
  onSuccess: (data, variable) => {
    console.log('createFiscalPeriod.onSuccess', variable, data); 
    queryCache.invalidateQueries(["getFiscalPeriods", variable.FiscalYearID])
  }
})


// Update Fiscal Period
export const updateFiscalPeriod = async (request: UpdateFiscalPeriodDTO & { FiscalYearID: number; FiscalPeriodID: number }) => await axios.put<FiscalPeriod>(
  `/values/years/${request.FiscalYearID}/periods/${request.FiscalPeriodID}`,
  request, 
);
export const useUpdateFiscalPeriod = () => useMutation(updateFiscalPeriod, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getFiscalPeriods", variable.FiscalYearID])})


// Delete Fiscal Period
export const deleteFiscalPeriod = async (args : { fiscalYearID: number, fiscalPeriodID: number }) => await axios.delete<any>(
  `/values/years/${args.fiscalYearID}/periods/${args.fiscalPeriodID}`
);
export const useDeleteFiscalPeriod = () => useMutation(deleteFiscalPeriod, { onSuccess: (data, variable) => queryCache.invalidateQueries(["getFiscalPeriods", variable.fiscalYearID])})


// Get Fiscal Years
export const getFiscalPeriods = async (_: string, fiscalYearID: number) => await axios.get<FiscalPeriod[]>(`/values/years/${fiscalYearID}/periods`);
export const useGetFiscalPeriods = (fiscalYearID: number) => useQuery(["getFiscalPeriods", fiscalYearID], getFiscalPeriods);

//#endregion Periods
