/**
 *
 * ListItem
 */

export type ListItem = {

	// AccountID int64
	AccountID: number;

	// Alias string
	Alias: string;

	// DateCreated int64
	DateCreated: number;

	// IsActive int
	IsActive: number;

	// IsDeleted int
	IsDeleted: number;

	// ListID int64
	ListID: number;

	// ListItemID int64
	ListItemID: number;

	// Name string
	Name: string;

	// OldID int64
	OldID: number;

	// Ordinal int64
	Ordinal: number;

	// ParentListItemID int64
	ParentListItemID: number;

	// StringID string
	StringID: string;

}

// newListItem is a factory method for creating new ListItem objects
export const newListItem = () : ListItem => ({ 
	AccountID: 0,
	Alias: '',
	DateCreated: 0,
	IsActive: 0,
	IsDeleted: 0,
	ListID: 0,
	ListItemID: 0,
	Name: '',
	OldID: 0,
	Ordinal: 0,
	ParentListItemID: 0,
	StringID: '',
});

