import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable } from 'core/components/tables';
import { DBSchemaStatsAggregate } from 'gen/aggregates/DBSchemaStatsAggregate';
import { DBStatAggregate } from 'gen/aggregates/DBStatAggregate';
import { DBTableStatAggregate } from 'gen/aggregates/DBTableStatAggregate';
import { getAllDBStats } from 'gen/routes/AdminDB';
import React, { useEffect, useState } from 'react';
import AdminHeader from '../common/AdminHeader';

const DBList = () => { 

    const [schemas, setSchemas] = useState<DBSchemaStatsAggregate[]>([]); 
    const [schema, setSchema] = useState<DBSchemaStatsAggregate | undefined>(undefined); 
    const [database, setDatabase] = useState<DBStatAggregate | undefined>(undefined); 

    useEffect(() => { 
        getAllDBStats()
            .then(result => { 
                setSchemas(result.data); 
            })
    }, [])

    const selectSchema = (name: string) => () => { 
        const schema = schemas.find(x => x.SchemaName === name); 
        setSchema(schema);
        if(schema) { 
            setDatabase(schema.Databases[0]); 
        }
    }

    const selectDatabase = (name: string) => () => { 
        if(schema) { 
            setDatabase(schema.Databases.find(x => x.Name === name)); 
        }
    }

    const defaultGray = '#666'; 

    const sizeColor = (size: number) : string => { 

        if(size < 1) {
            return defaultGray; 
        }

        if(size < 100) { 
            return '#000'; 
        }

        if(size < 1000) { 
            return '#c50'; 
        }

        return '#f00';
    }

    const rowCountColor = (size: number) : string => { 

        if(size < 5000) {
            return defaultGray; 
        }

        if(size < 10000) { 
            return '#000'; 
        }

        if(size < 50000) { 
            return '#c50'; 
        }

        return '#f00';
    }


    return (
        <>
            <NavBarSecondary right={
                <>
                    {/* <TableNav {
                        ...{count: data.data.Count, prev, next, page, limit: 20 }
                    } /> */}

                    {/* <AdminActions actions={[
                        { title: 'View Disabled', fn: gotoDisabled, perm: AdminUsers_GetDisabledUsersPermission }
                    ]} />  */}
                </>

            }>
                <AdminHeader title="Databases" /> 
            </NavBarSecondary>
            <Grid container spacing={0} style={{ height: 'calc(100% - 48px)' }}>
                <Grid item xs={1}>
                    <List>
                        {schemas.map((s, k) => 
                            <ListItem style={{ backgroundColor: schema && s.SchemaName === schema.SchemaName ? '#9f9' : 'transparent' }}  button onClick={selectSchema(s.SchemaName)}>
                                <ListItemText primary={s.SchemaName} />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item xs={3}>
                    <List>
                        {schema !== undefined && 
                            schema.Databases.map((db, l) => (
                                <ListItem 
                                    style={{ backgroundColor: database && db.Name === database.Name ? '#9f9' : 'transparent' }}
                                    button 
                                    onClick={selectDatabase(db.Name)}>
                                    <ListItemText 
                                        primary={`${db.Name} (${db.Shard})`} 
                                        secondary={`${db.Size} MB | ${db.Host}`} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Grid>
                <Grid item xs={8} style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                    {database !== undefined && 
                        <DTable
                            cols={[
                                { label: 'Name', size: 1, val: (v: DBTableStatAggregate) => <span style={{ fontWeight: 'bold' }}>{v.TableName}</span>},
                                { label: 'Collation', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: defaultGray }}>{v.TableCollation}</span>},
                                { label: 'Engine', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: defaultGray }}>{v.TableEngine}</span>},
                                { label: 'Row Format', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: defaultGray }}>{v.TableRowFormat}</span>},
                                { label: 'Avg. Row Length', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: defaultGray, textAlign: 'right' }}>{v.TableAverageRowLength}</span>},
                                { label: 'Index Length', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: defaultGray, textAlign: 'right' }}>{v.TableIndexLength}</span>},
                                { label: 'Rows', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: rowCountColor(v.TableRows), textAlign: 'right' }}>{v.TableRows}</span>},
                                { label: 'Size', size: 1, val: (v: DBTableStatAggregate) => <span style={{ color: sizeColor(v.TableSize), textAlign: 'right', fontStyle: 'italic', }}>{v.TableSize.toFixed(2)} MB</span>},
                            ]}
                            data={database.Tables}
                        />                            
                    }
                </Grid>
            </Grid>

           
        </>
    )
}

export default DBList; 