import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useCreateJob } from 'app/api/jobs';
import { QuoteCustomerAggregate } from 'app/definitions/aggregates/QuoteCustomerAggregate';
import { QuoteCustomerType } from 'core/definitions/constants/quoteCustomerType';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateJobDTO } from 'gen/dtos/CreateJobDTO';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { selectedQuoteAtom, showCreateJobDialogAtom } from './state';

const CreateJobDialog = () => {

    const history = useHistory(); 
    const [quote] = useRecoilState(selectedQuoteAtom); 
    const [showing, setShowing] = useRecoilState(showCreateJobDialogAtom); 
    const [selectedCustomer, setSelectedCustomer] = useState({ customerID: 0, customerContactID: 0 }); 
    const [createJob, { status: createJobStatus, error: createJobError }] = useCreateJob(); 

    const close = () => {
        setShowing(false); 
    }

    const selectCustomer = (customer: QuoteCustomerAggregate) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.checked) {
            setSelectedCustomer({ customerID: customer.CustomerID, customerContactID: customer.CustomerContactID });
        } else {
            setSelectedCustomer({ customerID: 0, customerContactID: 0 }); 
        }
    }

    const doCreateJob = () => {
        createJob({ ...newCreateJobDTO(), QuoteID: quote.QuoteID, CustomerID: selectedCustomer.customerID, CustomerContactID: selectedCustomer.customerContactID, AwardDate: moment().format() })
            .then((response) => {
                if(!response) {
                    return 
                }
                setShowing(false); 
                history.push(`/jobs/${response.data.JobID}`);
            });
    }

    return <Dialog open={showing} onBackdropClick={close} onEscapeKeyDown={close}>

        <DialogTitle>Create Job From Quote #{quote.QuoteNumber}</DialogTitle>
        
        <DialogContent>

            <h3>Choose Customer</h3>

            {createJobStatus === "error" && 
                <Alert color="danger">{handleNetworkError(createJobError)}</Alert>
            }

            <List>
                {quote.Customers.filter(x => x.QuoteCustomerType !== QuoteCustomerType.QuoteCustomerTypeEngineer).map((customer, key) => 
                    <ListItem key={key} style={{ backgroundColor: selectedCustomer.customerID === customer.CustomerID ? "#efe" : "inherit"}}>
                        <ListItemIcon>
                            <Checkbox 
                                checked={selectedCustomer.customerID === customer.CustomerID}
                                onChange={selectCustomer(customer)}
                            />                               
                        </ListItemIcon>
                        <ListItemText 
                            primary={customer.Customer.Title} 
                            secondary={customer.Contact.FirstName + " " + customer.Contact.LastName + (customer.Contact.Role ? ", " + customer.Contact.Role : " ")}
                        /> 
                    </ListItem>
                )}
            </List>

        </DialogContent>
        <DialogActions>
            <Button onClick={doCreateJob} disabled={createJobStatus === "loading" || selectedCustomer.customerID === 0}>
                Create Job
                {createJobStatus === "loading" &&
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button onClick={close} disabled={createJobStatus === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default CreateJobDialog; 