import { Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, Input, InputLabel, List, ListItem, ListItemIcon, ListItemText, Switch } from '@material-ui/core';
import { Cancel, Delete, Save } from '@material-ui/icons';
import selectedManageRoleAtom from 'core/state/roles/selectedManageRoleAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newUpdateRoleDTO } from 'gen/dtos/UpdateRoleDTO';
import { Policy } from 'gen/models/Policy';
import { useGetAllPolicies } from 'gen/routes/Policies';
import { useAddPolicyToRole, useDeleteRole, useGetRoleFromID, useRemovePolicyFromRole, useUpdateRole } from 'gen/routes/Roles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

const RolePolicyList = () => {

    const selectedRoleID = useRecoilValue(selectedManageRoleAtom);

    if(selectedRoleID === 0) {
        return null; 
    }

    return (
        <RolePolicyListInner /> 
    )
} 

const RolePolicyListInner = () => {

    const [selectedRoleID, setSelectedRoleID] = useRecoilState(selectedManageRoleAtom); 
    const { data: policies, status: policiesStatus, error: policiesError } = useGetAllPolicies(0, 'Title', 'ASC'); 
    const { data: role, status: roleStatus, error: roleError } = useGetRoleFromID(selectedRoleID); 
    const [deleteRole] = useDeleteRole(); 
    const [updateRole] = useUpdateRole(); 
    
    const [removePolicyFromRole] = useRemovePolicyFromRole(); 
    const [addPolicyToRole] = useAddPolicyToRole(); 

    const [isEditingRoleTitle, setIsEditingRoleTitle] = useState(false); 
    const [editRole, setEditRole] = useState(newUpdateRoleDTO());  
    const [isDeletingRole, setIsDeletingRole] = useState(false); 
    const history = useHistory(); 

    if(policiesStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(policiesError)}
        </Alert>
    }

    if(policiesStatus === "loading" || !policies) {
        return <CircularProgress /> 
    }

    if(roleStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(roleError)}
        </Alert>
    }

    if(roleStatus === "loading" || !role) {
        return <CircularProgress /> 
    }

    const doDeleteRole = () => {
        deleteRole({ roleID: role.data.RoleID })
            .then(() => {
                setSelectedRoleID(0); 
                setIsDeletingRole(false); 
            }); 
    }

    const deleteRoleConfirm = () => {
        setIsDeletingRole(true); 
    }

    const doUpdateRole = () => {
        updateRole({ roleID: role.data.RoleID, body: editRole }) 
            .then(() => {
                setIsEditingRoleTitle(false); 
            });
    }

    const onRolePolicyChange = (policy: Policy) => (e) => {
        if (role.data.Policies.some(x => x.PolicyID === policy.PolicyID)) {
            removePolicyFromRole({ roleID: selectedRoleID, policyID: policy.PolicyID }); 
        } else {
            addPolicyToRole({ roleID: selectedRoleID, policyID: policy.PolicyID });
        }
    }

    const goto = (url: string) => () => {
        history.push(url); 
    }
    
    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>
            
            {isEditingRoleTitle && 
                
                <div style={{ height: 48 }}>
                    
                    <FormControl>
                        <InputLabel>Role Name</InputLabel> 
                        <Input value={editRole.Name} onChange={(e) => setEditRole({ ...editRole, Name: e.target.value as string })} /> 
                    </FormControl>
                    
                    <FormControl>
                        <FormControlLabel 
                            control={<Switch 
                                checked={editRole.IsDefault === 1} 
                                onChange={(e) => setEditRole({ ...editRole, IsDefault: e.target.checked ? 1 : 0 })} />} 
                                label="Apply to new users by default" 
                        />  
                    </FormControl>

                    <IconButton onClick={() => doUpdateRole()}>
                        <Save /> 
                    </IconButton>

                    <IconButton onClick={() => setIsEditingRoleTitle(false)}>
                        <Cancel /> 
                    </IconButton>

                </div>
            }

            {!isEditingRoleTitle && 

                <div style={{ display: 'flex', height: 48 }}>
                    
                    <div style={{ flexGrow: 1, lineHeight: '48px', verticalAlign: 'middle', height: 48 }} onClick={() => {  setIsEditingRoleTitle(true); setEditRole({ Name: role.data.Name, IsDefault: role.data.IsDefault }) }}>
                        {role.data.Name} {role.data.IsDefault > 0 ? <span>(default)</span> : ''}
                    </div>

                    <div>

                        {!isDeletingRole && 
                            <IconButton onClick={() => deleteRoleConfirm()}>
                                <Delete /> 
                            </IconButton>
                        }

                        {isDeletingRole && 
                            <>
                                <IconButton onClick={() => doDeleteRole()}>
                                    <Delete style={{ color: 'red' }} /> 
                                </IconButton>

                                <IconButton onClick={() => setIsDeletingRole(false)}>
                                    <Cancel /> 
                                </IconButton>
                            </>
                        }
                    </div>
                </div>
                
            }


            {/* <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'center'
                }}
                message="Role Created"
                open={createRoleStatus === ActionTypes.SUCCESS} onClose={() => null} 
            /> */}
        
            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto', borderTop: 'solid 1px #000'}}>
                <List>
                    {policies.data.map((policy, key) => {
                        return (
                            <ListItem key={key} button>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={role.data.Policies.some(x => x.PolicyID === policy.PolicyID)}
                                        onClick={onRolePolicyChange(policy)}
                                    /> 
                                </ListItemIcon>
                                <ListItemText 
                                    onClick={goto(`/admin/policies/${policy.PolicyID}`)}
                                    primary={policy.ShortName} 
                                    secondary={`${policy.Description} | ${policy.PermissionCount} permissions`}
                                    
                                />
                            </ListItem>
                        ); 
                    })}
                </List>
            </div>
    
        </div>
    )
}

export default RolePolicyList; 