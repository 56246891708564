import {  AppBar, Divider, Drawer, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { AccountCircle, Cancel, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ExpandMore, Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { Perm } from 'core/utils/hasPerm';
import { ISiteConfig } from 'core/utils/routes';
import userService from 'core/utils/userService';
import { useSelf, useWSStatus } from 'core/utils/userUtils';
import { AdminViewPermission, MainMenu_ViewPermission, Settings_ViewPermission } from 'gen/constants/permissions';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as appConfig from 'config.json'; 


const drawerWidth = 240;
const headerHeight = 64; 
const footerHeight = 20; 

// const ForwardedPerm = React.forwardRef((props, ref) => ( 
//   <Perm {...props} innerRef={ref} /> 
// ))

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%', 
    overflow: 'hidden', 
  },
  hideBorder: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
    '&.MuiPaper-elevation1':{
      boxShadow:'none',
      border:'none'
    }
  },

  title: {
    flexGrow: 1, 
    cursor: 'pointer', 
  },
  appBar: {
    backgroundColor: '#1a6f8c', 
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerHeaderTitle: {
    position: 'absolute', 
    left: 3, 
    top: 3
  },
  drawerHeaderTitleImage: {
    height: 50, 
    width: 'auto' 
  }, 
  drawerHeaderTitleText: {
    position: 'absolute', 
    top: 15, 
    left: 60, 
    width: 60
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    height: '100%', 
    overflow: 'hidden', 
  },
  innerContent: {
    height: 'calc(100% - ' + (headerHeight + footerHeight) + 'px)'
  }, 

  pageFooter: {
    position: 'fixed', 
    left: 0, 
    bottom: 0, 
    width: '100%', 
    backgroundColor: '#000', 
    color: 'white', 
    textAlign: 'center', 
    height: footerHeight, 
    lineHeight: footerHeight + 'px', 
    verticalAlign: 'middle',
    fontSize: '11px', 
  }, 

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  account: {
    margin: 0, 
  }, 
  menuItem: {
    paddingLeft: 16, 
  }, 
  menuSubItem: { 
    paddingLeft: 50, 
  }
}));

const AdminNavBar : React.FC<{ children: React.ReactNode, config: ISiteConfig }> = ({ children, config }) => {

  const user = useSelf(); 
  const wsStatus = useWSStatus(); 

  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory(); 
  const [anchorEl, setAnchorEl] = React.useState(null);   // Right Profile Menu View 
  const profileMenuOpen = Boolean(anchorEl); 
  const [panel, setPanel] = useState(""); 
  const handleDrawerOpen = () => setDrawerOpen(true);
  
  const handleDrawerClose = () => {
    if(drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const removeImpersonation = () => {
    userService.removeImpersonation(); 
    // const authHeader = getImpersonateCookie(); 
    // setAuthHeader(authHeader); 
    // localStorage.setItem("currentUser", localStorage.getItem("impersonateUser") as string); 
    // localStorage.removeItem("impersonateUser"); 
    // setAuthCookie(authHeader); 
    // deleteImpersonateCookie(); 
    // window.location.reload(); 
  }

  const isImpersonating = () : boolean => userService.isImpersonating(); 


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const goto = (path: string) => (e:any) => {
    e.preventDefault(); 
    history.push(path); 
    setDrawerOpen(false); 
    setAnchorEl(null); 
    setPanel("")
  }

  if(!user) { 
    return null; 
  }

  // console.log('currentUser', currentUser); 
  const togglePanel = (name: string) => () => {
    if(panel === name) {
        setPanel(''); 
    } else {
        setPanel(name); 
    }
}

const Accordion = withStyles({
  root: {
    border:"0px !important",
    boxShadow:'0 !important',
    margin: '0px !important',
    '&$expanded': {
      margin: '0px !important',
    },
  },

  content: {
    '&$expanded': {
      margin: 0,
    },
  },
})(ExpansionPanel);

const AccordionSummary = withStyles({
  root: {
      margin: 0, 
      '&$expanded': {
          margin: 0, 
      }
  }, 
  content: {
      '&$expanded': { 
          margin: '0px 0 !important', 
      }
  }
})(ExpansionPanelSummary);

  return (
    <div 
    className={classes.root}
    role="presentation"
      >
              <CssBaseline /> 
        <AppBar 
          position="fixed" 
          style={{ 
            backgroundColor: userService.getSetting("Header Background Color", '#1a6f8c'), 
            color: userService.getSetting("Header Font Color", '#fff'), 
          }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawerOpen, 
          })}>
          <Toolbar>
            <Perm perm={MainMenu_ViewPermission}>
              <IconButton 
                color="inherit" 
                aria-label="menu" 
                onClick={handleDrawerOpen}
                edge="start" 
                className={clsx(classes.menuButton, drawerOpen && classes.hide)} 
              >
                  <MenuIcon />
              </IconButton>
            </Perm>
            <Typography onClick={() => history.push(`/`)} variant="h6" className={clsx(classes.title, drawerOpen && classes.hide)} noWrap>
              {userService.getSetting("Site Title", 'JOC RFQ')}
              {(userService.user !== undefined && userService.user.AccountTitle.length > 0) && 
                <div style={{ display: 'inline-block', marginLeft: 20, fontSize: '.7em' }}>{userService.user?.AccountTitle}</div>
              }
            </Typography>

              <div className={clsx(classes.account, drawerOpen && classes.hide)}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={profileMenuOpen}
                  onClose={handleProfileMenuClose}
                >
                  <Perm perm={Settings_ViewPermission}>
                    <MenuItem onClick={goto("/settings")}>Settings</MenuItem>
                  </Perm>

                  <MenuItem onClick={() => userService.logout()}>Logout</MenuItem>
                </Menu>
                {user.FullName} {isImpersonating() && <IconButton size="small" onClick={() => removeImpersonation()}><Cancel /></IconButton>}
              </div>

          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className={classes.drawerHeaderTitle}>
              <img src={userService.getSetting('Site Logo', '/app/unnamed.png')} alt={userService.getSetting('Site Title', 'Attis Technology')} className={classes.drawerHeaderTitleImage} /> 
              <div className={classes.drawerHeaderTitleText}>
                {userService.getSetting('Site Title', '')}
              </div>
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>

            {config.NavLinks.map((navLink, key) => ( 

              navLink.Type === 'divider' ? <Divider key={key} /> : 
              navLink.Title !=="Reports"?
                (<Perm perm={navLink.Permission} key={key}>
                    <ListItem button onClick={goto(navLink.Path as string)} key={key}>
      <ListItemIcon>
        <i className={navLink.IconClasses} />
      </ListItemIcon>
      <ListItemText primary={navLink.Title} /> 
    </ListItem>
  </Perm>
):
(
                  
  <Accordion  className= { classes.hideBorder} expanded={panel === 'reports'} onChange={togglePanel('reports')} >
        <AccordionSummary className={classes.menuItem} expandIcon={<ExpandMore />}>
        <ListItemIcon style={{paddingTop:'9px'}}>
            <i className={navLink.IconClasses} />
            </ListItemIcon>
            <ListItemText primary={navLink.Title} />
        </AccordionSummary>
        <ExpansionPanelDetails style={{ display: 'block'}}>
          {navLink.SubItems?.map((subLink,key)=>{

          return <Perm perm={subLink.Permission} key={key}>
          <ListItem button onClick={goto(subLink.Path as string)} key={key}>
            <ListItemIcon>
              <i className={subLink.IconClasses} />
            </ListItemIcon>
            <ListItemText primary={subLink.Title} />
          </ListItem>
          </Perm>
          })}
        

        </ExpansionPanelDetails>
    </Accordion>
)
              
            ))}
          </List>

          <Divider /> 

          <Perm perm={AdminViewPermission}>
              <List>
                <ListItem className={classes.menuItem} button onClick={goto(`/admin`)}>
                  <ListItemIcon>
                    <i className={`fa fa-rocket fa-sm`} />
                  </ListItemIcon>
                  <ListItemText primary={`Admin`} /> 
                </ListItem>
              </List>
            {/* <AdminItems goto={goto} /> */}
          </Perm>           
            
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <div className={classes.drawerHeader} />
          <div className={classes.innerContent}>
            {children}
          </div>
          <div className={classes.pageFooter}> 
            {userService.getSetting("Site Title", 'Attis Technology')}
            - {wsStatus} - appConfig.version
          </div>

        </main>
      </div>
    );
}

export default AdminNavBar; 