import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel } from '@material-ui/core';
import { useUpdateCustomerContact } from 'app/api/customers';
import { newUpdateCustomerContactDTOFromModel } from 'app/definitions/dtos/UpdateCustomerContactDTO';
import handleNetworkError from 'core/utils/handleNetworkError';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { editingCustomerContactAtom } from '../quotes/state';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`
const EditContactModal = () => {

    const [contact, setContact] = useRecoilState(editingCustomerContactAtom);
    const [updateContact, { status, error, isLoading, isSuccess, data }] = useUpdateCustomerContact(); 
    const resetContact = useResetRecoilState(editingCustomerContactAtom); 

    const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact,
            FirstName: event.target.value,
        });
    };

    const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            LastName: event.target.value, 
        })
    }

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Email: event.target.value, 
        })
    }
    
    const onPhoneChanged = (value: string) => {
        setContact({
            ...contact, 
            Phone: value, 
        })
    }

    const onPhoneExtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            PhoneExt: parseInt(event.target.value as string), 
        })
    }
    const onCellChanged = (value: string) => {
        setContact({
            ...contact, 
            Cell: value, 
        })
    }
    const onRoleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({
            ...contact, 
            Role: event.target.value, 
        })
    }

    const save = () => {
        updateContact(newUpdateCustomerContactDTOFromModel(contact)); 
    }

    useEffect(() => {
        
        if(isSuccess && data) {

            resetContact(); 
        }
    }, [isSuccess, data, resetContact]); 


    return <Dialog open={contact.CustomerContactID > 0} onBackdropClick={() => resetContact()}>
        
        <DialogTitle>Edit A Contact</DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }

            {status === "success" && 
                <Alert color="success">Contact Updated</Alert>
            }

            <StyledFormControl>
                <InputLabel>First Name</InputLabel>
                <Input type="text" name="FirstName" value={contact.FirstName} onChange={onFirstNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Last Name</InputLabel>
                <Input type="text" name="LastName" value={contact.LastName} onChange={onLastNameChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Role</InputLabel>
                <Input type="text" name="Role" value={contact.Role} onChange={onRoleChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Email</InputLabel>
                <Input type="text" name="Email" value={contact.Email} onChange={onEmailChanged} /> 
            </StyledFormControl>
            
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    Phone: <ReactPhoneInput country="us" value={contact.Phone} onChange={onPhoneChanged} /> 
                </Grid>
                <Grid item xs={4}>
                    Ext. <Input type="number" value={contact.PhoneExt} onChange={onPhoneExtChanged} /> 
                </Grid>
            </Grid>
            Cell: <ReactPhoneInput country="us" value={contact.Cell} onChange={onCellChanged} /> 

                {/* <Input type="text" name="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />  */}


        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={status === "loading"}>
                Save
                {isLoading && 
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button 
                onClick={() => resetContact()} 
                disabled={isLoading}>
                    cancel
            </Button>
        </DialogActions>

    </Dialog>
}

export default EditContactModal; 