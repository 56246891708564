import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import selectedUserGroupAtom from 'core/state/users/selectedUserGroupAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useUpdateUserGroup } from 'gen/routes/UserGroups';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`
const EditUserGroupDialog = () => {

    // const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom); 
    const [selectedUserGroup, setSelectedUserGroup] = useRecoilState(selectedUserGroupAtom); 
    const resetSelectedUserGroup = useResetRecoilState(selectedUserGroupAtom); 
    const [ updateUserGroup, { status, error }] = useUpdateUserGroup(); 
    const save = () => {
        updateUserGroup({ groupID: selectedUserGroup.UserGroupID, body: selectedUserGroup })
            .then(() => {
                resetSelectedUserGroup(); 
            });
    }

    return <Dialog open={selectedUserGroup.UserGroupID > 0} onBackdropClick={() => resetSelectedUserGroup()}>

        <DialogTitle>Edit A User Group</DialogTitle>
        
        <DialogContent>

            {status === "error" && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }


            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Title" value={selectedUserGroup.Title} onChange={(e) => setSelectedUserGroup({ ...selectedUserGroup, Title: e.target.value })} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Description</InputLabel>
                <Input type="text" name="Description" multiline rowsMax={5} rows={5} value={selectedUserGroup.Description} onChange={(e) => setSelectedUserGroup({...selectedUserGroup, Description: e.target.value as string })} /> 
            </StyledFormControl>

        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={status === "loading"} color="primary">
                Save
                {status === "loading" &&
                    <CircularProgress /> 
                }
            </Button>
            <Button onClick={() => resetSelectedUserGroup()} disabled={status === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default EditUserGroupDialog; 