import { ListAggregate, newListAggregate } from 'core/definitions/aggregates/ListAggregate';
import { ListItemAggregate, newListItemAggregate } from 'core/definitions/aggregates/ListItemAggregate';
import { memoize } from 'core/utils/utils';
import { ListItem, newListItem } from 'gen/models/ListItem';
import { atom, RecoilState, selector, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

export const listItemsSelector = selector<ListItem[]>({
    key: 'selectedListItemsSelector', 
    get: ({ get }) => {
        const itemIDs = get(listItemIDsAtom)
        const items = itemIDs.map(id => get(listItemWithID(id)))
        return items; 
    }
})

export const listItemIDsAtom = atom<number[]>({ 
    key: `listItemIDsAtom`, 
    default: [], 
})

export const useInsertItem = () => {

    const [items, setItems] = useRecoilState(listItemIDsAtom); 

    return useRecoilCallback(
        ({set}) => {
            return (value: ListItemAggregate) => {
                const id = (value.ListItemID > 0) ? value.ListItemID : (items.length > 0 ? Math.max(...items) : -1) + 1; 
                setItems([...items, id]); 
                set(listItemWithID(id), value)
            }
        }
    )

}

export const useInsertItems= () => {

    const setItems = useSetRecoilState(listItemIDsAtom); 

    return useRecoilCallback(
        ({set}) => {

            return (value: ListItemAggregate[]) => {
                
                value.forEach(item => {

                    console.log("Insert Item", item.ListItemID)

                    set(listItemWithID(item.ListItemID), item)

                })
                
                // Set the ids 
                setItems(value.map((x, y) => x.ListItemID));

            }
        }
    )
}

const itemHeight = 58; 
const currentHoveringItemIdx = (totalItemCount: number, itemIdx: number, currentPosition: number) : number => {
    
    // The item hasn't moved
    if(currentPosition === 0) {
        return itemIdx; 
    }

    // Moving down 
    if(currentPosition > 0) {
        return itemIdx + Math.round(Math.abs(currentPosition) / itemHeight);
    }

    // Moving Up 
    else {
        return itemIdx - Math.round(Math.abs(currentPosition) / itemHeight); 
    }
}

export const listItemsCurrentHoveringItemSelector = selector<number>({
    key: "listItemsCurrentHoveringItemSelector", 
    get: ({ get }) => {

        const listItems = get(listItemsSelector)
        const movingListItemData = get(movingListItemDataAtom); 
        const movingListItemPosition = get(movingListItemPositionAtom); 

        return currentHoveringItemIdx(
            listItems.length, 
            movingListItemData.movingItemIndex, 
            movingListItemPosition
        );
        
    }
})

// export const totalAllocatedSaleSelector = selector<number>({
//     key: "totalAllocatedSaleSelector", 
//     get: ({ get }) => {
//         const items = get(itemsSelector); 
//         const sales = items.map(item => item.AllocatedSale); 
//         return sales.length > 0 ? sales.reduce((x, y) => x + y) : 0; 
//     }
// })

// export const totalCostSelector = selector<number>({
//     key: "totalCostSelector", 
//     get: ({ get }) => {
//         const items = get(itemsSelector); 
//         return items.map(item => item.Cost).reduce((x, y) => x + y, 0);
//     }
// })

// export const grossMarginSelector = selector<number>({
//     key: "grossMarginSelector", 
//     get: ({ get }) => {
//         const projectCost = get(totalCostSelector); 
//         const job = get(selectedJobAtom); 
//         return (1 - (projectCost / job.TotalPrice)) * 100 
//         // return (job.TotalPrice - projectCost) / job.TotalPrice
//     }
// })

// export const grossProfitSelector = selector<number>({
//     key: "grossProfitSelector", 
//     get: ({ get }) => {
//         const projectCost = get(totalCostSelector); 
//         const job = get(selectedJobAtom); 
//         return job.TotalPrice - projectCost
//     }
// })

// export const thirdPartyCommissionSelector = selector<number>({
//     key: "thirdPartyCommissionSelector", 
//     get: ({ get }) => {        
//         const job = get(selectedJobAtom); 
//         if(job.IsThirdPartySplit === 0) {
//             return 0
//         }
//         const grossProfit = get(grossProfitSelector); 
//         return grossProfit * (job.ThirdPartySplitPercent / 100)
//     }
// })

// export const remainingGrossProfitSelector = selector<number>({
//     key: "remainingGrossProfitSelector", 
//     get: ({ get }) => {
//         const grossProfit = get(grossProfitSelector); 
//         const thirdPartyCommission = get(thirdPartyCommissionSelector)
//         return grossProfit - thirdPartyCommission
//     }
// })

// export const totalSalesPercentSelector = selector<number>({
//     key: "totalSalesPercentSelector", 
//     get: ({ get }) => {
//         const job = get(selectedJobAtom); 
//         return job.Sales.map(sales => sales.CommissionPercent).reduce((x, y) => x + y, 0); 
//     }
// })




export const newItem = (id: number) => atom<ListItemAggregate>({
    key: `listItem${id}`, 
    default: newListItemAggregate(newListItem()), 
});

export const listItemWithID = memoize(newItem) as (id: number) => RecoilState<ListItemAggregate>; 

export const updateListItemOrdinalAtom = atom<boolean>({
    key: `updateListItemOrdinalAtom`, 
    default: false, 
}) 

export const movingListItemPositionAtom = atom<number>({ 
    key: "movingListItemPositionAtom", 
    default: -1
})

export const movingListItemDataAtom = atom<{ movingItemIndex: number, hoveringItemIndex: number }>({
    key: "movingListItemDataAtom", 
    default: {
        movingItemIndex: -1, 
        hoveringItemIndex: -1, 
    }, 
}); 

export const listItemParentTopAtom = atom<number> ({
    key: "listItemParentTop", 
    default: -1, 
});

export const selectedListAtom = atom<ListAggregate>({
    key: "selectedListAtom", 
    default: newListAggregate(), 
}); 

export const sortListByAtom = atom<"ordinal" | "title">({
    key: "sortListByAtom", 
    default: "ordinal", 
});

export const selectedListIDAtom = atom<number>({
    key: "selectedListIDAtom", 
    default: 0, 
}); 

export const selectedListItemAtom = atom<ListItem>({
    key: "selectedListItemAtom", 
    default: newListItem(), 
}); 

export const deleteListItemIDAtom = atom<number>({
    key: "deleteListItemIDAtom", 
    default: 0, 
}); 