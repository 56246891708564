import { Checkbox, CircularProgress, FormControlLabel, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Switch, TextField } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import ConfirmModal from 'core/components/confirmModal';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetAllPermissions } from 'gen/routes/Permissions';
import { useAddPermissionToPolicy, useDeletePolicy, useGetPolicyFromID, useRemovePermissionFromPolicy } from 'gen/routes/Policies';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { policyPermissionPageAtom, selectedManagePolicyAtom } from './state';
import UpdatePolicyDialog from './UpdatePolicyDialog';



// const useStyles = makeStyles(theme => ({
//     add: {
//         position: 'absolute', 
//         bottom: 20, 
//         right: 20, 
//     }
// }))

// const newUpdatePolicyDTOFromAggregate = (agg: PolicyAggregate) : UpdatePolicyDTO => ({ 
//     PermissionPolicyID: 0, 
//     Title: agg.Title, 
//     Description: agg.Description, 
//     ShortName: agg.ShortName, 
// });

const PolicyPermissionList = () => {
    
    const selectedPolicyID = useRecoilValue(selectedManagePolicyAtom);

    if(selectedPolicyID === 0) {
        return null; 
    }

    return (
        <PolicyPermissionListInner />
    )
}

const PolicyPermissionListInner = () => {

    const [permSearch, setPermSearch] = useState(''); 
    const [permFeature, setPermFeature] = useState(''); 
    const [showOnlyAppliedPermissions, setShowOnlyAppliedPermissions] = useState(false);
    const [selectedPolicyID, setSelectedPolicyID] = useRecoilState(selectedManagePolicyAtom); 
    const { data: permData, status: permsStatus, error: permsError } = useGetAllPermissions(); 
    const { data: policy, status: policyStatus, error: policyError } = useGetPolicyFromID(selectedPolicyID); 
    const [deletePolicy] = useDeletePolicy(); 
    const [addPermissionToPolicy] = useAddPermissionToPolicy(); 
    const [removePermissionFromPolicy] = useRemovePermissionFromPolicy(); 
    const [page, setPage] = useRecoilState(policyPermissionPageAtom); 
    const [isDeletingPolicy, setIsDeletingPolicy] = useState(false); 

    if(permsStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(permsError)}
        </Alert>
    }

    if(permsStatus === "loading" || !permData) {
        return <CircularProgress /> 
    }

    if(policyStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(policyError)}
        </Alert>
    }

    if(policyStatus === "loading" || !policy) {
        return <CircularProgress /> 
    }

    const doDeletePolicy = () => {
        deletePolicy({ policyID: policy.data.PolicyID })
            .then(() => {
                setSelectedPolicyID(0); 
                setIsDeletingPolicy(false); 
            }); 
    }

    const onRolePermChange = (permissionName: string) => (e) => {
        if (policy.data.Permissions.some(x => x.Permission === permissionName)) {
            removePermissionFromPolicy({ policyID: selectedPolicyID, permission: permissionName }); 
        } else {
            addPermissionToPolicy({ policyID: selectedPolicyID, permission: permissionName });
        }
    }

    const doSetPermSearch = (ps: string) => { 

        if(ps.length === 0 && permSearch.length === 0) { 
            return; 
        }

        if(page !== 0) {
            setPage(0);
        }

        setPermSearch(ps); 

    }

    const searchPerms = (perms : string[][]) : string[][] => { 
        return perms.filter(x => { 
            
            const p = x[0].toLowerCase(); 

            if(permFeature.length > 0 && (p.length < permFeature.length || p.substring(0, permFeature.length) !== permFeature.toLowerCase())) { 
                return false 
            }

            if(permSearch.length === 0) { 
                return true
            }

            if(p.length < permSearch.length) { 
                return false 
            }
            
            return p.includes(permSearch.toLowerCase())

        })
    }


    const pageSize = 30; 
    const pageFrom = page * pageSize; 
    const perms = searchPerms(showOnlyAppliedPermissions ? permData.data.filter(permission => policy.data.Permissions.some(x => x.Permission === permission[0])) : permData.data);
    const permissionsPage = perms.slice(page * pageSize, (page * pageSize) + pageSize);
    const total = perms.length; 
    const pageTo = pageFrom + pageSize > total ? total : pageFrom + pageSize; 

    type PermCategory = {
        name: string;  
        permissions: string[][];
    }

    let categories = new Map<string, string>();  

    permData.data.forEach((perm) => { 
        
        const categoryName = perm[0].split("_")[0]; 
        
        if(!categories.has(categoryName)) { 
            categories.set(categoryName, categoryName); 
        }

    });

    // let categoryEls = [] as React.ReactElement[];
    // {categories.forEach((value: PermCategory, key: string, map: Map<string, PermCategory>) => { 
    //     categoryEls.push(
    //         <ListItem key={key} button>
    //             <ListItemText primary={value.name} secondary={`${value.permissions.length}`} />
    //         </ListItem>
    //     )
    // })}


    return (
        <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>

            <div style={{ display: 'flex', height: 48, lineHeight: '48px', verticalAlign: 'middle' }}>
                
                    <div style={{ display: 'flex', flexGrow: 1 }}>
                    
                        <TextField 
                            value={permSearch} 
                            onChange={(e) => doSetPermSearch(e.target.value as string)} 
                            label="Search Permissions..." 
                            style={{ width: 200 }}
                        /> 
                        <TextField 
                            select 
                            label="Features"
                            value={permFeature} 
                            onChange={(e) => setPermFeature(e.target.value as string)}
                            style={{ width: 200 }}
                        >
                            <MenuItem value="">All Features</MenuItem>
                            {Array.from(categories.keys()).map((category, k) => (
                                <MenuItem value={category} key={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Switch checked={showOnlyAppliedPermissions} onChange={(e) => setShowOnlyAppliedPermissions(e.target.checked)} />}
                            label={`Show ${policy.data.PermissionCount} applied permissions`}
                        /> 
                        

                        <IconButton onClick={() => setPage(page - 1)} disabled={page === 0}>
                            <ChevronLeft />
                        </IconButton>
                        {total > 0 ? pageFrom + 1 : 0} - {pageTo} of {total} 

                        <IconButton onClick={() => setPage(page + 1)} disabled={pageTo >= total}>
                            <ChevronRight />
                        </IconButton>
                    </div>
            </div>
                
            


            {/* <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'center'
                }}
                message="Role Created"
                open={createRoleStatus === ActionTypes.SUCCESS} onClose={() => null} 
            /> */}
        
            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto', borderTop: 'solid 1px #000'}}>
                {permissionsPage.length === 0 && <Alert color="info" style={{ margin: 20 }}>No Permissions To Show</Alert>}
                <List>
                    {/* {categoryEls} */}
                    
                    
                    {permissionsPage.map((permission, key) => {
                     
                        const permissionApplied = showOnlyAppliedPermissions ? true : policy.data.Permissions.some(x => x.Permission === permission[0]);
                     
                        // if (showOnlyAppliedPermissions && !permissionApplied) {
                        //     return null; 
                        // }
                        return (
                            <ListItem key={key} button onClick={onRolePermChange(permission[0])}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={permissionApplied}
                                    /> 
                                </ListItemIcon>
                                <ListItemText 
                                    primary={permission[0]} 
                                    secondary={permission[1]}
                                />
                            </ListItem>
                        ); 
                    })}
                    
                    
                </List>
            </div>
    
            <ConfirmModal 
                title="Delete this Policy?"
                description="Are you sure you want to delete this policy?"
                open={isDeletingPolicy}
                setOpen={setIsDeletingPolicy}
                confirm={doDeletePolicy}
            /> 

            <UpdatePolicyDialog /> 

        </div>
    )
}

export default PolicyPermissionList; 