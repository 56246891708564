// ObjectTypeUnknown is used as a catchall when removing the objectType from the search criteria
export const ObjectTypeUnknown = 0
// ObjectTypeUserProfile is a UserProfile object type
export const ObjectTypeUserProfile = 1
// ObjectTypeSchool is a school object type
export const ObjectTypeSchool = 2
// ObjectTypeFolder is a Folder object type
export const ObjectTypeFolder = 3
// ObjectTypeClass is a class objectType
export const ObjectTypeClass = 4
// ObjectTypeMemo is a memo objectType
export const ObjectTypeMemo = 5
// ObjectTypeAssignment is an assignment objectType
export const ObjectTypeAssignment = 6
// ObjectTypeNewsletter is a newsletter objectType
export const ObjectTypeNewsletter = 7
// ObjectTypeQuote is a quote
export const ObjectTypeQuote = 8
// ObjectTypeTicket is a ticket
export const ObjectTypeTicket = 9
// ObjectTypeCustomerEvent is a customer event
export const ObjectTypeCustomerEvent = 10
// ObjectTypeProject is a project goal
export const ObjectTypeProject = 11
// ObjectTypeProjectGoal is a project goal
export const ObjectTypeProjectGoal = 12;
export const ObjectTypeCustomer = 13;
export const ObjectTypeOrg = 14;
export const ObjectTypePhoneNumber = 15;
export const ObjectTypeEmail = 16;
export const ObjectTypeTopic = 17;
export const ObjectTypeListItem = 18;
export const ObjectTypeFile = 19;
export const ObjectTypeTopicEvent = 20;
export const ObjectTypeAccount = 22;
export const ObjectTypeUserGroup = 23; 

export const ObjectTypeUser = 24; 
export const ObjectTypeVendor = 25; 
export const ObjectTypeTodo = 26
export const ObjectTypeJob = 27

export const ObjectTypes = [ 
    { Name: 'User', Value: ObjectTypeUser }, 
    { Name: 'Manufacturers', Value: ObjectTypeVendor }, 
]