import { newSearchQuoteDTO, newSearchQuoteDTOFromMap, SearchQuoteDTO } from 'app/definitions/dtos/SearchQuoteDTO';
import { newObjectSearchAggregate, ObjectSearchAggregate } from 'core/definitions/aggregates/ObjectSearchAggregate';
export type QuoteSearchAggregate = ObjectSearchAggregate<SearchQuoteDTO>;

export const newQuoteSearchAggregate = () : QuoteSearchAggregate => ({
    ...newObjectSearchAggregate(newSearchQuoteDTO()), 
})

export const newQuoteSearchAggregateFromMap = (map: Map<string, string>) : QuoteSearchAggregate => ({
    ...newObjectSearchAggregate(
        newSearchQuoteDTOFromMap(map), 
    )
})