import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, MenuItem, TextField } from "@material-ui/core";
import { AccountBox, Delete, Edit, Group, Help, Person, Public } from "@material-ui/icons";
import { Add } from "core/components/fabs";
import NavBarSecondary from "core/components/NavBarSecondary";
import { DTable, TableNav } from "core/components/tables";
import { DataTypeInt, DataTypeString } from "core/definitions/constants/dataTypes";
import { ObjectTypeAccount, ObjectTypeUserGroup, ObjectTypeUserProfile } from "core/definitions/constants/objectTypes";
import handleNetworkError from "core/utils/handleNetworkError";
import download from "downloadjs";
import { AdminSettings_ExportSettingsPermission, AdminSettings_ImportSettingsPermission } from "gen/constants/permissions";
import { newCreateSettingDTO } from "gen/dtos/CreateSettingDTO";
import { Setting } from "gen/models/Setting";
import { exportSettings, importSettings, useCreateSetting, useDeleteSetting, useGetAllSettings, useUpdateSetting } from "gen/routes/AdminSettings";
import React, { useState } from "react";
import { Alert } from "reactstrap";
import AdminActions from "../common/AdminActions";
import AdminHeader from "../common/AdminHeader";

const Settings = () => { 

    const objectType = 0; 
    const [page, setPage] = useState(0); 
    const limit = 50; 
    const [orderBy, setOrderBy] = useState('Name'); 
    const orderDir = 'ASC'; 
    const [importShowing, setImportShowing] = useState(false); 

    const [showCreate, setShowCreate] = useState(false); 
    const [newSetting, setNewSetting] = useState(newCreateSettingDTO()); 
    const [createSetting] = useCreateSetting(); 
    const [deleteSetting] = useDeleteSetting(); 


    const doImportSettings = () => setImportShowing(true); 
    const [selectedUploadFile, setSelectedUploadFile] = useState<File | undefined>(undefined); 
    const onImportFileChange = (e) => { 
        const el = (e.target as HTMLInputElement); 
        if(el && el.files) { 
            setSelectedUploadFile(el.files[0]);
        }
    } 
    const onImportSubmit = () => { 
        const formData = new FormData(); 

        if(!selectedUploadFile) { 
            return 
        } 
        formData.append(
            "myFile", 
            selectedUploadFile, 
            selectedUploadFile.name, 
        )

        importSettings({ body: formData })
            .then(() => { 
                alert("Settings uploaded...") 
                setImportShowing(false); 
            })
    }


    const [deleting, setDeleting] = useState<Setting | undefined>(undefined); 
    const doDelete = () => { 
        if(deleting) { 
            deleteSetting({ settingID: deleting.SettingID })
                .then(() => { 
                    setDeleting(undefined); 
                })
                .catch(e => { 
                    alert(handleNetworkError(e))
                });
        }
    }

    const doCreateSetting = () => { 
        createSetting({ body: newSetting })
            .then(() => { 
                setShowCreate(false)
                setNewSetting(newCreateSettingDTO()); 
            })
            .catch(e => alert(handleNetworkError(e)))
    }

    const [edit, setEdit] = useState<Setting | undefined>(undefined); 
    const [updateSetting] = useUpdateSetting(); 

    const doUpdateSetting = () => { 
        if(edit) { 
            updateSetting({ 
                settingID: edit.SettingID, 
                body: { 
                    Name: edit.Name, 
                    Description: edit.Description, 
                    DefaultValue: edit.DefaultValue,
                    DataType: edit.DataType, 
                }
            })
                .then(() => setEdit(undefined))
                .catch(e => alert(handleNetworkError(e)))
        }
    }
    const doExportSettings = () => { 
        exportSettings()
            .then(resp => { 
                download(resp.data, `settings.json`, resp.headers['content-type']); 
                console.log("settings exported");
            }); 
    }


    const { data, error, status } = useGetAllSettings(objectType, page, limit, orderBy, orderDir); 

    if(status === "error") { 
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" ||  !data) { 
        return <CircularProgress /> 
    }


    return (
        <div style={{ height: '100%', overflow: 'hidden'}}>
            
            <Add action={() => setShowCreate(true)} /> 

            <NavBarSecondary
                right={<>
                    
                    <TableNav 
                        next={() => setPage(page + 1)} 
                        prev={() => setPage(page - 1)} 
                        count={data.data.Count} 
                        limit={limit} 
                        page={page} 
                    /> 

                    <AdminActions actions={[
                        // { title: 'Rebuild', fn: doRebuildPermissions, perm: AdminUtils_RebuildPermissionsPermission }, 
                        { title: 'Export', fn: doExportSettings, perm: AdminSettings_ExportSettingsPermission },
                        { title: 'Import', fn: doImportSettings, perm: AdminSettings_ImportSettingsPermission },
                    ]} /> 
                </>}
            >
                <AdminHeader title={"Settings"} />
            </NavBarSecondary>


            <Dialog open={importShowing} onClose={() => setImportShowing(false)}>
                <DialogTitle>Import Settings</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={onImportFileChange} /> 
                    <Button variant="contained" onClick={onImportSubmit}>Upload</Button>
                </DialogContent>
            </Dialog>

            <Dialog open={deleting !== undefined} onClose={() => setDeleting(undefined)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the setting {deleting && deleting.Name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={doDelete}>Yes, delete</Button>
                    &nbsp; 
                    <Button onClick={() => setDeleting(undefined)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                maxWidth="sm"
                fullWidth
                open={showCreate} 
                onClose={() => setShowCreate(false)}>
                <DialogTitle>Create</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="Name" 
                        value={newSetting.Name} 
                        onChange={(e) => setNewSetting({ ...newSetting, Name: e.target.value as string })}
                        fullWidth
                    /> 
                    <br /> 
                    <TextField 
                        label="Description" 
                        value={newSetting.Description} 
                        onChange={(e) => setNewSetting({ ...newSetting, Description: e.target.value as string })}
                        fullWidth
                    /> 
                    <br /> 
                    <TextField 
                        label="Default Value" 
                        value={newSetting.DefaultValue} 
                        onChange={(e) => setNewSetting({ ...newSetting, DefaultValue: e.target.value as string })}
                        fullWidth
                    /> 
                    <br /> 
                    <TextField 
                        label="Scope" 
                        value={newSetting.ObjectType} 
                        onChange={(e) => setNewSetting({ ...newSetting, ObjectType: parseInt(e.target.value as string) })}
                        fullWidth
                        select
                    >
                        <MenuItem value={ObjectTypeUserProfile}>User</MenuItem>
                        <MenuItem value={ObjectTypeUserGroup}>User Group</MenuItem>
                        <MenuItem value={ObjectTypeAccount}>Account</MenuItem>
                        <MenuItem value={0}>Global</MenuItem>
                    </TextField> 
                    <br /> 
                    <TextField 
                        label="Data Type" 
                        value={newSetting.DataType} 
                        onChange={(e) => setNewSetting({ ...newSetting, DataType: parseInt(e.target.value as string) })}
                        fullWidth
                        select
                    >
                        <MenuItem value={DataTypeInt}>Int</MenuItem>
                        <MenuItem value={DataTypeString}>String</MenuItem>
                    </TextField> 
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={doCreateSetting}>Save</Button>
                    <Button onClick={() => setShowCreate(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={edit !== undefined} onClose={() => setEdit(undefined)} fullWidth>
                <DialogTitle>Edit Setting</DialogTitle>
                <DialogContent>
                    {edit && (<>
                        <TextField 
                            label="Name" 
                            value={edit.Name} 
                            onChange={(e) => setEdit({ ...edit, Name: e.target.value as string })}
                            fullWidth
                        /> 
                        <br /> 
                        <TextField 
                            label="Description" 
                            value={edit.Description} 
                            onChange={(e) => setEdit({ ...edit, Description: e.target.value as string })}
                            fullWidth
                        /> 
                        <br /> 
                        <TextField 
                            label="Default Value" 
                            value={edit.DefaultValue} 
                            onChange={(e) => setEdit({ ...edit, DefaultValue: e.target.value as string })}
                            fullWidth
                        /> 
                        <br /> 
                        <TextField 
                            label="Scope" 
                            value={edit.ObjectType} 
                            onChange={(e) => setEdit({ ...edit, ObjectType: parseInt(e.target.value as string) })}
                            fullWidth
                            disabled
                            select
                        >
                            <MenuItem value={0}>Global</MenuItem>
                            <MenuItem value={ObjectTypeAccount}>Account</MenuItem>
                            <MenuItem value={ObjectTypeUserProfile}>User</MenuItem>
                        </TextField> 
                        <br /> 
                        <TextField 
                            label="Data Type" 
                            value={edit.DataType} 
                            onChange={(e) => setNewSetting({ ...edit, DataType: parseInt(e.target.value as string) })}
                            fullWidth
                            select
                        >
                            <MenuItem value={DataTypeInt}>Int</MenuItem>
                            <MenuItem value={DataTypeString}>String</MenuItem>
                        </TextField> 
                    </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={doUpdateSetting}>Save</Button>
                    <Button onClick={() => setEdit(undefined)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
                <DTable 
                    data={data.data.Data}
                    cols={[
                        { label: "#", val: (v: Setting) => v.StringID }, 
                        { label: "Scope", orderCol: orderBy, orderFn: () => setOrderBy("Scope"), val: (v : Setting) => <><ScopeIcon scope={v.ObjectType} />{scopeString(v.ObjectType)}</>}, 
                        { label: "Name", orderCol: orderBy, orderFn: () => setOrderBy("Name"), val: (v : Setting) => v.Name }, 
                        { label: "Default", val: (v: Setting) => v.DefaultValue}, 
                        { label: "", val: (v: Setting) => (
                            <>
                            <IconButton size="small" onClick={() => setDeleting(v)}><Delete /></IconButton>
                            &nbsp; 
                            <IconButton size="small" onClick={() => setEdit(v)}><Edit /></IconButton>
                            </>
                        )}
                    ]}
                /> 
            </div>

        </div>
    )
}

const scopeString = (scope: number) : string => { 
    switch(scope) { 
        case ObjectTypeAccount: 
            return "Account";
        case ObjectTypeUserProfile: 
            return "User";
        case ObjectTypeUserGroup: 
            return "UserGroup"; 
        case 0:
            return "Global"
        default: 
            return "???"
    }
}

const ScopeIcon : React.FC<{ scope: number }> = ({ scope }) => { 
    switch(scope) { 
        case ObjectTypeAccount: 
            return <AccountBox />
        case ObjectTypeUserGroup:
            return <Group /> 
        case ObjectTypeUserProfile: 
            return <Person /> 
        case 0:
            return <Public />
        default: 
            return <Help />
    }
}
export default Settings; 