/**
 *
 * UserAggregate
 */
import { UserGroup } from 'gen/models/UserGroup';
import { ProfileAggregate, newProfileAggregate } from './ProfileAggregate';
import { User, newUser } from 'gen/models/User';
import { UserDevice, newUserDevice } from 'gen/models/UserDevice';
import { Policy } from 'gen/models/Policy';
import { Role } from 'gen/models/Role';
import { TagDTO } from 'gen/dtos/TagDTO';

export type UserAggregate = User & {

	// AccountTitle string
	AccountTitle: string;

	// Device *models.UserDevice
	Device: UserDevice;

	// Groups []*models.UserGroup
	Groups: UserGroup[];

	// PermissionNames []string
	PermissionNames: string[];

	// Policies []*models.Policy
	Policies: Policy[];

	// Profile *ProfileAggregate
	Profile: ProfileAggregate;

	// Roles []*models.Role
	Roles: Role[];

	// Settings map[string]string
	Settings: { [key: string]: string };

	// Tags []*dtos.TagDTO
	Tags: TagDTO[];

}

// newUserAggregate is a factory method for new UserAggregate objects
export const newUserAggregate = () : UserAggregate => ({
	...newUser(),
	AccountTitle: '',
	Device: newUserDevice(),
	Groups: [],
	PermissionNames: [],
	Policies: [],
	Profile: newProfileAggregate(),
	Roles: [],
	Settings: {},
	Tags: [],
});

