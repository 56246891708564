import React from 'react';

const MoneyFormat = (props: { val: number }) => {   

    const { val } = props; 

    if(val < 1000 && val > -.01) {
        return <span>{val.toFixed(2)}</span>
    }

    let [left, right] = val.toString().indexOf(".") > -1 ? val.toString().split(".") : [val.toString(), "00"]; 
    const isNegative = left.substr(0, 1) === "-"

    if(isNegative) {
        left = left.substr(1)
    }

    // console.log('left', left, 'right', right); 
    const n = Math.floor(left.length / 3); 
    // console.log('PartCount', n); 
    const rest = left.length % 3
    // console.log('rest', rest); 
    // console.log('Rest', rest); 
    const leftRest = left.substr(0, rest) 
    const leftNoRest = left.substr(rest)
    // console.log('leftRest', leftRest, 'leftNoRest', leftNoRest); 

    // console.log('leftRest', leftRest)
    // console.log('leftNoRest', leftNoRest)
    const rightString = (right.length > 2) ? right.substr(0, 2) : (right.length === 1 ? right + "0" : right); 
    let outLeft : string[] = []; 
    if(leftRest.length > 0) {
        outLeft.push(leftRest); 
    }
    for(let m = 0; m < n; m++) {
        const val = leftNoRest.substr(m * 3, 3)
        // console.log(`leftNoRest.substr(${m} * ${n}, 3)`, val)
        outLeft.push(val);
    }

    
    // console.log('outleft', outLeft); 
    // const outVal = parseFloat().toFixed(2); 
    // console.log('outVal', outVal); 
    return <span>{isNegative && "-"}{outLeft.join(',') + "." + rightString}</span> 

}

export default MoneyFormat;     