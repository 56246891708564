import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateCustomer } from 'app/api/customers';
import handleNetworkError from 'core/utils/handleNetworkError';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { createCustomerAtom, showCreateCustomerModalAtom } from '../quotes/state';
const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreateCustomerModal = () => {

    const [showCreateCustomerModal, setShowCreateCustomerModal] = useRecoilState(showCreateCustomerModalAtom); 


    const [createCustomer, { status: createStatus, data: createData, error: createError }] = useCreateCustomer(); 
    const resetCustomer = useResetRecoilState(createCustomerAtom); 
    const [customer, setCustomer] = useRecoilState(createCustomerAtom); 

    const onTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer,
            Title: event.target.value,
        });
    };

    const onAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Address: event.target.value, 
        })
    }

    const onAddress2Changed = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Address2: event.target.value, 
        })
    }
    
    const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            City: event.target.value, 
        })
    }

    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            State: event.target.value, 
        })
    }
    const onZipChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Zip: event.target.value, 
        })
    }
    const onPhoneChanged = (value: string) => {
        setCustomer({
            ...customer, 
            Phone: value, 
        })
    }
    const onFaxChanged = (value: string) => {
        setCustomer({
            ...customer, 
            Fax: value, 
        })
    }

    const save = () => {
        createCustomer(customer); 
    }

    useEffect(() => {
        if(createStatus === "success" && createData?.data) {
            setShowCreateCustomerModal(false); 
            resetCustomer(); 
        }

    }, [createStatus, createData, setShowCreateCustomerModal, resetCustomer])


    return <Dialog open={showCreateCustomerModal} onBackdropClick={() => setShowCreateCustomerModal(false)}>

        <DialogTitle>Create A Customer</DialogTitle>
        
        <DialogContent>

            {createStatus === "error" && 
                <Alert color="danger">{handleNetworkError(createError)}</Alert>
            }

            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Customer Name" value={customer.Title} onChange={onTitleChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address</InputLabel>
                <Input type="text" name="Address" value={customer.Address} onChange={onAddressChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address (cont.)</InputLabel>
                <Input type="text" name="Address2" value={customer.Address2} onChange={onAddress2Changed} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>City</InputLabel>
                <Input type="text" name="City" value={customer.City} onChange={onCityChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>State</InputLabel>
                <Input type="text" name="State" value={customer.State} onChange={onStateChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Zip</InputLabel>
                <Input type="text" name="Zip" value={customer.Zip} onChange={onZipChanged} /> 
            </StyledFormControl>

            Phone: <ReactPhoneInput country="us" value={customer.Phone} onChange={onPhoneChanged} /> 
            <br /> 
            Fax: <ReactPhoneInput country="us" value={customer.Fax} onChange={onFaxChanged} /> 

                {/* <Input type="text" name="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />  */}

        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={createStatus === "loading"}>
                Create
                {createStatus === "loading" &&
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button onClick={() => setShowCreateCustomerModal(false)} disabled={createStatus === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default CreateCustomerModal; 