/**
 *
 * CreateObjectTodoDTO
 */

export type CreateObjectTodoDTO = {

	// AssignedTo int64
	AssignedTo: number;

	// DateDue string
	DateDue: string;

	// Description string
	Description: string;

	// IsPlainLog int
	IsPlainLog: number;

	// ObjectID int64
	ObjectID: number;

	// ObjectType int64
	ObjectType: number;

	// Title string
	Title: string;

}

// newCreateObjectTodoDTO is a factory method for new CreateObjectTodoDTO objects
export const newCreateObjectTodoDTO = () : CreateObjectTodoDTO => ({
	AssignedTo: 0,
	DateDue: '',
	Description: '',
	IsPlainLog: 0,
	ObjectID: 0,
	ObjectType: 0,
	Title: '',
});

