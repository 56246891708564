import { Button, ButtonGroup, CircularProgress, IconButton, Menu, MenuItem, Select } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { downloadSummaryReportExcel, downloadSummaryReportPDF, useGetSummaryReport } from 'app/api/reports';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { Report } from 'core/components/tables';
import { ReportAggregate } from 'core/definitions/aggregates/ReportAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Reports_DownloadSummaryReportPDFPermission } from 'gen/constants/permissions';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

const quoteReportsYearAtom = atom<number>({ 
    key: 'quoteReportsYearAtom', 
    default: parseInt(moment().format('YYYY'))
});

const TabSales = 'sales'; 
const TabManufacturers = 'manufacturers'; 

const quoteReportsTabAtom = atom<string>({ 
    key: 'quoteReportsTabAtom', 
    default: TabSales, // Sales
})

const SummaryReport = () => {

    const year = useRecoilValue(quoteReportsYearAtom); 
    const tab = useRecoilValue(quoteReportsTabAtom); 
    const {data, error, status} = useGetSummaryReport(year, tab); 


    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    // const table = data.data; 

    return <SummaryReportInner data={data.data} /> 

    // ['Sales Volume Quoted', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Number of projects booked', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ], 
    // ['Sales Volume Booked - Equipment', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Sales Volume Booked - Parts', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Sales Volume Total', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['GM Volume - Equipment', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['GM Volume - Parts', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['GM %', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Booking Goal', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Gross Margin Goal', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['Booking Variance', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 
    // ['GM Variance', 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00 ], 

}

const SummaryReportInner : React.FC<{ data: ReportAggregate }> = ({ data }) => {

    const [year, setYear] = useRecoilState(quoteReportsYearAtom); 
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); 

    const [tab, setTab] = useRecoilState(quoteReportsTabAtom); 

    const gotoYear = (e) => {
        setYear(parseInt(e.target.value as string))
    }

    const handleMenuClick = (e) => {
        setMenuAnchorEl(e.currentTarget);
    }

    const doDownloadReportsExcel = () => {
        downloadSummaryReportExcel(year,tab)
        .then(() => console.log('downloaded!')); 
    }

    const doDownloadReportsPDF = () => {
        downloadSummaryReportPDF(year,tab)
            .then(() => console.log('downloaded!')); 
    }

    const thisYear = parseInt(moment().format('YYYY')); 
    let dates : number[] = [2020]; 
    const l = thisYear - 2020; 
    for(let k = 1; k < (l + 1); k++) {
        // console.log(2020 + k)
        dates.push(2020 + k); 
    }

    // @test
    return (
        <div style={{ height: '100%', overflowY: 'hidden', width: '100%', overflowX: 'hidden'}}>
            <NavBarSecondary right={
                    <div style={{ display: 'flex'}}>
                        <Perm perm={Reports_DownloadSummaryReportPDFPermission}>
                            <>
                                <IconButton onClick={handleMenuClick}>
                                    <CloudDownload /> 
                                </IconButton>
                                
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    keepMounted
                                    open={Boolean(menuAnchorEl)}
                                    onClose={() => setMenuAnchorEl(null)}
                                >
                                    <Perm perm={Reports_DownloadSummaryReportPDFPermission}>
                                        <MenuItem onClick={doDownloadReportsExcel}>
                                            Download Excel
                                        </MenuItem>
                                    </Perm>

                                    <Perm perm={Reports_DownloadSummaryReportPDFPermission}>  
                                        <MenuItem onClick={doDownloadReportsPDF}>
                                            Download PDF
                                        </MenuItem>
                                    </Perm>
                                </Menu>
                            </>
                        </Perm>
                    </div>
                }>
                <strong>Summary</strong>

                <Select value={year} onChange={gotoYear}>
                    {dates.map((date, key) => <MenuItem key={key} value={date}>{date}</MenuItem>)}
                </Select>

                <ButtonGroup variant="contained" color="primary">
                    <Button style={tab === TabSales ? { backgroundColor: "#3f51b5" } : { backgroundColor: '#444'}} onClick={() => setTab(TabSales)}>Sales</Button>
                    <Button style={tab === TabManufacturers ? { backgroundColor: "#3f51b5" } : { backgroundColor: '#444'}} onClick={() => setTab(TabManufacturers)}>Manufacturers</Button>
                </ButtonGroup>

            </NavBarSecondary>
            
            <Report report={data}  title={"Summary Report"} /> 
            
        </div>
    )
}



export default SummaryReport; 