import { CircularProgress } from '@material-ui/core';

import handleNetworkError from 'core/utils/handleNetworkError';
import { verifyUserNonce } from 'gen/routes/Verify';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';

const Verify = () => {

    const { id } = useParams() as { id: string }; 
    const [message, setMessage] = useState({ type: '', message: '' }); 
    const history = useHistory(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        verifyUserNonce(id) 
            .then(response => { 
                
                setMessage({ type: 'success', message: 'Your account has been verified. Redirecting to login...' }); 
                
                setTimeout(() => {  

                    // debugger
                    if(response.data.RedirURL.length > 0) { 
                        history.push(response.data.RedirURL)
                        // debugger
                    } else { 
                        history.push(`/login`); 
                    }

                }, 1000); 

            }).catch((e) => setMessage({ 
                type: 'danger', 
                message: handleNetworkError(e)
            }))
    }, [id])
 
    if(message.type === '') {
        return <CircularProgress /> 
    }

    return (
        
       <Alert color={message.type}>{message.message}</Alert>
    )
   
}

export default Verify; 