import { Checkbox, CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Role } from 'gen/models/Role';
import { useAddPolicyToRole, useGetRoles, useGetRolesByPolicy, useRemovePolicyFromRole } from 'gen/routes/Roles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { selectedManagePolicyAtom } from './state';


const PolicyRoleList = () => {

    console.log('PolicyPermissionList.render()'); 
    
    const selectedPolicyID = useRecoilValue(selectedManagePolicyAtom);

    if(selectedPolicyID === 0) {
        return null; 
    }

    return (
        <PolicyRoleListInner />
    )
}

const PolicyRoleListInner = () => {

    const history = useHistory(); 
    const selectedPolicyID = useRecoilValue(selectedManagePolicyAtom); 
    const { data: policyRoles, error, status } = useGetRolesByPolicy(selectedPolicyID); 
    const { data : allRoles, error: roleError, status: roleStatus } = useGetRoles(0, 'Name', 'ASC'); 
    const [removePolicyFromRole] = useRemovePolicyFromRole(); 
    const [addPolicyToRole] = useAddPolicyToRole();     

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !policyRoles) {
        return <CircularProgress /> 
    }

    if(roleStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(roleError)}
        </Alert>
    }

    if(roleStatus === "loading" || !allRoles) {
        return <CircularProgress /> 
    }

    const onRolePolicyChange = (role: Role) => (e : React.ChangeEvent) => {
        if (policyRoles.data.some(x => x.RoleID === role.RoleID)) {
            removePolicyFromRole({ roleID: role.RoleID, policyID: selectedPolicyID }); 
        } else {
            addPolicyToRole({ roleID: role.RoleID, policyID: selectedPolicyID });
        }
    }

    const gotoRole = (roleID: number) => () => {
        history.push(`/admin/roles/${roleID}`)
    }

    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>
        
            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
                <List>
                    {allRoles.data.Data.map((role, key) => {
                        
                        return (
                            <ListItem key={key} button>
                                <ListItemIcon>
                                    <Checkbox onChange={onRolePolicyChange(role)} checked={policyRoles.data.some(x => x.RoleID === role.RoleID)} /> 
                                </ListItemIcon>
                                <ListItemText 
                                    onClick={gotoRole(role.RoleID)}
                                    primary={role.Name} 
                                    secondary={`${role.UserCount} users | ${role.PolicyCount} policies`}
                                />
                            </ListItem>
                        ); 
                    })}
                </List>
            </div>
    
            {/* <ConfirmModal 
                title="Delete this Policy?"
                description="Are you sure you want to delete this policy?"
                open={isDeletingPolicy}
                setOpen={setIsDeletingPolicy}
                confirm={doDeletePolicy}
            />  */}

            {/* <UpdatePolicyDialog />  */}

        </div>
    )
}

export default PolicyRoleList; 