import {
  Checkbox,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import {
  ReportAggregate,
  ReportCell,
  ReportSortingAggregate,
} from "core/definitions/aggregates/ReportAggregate";
import { ColType } from "core/definitions/constants/colTypes";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MoneyFormat from "./MoneyFormat";
import { atom, useRecoilValue } from "recoil";
import { useGetSalesActivityReport } from "app/api/reports";

export const TableNavWrapper = styled.div`
  padding-left: 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: inline-block;
`;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  rowHighlighted: {
    backgroundColor: "#efe",
  },
}));

interface TableNavProps {
  limit: number;
  page: number;
  count: number;
  prev: () => void | null;
  next: () => void | null;
}

const defaultTableNavProps = {
  limit: 50,
  page: 0,
  count: 0,
};

/**
 * TableNav is for navigating table pagination
 * @param limit
 * @param page
 * @param count
 * @param prev () => void | null
 * @param next () => void | null
 * @returns
 */
export const TableNav = (
  props: TableNavProps & typeof defaultTableNavProps
) => {
  const { limit, page, count, prev, next } = props;

  const offset = page * limit;
  const from = count === 0 ? 0 : offset + 1;
  const to = offset + limit > count ? count : offset + limit;

  return (
    <TableNavWrapper>
      {from} - {to} of {count}
      <IconButton size="small" onClick={prev} disabled={offset === 0}>
        <ChevronLeft />
      </IconButton>
      <IconButton
        size="small"
        onClick={next}
        disabled={to >= count || count === 0}
      >
        <ChevronRight />
      </IconButton>
    </TableNavWrapper>
  );
};
export const TableCellColOrder: React.FC<{
  width: number;
  orderFn: () => void;
  isOrderBy: boolean;
  dir: string;
  children: React.ReactNode;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}> = ({ width, orderFn, isOrderBy, dir, children, align }) => {
  return (
    <TableCellSmall style={{ width: width }} onClick={orderFn} align={align}>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        {children}
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          {isOrderBy && (dir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}
        </div>
      </div>
    </TableCellSmall>
  );
};

export const TableCellSmall = styled(TableCell)`
  padding: 11px 0px;
`;

export const TableWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        {children}
      </Table>
    </TableContainer>
  );
};

export type Col = {
  size?: number;
  orderFn?: () => void;
  orderCol?: string;
  label: string;
  val: (v: any) => any;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  onClick?: (row: any) => () => void;
};

export const DTableWrapper = styled.div`
  height: calc(100% - 48px);
  overflowy: hidden;
`;

export const DTable: React.FC<{
  onRowCheck?: (row: any) => void;
  onRowClick?: (row: any) => void;
  hlRow?: (row: any) => boolean;
  cols: Col[];
  data: any[];
  orderBy?: string;
  orderDir?: string;
  checkboxes?: boolean;
  isChecked?: (row: any) => boolean;
  checkRow?: (row: any) => void;
  checkAll?: (check: boolean) => void;
  onNoRows?: () => React.ReactElement;
}> = ({
  onRowCheck,
  onRowClick,
  hlRow,
  cols,
  data,
  orderBy,
  orderDir,
  checkboxes,
  isChecked,
  checkRow,
  checkAll,
  onNoRows,
}) => {
  const checkboxWidth = 3;
  const totalWidthPercent = checkboxes ? 99.9 - checkboxWidth : 99.9;
  const classes = useStyles();
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const colPercent = (col: Col): number => {
    const units = col.size || 1;
    return units * (totalWidthPercent / cols.length);
  };

  const rowClick = (row: any) => () => onRowClick ? onRowClick(row) : null;
  const toggleCheckAll = () => {
    if (checkAll !== undefined) {
      checkAll(!isCheckedAll);
      setIsCheckedAll(!isCheckedAll);
    }
  };

  const rowChecked = isChecked !== undefined ? isChecked : (row) => false;
  const doCheckRow = checkRow !== undefined ? checkRow : (row) => null;

  if (data.length === 0 && onNoRows) {
    return onNoRows();
  }

  return (
    <DataTable>
      <DataHead>
        {checkboxes && (
          <DataHeaderOrdered percent={checkboxWidth} align="center">
            <Checkbox onChange={toggleCheckAll} checked={isCheckedAll} />
          </DataHeaderOrdered>
        )}

        {cols.map((col, key) => (
          <DataHeaderOrdered
            key={key}
            percent={colPercent(col)}
            orderFn={col.orderFn}
            isOrderBy={orderBy !== undefined && col.orderCol === orderBy}
            dir={orderDir}
            align={col.align}
          >
            {col.label}
          </DataHeaderOrdered>
        ))}
      </DataHead>

      <DataBody>
        {data.map((row, key) => (
          <DataRow
            key={key}
            onClick={rowClick(row)}
            className={hlRow && hlRow(row) ? classes.rowHighlighted : ""}
          >
            {checkboxes && (
              <DataCell
                style={{ padding: 0 }}
                percent={checkboxWidth}
                align="center"
              >
                <Checkbox
                  onChange={(e) => doCheckRow(row)}
                  checked={rowChecked(row)}
                />
              </DataCell>
            )}
            {cols.map((col, j) => (
              <DataCell
                key={j}
                percent={colPercent(col)}
                align={col.align}
                onClick={col.onClick ? col.onClick(row) : () => null}
              >
                {col.val(row)}
              </DataCell>
            ))}
          </DataRow>
        ))}
      </DataBody>
    </DataTable>
  );
};

export const DataCell: React.FC<{
  width?: number;
  percent?: number;
  color?: string;
  children?: React.ReactNode;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  style?: React.CSSProperties;
  onClick?: () => void;
}> = ({ width, percent, color, children, align, style, onClick }) => {
  const styles = {
    backgroundColor: color,
    textAlign: align,
    ...style,
  } as React.CSSProperties;

  if (width !== 150 && width !== 0) {
    styles.width = width;
  }

  if (percent) {
    styles.width = percent + "%";
  }

  return (
    <DataCellEl style={styles} onClick={onClick}>
      {children}
    </DataCellEl>
  );
};

export const DataTable = styled.div`
  display: block;
  overflow-x: scroll;
  border-left: solid 1px #ccc;
  border-top: solid 1px #ccc;
  width: 100%;
  height: calc(100%);
`;
export const DataCellEl = styled.div`
  display: inline-block;
  padding: 11px 4px;
  border-bottom: solid 1px rgba(224, 224, 224, 1);
  border-right: solid 1px rgba(140, 140, 140, 0.2);
  height: 100%;
  width: 150px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;
export const DataHead = styled.div`
  display: block;
  height: 48px;
  cursor: pointer;
  width: calc(100% - 15px);
  &:hover {
    background-color: #eee;
  }
  direction: ltr;
`;

export const DataRow = styled.div`
  display: block;
  height: 48px;
    cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  direction: ltr;
`;

export const DataBody = styled.div`
  height: calc(100% - 48px);
  overflow-y: scroll;
  width: calc(100%);
  overflow-x: scroll;
`;

export const DataHeader = styled.div`
  height: 48px;
  width: 100%;
  overflow-y: hidden;
overflow-x: hidden;
  padding-right: 30px;
`;

export const ReportWrapper = styled.div`
  height: calc(100% - 48px);
  width: 100%;
overflow: hidden;
  position: relative;
`;

export const ReportColLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
`;

// 48px (top column) + 20 (scroll bar)
export const ReportColLeftValuesWrapper = styled.div`
  height: calc(100% - 68px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 20px);
`;
// direction: rtl;

export const ReportColLeftValuesInner = styled.div`
  height: auto;
  direction: ltr;
`;

export const ReportColRight = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100%;
`;

export const DataHeaderOrdered: React.FC<{
  width?: number;
  percent?: number;
  orderFn?: () => void;
  isOrderBy?: boolean;
  dir?: string;
  children: React.ReactNode;
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
}> = ({ width, percent, orderFn, isOrderBy, dir, children, align }) => {
  const widthVal =
    width && width > 0
      ? width + "px"
      : percent && percent > 0
      ? percent + "%"
      : "150px";
  return (
    <DataHeaderEl
      style={{
        width: widthVal,
        textAlign: align,
        backgroundColor: isOrderBy ? "rgba(160, 220, 160, .2)" : "transparent",
      }}
      onClick={orderFn}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          textAlign: align,
          paddingRight: 20,
          boxSizing: "border-box",
        }}
      >
        {children}
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          {isOrderBy && (dir === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />)}
        </div>
      </div>
    </DataHeaderEl>
  );
};

export const DataHeaderEl = styled(DataCellEl)`
  font-weight: bold;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: #eee;
  padding: 0px;
  line-height: 48px;
  vertical-align: middle;
  border-right: none;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;

export const Cell: React.FC<{
  col: ReportCell;
}> = ({ col }) => {
  const width = col.Width ? col.Width : 0;
  const fontWeight = col.FontWeight || "normal";
  const textDecoration = col.Link ? "underline" : "none";

  return (
    <DataCell
      width={width}
      style={{ fontWeight, textDecoration }}
      align={cellAlign(col)}
    >
      {col.Link ? (
        <Link to={col.Link as string}>
          <CellValue col={col} />
        </Link>
      ) : (
        <div>
          <CellValue col={col} />
        </div>
      )}
    </DataCell>
  );
};

export const cellAlign = (
  col: ReportCell
): "left" | "right" | "center" | "justify" | "inherit" | undefined => {
  switch (col.Type) {
    case ColType.Float:
      return col.Align || "right";
    case ColType.Integer:
      return col.Align || "center";
    case ColType.Money:
      return col.Align || "right";
    case ColType.Percent:
      return col.Align || "right";
    case ColType.String:
      return col.Align || "center";
    default:
      return col.Align || "left";
  }
};

export const CellValue = (props: { col: ReportCell }) => {
  const { col } = props;

  switch (col.Type) {
    case ColType.Float:
      return <>{parseFloat(col.FloatVal.toString()).toFixed(2)}</>;
    case ColType.Integer:
      return <>{parseFloat(col.FloatVal.toString()).toFixed(0)}</>;
    case ColType.Money:
      return (
        <>
          $<MoneyFormat val={parseFloat(col.FloatVal.toString())} />
        </>
      );
    case ColType.Percent:
      return <>{parseFloat(col.FloatVal.toString()).toFixed(1)}%</>;
    case ColType.String:
      return <>{col.StringVal.toString()}</>;
    default:
      return <>{col.StringVal.toString()}</>;
  }
};

export const Report: React.FC<{
  report: ReportAggregate;
  sorting?: any;
  setSorting?: any;
  title?: string;
}> = ({ report, sorting, setSorting, title }) => {
  const fixedLeft = true;
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const leftColRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (headerRef.current) {
      headerRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }

    if (leftColRef.current) {
      leftColRef.current.scrollTop = e.currentTarget.scrollTop;
    }
  };

  const leftColHandleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = e.currentTarget.scrollTop;
    }
  };

  const reportWidth =
    report.Headings.map((heading, k) => {
      if (fixedLeft && k === 0) {
        return 0;
      }
      return heading.Width ? heading.Width :title === "Sales Activity Report" ?200: 150;
    }).reduce((p, c) => p + c) + 20;

  const leftColWidth = report.Headings[0] ? report.Headings[0].Width : 150;
  const orderCol = (fieldName: any) => () => {
    if (sorting?.OrderBy === fieldName) {
      toggleOrderDir();
      return;
    }
    setSorting({ ...sorting, OrderBy: fieldName, OrderDir: "ASC" });
  };

  const toggleOrderDir = () => {
    const dirName = sorting?.OrderDir === "ASC" ? "DESC" : "ASC";
    setSorting({ ...sorting, OrderDir: dirName });
  };

  const numCols: any =
    title === "Sales Activity Report" && report.Headings.length>20 ? report.Headings.length +2 : title === "Sales Activity Report" ? report.Headings.length : 25;
  const widthValue = report.Headings.length === 110 ? 90.65 : 90;

  const colWidth = (units: number): any => {
    const baseWidth = widthValue / numCols;
    const width = units * baseWidth;
    return width;
  };

  const valuesToSortBy: any = report?.Data.map(
    (innerArray) => innerArray[sorting?.OrderBy]?.FloatVal
  );
  const sortedIndices: any =
    sorting?.OrderDir === "ASC"
      ? Array.from({ length: report?.Data?.length }, (_, index) => index).sort(
          (a, b) => valuesToSortBy[a]-valuesToSortBy[b]
        )
      : Array.from({ length: report?.Data?.length }, (_, index) => index).sort(
          (a, b) => valuesToSortBy[b]-valuesToSortBy[a]
        );
  const sortedDataByColumn =
    sorting?.OrderDir === "ASC"
      ? sortedIndices.map((index) => report?.Data[index])
      : sortedIndices.map((index) => report?.Data[index]);
  report.Data.length = 0;
  Array.prototype.push.apply(report?.Data, sortedDataByColumn);
  return (
    <>
      <ReportWrapper>
        <ReportColLeft style={{ width: leftColWidth }}>
          <DataRow style={{ height: 49 }}>
            <Cell col={report.Headings[0]} />
          </DataRow>
          <ReportColLeftValuesWrapper
            ref={leftColRef}
            onScroll={leftColHandleScroll}
          >
            {report.Data.map((row: ReportCell[], key) => (
              <DataRow>
                <Cell key={key} col={row[0]} />
              </DataRow>
            ))}
          </ReportColLeftValuesWrapper>
        </ReportColLeft>
        <ReportColRight
          style={{
            width: `calc(100% - ${leftColWidth}px)`,
            left: leftColWidth,
          }}
        >
          <DataTable>
            <DataHeader ref={headerRef}>
              <DataRow style={{ width: reportWidth + "px" }}>
                {report.Headings.map((heading, key) => {
                  if (fixedLeft && key === 0) {
                    return null;
                  }

                  return (
                    <>
                      {title === "Sales Activity Report" ? (
                        <DataHeaderOrdered
                          percent={colWidth(heading?.Width === 0 ? 1.1 : 1.3)}
                          orderFn={orderCol(key)}
                          isOrderBy={sorting?.OrderBy === key}
                          dir={sorting?.OrderDir}
                        >
                          {heading?.StringVal}
                        </DataHeaderOrdered>
                      ) : (
                       <Cell key={key} col={heading} />
                      )}
                    </>
                  );
                })}
              </DataRow>
            </DataHeader>
            <DataBody onScroll={handleScroll} ref={bodyRef}>
              {report?.Data.map((row: ReportCell[], key) => {
                return (
                  <DataRow key={key} style={{ width: reportWidth + "px" }}>
                    {row.map((col, colKey) => {
                      if (fixedLeft && colKey === 0) {
                        return null;
                      }

                      return (
                        <>
                          {title === "Sales Activity Report" ? (
                            <DataCell
                              percent={colWidth(col?.Width === 0 ? 1.1 : 1.3)}
                            >
                              <CellValue col={col}></CellValue>
                            </DataCell>
                          ) : (
                            <Cell key={colKey} col={col} />
                          )}
                        </>
                      );
                    })}
                  </DataRow>
                );
              })}
            </DataBody>
          </DataTable>
        </ReportColRight>
      </ReportWrapper>
    </>
  );
};
