/**
 *
 * CreateCustomerContactDTO
 */

export type CreateCustomerContactDTO = {

	// Cell string
	Cell: string;

	// Email string
	Email: string;

	// FirstName string
	FirstName: string;

	// LastName string
	LastName: string;

	// MI string
	MI: string;

	// Phone string
	Phone: string;

	// PhoneExt int64
	PhoneExt: number;

	// Role string
	Role: string;

}

// newCreateCustomerContactDTO is a factory method for new CreateCustomerContactDTO objects
export const newCreateCustomerContactDTO = () : CreateCustomerContactDTO => ({
	Cell: '',
	Email: '',
	FirstName: '',
	LastName: '',
	MI: '',
	Phone: '',
	PhoneExt: 0,
	Role: '',
});

