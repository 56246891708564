import { Button, CircularProgress, IconButton, MenuItem, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { newJobSalesAggregate } from 'app/definitions/aggregates/JobSalesAggregate';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import MoneyFormat from 'core/components/MoneyFormat';
import { MoneyInput } from 'core/components/MoneyInput';
import { TableWrapper } from "core/components/tables";
import handleNetworkError from 'core/utils/handleNetworkError';
import { Job_EditHouseAccount } from 'gen/constants/permissions';
import { Perm } from 'core/utils/hasPerm';
import React from "react";
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PaperBox } from './common';
import { remainingGrossProfitSelector, selectedJobAtom, totalSalesPercentSelector } from './state';


const JobDetailSales = () => {

    const [job, setJob] = useRecoilState(selectedJobAtom)
    const remainingGrossProfit = useRecoilValue(remainingGrossProfitSelector)
    const totalSalesPercent = useRecoilValue(totalSalesPercentSelector); 
    const { data, status, error } = useGetMyAccountUsers(1, 0, 100); 

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }


    const addSales = () => setJob({ ...job, Sales: [...job.Sales, newJobSalesAggregate() ]})    
    const deleteSales = (k: number) => () => setJob({ ...job, Sales: job.Sales.filter((x, y) => k !== y )})

    return (
        <PaperBox>
        <TableWrapper>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} style={{ padding: 6 }}>
                        <Button style={{ padding: "4px 6px" }} onClick={addSales} size="small" variant="contained">
                            Add Sales
                        </Button>
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>Sales Team</TableCell>
                    <TableCell style={{ width: 30, padding: 6 }}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {job.Sales.map((sp, key) => 
                    <TableRow key={key}>
                        <TableCell style={{ padding: 6, width: 120 }}>
                        {sp.IsHouse !== 1 ?                            
                            <MoneyInput value={sp.CommissionPercent} percent onChange={(v) => setJob({ 
                                    ...job, 
                                    Sales: job.Sales.map((sales, k) => (k === key) ? {...job.Sales[k], CommissionPercent: v} : job.Sales[k])
                                })} 
                            /> : 
                            <Perm perm={Job_EditHouseAccount} otherwise={React.createElement("div",null,sp.CommissionPercent+"%")}>
                                <MoneyInput value={sp.CommissionPercent} percent onChange={(v) => setJob({ 
                                        ...job, 
                                        Sales: job.Sales.map((sales, k) => (k === key) ? {...job.Sales[k], CommissionPercent: v} : job.Sales[k])
                                    })} 
                                /> 
                                </Perm>}
                        </TableCell>
                        <TableCell align="right" style={{ padding: 6 }}>
                            $<MoneyFormat val={((sp.CommissionPercent/100) * remainingGrossProfit)} /> 
                        </TableCell>
                        <TableCell style={{ padding: 6}}>
                            {sp.IsHouse === 1 ? "House/JOC Sales" : <TextField select size="small" variant="outlined" value={sp.UserID} onChange={(e) => 
                                    setJob({ 
                                        ...job, 
                                        Sales: job.Sales.map((sales, k) => (k === key) ? { ...job.Sales[k], UserID: parseInt(e.target.value as string) } : job.Sales[k])
                                    })
                                }>
                                    {data.data.Data.map((user, k) => 
                                        <MenuItem value={user.UserID} key={k}>
                                            {user.FullName}
                                        </MenuItem>
                                    )}
                                </TextField>
                            }

                        </TableCell>
                        <TableCell style={{ width: 30, padding: 6 }}>
                            {sp.IsHouse !== 1 && <IconButton size="small" onClick={deleteSales(key)}>
                                <Delete /> 
                            </IconButton>}
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell>{totalSalesPercent.toFixed(0)}%</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableBody>
        </TableWrapper>
    </PaperBox>
)
}


export default JobDetailSales; 