/**
 *
 * Base.Version
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';
import { VersionDTO } from 'gen/dtos/VersionDTO';

// GetVersion returns the version of the app
// GET /version
export const getVersion = async () => await axios.get<VersionDTO>(`/version`);
export const useGetVersion = () => useQuery(["getVersion"], (_ : string) => getVersion());
