export const fetchURLQueryParamsFromString = (query: string) : Map<string, string> => {

    if(query.substring(0,1) === '?') {
        query = query.substring(1, query.length + 1); 
    }

    let paramPairs: string[] = []; 
    if(query.indexOf('&') > -1) {
        paramPairs = query.split('&') as string[]; 
    } else {
        paramPairs = [query]
    }

    var params = new Map();  

    paramPairs.forEach(paramPair => {
        if(paramPair.indexOf("=") === -1) {
            return;
        } 
        const kv = paramPair.split("="); 
        params.set(kv[0], kv[1]); 
        return; 
    })

    return params; 

}

export const mapToURL = (prefix: string, map: Map<string, string>) : string => {
    console.log("mapToURL?", map); 
    var urlParts : string[] = []; 
    map.forEach((value, key) => {
        console.log('map', key, value); 
        urlParts.push(key + "=" + value); 
    });

    return prefix + "?" + urlParts.join("&"); 
}
