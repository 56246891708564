/**
 *
 * CustomerContact
 */

export type CustomerContact = {

	// Cell string
	Cell: string;

	// CustomerContactID int64
	CustomerContactID: number;

	// CustomerID int64
	CustomerID: number;

	// DateCreated int64
	DateCreated: number;

	// Email string
	Email: string;

	// FirstName string
	FirstName: string;

	// IsDeleted int
	IsDeleted: number;

	// LastName string
	LastName: string;

	// MI string
	MI: string;

	// Phone string
	Phone: string;

	// PhoneExt int64
	PhoneExt: number;

	// Role string
	Role: string;

}

// newCustomerContact is a factory method for creating new CustomerContact objects
export const newCustomerContact = () : CustomerContact => ({ 
	Cell: '',
	CustomerContactID: 0,
	CustomerID: 0,
	DateCreated: 0,
	Email: '',
	FirstName: '',
	IsDeleted: 0,
	LastName: '',
	MI: '',
	Phone: '',
	PhoneExt: 0,
	Role: '',
});

