import { JobAggregate, newJobAggregate } from 'app/definitions/aggregates/JobAggregate';
import { newSearchJobDTO, SearchJobDTO } from 'app/definitions/dtos/SearchJobDTO';
import { newUpdateJobDTO, UpdateJobDTO } from 'app/definitions/dtos/UpdateJobDTO';
import { newObjectSearchAggregate, ObjectSearchAggregate } from 'core/definitions/aggregates/ObjectSearchAggregate';
import { memoize } from 'core/utils/utils';
import { newUpdateJobItemDTO, UpdateJobItemDTO } from 'gen/dtos/UpdateJobItemDTO';
import { Vendor } from 'gen/models/Vendor';
import { atom, RecoilState, selector, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

export const jobFilterPanelShowingAtom = atom<boolean> ({
    key: "jobFilterPanelShowingAtom", 
    default: false, 
})


export const jobSearchAtom = atom<ObjectSearchAggregate<SearchJobDTO>> ({
    key: "jobSearchAtom", 
    default: newObjectSearchAggregate(newSearchJobDTO()), 
})


export const searchJobTabAtom = atom<number> ({
    key: "searchJobTabAtom", 
    default: 0
})

export const selectedJobAtom = atom<JobAggregate>({
    key: "selectedJobAtom", 
    default: newJobAggregate(), 
}); 

export const selectedUpdateJobDTOAtom = atom<UpdateJobDTO>({
    key: "selectedUpdateJobDTOAtom", 
    default: newUpdateJobDTO(), 
}); 

export const selectedUpdateJobDTOItemsAtom = atom<UpdateJobItemDTO[]>({
    key: "selectedUpdateJobDTOItemsAtom", 
    default: [], 
}); 

export const itemsSelector = selector<UpdateJobItemDTO[]>({
    key: 'selectedUpdateJobItemsSelector', 
    get: ({ get }) => {
        const itemIDs = get(itemIDsAtom)
        const items = itemIDs.map(id => get(itemWithID(id)))
        return items; 
    }
})

export const itemIDsAtom = atom<number[]>({ 
    key: `jobItemIDs`, 
    default: [], 
})

export const useInsertItem = () => {

    const [items, setItems] = useRecoilState(itemIDsAtom); 

    return useRecoilCallback(
        ({set}) => {
            return (value: UpdateJobItemDTO) => {
                const maxID = items.length > 0 ? Math.max(...items) : -1
                setItems([...items, maxID + 1]); 
                // console.log("Insert Item", items, maxID + 1)
                set(itemWithID(maxID + 1), value)
            }
        }
    )

}

export const useInsertItems= () => {

    const setItems = useSetRecoilState(itemIDsAtom); 

    return useRecoilCallback(
        ({set}) => {

            return (value: UpdateJobItemDTO[]) => {
                
                value.forEach((item, k) => {

                    console.log("Insert Item", k)

                    set(itemWithID(k), item)

                })
                
                // Set the ids 
                setItems(value.map((x, y) => y));

            }
        }
    )

}

export const totalAllocatedProfitSelector = selector<number>({
    key: "totalAllocatedProfitSelector", 
    get: ({ get }) => {
        const items = get(itemsSelector)
        const profits = items.map(item => item.AllocatedProfit); 
        return profits.length > 0 ? profits.reduce((x, y) => x + y) : 0; 
    }
})

export const totalAllocatedSaleSelector = selector<number>({
    key: "totalAllocatedSaleSelector", 
    get: ({ get }) => {
        const items = get(itemsSelector); 
        const sales = items.map(item => item.AllocatedSale); 
        return sales.length > 0 ? sales.reduce((x, y) => x + y) : 0; 
    }
})

export const totalCostSelector = selector<number>({
    key: "totalCostSelector", 
    get: ({ get }) => {
        const items = get(itemsSelector); 
        return items.map(item => item.Cost).reduce((x, y) => x + y, 0);
    }
})

export const grossMarginSelector = selector<number>({
    key: "grossMarginSelector", 
    get: ({ get }) => {
        const projectCost = get(totalCostSelector); 
        const job = get(selectedJobAtom); 
        return (1 - (projectCost / job.TotalPrice)) * 100 
        // return (job.TotalPrice - projectCost) / job.TotalPrice
    }
})

export const grossProfitSelector = selector<number>({
    key: "grossProfitSelector", 
    get: ({ get }) => {
        const projectCost = get(totalCostSelector); 
        const job = get(selectedJobAtom); 
        return job.TotalPrice - projectCost
    }
})

export const thirdPartyCommissionSelector = selector<number>({
    key: "thirdPartyCommissionSelector", 
    get: ({ get }) => {        
        const job = get(selectedJobAtom); 
        if(job.IsThirdPartySplit === 0) {
            return 0
        }
        const grossProfit = get(grossProfitSelector); 
        return grossProfit * (job.ThirdPartySplitPercent / 100)
    }
})

export const remainingGrossProfitSelector = selector<number>({
    key: "remainingGrossProfitSelector", 
    get: ({ get }) => {
        const grossProfit = get(grossProfitSelector); 
        const thirdPartyCommission = get(thirdPartyCommissionSelector)
        return grossProfit - thirdPartyCommission
    }
})

export const totalSalesPercentSelector = selector<number>({
    key: "totalSalesPercentSelector", 
    get: ({ get }) => {
        const job = get(selectedJobAtom); 
        return job.Sales.map(sales => sales.CommissionPercent).reduce((x, y) => x + y, 0); 
    }
})




export const newItem = (id: number) => atom<UpdateJobItemDTO>({
    key: `updateJobDetailItem${id}`, 
    default: newUpdateJobItemDTO(), 
});

export const itemWithID = memoize(newItem) as (id: number) => RecoilState<UpdateJobItemDTO>; 

export const vendorsAtom = atom<Vendor[]>({ 
    key: `vendorsAtom`, 
    default: [], 
})

// export const selectedUpdateJobItemsSelector = selector<UpdateJobItemDTO[]>({
//     key: 'selectedUpdateJobItemsSelector', 
//     get: ({ get }) => get(selectedUpdateJobDTOAtom).Items, 
//     set: ({ set, get }, newValue) => {
//         console.log('selectedUpdateJobItemsSelector::set()', newValue)
//         set(selectedUpdateJobDTOAtom, { ...get(selectedUpdateJobDTOAtom), Items: newValue as UpdateJobItemDTO[]})
//     }
// })



export const updateJobStatusAtom = atom<{ error: Error | null | undefined, status: "loading" | "idle" | "error" | "success" }>({
    key: "updateJobStatusAtom", 
    default: { 
        error: undefined, 
        status: "idle", 
    }, 
}); 