/**
 *
 * Base.Account
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { AccountAggregate } from 'gen/aggregates/AccountAggregate';
import { UpdateAccountDTO } from 'gen/dtos/UpdateAccountDTO';
import { UserCollectionAggregate } from 'gen/aggregates/UserCollectionAggregate';

// GetMyAccount gets the account of the active user
// GET /account
// @permission Account_GetMyAccount
export const getMyAccount = async () => await axios.get<AccountAggregate>(`/account`);
export const useGetMyAccount = () => useQuery(["getMyAccount"], (_ : string) => getMyAccount());

// UpdateMyAccount updates an account
// PUT /account
// @permission Account_UpdateMyAccount
export const updateMyAccount = async (args : { body : UpdateAccountDTO }) => await axios.put<any>(`/account`, args.body);
export const useUpdateMyAccount = () => useMutation(updateMyAccount, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["getMyAccount"]);
	},
	throwOnError: true, 
});
	

// GetMyAccountUsers gets a collection of users across all accounts
// GET /account/users?groupID={groupID:[0-9]+}&page={page:[0-9]+}&limit={limit:[0-9]+}
// @permission Account_GetMyAccountUsers
export const getMyAccountUsers = async (groupID : number, page : number, limit : number) => await axios.get<UserCollectionAggregate>(`/account/users?groupID=${groupID}&page=${page}&limit=${limit}`);
export const useGetMyAccountUsers = (groupID : number, page : number, limit : number) => useQuery(["getMyAccountUsers", groupID, page, limit], (_ : string, groupID : number, page : number, limit : number) => getMyAccountUsers(groupID,page,limit));
