import { VendorItem } from "gen/models/VendorItem";

export type UpdateVendorItemDTO = {
    Title: string;  
    Description: string;  
    ModelNumber: string; 
    Cost: number; 
}

export const newUpdateVendorItemDTO = (model: VendorItem) : UpdateVendorItemDTO => ({
    Title: model.Title, 
    Description: model.Description, 
    ModelNumber: model.ModelNumber, 
    Cost: model.Cost,
});

