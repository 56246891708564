/**
 *
 * CreateFormDTO
 */
import { FormFieldDTO } from './FormFieldDTO';

export type CreateFormDTO = {

	// Description string
	Description: string;

	// Fields []*FormFieldDTO
	Fields: FormFieldDTO[];

	// Title string
	Title: string;

}

// newCreateFormDTO is a factory method for new CreateFormDTO objects
export const newCreateFormDTO = () : CreateFormDTO => ({
	Description: '',
	Fields: [],
	Title: '',
});

