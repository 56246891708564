/**
 *
 * TaskBatchLog
 */

export type TaskBatchLog = {

	// Content null.String
	Content: string;

	// DaemonCounter int64
	DaemonCounter: number;

	// DateCreated int64
	DateCreated: number;

	// DateFinished int64
	DateFinished: number;

	// DateScheduled int64
	DateScheduled: number;

	// DateStarted int64
	DateStarted: number;

	// FailedTasks int
	FailedTasks: number;

	// FinishedTasks int
	FinishedTasks: number;

	// IsDeleted int
	IsDeleted: number;

	// RunStatus int64
	RunStatus: number;

	// RunTime int64
	RunTime: number;

	// TaskBatchID int64
	TaskBatchID: number;

	// TaskBatchLogID int64
	TaskBatchLogID: number;

	// TaskBatchScheduleID int64
	TaskBatchScheduleID: number;

	// TotalTasks int
	TotalTasks: number;

}

// newTaskBatchLog is a factory method for creating new TaskBatchLog objects
export const newTaskBatchLog = () : TaskBatchLog => ({ 
	Content: '',
	DaemonCounter: 0,
	DateCreated: 0,
	DateFinished: 0,
	DateScheduled: 0,
	DateStarted: 0,
	FailedTasks: 0,
	FinishedTasks: 0,
	IsDeleted: 0,
	RunStatus: 0,
	RunTime: 0,
	TaskBatchID: 0,
	TaskBatchLogID: 0,
	TaskBatchScheduleID: 0,
	TotalTasks: 0,
});

