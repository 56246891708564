import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand
} from 'reactstrap';

// import { useAuth } from 'state/selectors/AuthSelectorHooks';


const PublicNavBar = (props: any) => {

    return (
        <div>
          <Navbar color="light" light expand="md">

            <NavbarBrand href="/">

            </NavbarBrand>
          </Navbar>

          {props.children}
        </div>
      );
}


export default withRouter(PublicNavBar); 