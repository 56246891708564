import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Check, Delete, Error } from "@material-ui/icons";
import { deleteSysVal, updateSysVal, useGetSysVals } from "app/api/values";
import { useGetVendors } from "app/api/vendors";
import { VendorAggregate } from "app/definitions/aggregates/VendorAggregate";
import {
  sysValIDsAtom,
  sysValTypesAtom,
  sysValWithID,
  totalSysValValuesSelector,
  useInsertSysVals,
} from "app/state/selectedUpdateSysValDTOAtom";
import MoneyFormat from "core/components/MoneyFormat";
import { MoneyInput } from "core/components/MoneyInput";
import NavBarSecondary from "core/components/NavBarSecondary";
import { UserAggregate } from "core/definitions/aggregates/UserAggregate";
import {
  ObjectTypes,
  ObjectTypeUser,
  ObjectTypeVendor,
} from "core/definitions/constants/objectTypes";
import handleNetworkError from "core/utils/handleNetworkError";
import { fetchURLQueryParamsFromString, mapToURL } from "core/utils/url";
import { useGetMyAccountUsers } from "gen/routes/Account";
import { useGetListByTitle } from "gen/routes/Lists";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Alert } from "reactstrap";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import NewSysValsDialog from "./NewSysValsDialog";
import {
  DataBody,
  DataHead,
  DataHeaderOrdered,
  DataRow,
  DataTable,
  ReportWrapper,
} from "core/components/tables";
import { sortingSysValAtom } from "./state";


export const usersAtom = atom<UserAggregate[]>({
  key: "sysValUserListAtom",
  default: [],
});

export const vendorsAtom = atom<VendorAggregate[]>({
  key: "sysValVendorListAtom",
  default: [],
});

export const UserSelect: React.FC<{
  userID: number;
  onChange: (userID: number) => void;
}> = ({ userID, onChange }) => {
  const users = useRecoilValue(usersAtom);

  return (
    <TextField
      size="small"
      variant="outlined"
      select
      value={userID}
      onChange={(e) => onChange(parseInt(e.target.value))}
      label="User"
    >
      <MenuItem value={0}>All</MenuItem>
      {users.map((user, key) => (
        <MenuItem value={user.UserID} key={key}>
          {user.FullName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const VendorSelect: React.FC<{
  vendorID: number;
  onChange: (vendorID: number) => void;
}> = ({ vendorID, onChange }) => {
  const vendors = useRecoilValue(vendorsAtom);

  return (
    <TextField
      size="small"
      variant="outlined"
      select
      value={vendorID}
      onChange={(e) => onChange(parseInt(e.target.value))}
      label="Manufacturer"
    >
      <MenuItem value={0}>All</MenuItem>
      {vendors.map((vendor, key) => (
        <MenuItem value={vendor.VendorID} key={key}>
          {vendor.Title}
        </MenuItem>
      ))}
    </TextField>
  );
};

// 2147483647
// 20190101

const years = [2019, 2020, 2021, 2022, 2023, 2024, 2025];

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const monthNames = [
  "Annual",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// const weeks = [
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53
// ];

// const dom = [
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
// ];

// const dow = [
//     0, 1, 2, 3, 4, 5, 6
// ];

const SysVals = () => {
  // Front-load sysValTypes
  const { data, status, error } = useGetListByTitle("SysValType");
  const setSysValTypes = useSetRecoilState(sysValTypesAtom);

  // Front-load users
  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useGetMyAccountUsers(0, 0, 50);
  const setUsers = useSetRecoilState(usersAtom);

  // Front-load vendors
  const {
    data: vendorData,
    status: vendorStatus,
    error: vendorError,
  } = useGetVendors(0, 100, "Title", "ASC");
  const setVendors = useSetRecoilState(vendorsAtom);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (vendorStatus === "success" && vendorData) {
      setVendors(vendorData.data.Data);
    }
  }, [vendorStatus, vendorData]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (status === "success" && data) {
      setSysValTypes(data.data);
    }
  }, [status, data]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (userStatus === "success" && userData) {
      setUsers(userData.data.Data.map((u) => u as UserAggregate));
    }
  }, [userStatus, userData]);

  if (status === "error") {
    return <Alert color="danger">{handleNetworkError(error)}</Alert>;
  }
  if (status === "loading" || !data) {
    return <CircularProgress />;
  }

  if (userStatus === "error") {
    return <Alert color="danger">{handleNetworkError(userError)}</Alert>;
  }
  if (userStatus === "loading" || !userData) {
    return <CircularProgress />;
  }

  if (vendorStatus === "error") {
    return <Alert color="danger">{handleNetworkError(vendorError)}</Alert>;
  }
  if (vendorStatus === "loading" || !vendorData) {
    return <CircularProgress />;
  }

  return <SysValsInner />;
};

type sysValsSearchDTO = {
  sysValType: number;
  year: number;
  month: number;
  objectType: number;
  objectID: number;
  page: number;
  limit: number;
  orderBy: string;
  orderDir: string;
};

const sysValsSearchDTOToMap = (dto: sysValsSearchDTO): Map<string, string> => {
  const m = new Map();
  m.set("sysValType", dto.sysValType.toString());
  m.set("year", dto.year.toString());
  m.set("month", dto.month.toString());
  m.set("objectType", dto.objectType.toString());
  m.set("objectID", dto.objectID.toString());
  m.set("page", dto.page.toString());
  m.set("limit", dto.limit.toString());
  m.set("orderBy", dto.orderBy);
  m.set("orderDir", dto.orderDir);
  return m;
};

const newSysValsSearchFromMap = (
  map: Map<string, string>
): sysValsSearchDTO => ({
  sysValType: map.has("sysValType")
    ? parseInt(map.get("sysValType") as string)
    : 0,
  year: map.has("year") ? parseInt(map.get("year") as string) : 2021,
  month: map.has("month") ? parseInt(map.get("month") as string) : 0,
  objectType: map.has("objectType")
    ? parseInt(map.get("objectType") as string)
    : 0,
  objectID: map.has("objectID") ? parseInt(map.get("objectID") as string) : 0,
  page: map.has("page") ? parseInt(map.get("page") as string) : 0,
  limit: map.has("limit") ? parseInt(map.get("limit") as string) : 0,
  orderBy: map.has("orderBy") ? (map.get("orderBy") as string) : "Month",
  orderDir: map.has("orderDir") ? (map.get("orderDir") as string) : "ASC",
});

const SysValsInner = () => {
  const location = useLocation();
  const history = useHistory();
const sorting = useRecoilValue(sortingSysValAtom)
  const sysValTypes = useRecoilValue(sysValTypesAtom);
  var params = fetchURLQueryParamsFromString(location.search);
  const [search, setSearch] = useState(newSysValsSearchFromMap(params));
  const [refresh, setRefresh] = useState(false);
  const { data, status, error } = useGetSysVals(
    search.sysValType,
    search.year,
    search.month + 1,
    search.objectType,
    search.objectID,
    search.page,
    search.limit,
    sorting.OrderBy,
    sorting.OrderDir,
    refresh
  );
  const [newSysValsDialogOpen, setNewSysValsDialogOpen] = useState(false);
  const doSetSearch = (search: sysValsSearchDTO) => {
    setSearch(search);
    history.push(mapToURL(`/values/sysvals`, sysValsSearchDTOToMap(search)));
  };

  const insertSysVals = useInsertSysVals();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (status === "success" && data) {
      insertSysVals(data.data.Data);
    }
  }, [status, data]);

  if (status === "error") {
    return <Alert color="danger">{handleNetworkError(error)}</Alert>;
  }

  if (status === "loading" || !data) {
    return <CircularProgress />;
  }

  return (
    <div style={{ height: "100%", overflowY: "hidden" }}>
      <NavBarSecondary right={<div></div>}>
        <strong>Sys Vals</strong>

        <TextField
          size="small"
          select
          label="Type"
          variant="outlined"
          value={search.sysValType}
          onChange={(e) =>
            doSetSearch({
              ...search,
              sysValType: parseInt(e.target.value as string),
            })
          }
        >
          <MenuItem value={0}>All</MenuItem>
          {sysValTypes.Items.map((sysValType, key) => (
            <MenuItem value={sysValType.ListItemID} key={key}>
              {sysValType.Name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          size="small"
          select
          label="Year"
          variant="outlined"
          value={search.year}
          onChange={(e) =>
            doSetSearch({ ...search, year: parseInt(e.target.value as string) })
          }
        >
          <MenuItem value={0}>All</MenuItem>
          {years.map((year, key) => (
            <MenuItem value={year} key={key}>
              {year}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          size="small"
          select
          label="Month"
          variant="outlined"
          value={search.month}
          onChange={(e) =>
            doSetSearch({
              ...search,
              month: parseInt(e.target.value as string),
            })
          }
        >
          <MenuItem value={-1}>All</MenuItem>
          {months.map((month, key) => (
            <MenuItem value={month} key={key}>
              {monthNames[month]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          size="small"
          select
          label="Scope"
          variant="outlined"
          value={search.objectType}
          onChange={(e) =>
            doSetSearch({
              ...search,
              objectType: parseInt(e.target.value as string),
            })
          }
        >
          <MenuItem value={0}>Global</MenuItem>
          {ObjectTypes.map((objectType, key) => (
            <MenuItem value={objectType.Value} key={key}>
              {objectType.Name}
            </MenuItem>
          ))}
        </TextField>
        {search.objectType === ObjectTypeUser && (
          <UserSelect
            userID={search.objectID}
            onChange={(userID: number) =>
              doSetSearch({ ...search, objectID: userID })
            }
          />
        )}
        {search.objectType === ObjectTypeVendor && (
          <VendorSelect
            vendorID={search.objectID}
            onChange={(vendorID: number) =>
              doSetSearch({ ...search, objectID: vendorID })
            }
          />
        )}

        <Button
          onClick={() => {
            setNewSysValsDialogOpen(true);
          }}
        >
          New
        </Button>
      </NavBarSecondary>

      <div style={{ height: "calc(100% - 48px)", overflowY: "auto" }}>
        <SysValRows onDelete={() => setRefresh(!refresh)} />
      </div>
      <NewSysValsDialog
        open={newSysValsDialogOpen}
        onClose={() => setNewSysValsDialogOpen(false)}
      />
    </div>
  );
};

const SysValRows: React.FC<{ onDelete: () => void }> = ({ onDelete }) => {
  const ids = useRecoilValue(sysValIDsAtom);
  const [sysVal, setSysVal] = useRecoilState(sortingSysValAtom);
  const numCols = 7;

  const colWidth = (units: number): number => {
    return units * (99.9 / numCols);
  };

  const useStyles = makeStyles(() => ({
    hideBorder: {
      "& .MuiTableCell-sizeSmall": {
        padding: "6px 2px 6px 1px !important",
      },
    },
  }));
  const orderCol = (fieldName: string) => () => {
      
    if(sysVal.OrderBy === fieldName) {
        toggleOrderDir(); 
        return; 
    }
    setSysVal({ ...sysVal, OrderBy: fieldName, OrderDir: "ASC" }); 
}

const toggleOrderDir = () => {
    
    const dirName = sysVal.OrderDir === "ASC" ? "DESC" : "ASC";

    setSysVal({ ...sysVal, OrderDir: dirName } )
}
  const classes = useStyles();
 
  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.hideBorder}>
        <TableRow>
          <TableCell style={{ borderBottom: "none " }}>
   
            <DataHeaderOrdered percent={colWidth(7.1)} >Title</DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
          
            <DataHeaderOrdered percent={colWidth(7.1)} orderFn={orderCol("Year")} isOrderBy={sysVal?.OrderBy === "Year"} dir={sysVal?.OrderDir}>Year</DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
   
            <DataHeaderOrdered percent={colWidth(7.1)} orderFn={orderCol("Month")} isOrderBy={sysVal?.OrderBy === "Month"} dir={sysVal?.OrderDir}>Month</DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
     
            <DataHeaderOrdered percent={colWidth(7.1)} >
              Scope Type
            </DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
 
            <DataHeaderOrdered percent={colWidth(7.1)} >Scope</DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
   
            <DataHeaderOrdered percent={colWidth(7)} orderFn={orderCol("Val")} isOrderBy={sysVal?.OrderBy === "Value"} dir={sysVal?.OrderDir}>Value</DataHeaderOrdered>
          </TableCell>
          <TableCell style={{ borderBottom: "none " }}>
      
            
          </TableCell>
        </TableRow>

        <TableBody>
          {ids.map((id, key) => (
            <SysValRow key={key} id={id} onDelete={onDelete} />
          ))}
        </TableBody>
        <TableFooter>
          <SysValRowsFooter />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const SysValRowsFooter = () => {
  const totalValues = useRecoilValue(totalSysValValuesSelector);
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <strong>
          $<MoneyFormat val={totalValues} />
        </strong>
      </TableCell>
    </TableRow>
  );
};

enum Status {
  None,
  Updating,
  Success,
  Error,
}

const SysValRow: React.FC<{ id: number; onDelete: () => void }> = ({
  id,
  onDelete,
}) => {
  const sysVals = useRecoilValue(sysValTypesAtom);
  const users = useRecoilValue(usersAtom);
  const vendors = useRecoilValue(vendorsAtom);
  const [status, setStatus] = useState(Status.None);
  const [deleteStatus, setDeleteStatus] = useState(Status.None);
  const [sysVal, setSysVal] = useRecoilState(sysValWithID(id));
  const [isDeleteStart, setIsDeleteStart] = useState(false);
  const doDelete = () => {
    setDeleteStatus(Status.Updating);
    setTimeout(() => {
      deleteSysVal(sysVal.SysValID).then(() => {
        setDeleteStatus(Status.None);
        setIsDeleteStart(false);
        onDelete();
      });
    }, 1000);
  };

  const setSysValFloat = (field: string) => (val: number) => {
    console.log("sysVal changed!", val);
    console.log("updating sysval", sysVal);

    const updatedSysVal = { ...sysVal, Val: val };

    setStatus(Status.Updating);
    updateSysVal(updatedSysVal)
      .then(() => {
        setSysVal({ ...sysVal, Val: val });
        setStatus(Status.Success);
        setTimeout(() => {
          setStatus(Status.None);
        }, 2000);
      })
      .catch(() => {
        setStatus(Status.Error);
        setTimeout(() => {
          setStatus(Status.None);
        }, 2000);
      });
  };

  const sysValType = sysVals.Items.find(
    (x) => x.ListItemID === sysVal.SysValType
  );
  let title = "";
  // Users
  if (sysVal.ObjectType === ObjectTypeUser) {
    if (sysVal.ObjectID > 0) {
      const user = users.find((user) => user.UserID === sysVal.ObjectID);
      if (user) {
        title = user.FullName;
      }
    }
  }

  // Manufacturers
  if (sysVal.ObjectType === ObjectTypeVendor) {
    if (sysVal.ObjectID > 0) {
      const vendor = vendors.find(
        (vendor) => vendor.VendorID === sysVal.ObjectID
      );
      if (vendor) {
        title = vendor.Title;
      }
    }
  }

  return (
    <TableRow style={{ padding: "0px !important" }}>
      <TableCell style={{ width: "200px", padding: "0px" }}>
        {sysValType ? sysValType.Name : "??"}
        {/* <TextField variant="outlined" size="small" select value={sysVal.SysValType} onChange={setSysValInt('SysValType')}>
                    <MenuItem value={SysValType.BookingGoal}>Booking Goal</MenuItem>
                    <MenuItem value={SysValType.GMGoal}>GM Goal</MenuItem>
                </TextField> */}
      </TableCell>
      <TableCell style={{ width: "220px" }}>
        {sysVal.Year}
        {/* <TextField variant="outlined" size="small" select value={sysVal.Year} onChange={setSysValInt('Year')}>
                    {years.map((year, key) => <MenuItem key={key} value={year}>
                        {year}
                    </MenuItem>)}
                </TextField>  */}
      </TableCell>
      <TableCell style={{ width: "220px" }}>
        {monthNames[sysVal.Month]}
        {/* <TextField variant="outlined" size="small" select value={sysVal.Month} onChange={setSysValInt('Month')}>
                    {months.map((month, key) => <MenuItem key={key} value={month}>
                        {month}
                    </MenuItem>)}
                </TextField>  */}
      </TableCell>
      <TableCell style={{ width: "200px" }}>
        {sysVal.ObjectType === ObjectTypeUser && "User"}
        {sysVal.ObjectType === ObjectTypeVendor && "Manufacturer"}
        {sysVal.ObjectType === 0 && "Global"}
      </TableCell>
      <TableCell style={{ width: "240px" }}>{title}</TableCell>

      <TableCell style={{ width: "350px" }}>
        <MoneyInput value={sysVal.Val} onChange={setSysValFloat("Val")} />
        {status === Status.Updating && <CircularProgress size={20} />}
        {status === Status.Success && <Check style={{ color: "#090" }} />}
        {status === Status.Error && <Error style={{ color: "#900" }} />}
      </TableCell>
      <TableCell>
        {isDeleteStart === false ? (
          <IconButton onClick={() => setIsDeleteStart(true)}>
            <Delete />
          </IconButton>
        ) : (
          <>
            <Button
              onClick={doDelete}
              variant="contained"
              style={{ backgroundColor: "#900", color: "#fff" }}
              disabled={deleteStatus === Status.Updating}
            >
              Yes, Delete
              {deleteStatus === Status.Updating && (
                <CircularProgress size={20} />
              )}
            </Button>{" "}
            &nbsp;
            <Button onClick={() => setIsDeleteStart(false)}>Cancel</Button>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SysVals;
