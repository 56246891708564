import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateRole } from 'gen/routes/Roles';
import showCreateRoleModalAtom from 'core/state/roles/showCreateRoleModalAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateRoleDTO } from 'gen/dtos/CreateRoleDTO';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreateRoleDialog = () => {

    const [isModalShowing, setIsModalShowing] = useRecoilState(showCreateRoleModalAtom);
    const [createRole, { isError: isCreateRoleError, isLoading: isCreateRoleLoading, isSuccess: isCreateRoleSuccess, error: createRoleError }] = useCreateRole(); 


    const [name, setName] = useState(""); 

    const save = () => {
        const newRole = newCreateRoleDTO();
        newRole.Name = name; 
        createRole({ body: newRole }) 
    }

    useEffect(() => {
        if(isCreateRoleSuccess) {
            setIsModalShowing(false); 
        }
    }, [isCreateRoleSuccess, setIsModalShowing]); 

    return <Dialog open={isModalShowing} onBackdropClick={() => setIsModalShowing(false)}>
        
        <DialogTitle>Create a new role</DialogTitle>
        
        <DialogContent>
            
            {isCreateRoleError && 
                <Alert color="danger">{handleNetworkError(createRoleError)}</Alert>
            }


            <StyledFormControl>
                <InputLabel>Name</InputLabel>
                <Input type="text" name="Name" value={name} onChange={(e) => setName(e.target.value)} /> 
            </StyledFormControl>
            
        </DialogContent>
        <DialogActions>

            <Button variant="contained" onClick={() => save()} disabled={isCreateRoleLoading}>
                
                Create Role

                {isCreateRoleLoading &&
                    <CircularProgress size={20} /> 
                }

            </Button>

            <Button onClick={() => setIsModalShowing(false)} disabled={isCreateRoleLoading}>cancel</Button>
            
        </DialogActions>

    </Dialog>
}

export default CreateRoleDialog; 