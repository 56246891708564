import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useCreateTaskBatch } from 'gen/routes/Tasks';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreateTaskBatchDTO } from 'gen/dtos/CreateTaskBatchDTO';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import showCreateTaskBatchAtom from './atoms/showCreateTaskBatchAtom';
const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreateTaskBatchDialog = () => {

    const [showing, setShowing] = useRecoilState(showCreateTaskBatchAtom); 
    const [taskBatch, setTaskBatch] = useState(newCreateTaskBatchDTO())


    const [createTaskBatch, { status: createStatus, error: createError }] = useCreateTaskBatch(); 

    const save = () => {
        createTaskBatch({ body: taskBatch })
            .then(() => {
                setTimeout(() => { 
                        setShowing(false); 
                        setTaskBatch(newCreateTaskBatchDTO()); 
                    }, 
                    1000
                );  
            });
    }

    const close = () => {
        setShowing(false); 
    }


    return <Dialog open={showing} onBackdropClick={close}>

        <DialogTitle>Create A Task Batch</DialogTitle>
        
        <DialogContent>

            {createStatus === "error" && 
                <Alert color="danger">{handleNetworkError(createError)}</Alert>
            }

            {createStatus === "success" && 
                <Alert color="success">Task Batch Created</Alert>
            }

            <StyledFormControl>
                <InputLabel>Name</InputLabel>
                <Input type="text" name="Name" value={taskBatch.Name} onChange={(e) => setTaskBatch({ ...taskBatch, Name: e.target.value })} /> 
            </StyledFormControl>

        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={createStatus === "loading"}>
                Create
                {createStatus === "loading" &&
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button onClick={close} disabled={createStatus === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default CreateTaskBatchDialog; 