import { useSelf } from 'core/utils/userUtils';
import React from 'react';
import ObjectTodos from './ObjectTodos';

const AdminTodos = () => { 

    const currentUser = useSelf(); 

    if(!currentUser) { 
        return <div>??</div>
    }

    return (
        <div style={{ height: '100%', overflowY: 'auto' }}>

            <ObjectTodos 
                assignedTo={0} 
                objectType={0} 
                objectID={0} 
                rowLimit={50} 
                showUserFilter
                isPlainLog
                showObjectTypeFilter
            />
            
        </div>
    ); 

}

export default AdminTodos; 