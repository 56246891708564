import { Snackbar } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';

export const SnackbarSuccess = (props: {open: boolean, onClose?: () => void, children: any }) => {
    
        return (

            <Snackbar open={props.open} autoHideDuration={6000} onClose={props.onClose}>
                <Alert color="success">
                    <CheckCircleOutline /> 
                    {props.children}
                </Alert>
            </Snackbar>

        )

}

export const SnackbarError = (props: {open: boolean, onClose?: () => void, children: any }) => {
    
    return (

        <Snackbar open={props.open} autoHideDuration={6000} onClose={props.onClose}>
            <Alert color="danger">
                <ErrorOutline /> 
                {props.children}
            </Alert>
        </Snackbar>

    )

}

export const SnackbarWarning = (props: {open: boolean, onClose?: () => void, children: any }) => {
    
    return (

        <Snackbar open={props.open} autoHideDuration={6000} onClose={props.onClose}>
            <Alert color="warning">
                <WarningOutlined /> 
                {props.children}
            </Alert>
        </Snackbar>

    )

}

export const SnackbarInfo : React.FC<{open: boolean, autoHide?: number, onClose?: () => void, children: any }> = ({ open, autoHide, onClose, children })=> {
    
    const ah = autoHide || 6000; 

    return (

        <Snackbar open={open} autoHideDuration={ah} onClose={onClose}>
            <Alert color="info">
                <InfoOutlined /> 
                {children}
            </Alert>
        </Snackbar>

    )

}