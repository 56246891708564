import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 20, 
        padding: 20, 
    }
}))


export const Disabled = () => {

    const classes = useStyles()
    return <div id="content">

        <Paper className={classes.container}>
            <h2>Account Disabled</h2>
            <p>Your account has been disabled.</p>
        </Paper>

    </div>
}
