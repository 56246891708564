import React from 'react'; 
import { Modal, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Roboto'
    }, 
    description: {
        fontFamily: 'Roboto', 
    }, 
    paper: {
      position: 'absolute',
      maxWidth: 300,
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #999',
      borderRadius: 3, 
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: 'calc(50% - 100px)', 
      left: 'calc(50% - 150px)', 
    //   transform: 'translate(-50%, -50%)', 
    },
  }));

export default function ConfirmModal (props: { title: string, description: string, open: boolean, setOpen: (open: boolean) => void, confirm: () => void }) {

    const classes = useStyles(); 

    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <div className={classes.paper}>
                <h2 className={classes.title} id="simple-modal-title">{props.title} </h2>
                <p id="simple-modal-description">
                    {props.description}
                </p>
                <Button variant="contained" onClick={props.confirm}>Yes</Button>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
            </div>
        </Modal>
    )
}