import { CircularProgress, Grid } from '@material-ui/core';
import config from 'config.json';
import { PaperContainer } from 'core/components/paperContainer';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetVersion } from 'gen/routes/Version';
import moment from 'moment';
import React from 'react';
import { Alert } from 'reactstrap';


const Settings = () => {
    
    const { data, error, status } = useGetVersion(); 
    // const version = process.env.REACT_APP_VERSION
    // const apiURL = process.env.REACT_APP_API_BASE_URL_PROD


    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if (status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <PaperContainer>

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    Client Version
                </Grid>
                <Grid item xs={6}>
                    config.version
                </Grid>
                
                <Grid item xs={6}>
                    API Version (Current)
                </Grid>
                <Grid item xs={6}>
                    {data.data.API.Version} ({moment(data.data.API.BuildDate).format("MM/dd/YYYY")})
                </Grid>

                <Grid item xs={6}>
                    API Version (At Build)
                </Grid>
                <Grid item xs={6}>
                    config.apiVersion
                </Grid>
            </Grid>

        </PaperContainer>
    )
}

export default Settings; 