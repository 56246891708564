import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import { useUpdateCustomer } from 'app/api/customers';
import { newUpdateCustomerDTOFromAggregate } from 'app/definitions/dtos/UpdateCustomerDTO';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { selectedCustomerAtom, showEditCustomerDialogAtom } from '../quotes/state';



const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const EditCustomerModal = () => {

    const [customer, setCustomer] = useRecoilState(selectedCustomerAtom)
    const [showing, setShowing] = useRecoilState(showEditCustomerDialogAtom); 

    const [updateCustomer, { status: updateCustomerStatus, error: updateCustomerError }] = useUpdateCustomer(); 

    const onTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer,
            Title: event.target.value,
        });
    };

    const onAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Address: event.target.value, 
        })
    }

    const onAddress2Changed = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Address2: event.target.value, 
        })
    }
    
    const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            City: event.target.value, 
        })
    }

    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            State: event.target.value, 
        })
    }
    const onZipChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer, 
            Zip: event.target.value, 
        })
    }
    const onPhoneChanged = (value: string) => {
        setCustomer({
            ...customer, 
            Phone: value, 
        })
    }
    const onFaxChanged = (value: string) => {
        setCustomer({
            ...customer, 
            Fax: value, 
        })
    }

    const save = () => {
        updateCustomer(newUpdateCustomerDTOFromAggregate(customer)); 
        setShowing(false); 
    }

    return <Dialog open={showing} onBackdropClick={() => setShowing(false)}>
        
        <DialogTitle>Edit A Customer</DialogTitle>
        
        <DialogContent>

            {updateCustomerStatus === "error" && 
                <Alert color="danger">{handleNetworkError(updateCustomerError)}</Alert>
            }

            {updateCustomerStatus === "success" && 
                <Alert color="success">Customer Updated</Alert>
            }

            <StyledFormControl>
                <InputLabel>Title</InputLabel>
                <Input type="text" name="Title" value={customer.Title} onChange={onTitleChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address</InputLabel>
                <Input type="text" name="Address" value={customer.Address} onChange={onAddressChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Address (cont.)</InputLabel>
                <Input type="text" name="Address2" value={customer.Address2} onChange={onAddress2Changed} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>City</InputLabel>
                <Input type="text" name="City" value={customer.City} onChange={onCityChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>State</InputLabel>
                <Input type="text" name="State" value={customer.State} onChange={onStateChanged} /> 
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel>Zip</InputLabel>
                <Input type="text" name="Zip" value={customer.Zip} onChange={onZipChanged} /> 
            </StyledFormControl>

            Phone: <ReactPhoneInput country="us" value={customer.Phone} onChange={onPhoneChanged} /> 
            <br /> 
            Fax: <ReactPhoneInput country="us" value={customer.Fax} onChange={onFaxChanged} /> 

        </DialogContent>
        <DialogActions>
            <Button onClick={save} disabled={updateCustomerStatus === "loading"}>
                Save
                {updateCustomerStatus === "loading" && 
                    <CircularProgress size={20} /> 
                }
            </Button>
            <Button onClick={() => setShowing(false)} disabled={updateCustomerStatus === "loading"}>cancel</Button>
        </DialogActions>

    </Dialog>
}

export default EditCustomerModal; 