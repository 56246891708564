import { Customer, newCustomer } from "gen/models/Customer";
import { CustomerContact } from "gen/models/CustomerContact";

export type CustomerAggregate = Customer & {
    Contacts: Array<CustomerContact>;
}

export const newCustomerAggregate = () : CustomerAggregate => ({
    ...newCustomer(), 
    Contacts: [], 
});