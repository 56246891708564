import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, TableNav } from 'core/components/tables';
import { UserAggregate } from 'core/definitions/aggregates/UserAggregate';
import AdminHeader from 'core/pages/admin/common/AdminHeader';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import userService from 'core/utils/userService';
import { Accounts_ViewPermission, Users_CreateUserPermission } from 'gen/constants/permissions';
import { useGetRoles } from 'gen/routes/Roles';
import { useGetUserGroups } from 'gen/routes/UserGroups';
import { useGetAllUsers } from 'gen/routes/Users';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { CreateUserModal } from './CreateUserModal';

const UserList = () => {

    const limit = 50; 
    const [page, setPage] = useState(0); 
    const [orderBy, setOrderBy] = useState('Email');
    const [orderDir, setOrderDir] = useState('ASC'); 
    const [groupID, setGroupID] = useState(0); 
    const [roleID, setRoleID] = useState(0); 
    const accountID = 0; 
    
    // fetchURLQueryParamsFromString
    const { data: groupData, status: groupStatus, error: groupError } = useGetUserGroups(); 
    const { data: roleData, status: roleStatus, error: roleError } = useGetRoles(0, 'Name', 'ASC'); 
    const { data: userData, status: userStatus, error: userError } = useGetAllUsers(accountID, groupID, roleID, "-1", page, limit, orderBy, orderDir); 
    const [showCreate, setShowCreate] = useState(false);

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    const history = useHistory(); 
    
    if(groupStatus === "error") { return <Alert color="danger">{handleNetworkError(groupError)}</Alert> }
    if(groupStatus === "loading" || !groupData) { return <CircularProgress /> }

    if(userStatus === "error") { return <Alert color="danger">{handleNetworkError(userError)}</Alert> }
    if(userStatus === "loading" || !userData) { return <CircularProgress /> }

    if(roleStatus === "error") { return <Alert color="danger">{handleNetworkError(roleError)}</Alert> }
    if(roleStatus === "loading" || !roleData) { return <CircularProgress /> }

    const users = userData.data.Data; 

    const cols = [
        { label: "#", orderFn: orderCol("UserID"), val: (v: UserAggregate) => v.UserID, size: .5, onClick: (row: UserAggregate) => () => history.push(`/admin/accounts/${row.AccountID}/users/${row.UserID}`) }, 
        { label: "Email", orderFn: orderCol("Email"), val: (v: UserAggregate) => v.Email, size: 1.5, onClick: (row: UserAggregate) => () => history.push(`/admin/accounts/${row.AccountID}/users/${row.UserID}`) }, 
        { label: "Prefix", orderFn: orderCol("UserPrefix"), val: (v: UserAggregate) => v.UserPrefix, size: 1 }, 
        { label: "Name", orderFn: orderCol("FullName"), val: (v: UserAggregate) => <>{v.FullName}</>, size: 1 }, 
        { label: "Active", val: (v: UserAggregate) => <>{v.DateActivated > 0 ? 'Yes' : 'No'}</>, size: 1 }, 
        { label: "Groups", val: (v: UserAggregate) => v.UserGroupCount, size: 1 }, 
        { label: "Roles", val: (v: UserAggregate) => v.RoleCount, size: 1 }, 
    ]

    if(userService.hasPerm(Accounts_ViewPermission)) { 
        cols.push({ label: "AccountID", val: (v: UserAggregate) => <Link to={`/accounts/${v.AccountID}`}>Account {v.AccountID}</Link>, size: 1 }); 
    }
    
    return (
        <div style={{ height: '100%', overflowY: 'hidden' }}>

            <Perm perm={Users_CreateUserPermission}>
                <Add action={() => setShowCreate(true)} /> 
            </Perm>


            <NavBarSecondary right={
                <>
                    <TableNav {
                        ...{count: userData.data.Count, prev : () => setPage(page - 1), next : () => setPage(page + 1), page, limit }
                    } />

                    {/* <AdminActions actions={[
                        { title: 'View Disabled', fn: gotoDisabled, perm: Users_GetDisabledUsersPermission }
                    ]} />  */}
                </>

            }>
                <AdminHeader title="Users" /> 
                
                &nbsp; 

               
                <TextField 
                    select 
                    size="small" 
                    margin="none"
                    label="Group"
                    value={groupID}
                    onChange={(e) => setGroupID(parseInt(e.target.value as string))}
                >
                    <MenuItem value={0} key={-1}>None</MenuItem>
                    {groupData.data.map((group, key) => <MenuItem key={key} value={group.UserGroupID}>{group.Title} ({group.UserCount})</MenuItem>)}
                </TextField>
                <TextField
                    select 
                    size="small"
                    margin="none"
                    label="Role"
                    value={roleID}
                    onChange={(e) => setRoleID(parseInt(e.target.value as string))}>
                        <MenuItem value={0} key={-1}>None</MenuItem>
                        {roleData.data.Data.map((role, key) => <MenuItem key={key} value={role.RoleID}>{role.Name} ({role.UserCount})</MenuItem>)}
                </TextField>

            </NavBarSecondary>

            <Grid item xs={12} style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>

                <DTable
                    cols={cols}
                    orderBy={orderBy}
                    orderDir={orderDir}
                    data={users}
                /> 
            </Grid>

            
            <CreateUserModal accountID={1} open={showCreate} onClose={() => setShowCreate(false)} /> 

        </div>
    ); 
}

export default UserList; 