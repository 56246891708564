/**
 *
 * CreateCommentDTO
 */

export type CreateCommentDTO = {

	// Content string
	Content: string;

	// ObjectID int64
	ObjectID: number;

	// ObjectType int64
	ObjectType: number;

}

// newCreateCommentDTO is a factory method for new CreateCommentDTO objects
export const newCreateCommentDTO = () : CreateCommentDTO => ({
	Content: '',
	ObjectID: 0,
	ObjectType: 0,
});

