import { newObjectSearch, ObjectSearch } from "gen/models/ObjectSearch"

export type ObjectSearchAggregate<T> = ObjectSearch & {
    Search: T;
}

export function newObjectSearchAggregate<T>(search : T) : ObjectSearchAggregate<T> { 
    return {
        ...newObjectSearch(), 
        Search: search, 
    } 
}