import { Button, Checkbox, CircularProgress, Collapse, FormControlLabel, MenuItem, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSubmitForm } from 'gen/routes/Forms';
import { useGetListFromID } from 'gen/routes/Lists';
import { FormAggregate } from 'core/definitions/aggregates/FormAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import { FormField } from 'gen/models/FormField';
import { FormFieldGroup } from 'gen/models/FormFieldGroup';
import { FormValue } from 'gen/models/FormValue';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';

const useStyles = makeStyles({ 
    formTitle: { 
        fontSize: '20px', 
    },
    formDescription: { 
        fontSize: '12px', 
        marginBottom: 20, 
    }, 
    footer: { 
        marginTop: 20, 
        padding: 10, 
        borderTop: 'solid 1px #909090', 
    }
})


export const getOptions = (optionsString: string) : string[] => { 
    if(!optionsString) { 
        return []; 
        // item.Options = "" 
    }

    return optionsString.split(";").filter(x => x.length > 0); 
}

export const getOption = (option: string) : string[] => { 
    return option.split(":")
}

export const addToOptionsArray = (options: string[], key: string, value: string) : string => { 
    return addToOptions(options.join(";"), key, value); 
}

export const addToOptions = (options: string, key: string, value: string) : string => { 
    return options + ";" + key + ":" + value
}


export enum DataSourceType { 
    None, 
    List, 
    Model 
}

export enum FormFieldType { 
	SingleLine, 
	MultiLine,
	Radio,      // Select
	Checkbox,       // Bool 
	Select,         // Select
	MultiSelect,    // Select
	DateTime,
	Time,
	Date,
	Number,
	NumberRange,
    Search, 
    Email, 
    FileUpload, 
    YesNo,      // Bool 
    Phone, 
};

export const FormFieldTypes = [
    FormFieldType.SingleLine, 
	FormFieldType.MultiLine,
	FormFieldType.Radio,
	FormFieldType.Checkbox,
	FormFieldType.Select,
	FormFieldType.MultiSelect,
	FormFieldType.DateTime,
	FormFieldType.Time,
	FormFieldType.Date,
	FormFieldType.Number,
	FormFieldType.NumberRange,
    FormFieldType.Search, 
    FormFieldType.Email, 
    FormFieldType.FileUpload, 
    FormFieldType.YesNo, 
    FormFieldType.Phone, 
]


export const formFieldTypeToString = (type: FormFieldType) : string => { 
    switch(type) { 

        case FormFieldType.SingleLine: 
            return "SingleLine"; 
        case FormFieldType.MultiLine: 
            return "MultiLine"; 
        case FormFieldType.Radio: 
            return "Radio"; 
        case FormFieldType.Checkbox: 
            return "Checkbox"; 
        case FormFieldType.Select: 
            return "Select"; 
        case FormFieldType.MultiSelect: 
            return "MultiSelect"; 
        case FormFieldType.DateTime: 
            return "DateTime"; 
        case FormFieldType.Time: 
            return "Time"; 
        case FormFieldType.Date: 
            return "Date"; 
        case FormFieldType.Number: 
            return "Number"; 
        case FormFieldType.NumberRange: 
            return "NumberRange";
        case FormFieldType.Email: 
            return "Email";
        case FormFieldType.FileUpload: 
            return "File Upload"; 
        case FormFieldType.YesNo: 
            return "YesNo"; 
        case FormFieldType.Phone: 
            return "Phone"; 
        default: 
            return "???";  
    }
}

enum Status {
    None, 
    Loading, 
    Success, 
    Error, 
}

const getGroups = (form: FormAggregate, isUpdate: boolean) : FormFieldGroup[] => form.FieldGroups.filter(
    fieldGroup => form.Fields.some(
        field => field.FormFieldGroupID === fieldGroup.FormFieldGroupID 
        && 
        (!isUpdate ? field.ShowOnCreate === 1 : true)
    )
); 

const Form : React.FC<{ 
    form: FormAggregate,  
    data?: FormValue[], 
    formSubmissionID?: number,
    onSuccess?: () => void; 
}> = ({ form, data, formSubmissionID, onSuccess }) => { 

    const isUpdate = Boolean(data); 
    const submissionID = formSubmissionID ? formSubmissionID : 0 ; 
    const [submitForm] = useSubmitForm(); 
    const [groupOpen, setGroupOpen] = useState(0); 
    const [values, setValues] = useState(form.Fields.reduce((map, obj) => { 
        if(isUpdate && data) { 
            const val = data.find(x => x.FormFieldID === obj.FormFieldID)
            if(val) { 
                map["f" + obj.FormFieldID] = val.ValueID > 0 ? val.ValueID.toString() : val.Value; 
                return map; 
            }
        }
        map["f" + obj.FormFieldID] = obj.DefaultValue; 
        return map;
    }, {}))
    const [status, setStatus] = useState<{ status: Status; errors: string[] }>({ status: Status.None, errors: [] }); 

    const classes = useStyles(); 

    const clearValues = () => { 

        let clearedValues = values; 
        form.Fields.forEach((field) => clearedValues[`f${field.FormFieldID}`] = field.DefaultValue);
        console.log('clearedValues', clearedValues); 
        setValues(clearedValues); 
    } 

    const doSubmitForm = () => { 

        setStatus({ status: Status.Loading, errors: [] }); 

        let errors : string[] = []; 
        form.Fields.forEach(field => { 
            if(field.IsRequired && !values[`f${field.FormFieldID}`]) { 
                errors.push(`${field.Title} is required`)
            }
        });

        if(errors.length > 0) { 
            setStatus({ status: Status.Error, errors: errors }); 
            return;
        }

        setTimeout(() => { 

            console.log('Submitting form with values', values) 

            submitForm({ formID: form.FormID, body : { 
                FormSubmissionID: submissionID, 
                Values: values, 
            }}).then(() => { 
                
                if(isUpdate) { 
                    clearValues(); 
                }


                setStatus({ status: Status.Success, errors: [], })

                setTimeout(() => { 
                    setStatus({ status: Status.None, errors: [] }); 
                }, 3000);

                if(onSuccess) onSuccess(); 

            }).catch((e) => { 
                setStatus({ status: Status.Error, errors: [ handleNetworkError(e) ]})
            })
        }, 1000); 
    }

    const onValueChange = (key: number) => (value: string) => { 
        setValues({ ...values, [`f` + key]: value })
    }

    return (
        <Paper elevation={5} style={{ padding: 10 }}>
            
            <h1 className={classes.formTitle}>{form.Title}</h1>

            <p className={classes.formDescription}>{form.Description}</p>

            {status.status === Status.Error && 
                <Alert color="danger">
                    <ul>
                        {status.errors.map((e, k) => <li key={k}>{status.errors[k]}</li>)}
                    </ul>
                </Alert>
            }

            {status.status === Status.Success && 
                <Alert color="success">
                    {isUpdate ? form.UpdateResponseTextSuccess : form.CreateResponseTextSuccess} 
                </Alert>
            }

            {form.Fields.some(x => x.IsRequired === 1) && <div><sup style={{ fontSize: '12px', color: 'red' }}>*</sup> required fields</div>}

            {getGroups(form, isUpdate).map((fieldGroup, key) => (
                <div key={key}>
                    <div onClick={() => setGroupOpen(key)} style={{ cursor: 'pointer' }}>
                        <strong>{fieldGroup.Ordinal}. {fieldGroup.Title}</strong>
                    </div>
                    <Collapse in={groupOpen === key}>
                        <Paper elevation={2} style={{ margin: 10, padding: 10 }}>
                            {form.Fields.filter(x => x.FormFieldGroupID === fieldGroup.FormFieldGroupID).filter(field => shouldShowField(field, isUpdate)).map((field, key) => <FormFieldEl field={field} key={key} value={values[`f${field.FormFieldID}`]} onChange={onValueChange(field.FormFieldID)} disabled={!shouldEditField(field, isUpdate)}/>)}
                        </Paper>
                    </Collapse>
                </div>
            ))}


            <div className={classes.footer}>
                <Button variant="contained" onClick={doSubmitForm} disabled={status.status === Status.Loading}>
                    {data ? form.UpdateButtonText : form.CreateButtonText}
                    {status.status === Status.Loading && 
                        <CircularProgress size={20} /> 
                    }
                </Button>
            </div>
            
        </Paper>
    )
}

const shouldShowField = (field: FormField, isUpdate: boolean) : boolean => { 

    if(!isUpdate && field.ShowOnCreate === 0) { 
        return false; 
    }

    return true; 

}

const shouldEditField = (field: FormField, isUpdate: boolean) : boolean => { 
    if(isUpdate && field.EditOnUpdate === 0) { 
        return false 
    }

    return true; 
}

const FormFieldEl : React.FC<{ field: FormField; value: string; onChange: (val: string) => void, disabled: boolean }> = ({ field, value, onChange, disabled }) => { 

    const [fieldValue, setFieldValue] = useState(value)
    useEffect(() => { 
        setFieldValue(value); 
    }, [value])

    const onValueChange = (value : string) => { 
        setFieldValue(value); 
    }

    return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
            <div style={{ width: 200 }}>
                <strong>{field.Title}{field.IsRequired === 1 && <sup style={{ fontSize: '12px', color: 'red'}}>*</sup>}</strong>
            </div>
            <div>
                {field.FieldType === FormFieldType.SingleLine && <TextField disabled={disabled} helperText={field.HelpText} value={fieldValue} onChange={(e) => onValueChange(e.target.value as string)} onBlur={() => onChange(fieldValue)} />}
                {field.FieldType === FormFieldType.Email && <TextField disabled={disabled} helperText={field.HelpText} value={fieldValue} onChange={(e) => onValueChange(e.target.value as string)} onBlur={() => onChange(fieldValue)} />}
                {/* https://www.npmjs.com/package/react-phone-number-input */}
                {field.FieldType === FormFieldType.Phone && <TextField disabled={disabled} helperText={field.HelpText} value={fieldValue} onChange={(e) => onValueChange(e.target.value as string)} onBlur={() => onChange(fieldValue)} />}
                {field.FieldType === FormFieldType.MultiLine && <TextField disabled={disabled} multiline rows={3} rowsMax={3} helperText={field.HelpText} value={fieldValue} onChange={(e) => onValueChange(e.target.value as string)} onBlur={() => onChange(fieldValue)} />}
                {field.FieldType === FormFieldType.Select && <SelectField disabled={disabled} field={field} value={fieldValue} onChange={(value : string) => onValueChange(value)} onBlur={() => onChange(fieldValue)} />}
                {field.FieldType === FormFieldType.Checkbox && <SingleCheckboxField disabled={disabled} field={field} value={fieldValue} onChange={(value : string) => { onValueChange(value); onChange(value); }}  />}
            </div>
        </div>
    ); 
}

const SelectField : React.FC<{ field: FormField; value: string; onChange: (value: string) => void; onBlur: () => void, disabled: boolean }> = ({ field, value, onChange, onBlur, disabled }) => { 

    if(field.DataSourceType === DataSourceType.List) { 
        return <SelectFieldFromList {...{field, value, onChange, onBlur, disabled }} /> 
    }

    // if(field.DataSourceType === DataSourceType.None) { 
    return <SelectFieldFromOptions {...{field, value, onChange, onBlur, disabled }} /> 
}

const SelectFieldFromOptions : React.FC<{ field: FormField; value: string; onChange: (value: string) => void; onBlur: () => void, disabled: boolean }> = ({ field, value, onChange, onBlur, disabled }) => { 

    const options = getOptions(field.Options); 

    return (
        <TextField 
            select 
            helperText={field.HelpText} 
            value={value} 
            onChange={(e) => onChange(e.target.value as string)} 
            onBlur={onBlur}
            disabled={disabled}
        >
            {options.map((option, key) => { 
                const [title, value] = getOption(option); 
                return <MenuItem key={key} value={value}>{title}</MenuItem>
            })}
        </TextField>
    )
}


const SelectFieldFromList : React.FC<{ field: FormField, value: string; onChange: (value: string) => void; onBlur: () => void; disabled: boolean }> = ({ field, value, onChange, onBlur, disabled }) => { 

    const { status, data, error } = useGetListFromID(field.DataSourceID); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <TextField 
            select 
            helperText={field.HelpText} 
            value={value} 
            onChange={(e) => onChange(e.target.value as string)} 
            onBlur={onBlur}
            disabled={disabled}
        >
            {data.data.Items.map((item, key) => { 
                return <MenuItem key={key} value={item.ListItemID.toString()}>{item.Name}</MenuItem>
            })}
        </TextField>
    )

}

const SingleCheckboxField: React.FC<{ field: FormField, value: string; onChange: (e) => void, disabled: boolean }> = ({ field, value, onChange, disabled }) => { 

    return (
        <FormControlLabel 
            label={field.HelpText} 
            disabled={disabled}
            control={
                <Checkbox 
                    checked={value === "1"} 
                    onChange={(e) => onChange(e.target.checked ? "1" : "0")} 
                />
            } 
        />
    )

}




export default Form; 