import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    appBar1: {
        // backgroundColor: '#1db3ce'
        backgroundColor: 'rgba(29, 179, 206, 1)', 
    },
    appBar2: { backgroundColor: 'rgba(29, 179, 206, .5)'}, 
    appBar3: { 
        backgroundColor: 'rgba(29, 179, 206, .1)', 
        color: "#000",  
    }, 
    toolBar: {
        display: 'flex'
    }, 
    left: {
        flex: 1, 
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "center", 
        // alignContent: "flex-start",
    }, 
    center: {
        flex: 1, 
        justifyContent: "center", 
        alignItems: "center", 
        display: "flex", 
    },
    right: {
        flex: 1, 
        display: "flex", 
        justifyContent: "flex-end", 
        alignItems: "center", 
        // alignContent: "flex-end", 
    }
})); 

const NavBarSecondary : React.FC<{ 
    level?: 1 | 2 | 3 | undefined, 
    children: React.ReactNode, 
    center?: React.ReactNode, 
    right?: React.ReactNode, 
    back?: string, 
    position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative'
}> = ({ 
    level, 
    children, 
    center, 
    right, 
    back, 
    position 
}) => {

    const classes = useStyles(); 
    const history = useHistory(); 

    const classLevel = (() => { 
        switch(level) { 
            case 2 : 
                return classes.appBar2; 
            case 3: 
                return classes.appBar3; 
            case 1 : 
            default: 
                return classes.appBar1; 
        } 
    })(); 

    return (
        <AppBar position={position || "sticky" } className={classLevel}>
            <Toolbar variant="dense" className={classes.toolBar}>
                <div className={classes.left}>
                    {back && 
                        <IconButton onClick={() => history.push(back)}>
                            <ChevronLeft /> 
                        </IconButton>
                    }
                    {children}
                </div>
                <div className={classes.center}>
                    {center}
                </div>
                <div className={classes.right}>
                    {right && right}
                </div>
            </Toolbar>
        </AppBar>
    )

}

export default NavBarSecondary; 