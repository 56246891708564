import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { CheckCircle, Help, Remove, Report } from '@material-ui/icons';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { TaskBatchAggregate } from 'core/definitions/aggregates/TaskBatchAggregate';
import handleNetworkError from 'core/utils/handleNetworkError';
import { useGetTaskBatches } from "gen/routes/Tasks";
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSetRecoilState } from 'recoil';
import AdminHeader from '../common/AdminHeader';
import showCreateTaskBatchAtom from './atoms/showCreateTaskBatchAtom';
import CreateTaskBatchDialog from './CreateTaskBatchDialog';


const TaskBatchList = () => {
    
    const history = useHistory(); 
    const setCreateShowing = useSetRecoilState(showCreateTaskBatchAtom); 
    const { data, error, status } = useGetTaskBatches(); 
    
    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return <div style={{ height: '100%', overflowX: 'hidden' }}>
        <NavBarSecondary>
            <AdminHeader title={"Tasks"} />
        </NavBarSecondary>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Last Error</TableCell>
                    <TableCell>Last Success</TableCell>
                    <TableCell>Schedules</TableCell>
                    <TableCell>Tasks</TableCell>
                    <TableCell>Active</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.data.map((batch, key) => 
                    <TableRow key={key} hover onClick={() => {
                        history.push(`/admin/tasks/${batch.TaskBatchID}`)
                    }}>
                        <TableCell>
                            <StatusIcon batch={batch} /> 
                        </TableCell>
                        <TableCell>{batch.Name}</TableCell>
                        <TableCell>
                            {batch.LastErrorDate === 0 ? 'never' : moment(batch.LastErrorDate).fromNow(true)}
                        </TableCell>
                        <TableCell>
                            {batch.LastSuccessDate === 0 ? 'never' : moment(batch.LastSuccessDate).fromNow(true)}
                        </TableCell>
                        <TableCell>
                            {batch.ScheduleCount}
                        </TableCell>
                        <TableCell>
                            {batch.TaskCount}
                        </TableCell>
                        <TableCell>
                            {batch.IsActive ? "yes" : "no"}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
        <Add action={() => setCreateShowing(true)} /> 
        <CreateTaskBatchDialog /> 
    </div>

}

const StatusIcon = (props: { batch: TaskBatchAggregate }) => {
    
    if(props.batch.LastRunDate === 0) {
        return <Remove style={{ color: '#999' }} /> 
    }

    if(props.batch.LastErrorDate > props.batch.LastSuccessDate) {
        return <Report style={{ color: '#900' }} /> 
    }

    if(props.batch.LastErrorDate < props.batch.LastSuccessDate) {
        return <CheckCircle style={{ color: '#090' }} /> 
    }

    return <Help style={{ color: '#009' }} /> 
}

export default TaskBatchList; 