import { CircularProgress } from '@material-ui/core';
import { AxiosError } from 'axios';
import Header from 'core/components/header';
import { Activation } from 'core/pages/utils/Activation';
import { Disabled } from 'core/pages/utils/Disabled';
import { Forbidden } from 'core/pages/utils/Forbidden';
import { Locked } from 'core/pages/utils/Locked';
import handleNetworkError from 'core/utils/handleNetworkError';
import { createClientLog } from 'gen/routes/Activity';
import { useGetCurrentUser } from 'gen/routes/Identity';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Redirect, Route, useLocation } from 'react-router';
import { BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { BreakpointProvider } from 'react-socks';
import { Alert } from 'reactstrap';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';
import userService from './userService';
import { useSelf } from './userUtils';


export type ISiteConfig = { 
  Title: string; 
  Footer: string; 
  NavLinks: ISiteConfigNavLink[]; 
  }

export type ISiteConfigNavLink = { 
  Path?: string; 
  Title?: string; 
  IconClasses?: string; 
  Permission?: string; 
  Type?: 'divider' | 'link';
  SubItems?:ISiteConfigSubChild[]
} 

export type ISiteConfigSubChild = {
  Title: string; 
  Path: string; 
  IconClasses?: string; 
  Permission: string; 
}
export const PrivateRoute = ({ component: Component, permission, ...rest }) => {

    const location = useLocation(); 
    const allowed = permission ? userService.hasPerm(permission) : true; 
  
    return (
      
      <Route {...rest} render={ props => {
  
        if(!userService.isLoggedIn()) {
          return <Redirect from={location.pathname} to="/login" />
        } 
          
        if(userService.isDisabled()) {
          return <Disabled /> 
        }
  
        if(userService.isLocked())
        {
          return <Locked />
        }
  
        if(!userService.isActivated()) {
          return <Activation /> 
        }
  
        if(!allowed) {
          return <Forbidden /> 
        }
  
        return <Component {...props} /> 
  
      }} /> 
    ); 
  }
  
  export const PublicRoute = ({ component: Component, ...rest }) => {
    const location = useLocation(); 
    console.log('PublicRoute', location.pathname)
    
    return (
      <Route {...rest} render={ props => (        
          <Component {...props} /> 
      )} /> 
    ); 
  
  }

  
export type IRoute = { 
    path: string; 
    Component: () => JSX.Element; 
    private: boolean; 
    permission: string; 
}

  export const initApp = (routes: IRoute[]) => {
      return function() {
        return (

            <RecoilRoot>
              
              <BreakpointProvider>
                
                <CookiesProvider>
      
                  <Router basename="/app">
      
                    <Session>
      
                      <AppWrapper>
  
                        <Header> 
    
                            <Switch>
    
                              {routes.map((route, key) => {
                                  if(route.private === true) {
                                    return <PrivateRoute key={key} component={route.Component} permission={route.permission} path={route.path} /> 
                                  } else {
                                    return <PublicRoute key={key} component={route.Component} path={route.path} /> 
                                  }
                              })}
      
                            </Switch>
                            
                        </Header>
  
                      </AppWrapper>
      
                    </Session>
      
                  </Router>

                </CookiesProvider>

              </BreakpointProvider>

            </RecoilRoot>

        );
    } 
  }
  
  
  const AppWrapperEl = styled.div`
  
    width: 100%;
    height: 100%; 
    overflow: hidden; 
    flex-grow: 1; 
  
  `
  
  const AppWrapper : React.FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const history = useHistory(); 
   
    useEffect(() => {
  
      createClientLog({ body: { PathName: history.location.pathname } }); 
  
      history.listen((location, action) => {    
        // console.log('appWrapper.history', location, action); 
        createClientLog({ body: { PathName: history.location.pathname } }); 
        // createClientActivity(location.pathname).then(() => 'Logged the client!!'); 
      })
  
    }, [history]); 
    
    return <AppWrapperEl>
      {children}
    </AppWrapperEl>
  }

  const SessionUser : React.FC<{ children: React.ReactNode }> = ({ children }) => {
  
    const { data, error, status } = useGetCurrentUser(); 

    useEffect(() => {

      switch(status) { 
        case "error": { 
          
          const e = error as AxiosError; 
          
          if(e && e.response) { 
            
            console.log('error.response.status', e.response.status); 
            
            if(e.response.status === 401) { 
              userService.logout(); 
            }

          } else {
            console.log('an unknown error occurred'); 
          }

        }
        break; 
        case "success": { 
          if(data) { 

            userService.setUser(data.data); 
            userService.initWS(); 
          }
          break; 
        }

      }

    }, [data, status, error]); 
  
    if(status === "error") {

      return <Alert color="danger">{handleNetworkError(error)}</Alert>

    }
  
    if(status === "loading" || !data) {

      return <CircularProgress /> 

    }

    if(status === "success" && userService.user && userService.user.UserID > 0) { 
      return <>{children}</>
    }

    return <div>Loading...</div>
    
  }
  
// Session wraps the entire app and loads the current user on initial page load 
const Session : React.FC<{ children: React.ReactNode }> = ({ children }) => { 
  
  useSelf(); 
  
  userService.init(); 

  if(userService.isLoggedIn()) { 

    return <SessionUser>{children}</SessionUser>; 

  } else { 

     return <>{children}</>

  }

}  
