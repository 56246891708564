import React from 'react'; 

import { useRecoilState, useRecoilValue } from 'recoil'; 
import { Alert } from 'reactstrap'; 
import { CircularProgress, Grid } from '@material-ui/core';
import { Cell, Divide, PaperBox } from './common';
import MoneyFormat from 'core/components/MoneyFormat';
import handleNetworkError from 'core/utils/handleNetworkError';
import { grossMarginSelector, grossProfitSelector, remainingGrossProfitSelector, selectedJobAtom, thirdPartyCommissionSelector, totalCostSelector } from './state';
import { useGetVendors } from 'app/api/vendors';
import { MoneyInput } from 'core/components/MoneyInput';

export const JobCloseOut = () => { 

    const [job, setJob] = useRecoilState(selectedJobAtom); 
    const { data : vendorsData, status, error } = useGetVendors(0, 100, 'Title', 'ASC'); 
    const totalCost = useRecoilValue(totalCostSelector);
    
    const grossProfit = useRecoilValue(grossProfitSelector); 
    const grossProfitActual = job.TotalPriceActual - job.TotalCostActual;

    const grossMargin = useRecoilValue(grossMarginSelector);
    
    const thirdPartyCommission = useRecoilValue(thirdPartyCommissionSelector); 
    const thirdPartyActual = (job.ThirdPartySplitPercent * grossProfitActual); 

    const remainingGrossProfit = useRecoilValue(remainingGrossProfitSelector)

    

    
    const profitB4Splits = grossProfit - thirdPartyCommission;
    const profitB4SplitsPercent = ((profitB4Splits / job.TotalPrice) * 100); 
    const profitB4SplitsActual = (grossProfitActual - thirdPartyActual); 

    const totalCommission = job.Sales.map(x => ((x.CommissionPercent / 100) * remainingGrossProfit)).reduce((p, c) => p + c, 0); 
    const totalCommissionPercent = (totalCommission / job.TotalPrice)* 100; 
    const totalCommissionActual = job.Sales.map(x => (x.CommissionPercent / 100) * profitB4SplitsActual).reduce((p, c) => p + c, 0); 

    const totalHouse = profitB4Splits - totalCommission; 
    const totalHousePercent = (totalHouse / job.TotalPrice) * 100; 
    const totalHouseActual = profitB4SplitsActual - totalCommissionActual;  

    const totalAllocatedProfit = totalHouse + totalCommission; 
    const totalAllocatedProfitActual = totalHouseActual + totalCommissionActual; 
    

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !vendorsData) {
        return <CircularProgress /> 
    }

    return (
        <PaperBox>
            <Grid container spacing={1}>

                <Grid item xs={1}>Split</Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={1}>Margin</Grid>
                <Grid item xs={3}>Estimated</Grid>
                <Grid item xs={3}>Actual</Grid>
                
                {/* Sales */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Sales</Cell>
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={job.TotalPrice} /> 
                </Cell>
                <Cell xs={3}>
                    <MoneyInput value={job.TotalPriceActual}  onChange={(v : number) => setJob({ ...job, TotalPriceActual: v })}/>
                </Cell>

                {/* Costs: Mat'l & Freight */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Costs: Mat'l & Freight</Cell>
                <Cell xs={1}>&nbsp;</Cell>
                
                <Cell xs={3}>
                    $<MoneyFormat val={totalCost} /> 
                </Cell>
                
                <Cell xs={3}>
                    <MoneyInput value={job.TotalCostActual} onChange={(v: number) => setJob({ ...job, TotalCostActual: v })} />
                </Cell>

                {/* Job Profit */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Job Profit</Cell>
                <Cell xs={1}>{grossMargin.toFixed(1)}%</Cell>
                <Cell xs={3}>
                
                    $<MoneyFormat val={grossProfit} /> 

                </Cell>
                <Cell xs={3}>$<MoneyFormat val={grossProfitActual} /></Cell>

                {/* Third Party */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Third Party</Cell>
                <Cell xs={1}>{job.ThirdPartySplitPercent.toFixed(1)}%</Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={thirdPartyCommission} /> 
                </Cell>
                <Cell xs={3}>$<MoneyFormat val={thirdPartyActual} /></Cell>

                {/* Profit B4 Splits */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Profit B4 Splits</Cell>
                <Cell xs={1}>{profitB4SplitsPercent.toFixed(1)}%</Cell>
                <Cell xs={3}>$<MoneyFormat val={profitB4Splits} /></Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={profitB4SplitsActual} /> 
                </Cell>

                <Divide /> 

                {/* Commission Split */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}><span style={{ textDecoration: 'underline', fontSize: '14px' }}>Commission Split</span></Cell>
                <Cell xs={1}>&nbsp;</Cell>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>

                {job.Sales.map((sp, key) => (
                    <>
                        <Cell xs={1}>{sp.CommissionPercent.toFixed(1)}%</Cell>
                        <Cell xs={4}>{sp.Name}</Cell>
                        <Cell xs={1}>{((((sp.CommissionPercent/100) * remainingGrossProfit) / job.TotalPrice) * 100).toFixed(1)}%</Cell>
                        <Cell xs={3}>
                            $<MoneyFormat val={(sp.CommissionPercent/100) * remainingGrossProfit} />
                        </Cell>
                        <Grid item xs={3}>
                            $<MoneyFormat val={(sp.CommissionPercent / 100) * profitB4SplitsActual} /> 
                        </Grid>
                    </>
                ))}

                <Divide /> 
                
                <Cell xs={1}>{((totalCommission / profitB4Splits) * 100).toFixed(1)}%</Cell>
                <Cell xs={4}>Total Commission</Cell>
                <Cell xs={1}>{totalCommissionPercent.toFixed(1)}%</Cell>
                <Cell xs={3}>$<MoneyFormat val={totalCommission} /></Cell>
                <Cell xs={3}>$<MoneyFormat val={totalCommissionActual} /></Cell>

                {/* Total House */}
                <Cell xs={1}>{((totalHouse / profitB4Splits)*100).toFixed(1)}%</Cell>
                <Cell xs={4}>Total House</Cell>
                <Cell xs={1}>{totalHousePercent.toFixed(1)}%</Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={totalHouse} />
                </Cell >
                <Cell xs={3}>
                    $<MoneyFormat val={totalHouseActual} /> 
                </Cell>

                {/* Total Allocated Profit */}
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={4}>Total Allocated Profit</Cell>
                <Cell xs={1}>&nbsp;</Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={totalAllocatedProfit} />
                </Cell>
                <Cell xs={3}>
                    $<MoneyFormat val={totalAllocatedProfitActual} /> 
                </Cell>


            </Grid>
        </PaperBox>
    )

}