/**
 *
 * JobSales
 */

export type JobSales = {

	// CommissionDollars float64
	CommissionDollars: number;

	// CommissionPercent float64
	CommissionPercent: number;

	// DateCreated int64
	DateCreated: number;

	// IsDeleted int
	IsDeleted: number;

	// IsHouse int
	IsHouse: number;

	// JobID int64
	JobID: number;

	// JobSalesID int64
	JobSalesID: number;

	// UserID int64
	UserID: number;

}

// newJobSales is a factory method for creating new JobSales objects
export const newJobSales = () : JobSales => ({ 
	CommissionDollars: 0,
	CommissionPercent: 0,
	DateCreated: 0,
	IsDeleted: 0,
	IsHouse: 0,
	JobID: 0,
	JobSalesID: 0,
	UserID: 0,
});

