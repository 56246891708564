import { CustomerAggregate, newCustomerAggregate } from "app/definitions/aggregates/CustomerAggregate";
import { CustomerEventAggregate, newCustomerEventAggregate } from "app/definitions/aggregates/CustomerEventAggregate";
import { CreateCommentDTO, newCreateCommentDTO } from "gen/dtos/CreateCommentDTO";
import { CreateCustomerContactDTO, newCreateCustomerContactDTO } from "gen/dtos/CreateCustomerContactDTO";
import { CreateCustomerDTO, newCreateCustomerDTO } from "gen/dtos/CreateCustomerDTO";
import { CreateCustomerEventDTO, newCreateCustomerEventDTO } from "gen/dtos/CreateCustomerEventDTO";
import { newUpdateCustomerEventDTO, UpdateCustomerEventDTO } from "gen/dtos/UpdateCustomerEventDTO";
import { CustomerContact, newCustomerContact } from "gen/models/CustomerContact";
import { atom } from "recoil";

// CreateComment 
export const createCommentAtom = atom<CreateCommentDTO>({
  key: "createCommentAtom",
  default: newCreateCommentDTO()
});


// CreateCustomer 
export const createCustomerAtom = atom<CreateCustomerDTO>({
  key: "createCustomerAtom",
  default: newCreateCustomerDTO()
});


// CreateCustomerContact 

export const createCustomerContactAtom = atom<CreateCustomerContactDTO>({
  key: "createCustomerContactAtom",
  default: newCreateCustomerContactDTO()
});


// CreateCustomerEvent 

export const createCustomerEventAtom = atom<CreateCustomerEventDTO>({
  key: "createCustomerEventAtom",
  default: newCreateCustomerEventDTO()
});


// EditCustomerContact
export const editingCustomerContactAtom = atom<CustomerContact>({
  key: "editingCustomerContactAtom",
  default: newCustomerContact(), 
});


// EditCustomerEvent
export const editingCustomerEventAtom = atom<UpdateCustomerEventDTO>({
  key: "editingCustomerEventAtom",
  default: newUpdateCustomerEventDTO(), 
});

// SelectedCustomer
export const selectedCustomerAtom = atom<CustomerAggregate>({
  key: "selectedCustomerAtom",
  default: newCustomerAggregate(), 
});

// SeletedCustomerEvent
export const selectedCustomerEventAtom = atom<CustomerEventAggregate>({
  key: "selectedCustomerEventAtom",
  default: newCustomerEventAggregate(), 
});

// SelectedCustomerID 

export const selectedCustomerIDAtom = atom<number | undefined> ({
    key: "selectedCustomerID", 
    default: undefined, 
});

// SelectedQuoteCustomerContactCustomerID
export const selectQuoteCustomerContactCustomerIDAtom = atom({
  key: "selectQuoteCustomerContactCustomerIDAtom",
  default: 0,
});

// ShowCreateContact
export const showCreateContactModalAtom = atom({
  key: "showCreateContactModal",
  default: false,
});

// ShowCreateCustomerEvent
export const showCreateCustomerEventDialogAtom = atom({
  key: "showCreateCustomerEventDialogAtom",
  default: false,
});

// ShowCreateCustomer
export const showCreateCustomerModalAtom = atom({
  key: "showCreateCustomerModal",
  default: false,
});

// ShowCreatequoteCustomerContact
export const showCreateQuoteCustomerContactDialogAtom = atom({
  key: "showCreateQuoteCustomerContactDialogAtom",
  default: false,
});

// ShowEditCustomerDialog 
export const showEditCustomerDialogAtom = atom({
  key: "showEditCustomerDialogAtom",
  default: false,
});

// ShowUpdateContactModal
export const showUpdateContactModalAtom = atom({
  key: "showUpdateContactModal",
  default: false,
});