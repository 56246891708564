import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DTable, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Forms_ImportFormPermission } from 'gen/constants/permissions';
import { Form } from 'gen/models/Form';
import { importForm, useGetForms } from 'gen/routes/Forms';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Alert } from 'reactstrap';
import AdminActions from '../common/AdminActions';
import AdminHeader from '../common/AdminHeader';
import CreateFormDialog from './CreateFormDialog';

const FormsList = () => { 

    const [showCreateFormDialog, setShowCreateFormDialog] = useState(false); 
    const history = useHistory(); 
    const [page, setPage] = useState(0); 
    const limit = 50; 
    const [orderBy, setOrderBy] = useState('Title');
    const [orderDir, setOrderDir] = useState('ASC'); 
    const { data, status, error } = useGetForms(page, limit, orderBy, orderDir); 

    const [importShowing, setImportShowing] = useState(false); 
    const [selectedUploadFile, setSelectedUploadFile] = useState<File | undefined>(undefined); 

    const onImportFileChange = (e) => { 
        const el = (e.target as HTMLInputElement); 
        if(el && el.files) { 
            setSelectedUploadFile(el.files[0]);
        }
    } 
    const onImportSubmit = () => { 
        const formData = new FormData(); 

        if(!selectedUploadFile) { 
            return 
        } 
        formData.append(
            "myFile", 
            selectedUploadFile, 
            selectedUploadFile.name, 
        )

        importForm({ body: formData })
            .then(() => { 
                alert("Form imported...") 
                setImportShowing(false); 
            })
    }

    if(status === "error") { return <Alert color="danger">{handleNetworkError(error)}</Alert> }
    if(status === "loading" || !data) { return <CircularProgress /> }

    const prev = () => { 
        setPage(page - 1); 
    }

    const next = () => { 
        setPage(page + 1); 
    }

    const toggleOrderDir = () => {
        const dirName = orderDir === "ASC" ? "DESC" : "ASC";
        setOrderDir(dirName); 
    }

    const orderCol = (fieldName: string) => () => {
        if(orderBy === fieldName) {
            toggleOrderDir(); 
            return; 
        }
        setOrderBy(fieldName)
        setOrderDir("ASC"); 
    }

    return (
        <>
            <Dialog open={importShowing} onClose={() => setImportShowing(false)}>
                <DialogTitle>Import Form</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={onImportFileChange} /> 
                    <Button variant="contained" onClick={onImportSubmit}>Upload</Button>
                </DialogContent>
            </Dialog>

            <CreateFormDialog 
                open={showCreateFormDialog} 
                onClose={() => setShowCreateFormDialog(false)} 
                onSave={(form) => history.push(`/admin/forms/${form.FormID}`)} 
            /> 

            <div style={{ height: '100%', overflowY: 'hidden' }}>
                <NavBarSecondary right={
                    <>
                        <TableNav {
                            ...{ count: data.data.Count, prev, next, page, limit }
                        } />

                        <Button variant="contained" onClick={() => setShowCreateFormDialog(true)}>
                            New <Add /> 
                        </Button>

                        <AdminActions actions={[
                                { title: 'Import', fn: () => setImportShowing(true), perm: Forms_ImportFormPermission }, 
                            ]}
                        /> 
                    </>
                }>
                    <AdminHeader title="Forms" /> 
                </NavBarSecondary>

                <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
                    <DTable
                        onRowClick={(row: Form) => history.push(`/admin/forms/${row.FormID}`)}
                        cols={[
                            { size: .5, orderFn: orderCol("Title"), orderCol: "Title", label: "Title", val: (row: Form) => <strong>{row.Title}</strong> },
                            { size: 1.5, orderFn: orderCol("Description"), orderCol: "Description", label: "Description", val: (row: Form) => <strong>{row.Description}</strong> },
                            { size: 1, orderFn: orderCol("LastSubmitted"), orderCol: "LastSubmitted", label: "Last Submitted", val: (row: Form) => moment(row.LastSubmitted).format('MM/DD/YYYY h:ma')}, 
                            { size: 1, orderFn: orderCol("SubmissionCount"), orderCol: "SubmissionCount", label: "Submissions", val: (row: Form) => <span>{row.SubmissionCount}</span>},
                        ]}
                        data={data.data.Data}
                        orderBy={orderBy}
                        orderDir={orderDir}
                    /> 
                </div>
            </div>
        </>
    )

}

export default FormsList; 