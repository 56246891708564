import { Button, CircularProgress, TextField } from '@material-ui/core';
import config from 'config.json';
import { Status, statusDefault, statusError, statusLoading, statusNetworkError, StatusState, statusSuccess } from 'core/common/status';
import { resetPasswordAnonymousFinish, useResetPasswordAnonymousValidate } from 'gen/routes/PasswordReset';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { AlertError, AlertSuccess, AuthForm, AuthTitle, Row } from './common';

enum PasswordStrength { 
    None, 
    Weak, 
    Medium, 
    Strong, 
}

const ResetFinishInternal = () => {

    const history = useHistory(); 
    const { token } = useParams() as { token: string }; 
    const { status : validateStatus } = useResetPasswordAnonymousValidate(token)
    const [ resetStatus, setResetStatus ] = useState<StatusState>(statusDefault()); 
    const { executeRecaptcha } = useGoogleReCaptcha(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => { 
        if(validateStatus === "error") { 
            history.push(`/login`); 
        }
    }, [validateStatus])

    
    const upperCaseLetter = new RegExp(/[A-Z]{1,}/)
    const numeric = new RegExp(/[0-9]{1,}/)
    const specialCharacter = new RegExp(/[!@#$%^&*)(+=._-]{1,}/)

    // const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    // const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    // const allowedCharacters = new RegExp(/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/)
    

    const [ passwordState, setPasswordState ] = useState({ 
        password: '', 
        passwordConfirm: '', 
        passwordError: false, 
        passwordConfirmError: false, 
        passwordHelper: '', 
        passwordConfirmHelper: '', 
        strength: PasswordStrength.None, 
        hasUppercase: false, 
        hasLength: false, 
        hasNumber: false, 
        hasSpecialCharacter: false, 
    });

    const onPasswordChange = (e : React.ChangeEvent<HTMLInputElement>) => { 

        const val = e.target.value as string; 

        let state = passwordState; 
        state.hasUppercase = upperCaseLetter.test(val);
        state.hasNumber = numeric.test(val); 
        state.hasLength = val.length >= 8; 
        state.hasSpecialCharacter = specialCharacter.test(val); 
        state.password = val; 

        console.log('Password Change:', state.password); 

        let score = 0; 
        if(state.hasUppercase) { 
            score++; 
        }

        if(state.hasNumber) { 
            score++; 
        }

        if(state.hasLength) { 
            score++; 
        }

        if(state.hasSpecialCharacter) { 
            score++; 
        }

        // setPasswordState({ ...state }); 

        // return; 


        console.log('Password Change', state.password, "Score:", score); 

        if(score <= 2) { 
            setPasswordState({ ...state, passwordError: false, passwordHelper: "", strength: PasswordStrength.Weak }); 
        }

        if(score === 3) { 
            setPasswordState({ ...state, strength: PasswordStrength.Medium }); 
        }

        if(score === 4) {
            setPasswordState({ ...state, strength: PasswordStrength.Strong }); 
        }


        // if(val.length === 0) { 
        //     setPasswordState({ ...passwordState, password: '', passwordError: true, passwordHelper: "Password cannot be empty.", strength: PasswordStrength.None }); 
        //     return; 
        // }

        
        // if(strongPassword.test(val)) { 
        //     setPasswordState({ ...state, passwordError: false, strength: PasswordStrength.Strong }); 
        //     return; 
        // }

        // if(mediumPassword.test(val)) { 
        //     setPasswordState({ ...state, passwordError: false, passwordHelper: "", strength: PasswordStrength.Medium }); 
        //     return; 
        // }

    }

    const onPasswordConfirmChange = (e) => { 
        if((e.target.value as string).localeCompare(passwordState.password) === 0) { 
                setPasswordState({ ...passwordState, passwordConfirm: e.target.value, passwordConfirmError: false, passwordConfirmHelper: "" }); 
        } else { 
            setPasswordState({ ...passwordState, passwordConfirm: e.target.value, passwordConfirmError: true, passwordConfirmHelper: "Passwords do not match" }); 
        }
    }

    const doResetPassword = async () => {

        if(!executeRecaptcha) { 
            console.log('ExecuteRecaptcha is undefined'); 
            return; 
        }

        const rcToken = config.reCaptchaKey.length > 0 ? await executeRecaptcha("PASSWORD_RESET") : ''; 

        if(passwordState.password.length === 0 || passwordState.passwordConfirm.length === 0) { 
            setResetStatus(statusError("All fields are required.")); 
            return; 
        }

        if(passwordState.password.localeCompare(passwordState.passwordConfirm) !== 0) { 
            setResetStatus(statusError("The passwords do not match."))
            return; 
        }

        setResetStatus(statusLoading('')); 

        setTimeout(() => { 
            resetPasswordAnonymousFinish({ 
                body: { 
                    RCToken: rcToken, 
                    Token: token, 
                    Password: passwordState.password, 
                    PasswordConfirm: passwordState.passwordConfirm 
                }
            })
                .then(() => {
                    setResetStatus(statusSuccess('Your password has been reset.'));
                    setTimeout(() => { 
                        history.push(`/login`); 
                    }, 1000);
                })
                .catch(e => setResetStatus(statusNetworkError(e))); 
                
            }, 1000); 
    }

    if(validateStatus === "error"){ 
        return <Alert color="danger">Invalid link</Alert>
    }

    if(validateStatus !== "success"){ 
        return <CircularProgress /> 
    }


    
    return (
        <AuthForm>
            <AuthTitle>Enter Your New Password</AuthTitle>
            {
                {
                    [Status.Success]: <AlertSuccess>{resetStatus.message}</AlertSuccess>, 
                }[resetStatus.status] || (
                    <>
                        {resetStatus.status === Status.Failure && 
                            <AlertError>{resetStatus.message}</AlertError>
                        }
                        
                        <strong>Passwords must be atleast 8 characters long and include an uppercase letter, a number and one of the following special characters -- <i style={{ fontStyle: 'italic', color: '#009', fontWeight: "bold" }}>.!@#$%^&*()_+-=</i></strong>
                        
                        <TextField 
                            fullWidth 
                            type="password" 
                            value={passwordState.password} 
                            onChange={onPasswordChange} 
                            label="Password" 
                            error={passwordState.passwordError}
                            helperText={passwordState.passwordHelper}
                        /> 

                        <PasswordStrengthBadge strength={passwordState.strength} /> 

                        {/* {!passwordState.hasUppercase && <div>No Uppercase</div>}
                        {!passwordState.hasLength && <div>Too short (min. 8 chars)</div>}
                        {!passwordState.hasNumber && <div>No number</div>}
                        {!passwordState.hasSpecialCharacter && <div>No Special Character: <i style={{ fontStyle: 'italic', color: '#009', fontWeight: "bold" }}>.!@#$%^&*()_+-=</i></div>} */}

                        <br /> 

                        <TextField 
                            fullWidth 
                            type="password" 
                            value={passwordState.passwordConfirm} 
                            onChange={onPasswordConfirmChange} 
                            label="Password (again)" 
                            error={passwordState.passwordConfirmError}
                            helperText={passwordState.passwordConfirmHelper}
                        /> 

                        <br /> 

                        <Row>
                            <Button disabled={resetStatus.status === Status.Loading || passwordState.strength !== PasswordStrength.Strong} onClick={doResetPassword} variant="contained">
                                Submit
                                {resetStatus.status === Status.Loading && <CircularProgress size={20} />}
                            </Button>
                        </Row>

                    </>
                )
            }
        </AuthForm>
    ); 

}

const PasswordStrengthBadge : React.FC<{ strength: PasswordStrength }> = ({ strength }) => { 

    if(strength === PasswordStrength.None) { 
        return null; 
    }

    const background = { 
        [PasswordStrength.Strong]: "green", 
        [PasswordStrength.Medium] : "yellow", 
        [PasswordStrength.Weak] : "red",
    }[strength] || "yellow"; 

    const textColor = { 
        [PasswordStrength.Strong]: "white", 
        [PasswordStrength.Medium] : "black", 
        [PasswordStrength.Weak] : "white",
    }[strength] || "black"; 

    const text = { 
        [PasswordStrength.Strong]: "Strong", 
        [PasswordStrength.Medium] : "Medium", 
        [PasswordStrength.Weak] : "Weak",
    }[strength] || "Medium"; 



    return <div style={{ padding: 5, marginTop: 20, borderRadius: '5px', textAlign: 'center', backgroundColor: background, color: textColor }}>
        {text}
    </div>

}

const ResetFinish = () => { 
    
    if(config.reCaptchaKey.length === 0) { 
        return <ResetFinishInternal /> 
    }

    return ( 
        <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
            <ResetFinishInternal /> 
        </GoogleReCaptchaProvider>
    );
}

export default ResetFinish; 