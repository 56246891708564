/**
 *
 * CreateJobDTO
 */

export type CreateJobDTO = {

	// AwardDate string
	AwardDate: string;

	// CustomerContactID int64
	CustomerContactID: number;

	// CustomerID int64
	CustomerID: number;

	// QuoteID int64
	QuoteID: number;

}

// newCreateJobDTO is a factory method for new CreateJobDTO objects
export const newCreateJobDTO = () : CreateJobDTO => ({
	AwardDate: '',
	CustomerContactID: 0,
	CustomerID: 0,
	QuoteID: 0,
});

