/**
 *
 * CreateSysValsForYearDTO
 */

export type CreateSysValsForYearDTO = {

	// DefaultVal float64
	DefaultVal: number;

	// DefaultValRange int64
	DefaultValRange: number;

	// ObjectID int64
	ObjectID: number;

	// ObjectType int64
	ObjectType: number;

	// OverrideExisting bool
	OverrideExisting: boolean;

	// SysValTypes []int64
	SysValTypes: number[];

	// Year int64
	Year: number;

}

// newCreateSysValsForYearDTO is a factory method for new CreateSysValsForYearDTO objects
export const newCreateSysValsForYearDTO = () : CreateSysValsForYearDTO => ({
	DefaultVal: 0,
	DefaultValRange: 0,
	ObjectID: 0,
	ObjectType: 0,
	OverrideExisting: false,
	SysValTypes: [],
	Year: 0,
});

