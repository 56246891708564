
import { CircularProgress, Grid, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Add } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { TableWrapper } from 'core/components/tables';
import AdminHeader from 'core/pages/admin/common/AdminHeader';
import selectedUserGroupAtom from 'core/state/users/selectedUserGroupAtom';
import showCreateGroupModalAtom from 'core/state/users/showCreateGroupModalAtom';
import handleNetworkError from 'core/utils/handleNetworkError';
import { UserGroup } from 'gen/models/UserGroup';
import { useGetUserGroups } from 'gen/routes/UserGroups';
import React from 'react';
import { Alert } from 'reactstrap';
import { useSetRecoilState } from 'recoil';
import CreateUserGroupDialog from './CreateUserGroupDialog';
import EditUserGroupDialog from './EditUserGroupDialog';

const GroupsList = () => {

    const { data, status, error } = useGetUserGroups(); 
    const setIsCreateGroupShowing = useSetRecoilState(showCreateGroupModalAtom);
    const setSelectedUserGroup = useSetRecoilState(selectedUserGroupAtom); 


    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }
    
    const editGroup = (group: UserGroup) => () => {
        setSelectedUserGroup({ UserGroupID: group.UserGroupID, Title: group.Title, Description: group.Description }); 
    }

    return (
        <div>

            <NavBarSecondary>
                <AdminHeader title={"User Groups"} />
            </NavBarSecondary>

            <Add action={() => setIsCreateGroupShowing(true)} /> 

            <Grid item xs={12}>
                <TableWrapper>
                    <TableHead>
                        <TableRow hover role="checkbox">
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Users</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.data.map((group, key) => 
                            <TableRow key={key} hover role="checkbox" onClick={editGroup(group)}>
                                <TableCell>{group.Title}</TableCell>
                                <TableCell>{group.Description}</TableCell> 
                                <TableCell>{group.UserCount}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </TableWrapper>
            </Grid>

            <CreateUserGroupDialog /> 
            
            <EditUserGroupDialog /> 
            
        </div>
    ); 
    
}

export default GroupsList; 