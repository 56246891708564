import { Button, IconButton, MenuItem, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useGetVendors } from 'app/api/vendors';
import { DatePickerInput } from 'core/components/DatePicker';
import MoneyFormat from 'core/components/MoneyFormat';
import { MoneyInput } from 'core/components/MoneyInput';
import { TableWrapper } from 'core/components/tables';
import { newUpdateJobItemDTO } from 'gen/dtos/UpdateJobItemDTO';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { grossMarginSelector, grossProfitSelector, itemIDsAtom, itemWithID, totalCostSelector, useInsertItem, vendorsAtom } from './state';
import { log } from 'console';

const ITableCell = styled(TableCell)`
    padding: 1px,1px,1px,1px; 
    font-size: 12
`

const JobDetailItems = () => {

    console.log('JobDetailItems.render()'); 
    const itemIDs = useRecoilValue(itemIDsAtom); 
    const insertItem = useInsertItem(); 
    const addItem = () => insertItem(newUpdateJobItemDTO()); 
    const { data: vendorData, status: vendorStatus } = useGetVendors(0, 100, "Title", "ASC"); 
    const setVendors = useSetRecoilState(vendorsAtom); 
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(vendorStatus === "success" && vendorData) {
            setVendors(vendorData.data.Data); 
        }
    }, [vendorData, vendorStatus])

    // const totalAllocatedProfit = useRecoilValue(totalAllocatedProfitSelector); 
    // const totalAllocatedSale = useRecoilValue(totalAllocatedSaleSelector);

    return (
        <div style={{ margin: 5, padding: 10 }}>

            <Button onClick={addItem} variant="contained">Add Item</Button>

            <TableWrapper>
                <TableHead>
                    <TableRow>
                        <ITableCell style={{ width: 20 }} align="center">#</ITableCell>
                        <ITableCell>Product/Service</ITableCell>
                        <ITableCell style={{ width: 170 }}>Vendor (Select)</ITableCell>
                        <ITableCell style={{ width: 170 }}>PO #</ITableCell>
                        <ITableCell style={{ width: 170 }}>Order #</ITableCell>
                        <ITableCell style={{ width: 170 }}>Order Date</ITableCell>
                        <ITableCell style={{ width: 170 }}>Requested Ship Date</ITableCell>
                        <ITableCell style={{ width: 170 }}>Estimated Ship Date</ITableCell>
                        <ITableCell style={{ width: 170 }}>Cost</ITableCell>
                        <ITableCell style={{ width: 170 }}>Allocated Profit</ITableCell> 
                        <ITableCell style={{ width: 170 }}>Allocated Sale</ITableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                    {itemIDs.map((id, index) => <JobDetailItemRow 
                            key={id} 
                            id={id}
                            index={index}
                        /> 
                    )}

                   <JobDetailItemsFooter /> 
                    
                </TableBody>
            </TableWrapper>
        </div>
    )
}

const JobDetailItemRow = (props: { index: number, id: number }) => {

    console.log(`JobDetailItemRow::render(${props.index})`); 
    const [itemIDs, setItemIDs] = useRecoilState(itemIDsAtom)
    const vendors = useRecoilValue(vendorsAtom);
    const [item, setItem] = useRecoilState(itemWithID(props.id))

    const removeItem = () => setItemIDs(itemIDs.filter((x, i) => i !== props.index));
    const totalCosts = useRecoilValue(totalCostSelector); 
    const grossProfit = useRecoilValue(grossProfitSelector)
    const markupPercent =grossProfit/totalCosts

    return (
        <TableRow>
                            
            {/* # */}
            <TableCell align="center" style={{ width: 20 }}>{props.index + 1}</TableCell>
            
            {/* Vendor */}
            <ITableCell>
                <TextField fullWidth style={{padding: 0}} value={item.ProductDescription} size="small" variant="outlined" onChange={(e) => setItem({...item, ProductDescription: e.target.value })} /> 
            </ITableCell>

            {/* Vendor Select */}
            <ITableCell>
                <TextField select fullWidth style={{padding: 0}} value={item.VendorID} size="small" variant="outlined" onChange={(e) => setItem({ ...item, VendorID: parseInt(e.target.value)})}> 
                    {vendors.map((vendor, key) => 
                        <MenuItem key={key} value={vendor.VendorID}>{vendor.Title}</MenuItem>
                    )}
                </TextField>
            </ITableCell>
            
            {/* PO # */}
            <ITableCell>
                <TextField fullWidth style={{width: 80}} value={item.PONumberToVendor} size="small" variant="outlined" onChange={(e) => setItem({...item, PONumberToVendor: e.target.value })} /> 
            </ITableCell>
            
            {/* Order # */}
            <ITableCell>
                <TextField fullWidth style={{width: 80}} value={item.OrderNumber} size="small" variant="outlined" onChange={(e) => setItem({...item, OrderNumber: e.target.value })} /> 
            </ITableCell>
            
            {/* Order Date */}
            <ITableCell>
                <DatePickerInput value={item.OrderDate} onChange={(e) => setItem({...item, OrderDate: moment(e).format() })} /> 
            </ITableCell>
            
            {/* Requested Ship Date */}
            <ITableCell>
                <DatePickerInput value={item.RequestedShipDate} onChange={(e) => setItem({...item, RequestedShipDate: moment(e).format() })} /> 
            </ITableCell>
            
            {/* Estimated Ship Date */}
            <ITableCell>
                <DatePickerInput value={item.EstimatedShipDate} onChange={(e) => setItem({...item, EstimatedShipDate: moment(e).format() })} /> 
            </ITableCell>

            {/* Cost */}
            <ITableCell align="right">
                <MoneyInput value={item.Cost} onChange={(value) => setItem({...item, Cost:  value })} /> 
            </ITableCell>

            {/* Allocated Profit */}
            { <ITableCell align="right"> <MoneyInput  disabled={true} value={(markupPercent * item.Cost)}  onChange={(value) => setItem({...item, AllocatedProfit:  (markupPercent * item.Cost) })} /> </ITableCell> }  
          
            {/* Allocated Sale */}
            { <ITableCell  align="right"> <MoneyInput disabled={true} value={item.Cost+(markupPercent * item.Cost)} onChange={(value) => setItem({...item, AllocatedSale:  item.Cost+(markupPercent * item.Cost) })} /> </ITableCell> }
 
            <ITableCell>
                <IconButton onClick={removeItem}>
                    <Delete /> 
                </IconButton>
            </ITableCell>
            
        </TableRow>
    )
    
    
}


const JobDetailItemsFooter = () => {

    console.log('JobDetailItemsFooter.render()'); 
    const totalCosts = useRecoilValue(totalCostSelector); 
    
    return (
        <TableRow>

            <ITableCell colSpan={7}></ITableCell>

            <ITableCell align="right">
                $<MoneyFormat val={totalCosts} />  
            </ITableCell>

            {/* <TableCell align="right">$<MoneyFormat val={totalAllocatedProfit} /></TableCell> */}
            {/* <TableCell align="right">$<MoneyFormat val={totalAllocatedSale} /></TableCell> */}                       
            
        </TableRow>
    )
}

export default JobDetailItems