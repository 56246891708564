import axios from "axios";
import config from 'config.json';

import { LoginResponseAggregate } from "gen/aggregates/LoginResponseAggregate";
import { newUserAggregate, UserAggregate } from "gen/aggregates/UserAggregate";
import { getUserSession } from "gen/routes/Users";
import { setAuthHeader, setDeviceHeader } from "./api";
import { deleteAuthCookie, deleteImpersonateCookie, getAuthCookie, getCookie, getImpersonateCookie, setAuthCookie, setDeviceCookie, setImpersonateCookie } from "./cookie";

class userService { 

    user: UserAggregate | undefined

    private auth : string; 
    private device: string; 
    private events : { [s : string]: { (arg : any) : void }[] }; 
    private subscriberKeys : { [ s : string ] : boolean }; 

    constructor() { 
        this.user = newUserAggregate(); 
        this.auth = getCookie("auth"); 
        this.device = getCookie("device"); 
        this.events = { 
            "login" : [], 
            "logout": [] 
        };
        this.subscriberKeys = {}; 
    }

    init() { 

        if(this.auth) {
            setAuthHeader(this.auth); 
        }

        if(this.device) {
            setDeviceHeader(this.device);         
        } 

    }


    initWS = () => { 
        
        const self = this; 

        // const p = new Promise<string>((resolve, reject) => { 
                                                        
            if(!window["WebSocket"]) { 
                // reject('Not supported'); 
                return; 
            }

            if(config.wsURL.length === 0) { 
                return; 
            }

            const wsConn = new WebSocket(`${config.wsURL}?s=${this.auth}`); 

            wsConn.addEventListener('close', (e) => { 
                console.log('WS Connection Closed', e);
                console.log('Trying WS connection in 5 seconds...')
                
                self.publish("wsstatus", "closed"); 
                self.publish("wsclosed", ""); 
                // reject('closed'); 
                // setTimeout(() => { 
                //     // setInterval(() => { 
                //         console.log('Trying WS connection...'); 
                //         self.initWS(); 
                //     // }, 5000); 
                // }, 5000);   

            });

            wsConn.addEventListener('open', (e) => { 
                console.log('WS Connection Opened', e)
                self.publish("wsopen", ""); 
                self.publish("wsstatus", "open"); 
            });
            wsConn.addEventListener('error', (e) => { 
                self.publish("wserror", ""); 
                self.publish("wsstatus", "error"); 
            });
            wsConn.addEventListener('message', (e) => { 
                self.publish("wsmessage", e.data); 
                // console.log('WS Message', e.data)
            });

        // }); 

        // return p; 
    }

    setUser(user: UserAggregate) { 
        this.user = user; 
        // this.events["login"].map(fn => fn(user)); 
        this.publish("login", user); 
    }

    isLoggedIn(): boolean { 

        return this.auth.length > 0;
        // return this.user.UserID > 0; 
    }

    isDisabled(): boolean { 
        return !!this.user && this.user.IsDisabled === 1;
    }

    isLocked() : boolean { 
        return !!this.user && this.user.IsLocked === 1; 
    }

    isActivated() : boolean { 
        return !!this.user && this.user.DateActivated > 0; 
    }

    logout() { 
        localStorage.removeItem("currentUser");
        delete axios.defaults.headers.common["Authorization"];
        deleteAuthCookie(); 
        this.auth = "" 
        this.publish("logout", ""); 
        // this.events["logout"].map(fn => fn("")); 
        // window.location.reload(); 
    }

    login( response : LoginResponseAggregate ) {
        console.log('login.response', response); 
        
        // Set the auth header 
        setAuthHeader(response.Token); 
        setAuthCookie(response.Token); 

        setDeviceHeader(response.Device); 
        setDeviceCookie(response.Device); 
        console.log('Setting auth', response.Token); 
        this.auth = response.Token;    
        this.setUser(response.User);      

        // window.location.href = '/app/'; 
    }

    subscribe(eventName: string, fn : (args: any) => void, key : string) { 

        if(this.subscriberKeys.hasOwnProperty(key)) { 
            // already subscribed
            return; 
        }

        this.subscriberKeys[key] = true; 
        // console.log('userService.subscribe', key, '=>', eventName); 

        if(!this.events.hasOwnProperty(eventName)) { 
            this.events[eventName] = []; 
        }

        this.events[eventName].push(fn); 
    }

    publish(eventName : string, args: any) { 
        if(!this.events.hasOwnProperty(eventName)) { 
            return; 
        }
        this.events[eventName].map(fn => fn(args)); 
    }

    impersonate(userID: number, fn: () => void) {
        
        const self = this; 

        getUserSession(userID)
            .then(response => {
                
                // Set the auth header 
                setImpersonateCookie(getAuthCookie());
                // localStorage.setItem("impersonateUser", localStorage.getItem("currentUser") as string);

                setAuthHeader(response.data.Token); 
                setAuthCookie(response.data.Token); 
                
                // localStorage.setItem("currentUser", JSON.stringify(response.data.user));
               
                self.setUser(response.data.User); 
                fn(); 
            }); 
    }



    isImpersonating() : boolean { 
        const authHeader = getImpersonateCookie(); 
        return authHeader.length > 0; 
    }

    removeImpersonation() { 
        const authHeader = getImpersonateCookie(); 
        setAuthHeader(authHeader); 
        // localStorage.setItem("currentUser", localStorage.getItem("impersonateUser") as string); 
        // localStorage.removeItem("impersonateUser"); 
        setAuthCookie(authHeader); 
        deleteImpersonateCookie(); 
        window.location.reload(); 
    }

    getSetting = (name : string, defaultValue: string) : string => { 

        if(this.user && this.user.Settings.hasOwnProperty(name)) { 
            return this.user.Settings[name]; 
        }
    
        return defaultValue; 
    }
    


    hasPerm = (
        permissionNames: string | string[] | undefined, 
        any?: boolean, 
    ) => {

        // If an empty permission name (or an empty array) was passed in, assume it's a placeholder and return true 
        if(!permissionNames || permissionNames.length === 0) {
            return true; 
        }

        const pm = !Array.isArray(permissionNames) ? [permissionNames] : permissionNames; 

        if(!this.user) { 
            return false; 
        }

        if(this.user.UserID === 0) {
            return false; 
        }

        if(this.user.UserID === 1) {
            return true; 
        }

        if(!this.user.PermissionNames) {
            return false; 
        }

        if(!(this.user.DateActivated > 0 && this.user.IsDisabled === 0 && this.user.IsLocked === 0)) {
            return false; 
        }


        let ret = false; 
        for(let i = 0; i < pm.length; i++) {
            const hasPerm = this.user.PermissionNames.some(x => x === pm[i]); 

            // If any of the permissions are found, return true; 
            if(any) { 
                if(hasPerm) { 
                    ret = true; 
                    break; 
                } 
            } else {
                // If any of the permissions are not found, return false; 
                if(!hasPerm) {
                    ret = false; 
                    break; 
                } else {
                    ret = true; 
                }
            }
        }

        return ret; 
    }

}

export default new userService()