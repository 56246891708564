/**
 *
 * ObjectSearch
 */

export type ObjectSearch = {

	// CreatedBy int64
	CreatedBy: number;

	// DateCreated int64
	DateCreated: number;

	// IsDefault int
	IsDefault: number;

	// IsDeleted int
	IsDeleted: number;

	// LastUpdated int64
	LastUpdated: number;

	// ObjectSearchID int64
	ObjectSearchID: number;

	// SearchString null.String
	SearchString: string;

	// SearchType int64
	SearchType: number;

	// Title string
	Title: string;

}

// newObjectSearch is a factory method for creating new ObjectSearch objects
export const newObjectSearch = () : ObjectSearch => ({ 
	CreatedBy: 0,
	DateCreated: 0,
	IsDefault: 0,
	IsDeleted: 0,
	LastUpdated: 0,
	ObjectSearchID: 0,
	SearchString: '',
	SearchType: 0,
	Title: '',
});

