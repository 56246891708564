import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Snackbar, TextField } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { exportForm, useGetFormFromID, useGetForms, useUpdateForm } from 'gen/routes/Forms';
import { useGetListFromID, useGetLists } from 'gen/routes/Lists';
import Form, { addToOptionsArray, DataSourceType, FormFieldType, FormFieldTypes, formFieldTypeToString, getOptions } from 'core/components/form';
import NavBarSecondary from 'core/components/NavBarSecondary';
import handleNetworkError from 'core/utils/handleNetworkError';
import { memoize } from 'core/utils/utils';
import { Forms_ExportFormPermission } from 'gen/constants/permissions';
import { FormFieldDTO, newFormFieldDTO } from 'gen/dtos/FormFieldDTO';
import { FormFieldGroupDTO, newFormFieldGroupDTO } from 'gen/dtos/FormFieldGroupDTO';
import { UpdateFormDTO } from 'gen/dtos/UpdateFormDTO';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { atom, RecoilState, selector, useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AdminActions from '../common/AdminActions';
import AdminHeader from '../common/AdminHeader';
import { selectedFormAtom, UpdateFormDTOFromAggregate } from './common';
import EditFormGroupDialog from './EditFormGroupDialog';
import download from 'downloadjs';

const formFieldIDsAtom = atom<number[]>({ 
    key: 'formFieldIDsAtom', 
    default: [], 
});

const formFieldsSelector = selector<FormFieldDTO[]>({ 
    key: 'formFieldsSelector', 
    get: ({ get }) => { 
        const fieldIDs = get(formFieldIDsAtom); 
        const fields = fieldIDs.map(id => get(formFieldDTOWithID(id)));
        return fields; 
    }
});

const useInsertFormFields = () => { 
    const setItems = useSetRecoilState(formFieldIDsAtom); 

    return useRecoilCallback(
        ({set}) => { 
            return (value: FormFieldDTO[]) => { 
                value.forEach((item, k) => { 
                    set(formFieldDTOWithID(item.Ordinal), item);
                });

                setItems(value.map((x, y) => x.Ordinal)); 
            }
        }
    )
}

// export const useInsertFormField = () => {

//     const [items, setItems] = useRecoilState(formFieldIDsAtom); 

//     return useRecoilCallback(
//         ({set}) => {
//             return (value: FormFieldDTO) => {
//                 const id = (items.length > 0 ? Math.max(...items) : -1) + 1; 
//                 setItems([...items, id]); 
//                 set(formFieldDTOWithID(id), value)
//             }
//         }
//     )

// }



export const newFormFieldDTOInAtom = (id: number) => atom<FormFieldDTO>({
    key: `formField${id}`, 
    default: newFormFieldDTO(), 
});
export const formFieldDTOWithID = memoize(newFormFieldDTOInAtom) as (id: number) => RecoilState<FormFieldDTO>; 


//
// Groups 
// 


const formFieldGroupIDsAtom = atom<number[]>({ 
    key: 'formFieldGroupIDsAtom', 
    default: [], 
});

const formFieldGroupsSelector = selector<FormFieldGroupDTO[]>({ 
    key: 'formFieldGroupsSelector', 
    get: ({ get }) => { 
        const fieldIDs = get(formFieldGroupIDsAtom); 
        const fields = fieldIDs.map(id => get(formFieldGroupDTOWithID(id)));
        return fields; 
    }
});

const useInsertFormFieldGroups = () => { 
    const setItems = useSetRecoilState(formFieldGroupIDsAtom); 

    return useRecoilCallback(
        ({set}) => { 
            return (value: FormFieldGroupDTO[]) => { 
                value.forEach((item, k) => { 
                    set(formFieldGroupDTOWithID(k), item);
                });

                setItems(value.map((x, y) => y)); 
            }
        }
    )
}

// export const useInsertFormFieldGroup = () => {

//     const [items, setItems] = useRecoilState(formFieldGroupIDsAtom); 

//     return useRecoilCallback(
//         ({set}) => {
//             return (value: FormFieldGroupDTO) => {
//                 const id = (items.length > 0 ? Math.max(...items) : -1) + 1; 
//                 setItems([...items, id]); 
//                 set(formFieldGroupDTOWithID(id), value)
//             }
//         }
//     )

// }

export const newFormFieldGroupDTOInAtom = (id: number) => atom<FormFieldGroupDTO>({
    key: `formFieldGroup${id}`, 
    default: newFormFieldGroupDTO(), 
});
export const formFieldGroupDTOWithID = memoize(newFormFieldGroupDTOInAtom) as (id: number) => RecoilState<FormFieldGroupDTO>; 

const FormDetail = () => { 

    const { id } = useParams() as { id: string }; 
    const { status, data, error } = useGetFormFromID(parseInt(id)); 
    const [selectedForm, setSelectedForm] = useRecoilState(selectedFormAtom); 

    useEffect(() => { 

        if(status === "success" && data) { 
            setSelectedForm(data.data); 
        }

    }, [status, data, setSelectedForm])
    
    if(status === "error") {
        return <Alert severity="error">{handleNetworkError(error)}</Alert>
    }
    
    return status === "success" && selectedForm.FormID > 0  ?  ( <FormDetailInner /> ) : <CircularProgress />; 
}



enum Status { 
    None, 
    Loading, 
    Success, 
    Error, 
}

const FormDetailInner = () => { 

    const [selectedField, setSelectedField] = useState(0); 
    const [selectedGroup, setSelectedGroup] = useState(0); 
    const [editGroup, setEditGroup] = useState(-1); 

    const [saveStatus, setSaveStatus] = useState({ message: '', status: Status.None }); 
    const form = useRecoilValue(selectedFormAtom);
    const [updateForm] = useUpdateForm(); 
    const [dto, setDTO] = useState<UpdateFormDTO>(UpdateFormDTOFromAggregate(form)); 
    const insertItems = useInsertFormFields(); 
    const insertGroups = useInsertFormFieldGroups(); 
    const formFields = useRecoilValue(formFieldsSelector);
    const groups = useRecoilValue(formFieldGroupsSelector);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        insertItems(dto.Fields);
        insertGroups(dto.Groups); 
    }, [dto.Fields, dto.Groups]);
    
    useEffect(() => { 
        setDTO(UpdateFormDTOFromAggregate(form)); 
    }, [form.FormRevisionID])

    const doSave = () => { 
        
        setSaveStatus({ message: '', status: Status.Loading }); 

        setTimeout(() => {
            updateForm({ 
                formID: form.FormID, 
                body: { 
                    ...dto, 
                    Fields: formFields 
                } 
            })
            .then(() => {
                setSaveStatus({ message: '', status: Status.Success }); 
            })
            .catch(e => { 
                setSaveStatus({ message: handleNetworkError(e), status: Status.Error })
            })
        }, 1000);

    }

    const group : FormFieldGroupDTO | undefined = dto.Groups.find((x, k) => k === selectedGroup); 

    const doSetSelectedGroup = (groupOrdinal: number) => { 
        setSelectedGroup(groupOrdinal); 
        setSelectedField(-1); 
    }

    const addGroup = () => { 
        const ordinal = dto.Groups.length; 
        setDTO({ ...dto, Groups: [ ...dto.Groups, { ...newFormFieldGroupDTO(), Title: "New Group", Ordinal: ordinal } ]})
        doSetSelectedGroup(ordinal); 
        console.log('addGroup', dto.Groups.length)
    }

    const updateGroup = (group: FormFieldGroupDTO) => { 
        setDTO({ ...dto, Groups: dto.Groups.map(x => x.Ordinal === group.Ordinal ? group : x )});
    }

    const deleteGroup = (group: FormFieldGroupDTO) => { 

        // Remove the deleted item 
        setDTO({ ...dto, 
            Groups: dto.Groups.filter(x => x.Ordinal !== group.Ordinal).map((x, k) => { 
            
                // Above the deleted group
                if(k < group.Ordinal) { 
                    return x; 
                }

                // Move all groups below this deleted one up. 
                return { ...x, Ordinal: x.Ordinal - 1 }; 
            }), 
            Fields: dto.Fields.filter(x => x.GroupOrdinal !== group.Ordinal).map((x, k) => { 

                // In a group above the deleted group 
                if(x.GroupOrdinal < group.Ordinal) { 
                    return x; 
                }

                // Move all items in groups below the deleted one up
                return { ...x, GroupOrdinal: x.GroupOrdinal - 1 }
            })
        }); 
    }

    const addField = () => { 
        const groupOrdinal = group ? group.Ordinal : 0; 
        const ordinal = dto.Fields.filter(x => x.GroupOrdinal === groupOrdinal).length; 
        setDTO({ 
            ...dto, 
            Fields: [ 
                ...dto.Fields, { 
                    ...newFormFieldDTO(), 
                    GroupOrdinal: groupOrdinal, 
                    Ordinal: (groupOrdinal * 1000) + ordinal, 
                    FieldType: FormFieldType.SingleLine, 
                    Title: `Field ${ordinal}`,
                } 
            ], 
            Groups: dto.Groups.map(x => x.Ordinal === groupOrdinal ? { ...x, FieldCount: x.FieldCount + 1 } : x)
        })
        console.log('addField', dto.Fields.length)
        setSelectedField(ordinal); 
    }

    const deleteField = (field: FormFieldDTO) => { 

        const groupMin = field.GroupOrdinal * 1000
        const groupMax = groupMin + 999; 

        console.log('DeleteField', field); 
        console.log('Removing item in group', field.GroupOrdinal, ' between ', groupMin, ' - ', groupMax)

        setDTO({ 
            ...dto, 
            Fields: dto.Fields.filter(x => x.Ordinal !== field.Ordinal ).map((x, k) => { 
                if(x.Ordinal < field.Ordinal || x.Ordinal > groupMax) { 
                    return x; 
                }

                return { ...x, Ordinal: x.Ordinal - 1 }
            }), 
            Groups: dto.Groups.map(x => x.Ordinal === field.GroupOrdinal ? { ...x, FieldCount: x.FieldCount - 1 } : x)
        });
        setSelectedField(-1); 
    }

    const doExportForm = () => { 
        exportForm(form.FormID)
            .then(resp => { 
                download(resp.data, `form-${form.Guid}.json`, resp.headers["content-type"])
            });
    }

    
    return (
        <>
            {/* Edit Group */}
            {editGroup > -1 && 
                <EditFormGroupDialog 
                    open={editGroup > -1} 
                    group={dto.Groups[editGroup]} 
                    onSave={(group: FormFieldGroupDTO) => updateGroup(group)} 
                    onClose={() => setEditGroup(-1)} 
                    onDelete={deleteGroup}
                /> 
            } 
            
            <div style={{ height: '100%', overflowY: 'hidden' }}>
                <NavBarSecondary
                    right={<>

                        <Button onClick={doSave} variant="contained" disabled={saveStatus.status === Status.Loading}>
                            Save
                            {saveStatus.status === Status.Loading && <CircularProgress size={20} />}
                        </Button>
                        <AdminActions actions={[
                                { title: 'Export', fn: doExportForm, perm: Forms_ExportFormPermission }, 
                                // { title: user.IsDisabled === 0 ? 'Disable' : 'Enable', fn: () => user.IsDisabled === 0 ? disableUser({ userID: user.UserID }) : enableUser({ userID: user.UserID }), show: () => user.DateActivated > 0, perm: user.IsDisabled ? AdminUsers_EnableUserPermission : AdminUsers_DisableUserPermission }, 
                                // { title: user.IsLocked === 0 ? 'Lock' : 'Unlock', fn: () => user.IsLocked === 0 ? lockUser({ userID: user.UserID }) : unLockUser({ userID: user.UserID }), show: () => user.DateActivated > 0}, 
                                // { title: "Refresh Session", fn: doRefreshUserSession, perm: AdminUtils_RefreshAllUserSessionsPermission}, 
                            ]}
                        /> 
                    </>}
                >
                    <AdminHeader title={form.Title} parents={[{title: "Forms", url: "/admin/forms"}]} /> 
                    
                    <Link to={`/admin/forms/${form.FormID}/data`}>Data</Link>
                    
                    {/* {user.IsDisabled === 1 && <Chip label="Disabled" onDelete={() => enableUser({ userID: user.UserID })}  />}
                    {user.IsLocked === 1 && <Chip icon={<Lock />} label="Locked" onDelete={() => unLockUser({ userID: user.UserID })} />}
                    {user.DateActivated === 0 && <Chip label="Inactive" onDelete={() => activateUser({ userID: user.UserID })} />} */}
                </NavBarSecondary>


                <Snackbar open={saveStatus.status === Status.Success} autoHideDuration={3000} onClose={() => setSaveStatus({ message: '', status: Status.None })}>
                    <Alert severity="success">Form Saved</Alert>
                </Snackbar>
                
                <Snackbar open={saveStatus.status === Status.Error} autoHideDuration={3000} onClose={() => setSaveStatus({ message: '', status: Status.None })}>
                    <Alert severity="error">{saveStatus.message}</Alert>
                </Snackbar>

                <Grid container spacing={1} style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
                    <Grid item xs={8} style={{ height: '100%', overflowY: 'hidden' }}>
                        <Paper elevation={5} style={{ margin: 20, padding: 20, height: 'calc(100% - 40px)', overflowY: 'auto' }}>

                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <TextField 
                                        value={dto.Title} 
                                        onChange={(e) => setDTO({ ...dto, Title: e.target.value as string})} 
                                        label="Title" 
                                        helperText="The title of the form" 
                                        fullWidth 
                                    /> 
                                </Grid>    
                                <Grid item xs={3}>
                                    <TextField 
                                        value={dto.StringIDPrefix}
                                        onChange={(e) => { 
                                            let val = e.target.value as string
                                            if(val.length > 3) { 
                                                val = val.substring(0, 2)
                                            }
                                            setDTO({ ...dto, StringIDPrefix: val})
                                        }}
                                        label="ID Prefix"
                                        helperText="2-3 character prefix at the start of the record ID"
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField value={form.Guid} disabled fullWidth label="Form ID" /> 
                                </Grid>
                            </Grid>

                            <TextField 
                                value={dto.Description} 
                                onChange={(e) => setDTO({ ...dto, Description: e.target.value as string})} 
                                label="Description" 
                                helperText="The description of the form" 
                                fullWidth 
                                rows={3}
                                rowsMax={3}
                                multiline
                            />
                            
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField 
                                        value={dto.CreateButtonText} 
                                        onChange={(e) => setDTO({ ...dto, CreateButtonText: e.target.value as string})} 
                                        label="Create Button Text" 
                                        helperText="The text shown in the Submit Button on the form when creating a model." 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        value={dto.UpdateButtonText} 
                                        onChange={(e) => setDTO({ ...dto, UpdateButtonText: e.target.value as string})} 
                                        label="Update Button Text" 
                                        helperText="The text shown in the Submit Button on the form when updating a model." 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        value={dto.CreateResponseTextSuccess} 
                                        onChange={(e) => setDTO({ ...dto, CreateResponseTextSuccess: e.target.value as string})} 
                                        label="Create Response Text" 
                                        helperText="The response text shown when the form is submitted successfully when creating a model." 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        value={dto.UpdateResponseTextSuccess} 
                                        onChange={(e) => setDTO({ ...dto, UpdateResponseTextSuccess: e.target.value as string})} 
                                        label="Update Response Text" 
                                        helperText="The response text shown when the form is submitted successfully when updating a model." 
                                        fullWidth 
                                    />
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={0}>


                                <Grid item xs={2}>
                                    <Button onClick={addGroup}>Add Group</Button>

                                    <List>
                                        {groups.map((group, index) => (
                                            <ListItem button key={index} style={ selectedGroup === index ? { backgroundColor: '#efe', fontWeight: 'bold' } : undefined}> 
                                                <ListItemText 
                                                    primary={group.Title} 
                                                    onClick={() => doSetSelectedGroup(index)}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => setEditGroup(index)}>
                                                        <Edit /> 
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                
                                {group ? (
                                    <FormGroupEditor 
                                        group={group} 
                                        fields={formFields.filter(x => x.GroupOrdinal === group.Ordinal)} 
                                        addField={addField}
                                        deleteField={deleteField}
                                        setSelectedField={setSelectedField}
                                        selectedField={selectedField}
                                    /> 
                                ) : ( <Grid item xs={9}><Alert color="info">Select a group</Alert></Grid>)}

                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 20 }}>
                        <Form form={form} />    
                    </Grid>
                </Grid>
            </div>

        </>
    )

}

const FormGroupEditor : React.FC<{ 
    group: FormFieldGroupDTO, 
    fields: FormFieldDTO[], 
    addField: () => void, 
    deleteField: (field: FormFieldDTO) => void, 
    setSelectedField : (fieldIndex: number) => void, 
    selectedField: number 
}> = ({ 
    group, 
    fields, 
    addField, 
    deleteField, 
    setSelectedField, 
    selectedField 
}) => { 

    return (
        <> 
            <Grid item xs={2}>
                <Button onClick={addField}>Add Field</Button>

                {fields.length > 0 ? (
                    <List>
                        {fields.map((formField, index) => (
                            <ListItem key={index} button onClick={() => setSelectedField(formField.Ordinal)} style={ selectedField === formField.Ordinal ? { backgroundColor: '#efe', fontWeight: 'bold' } : undefined}> 
                                <ListItemText 
                                    primary={formField.Title} 
                                    secondary={formFieldTypeToString(formField.FieldType) + " | " + (formField.IsRequired === 1 ? "Required" : "Optional")}
                                />
                            </ListItem>
                        ))}
                    </List>
                    ) : <Alert color="info">There are no fields in this group</Alert>}
            </Grid>

            <Grid item xs={8}>
                {selectedField > -1 && (
                    <Paper style={{ margin: 0, padding: 20 }}>
                        <FormFieldEl 
                            idx={selectedField} 
                            deleteField={deleteField} 
                        />
                    </Paper>
                )}
            </Grid>
        </>
    )
}

const FormFieldEl : React.FC<{ idx: number; deleteField: (field: FormFieldDTO) => void }> = ({ idx, deleteField }) => { 

    const [item, setItem] = useRecoilState(formFieldDTOWithID(idx)); 
    const [deleting, setDeleting] = useState(false); 

    console.log('item', item); 

   
    const options = getOptions(item.Options); 

    const addOption = (key : string, value : string) => { 
        setItem({ ...item, Options: addToOptionsArray(options, key, value)})
    }

    const updateOption = (id: number, key : string, value : string) => { 
        setItem({ ...item, Options: options.map((x, k) => k === id ? key + ":" + value : x).join(";")})
    }

    const removeOption = (id: number) => { 
        console.log('Removing option', id); 
        const newOptions = options.filter((x, k) => k !== id); 
        console.log('New Options: ', newOptions); 
        setItem({ ...item, Options: newOptions.join(";") }); 
    }


    return (
        <Paper style={{ margin: 0, padding: 10 }} elevation={1}>
            <TextField 
                value={item.Title} 
                onChange={(e) => setItem({ ...item, Title: e.target.value as string })} 
                label="Title"
                helperText="The title of the field"
                fullWidth
            /> 

            <TextField 
                value={item.HelpText} 
                onChange={(e) => setItem({ ...item, HelpText: e.target.value as string })} 
                label="Helper Text"
                helperText="Secondary text for further explaning the purpose of this field."
                fullWidth
            /> 

            <FormControlLabel 
                control={<Checkbox checked={item.IsRequired === 1} onChange={(e) => setItem({ ...item, IsRequired: e.target.checked ? 1 : 0 })} />}
                label="Required"
            />

            <FormControlLabel 
                control={<Checkbox checked={item.IsUnique === 1} onChange={(e) => setItem({ ...item, IsUnique: e.target.checked ? 1 : 0 })} />}
                label="Unique"
            />

            <FormControlLabel 
                control={<Checkbox checked={item.ShowOnCreate === 1} onChange={(e) => setItem({ ...item, ShowOnCreate: e.target.checked ? 1 : 0 })} />}
                label="Show On Create"
            />

            <FormControlLabel 
                control={<Checkbox checked={item.EditOnUpdate === 1} onChange={(e) => setItem({ ...item, EditOnUpdate: e.target.checked ? 1 : 0 })} />}
                label="Edit On Update"
            />

            <FormControlLabel 
                control={<Checkbox checked={item.ShowOnTableView === 1} onChange={(e) => setItem({ ...item, ShowOnTableView: e.target.checked ? 1 : 0 })} />}
                label="Show In Table View"
            />

            <TextField 
                value={item.TableViewOrdinal} 
                onChange={(e) => setItem({ ...item, TableViewOrdinal: parseInt(e.target.value) })} 
                type="number"
                label="Table View Ordinal"
            /> 



            <TextField 
                value={item.FieldType} 
                onChange={(e) => { 

                    let updatedItem = { ...item, FieldType: parseInt(e.target.value as string )}; 

                    if(updatedItem.FieldType === FormFieldType.Checkbox) { 
                        updatedItem.DefaultValue = "0"; 
                    }

                    setItem(updatedItem); 
                }} 
                label="Field Type"
                helperText="The field type"
                fullWidth
                select
            >
                {FormFieldTypes.map((formFieldType, k) => <MenuItem value={formFieldType} key={k}>{formFieldTypeToString(formFieldType)}</MenuItem>)}
            </TextField> 

           
            {[FormFieldType.SingleLine, FormFieldType.MultiLine].indexOf(item.FieldType) > -1 && (
                <>
                    <div>
                        <TextField type="number" label="Mininum characters" value={item.MinVal} onChange={(e) => setItem({ ...item, MinVal: parseInt(e.target.value as string) })} /> - 
                        <TextField type="number" label="Maximum characters" value={item.MaxVal} onChange={(e) => setItem({ ...item, MinVal: parseInt(e.target.value as string) })} helperText="A maximum value of zero (0) means no limit will be enforced other than that of the database field." /> 
                    </div>
                    <div>
                        <TextField fullWidth label="Default Value" value={item.DefaultValue} onChange={(e) => setItem({ ...item, DefaultValue: e.target.value as string })} /> 
                    </div>
                </>
            )}
            

            {[FormFieldType.Select, FormFieldType.Radio, FormFieldType.MultiSelect].indexOf(item.FieldType) > -1 && (
                <>
                    <TextField fullWidth select label="Data Source Type" value={item.DataSourceType} onChange={(e) => setItem({ ...item, DataSourceType: parseInt(e.target.value as string)})}>
                        <MenuItem value={DataSourceType.None}>None</MenuItem>
                        <MenuItem value={DataSourceType.List}>List</MenuItem>
                        <MenuItem value={DataSourceType.Model}>Model</MenuItem>
                    </TextField>
                    
                    {item.DataSourceType === DataSourceType.None && ( 
                        <>

                            {/* Options: {item.Options}  */}
                            <Button onClick={() => addOption("Option #" + options.length, "Value #" + options.length)}>Add Option</Button>
                            {options.map((option, key) => {
                                const [title, value] = option.split(":"); 
                                return ( 

                                    <Paper elevation={2} style={{ margin: "10px 0", padding: 5 }}>
                                        
                                        <FormFieldOption 
                                            key={key} 
                                            id={key} 
                                            title={title} 
                                            value={value} 
                                            onChange={updateOption} 
                                            onRemove={removeOption} 
                                        /> 

                                    </Paper>
                                    
                                ); 
                            })}

                            <TextField label="Default Value" select fullWidth value={item.DefaultValue} onChange={(e) => setItem({ ...item, DefaultValue: e.target.value as string })}>
                                {options.map((option, key) => { 
                                    const [title, value] = option.split(':'); 
                                    return ( 
                                        <MenuItem key={key} value={value}>{title}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </>
                    )}

                    {item.DataSourceType === DataSourceType.List && (
                        <DataSourceListSelector 
                            listID={item.DataSourceID} 
                            defaultValue={item.DefaultValue}
                            onDefaultChange={(defaultValue: string) => setItem({ ...item, DefaultValue: defaultValue })}
                            onChange={(listID) => setItem({ ...item, DataSourceID: listID })} 
                        /> 
                    )}

                    {item.DataSourceType === DataSourceType.Model && (
                        <DataSourceFormSelector 
                            formID={item.DataSourceID}
                            fieldID={item.DataSourceFormFieldID} 
                            onChange={(formID: number) => setItem({ ...item, DataSourceID: formID })}
                            onFieldChange={(fieldID: number) => setItem({ ...item, DataSourceFormFieldID: fieldID })}                            
                        /> 
                    )}

                </>
            )}

            {[FormFieldType.Checkbox].indexOf(item.FieldType) > -1 && (
                <>
                    <TextField label="Default Value" select value={item.DefaultValue} onChange={(e) => setItem({ ...item, DefaultValue: e.target.value as string })} fullWidth>
                        <MenuItem value={"0"}>False</MenuItem>
                        <MenuItem value={"1"}>True</MenuItem>
                    </TextField>
                </>
            )}

            <hr /> 

            {deleting === false && 
                <IconButton onClick={() => setDeleting(true)}><Delete /></IconButton>
            }

            {deleting === true && 
                <Button onClick={() => deleteField(item)} variant="contained" style={{ color: '#fff', backgroundColor: '#f00' }}>
                    Confirm Remove Field
                </Button>
            }
        </Paper>
    )
}

const FormFieldOption : React.FC<{ 
    id: number; 
    title: string;
    value: string; 
    onChange: (id: number, key: string, value: string) => void;
    onRemove: (id: number) => void; 
}> = ({ 
    id, 
    title, 
    value, 
    onChange, 
    onRemove 
}) => { 

    const [optionTitle, setOptionTitle] = useState(title);
    const [optionValue, setOptionValue] = useState(value); 

    useEffect(() => { 
        setOptionTitle(title); 
        setOptionValue(value); 
    }, [title, value])

    const updateOption = () => { 
        if(optionTitle === "" || optionValue === "") { 
            return; 
        }

        onChange(id, optionTitle, optionValue); 
    }

    const doSetOptionTitle = (e) => { 

        if([";", ":", "\"", "'"].indexOf(e.key) > -1) {
            return 
        }
        
        setOptionTitle(e.target.value); 
    }   

    const doSetOptionValue = (e) => { 
        
        if([";", ":", "\"", "'"].indexOf(e.key) > -1) {
            return 
        }
        
        setOptionValue(e.target.value); 
    }   


    return (
        <>
            <TextField onChange={doSetOptionTitle} value={optionTitle} onBlur={updateOption} />

            <TextField onChange={doSetOptionValue} value={optionValue} onBlur={updateOption} />

            <IconButton onClick={() => onRemove(id)}>
                <Delete />
            </IconButton>
        </>
    );

}

const DataSourceListSelector : React.FC<{ listID: number; defaultValue: string; onChange: (listID: number) => void; onDefaultChange: (listItemID: string) => void;  }> = ({ listID, defaultValue, onChange, onDefaultChange }) => { 

    const { data, status, error } = useGetLists(0, "Title", "ASC"); 

    if(status === "error") {
        return <Alert color="info">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    if(data.data.Count === 0) {
        return <Alert style={{ margin: 20 }} color="info">No Entries Yet</Alert>
    }

    return (
        <>
            <TextField 
                value={listID} 
                onChange={(e) => onChange(parseInt(e.target.value as string))}
                select
                label="List"
                fullWidth
            >
                {data.data.Data.map((item, k) => <MenuItem key={k} value={item.ListID}>{item.Title}</MenuItem>)}
            </TextField>

            {listID > 0 && 
                <DataSourceListSelectorDefault listID={listID} defaultValue={defaultValue} onChange={(listItemID: string) => onDefaultChange(listItemID)} /> 
            }
        </>
    ); 
}

const DataSourceListSelectorDefault : React.FC<{ listID: number; defaultValue: string; onChange: (listItemID: string) => void; }> = ({ listID, defaultValue, onChange }) => { 

    const { data, status, error } = useGetListFromID(listID); 

    if(status === "error") {
        return <Alert color="info">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <>
            <TextField 
                value={defaultValue} 
                onChange={(e) => onChange(e.target.value as string)}
                select
                label="Default List Item"
                fullWidth
            >
                {data.data.Items.map((item, k) => <MenuItem key={k} value={item.ListItemID.toString()}>{item.Name}</MenuItem>)}
            </TextField>
        </>
    ); 
}


const DataSourceFormSelector : React.FC<{ formID: number; fieldID: number; onChange: (formID: number) => void; onFieldChange: (fieldID: number) => void;  }> = ({ formID, fieldID, onChange, onFieldChange }) => { 

    const { data, status, error } = useGetForms(0, 500, "Title", "ASC");


    if(status === "error") {
        return <Alert color="info">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    if(data.data.Count === 0) {
        return <Alert style={{ margin: 20 }} color="info">No Forms Yet</Alert>
    }

    return (
        <>
            <TextField 
                value={formID} 
                onChange={(e) => onChange(parseInt(e.target.value as string))}
                select
                label="Model"
                fullWidth
            >
                {data.data.Data.map((item, k) => <MenuItem key={k} value={item.FormID}>{item.Title}</MenuItem>)}
            </TextField>

            {formID > 0 && 
                <DataSourceFormFieldSelector formID={formID} fieldID={fieldID} onChange={(fieldID: number) => onFieldChange(fieldID)} /> 
            }
        </>
    ); 
}

const DataSourceFormFieldSelector : React.FC<{ formID: number; fieldID: number; onChange: (fieldID: number) => void; }> = ({ formID, fieldID, onChange }) => { 

    const { data, status, error } = useGetFormFromID(formID); 

    if(status === "error") {
        return <Alert color="info">{handleNetworkError(error)}</Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <>
            <TextField 
                select
                value={fieldID} 
                onChange={(e) => onChange(parseInt(e.target.value as string))}
                label="Field"
                fullWidth
            >
                {data.data.Fields.map((item, k) => <MenuItem key={k} value={item.FormFieldID}>{item.Title}</MenuItem>)}
            </TextField>
        </>
    ); 
}


export default FormDetail; 