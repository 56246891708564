/**
 *
 * Admin.AdminActivity
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserLogsSummaryAggregate } from 'gen/aggregates/UserLogsSummaryAggregate';

// GetUserLogs returns a slice of logs associated with a user
// GET /admin/activity?userID={userID:[0-9]+}&limit={limit:[0-9]+}&page={page:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}&actionType={actionType:[0-9]+}&ip={ip:[0-9]+}
// @permission AdminActivity_GetUserLogs
export const getUserLogs = async (userID : number, limit : number, page : number, orderBy : string, orderDir : string, actionType : number, ip : number) => await axios.get<UserLogsSummaryAggregate>(`/admin/activity?userID=${userID}&limit=${limit}&page=${page}&orderBy=${orderBy}&orderDir=${orderDir}&actionType=${actionType}&ip=${ip}`);
export const useGetUserLogs = (userID : number, limit : number, page : number, orderBy : string, orderDir : string, actionType : number, ip : number) => useQuery(["getUserLogs", userID, limit, page, orderBy, orderDir, actionType, ip], (_ : string, userID : number, limit : number, page : number, orderBy : string, orderDir : string, actionType : number, ip : number) => getUserLogs(userID,limit,page,orderBy,orderDir,actionType,ip));
