import React from 'react'; 
import { GridSize, Grid, Paper, Divider } from '@material-ui/core';
import styled from "styled-components";

export const PaperBox = styled(Paper)`
    margin: 10px; 
    padding: 10px; 
`


export const Cell = (props : { xs: GridSize, children: React.ReactNode, right?: boolean }) => <CellEl 
    item 
    xs={props.xs}
    style={{ textAlign: props.right ? 'right' : 'left' }}
>
        {props.children}
    </CellEl>

export const CellEl = styled(Grid)`
    min-height: 40px; 
    line-height: 40px; 
    vertical-align: middle; 
`

export const Divide = () => <Divider style={{ width: '100%', marginTop: 20, marginBottom: 20 }} /> 
