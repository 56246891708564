import { TagDTO } from "gen/dtos/TagDTO"
import { Policy } from "gen/models/Policy"
import { newProfile, Profile } from "gen/models/Profile"
import { Role } from "gen/models/Role"
import { newUser, User } from "gen/models/User"
import { newUserDevice, UserDevice } from "gen/models/UserDevice"
import { UserGroup } from "gen/models/UserGroup"

export type UserAggregate = User & {
    Device: UserDevice; 
    Groups: UserGroup[]; 
    Roles: Role[]; 
    PermissionNames: string[]; 
    Policies: Policy[];
    Profile: Profile; 
    Tags: TagDTO[]; 
    Settings : { [ key: string ] : string }; 
}

export const newUserAggregate = () : UserAggregate => ({
    ...newUser(), 
    Device: newUserDevice(), 
    Groups: [], 
    Roles: [], 
    PermissionNames: [], 
    Policies: [], 
    Profile: newProfile(), 
    Tags: [], 
    Settings: {}, 
})