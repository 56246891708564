import { Input } from '@material-ui/core';
import { Container } from 'core/components/Container';
import React from 'react';
import { useRecoilState } from 'recoil';
import { selectedQuoteAtom } from './state';

const QuoteNotes = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 

    return (
        <Container title="Project Notes">
            <Input 
                style={{ width: '100%' }} 
                multiline 
                value={quote.Notes} 
                onChange={(e) => setQuote({ ...quote, Notes: e.target.value })} 
                rowsMax={20}
                rows={10}
            /> 
        </Container>
    )
}

export default QuoteNotes; 