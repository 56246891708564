import { CircularProgress, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ConfirmModal from 'core/components/confirmModal';
import handleNetworkError from 'core/utils/handleNetworkError';
import { PolicyAggregate } from 'gen/aggregates/PolicyAggregate';
import { UpdatePolicyDTO } from 'gen/dtos/UpdatePolicyDTO';
import { useDeletePolicy, useGetPolicyFromID } from 'gen/routes/Policies';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { editPolicyAtom, selectedManagePolicyAtom } from './state';
import UpdatePolicyDialog from './UpdatePolicyDialog';

const newUpdatePolicyDTOFromAggregate = (agg: PolicyAggregate) : UpdatePolicyDTO => ({ 
    PermissionPolicyID: 0, 
    Title: agg.Title, 
    Description: agg.Description, 
    ShortName: agg.ShortName, 
});

const PolicyDetailDetail = () => {

    console.log('PolicyDetailDetail.render()'); 
    
    const selectedPolicyID = useRecoilValue(selectedManagePolicyAtom);

    if(selectedPolicyID === 0) {
        return null; 
    }

    return (
        <PolicyPermissionListInner />
    )
}

const PolicyPermissionListInner = () => {

    const [selectedPolicyID, setSelectedPolicyID] = useRecoilState(selectedManagePolicyAtom); 
    const { data: policy, status: policyStatus, error: policyError } = useGetPolicyFromID(selectedPolicyID); 
    const [deletePolicy] = useDeletePolicy(); 
    const setEditPolicy = useSetRecoilState(editPolicyAtom);
    const [isDeletingPolicy, setIsDeletingPolicy] = useState(false); 

   
    if(policyStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(policyError)}
        </Alert>
    }

    if(policyStatus === "loading" || !policy) {
        return <CircularProgress /> 
    }

    const doDeletePolicy = () => {
        deletePolicy({ policyID: policy.data.PolicyID })
            .then(() => {
                setSelectedPolicyID(0); 
                setIsDeletingPolicy(false); 
            }); 
    }

    // const doUpdatePolicy = () => {
        // updatePolicy({ PolicyID: policy.data.PolicyID, ShortName: policyTitle, Title:  })
        //     .then(() => {
        //         setIsEditingRoleTitle(false); 
        //     });
    // }


    return (
        <>
            <div>

                <div style={{ display: 'flex', height: 48 }}>
                    
                    <div style={{ flexGrow: 1, lineHeight: '48px', verticalAlign: 'middle', height: 48 }} onClick={() => setEditPolicy({ ...newUpdatePolicyDTOFromAggregate(policy.data), PolicyID: policy.data.PolicyID })}>

                        {policy.data.ShortName}

                    </div>


                    <div>


                        <IconButton onClick={() => setIsDeletingPolicy(true)}>
                            <Delete /> 
                        </IconButton>

                    </div>
                </div>
                    
        
                <ConfirmModal 
                    title="Delete this Policy?"
                    description="Are you sure you want to delete this policy?"
                    open={isDeletingPolicy}
                    setOpen={setIsDeletingPolicy}
                    confirm={doDeletePolicy}
                /> 

                <UpdatePolicyDialog /> 
                <div style={{ marginTop: 20 }}>
                    {policy.data.Description}
                </div>
            </div>
            
        </>
    )
}

export default PolicyDetailDetail; 