import { CircularProgress, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDeleteCustomer, useGetCustomerFromID } from 'app/api/customers';
import { CustomerAggregate } from 'app/definitions/aggregates/CustomerAggregate';
import ConfirmModal from 'core/components/confirmModal';
import { Edit } from 'core/components/fabs';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { Tab, Tabs } from 'core/components/tabs';
import { ObjectTypeCustomer } from 'core/definitions/constants/objectTypes';
import ObjectTodos from 'core/pages/todos/ObjectTodos';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Customers_CanManageLogsPermission, Customers_DeleteCustomerPermission } from 'gen/constants/permissions';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSetRecoilState } from 'recoil';
import { PaperBox } from '../jobs/common';
import { selectedCustomerAtom, showEditCustomerDialogAtom } from '../quotes/state';
import CustomerContactsList from './CustomerContactsList';
import EditCustomerModal from './EditCustomerModal';


const ITEM_HEIGHT = 48; 
const CustomerDetail = () => {

    const { id } = useParams() as { id: string }; 
    const { status, data, error } = useGetCustomerFromID(parseInt(id as string)); 
    const [deleteCustomer, { status: deleteCustomerStatus, error: deleteCustomerError }] = useDeleteCustomer(); 
    const [tab, setTab] = useState("details");  
    const history = useHistory(); 
    const [deleteModalShowing, setDeleteModalShowing] = useState(false); 
    const [actionsEl, setActionsEl] = useState(null); 
    const actionsShowing = Boolean(actionsEl); 
    const setSelectedCustomer = useSetRecoilState(selectedCustomerAtom); 

    useEffect(() => {
        if(deleteCustomerStatus === "success") {
            history.push(`/customers`); 
        }
    }, [deleteCustomerStatus, history])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(status === "success" && data) {
            setSelectedCustomer(data.data); 
        }
    }, [status, data])


    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    if(status === "error") {
        return <Alert color="danger">{handleNetworkError(error)}</Alert>
    }

    const handleShowActions = (e) => setActionsEl(e.currentTarget); 
    const handleHideActions = () => setActionsEl(null); 

    const deleteCustomerStart = () => {
        setDeleteModalShowing(true); 
        handleHideActions(); 
    }


    const customer = data.data; 

    const confirmDelete = () => {
        deleteCustomer(customer.CustomerID);
    }

 

    return (
        <>
            <NavBarSecondary 
                back="/customers"
                right={
                    <Perm 
                        perm={[Customers_DeleteCustomerPermission]} 
                        any
                    >
                        <IconButton onClick={handleShowActions}>
                            <MoreVert /> 
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={actionsEl}
                            keepMounted
                            open={actionsShowing}
                            onClose={handleHideActions}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            {/* <Perm perm={CustomersArchiveCustomerPermission}>
                                <MenuItem key={0} onClick={handleHideActions}>
                                    <Archive /> 
                                    Archive
                                </MenuItem>
                            </Perm> */}
                            <Perm perm={Customers_DeleteCustomerPermission}>
                                <MenuItem key={1} onClick={deleteCustomerStart}>
                                    Delete Customer
                                </MenuItem>
                            </Perm>
                        </Menu>
                    </Perm>
                }
            >
                Customers / {customer.Title}
            </NavBarSecondary>
            <Tabs>
                <Tab label="Details" value={'details'} tab={tab} onClick={() => setTab('details')} />
                <Tab label={`Contacts (${customer.ContactCount})`} value={'contacts'} tab={tab} onClick={() => setTab('contacts')} />
                <Tab label={`Logs`} value={'logs'} tab={tab} onClick={() => setTab('logs')} perm={Customers_CanManageLogsPermission} />

            </Tabs>
            
            {{
                "details": <Detail customer={customer} />, 
                "contacts": <CustomerContactsList />, 
                "quotes": <CustomerContactsList />, 
                "logs": <CustomerLogs customer={customer} /> 
            }[tab]}

        
            {/* Delete customer error */}
            {deleteCustomerStatus === "error" && 
                <Snackbar 
                    anchorOrigin={{
                        vertical: 'bottom', 
                        horizontal: 'center'
                    }}
                    message={`Error: ${handleNetworkError(deleteCustomerError)}`}
                    open={deleteCustomerStatus === "error"} onClose={() => null} 
                />
            }

            <ConfirmModal 
                title="Delete this customer?"
                description="Are you sure you want to delete this customer?"
                open={deleteModalShowing}
                setOpen={setDeleteModalShowing}
                confirm={confirmDelete}
            /> 

        </>
    )
   
}


const CustomerLogs : React.FC<{ customer: CustomerAggregate }> = ({ customer }) => { 

    return ( 
        <PaperBox>
            <ObjectTodos 
                assignedTo={0} 
                objectType={ObjectTypeCustomer} 
                objectID={customer.CustomerID} 
                showCreate={true}
                isPlainLog
            /> 
        </PaperBox>
    )
}

const Detail = (props: { customer: CustomerAggregate }) => {

    const { customer } = props; 
    const setEditShowing = useSetRecoilState(showEditCustomerDialogAtom); 

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{customer.Address}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Address 2</TableCell>
                        <TableCell>{customer.Address2}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>City</TableCell>
                        <TableCell>{customer.City}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>{customer.State}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Zip</TableCell>
                        <TableCell>{customer.Zip}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{customer.Phone}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Fax</TableCell>
                        <TableCell>{customer.Fax}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Edit action={() => setEditShowing(true)} /> 

            <EditCustomerModal /> 
        </div>
    )
}

export default CustomerDetail; 