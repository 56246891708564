/**
 *
 * Admin.AdminDB
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';

// GetAllDBStats gets stats on all databases
// GET /admin/dbs/stats
// @permission AdminDB_GetAllDBStats
export const getAllDBStats = async () => await axios.get<any>(`/admin/dbs/stats`);
export const useGetAllDBStats = () => useQuery(["getAllDBStats"], (_ : string) => getAllDBStats());
