/**
 *
 * Job
 */

export type Job = {

	// AwardDate int64
	AwardDate: number;

	// AwardDateString string
	AwardDateString: string;

	// BidTypeID int64
	BidTypeID: number;

	// BillingAddress string
	BillingAddress: string;

	// BillingAddressCity string
	BillingAddressCity: string;

	// BillingAddressState string
	BillingAddressState: string;

	// BillingAddressZip string
	BillingAddressZip: string;

	// BondStatusID int64
	BondStatusID: number;

	// CallAhead string
	CallAhead: string;

	// Comments string
	Comments: string;

	// CommissionTypeID int64
	CommissionTypeID: number;

	// CompanyProvidingBond string
	CompanyProvidingBond: string;

	// ContractorAddress string
	ContractorAddress: string;

	// ContractorCity string
	ContractorCity: string;

	// ContractorName string
	ContractorName: string;

	// ContractorState string
	ContractorState: string;

	// ContractorZip string
	ContractorZip: string;

	// CustomerContactID int64
	CustomerContactID: number;

	// CustomerID int64
	CustomerID: number;

	// CustomerPO1Number string
	CustomerPO1Number: string;

	// CustomerPO1SentTo int64
	CustomerPO1SentTo: number;

	// CustomerPO2Number string
	CustomerPO2Number: string;

	// CustomerPO2SentTo int64
	CustomerPO2SentTo: number;

	// DateCreated int64
	DateCreated: number;

	// Description string
	Description: string;

	// GrossMarginPercent float64
	GrossMarginPercent: number;

	// GrossProfit float64
	GrossProfit: number;

	// Guid string
	Guid: string;

	// InsideSalesID int64
	InsideSalesID: number;

	// IsAddFreight int
	IsAddFreight: number;

	// IsDeleted int
	IsDeleted: number;

	// IsThirdPartySplit int
	IsThirdPartySplit: number;

	// JEFDate int64
	JEFDate: number;

	// JEFDateString string
	JEFDateString: string;

	// JobID int64
	JobID: number;

	// JobNumberString string
	JobNumberString: string;

	// JobPOOrdinal int64
	JobPOOrdinal: number;

	// LastUpdated int64
	LastUpdated: number;

	// MarketID int64
	MarketID: number;

	// Notes null.String
	Notes: string;

	// PONumber string
	PONumber: string;

	// ProjectCost float64
	ProjectCost: number;

	// PropertyAddress string
	PropertyAddress: string;

	// PropertyCity string
	PropertyCity: string;

	// PropertyName string
	PropertyName: string;

	// PropertyState string
	PropertyState: string;

	// PropertyZip string
	PropertyZip: string;

	// PublicPrivateMoneyID int64
	PublicPrivateMoneyID: number;

	// QuoteID int64
	QuoteID: number;

	// QuoteNumberID int64
	QuoteNumberID: number;

	// RelationshipID int64
	RelationshipID: number;

	// RemainingGrossProfit float64
	RemainingGrossProfit: number;

	// Sales1 string
	Sales1: string;

	// Sales1ID int64
	Sales1ID: number;

	// Sales2 string
	Sales2: string;

	// ShipToName string
	ShipToName: string;

	// ShipToTag string
	ShipToTag: string;

	// ShippingAddress string
	ShippingAddress: string;

	// ShippingAddressCity string
	ShippingAddressCity: string;

	// ShippingAddressState string
	ShippingAddressState: string;

	// ShippingAddressZip string
	ShippingAddressZip: string;

	// TaxExemptNumber string
	TaxExemptNumber: string;

	// ThirdPartyCommission float64
	ThirdPartyCommission: number;

	// ThirdPartyName string
	ThirdPartyName: string;

	// ThirdPartySplitPercent float64
	ThirdPartySplitPercent: number;

	// TotalCostActual float64
	TotalCostActual: number;

	// TotalPrice float64
	TotalPrice: number;

	// TotalPriceActual float64
	TotalPriceActual: number;

	// Vendor1ID int64
	Vendor1ID: number;

	// Vendor2ID int64
	Vendor2ID: number;

	// EngineerCoName string
	EngineerCoName: string;

}

// newJob is a factory method for creating new Job objects
export const newJob = () : Job => ({ 
	AwardDate: 0,
	AwardDateString: '',
	BidTypeID: 0,
	BillingAddress: '',
	BillingAddressCity: '',
	BillingAddressState: '',
	BillingAddressZip: '',
	BondStatusID: 0,
	CallAhead: '',
	Comments: '',
	CommissionTypeID: 0,
	CompanyProvidingBond: '',
	ContractorAddress: '',
	ContractorCity: '',
	ContractorName: '',
	ContractorState: '',
	ContractorZip: '',
	CustomerContactID: 0,
	CustomerID: 0,
	CustomerPO1Number: '',
	CustomerPO1SentTo: 0,
	CustomerPO2Number: '',
	CustomerPO2SentTo: 0,
	DateCreated: 0,
	Description: '',
	GrossMarginPercent: 0,
	GrossProfit: 0,
	Guid: '',
	InsideSalesID: 0,
	IsAddFreight: 0,
	IsDeleted: 0,
	IsThirdPartySplit: 0,
	JEFDate: 0,
	JEFDateString: '',
	JobID: 0,
	JobNumberString: '',
	JobPOOrdinal: 0,
	LastUpdated: 0,
	MarketID: 0,
	Notes: '',
	PONumber: '',
	ProjectCost: 0,
	PropertyAddress: '',
	PropertyCity: '',
	PropertyName: '',
	PropertyState: '',
	PropertyZip: '',
	PublicPrivateMoneyID: 0,
	QuoteID: 0,
	QuoteNumberID: 0,
	RelationshipID: 0,
	RemainingGrossProfit: 0,
	Sales1: '',
	Sales1ID: 0,
	Sales2: '',
	ShipToName: '',
	ShipToTag: '',
	ShippingAddress: '',
	ShippingAddressCity: '',
	ShippingAddressState: '',
	ShippingAddressZip: '',
	TaxExemptNumber: '',
	ThirdPartyCommission: 0,
	ThirdPartyName: '',
	ThirdPartySplitPercent: 0,
	TotalCostActual: 0,
	TotalPrice: 0,
	TotalPriceActual: 0,
	Vendor1ID: 0,
	Vendor2ID: 0,
	EngineerCoName: '',
});

