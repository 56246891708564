import {
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import {
  downloadSalesActivityReportExcel,
  downloadSalesActivityReportPDF,
  useGetSalesActivityReport,
} from "app/api/reports";
import NavBarSecondary from "core/components/NavBarSecondary";
import { Report } from "core/components/tables";
import { ReportAggregate } from "core/definitions/aggregates/ReportAggregate";
import handleNetworkError from "core/utils/handleNetworkError";
import { Perm } from "core/utils/hasPerm";
import {
  Reports_DownloadSalesActivityReportExcelPermission,
  Reports_DownloadSalesActivityReportPDFPermission,
} from "gen/constants/permissions";

import moment from "moment";
import React, { useState } from "react";
import { Alert } from "reactstrap";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { salesActivityReportAtom } from "./state";

const salesActivityReportYearAtom = atom<number>({
  key: "salesActivityReportYearAtom",
  default: new Date().getFullYear(),
});

enum Scope {
  Totals = "totals",
  Sales = "sales",
  Vendors = "vendors",
}

export const salesActivityReportScopeAtom = atom<Scope>({
  key: "salesActivityReportScopeAtom",
  default: Scope.Totals,
});

const SalesActivityReport = () => {
  const year = useRecoilValue(salesActivityReportYearAtom);
  const scope = useRecoilValue(salesActivityReportScopeAtom);
  console.log("year", year, "scope", scope);
  const { data, error, status } = useGetSalesActivityReport(year, scope);

  if (status === "error") {
    return <Alert color="danger">{handleNetworkError(error)}</Alert>;
  }

  if (status === "loading" || !data) {
    return <CircularProgress />;
  }

  // const table = data.data;

  return <QuoteReportsInner data={data.data} />;
};

const QuoteReportsInner: React.FC<{ data: ReportAggregate }> = ({ data }) => {
  const [year, setYear] = useRecoilState(salesActivityReportYearAtom);
  const [scope, setScope] = useRecoilState(salesActivityReportScopeAtom);
  const [sorting, setSorting] = useRecoilState<any>(salesActivityReportAtom);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const gotoYear = (e) => {
    setYear(parseInt(e.target.value as string));
  };

  const handleMenuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const doDownloadSalesActivityReportExcel = () => {
    downloadSalesActivityReportExcel(scope, year)
  };
  const doDownloadSalesActivityReportPDF = () => {
    downloadSalesActivityReportPDF(scope, year)
  };

  const thisYear = parseInt(moment().format("YYYY"));
  let dates: number[] = [2020];
  const l = thisYear - 2020;
  for (let k = 1; k < l + 1; k++) {
    // console.log(2020 + k)
    dates.push(2020 + k);
  }

  // @test
  return (
    <div style={{ height: "100%", overflowY: "hidden" }}>
      <NavBarSecondary
        right={
          <div style={{ display: "flex" }}>
            <Perm
              perm={[
                Reports_DownloadSalesActivityReportExcelPermission,
                Reports_DownloadSalesActivityReportPDFPermission,
              ]}
            >
              <>
                <IconButton onClick={handleMenuClick}>
                  <CloudDownload />
                </IconButton>

                <Menu
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={() => setMenuAnchorEl(null)}
                >
                  <Perm
                    perm={Reports_DownloadSalesActivityReportExcelPermission}
                  >
                    <MenuItem onClick={doDownloadSalesActivityReportExcel}>
                      Download Excel
                    </MenuItem>
                  </Perm>
                  <Perm perm={Reports_DownloadSalesActivityReportPDFPermission}>
                    <MenuItem onClick={doDownloadSalesActivityReportPDF}>
                      Download PDF
                    </MenuItem>
                  </Perm>
                </Menu>
              </>
            </Perm>
          </div>
        }
      >
        <strong>Summary</strong>

        <Select value={year} onChange={gotoYear}>
          {dates.map((date, key) => (
            <MenuItem key={key} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>

        <ButtonGroup variant="contained" color="primary">
          <Button
            style={
              scope === Scope.Totals
                ? { backgroundColor: "#3f51b5" }
                : { backgroundColor: "#444" }
            }
            onClick={() => setScope(Scope.Totals)}
          >
            Totals
          </Button>
          <Button
            style={
              scope === Scope.Sales
                ? { backgroundColor: "#3f51b5" }
                : { backgroundColor: "#444" }
            }
            onClick={() => setScope(Scope.Sales)}
          >
            Sales
          </Button>
          <Button
            style={
              scope === Scope.Vendors
                ? { backgroundColor: "#3f51b5" }
                : { backgroundColor: "#444" }
            }
            onClick={() => setScope(Scope.Vendors)}
          >
            Manufacturers
          </Button>
        </ButtonGroup>
      </NavBarSecondary>

      <Report
        report={data}
        sorting={sorting}
        setSorting={setSorting}
        title={"Sales Activity Report"}
      />
    </div>
  );
};

export default SalesActivityReport;
