import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const AlertDialog = (props: {
    title: string, 
    open: boolean, 
    description: string, 
    handleClose: () => void, 
    yes?: () => void, 
    no?: () => void, 
    yesText?: string, 
    noText?: string, 
}) => {


    const { open, handleClose, title, description } = props; 

    const yes = (!props.yes) ? handleClose : props.yes; 
    const no = (!props.no) ? handleClose : props.no; 
    const yesText = !props.yesText ? "OK" : props.yesText; 
    const noText = !props.noText ? "Cancel" : props.noText; 

    if(!open) {
        return null; 
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onEscapeKeyDown={no}
                onBackdropClick={no} 
                >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={yes} color="primary" autoFocus>
                        {yesText}
                    </Button>
                    {props.no &&
                        <Button onClick={no}>
                            {noText}
                        </Button>
                    } 
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog; 