import { newSearchJobDTO, newSearchJobDTOFromMap, SearchJobDTO } from 'app/definitions/dtos/SearchJobDTO';
import { newObjectSearchAggregate, ObjectSearchAggregate } from 'core/definitions/aggregates/ObjectSearchAggregate';
export type JobSearchAggregate = ObjectSearchAggregate<SearchJobDTO>;

export const newJobSearchAggregate = () : JobSearchAggregate => ({
    ...newObjectSearchAggregate(newSearchJobDTO()), 
})

export const newJobSearchAggregateFromMap = (map: Map<string, string>): JobSearchAggregate => ({ 
    ...newObjectSearchAggregate(
        newSearchJobDTOFromMap(map), 
    )
})