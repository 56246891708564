import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Forms_GenerateRandomSubmissionsPermission } from 'gen/constants/permissions';
import { useGenerateRandomSubmissions, useGetFormData } from 'gen/routes/Forms';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Alert } from 'reactstrap';
import AdminActions from '../common/AdminActions';
import AdminHeader from '../common/AdminHeader';
import FormDataSubmissionCreateForm from './FormDataSubmissionCreateForm';
import FormDataSubmissionEditForm from './FormDataSubmissionEditForm';

const useStyles = makeStyles({ 
    emptyCell: { 
        backgroundColor: "#ccc"
    }, 
    cell: { 
        maxWidth: '100px', 
        overflow: 'hidden'
    }
})

const FormData = () => { 
    
    const { id } = useParams() as { id: string }; 
    
    const formID = parseInt(id); 
    const limit = 50; 
    const [page, setPage] = useState(0); 
    const { data, error, status } = useGetFormData(formID, page, limit); 
    const [editShowing, setEditShowing] = useState(0); 
    const [newShowing, setNewShowing] = useState(false); 

    const [isGeneratingRandom, setIsGeneratingRandom] = useState(false); 
    const [generateRandom] = useGenerateRandomSubmissions(); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const onEditRow = (id: number) => () => { 
        setEditShowing(id)
    }

    const onDeleteRow = (id: number) => () => { }

    // const fields = data.data.Fields.filter(x => x.ShowOnTableView === 1);

    // const fieldList = fields.map(field => field.FormFieldID); 

    const doGenerateRandom = () => { 
        setIsGeneratingRandom(true); 
        generateRandom({ formID: formID, qty: 100 })
            .then(() => { 
                setIsGeneratingRandom(false); 
            })
            .catch(e => { 
                setIsGeneratingRandom(false); 
                alert(handleNetworkError(e))
            });
    }

    // TODO Separate title for column (different from title shown on form)
    // TODO Revisions 
    return (
        <>
            <Dialog open={editShowing > 0} onClose={() => setEditShowing(0)}>
                <DialogTitle>Edit {data.data.FormTitle} #{editShowing}</DialogTitle>
                <DialogContent>
                    <FormDataSubmissionEditForm 
                        formID={formID} 
                        submissionID={editShowing} 
                        onSuccess={() => setEditShowing(0)}
                    /> 
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditShowing(0)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={newShowing} onClose={() => setNewShowing(false)}>
                <DialogTitle>Create {data.data.FormTitle}</DialogTitle>
                <DialogContent>
                    <FormDataSubmissionCreateForm
                        formID={formID} 
                        onSuccess={() => setNewShowing(false)}
                    /> 
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewShowing(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            
            <div style={{ height: '100%', overflowY: 'hidden' }}>
                <NavBarSecondary
                    right={<>
                        <Perm perm={Forms_GenerateRandomSubmissionsPermission}>
                            <Button disabled={isGeneratingRandom} onClick={doGenerateRandom}>
                                Generate 100 Random
                                {isGeneratingRandom && <CircularProgress size={20} />}
                            </Button>
                        </Perm>
                        <Button onClick={() => setNewShowing(true)} variant="contained">
                            <Add />
                            New
                        </Button>
                        <TableNav limit={limit} page={page} count={data.data.SubmissionCount} prev={() => setPage(page - 1)} next={() => setPage(page + 1)} /> 
                        &nbsp; 
                        <AdminActions actions={[
                                // { title: 'Activate', fn: () => activateUser({ userID: user.UserID }), show: () => user.DateActivated === 0, perm: AdminUsers_ActivateUserPermission }, 
                                // { title: user.IsDisabled === 0 ? 'Disable' : 'Enable', fn: () => user.IsDisabled === 0 ? disableUser({ userID: user.UserID }) : enableUser({ userID: user.UserID }), show: () => user.DateActivated > 0, perm: user.IsDisabled ? AdminUsers_EnableUserPermission : AdminUsers_DisableUserPermission }, 
                                // { title: user.IsLocked === 0 ? 'Lock' : 'Unlock', fn: () => user.IsLocked === 0 ? lockUser({ userID: user.UserID }) : unLockUser({ userID: user.UserID }), show: () => user.DateActivated > 0}, 
                                // { title: "Refresh Session", fn: doRefreshUserSession, perm: AdminUtils_RefreshAllUserSessionsPermission}, 
                            ]}
                        /> 
                    </>}
                >
                    <AdminHeader title={"Data"} parents={[{title: "Forms", url: "/admin/forms"}, { title: data.data.FormTitle, url: `/admin/forms/${data.data.FormID}`}]} /> 

                    {/* {user.IsDisabled === 1 && <Chip label="Disabled" onDelete={() => enableUser({ userID: user.UserID })}  />}
                    {user.IsLocked === 1 && <Chip icon={<Lock />} label="Locked" onDelete={() => unLockUser({ userID: user.UserID })} />}
                    {user.DateActivated === 0 && <Chip label="Inactive" onDelete={() => activateUser({ userID: user.UserID })} />} */}
                </NavBarSecondary>
                <div style={{ height: 'calc(100% - 48px', overflowY: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {data.data.Fields.filter(x => x !== "#").map((field, key) => <TableCell key={key}>{field}</TableCell>)}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.data.Values.map((row, key) => (
                                <FormDataRow  
                                    data={row} 
                                    key={key} 
                                    onEdit={onEditRow(parseInt(row[0]))}
                                    onDelete={onDeleteRow(parseInt(row[0]))}
                                />
                            ))}
                            
                        </TableBody>
                    </Table>
                </div>
            </div>
        </>
    )
}

const FormDataRow : React.FC<{ 
    data: string[];  
    onEdit: () => void; 
    onDelete: () => void; 
}> = ({ data, onEdit, onDelete }) => { 

    const classes = useStyles(); 
    // const cells = data.Data.split(";")

    // console.log(data) 

    return (
        <TableRow>
            {/* <TableCell>{moment(data.DateCreated).format('MM/DD/YYYY h:mma')}</TableCell> */}
            {data.filter((x, y) => y !== 0).map((cell, key) => <TableCell className={(cell.length > 0 ? '' : classes.emptyCell) + " " + classes.cell} key={key}>
                <div className={classes.cell}>{cell}</div>
            </TableCell>)}
            <TableCell>
                <IconButton onClick={onEdit}><Edit /></IconButton>
                &nbsp; 
                <IconButton onClick={onDelete}><Delete /></IconButton>
            </TableCell>
        </TableRow>
    )

}
export default FormData; 
