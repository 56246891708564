import { Perm } from 'core/utils/hasPerm';
import React from 'react';
import styled from 'styled-components';


export const Tabs = styled.div`
    border-bottom: solid 1px #616161; 
    margin-top: 10px; 
    padding-left: 20px; 
    margin-bottom: 10px; 
    height: 48px; 
    width: 100%; 
`; 

const TabItem = styled.div`
    height: 100%; 
    flex-shrink: 0; 
    line-height: 48px; 
    vertical-align: middle; 
    margin-left: 5px; 
    margin-right: 5px; 
    width: auto; 
    padding-left: 20px; 
    padding-right: 20px; 
    border-top-left-radius: 4px; 
    border-top-right-radius: 4px; 
    display: inline-block; 
    &:hover { 
        background-color: #616161; 
        cursor: pointer; 
    }
`;

const TabItemSelected = styled(TabItem)`
    background-color: #616161; 
    color: #fff; 
    height: 48px; 
    margin-bottom: 0px; 
`; 

export const Tab = (props: { perm?: string | string[] | undefined, value: number | string, label: string, onClick: () => void , tab: number | string }) => {

    return (
        <Perm perm={props.perm}>
            {props.value === props.tab ? 
                <TabItemSelected onClick={props.onClick}>
                    {props.label}
                </TabItemSelected>
            : <TabItem onClick={props.onClick}>
                {props.label}
            </TabItem>}
        </Perm>
    )

}