import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PencilIcon from '@material-ui/icons/Edit';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    add: {
        position: 'absolute', 
        bottom: 40, 
        right: 20, 
    },
    edit: {
        position: 'absolute', 
        bottom: 40, 
        right: 20, 
    },
})); 


export const Add : React.FC<{ action: () => void }> = ({ action }) => { 
    
    const classes = useStyles(); 

    return (
        <Fab color="primary" aria-label="add" size="small" className={classes.add} onClick={action}>
            <AddIcon />
        </Fab>
    )
}

export const Edit : React.FC<{ action: () => void }> = ({ action }) => {
    
    const classes = useStyles(); 

    return (
        <Fab color="secondary" aria-label="add" size="small" className={classes.edit} onClick={action}>
            <PencilIcon />
        </Fab>
    )
}