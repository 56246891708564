/**
 *
 * Base.Identity
 */
 
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserAggregate } from 'gen/aggregates/UserAggregate';

// GetCurrentUser gets the current user
// GET /users/self
export const getCurrentUser = async () => await axios.get<UserAggregate>(`/users/self`);
export const useGetCurrentUser = () => useQuery(["getCurrentUser"], (_ : string) => getCurrentUser());
