import { Button, TextField } from '@material-ui/core';
import { Status, statusDefault } from 'core/common/status';
import handleNetworkError from 'core/utils/handleNetworkError';
import { resetPasswordAnonymousStart } from 'gen/routes/PasswordReset';
import React, { useState } from 'react';
import { AlertError, AlertSuccess, AuthForm, AuthTitle, Ln, Row } from './common';

const ResetStart = () => {

    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(statusDefault());  

    const doResetPassword = () => {
        resetPasswordAnonymousStart({ body: { Email: email } }) 
            .then(() => {
                setStatus({ status: Status.Success, message: `If an account was found in our system, an email was sent to ${email}. Please check your email for further instructions.` })
            })
            .catch(e => { 
                setStatus({ status: Status.Failure, message: handleNetworkError(e) }); 
            }); 
    }

    return (
        <AuthForm>
            
            <AuthTitle>Reset Your Password</AuthTitle>

            {
                {
                    // [Status.Failure]: <AlertError>{status.message}</AlertError>, 
                    [Status.Success]: <AlertSuccess>{status.message}</AlertSuccess>, 
                }[status.status] || (
                    <>
                        {status.status === Status.Failure && 
                            <AlertError>{status.message}</AlertError>
                        }
                        <Row>Please provide the email address associated with your account. An email will be sent to that address with further instructions for resetting your password.</Row>
                        
                        <TextField fullWidth value={email} onChange={(e) => setEmail(e.target.value as string)} label="Email" /> 
        
                        <br /> 

                        <Row>
                            <Button onClick={doResetPassword} variant="contained">
                                Submit
                            </Button>
                            &nbsp; 
                            &nbsp;
                            &nbsp;
                            <Ln to="/login">Login</Ln>
                        </Row>
                    </>
                )
            }

            
        </AuthForm>

    )

}

export default ResetStart; 