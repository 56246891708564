import { Button, CircularProgress, Divider, Grid, GridSize, IconButton, MenuItem, Paper, TextField } from '@material-ui/core';
import { ChevronLeft, ChevronRight, CloudDownload } from '@material-ui/icons';
import { downloadJobExcel, useGetJobFromID, useUpdateJob } from 'app/api/jobs';
import { useGetVendors } from 'app/api/vendors';
import { newUpdateJobDTOFromModel } from 'app/definitions/dtos/UpdateJobDTO';
import { newUpdateJobItemDTOFromModel } from 'app/definitions/dtos/UpdateJobItemDTO';
import { DatePickerButton } from 'core/components/DatePicker';
import MoneyFormat from 'core/components/MoneyFormat';
import { MoneyInput } from 'core/components/MoneyInput';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { SnackbarError, SnackbarSuccess } from 'core/components/Snackbars';
import { Tab, Tabs } from 'core/components/tabs';
import { ListTypeCommissionType } from 'core/definitions/constants/listTypes';
import { ObjectTypeJob } from 'core/definitions/constants/objectTypes';
import ObjectTodos from 'core/pages/todos/ObjectTodos';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Job_CanManageCloseoutPermission, Job_CanManageLogsPermission, Job_CanManageTasksPermission } from 'gen/constants/permissions';
import { useGetListFromListType, useGetListsByStringIDs } from 'gen/routes/Lists';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { JobCloseOut } from './JobCloseOut';
import JobDetailInsideSales from './JobDetailInsideSales';
import JobDetailItems from './JobDetailItems';
import JobDetailSales from './JobDetailSales';
import { grossMarginSelector, grossProfitSelector, itemsSelector, remainingGrossProfitSelector, selectedJobAtom, thirdPartyCommissionSelector, totalCostSelector, updateJobStatusAtom, useInsertItems } from './state';

const JobDetail = () => {

    const [tab, setTab] = useState('jef');
    console.log('JobDetail::render()'); 
    const { id } = useParams() as { id: string }; 
    const jobID = parseInt(id as string); 
    const { status, error, data } = useGetJobFromID(jobID); 
    const setJob = useSetRecoilState(selectedJobAtom); 
    // const setDTO = useSetRecoilState(selectedUpdateJobDTOAtom); 
    // const setItems = useSetRecoilState(selectedUpdateJobDTOItemsAtom); 
    const updateJobStatus = useRecoilValue(updateJobStatusAtom); 
    const insertItems = useInsertItems(); 

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(status === "success" && data) {
            setJob(data.data);
            const dtos = data.data.Items.map(item => newUpdateJobItemDTOFromModel(item)); 
            insertItems(dtos); 
        }

    }, [status, data]); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }
   

    // const costTotal = job.Items.map(item => item.TotalCost).reduce((p,v) => p + v); 
    // const profitMargin = (job.Quote.TotalPrice - costTotal);
    // const profitMarginPercent = profitMargin / job.Quote.TotalPrice; 
    // const markupPercent = profitMargin / costTotal; 
    // const allocatedProfitTotal = job.Items.map(item => markupPercent * item.TotalPrice).reduce((p, v) => p + v); 
    // const allocatedSaleTotal = job.Items.map(item => (markupPercent * item.TotalPrice) + item.TotalPrice).reduce((p, v) => p + v); 

    // const thirdPartyPercent = 0; 
    // const jobMarginPercent = 1 - thirdPartyPercent; 
    // const jobMarginAmount = jobMarginPercent * profitMargin; 
    // const commissionPercent = .4; 

    

    return (
        <div style={{ backgroundColor: '#ddd', height: '100%', overflowY: 'hidden' }}>

            <SnackbarError open={updateJobStatus.status === "error"}>{handleNetworkError(updateJobStatus.error)}</SnackbarError>
            <SnackbarSuccess open={updateJobStatus.status === "success"}>
                Job Updated
            </SnackbarSuccess>

            <JobDetailHeader /> 

            <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
                
                <Grid container spacing={1}>
                    <Grid item xs={8}>

                        <JobDetailLeftHeader /> 


                        <Tabs>
                            <Tab label="JEF" onClick={() => setTab('jef')} value='jef' tab={tab} /> 
                            <Tab label="TIP" onClick={() => setTab('tip')} value='tip' tab={tab} /> 
                            <Tab perm={Job_CanManageTasksPermission} label="Tasks" onClick={() => setTab('tasks')} value='tasks' tab={tab} /> 
                            <Tab perm={Job_CanManageLogsPermission} label="Logs" onClick={() => setTab('logs')} value='logs' tab={tab} /> 
                        </Tabs>
                        
                        {{
                            "jef" : (
                                <>
                                    <JobDetailLeftJEF /> 
                                    <Perm perm={Job_CanManageCloseoutPermission}>
                                        <JobCloseOut /> 
                                    </Perm>
                                    <JobDetailNotes /> 
                                </>
                            ), 
                            "tip" : <JobDetailLeftTIP />, 
                            "tasks": <JobDetailLeftTasks />,
                            "logs": <JobDetailLeftLogs />,
                        }[tab]}
                        

                    </Grid>

                    <Grid item xs={4}>

                        <JobDetailRight /> 

                        <JobDetailSales /> 
                        
                    </Grid>               

                </Grid>
            
                <JobDetailItems /> 

            </div>
        </div>
    )
}



const LeftCol = styled(Grid)`
    text-align: right; 
    font-weight: bold; 
    min-height: 40px; 
    line-height: 40px; 
    vertical-align: middle; 
`
const RightCol = styled(Grid)`
    text-align: left; 
    min-height: 40px; 
    line-height: 40px; 
    vertical-align: middle; 
`

const PaperBox = styled(Paper)`
    margin: 10px; 
    padding: 10px; 
`

const JobDetailHeader = () => {

    const job = useRecoilValue(selectedJobAtom) 
    const [updateJob] = useUpdateJob(); 
    const resetUpdateStatus = useResetRecoilState(updateJobStatusAtom); 
    const [updateStatus, setUpdateStatus] = useRecoilState(updateJobStatusAtom);
    const history = useHistory(); 
    const items = useRecoilValue(itemsSelector); 

    const doUpdateJob = () => {
        setUpdateStatus({ ...updateStatus, status: "loading"}); 
        const updateJobDTO = { ...newUpdateJobDTOFromModel(job), Items: items }; 
        updateJob(updateJobDTO)
            .then(() => {
                setUpdateStatus({ status: "success", error: null });
                setTimeout(() => resetUpdateStatus(), 2000); 
            })
            .catch((e) => {
                setUpdateStatus({ status: "error", error: e }); 
                setTimeout(() => resetUpdateStatus(), 2000); 
            });
           
        // updateJob(newUpdateJobDTOFromModel(job))
    }
    const gotoQuote = () => {
        history.push(`/quotes/${job.QuoteID}`)
    }

    const gotoJob = (jobID: number) => () => {
        history.push(`/jobs/${jobID}`)
    }

    return (
        <NavBarSecondary back="/jobs"
            right={
                <div>
                    <IconButton onClick={() => { 
                        downloadJobExcel(job.JobID)
                            .then(() => { 
                                console.log('yay!'); 
                            })
                    }}>
                        <CloudDownload /> 
                    </IconButton> &nbsp; 
                    
                    <Button variant="contained" color="secondary" onClick={doUpdateJob} disabled={updateStatus.status === "loading"}>
                        Save
                        {updateStatus.status === "loading" && 
                            <CircularProgress size={20} /> 
                        }
                    </Button>
                </div>
            }
        >
            <strong>Jobs / {job.QuoteNumber}</strong>

            {job.PrevJob.JobID > 0 && 
                <Button size="small" onClick={gotoJob(job.PrevJob.JobID)}>
                    <ChevronLeft /> 
                    {job.PrevJob.JobNumber}
                </Button>
            }

            {job.NextJob.JobID > 0 && 
                <Button size="small" onClick={gotoJob(job.NextJob.JobID)}>
                    {job.NextJob.JobNumber}
                    <ChevronRight /> 
                </Button>
            }

            &nbsp; 

            <Button onClick={gotoQuote}>Go to Quote</Button>

        </NavBarSecondary>
    )

}

const JobDetailLeftHeader = () => {

    const job = useRecoilValue(selectedJobAtom); 

    return (
        <PaperBox>
            <Grid container spacing={1}>
            
                <LeftCol item xs={2} style={{ fontSize: 20 }}>Job Name</LeftCol>
                <RightCol item xs={9} style={{ fontSize: 20 }}>{job.Description}</RightCol>

                <LeftCol item xs={2} style={{ fontSize: 20 }}>Job Number</LeftCol>
                <RightCol item xs={9} style={{ fontSize: 20 }}>{job.JobNumberString}</RightCol>

                <LeftCol item xs={2} style={{ fontSize: 20 }}>PO Number</LeftCol>
                <RightCol item xs={9} style={{ fontSize: 20 }}>{job.PONumber}</RightCol>

            </Grid>
        </PaperBox>        
    )
}

const JobDetailLeftJEF = () => { 

    const [job, setJob] = useRecoilState(selectedJobAtom); 
    const { data : vendorsData, status, error } = useGetVendors(0, 100, 'Title', 'ASC'); 
    
    
    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !vendorsData) {
        return <CircularProgress /> 
    }

    return (
        <PaperBox>
            <Grid container spacing={1}>

                <LeftCol item xs={2}>Sale Type</LeftCol>
                <RightCol item xs={9}>
                    <JobDetailCommissionSelect /> 
                </RightCol>

                <LeftCol item xs={2}>Customer PO # 1</LeftCol>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth size="small" variant="outlined" value={job.CustomerPO1Number} onChange={(e) => setJob({ ...job, CustomerPO1Number: e.target.value })} label="PO Number (1)" />
                        </Grid>
                        <Grid item xs={6}>                    
                            <TextField fullWidth size="small" variant="outlined" select value={job.CustomerPO1SentTo} onChange={(e) => setJob({ ...job, CustomerPO1SentTo: parseInt(e.target.value as string)})} label="PO Sent To (1)">
                                <MenuItem value={0}>Not Set...</MenuItem>
                                {vendorsData.data.Data.map((vendor, key) => <MenuItem key={key} value={vendor.VendorID}>{vendor.Title}</MenuItem>)}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>

                <LeftCol item xs={2}>Customer PO # 2</LeftCol>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth size="small" variant="outlined" value={job.CustomerPO2Number} onChange={(e) => setJob({ ...job, CustomerPO2Number: e.target.value })} label="PO Number (2)" />
                        </Grid>
                        <Grid item xs={6}>                    
                            <TextField fullWidth size="small" variant="outlined" select value={job.CustomerPO2SentTo} onChange={(e) => setJob({ ...job, CustomerPO2SentTo: parseInt(e.target.value as string)})} label="PO Sent To (2)">
                                <MenuItem value={0}>Not Set...</MenuItem>
                                {vendorsData.data.Data.map((vendor, key) => <MenuItem key={key} value={vendor.VendorID}>{vendor.Title}</MenuItem>)}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>

                <LeftCol item xs={2}>Freight</LeftCol>
                <Grid item xs={9}>
                    <TextField size="small" variant="outlined" select value={job.IsAddFreight} onChange={(e) => setJob({ ...job, IsAddFreight: parseInt(e.target.value as string)})}>
                        <MenuItem value={0}>Freight Included</MenuItem>
                        <MenuItem value={1}>Freight Not Included</MenuItem>
                    </TextField>
                </Grid>

                <LeftCol item xs={2}>PO Date</LeftCol>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <DatePickerButton onChange={(d) => {
                                setJob({ ...job, AwardDateString: moment(d).format()})
                            }} value={job.AwardDateString} /> 
                        </Grid>
                        <LeftCol item xs={2}>JEF Date</LeftCol>
                        <Grid item xs={6}>
                            <DatePickerButton onChange={(d) => {
                                setJob({ ...job, JEFDateString: moment(d).format()})
                            }} value={job.JEFDateString} /> 
                        </Grid>

                    </Grid>
                </Grid>

                <LeftCol item xs={2}>Third Party Split?</LeftCol>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField fullWidth size="small" variant="outlined" select value={job.IsThirdPartySplit} onChange={(e) => setJob({ ...job, IsThirdPartySplit: parseInt(e.target.value as string)})}>
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </TextField>
                        </Grid>    
                        <Grid item xs={9}>
                            <TextField fullWidth size="small" variant="outlined" label="Third Party Name" value={job.ThirdPartyName} onChange={(e) => setJob({ ...job, ThirdPartyName: e.target.value })} /> 
                        </Grid>
                    </Grid>
                </Grid>
                
                <LeftCol item xs={2}>Tax Exempt #</LeftCol>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField fullWidth size="small" variant="outlined" label="Tax Exempt #" value={job.TaxExemptNumber} onChange={(e) => setJob({ ...job, TaxExemptNumber: e.target.value })} /> 
                        </Grid>
                        <Grid item xs={9}></Grid>
                    </Grid>
                </Grid>

                
            </Grid>
        </PaperBox>
    )

}



const   JobDetailLeftTIP = () => { 

    const history = useHistory(); 
    const goto = (url : string) => () => history.push(url); 
    const [job, setJob] = useRecoilState(selectedJobAtom); 
    const { data, status, error } = useGetListsByStringIDs('klukpfnsjruf,fkhyvx8b8ve1,ci3ftpwkv1uf');

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    console.log('data', data); 

    return ( 
        <>
            <PaperBox>
                <Grid container spacing={1}>
                    <LeftCol item xs={2}>Customer</LeftCol>
                    <RightCol item xs={9}>
                        <span style={{ color: '#009', textDecoration: 'underline', cursor: 'pointer' }} onClick={goto(`/app/customers/${job.CustomerID}`)}>
                            {job.Customer.Title}
                        </span>
                    </RightCol>
                    <LeftCol item xs={2}>Engineering Company</LeftCol>
                    <RightCol item xs={9}> {job.EngineerCoName}</RightCol>

                    <LeftCol item xs={2}>Contact Name</LeftCol>
                    <Grid item xs={9}>
                        <Grid container spacing={1}>
                            <RightCol item xs={3}>{job.CustomerContact.FirstName} {job.CustomerContact.LastName}</RightCol>
                            <LeftCol item xs={2}>Cell</LeftCol>
                            <RightCol item xs={2}>{job.CustomerContact.Cell}</RightCol>
                            <LeftCol item xs={2}>Email</LeftCol>
                            <RightCol item xs={3}>{job.CustomerContact.Email}</RightCol>
                        </Grid>
                    </Grid>

                    <div style={{ height: 50, width: '100%' }}>&nbsp;</div>

                    <LeftCol item xs={2}>Billing Address</LeftCol>
                    <Grid item xs={9}>
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.BillingAddress} onChange={(e) => setJob({ ...job, BillingAddress: e.target.value })} label="Address" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.BillingAddressCity} onChange={(e) => setJob({ ...job, BillingAddressCity: e.target.value })} label="City" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.BillingAddressState} onChange={(e) => setJob({ ...job, BillingAddressState: e.target.value })} label="State" /> 
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth size="small" variant="outlined" value={job.BillingAddressZip} onChange={(e) => setJob({ ...job, BillingAddressZip: e.target.value })} label="Zip" /> 
                            </Grid>
                        </Grid>

                    </Grid>
                    
                    <div style={{ height: 50, width: '100%' }}>&nbsp;</div>

                    <LeftCol item xs={2}>Shipping Address</LeftCol>
                    <Grid item xs={9}>
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ShippingAddress} onChange={(e) => setJob({ ...job, ShippingAddress: e.target.value })} label="Address" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ShippingAddressCity} onChange={(e) => setJob({ ...job, ShippingAddressCity: e.target.value })} label="City" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ShippingAddressState} onChange={(e) => setJob({ ...job, ShippingAddressState: e.target.value })} label="State" /> 
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ShippingAddressZip} onChange={(e) => setJob({ ...job, ShippingAddressZip: e.target.value })} label="Zip" /> 
                            </Grid>
                        </Grid>

                    </Grid>
                    <LeftCol item xs={2}>Relationship</LeftCol> 
                    <RightCol item xs={9}>
                        <TextField variant="outlined" size="small" fullWidth select value={job.RelationshipID} onChange={(e) => setJob({ ...job, RelationshipID: parseInt(e.target.value)})}>
                            {data.data['klukpfnsjruf'].Items.map((item, k) => <MenuItem key={k} value={item.ListItemID}>{item.Name}</MenuItem>)}
                        </TextField>
                    </RightCol> 

                    <LeftCol xs={2}>Call Ahead</LeftCol> 
                    <RightCol item xs={9}>
                        <TextField variant="outlined" size="small" fullWidth value={job.CallAhead} onChange={(e) => setJob({ ...job, CallAhead: e.target.value })} />
                    </RightCol> 


                    <LeftCol xs={2}>Ship To Name</LeftCol> 
                    <RightCol item xs={9}>
                        <TextField variant="outlined" size="small" fullWidth value={job.ShipToName} onChange={(e) => setJob({ ...job, ShipToName: e.target.value })}  helperText="Name of ship to"/>    
                    </RightCol> 
                    
                    <LeftCol xs={2}>Ship To Tag</LeftCol> 
                    <RightCol item xs={9}>
                        <TextField variant="outlined" size="small" fullWidth value={job.ShipToTag} onChange={(e) => setJob({ ...job, ShipToTag: e.target.value })} helperText="Ship to tag" />
                    </RightCol> 

                    <div style={{ height: 50, width: '100%' }}>&nbsp;</div>

                    <LeftCol item xs={2}>
                        Property Owner<sup>*</sup>
                        <div style={{ fontSize: 10, fontStyle: 'italic' }}><sup>*</sup>if different than ship to</div>
                    </LeftCol>
                    <Grid item xs={9}>
                        
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.PropertyName} onChange={(e) => setJob({ ...job, PropertyName: e.target.value })} label="Property Name" /> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.PropertyAddress} onChange={(e) => setJob({ ...job, PropertyAddress: e.target.value })} label="Property Owner Address" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.PropertyCity} onChange={(e) => setJob({ ...job, PropertyCity: e.target.value })} label="City" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.PropertyState} onChange={(e) => setJob({ ...job, PropertyState: e.target.value })} label="State" /> 
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth size="small" variant="outlined" value={job.PropertyZip} onChange={(e) => setJob({ ...job, PropertyZip: e.target.value })} label="Zip" /> 
                            </Grid>
                        </Grid>

                    </Grid>

                    <div style={{ height: 50, width: '100%' }}>&nbsp;</div>
                    
                    <LeftCol item xs={2}>
                        Prime/General Contractor<sup>*</sup>
                        <div style={{ fontSize: 10, fontStyle: 'italic' }}><sup>*</sup>if different than sold to</div>
                    </LeftCol>
                    <Grid item xs={9}>
                        
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ContractorName} onChange={(e) => setJob({ ...job, ContractorName: e.target.value })} label="Contractor Name" /> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ContractorAddress} onChange={(e) => setJob({ ...job, ContractorAddress: e.target.value })} label="Contractor Address" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ContractorCity} onChange={(e) => setJob({ ...job, ContractorCity: e.target.value })} label="City" /> 
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ContractorState} onChange={(e) => setJob({ ...job, ContractorState: e.target.value })} label="State" /> 
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth size="small" variant="outlined" value={job.ContractorZip} onChange={(e) => setJob({ ...job, ContractorZip: e.target.value })} label="Zip" /> 
                            </Grid>
                        </Grid>

                    </Grid>

                    <div style={{ height: 50, width: '100%' }}>&nbsp;</div>

                    <LeftCol item xs={2}>Required for Projects over $10,000.00</LeftCol>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                    <TextField label="Private or Public Money" variant="outlined" size="small" fullWidth select value={job.PublicPrivateMoneyID} onChange={(e) => setJob({ ...job, PublicPrivateMoneyID: parseInt(e.target.value)})}>
                                    {data.data['fkhyvx8b8ve1'].Items.map((item, k) => <MenuItem key={k} value={item.ListItemID}>{item.Name}</MenuItem>)}
                                </TextField>    

                            </Grid>

                            <Grid item xs={12}>
                                    <TextField label="BOND" variant="outlined" size="small" fullWidth select value={job.BondStatusID} onChange={(e) => setJob({ ...job, BondStatusID: parseInt(e.target.value)})}>
                                    {data.data['ci3ftpwkv1uf'].Items.map((item, k) => <MenuItem key={k} value={item.ListItemID}>{item.Name}</MenuItem>)}
                                </TextField>  
                            </Grid>

                            <Grid item xs={12}>
                                <TextField label="Company Providing Bond" variant="outlined" size="small" fullWidth value={job.CompanyProvidingBond} onChange={(e) => setJob({ ...job, CompanyProvidingBond: e.target.value })} />    
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline rowsMax={5} rows={5} variant="outlined" size="small" fullWidth value={job.Comments} onChange={(e) => setJob({ ...job, Comments: e.target.value })} />    
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </PaperBox>
        </>
    )

}

const JobDetailLeftTasks = () => { 

    const job = useRecoilValue(selectedJobAtom); 

    return ( 
        <PaperBox>
            <ObjectTodos 
                assignedTo={0} 
                objectType={ObjectTypeJob} 
                objectID={job.JobID} 
                showCreate={true}
            /> 
        </PaperBox>
    )
}


const JobDetailLeftLogs = () => { 

    const job = useRecoilValue(selectedJobAtom); 

    return ( 
        <PaperBox>
            <ObjectTodos 
                assignedTo={0} 
                objectType={ObjectTypeJob} 
                objectID={job.JobID} 
                showCreate={true}
                isPlainLog
            /> 
        </PaperBox>
    )
}

const JobDetailRight = () => {
    
    const [job, setJob] = useRecoilState(selectedJobAtom); 
    const totalCost = useRecoilValue(totalCostSelector);
    const grossMargin = useRecoilValue(grossMarginSelector);
    const grossProfit = useRecoilValue(grossProfitSelector); 
    const thirdPartyCommission = useRecoilValue(thirdPartyCommissionSelector); 
    const remainingGrossProfit = useRecoilValue(remainingGrossProfitSelector); 

    return (
        <PaperBox>
            <Grid container spacing={1}>
                
                {/* Total */}
                <LeftCol item xs={6}>
                    Sell Price
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right"}}>
                    <MoneyInput value={job.TotalPrice} onChange={(val) => {
                        setJob({ ...job, TotalPrice: val })
                    }} /> 
                </RightCol>
                
                {/* Total */}
                <LeftCol item xs={6}>
                    Project Cost
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right"}}>
                    $<MoneyFormat val={totalCost} />
                </RightCol>

                {/* Total */}
                <LeftCol item xs={6}>
                    Gross Margin %
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right"}}>
                    {grossMargin.toFixed(0)}%
                </RightCol>

                {/* Gross Profit Dollars */}
                <LeftCol item xs={6}>
                    Gross Profit Dollars
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right"}}>
                    $<MoneyFormat val={grossProfit} /> 
                </RightCol>

                {/* Cost Total */}
                <LeftCol item xs={6}>
                    3rd Party Split (%)
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right"}}>
                    <MoneyInput 
                        value={job.ThirdPartySplitPercent} 
                        onChange={(v) => setJob({ ...job, ThirdPartySplitPercent: v })} 
                        percent 
                    />  
                </RightCol>

                {/* Profit Margin */}
                <LeftCol item xs={6} style={{textAlign: "right"}}>
                    3rd Party Commission
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right" }}>
                    $<MoneyFormat val={thirdPartyCommission} /> 
                </RightCol>

                {/* Remaining Gross Margin */}
                <LeftCol item xs={6}>
                    Remaining Gross Margin
                </LeftCol>
                <RightCol item xs={6} style={{ textAlign: "right" }}>
                    $<MoneyFormat val={remainingGrossProfit} /> 
                </RightCol>

                <LeftCol item xs={6}>
                    Inside Sales
                </LeftCol>
                <RightCol item xs={6}>
                    <JobDetailInsideSales /> 
                </RightCol>

            </Grid>
        </PaperBox>

    )
}

const JobDetailCommissionSelect = () => {

    const [job, setJob] = useRecoilState(selectedJobAtom); 
    const { data, status, error } = useGetListFromListType(ListTypeCommissionType);

    if(status === "error") {
        return <Alert color="primary">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    return (
        <TextField 
            size="small" 
            variant="outlined" 
            select 
            value={job.CommissionTypeID} 
            onChange={(e) => setJob({ ...job, CommissionTypeID: parseInt(e.target.value as string)})}
        >
            {data.data.Items.map((item, key) => 
                <MenuItem key={key} value={item.ListItemID}>{item.Name}</MenuItem>
            )}
        </TextField> 
    )
}

const JobDetailNotes = () => {

    const [job, setJob] = useRecoilState(selectedJobAtom); 

    return (
        <PaperBox>
            <TextField 
                rows={10} rowsMax={10} multiline label="Notes" style={{ width: '100%' }} 
                value={job.Notes || ''}
                onChange={(e) => setJob({ ...job, Notes: e.target.value as string })}
            /> 
        </PaperBox>
    )
}


export default JobDetail; 