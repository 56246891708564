/**
 *
 * SearchResultsAggregate
 */
import { SearchResultDTO } from 'gen/dtos/SearchResultDTO';

export type SearchResultsAggregate = {

	// Results []*dtos.SearchResultDTO
	Results: SearchResultDTO[];

}

// newSearchResultsAggregate is a factory method for new SearchResultsAggregate objects
export const newSearchResultsAggregate = () : SearchResultsAggregate => ({
	Results: [],
});

