import { CustomerAggregate } from "app/definitions/aggregates/CustomerAggregate";
import axios from "axios";
import { Paged } from "core/definitions/utils/Paged";
import { SearchResultsAggregate} from "gen/aggregates/SearchResultsAggregate";
import { CreateCustomerContactDTO } from "gen/dtos/CreateCustomerContactDTO";
import { CreateCustomerDTO } from "gen/dtos/CreateCustomerDTO";
import { UpdateCustomerContactDTO } from "gen/dtos/UpdateCustomerContactDTO";
import { UpdateCustomerDTO } from "gen/dtos/UpdateCustomerDTO";
import { Customer } from 'gen/models/Customer';
import { CustomerContact } from 'gen/models/CustomerContact';
import { queryCache, useMutation, useQuery } from "react-query";


// Customers 
export const getCustomers = async (_: string, page: number, limit: number, orderBy: string, orderDir: string) => {
  const res = await axios.get<Paged<CustomerAggregate>>(
    `/customers?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`
  );
  return res;
};

export const useGetCustomers = (page: number, limit: number, orderBy: string, orderDir: string) => useQuery(["getCustomers", page, limit, orderBy, orderDir], getCustomers);


// Get Customer From ID
export const getCustomerFromID = async (customerID: number) => await axios.get<CustomerAggregate>(
  `/customers/${customerID}`
);
export const useGetCustomerFromID = (customerID: number | undefined) => {
  const wrapper = async (_: string, customerID: number | undefined) => {
    if(customerID) return await getCustomerFromID(customerID);
  }
  return useQuery(customerID !== undefined && ["getCustomerByID", customerID], wrapper);
};

// Get Customers From IDs
export const getCustomersFromIDs = async (_: string, customerIDs: number[]) => await axios.get<Customer[]>(
  `/customers?ids=${customerIDs.join(',')}`
);
export const useGetCustomersFromIDs = (customerIDs: number[]) => useQuery(["getCustomersFromIDs", customerIDs], getCustomersFromIDs);
  
// CreateCustomer
export const createCustomer = async (request: CreateCustomerDTO) => {
  const res = await axios.post<Customer>(
    "/customers",
    request, 
  );
  return res;
};

export const useCreateCustomer = () => {
  return useMutation(createCustomer, {
    onSuccess: (data, variables) => {
      // Invalidate the query 
      // https://react-query.tanstack.com/docs/guides/invalidations-from-mutations
      queryCache.invalidateQueries("getCustomers");
    },
  });
};

// Update Customer 

export const updateCustomer = async (request: UpdateCustomerDTO & { CustomerID: number }) => {
  const res = await axios.put<Customer>(
    `/customers/${request.CustomerID}`,
    request
  );

  return res;
};
export const useUpdateCustomer = () => {
  return useMutation(updateCustomer, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getCustomers");
      queryCache.invalidateQueries(["getCustomerByID", data.data.CustomerID])
    },
  });
};

// Delete Customer 
export const deleteCustomer = async (customerID: number) => {
  const res = await axios.delete(`/customers/${customerID}`);
  return res;
};

export const useDeleteCustomer = () => {
  return useMutation(deleteCustomer, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getCustomers");
    },
  });
};


export const searchCustomers = async (search: string) => await axios.get<SearchResultsAggregate>(
    `/customers?search=${search}`
  );

// Create Customer Contact
export const createCustomerContact = async (request: CreateCustomerContactDTO & { CustomerID: number }) => await axios.post<CustomerContact>(
        `/customers/${request.CustomerID}/contacts`, 
        request
    );
export const useCreateCustomerContact = () => {
  return useMutation(createCustomerContact, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getCustomers");
      queryCache.invalidateQueries(["getCustomerByID", data.data.CustomerID])
    },
  });
};

// Delete Customer Contact
export const deleteCustomerContact = async (request: { CustomerID: number; CustomerContactID: number }) => await axios.delete(
    `/customers/${request.CustomerID}/contacts/${request.CustomerContactID}`
);

export const useDeleteCustomerContact = () => {
  return useMutation(deleteCustomerContact, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getCustomers"); 
      queryCache.invalidateQueries(["getCustomerByID", variables.CustomerID])
    }
  })
}


// Update Customer Contact
export const updateCustomerContact = async (request: UpdateCustomerContactDTO & { CustomerID: number; CustomerContactID: number }) => await axios.put<CustomerContact>(
    `/customers/${request.CustomerID}/contacts/${request.CustomerContactID}`, 
    request, 
);

export const useUpdateCustomerContact = () => {
  return useMutation(updateCustomerContact, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries("getCustomers");
      queryCache.invalidateQueries(["getCustomerByID", data.data.CustomerID])
    },
  });
};













