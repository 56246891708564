import { toNumberList } from "core/utils/utils";

export type SearchJobDTO = {
    DateFrom: string; 
    DateTo: string; 
    Markets: number[]; 
    BidTypes: number[]; 
    CommissionTypes: number[]; 
    Customers: number[]; 
    Sales: number[]; 
    Vendors: number[]; 
    EngineerCompanies: number[];
    SearchID: number; 
    Limit: number; 
    Page: number; 
    OrderBy: string; 
    OrderDir: string; 
    ResultCount: number; 
}

export const newSearchJobDTO = () : SearchJobDTO => ({
    DateFrom: '', 
    DateTo: '', 
    Markets: [], 
    BidTypes: [], 
    CommissionTypes: [], 
    Customers: [], 
    Sales: [], 
    Vendors: [], 
    EngineerCompanies: [],
    SearchID: 0, 
    Limit: 50, 
    Page: 0, 
    OrderBy: 'JobNumberString', 
    OrderDir: 'DESC', 
    ResultCount: 0, 
});

export const newSearchJobDTOFromMap = (map: Map<string, string>) : SearchJobDTO => ({
    DateFrom: map.has('dateFrom') ? map.get('dateFrom') as string  : '', 
    DateTo: map.has('dateTo') ? map.get('dateTo') as string : '', 
    Markets: map.has('markets') ? toNumberList(map.get('markets') as string)  : [], 
    BidTypes: map.has('bidTypes') ? toNumberList(map.get('bidTypes') as string)  : [], 
    CommissionTypes: map.has('commissionTypes') ? toNumberList(map.get('commissionTypes') as string) : [], 
    Customers: map.has('customers') ? toNumberList(map.get('customers') as string) : [],
    Sales: map.has('sales') ? toNumberList(map.get('sales') as string)  : [], 
    Vendors: map.has('vendors') ? toNumberList(map.get('vendors') as string) : [], 
    EngineerCompanies:  map.has('engineerCompanies') ? toNumberList(map.get('engineerCompanies') as string) : [], 
    SearchID: map.has('searchID') ? parseInt(map.get('searchID') as string) : 0, 
    Limit: map.has('limit') ? parseInt(map.get('limit') as string) : 50, 
    Page: map.has('page') ? parseInt(map.get('page') as string) : 0, 
    OrderBy: map.has('orderBy') ? map.get('orderBy') as string : 'JobNumberString', 
    OrderDir: map.has('orderDir') ? map.get('orderDir') as string : 'DESC', 
    ResultCount: 0, 
})

export const searchJobDTOToMap = (dto: SearchJobDTO) : Map<string, string> => {

    const m = new Map(); 
    m.set('dateFrom', dto.DateFrom); 
    m.set('dateTo', dto.DateTo); 
    m.set('markets', dto.Markets ? dto.Markets.join(',') : ''); 
    m.set('bidTypes', dto.BidTypes ? dto.BidTypes.join(',') : ''); 
    m.set('commissionTypes', dto.CommissionTypes ? dto.CommissionTypes.join(',') : ''); 
    m.set('customers', dto.Customers ? dto.Customers.join(',') : ''); 
    m.set('sales', dto.Sales ? dto.Sales.join(',') : ''); 
    m.set('vendors', dto.Vendors ? dto.Vendors.join(',') : ''); 
    m.set('engineerCompanies', dto.EngineerCompanies ? dto.EngineerCompanies.join(',') : ''); 
    m.set('searchID', dto.SearchID ? dto.SearchID.toString() : 0); 
    m.set('limit', dto.Limit); 
    m.set('page', dto.Page); 
    m.set('orderBy', dto.OrderBy); 
    m.set('orderDir', dto.OrderDir); 
    m.set('resultCount', dto.ResultCount ? dto.ResultCount.toString() : '0'); 

    return m; 
}