import { siteConfig } from 'configs/site';
import userService from 'core/utils/userService';
import { useIsLoggedIn } from 'core/utils/userUtils';
import React from 'react';
import AdminNavBar from './adminNavBar';
import PublicNavBar from './publicNavBar';

const Header : React.FC<{ children : React.ReactNode }> = ({ children }) => { 

  const loggedIn = useIsLoggedIn(); 

  // getCurrentUser() occurs before this Header tag is called
  // So, we need the userService singleton to be checked just in case. 
  if (loggedIn || userService.isLoggedIn()) { 
    return ( 
      <AdminNavBar config={siteConfig}>
        {children}
      </AdminNavBar>
    ); 
  } 
  
  return ( 
    <PublicNavBar> 
      {children}
    </PublicNavBar>
  );

}

export default Header; 