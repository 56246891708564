/**
 *
 * Base.Users
 */
 
import axios from 'axios';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CreateUserDTO } from 'gen/dtos/CreateUserDTO';
import { LoginResponseAggregate } from 'gen/aggregates/LoginResponseAggregate';
import { ObjectSettingDTO } from 'gen/dtos/ObjectSettingDTO';
import { Profile } from 'gen/models/Profile';
import { ResetPasswordLinkDTO } from 'gen/dtos/ResetPasswordLinkDTO';
import { SearchResultsAggregate } from 'gen/aggregates/SearchResultsAggregate';
import { SetUserPasswordDTO } from 'gen/dtos/SetUserPasswordDTO';
import { UpdateObjectSettingsDTO } from 'gen/dtos/UpdateObjectSettingsDTO';
import { UpdateProfileDTO } from 'gen/dtos/UpdateProfileDTO';
import { UpdateUserDTO } from 'gen/dtos/UpdateUserDTO';
import { UpdateUserPhoneRequestDTO } from 'gen/dtos/UpdateUserPhoneRequestDTO';
import { UserAggregate } from 'gen/aggregates/UserAggregate';
import { UserCollectionAggregate } from 'gen/aggregates/UserCollectionAggregate';
import { UserDevice } from 'gen/models/UserDevice';
import { UserEventLogsAggregate } from 'gen/aggregates/UserEventLogsAggregate';

// CreateUser creates a user
// POST /users
// @permission Users_CreateUser
export const createUser = async (args : { body : CreateUserDTO }) => await axios.post<UserAggregate>(`/users`, args.body);
export const useCreateUser = () => useMutation(createUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
	},
	throwOnError: true, 
});
	

// UpdateUser updates a user
// PUT /users/{userID:[0-9]+}
// @permission Users_UpdateUser
export const updateUser = async (args : { userID : number, body : UpdateUserDTO }) => await axios.put<UserAggregate>(`/users/${args.userID}`, args.body);
export const useUpdateUser = () => useMutation(updateUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// ActivateUser activates a user without email activation
// POST /users/{userID:[0-9]+}/activate
// @permission Users_ActivateUser
export const activateUser = async (args : { userID : number }) => await axios.post<any>(`/users/${args.userID}/activate`, {});
export const useActivateUser = () => useMutation(activateUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// UpdateUserProfile updates a user profile
// PUT /users/{userID:[0-9]+}/profile
// @permission Users_UpdateUserProfile
export const updateUserProfile = async (args : { userID : number, body : UpdateProfileDTO }) => await axios.put<Profile>(`/users/${args.userID}/profile`, args.body);
export const useUpdateUserProfile = () => useMutation(updateUserProfile, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// GetAllUsers gets a collection of users across all accounts
// GET /users?accountID={accountID:[0-9]+}&roleID={roleID:[0-9]+}&groupID={groupID:[0-9]+}&disabled={disabled:[0-9-]+}&page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Users_GetAllUsers
export const getAllUsers = async (accountID : number, roleID : number, groupID : number, disabled : string, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<UserCollectionAggregate>(`/users?accountID=${accountID}&roleID=${roleID}&groupID=${groupID}&disabled=${disabled}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDir=${orderDir}`);
export const useGetAllUsers = (accountID : number, roleID : number, groupID : number, disabled : string, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getAllUsers", accountID, roleID, groupID, disabled, page, limit, orderBy, orderDir], (_ : string, accountID : number, roleID : number, groupID : number, disabled : string, page : number, limit : number, orderBy : string, orderDir : string) => getAllUsers(accountID,roleID,groupID,disabled,page,limit,orderBy,orderDir));

// GetUserFromID gets a user from their ID
// GET /users/{userID:[0-9]+}
// @permission Users_GetUserFromID
export const getUserFromID = async (userID : number) => await axios.get<UserAggregate>(`/users/${userID}`);
export const useGetUserFromID = (userID : number) => useQuery(["getUserFromID", userID], (_ : string, userID : number) => getUserFromID(userID));

// DeleteUser deletes a user
// DELETE /users/{userID:[0-9]+}
// @permission Users_DeleteUser
export const deleteUser = async (args : { userID : number }) => await axios.delete<any>(`/users/${args.userID}`);
export const useDeleteUser = () => useMutation(deleteUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// SetUserPassword sets a users password
// POST /users/{userID:[0-9]+}/password
// @permission Users_SetUserPassword
export const setUserPassword = async (args : { userID : number, body : SetUserPasswordDTO }) => await axios.post<any>(`/users/${args.userID}/password`, args.body);
export const useSetUserPassword = () => useMutation(setUserPassword, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// GetUserSession returns the session information for another user to impersonate
// GET /users/{userID:[0-9]+}/session
// @permission Users_GetUserSession
export const getUserSession = async (userID : number) => await axios.get<LoginResponseAggregate>(`/users/${userID}/session`);
export const useGetUserSession = (userID : number) => useQuery(["getUserSession", userID], (_ : string, userID : number) => getUserSession(userID));

// GetUserResetPasswordLink returns a link for a user to reset their password
// GET /users/{userID:[0-9]+}/resetPasswordLink
// @permission Users_GetUserResetPasswordLink
export const getUserResetPasswordLink = async (userID : number) => await axios.get<ResetPasswordLinkDTO>(`/users/${userID}/resetPasswordLink`);
export const useGetUserResetPasswordLink = (userID : number) => useQuery(["getUserResetPasswordLink", userID], (_ : string, userID : number) => getUserResetPasswordLink(userID));

// SearchUsersByEmail searches user accounts by email
// GET /users?email={email:[*]}&userType={userType:[0-9]+}
// @permission Users_SearchUsersByEmail
export const searchUsersByEmail = async (email : string, userType : number) => await axios.get<UserAggregate>(`/users?email=${email}&userType=${userType}`);
export const useSearchUsersByEmail = (email : string, userType : number) => useQuery(["searchUsersByEmail", email, userType], (_ : string, email : string, userType : number) => searchUsersByEmail(email,userType));

// SearchUsersByName searches users by their name
// GET /users?name={name:[a-zA-Z]+}
// @permission Users_SearchUsersByName
export const searchUsersByName = async (name : string) => await axios.get<SearchResultsAggregate>(`/users?name=${name}`);
export const useSearchUsersByName = (name : string) => useQuery(["searchUsersByName", name], (_ : string, name : string) => searchUsersByName(name));

// GetUserDevices returns a slice of devices associated with a user
// GET /users/{userID:[0-9]+}/devices
// @permission Users_GetUserDevices
export const getUserDevices = async (userID : number) => await axios.get<UserDevice[]>(`/users/${userID}/devices`);
export const useGetUserDevices = (userID : number) => useQuery(["getUserDevices", userID], (_ : string, userID : number) => getUserDevices(userID));

// AddPolicyToUser adds a policy to a user
// POST /users/{userID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission Users_AddPolicyToUser
export const addPolicyToUser = async (args : { userID : number, policyID : number }) => await axios.post<any>(`/users/${args.userID}/policies/${args.policyID}`, {});
export const useAddPolicyToUser = () => useMutation(addPolicyToUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// RemovePolicyFromUser removes a policy from a group
// DELETE /users/{userID:[0-9]+}/policies/{policyID:[0-9]+}
// @permission Users_RemovePolicyFromUser
export const removePolicyFromUser = async (args : { userID : number, policyID : number }) => await axios.delete<any>(`/users/${args.userID}/policies/${args.policyID}`);
export const useRemovePolicyFromUser = () => useMutation(removePolicyFromUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// AddRoleToUser adds a role to a user
// POST /users/{userID:[0-9]+}/roles/{roleID:[0-9]+}
// @permission Users_AddRoleToUser
export const addRoleToUser = async (args : { userID : number, roleID : number }) => await axios.post<any>(`/users/${args.userID}/roles/${args.roleID}`, {});
export const useAddRoleToUser = () => useMutation(addRoleToUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// RemoveRoleFromUser removes a role from a user
// DELETE /users/{userID:[0-9]+}/roles/{roleID:[0-9]+}
// @permission Users_RemoveRoleFromUser
export const removeRoleFromUser = async (args : { userID : number, roleID : number }) => await axios.delete<any>(`/users/${args.userID}/roles/${args.roleID}`);
export const useRemoveRoleFromUser = () => useMutation(removeRoleFromUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// DisableUser disables a user
// PUT /users/{userID:[0-9]+}/disable
// @permission Users_DisableUser
export const disableUser = async (args : { userID : number }) => await axios.put<any>(`/users/${args.userID}/disable`, {});
export const useDisableUser = () => useMutation(disableUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// EnableUser enables a user
// PUT /users/{userID:[0-9]+}/enable
// @permission Users_EnableUser
export const enableUser = async (args : { userID : number }) => await axios.put<any>(`/users/${args.userID}/enable`, {});
export const useEnableUser = () => useMutation(enableUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// LockUser disables a user
// PUT /users/{userID:[0-9]+}/lock
// @permission Users_LockUser
export const lockUser = async (args : { userID : number }) => await axios.put<any>(`/users/${args.userID}/lock`, {});
export const useLockUser = () => useMutation(lockUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// UnlockUser unlocks a user
// PUT /users/{userID:[0-9]+}/unlock
// @permission Users_UnlockUser
export const unlockUser = async (args : { userID : number }) => await axios.put<any>(`/users/${args.userID}/unlock`, {});
export const useUnlockUser = () => useMutation(unlockUser, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// AddUserToGroup adds a User to a UserGroup
// POST /users/{userID:[0-9]+}/groups/{groupID:[0-9]+}
// @permission Users_AddUserToGroup
export const addUserToGroup = async (args : { userID : number, groupID : number }) => await axios.post<any>(`/users/${args.userID}/groups/${args.groupID}`, {});
export const useAddUserToGroup = () => useMutation(addUserToGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// RemoveUserFromGroup removes a user from a group
// DELETE /users/{userID:[0-9]+}/groups/{groupID:[0-9]+}
// @permission Users_RemoveUserFromGroup
export const removeUserFromGroup = async (args : { userID : number, groupID : number }) => await axios.delete<any>(`/users/${args.userID}/groups/${args.groupID}`);
export const useRemoveUserFromGroup = () => useMutation(removeUserFromGroup, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// GetUserSettings gets a users settings
// GET /users/{userID:[0-9]+}/settings
// @permission Users_GetUserSettings
export const getUserSettings = async (userID : number) => await axios.get<ObjectSettingDTO[]>(`/users/${userID}/settings`);
export const useGetUserSettings = (userID : number) => useQuery(["getUserSettings", userID], (_ : string, userID : number) => getUserSettings(userID));

// UpdateUserSettings updates a user settings
// PUT /users/{userID:[0-9]+}/settings
// @permission Users_UpdateUserSettings
export const updateUserSettings = async (args : { userID : number, body : UpdateObjectSettingsDTO }) => await axios.put<any>(`/users/${args.userID}/settings`, args.body);
export const useUpdateUserSettings = () => useMutation(updateUserSettings, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// GetUserEventLogs gets a users event logs
// GET /users/{userID:[0-9]+}/eventLogs?ip={ip:[0-9.*]+}&eventName={eventName:[a-zA-Z0-9.*]+}&device={device:[a-z0-9-*]+}&page={page:[0-9]+}&limit={limit:[0-9]+}&orderBy={orderBy:[a-zA-Z0-9]+}&orderDir={orderDir:[a-zA-Z]+}
// @permission Users_GetUserEventLogs
export const getUserEventLogs = async (userID : number, ip : string, eventName : string, device : string, page : number, limit : number, orderBy : string, orderDir : string) => await axios.get<UserEventLogsAggregate>(`/users/${userID}/eventLogs?ip=${ip}&eventName=${eventName}&device=${device}&page=${page}&limit=${limit}&orderBy=${orderBy === "UserID" ? "ActorID" :orderBy}&orderDir=${orderDir}`);
export const useGetUserEventLogs = (userID : number, ip : string, eventName : string, device : string, page : number, limit : number, orderBy : string, orderDir : string) => useQuery(["getUserEventLogs", userID, ip, eventName, device, page, limit, orderBy, orderDir], (_ : string, userID : number, ip : string, eventName : string, device : string, page : number, limit : number, orderBy : string, orderDir : string) => getUserEventLogs(userID,ip,eventName,device,page,limit,orderBy,orderDir));

// ToggleTwoFactor toggles two factor auth for a user
// PUT /users/{userID:[0-9]+}/twoFactor
// @permission Users_ToggleTwoFactor
export const toggleTwoFactor = async (args : { userID : number }) => await axios.put<any>(`/users/${args.userID}/twoFactor`, {});
export const useToggleTwoFactor = () => useMutation(toggleTwoFactor, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	

// UpdateUserPhoneNumber updates the phone number for a user
// PUT /users/{userID:[0-9]+}/phone
// @permission Users_UpdateUserPhoneNumber
export const updateUserPhoneNumber = async (args : { userID : number, body : UpdateUserPhoneRequestDTO }) => await axios.put<any>(`/users/${args.userID}/phone`, args.body);
export const useUpdateUserPhoneNumber = () => useMutation(updateUserPhoneNumber, {
	onSuccess: (data, variables) => { 
		queryCache.invalidateQueries(["searchUsersByName"]);
		queryCache.invalidateQueries(["getUserFromID", variables.userID]);
	},
	throwOnError: true, 
});
	
