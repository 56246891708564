import { FiscalPeriod } from "gen/models/FiscalPeriod";
import moment from "moment";

export type UpdateFiscalPeriodDTO = {
    FiscalPeriodID: number; 
    FiscalYearID: number; 
    DateFrom: string; 
    DateTo: string; 
}

export const newUpdateFiscalPeriodDTO = () : UpdateFiscalPeriodDTO => ({
    FiscalPeriodID: 0,  
    FiscalYearID: 0, 
    DateFrom: '', 
    DateTo: '', 
});

export const newUpdateFiscalPeriodDTOFromModel = (model: FiscalPeriod) : UpdateFiscalPeriodDTO => ({
    FiscalPeriodID: model.FiscalPeriodID, 
    FiscalYearID: model.FiscalYearID, 
    DateFrom: moment(model.DateFrom).format(), 
    DateTo: moment(model.DateTo).format(), 
})