import { JobSales, newJobSales } from "gen/models/JobSales"

export type JobSalesAggregate = JobSales & {
    Name: string; 
}

export const newJobSalesAggregate = () : JobSalesAggregate => ({
    ...newJobSales(), 
    Name: '', 
})
