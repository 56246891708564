import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, TextField } from '@material-ui/core';
import { useCreatePolicy } from 'gen/routes/Policies';
import handleNetworkError from 'core/utils/handleNetworkError';
import { newCreatePolicyDTO } from 'gen/dtos/CreatePolicyDTO';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { showCreatePolicyModalAtom } from './state';


const StyledFormControl = styled(FormControl)`
    margin-bottom: 10px; 
    width: 100%; 
`

const CreatePolicyDialog = () => {

    const [isModalShowing, setIsModalShowing] = useRecoilState(showCreatePolicyModalAtom);
    const [createPolicy, { isError: isCreateRoleError, isLoading, isSuccess, error }] = useCreatePolicy(); 
    const [policy, setPolicy] = useState(newCreatePolicyDTO()); 
    const save = () => createPolicy({ body: policy });

    useEffect(() => {
        if(isSuccess) {
            setIsModalShowing(false); 
            setPolicy(newCreatePolicyDTO()); 
        }
    }, [isSuccess, setIsModalShowing]); 

    return <Dialog open={isModalShowing} onBackdropClick={() => setIsModalShowing(false)}>
        
        <DialogTitle>Create a New Policy</DialogTitle>
        
        <DialogContent>
            
            {isCreateRoleError && 
                <Alert color="danger">{handleNetworkError(error)}</Alert>
            }

            <StyledFormControl>
                <InputLabel>Name</InputLabel>
                <Input type="text" name="Name" value={policy.ShortName} onChange={(e) => setPolicy({ ...policy, ShortName: e.target.value })} /> 
            </StyledFormControl>

            <TextField
                multiline
                fullWidth
                rows={5}
                rowsMax={5}
                value={policy.Description}
                onChange={(e) => setPolicy({ ...policy, Description: e.target.value })}
                label="Description"
            /> 
            
        </DialogContent>
        <DialogActions>

            <Button variant="contained" onClick={() => save()} disabled={isLoading}>
                
                Create Policy

                {isLoading &&
                    <CircularProgress size={20} /> 
                }

            </Button>

            <Button onClick={save} disabled={isLoading}>cancel</Button>
            
        </DialogActions>

    </Dialog>
}

export default CreatePolicyDialog; 