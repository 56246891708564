/**
 *
 * CreateRoleDTO
 */

export type CreateRoleDTO = {

	// Name string
	Name: string;

	// Permissions string
	Permissions: string;

}

// newCreateRoleDTO is a factory method for new CreateRoleDTO objects
export const newCreateRoleDTO = () : CreateRoleDTO => ({
	Name: '',
	Permissions: '',
});

