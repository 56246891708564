/**
 *
 * User
 */

export type User = {

	// AccountID int64
	AccountID: number;

	// AccountRole int
	AccountRole: number;

	// DateActivated int64
	DateActivated: number;

	// DateCreated int64
	DateCreated: number;

	// Email string
	Email: string;

	// FullName string
	FullName: string;

	// ImageID int64
	ImageID: number;

	// IsDeleted int
	IsDeleted: number;

	// IsDisabled int
	IsDisabled: number;

	// IsLocked int
	IsLocked: number;

	// LastLogin int64
	LastLogin: number;

	// LastLoginAttempt int64
	LastLoginAttempt: number;

	// LastLoginAttemptCounter int
	LastLoginAttemptCounter: number;

	// LastUpdated int64
	LastUpdated: number;

	// PermissionCount int64
	PermissionCount: number;

	// PolicyCount int64
	PolicyCount: number;

	// ProfileID int64
	ProfileID: number;

	// ReasonForLeaving null.String
	ReasonForLeaving: string;

	// RoleCount int64
	RoleCount: number;

	// SMSPhoneNumber string
	SMSPhoneNumber: string;

	// SendSMSNotifications int
	SendSMSNotifications: number;

	// TwoFactorEnabled int
	TwoFactorEnabled: number;

	// TwoFactorLastCompleted int64
	TwoFactorLastCompleted: number;

	// UserGroupCount int64
	UserGroupCount: number;

	// UserID int64
	UserID: number;

	// UserPrefix string
	UserPrefix: string;

}

// newUser is a factory method for creating new User objects
export const newUser = () : User => ({ 
	AccountID: 0,
	AccountRole: 0,
	DateActivated: 0,
	DateCreated: 0,
	Email: '',
	FullName: '',
	ImageID: 0,
	IsDeleted: 0,
	IsDisabled: 0,
	IsLocked: 0,
	LastLogin: 0,
	LastLoginAttempt: 0,
	LastLoginAttemptCounter: 0,
	LastUpdated: 0,
	PermissionCount: 0,
	PolicyCount: 0,
	ProfileID: 0,
	ReasonForLeaving: '',
	RoleCount: 0,
	SMSPhoneNumber: '',
	SendSMSNotifications: 0,
	TwoFactorEnabled: 0,
	TwoFactorLastCompleted: 0,
	UserGroupCount: 0,
	UserID: 0,
	UserPrefix: '',
});

